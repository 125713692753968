import React from 'react';
import { useSelector } from 'react-redux';
import BasicPageWrapper from '@/ui/pages/pageWrappers/basicPageWrapper/BasicPageWrapper';
import Animation from './maintenance-animation.gif';
import './MaintenancePage.sass';
var MaintenancePage = function () {
    var currentMaintenanceSchedule = useSelector(function (state) {
        var _a;
        return (_a = state.app.maintenanceSchedule) === null || _a === void 0 ? void 0 : _a.current;
    });
    if (!currentMaintenanceSchedule) {
        return null;
    }
    return (React.createElement(BasicPageWrapper, { className: "maintenance" },
        React.createElement("img", { src: Animation }),
        React.createElement("h1", null, "We are down for maintenance"),
        React.createElement("div", { className: "description" },
            "We are currently undergoing some necessary maintenance, we expect to be back up on",
            ' ',
            React.createElement("span", { className: "bold-weight" }, currentMaintenanceSchedule.upTimeAt),
            ", sorry for any inconvenience. Have a well deserved break and check back in later.")));
};
export default MaintenancePage;
