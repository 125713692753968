import React, { useCallback, useMemo, useState, } from 'react';
import { useDispatch } from 'react-redux';
import { getFormServiceError } from '@/helpers/formHelper';
import { actions as usersActions } from '@/api/users';
import { Modal } from '@payaca/components/modal';
import { ValidatedForm } from '@payaca/components/validatedForm';
import { DropdownField } from '@payaca/components/dropdownField';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { ErrorMessage } from '@payaca/components/feedbackMessage';
var ChangeUserRoleModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, selectedUserIds = _a.selectedUserIds, onChangeUserRoleSuccess = _a.onChangeUserRoleSuccess;
    var dispatch = useDispatch();
    var _b = useState(false), isProcessing = _b[0], setIsProcessing = _b[1];
    var _c = useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var onSubmit = useCallback(function (isAdminRole) {
        setIsProcessing(true);
        setErrorMessage(undefined);
        var payload = {
            setAdminRole: isAdminRole,
        };
        dispatch(usersActions.updateUsersAccountRole(selectedUserIds, payload, function (error) {
            setIsProcessing(false);
            if (!error) {
                onChangeUserRoleSuccess && onChangeUserRoleSuccess();
            }
            else {
                setErrorMessage(getFormServiceError('changeUserRole', error));
            }
        }));
    }, [dispatch, onChangeUserRoleSuccess, selectedUserIds]);
    var initialFormState = useMemo(function () {
        return {
            isAdminRole: false,
        };
    }, []);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        return (React.createElement(React.Fragment, null,
            React.createElement(DropdownField, { styleVariant: InputStyleVariant.OUTSIZE, label: "", value: formState.isAdminRole || false, name: "isAdminRole", options: [
                    {
                        label: 'Admin',
                        value: true,
                    },
                    {
                        label: 'Non-admin',
                        value: false,
                    },
                ], onChange: onFieldChange }),
            React.createElement("div", { className: "actions-container" },
                React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, isProcessing: isProcessing, onClick: function () { return !isProcessing && onSubmit(formState.isAdminRole); } }, "Submit"),
                errorMessage && React.createElement(ErrorMessage, { message: errorMessage }))));
    }, [onSubmit, isProcessing, errorMessage]);
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose, title: "Change user role", size: "xs" },
        React.createElement(ValidatedForm, { renderFormContents: renderFormContents, initialFormState: initialFormState })));
};
export default ChangeUserRoleModal;
