import React, { useMemo, useRef } from 'react';
import JobOverviewItemGroups from './JobOverviewItemGroups';
import JobOverviewTotals from './JobOverviewTotals';
import JobOverviewNotes from '../jobOverviewNotes/JobOverviewNotes';
import JobOverviewAcceptedSignature from './JobOverviewAcceptedSignature';
import SatisfactionNoteDeclarationResponse from './SatisfactionNoteDeclarationResponse';
import { isQuoteOrEstimate } from '@payaca/helpers/jobStatusHelper';
import { useSmallViewListener } from '@payaca/utilities/hookUtilities';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
import './JobOverview.sass';
var JobOverview = function (_a) {
    var _b = _a.acceptedSignatureImageData, acceptedSignatureImageData = _b === void 0 ? undefined : _b, account = _a.account, callToAction = _a.callToAction, _c = _a.forceDisplayAsProposition, forceDisplayAsProposition = _c === void 0 ? false : _c, _d = _a.isEditable, isEditable = _d === void 0 ? false : _d, customer = _a.customer, job = _a.job, jobLineItems = _a.jobLineItems, jobLineItemGroups = _a.jobLineItemGroups, jobLineItemAttachments = _a.jobLineItemAttachments, jobFinanceInformation = _a.jobFinanceInformation, jobPayments = _a.jobPayments, satisfactionNoteSignatureImageData = _a.satisfactionNoteSignatureImageData, onUpdate = _a.onUpdate;
    var jobOverviewRef = useRef(null);
    var isSmallView = useSmallViewListener(jobOverviewRef, 700)[0];
    var jobContact = useMemo(function () {
        return getJobContactFromCustomer(customer, job.contactId);
    }, [job.contactId, customer]);
    var isJobQuoteOrEstimate = useMemo(function () {
        return isQuoteOrEstimate(job.status);
    }, [job.status]);
    var displayAsProposition = useMemo(function () {
        return isJobQuoteOrEstimate || forceDisplayAsProposition;
    }, [forceDisplayAsProposition, isJobQuoteOrEstimate]);
    var shouldRenderSatisfactionNoteDeclarationResponse = useMemo(function () {
        return (jobFinanceInformation &&
            (!!jobFinanceInformation.satisfactionNoteSignedAt ||
                !!jobFinanceInformation.satisfactionNoteRejectedAt));
    }, [jobFinanceInformation]);
    var shouldRenderJobOverviewAcceptedSignature = useMemo(function () {
        return !jobFinanceInformation && !!job.acceptedAt;
    }, [job, jobFinanceInformation]);
    var hasAnyReverseChargeVat = useMemo(function () {
        // hide vat if no items have any vat included
        return jobLineItems.some(function (jli) { return !!jli.isReverseChargeVat; });
    }, [jobLineItems]);
    return (React.createElement("div", { ref: jobOverviewRef, className: "job-overview" + (isSmallView ? ' small-view' : '') },
        React.createElement(JobOverviewItemGroups, { isEditable: isEditable, hideItemPrices: !!job.hideItemPrices, jobLineItemGroups: jobLineItemGroups, jobLineItems: jobLineItems, jobLineItemAttachments: jobLineItemAttachments, isProposition: displayAsProposition, onUpdate: onUpdate, hideVat: !job.isAnyVatIncluded }),
        React.createElement("div", { className: "job-overview-totals-and-customer-notes-wrapper" },
            job.jobNotes && (React.createElement(JobOverviewNotes, { title: "Customer notes", notes: job.jobNotes })),
            React.createElement(JobOverviewTotals, { job: job, jobPayments: jobPayments, jobFinanceInformation: jobFinanceInformation, isQuote: displayAsProposition, hasAnyReverseChargeVat: hasAnyReverseChargeVat })),
        callToAction,
        job.acceptedAt &&
            jobContact &&
            shouldRenderJobOverviewAcceptedSignature && (React.createElement("div", { className: "accepted-wrapper" },
            React.createElement(JobOverviewAcceptedSignature, { jobAcceptedAt: job.acceptedAt, jobContact: jobContact, acceptedSignatureImageData: acceptedSignatureImageData }))),
        shouldRenderSatisfactionNoteDeclarationResponse && (React.createElement(SatisfactionNoteDeclarationResponse, { agreementDuration: (jobFinanceInformation === null || jobFinanceInformation === void 0 ? void 0 : jobFinanceInformation.agreementDuration) || 0, customerName: (jobContact === null || jobContact === void 0 ? void 0 : jobContact.name) || '', companyName: account.companyName, rejectedAt: jobFinanceInformation === null || jobFinanceInformation === void 0 ? void 0 : jobFinanceInformation.satisfactionNoteRejectedAt, lenderName: (jobFinanceInformation === null || jobFinanceInformation === void 0 ? void 0 : jobFinanceInformation.type) || '', signatureImageData: satisfactionNoteSignatureImageData, signedAt: jobFinanceInformation === null || jobFinanceInformation === void 0 ? void 0 : jobFinanceInformation.satisfactionNoteSignedAt }))));
};
export default JobOverview;
