var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef, useState } from 'react';
import './ResponsivePopperAndTrigger.sass';
import { ClickAwayListener, Drawer, Grow, Paper, Popper, } from '@material-ui/core';
var ResponsivePopperAndTrigger = function (_a) {
    var _b = _a.isSmallView, isSmallView = _b === void 0 ? false : _b, _c = _a.popperPlacement, popperPlacement = _c === void 0 ? 'bottom-start' : _c, popperClassName = _a.popperClassName, drawerClassName = _a.drawerClassName, renderTriggerContent = _a.renderTriggerContent, renderPopperContent = _a.renderPopperContent, _d = _a.useDrawerWhenInSmallView, useDrawerWhenInSmallView = _d === void 0 ? true : _d;
    var ref = useRef(null);
    var _e = useState(false), showPopper = _e[0], setShowPopper = _e[1];
    return (React.createElement("div", { className: "responsive-popper-and-trigger " + (isSmallView ? 'sm-view' : '') + " " + (showPopper ? 'active' : '') },
        React.createElement("div", { className: "show-popper-trigger", ref: ref, onClick: function () { return setShowPopper(true); } }, renderTriggerContent(isSmallView)),
        (!isSmallView || !useDrawerWhenInSmallView) && (React.createElement(Popper, { open: showPopper && !!ref.current, anchorEl: ref.current, role: undefined, transition: true, placement: popperPlacement, className: "responsive-popper-and-trigger-popper " + (popperClassName || '') }, function (_a) {
            var TransitionProps = _a.TransitionProps;
            return (React.createElement(Grow, __assign({}, TransitionProps, { timeout: 350 }),
                React.createElement(Paper, null,
                    React.createElement(ClickAwayListener, { onClickAway: function () {
                            setShowPopper(false);
                        } },
                        React.createElement("div", null, renderPopperContent(isSmallView))))));
        })),
        isSmallView && useDrawerWhenInSmallView && (React.createElement(Drawer, { anchor: "bottom", open: showPopper, className: "responsive-popper-and-trigger-drawer " + (drawerClassName || '') },
            React.createElement(Paper, null,
                React.createElement(ClickAwayListener, { onClickAway: function () {
                        setShowPopper(false);
                    } },
                    React.createElement("div", null, renderPopperContent(isSmallView))))))));
};
export default ResponsivePopperAndTrigger;
