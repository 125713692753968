import React, { useCallback, useEffect, useState, } from 'react';
import { Modal } from '@payaca/components/modal';
import { actions as userActions } from '@bit/payaca-tech.payaca-core.store.user';
import './ConfirmMakePrimaryUserModal.css';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { useDispatch, useSelector } from 'react-redux';
var ConfirmMakePrimaryUserModal = function (_a) {
    var userId = _a.userId, isOpen = _a.isOpen, onClose = _a.onClose, onMakePrimaryUserSuccess = _a.onMakePrimaryUserSuccess;
    var dispatch = useDispatch();
    var _b = useState(false), isSubmitted = _b[0], setIsSubmitted = _b[1];
    var isMakingPrimaryUser = useSelector(function (state) {
        return state.user.isMakingPrimaryUser;
    });
    var isPrimaryUserMadeSuccessfully = useSelector(function (state) {
        return state.user.isPrimaryUserMadeSuccessfully;
    });
    var onConfirm = useCallback(function () {
        setIsSubmitted(true);
        dispatch(userActions.requestMakePrimaryUser(userId));
    }, [userId, dispatch]);
    useEffect(function () {
        if (isSubmitted && isPrimaryUserMadeSuccessfully) {
            onMakePrimaryUserSuccess && onMakePrimaryUserSuccess();
        }
    }, [isSubmitted, isPrimaryUserMadeSuccessfully, onMakePrimaryUserSuccess]);
    return (React.createElement(Modal, { isOpen: isOpen, title: 'Make primary user', size: "sm", onClose: onClose, className: "confirm-make-primary-user-modal", actions: React.createElement(Button, { onClick: function () { return !isMakingPrimaryUser && onConfirm(); }, isProcessing: isMakingPrimaryUser, styleVariant: ButtonStyleVariant.OUTSIZE }, "Yes, confirm") },
        React.createElement("ul", null,
            React.createElement("li", null, "The primary user's details will be associated with your subscription payment."),
            React.createElement("li", null, "The primary user is the first person we will contact in relation to your account."),
            React.createElement("li", null, "If you make someone else the primary user, you will not be able to undo this action.")),
        React.createElement("p", { style: { textAlign: 'center' } },
            React.createElement("strong", null, "Are you sure you want to change the primary user?"))));
};
export default ConfirmMakePrimaryUserModal;
