import { action } from 'typesafe-actions';
import { ActionType } from './lineItemsTypes';
// GETTING LINE ITEM
export var requestGetLineItem = function (lineItemId, callback) {
    return action(ActionType.REQUEST_GET_LINE_ITEM, { lineItemId: lineItemId, callback: callback });
};
export var getLineItemSuccess = function (lineItem) {
    return action(ActionType.GET_LINE_ITEM_SUCCESS, {
        lineItemId: lineItem.id,
        lineItem: lineItem,
    });
};
export var getLineItemFailure = function (error, errorMessage) {
    return action(ActionType.GET_LINE_ITEM_FAILURE, { errorMessage: errorMessage }, null, error);
};
// CLEAR LINE ITEMS
export var clearLineItems = function () {
    return action(ActionType.CLEAR_LINE_ITEMS);
};
export var requestGetLineItemsForLineItemGroup = function (lineItemGroupId) {
    return action(ActionType.REQUEST_GET_LINE_ITEMS_FOR_LINE_ITEM_GROUP, {
        lineItemGroupId: lineItemGroupId,
    });
};
