import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CompanyContactFormSubsection from '../companyContactFormSubsection/CompanyContactFormSubsection';
import './CompanyContactsFormSubsection.css';
var CompanyContactsFormSubsection = function (_a) {
    var companyContacts = _a.companyContacts, fieldNamePrefix = _a.fieldNamePrefix, touchedState = _a.touchedState, validationState = _a.validationState, onChange = _a.onChange, onTouch = _a.onTouch, onCompanyContactAdd = _a.onCompanyContactAdd, onCompanyContactRemove = _a.onCompanyContactRemove;
    return (React.createElement("div", { className: "company-contacts-form-subsection" },
        companyContacts &&
            companyContacts.map(function (companyContact, index) {
                var isMainContact = companyContact.department === 'Main contact' && index === 0;
                return (React.createElement(CompanyContactFormSubsection, { key: index, canModifyDepartment: !isMainContact, fieldNamePrefix: fieldNamePrefix + "[" + index + "]", touchedState: touchedState, validationState: validationState, companyContact: companyContact, onTouch: onTouch, onChange: onChange, onRemove: !isMainContact ? function () { return onCompanyContactRemove(index); } : undefined }));
            }),
        React.createElement("div", { className: "add-button", onClick: onCompanyContactAdd },
            React.createElement(FontAwesomeIcon, { icon: faPlus }),
            "Add contact")));
};
export default CompanyContactsFormSubsection;
