import React from 'react';
import { Button } from '@payaca/components';
import './Form.css';
var Form = function (_a) {
    var buttonAction = _a.buttonAction, buttonDisabledAction = _a.buttonDisabledAction, buttonTitle = _a.buttonTitle, children = _a.children, _b = _a.hideButton, hideButton = _b === void 0 ? false : _b, isValid = _a.isValid, _c = _a.submitting, submitting = _c === void 0 ? false : _c;
    var onButtonSubmit = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (!isValid && buttonDisabledAction) {
            buttonDisabledAction();
        }
        else if (isValid && buttonAction) {
            buttonAction();
        }
    };
    // For onSubmit on pressing enter key, input is required and on Safari cannot be hidden so is off screen #gross
    return (React.createElement("form", { className: "form", onSubmit: function (e) { return onButtonSubmit(e); } },
        children,
        React.createElement("input", { className: "submit", type: "submit", tabIndex: -1 }),
        !hideButton && (React.createElement("div", { className: "button-container" },
            React.createElement("span", { onClick: !isValid ? buttonDisabledAction : undefined },
                React.createElement(Button, { isDisabled: !isValid, isProcessing: submitting, type: "submit" }, buttonTitle || 'Save'))))));
};
export default Form;
