import { getDeal, getJobsByDealId } from '@/utils/stateAccessors';
import { CreateButton } from '@payaca/components/button';
import { isInvoice } from '@payaca/helpers/jobStatusHelper';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@payaca/components/modal';
import { CreateArea, ClickableAreaColourVariant, } from '@payaca/components/clickableArea';
import './AddNewDealItemControl.sass';
import { useHistory } from 'react-router';
import { actions as jobActions } from '@/api/jobs';
import TooltipUI from '@material-ui/core/Tooltip';
var AddNewDealItemControl = function (_a) {
    var dealId = _a.dealId;
    var dispatch = useDispatch();
    var history = useHistory();
    var _b = useState(false), isCreatingInvoice = _b[0], setIsCreatingInvoice = _b[1];
    var _c = useState(false), showAddNewDealItemModal = _c[0], setShowAddNewDealItemModal = _c[1];
    var deal = useSelector(function (state) {
        return getDeal(state, dealId);
    });
    var jobs = useSelector(function (state) {
        return getJobsByDealId(state, dealId);
    });
    var canCreateProposal = useMemo(function () {
        if (!deal)
            return false;
        return !deal.invoiceIds.length && !deal.propositionIds.length;
    }, [deal]);
    var invoiceableProposalId = useMemo(function () {
        var _a;
        return (_a = jobs.find(function (x) {
            return !x.archivedAt &&
                !x.inactivatedAt &&
                !isInvoice(x.status) &&
                !x.showBespokeFinancePlans;
        })) === null || _a === void 0 ? void 0 : _a.id;
    }, [jobs]);
    var canCreateInvoice = useMemo(function () {
        if (!deal)
            return false;
        if (!deal.invoiceIds.length && !deal.propositionIds.length)
            return true;
        if (!!deal.invoiceIds.length)
            return false;
        return !!invoiceableProposalId;
    }, [deal, invoiceableProposalId]);
    var createInvoice = useCallback(function (proposalId) {
        if (!canCreateInvoice)
            return;
        if (proposalId) {
            setIsCreatingInvoice(true);
            dispatch(jobActions.convertJobToInvoice(proposalId, function (err, resp) {
                setIsCreatingInvoice(false);
                if (!err) {
                    history.push({
                        pathname: "/invoices/" + resp.invoiceId,
                    });
                }
            }));
        }
        else {
            history.push('/invoices/new');
        }
    }, [canCreateInvoice]);
    return (React.createElement("div", { className: "add-new-deal-item-control" },
        !canCreateInvoice && !canCreateProposal ? (React.createElement(TooltipUI, { title: "this option is not available", arrow: true, placement: "top" },
            React.createElement("div", null,
                React.createElement(CreateButton, { className: "disabled" }, "Add new")))) : (React.createElement(CreateButton, { onClick: function () { return setShowAddNewDealItemModal(true); } }, "Add new")),
        React.createElement(Modal, { isOpen: showAddNewDealItemModal, onClose: function () { return setShowAddNewDealItemModal(false); }, className: "add-new-deal-item-control-modal", title: "Add new..." },
            React.createElement("p", null, "Do you want to add a new Proposal or Invoice?"),
            React.createElement(CreateArea, { colourVariant: ClickableAreaColourVariant.SECONDARY_LIGHT, isDisabled: !canCreateProposal, onClick: function () { return history.push('/quotes/new'); } }, "Proposal"),
            React.createElement(CreateArea, { colourVariant: ClickableAreaColourVariant.SECONDARY_LIGHT, isDisabled: !canCreateInvoice, onClick: function () {
                    return !isCreatingInvoice && createInvoice(invoiceableProposalId);
                }, isProcessing: isCreatingInvoice }, "Invoice"))));
};
export default AddNewDealItemControl;
