import React, { useState } from 'react';
import { BasicField } from '@payaca/components/basicField';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import './JobReferenceControl.sass';
var JobReferenceControl = function (_a) {
    var customReference = _a.customReference, onFieldChange = _a.onFieldChange, reference = _a.reference, onFieldBlur = _a.onFieldBlur;
    var _b = useState(false), isSettingCustomReference = _b[0], setIsSettingCustomReference = _b[1];
    return (React.createElement("div", { className: "job-reference-control" },
        React.createElement("label", null, "Reference"),
        React.createElement("div", { className: "flex-container flex-center" }, customReference || isSettingCustomReference ? (React.createElement(React.Fragment, null,
            React.createElement(BasicField, { name: "customReference", type: "text", value: customReference || '', onChange: function (fieldChangeValue) {
                    var value = fieldChangeValue.customReference;
                    value = value.substring(0, 20);
                    onFieldChange({ customReference: value });
                }, additionalInputProps: {
                    placeholder: reference,
                }, onBlur: onFieldBlur, onChangeTimeout: onFieldBlur }))) : (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "flex-grow" }, (customReference || reference) && (React.createElement("strong", null,
                "#",
                customReference || reference))),
            React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () { return setIsSettingCustomReference(true); } }, "Change"))))));
};
export default JobReferenceControl;
