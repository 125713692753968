import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './ClickableArea.sass';
export var ClickableAreaColourVariant;
(function (ClickableAreaColourVariant) {
    ClickableAreaColourVariant["SECONDARY_LIGHTER"] = "secondary-lighter";
    ClickableAreaColourVariant["SECONDARY_LIGHT"] = "secondary-light";
})(ClickableAreaColourVariant || (ClickableAreaColourVariant = {}));
var ClickableArea = function (_a) {
    var onClick = _a.onClick, className = _a.className, isProcessing = _a.isProcessing, _b = _a.colourVariant, colourVariant = _b === void 0 ? ClickableAreaColourVariant.SECONDARY_LIGHTER : _b, children = _a.children, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c;
    return (React.createElement("div", { className: "clickable-area " + (className ? className : '') + " " + (isProcessing ? 'processing' : '') + " area-col-" + colourVariant + " " + (isDisabled ? 'disabled' : ''), role: "button", onClick: !isDisabled ? onClick : undefined },
        React.createElement("div", { className: "clickable-area-content" }, children),
        isProcessing && (React.createElement("span", { className: 'spinner-container' },
            React.createElement(FontAwesomeIcon, { icon: faCircleNotch, spin: true })))));
};
export default ClickableArea;
