import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { faCheck, faInfo, faPoundSign, faSearch, faTimes, faWrench, } from '@fortawesome/free-solid-svg-icons';
import { Edit } from 'react-iconly';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JobOverviewTimelineEvent from './JobOverviewTimelineEvent';
import LenderHelpModal from './LenderHelpModal';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { isDepositConfirmedPaid, isDepositAttemptedPaid, isInvoiceAttemptedPaid, isSatisfactionNoteSignatureRequired, } from '@payaca/helpers/jobHelperV2';
import { isEstimate, isQuoteOrEstimate } from '@payaca/helpers/jobStatusHelper';
import { hasAcceptedFinanceApplication, hasCustomerAgreementSignedFinanceApplication, hasRejectedFinanceApplication, hasSubmittedFinanceApplication, isPendingCustomerAgreementSigned, isPendingFinanceApplicationDecision, requiresCustomerActionFinanceApplication, } from '@payaca/helpers/jobFinanceApplicationHelper';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
import './JobOverviewTimeline.sass';
var signIcon = React.createElement(Edit, { set: "light", size: "small", primaryColor: "#263e59" });
var JobOverviewTimeline = function (_a) {
    var _b = _a.acceptedSignatureImageData, acceptedSignatureImageData = _b === void 0 ? undefined : _b, companyName = _a.companyName, customer = _a.customer, jobFinanceInformation = _a.jobFinanceInformation, job = _a.job, jobPayments = _a.jobPayments, satisfactionNoteSignatureImageData = _a.satisfactionNoteSignatureImageData;
    var _c = useState(false), showLenderDetailsModal = _c[0], setShowLenderDetailsModal = _c[1];
    var jobContact = useMemo(function () {
        return getJobContactFromCustomer(customer, job.contactId);
    }, [job]);
    var propositionType = useMemo(function () { return (isEstimate(job.status) ? 'Estimate' : 'Quote'); }, [job.status]);
    var jobAcceptedEvent = useMemo(function () {
        if (!jobFinanceInformation && !!(job === null || job === void 0 ? void 0 : job.acceptedAt)) {
            return {
                timestamp: job.acceptedAt,
                name: propositionType + " accepted",
                icon: React.createElement(FontAwesomeIcon, { className: "icon", icon: faCheck }),
                description: acceptedSignatureImageData
                    ? "Signed by " + (jobContact === null || jobContact === void 0 ? void 0 : jobContact.name)
                    : "Marked as accepted by " + companyName,
            };
        }
    }, [
        acceptedSignatureImageData,
        companyName,
        job,
        jobContact,
        jobFinanceInformation,
        propositionType,
    ]);
    var jobDeclinedEvent = useMemo(function () {
        if (!!(job === null || job === void 0 ? void 0 : job.declinedAt)) {
            return {
                icon: React.createElement(FontAwesomeIcon, { className: "icon", icon: faTimes }),
                timestamp: job.declinedAt,
                name: propositionType + " declined",
                description: "Declined by " + (jobContact === null || jobContact === void 0 ? void 0 : jobContact.name) + (job.declineReason ? " with reason: " + job.declineReason + "." : '.'),
            };
        }
    }, [job, jobContact, propositionType]);
    var jobFinanceApprovedEvent = useMemo(function () {
        if (jobFinanceInformation &&
            hasAcceptedFinanceApplication(jobFinanceInformation)) {
            return {
                icon: React.createElement(FontAwesomeIcon, { className: "icon", icon: faCheck }),
                timestamp: jobFinanceInformation.approvedAt,
                name: 'Finance application approved',
                showLenderHelp: true,
            };
        }
    }, [jobFinanceInformation]);
    var jobFinanceRejectedEvent = useMemo(function () {
        if (jobFinanceInformation &&
            hasRejectedFinanceApplication(jobFinanceInformation)) {
            return {
                icon: React.createElement(FontAwesomeIcon, { className: "icon", icon: faTimes }),
                timestamp: jobFinanceInformation.rejectedAt,
                name: 'Finance application rejected',
                showLenderHelp: true,
            };
        }
    }, [jobFinanceInformation]);
    var jobFinanceInReviewEvent = useMemo(function () {
        if (jobFinanceInformation &&
            isPendingFinanceApplicationDecision(jobFinanceInformation) &&
            !requiresCustomerActionFinanceApplication(jobFinanceInformation)) {
            return {
                icon: React.createElement(FontAwesomeIcon, { className: "icon", icon: faSearch }),
                timestamp: jobFinanceInformation.applicationStartedAt,
                name: 'Finance application in review',
                description: "Awaiting confirmation of next steps from the lender",
                showLenderHelp: true,
            };
        }
    }, [jobFinanceInformation]);
    var jobFinanceDepositPaidFullEvent = useMemo(function () {
        if (!!jobFinanceInformation &&
            !!(job === null || job === void 0 ? void 0 : job.depositAmount) &&
            !!isDepositConfirmedPaid(job, jobPayments)) {
            // get latest completed payment confirmation
            var financeDepositPaidConfirmation = jobPayments.reduce(function (acc, curr) {
                if (curr.isDepositPayment && !!curr.paymentCompletedConfirmationAt) {
                    acc =
                        acc &&
                            moment(curr === null || curr === void 0 ? void 0 : curr.paymentCompletedConfirmationAt).isBefore(moment(acc))
                            ? acc
                            : curr === null || curr === void 0 ? void 0 : curr.paymentCompletedConfirmationAt;
                }
                return acc;
            }, null);
            return {
                icon: React.createElement(FontAwesomeIcon, { className: "icon", icon: faPoundSign }),
                name: 'Finance deposit paid in full',
                timestamp: financeDepositPaidConfirmation,
            };
        }
    }, [job, jobFinanceInformation, jobPayments]);
    var jobCompletedEvent = useMemo(function () {
        if (!!(jobFinanceInformation === null || jobFinanceInformation === void 0 ? void 0 : jobFinanceInformation.jobCompletedAt)) {
            return {
                icon: React.createElement(FontAwesomeIcon, { className: "icon", icon: faWrench }),
                name: 'Job completed',
                timestamp: jobFinanceInformation.jobCompletedAt,
                description: "Completed by " + companyName,
            };
        }
    }, [companyName, jobFinanceInformation]);
    var satisfactionNoteSignedEvent = useMemo(function () {
        if (!!(jobFinanceInformation === null || jobFinanceInformation === void 0 ? void 0 : jobFinanceInformation.satisfactionNoteSignedAt)) {
            return {
                icon: signIcon,
                name: 'Satisfaction note signed',
                timestamp: jobFinanceInformation.satisfactionNoteSignedAt,
                description: satisfactionNoteSignatureImageData
                    ? "Satisfaction declaration signed by " + (jobContact === null || jobContact === void 0 ? void 0 : jobContact.name)
                    : (jobContact === null || jobContact === void 0 ? void 0 : jobContact.name) + " has not signed the satisfaction declaration but as over 72 hours have passed it is assumed to be satisfied",
            };
        }
    }, [jobContact, jobFinanceInformation, satisfactionNoteSignatureImageData]);
    var satisfactionNoteRejectedEvent = useMemo(function () {
        if (!!(jobFinanceInformation === null || jobFinanceInformation === void 0 ? void 0 : jobFinanceInformation.satisfactionNoteRejectedAt)) {
            return {
                icon: signIcon,
                name: 'Satisfaction note signed',
                timestamp: jobFinanceInformation.satisfactionNoteRejectedAt,
                description: "Satisfaction declaration rejected by " + (jobContact === null || jobContact === void 0 ? void 0 : jobContact.name),
            };
        }
    }, [jobContact, jobFinanceInformation]);
    var customerFinanceAgreementSignedEvent = useMemo(function () {
        if (jobFinanceInformation &&
            hasCustomerAgreementSignedFinanceApplication(jobFinanceInformation)) {
            return {
                icon: React.createElement(FontAwesomeIcon, { className: "icon", icon: faCheck }),
                name: 'Finance agreement signed',
                timestamp: jobFinanceInformation.customerAgreementSignedAt,
                description: "Customer finance agreement signed",
            };
        }
    }, [jobFinanceInformation]);
    var completedJobPayments = useMemo(function () {
        return jobPayments.filter(function (x) { return !!x.paymentCompletedConfirmationAt; });
    }, [jobPayments]);
    var pendingJobPayments = useMemo(function () {
        return jobPayments.filter(function (x) {
            return !x.paymentCompletedConfirmationAt &&
                !x.paymentFailedConfirmationAt &&
                !!x.bacsPaymentMadeAt;
        });
    }, [jobPayments]);
    var pastTimelineEvents = useMemo(function () {
        var timelineEventsList = [];
        jobAcceptedEvent && timelineEventsList.push(jobAcceptedEvent);
        jobDeclinedEvent && timelineEventsList.push(jobDeclinedEvent);
        jobFinanceInReviewEvent && timelineEventsList.push(jobFinanceInReviewEvent);
        jobFinanceApprovedEvent && timelineEventsList.push(jobFinanceApprovedEvent);
        jobFinanceRejectedEvent && timelineEventsList.push(jobFinanceRejectedEvent);
        customerFinanceAgreementSignedEvent &&
            timelineEventsList.push(customerFinanceAgreementSignedEvent);
        if (completedJobPayments) {
            completedJobPayments.forEach(function (jobPayment) {
                return timelineEventsList.push({
                    icon: React.createElement(FontAwesomeIcon, { className: "icon", icon: faPoundSign }),
                    timestamp: jobPayment.paymentCompletedConfirmationAt,
                    name: jobPayment.isDepositPayment
                        ? 'Deposit payment made'
                        : 'Payment made',
                    description: currencyPrice(jobPayment.paymentValue) + " paid",
                });
            });
        }
        jobFinanceDepositPaidFullEvent &&
            timelineEventsList.push(jobFinanceDepositPaidFullEvent);
        jobCompletedEvent && timelineEventsList.push(jobCompletedEvent);
        satisfactionNoteSignedEvent &&
            timelineEventsList.push(satisfactionNoteSignedEvent);
        satisfactionNoteRejectedEvent &&
            timelineEventsList.push(satisfactionNoteRejectedEvent);
        if (pendingJobPayments) {
            pendingJobPayments.forEach(function (jobPayment) {
                return timelineEventsList.push({
                    icon: React.createElement(FontAwesomeIcon, { className: "icon", icon: faPoundSign }),
                    timestamp: jobPayment.bacsPaymentMadeAt,
                    name: jobPayment.isDepositPayment
                        ? 'Deposit payment pending'
                        : 'Payment pending',
                    description: currencyPrice(jobPayment.paymentValue) + " pending",
                });
            });
        }
        return timelineEventsList.sort(function (a, b) {
            return moment(a.timestamp).isBefore(moment(b.timestamp)) ? 1 : -1;
        });
    }, [
        customerFinanceAgreementSignedEvent,
        completedJobPayments,
        pendingJobPayments,
        jobAcceptedEvent,
        jobCompletedEvent,
        jobDeclinedEvent,
        jobFinanceApprovedEvent,
        jobFinanceDepositPaidFullEvent,
        jobFinanceInReviewEvent,
        jobFinanceRejectedEvent,
        satisfactionNoteRejectedEvent,
        satisfactionNoteSignedEvent,
    ]);
    var payDepositEvent = useMemo(function () {
        // deposit, deposit not paid, quote accepted or if job finance then job finance signed or rejected
        if (!!job.depositAmount &&
            !isDepositAttemptedPaid(job, jobPayments) &&
            ((job === null || job === void 0 ? void 0 : job.acceptedAt) ||
                (jobFinanceInformation
                    ? hasCustomerAgreementSignedFinanceApplication(jobFinanceInformation) || hasRejectedFinanceApplication(jobFinanceInformation)
                    : false)))
            return {
                icon: React.createElement(FontAwesomeIcon, { className: "icon", icon: faPoundSign }),
                description: 'Pay deposit',
            };
    }, [job, jobFinanceInformation, jobPayments]);
    var signFinanceAgreement = useMemo(function () {
        if (!isPendingFinanceApplicationDecision(jobFinanceInformation) &&
            isPendingCustomerAgreementSigned(jobFinanceInformation)) {
            return {
                icon: signIcon,
                description: 'Sign finance agreement',
                showLenderHelp: true,
            };
        }
    }, [jobFinanceInformation]);
    var provideInformationToLender = useMemo(function () {
        if (requiresCustomerActionFinanceApplication(jobFinanceInformation)) {
            return {
                icon: React.createElement(FontAwesomeIcon, { className: "icon", icon: faInfo }),
                description: 'The lender has requested further information in order to proceed with your application',
                showLenderHelp: true,
            };
        }
    }, [jobFinanceInformation]);
    var payInvoiceEvent = useMemo(function () {
        if (!isQuoteOrEstimate(job.status) &&
            !isInvoiceAttemptedPaid(job, jobPayments))
            return {
                icon: React.createElement(FontAwesomeIcon, { className: "icon", icon: faPoundSign }),
                description: 'Pay invoice',
            };
    }, [job, jobPayments]);
    var signPropositionEvent = useMemo(function () {
        // quotes/estimates, not accepted job and not submitted job finance or rejected job finance
        if (isQuoteOrEstimate(job.status) &&
            !(job === null || job === void 0 ? void 0 : job.acceptedAt) &&
            (!hasSubmittedFinanceApplication(jobFinanceInformation) ||
                hasRejectedFinanceApplication(jobFinanceInformation))) {
            var showApplyForFinance = job.showBespokeFinancePlans &&
                !hasRejectedFinanceApplication(jobFinanceInformation);
            return {
                icon: signIcon,
                description: "Review & sign" + (showApplyForFinance ? ' (or apply for finance)' : ''),
            };
        }
    }, [job, jobFinanceInformation]);
    var signSatisfactionNoteEvent = useMemo(function () {
        if (isSatisfactionNoteSignatureRequired(job, jobFinanceInformation)) {
            return {
                icon: signIcon,
                description: "Sign satisfaction note",
            };
        }
    }, [job, jobFinanceInformation]);
    var presentTimelineEvent = useMemo(function () {
        if (signPropositionEvent) {
            return signPropositionEvent;
        }
        if (provideInformationToLender) {
            return provideInformationToLender;
        }
        if (signFinanceAgreement) {
            return signFinanceAgreement;
        }
        if (payDepositEvent) {
            return payDepositEvent;
        }
        if (signSatisfactionNoteEvent) {
            return signSatisfactionNoteEvent;
        }
        if (payInvoiceEvent) {
            return payInvoiceEvent;
        }
        return null;
    }, [
        payDepositEvent,
        payInvoiceEvent,
        provideInformationToLender,
        signFinanceAgreement,
        signPropositionEvent,
        signSatisfactionNoteEvent,
    ]);
    var lenderHelp = useMemo(function () { return (React.createElement("span", { className: "lender-help-text", onClick: function () { return setShowLenderDetailsModal(true); } }, "View lender contact information")); }, []);
    return (React.createElement("div", { className: "job-overview-timeline-container" },
        React.createElement("div", { className: "job-overview-timeline-events" },
            React.createElement("div", { className: "present-events" }, presentTimelineEvent && (React.createElement(React.Fragment, null,
                React.createElement("h3", { className: "next-step-title" }, "Next step"),
                React.createElement(JobOverviewTimelineEvent, { icon: presentTimelineEvent.icon, name: presentTimelineEvent.description }, presentTimelineEvent.showLenderHelp && lenderHelp)))),
            React.createElement("div", { className: "past-events" }, pastTimelineEvents.map(function (timelineEvent, index) { return (React.createElement(JobOverviewTimelineEvent, { timestamp: timelineEvent.timestamp, icon: timelineEvent.icon, name: timelineEvent.name, key: "past-timeline-event-" + index },
                timelineEvent.description && (React.createElement("span", null, timelineEvent.description)),
                timelineEvent.showLenderHelp && lenderHelp)); }))),
        showLenderDetailsModal && (React.createElement(LenderHelpModal, { open: showLenderDetailsModal, onClose: function () { return setShowLenderDetailsModal(false); } }))));
};
export default JobOverviewTimeline;
