var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AccountActionTypes } from "./accountTypes";
export var initialState = {
    accountAccessInformation: null,
    isGettingAccountAccessInformation: false,
    requiresUpdateBusinessSettingsPrompt: false,
    emailTemplates: null,
    isGettingEmailTemplates: false,
    gettingEmailTemplatesError: null,
    isSendingDemoQuote: false,
    isDemoQuoteSentSuccessfully: undefined,
};
var accountReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        // ACCOUNT ACCESS INFORMATION
        case AccountActionTypes.REQUEST_GET_ACCOUNT_ACCESS_INFORMATION:
            return __assign(__assign({}, state), { isGettingAccountAccessInformation: true });
        case AccountActionTypes.GET_ACCOUNT_ACCESS_INFORMATION_FAILURE:
            return __assign(__assign({}, state), { accountAccessInformation: null, isGettingAccountAccessInformation: false });
        case AccountActionTypes.CLEAR_ACCOUNT_ACCESS_INFORMATION:
            return __assign(__assign({}, state), { accountAccessInformation: null });
        case AccountActionTypes.GET_ACCOUNT_ACCESS_INFORMATION_SUCCESS:
            return __assign(__assign({}, state), { accountAccessInformation: action.payload.accountAccessInformation, isGettingAccountAccessInformation: false });
        // UPDATE BUSINESS SETTINGS PROMPT
        case AccountActionTypes.SET_REQUIRES_UPDATE_BUSINESS_SETTINGS_PROMPT:
            return __assign(__assign({}, state), { requiresUpdateBusinessSettingsPrompt: action.payload.requiresUpdateBusinessSettingsPrompt });
        // EMAIL TEMPLATES
        case AccountActionTypes.REQUEST_GET_EMAIL_TEMPLATES:
            return __assign(__assign({}, state), { isGettingEmailTemplates: true, gettingEmailTemplatesError: null });
        case AccountActionTypes.GET_EMAIL_TEMPLATES_SUCCESS:
            return __assign(__assign({}, state), { isGettingEmailTemplates: false, gettingEmailTemplatesError: null });
        case AccountActionTypes.GET_EMAIL_TEMPLATES_FAILURE:
            return __assign(__assign({}, state), { isGettingEmailTemplates: false, gettingEmailTemplatesError: action.payload.errorMessage });
        case AccountActionTypes.STORE_EMAIL_TEMPLATES:
            return __assign(__assign({}, state), { emailTemplates: action.payload.emailTemplates });
        case AccountActionTypes.CLEAR_EMAIL_TEMPLATES:
            return __assign(__assign({}, state), { emailTemplates: null, gettingEmailTemplatesError: null, isGettingEmailTemplates: false });
        // SENDING DEMO QUOTE
        case AccountActionTypes.REQUEST_SEND_DEMO_QUOTE:
            return __assign(__assign({}, state), { isSendingDemoQuote: true, isDemoQuoteSentSuccessfully: undefined });
        case AccountActionTypes.SEND_DEMO_QUOTE_SUCCESS:
            return __assign(__assign({}, state), { isSendingDemoQuote: false, isDemoQuoteSentSuccessfully: true });
        case AccountActionTypes.SEND_DEMO_QUOTE_FAILURE:
            return __assign(__assign({}, state), { isSendingDemoQuote: false, isDemoQuoteSentSuccessfully: false });
        default:
            return state;
    }
};
export default accountReducer;
