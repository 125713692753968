// ======================================================================================================
// Public routes config
// - Define public routes
// ======================================================================================================

import React from 'react';

import { Routes } from './RoutesConfig';

import Login from '@/ui/pages/loginPage/LoginPage';
import AppleCallback from '@/ui/pages/appleCallbackPage/AppleCallbackPage';
import XeroCallback from '@/ui/pages/xeroCallbackPage/XeroCallbackPage';
import Register from '@/ui/pages/registerPage/RegisterPage';
import ForgotPassword from '@/ui/pages/forgotPasswordPage/ForgotPasswordPage';
import ResetPassword from '@/ui/pages/resetPasswordPage/ResetPasswordPage';
import VerifyUser from '@/ui/pages/verifyUserPage/VerifyUserPage';
import UserInvite from '@/ui/pages/userInvitePage/UserInvitePage';
import EnableCustomerPayments from '@/ui/pages/enableCustomerPaymentsPage/EnableCustomerPaymentsPage';
import LoginOAuth from '@/ui/pages/loginOAuthPage/LoginOAuthPage';

const PublicRoutingConfig = {
  Routes: {
    [Routes.LOGIN]: {
      path: 'login',
      component: Login,
    },
    [Routes.LOGIN_OAUTH]: {
      path: 'oauth',
      component: LoginOAuth,
    },
    [Routes.APPLE_CALLBACK]: {
      path: 'apple/callback',
      component: AppleCallback,
    },
    [Routes.XERO_CALLBACK]: {
      path: 'xero/callback',
      component: XeroCallback,
    },
    [Routes.REGISTER]: {
      path: 'register',
      component: Register,
    },
    [Routes.FORGOT_PASSWORD]: {
      path: 'forgotPassword',
      component: ForgotPassword,
    },
    [Routes.RESET]: {
      path: 'reset/:token',
      component: ResetPassword,
    },
    [Routes.USER_INVITE]: {
      path: 'invite/:token',
      component: UserInvite,
    },
    [Routes.VERIFY_USER_ACCOUNT]: {
      path: 'verify/:token',
      renderComponent: (props) => <VerifyUser {...props} isAuthed={false} />,
    },
    [Routes.ENABLE_CUSTOMER_PAYMENTS]: {
      path: 'enableCustomerPayments',
      component: EnableCustomerPayments,
    },
  },
  Redirects: {
    TO_LOGIN: {
      fromPaths: ['*'],
      toPath: 'login',
      component: Login,
    },
  },
};

export { PublicRoutingConfig };
