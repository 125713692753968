import React, { useMemo } from 'react';
import { Button } from '@payaca/components/button';
import { JobStatus } from '@payaca/types/jobTypes';
import { isQuote } from '@/helpers/jobHelper';
import './JobStatusControl.sass';
var JobStatusControl = function (_a) {
    var jobStatus = _a.jobStatus, onFieldChange = _a.onFieldChange;
    var jobIsQuote = useMemo(function () {
        return isQuote(jobStatus);
    }, [jobStatus]);
    return (React.createElement("div", { className: "job-status-control" },
        React.createElement("p", null, "Send as"),
        React.createElement("div", { className: "flex-container flex-center" },
            React.createElement(Button, { isOutlined: jobIsQuote ? false : true, onClick: function () { return onFieldChange({ status: JobStatus.NEW_QUOTE }); } }, "Quote"),
            React.createElement(Button, { isOutlined: jobIsQuote ? true : false, onClick: function () { return onFieldChange({ status: JobStatus.NEW_ESTIMATE }); } }, "Estimate"))));
};
export default JobStatusControl;
