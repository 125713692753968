export var ActionType;
(function (ActionType) {
    ActionType["REQUEST_GET_DEAL"] = "deals.requestGetDeal";
    ActionType["GET_DEAL_SUCCESS"] = "deals.getDealSuccess";
    ActionType["GET_DEAL_FAILURE"] = "deals.getDealFailure";
    ActionType["CLEAR_DEAL"] = "deals.clearDeal";
    ActionType["CLEAR_DEALS"] = "deals.clearDeals";
    ActionType["REQUEST_ACTIVATE_DEAL_VIEW"] = "deals.requestActivateDealView";
    ActionType["ACTIVATE_DEAL_VIEW_SUCCESS"] = "deals.activateDealViewSuccess";
    ActionType["ACTIVATE_DEAL_VIEW_FAILURE"] = "deals.activateDealViewFailure";
    ActionType["REQUEST_CREATE_DEAL"] = "deals.requestCreateDeal";
    ActionType["CREATE_DEAL_SUCCESS"] = "deals.createDealSuccess";
    ActionType["CREATE_DEAL_FAILURE"] = "deals.createDealFailure";
    ActionType["REQUEST_UPDATE_DEAL"] = "deals.requestUpdateDeal";
    ActionType["UPDATE_DEAL_SUCCESS"] = "deals.updateDealSuccess";
    ActionType["UPDATE_DEAL_FAILURE"] = "deals.updateDealFailure";
    ActionType["REQUEST_SET_DEAL_CUSTOMER"] = "deals.requestSetDealCustomer";
    ActionType["SET_DEAL_CUSTOMER_SUCCESS"] = "deals.setDealCustomerSuccess";
    ActionType["SET_DEAL_CUSTOMER_FAILURE"] = "deals.setDealCustomerFailure";
    ActionType["REQUEST_UNSET_DEAL_CUSTOMER"] = "deals.requestUnsetDealCustomer";
    ActionType["UNSET_DEAL_CUSTOMER_SUCCESS"] = "deals.unsetDealCustomerSuccess";
    ActionType["UNSET_DEAL_CUSTOMER_FAILURE"] = "deals.unsetDealCustomerFailure";
    ActionType["REQUEST_GET_LISTED_DEALS_PAGE"] = "deals.requestGetListedDealsPage";
    ActionType["GET_LISTED_DEALS_PAGE_SUCCESS"] = "deals.getListedDealsPageSuccess";
    ActionType["GET_LISTED_DEALS_PAGE_FAILURE"] = "deals.getListedDealsPageFailure";
    ActionType["CLEAR_LISTED_DEALS_PAGE"] = "deals.clearListedDealsPage";
    ActionType["REQUEST_ARCHIVE_DEALS"] = "deals.requestArchiveDeals";
    ActionType["ARCHIVE_DEALS_SUCCESS"] = "deals.archiveDealsSuccess";
    ActionType["ARCHIVE_DEALS_FAILURE"] = "deals.archiveDealsFailure";
})(ActionType || (ActionType = {}));
