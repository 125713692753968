import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { PhoneNumberField } from '@payaca/components/phoneNumberField';
import { useDispatch } from 'react-redux';
import './LoqateAdvisoryPhoneInputField.sass';
import { actions as usersActions } from '@/api/users';
import { faCheck, faCircleNotch, faTimes, } from '@fortawesome/free-solid-svg-icons';
var LoqateAdvisoryPhoneInputField = function (_a) {
    var styleVariant = _a.styleVariant, name = _a.name, value = _a.value, label = _a.label, description = _a.description, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, _c = _a.isRequired, isRequired = _c === void 0 ? false : _c, iconBefore = _a.iconBefore, onChange = _a.onChange, onTouch = _a.onTouch, _d = _a.disableValidation, disableValidation = _d === void 0 ? false : _d;
    var dispatch = useDispatch();
    var _e = useState(), loqateValidationTimeout = _e[0], setLoqateValidationTimeout = _e[1];
    var _f = useState(false), isValidating = _f[0], setIsValidating = _f[1];
    var _g = useState(), isValid = _g[0], setIsValid = _g[1];
    useEffect(function () {
        return function () {
            setLoqateValidationTimeout(function (loqateValidationTimeout) {
                loqateValidationTimeout && clearTimeout(loqateValidationTimeout);
                return undefined;
            });
        };
    }, []);
    var runLoqateValidation = useCallback(function (value) {
        if (!value || value === '') {
            setIsValid(undefined);
            return;
        }
        setIsValidating(true);
        dispatch(usersActions.validatePhone(value, function (error, response) {
            setIsValidating(false);
            if (error) {
                setIsValid(undefined);
            }
            else {
                setIsValid(response);
            }
        }));
    }, [dispatch]);
    var iconAfter = useMemo(function () {
        if (isValidating)
            return faCircleNotch;
        if (isValid === true)
            return faCheck;
        if (isValid === false)
            return faTimes;
    }, [isValidating, isValid]);
    var validationResult = useMemo(function () {
        if (isValidating || isValid === undefined)
            return;
        return {
            isValid: isValid,
            errors: isValid
                ? undefined
                : [
                    'This phone number looks invalid to us, please double check before continuing',
                ],
        };
    }, [isValidating, isValid]);
    useEffect(function () {
        if (!disableValidation) {
            setLoqateValidationTimeout(function (loqateValidationTimeout) {
                loqateValidationTimeout && clearTimeout(loqateValidationTimeout);
                return setTimeout(function () {
                    runLoqateValidation(value);
                }, 500);
            });
        }
    }, [value, runLoqateValidation, disableValidation]);
    useEffect(function () {
        if (disableValidation) {
            setIsValidating(false);
            setIsValid(undefined);
            setLoqateValidationTimeout(function (loqateValidationTimeout) {
                loqateValidationTimeout && clearTimeout(loqateValidationTimeout);
                return undefined;
            });
        }
    }, [disableValidation]);
    return (React.createElement("div", { className: "loqate-advisory-phone-input-field" },
        React.createElement(ValidatedFieldWrapper, { isTouched: value && value !== '', validationResult: validationResult },
            React.createElement(PhoneNumberField, { styleVariant: styleVariant, name: name, value: value, label: label, description: description, isDisabled: isDisabled, isRequired: isRequired, iconBefore: iconBefore, onChange: onChange, onTouch: onTouch, iconAfter: iconAfter }))));
};
export default LoqateAdvisoryPhoneInputField;
