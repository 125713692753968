import React from 'react';
import LabelValuePair from '../labelValuePair/LabelValuePair';
import SubsidisedFinanceDataProtectionDeclaration from '../subsidisedFinanceDataProtectionDeclaration/SubsidisedFinanceDataProtectionDeclaration';
var AuthorityToSearchOverview = function (_a) {
    var authorityToSearch = _a.authorityToSearch;
    return (React.createElement("div", { className: "authority-to-search-overview" },
        React.createElement("div", null,
            React.createElement(LabelValuePair, { label: "Bank name", value: (authorityToSearch === null || authorityToSearch === void 0 ? void 0 : authorityToSearch.bankName) || '' }),
            React.createElement(LabelValuePair, { label: "Sort code", value: (authorityToSearch === null || authorityToSearch === void 0 ? void 0 : authorityToSearch.sortCode) || '' }),
            React.createElement(LabelValuePair, { label: "Account number", value: (authorityToSearch === null || authorityToSearch === void 0 ? void 0 : authorityToSearch.accountNumber) || '' }),
            React.createElement(LabelValuePair, { label: "Name on account", value: (authorityToSearch === null || authorityToSearch === void 0 ? void 0 : authorityToSearch.accountName) || '' })),
        React.createElement("div", null,
            React.createElement(SubsidisedFinanceDataProtectionDeclaration, null),
            React.createElement(LabelValuePair, { label: "I have read and agreed to the above", value: (authorityToSearch === null || authorityToSearch === void 0 ? void 0 : authorityToSearch.hasReadAndAcceptedAuthorityToSearch) ? 'YES'
                    : 'NO' }))));
};
export default AuthorityToSearchOverview;
