var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useMemo, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JobAssignmentControl from '../jobAssignmentControl/JobAssignmentControl';
import { actions as jobsActions } from '@bit/payaca-tech.payaca-core.store.jobs';
import './PreviewJobHeader.sass';
import { getJob, getJobPaymentsByJobId, getActiveJobFinanceInformationByJobId, } from '@/utils/stateAccessors';
import { getJobType, getReadableJobStatus } from '@payaca/helpers/jobHelperV2';
import { getReadableJobStatusStyle } from '@payaca/helpers/jobHelper';
import { Badge } from '@payaca/components/badge';
import { ResponsiveViewWrapper } from '@payaca/components/responsiveViewWrapper';
var PreviewJobHeader = function (_a) {
    var jobId = _a.jobId;
    var dispatch = useDispatch();
    var job = useSelector(function (state) {
        return getJob(state, jobId);
    });
    var onJobUpdateSuccess = useCallback(function () {
        dispatch(jobsActions.requestGetJob(jobId));
    }, [dispatch, jobId]);
    var jobType = useMemo(function () {
        if (!job)
            return;
        return getJobType(job);
    }, [job]);
    var jobTitle = useMemo(function () {
        if (!job)
            return;
        return jobType + " #" + (job.customReference || job.reference);
    }, [job, jobType]);
    var jobPayments = useSelector(function (state) {
        return getJobPaymentsByJobId(state, jobId);
    });
    var activeJobFinanceInformation = useSelector(function (state) {
        return getActiveJobFinanceInformationByJobId(state, jobId);
    });
    var readableJobStatusElement = useMemo(function () {
        if (!job)
            return;
        var readableJobStatus = getReadableJobStatus(job, jobPayments, activeJobFinanceInformation);
        var readableJobStatusStyle = getReadableJobStatusStyle(readableJobStatus);
        return React.createElement(Badge, __assign({}, readableJobStatusStyle), readableJobStatus);
    }, [job, jobPayments]);
    return (React.createElement(ResponsiveViewWrapper, { className: "preview-job-header flex-container flex-center", downBreakpointSm: 600 },
        React.createElement("div", { className: "status-container" }, readableJobStatusElement),
        React.createElement("h1", null, jobTitle),
        React.createElement("div", { className: "assignment-control-container" },
            React.createElement(JobAssignmentControl, { jobId: jobId, onJobUpdateSuccess: onJobUpdateSuccess }))));
};
export default PreviewJobHeader;
