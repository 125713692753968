import React, { useCallback, useMemo } from 'react';
import { FeedbackLevel, FeedbackMessage, } from '@payaca/components/feedbackMessage';
import './PasswordValidationFeedback.sass';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { minimumLengthFieldValidator, mustContainLetterFieldValidator, mustContainNumberFieldValidator, minimumLengthValidationMessage, mustContainLetterValidationMessage, mustContainNumberValidationMessage, } from '@bit/payaca-tech.payaca-core.helpers.password-validation';
var PasswordValidationFeedback = function (_a) {
    var password = _a.password;
    var renderValidationFeedbackMessage = useCallback(function (validationFeedbackMessage, isValidationPassed) {
        return (React.createElement(FeedbackMessage, { message: validationFeedbackMessage, feedbackLevel: isValidationPassed ? FeedbackLevel.SUCCESS : undefined, iconBefore: isValidationPassed ? faCheck : faTimes }));
    }, []);
    var minimumLengthValidationResult = useMemo(function () {
        return minimumLengthFieldValidator('password', {
            password: password,
        });
    }, [password]);
    var mustContainNumberValidationResult = useMemo(function () {
        return mustContainNumberFieldValidator('password', {
            password: password,
        });
    }, [password]);
    var mustContainLetterValidationResult = useMemo(function () {
        return mustContainLetterFieldValidator('password', {
            password: password,
        });
    }, [password]);
    return (React.createElement("div", { className: "password-validation-feedback" },
        renderValidationFeedbackMessage(minimumLengthValidationMessage, minimumLengthValidationResult.isValid),
        renderValidationFeedbackMessage(mustContainLetterValidationMessage, mustContainLetterValidationResult.isValid),
        renderValidationFeedbackMessage(mustContainNumberValidationMessage, mustContainNumberValidationResult.isValid)));
};
export default PasswordValidationFeedback;
