import React, { useMemo } from 'react';
import './Badge.sass';
var Badge = function (_a) {
    var children = _a.children, backgroundColour = _a.backgroundColour, colour = _a.colour;
    var badgeStyle = useMemo(function () {
        var style = {};
        if (backgroundColour) {
            style.backgroundColor = backgroundColour;
        }
        if (colour) {
            style.color = colour;
        }
        return style;
    }, [colour, backgroundColour]);
    return (React.createElement("span", { className: "badge", style: badgeStyle }, children));
};
export default Badge;
