import React from 'react';
import moment from 'moment';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { MiniLoader } from '@payaca/components';
import './SubscriptionPaymentPreview.sass';
var SubscriptionPaymentPreview = function (_a) {
    var subscriptionPaymentPreview = _a.subscriptionPaymentPreview, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b;
    if (isLoading) {
        return (React.createElement("div", { className: "subscription-payment-preview" },
            React.createElement("div", { className: "loader-container" },
                React.createElement(MiniLoader, null))));
    }
    else if (subscriptionPaymentPreview) {
        return (React.createElement("div", { className: "subscription-payment-preview" },
            React.createElement("p", null,
                "If you proceed, you will be charged",
                ' ',
                React.createElement("strong", null, currencyPrice(subscriptionPaymentPreview.immediateTotal)),
                ' ',
                "today"),
            React.createElement("p", null,
                "Your next payment of",
                ' ',
                React.createElement("strong", null, currencyPrice(subscriptionPaymentPreview.nextInvoiceTotal)),
                ' ',
                "will be taken on",
                ' ',
                React.createElement("strong", null, moment(subscriptionPaymentPreview.nextInvoiceAt).format('DD MMM YYYY')))));
    }
    return null;
};
export default SubscriptionPaymentPreview;
