var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SubscriptionActionTypes, } from "./subscriptionTypes";
export var initialState = {
    products: null,
    isGettingProducts: false,
    accountSubscription: null,
    isGettingAccountSubscription: false,
    isCreatingSubscription: false,
    isUpdatingSubscription: false,
    isRestoringSubscription: false,
    isUpdatingPaymentMethod: false,
    isSubscriptionCreatedSuccessfully: undefined,
    isSubscriptionUpdatedSuccessfully: undefined,
    isSubscriptionRestoredSuccessfully: undefined,
    isPaymentMethodUpdatedSuccessfully: undefined,
    isGettingSubscriptionPaymentPreview: false,
    subscriptionPaymentPreview: undefined,
};
var subscriptionReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SubscriptionActionTypes.REQUEST_GET_PRODUCTS:
            return __assign(__assign({}, state), { isGettingProducts: true });
        case SubscriptionActionTypes.GET_PRODUCTS_FAILURE:
            return __assign(__assign({}, state), { products: null, isGettingProducts: false });
        case SubscriptionActionTypes.CLEAR_PRODUCTS:
            return __assign(__assign({}, state), { products: null });
        case SubscriptionActionTypes.GET_PRODUCTS_SUCCESS:
            return __assign(__assign({}, state), { products: action.payload.products, isGettingProducts: false });
        case SubscriptionActionTypes.REQUEST_GET_ACCOUNT_SUBSCRIPTION:
            return __assign(__assign({}, state), { isGettingAccountSubscription: true });
        case SubscriptionActionTypes.GET_ACCOUNT_SUBSCRIPTION_FAILURE:
            return __assign(__assign({}, state), { accountSubscription: null, isGettingAccountSubscription: false });
        case SubscriptionActionTypes.CLEAR_ACCOUNT_SUBSCRIPTION:
            return __assign(__assign({}, state), { accountSubscription: null });
        case SubscriptionActionTypes.GET_ACCOUNT_SUBSCRIPTION_SUCCESS:
            return __assign(__assign({}, state), { accountSubscription: action.payload.accountSubscription, isGettingAccountSubscription: false });
        case SubscriptionActionTypes.REQUEST_CREATE_SUBSCRIPTION:
            return __assign(__assign({}, state), { isSubscriptionCreatedSuccessfully: undefined, isCreatingSubscription: true });
        case SubscriptionActionTypes.CREATE_SUBSCRIPTION_SUCCESS:
            return __assign(__assign({}, state), { isSubscriptionCreatedSuccessfully: true, isCreatingSubscription: false });
        case SubscriptionActionTypes.CREATE_SUBSCRIPTION_FAILURE:
            return __assign(__assign({}, state), { isSubscriptionCreatedSuccessfully: false, isCreatingSubscription: false });
        case SubscriptionActionTypes.REQUEST_UPDATE_SUBSCRIPTION:
            return __assign(__assign({}, state), { isSubscriptionUpdatedSuccessfully: undefined, isUpdatingSubscription: true });
        case SubscriptionActionTypes.UPDATE_SUBSCRIPTION_SUCCESS:
            return __assign(__assign({}, state), { isSubscriptionUpdatedSuccessfully: true, isUpdatingSubscription: false });
        case SubscriptionActionTypes.UPDATE_SUBSCRIPTION_FAILURE:
            return __assign(__assign({}, state), { isSubscriptionUpdatedSuccessfully: false, isUpdatingSubscription: false });
        case SubscriptionActionTypes.REQUEST_RESTORE_SUBSCRIPTION:
            return __assign(__assign({}, state), { isSubscriptionRestoredSuccessfully: undefined, isRestoringSubscription: true });
        case SubscriptionActionTypes.RESTORE_SUBSCRIPTION_SUCCESS:
            return __assign(__assign({}, state), { isSubscriptionRestoredSuccessfully: true, isRestoringSubscription: false });
        case SubscriptionActionTypes.RESTORE_SUBSCRIPTION_FAILURE:
            return __assign(__assign({}, state), { isSubscriptionRestoredSuccessfully: false, isRestoringSubscription: false });
        case SubscriptionActionTypes.REQUEST_UPDATE_PAYMENT_METHOD:
            return __assign(__assign({}, state), { isPaymentMethodUpdatedSuccessfully: undefined, isUpdatingPaymentMethod: true });
        case SubscriptionActionTypes.UPDATE_PAYMENT_METHOD_SUCCESS:
            return __assign(__assign({}, state), { isPaymentMethodUpdatedSuccessfully: true, isUpdatingPaymentMethod: false });
        case SubscriptionActionTypes.UPDATE_PAYMENT_METHOD_FAILURE:
            return __assign(__assign({}, state), { isPaymentMethodUpdatedSuccessfully: false, isUpdatingPaymentMethod: false });
        case SubscriptionActionTypes.CLEAR_SUBSCRIPTION_PAYMENT_PREVIEW:
            return __assign(__assign({}, state), { subscriptionPaymentPreview: undefined });
        case SubscriptionActionTypes.REQUEST_GET_SUBSCRIPTION_PAYMENT_PREVIEW:
            return __assign(__assign({}, state), { subscriptionPaymentPreview: undefined, isGettingSubscriptionPaymentPreview: true });
        case SubscriptionActionTypes.GET_SUBSCRIPTION_PAYMENT_PREVIEW_SUCCESS:
            return __assign(__assign({}, state), { subscriptionPaymentPreview: action.payload.subscriptionPaymentPreview, isGettingSubscriptionPaymentPreview: false });
        case SubscriptionActionTypes.GET_SUBSCRIPTION_PAYMENT_PREVIEW_FAILURE:
            return __assign(__assign({}, state), { subscriptionPaymentPreview: undefined, isGettingSubscriptionPaymentPreview: false });
        default:
            return state;
    }
};
export default subscriptionReducer;
