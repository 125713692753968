import React from 'react';
import SplitPageWrapper from '../splitPageWrapper/SplitPageWrapper';
import { ContentCarousel } from '@payaca/components/contentCarousel';
import './UnauthenticatedPageWrapper.sass';
var UnauthenticatedPageWrapper = function (_a) {
    var children = _a.children, className = _a.className, headerContent = _a.headerContent, titleContent = _a.titleContent;
    return (React.createElement(SplitPageWrapper, { className: "unauthenticated-page-wrapper " + (className ? className : ''), rhsContent: React.createElement(React.Fragment, null,
            headerContent && (React.createElement("div", { className: "header-content" }, headerContent)),
            React.createElement("div", { className: "body-content" },
                titleContent && (React.createElement("div", { className: "title-content" }, titleContent)),
                children)), lhsContent: React.createElement(React.Fragment, null,
            React.createElement("div", { className: "header-content" },
                React.createElement("img", { className: "payaca-logo", src: "https://storage.googleapis.com/payaca-prod-assets/payaca-logo.png" })),
            React.createElement("div", { className: "body-content" },
                React.createElement(ContentCarousel, { autoPlay: true },
                    React.createElement("div", { className: "carousel-slide" },
                        React.createElement("img", { src: require('./img-1.png').default }),
                        React.createElement("h2", null, "Keep track of your growth"),
                        React.createElement("p", null, "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.")),
                    React.createElement("div", { className: "carousel-slide" },
                        React.createElement("img", { src: require('./img-1.png').default }),
                        React.createElement("h2", null, "Keep track of your growth"),
                        React.createElement("p", null, "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.")),
                    React.createElement("div", { className: "carousel-slide" },
                        React.createElement("img", { src: require('./img-1.png').default }),
                        React.createElement("h2", null, "Keep track of your growth"),
                        React.createElement("p", null, "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."))))) }));
};
export default UnauthenticatedPageWrapper;
