import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './Modal.css';
var ModalWrapper = function (_a) {
    var children = _a.children, classes = _a.classes, headerContent = _a.headerContent, icon = _a.icon, onClose = _a.onClose, open = _a.open, showCloseIcon = _a.showCloseIcon, size = _a.size, title = _a.title;
    var titleClass = 'title' + (classes ? " " + classes : '');
    var contentClass = 'content' + (classes ? " " + classes : '');
    return (React.createElement(Dialog, { open: !!open, onClose: function () { return onClose && onClose(); }, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", fullWidth: true, maxWidth: size || 'sm' },
        icon && (React.createElement("div", { style: { textAlign: 'center' } },
            React.createElement("img", { style: { maxWidth: 100, maxHeight: 100, marginTop: 20 }, alt: "Modal icon", src: icon }))),
        React.createElement("div", { className: "modal-header-wrapper" },
            React.createElement(DialogTitle, { className: titleClass, id: "alert-dialog-title" }, title),
            headerContent,
            showCloseIcon && (React.createElement("div", { className: "close-icon" },
                React.createElement(FontAwesomeIcon, { icon: faTimes, onClick: onClose })))),
        React.createElement(DialogContent, { className: contentClass }, children)));
    // }
};
export default ModalWrapper;
