// ======================================================================================================
// Table helper functions
// ======================================================================================================
import React from 'react';
import get from 'lodash.get';

import { getKeyByValue, StringUtil } from '@/utils';

import { currencyPrice } from '@/helpers/financeHelper';
import { isInvoice, isSentQuoteEstimate, JobStatus } from '@/helpers/jobHelper';
import { userRole, userStatus } from '@/helpers/userHelper';
import { jobHasSent } from '@bit/payaca-tech.payaca-core.helpers.job-status';
import { getReadableJobStatusStyle } from '@bit/payaca-tech.payaca-core.helpers.job';

import XeroLogo from './xero.svg';
import QuickbooksLogo from './quickbooks.svg';

// ===================== Table sorting =====================
// Defines the sorting config of properties (Could be improved #bit-gross)
const Sorting = {
  'Valid until': {
    sort: 'date',
    raw: true,
  },
  'Due at': {
    sort: 'date',
    raw: true,
  },
  'Sent at': {
    sort: 'date',
    raw: true,
  },
  'Price (ex VAT)': {
    raw: true,
  },
  'Cost price': {
    raw: true,
  },
  'Total (inc VAT)': {
    raw: true,
  },
};
const getSortConfig = (sortBy) => Sorting[sortBy];

// ===================== Formatting functions =====================
// Aside from `columns.name`, all values should be the path to the nested proprty i.e. customer.name
const Config = {
  customers: {
    search: {
      searchPlaceholder: 'Search by anything',
    },
    modalReference: ['customer', 'customers'],
    initialSortBy: 'Name',
    bulkActions: (profile) =>
      getAllowedBulkActions(profile, AllBulkGeneralActions),
    columns: [
      {
        name: 'Name',
        target: 'name',
      },
      {
        name: 'Email',
        target: 'email',
      },
      {
        name: 'Phone',
        target: 'contactNumber',
      },
      {
        name: 'Address',
        target: 'address',
      },
      {
        name: 'Import',
        targets: ['xeroCustomerId', 'quickbooksCustomerId'],
        format: (connectionValues) => {
          return (
            <div>
              {connectionValues.xeroCustomerId && (
                <img
                  alt="Xero"
                  style={{ height: 20, width: 20, margin: '0 3px' }}
                  src={XeroLogo}
                />
              )}
              {connectionValues.quickbooksCustomerId && (
                <img
                  alt="Xero"
                  style={{ height: 20, width: 20, margin: '0 3px' }}
                  src={QuickbooksLogo}
                />
              )}
            </div>
          );
        },
      },
    ],
  },
  items: {
    tableClassName: 'items',
    search: {
      searchBy: ['name', 'description'],
      searchPlaceholder: 'Search by item name or description',
    },
    modalReference: ['item', 'items'],
    initialSortBy: 'Description',
    bulkActions: (profile) =>
      getAllowedBulkActions(profile, AllBulkGeneralActions),
    disableRow: (data, payload) => {
      return !!(
        payload.disableData &&
        payload.disableData.find((item) => {
          // id item row matches id of an existing item
          return data.id === (item.lineItemId || item.id);
        })
      );
    },
    columns: [
      {
        name: 'Id',
        target: 'id',
        hidden: true,
      },
      {
        name: 'Item reference',
        target: 'name',
      },
      {
        name: 'Description',
        target: 'description',
        classes: 'description',
      },
      {
        name: 'Price (ex VAT)',
        target: 'price',
        format: (price) => currencyPrice(price),
        classes: 'price',
      },
      {
        name: 'Cost price',
        target: 'supplierPrice',
        format: (price) => price && currencyPrice(price),
        classes: 'price',
      },
      {
        name: 'VAT',
        target: 'vatTotal',
      },
      {
        name: 'Total (inc VAT)',
        target: 'total',
        classes: 'price',
        format: (price) => price && currencyPrice(price),
      },
      {
        name: 'Import',
        targets: ['xeroItemId', 'quickbooksItemId'],
        format: (connectionValues) => {
          return (
            <div>
              {connectionValues.xeroItemId && (
                <img
                  alt="Xero"
                  style={{ height: 20, width: 20, margin: '0 3px' }}
                  src={XeroLogo}
                />
              )}
              {connectionValues.quickbooksItemId && (
                <img
                  alt="Xero"
                  style={{ height: 20, width: 20, margin: '0 3px' }}
                  src={QuickbooksLogo}
                />
              )}
            </div>
          );
        },
      },
    ],
  },
  users: {
    search: {
      searchBy: ['name'],
      searchPlaceholder: 'Search by user name',
    },
    singleQuickActions: (data, profile, actions) =>
      getAllowedSingleUserActions(data, profile, actions),
    bulkActions: (profile) =>
      getAllowedBulkActions(profile, AllBulkUserActions),
    initialSortBy: 'Name',
    modalReference: ['user', 'users'],
    disableRow: (data, payload) => data.id === payload.profile.id, // disable row actions for own user data
    columns: [
      {
        name: 'Name',
        target: 'name',
      },
      {
        name: 'Email',
        target: 'email',
      },
      {
        name: 'Status',
        target: 'inviteToken', // check deactivatedAt, active, invited
        format: (inviteToken) => userStatus(inviteToken),
      },
      {
        name: 'Role',
        target: 'isAdmin', // check deactivatedAt, active, invited
        format: (isAdmin) => userRole(isAdmin),
      },
    ],
  },
};

const getTableConfig = (configName) => {
  return Config[configName];
};

// jobHelper file loads after this, so defining in an object leads to JobStatus as undefined, hence why its nested in a function
const QuickActionJobStatus = () => {
  return {
    ...JobStatus,
    ACCEPTED: 'accepted',
    DECLINED: 'declined',
    BOUNCED_QUOTE: 'bounced_quote',
    BOUNCED_INVOICE: 'bounced_invoice',
    QUOTED_WITH_DEPOSIT: 'quoted_with_deposit',
    ACCEPTED_WITH_DEPOSIT: 'accepted_with_deposit',
    ZERO_PERCENT_FINANCE: 'zero_percent_finance',
  };
};

const QuickActions = {
  SEND: 'send',
  RESEND: 'resend',
  DUPLICATE: 'duplicate',
  INVOICE: 'invoice',
  MARK_AS_ACCEPTED: 'markAsAccepted',
  MARK_AS_DECLINED: 'markAsDeclined',
  DELETE: 'delete',
  ASSIGN_JOB: 'assignJob',
  UNASSIGN_JOB: 'unassignJob',
  CHANGE_ROLE: 'changeRole',
};

const RoleActions = {
  adminActions: [QuickActions.ASSIGN_JOB, QuickActions.UNASSIGN_JOB],
};

/**
 * Filter actions by allowed actions provided
 * @param {array} allowedActionNames names of actions dependent on data status
 * @return {object}
 */
const _getAllowedActionsByNames = (allActions, allowedActionNames) => {
  return Object.keys(allActions)
    .filter((key) => allowedActionNames && allowedActionNames.includes(key))
    .reduce((obj, key) => {
      obj[key] = allActions[key];
      return obj;
    }, {});
};

/**
 * Filter out any actions not available to user role
 * @param {array} actions list of actions
 * @param {object} profile user profile
 */
const _filterActionByRole = (actions, profile) => {
  const isAdmin = !!profile.isAdmin;
  let allowedActions = actions;
  // admin should have all actions available
  if (!isAdmin) {
    allowedActions = Object.entries(allowedActions).reduce((acc, [k, v]) => {
      if (!RoleActions.adminActions.find((adminAction) => adminAction === k)) {
        acc[k] = v;
      }
      return acc;
    }, {});
  }

  return allowedActions;
};

/**
 * Create actions object to use in Table
 * @param {object} dataItem data item getting quick actions for i.e. job
 * @param {object} allowedActions allowed actions for a job
 * @param {object} actionFunctions functions for actions passed in from table parent componet
 * @return {object}
 */
const _getActionObject = (dataItem, allowedActions, actionFunctions) => {
  // filter out any quick actions without a relating function provided in Table parent component and create action object
  return Object.entries(allowedActions).reduce((acc, [key, title]) => {
    const func = actionFunctions && actionFunctions[key];
    return !func
      ? acc
      : acc.concat({ name: title, onClick: () => func(dataItem.id) });
  }, []);
};

const AllBulkGeneralActions = {
  [QuickActions.DELETE]: 'Delete',
};

/**
 * Get single actions available to user and depenedent on job
 * @param {object} dataItem data item getting quick actions for i.e. job
 * @param {object} profile user profile
 * @param {object} actionFunctions functions for actions passed in from table parent componet
 */

// =========== users
const AllSingleUserActions = {
  [QuickActions.DELETE]: 'Delete',
  [QuickActions.CHANGE_ROLE]: 'Change role',
};

const AllBulkUserActions = {
  [QuickActions.DELETE]: 'Delete',
  [QuickActions.CHANGE_ROLE]: 'Change role',
};

/**
 * Get single actions available to edit users
 * @param {object} dataItem data item getting quick actions for i.e. job
 * @param {object} profile user profile
 * @param {object} actionFunctions functions for actions passed in from table parent componet
 */
const getAllowedSingleUserActions = (dataItem, profile, actionFunctions) => {
  const allowedActionNames = [QuickActions.DELETE, QuickActions.CHANGE_ROLE];

  // filter all job actions by allowed actions provided
  let allowedActions = _getAllowedActionsByNames(
    AllSingleUserActions,
    allowedActionNames
  );

  // filter action by user role
  allowedActions = _filterActionByRole(allowedActions, profile);

  // get formatted action object
  return _getActionObject(dataItem, allowedActions, actionFunctions);
};

/**
 * Get bulk actions available to user
 * @param {object} profile user profile
 */
const getAllowedBulkActions = (profile, allBulkActions) => {
  // filter out role actions
  return _filterActionByRole(allBulkActions, profile);
};

export { getAllowedBulkActions, getSortConfig, getTableConfig, QuickActions };
