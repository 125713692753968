import React from 'react';
import { ResponsiveViewWrapper } from '@payaca/components/responsiveViewWrapper';
import './PreviewJobControlWrapper.sass';
var PreviewJobControlWrapper = function (_a) {
    var className = _a.className, title = _a.title, previewContent = _a.previewContent, sidebarContent = _a.sidebarContent;
    return (React.createElement(ResponsiveViewWrapper, { downBreakpointSm: 700, className: "preview-job-control-wrapper " + (className ? className : '') },
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: "lhs" },
                title && React.createElement("div", { className: "title-bar" }, title),
                previewContent && (React.createElement("div", { className: "preview-content-outer" },
                    React.createElement("div", { className: "preview-content" }, previewContent)))),
            sidebarContent && (React.createElement("div", { className: "rhs" },
                React.createElement("div", { className: "sidebar-content" }, sidebarContent))))));
};
export default PreviewJobControlWrapper;
