var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import './DropdownField.sass';
import FieldLabel from '../fieldLabel/FieldLabel';
import InputWrapper from '../inputWrapper/InputWrapper';
import Checkbox from '../checkbox/Checkbox';
var DropdownField = function (_a) {
    var styleVariant = _a.styleVariant, colourVariant = _a.colourVariant, name = _a.name, value = _a.value, label = _a.label, description = _a.description, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, _c = _a.isRequired, isRequired = _c === void 0 ? false : _c, iconBefore = _a.iconBefore, iconAfter = _a.iconAfter, options = _a.options, optionGroups = _a.optionGroups, _d = _a.multiple, multiple = _d === void 0 ? false : _d, _e = _a.additionalSelectProps, additionalSelectProps = _e === void 0 ? {} : _e, onChange = _a.onChange, onTouch = _a.onTouch, _f = _a.hasBoxShadow, hasBoxShadow = _f === void 0 ? true : _f, _g = _a.displayCheckboxes, displayCheckboxes = _g === void 0 ? false : _g;
    var _h = useState(false), isOpen = _h[0], setIsOpen = _h[1];
    var onInputChange = useCallback(function (event) {
        var _a;
        onChange &&
            onChange((_a = {},
                _a[event.target.name] = event.target.value,
                _a));
    }, [onChange]);
    var renderOptions = useCallback(function (options) {
        return options.map(function (option, index) {
            return (React.createElement(MenuItem, { value: option.value, key: "option-" + index },
                displayCheckboxes && (React.createElement(Checkbox, { isChecked: Array.isArray(value)
                        ? value.includes(option.value)
                        : value === option.value, onChange: function () { return null; } })),
                option.label));
        });
    }, [displayCheckboxes, value]);
    return (React.createElement("div", { className: "dropdown-field" },
        React.createElement(FieldLabel, { label: label, isRequired: isRequired, description: description }),
        React.createElement(InputWrapper, { isDisabled: isDisabled, styleVariant: styleVariant, colourVariant: colourVariant, hasBoxShadow: hasBoxShadow },
            iconBefore && React.createElement(FontAwesomeIcon, { icon: iconBefore }),
            React.createElement(Select, __assign({ value: value, name: name, onChange: onInputChange, displayEmpty: true, disableUnderline: true, disabled: isDisabled, onClose: function () {
                    setIsOpen(false);
                    onTouch && onTouch(name);
                }, onOpen: function () { return setIsOpen(true); }, open: isOpen, multiple: multiple, MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                } }, additionalSelectProps),
                renderOptions(options.filter(function (option) { return !option.optionGroupId; })),
                optionGroups &&
                    optionGroups.map(function (optionGroup, index) {
                        return __spreadArrays([
                            React.createElement("div", { className: "option-group", key: "option-group-" + index },
                                React.createElement("hr", null),
                                optionGroup.name && (React.createElement("small", { className: "option-group-name" }, optionGroup.name)))
                        ], renderOptions(options.filter(function (option) { return option.optionGroupId === optionGroup.id; })));
                    })),
            iconAfter && React.createElement(FontAwesomeIcon, { icon: iconAfter }))));
};
export default DropdownField;
