import moment from 'moment';
import { JobStatus } from '@payaca/types/jobTypes';
var invoiceStatuses = [
    JobStatus.NEW_INVOICE,
    JobStatus.INVOICED,
    JobStatus.PAID,
];
var quoteStatuses = [
    JobStatus.NEW_QUOTE,
    JobStatus.QUOTED,
    JobStatus.ACCEPTED,
];
var estimateStatuses = [
    JobStatus.NEW_ESTIMATE,
    JobStatus.ESTIMATED,
];
var sentStatuses = [
    JobStatus.QUOTED,
    JobStatus.ESTIMATED,
    JobStatus.INVOICED,
    JobStatus.ACCEPTED,
    JobStatus.PAID,
];
export var isInvoice = function (jobStatus) {
    return invoiceStatuses.indexOf(jobStatus) > -1;
};
export var isQuote = function (jobStatus) {
    return quoteStatuses.indexOf(jobStatus) > -1;
};
export var isEstimate = function (jobStatus) {
    return estimateStatuses.indexOf(jobStatus) > -1;
};
export var isQuoteOrEstimate = function (jobStatus) {
    return !isInvoice(jobStatus);
};
export var jobHasSent = function (jobStatus) {
    return sentStatuses.indexOf(jobStatus) > -1;
};
export var isSentQuoteEstimate = function (jobStatus) {
    return jobStatus === JobStatus.QUOTED || jobStatus === JobStatus.ESTIMATED;
};
export var isInvoiceOverdue = function (invoiceDue, status) {
    return !!invoiceDue &&
        moment(invoiceDue).isBefore(moment()) &&
        status !== JobStatus.PAID &&
        status !== JobStatus.NEW_INVOICE;
};
