var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import './ContentCarousel.sass';
var ContentCarousel = function (_a) {
    var children = _a.children, _b = _a.autoPlay, autoPlay = _b === void 0 ? false : _b, additionalCarouselProps = _a.additionalCarouselProps;
    var renderIndicator = useCallback(function (clickHandler, isSelected, index, label) {
        return (React.createElement("div", { onClick: clickHandler, className: "carousel-indicator " + (isSelected ? 'selected' : '') }));
    }, []);
    return (React.createElement("div", { className: 'content-carousel' },
        React.createElement(Carousel, __assign({ renderIndicator: renderIndicator, showArrows: false, showIndicators: true, showStatus: false, showThumbs: false, autoPlay: autoPlay }, additionalCarouselProps), children)));
};
export default ContentCarousel;
