import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { actions as subsidisedFinanceInformationActions } from '@payaca/store/subsidisedFinanceInformation';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { MiniLoader, SubsidisedFinanceInformationOverview, } from '@payaca/components';
import './ReviewFormSection.css';
var ReviewFormSection = function () {
    var dispatch = useDispatch();
    var history = useHistory();
    var isSubmitting = useSelector(function (state) {
        var _a;
        return (_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.isSubmitting;
    });
    var isSubmitted = useSelector(function (state) {
        var _a;
        return (_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.isSubmitted;
    });
    var businessInformation = useSelector(function (state) {
        var _a;
        return (_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.businessInformation;
    });
    var financialInformation = useSelector(function (state) {
        var _a;
        return (_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.financialInformation;
    });
    var regulatoryRequirements = useSelector(function (state) {
        var _a;
        return (_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.regulatoryRequirements;
    });
    var additionalInformation = useSelector(function (state) {
        var _a;
        return (_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.additionalInformation;
    });
    var authorityToSearch = useSelector(function (state) {
        var _a;
        return (_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.authorityToSearch;
    });
    var supportingDocuments = useSelector(function (state) {
        var _a;
        return (_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.supportingDocuments;
    });
    useEffect(function () {
        dispatch(subsidisedFinanceInformationActions.requestGetBusinessInformation());
        dispatch(subsidisedFinanceInformationActions.requestGetFinancialInformation());
        dispatch(subsidisedFinanceInformationActions.requestGetRegulatoryRequirements());
        dispatch(subsidisedFinanceInformationActions.requestGetAdditionalInformation());
        dispatch(subsidisedFinanceInformationActions.requestGetAuthorityToSearch());
        dispatch(subsidisedFinanceInformationActions.requestGetSupportingDocuments());
    }, []);
    useEffect(function () {
        if (isSubmitted) {
            history.push('/subsidisedFinanceInformation/overview');
        }
    }, [isSubmitted, history]);
    var onSubmit = useCallback(function () {
        dispatch(subsidisedFinanceInformationActions.requestSubmitSubsidisedFinanceInformation());
    }, [dispatch]);
    return (React.createElement("div", { className: "review-form-section", style: { position: 'relative' } },
        React.createElement("h2", null, "Please confirm that all the data you have provided is correct before submitting."),
        React.createElement(SubsidisedFinanceInformationOverview, { businessInformation: businessInformation, financialInformation: financialInformation, regulatoryRequirements: regulatoryRequirements, additionalInformation: additionalInformation, authorityToSearch: authorityToSearch, supportingDocuments: supportingDocuments }),
        React.createElement("div", { className: "action-buttons-container" }, isSubmitting ? (React.createElement("div", { className: "loader-container flex-grow" },
            React.createElement(MiniLoader, null))) : (React.createElement(Button, { onClick: onSubmit, iconAfter: faChevronRight, styleVariant: ButtonStyleVariant.OUTSIZE }, "Confirm and Submit")))));
};
export default ReviewFormSection;
