import React from 'react';
import PayacaLogo from '@/assets/images/icon.png';
import { Button } from '@payaca/components';
import './StatusError.css';
var StatusError = function (_a) {
    var title = _a.title, status = _a.status, errorName = _a.errorName, errorDescriptionMessage = _a.errorDescriptionMessage, errorResolveMessage = _a.errorResolveMessage, buttonTitle = _a.buttonTitle, buttonOnClick = _a.buttonOnClick;
    return (React.createElement("div", { className: "status-error" },
        React.createElement("img", { src: PayacaLogo }),
        React.createElement("h1", null, title),
        React.createElement("h4", null,
            "Error",
            status ? " " + status : '',
            ": ",
            errorName),
        React.createElement("p", null, errorDescriptionMessage),
        errorResolveMessage && React.createElement("p", null, errorResolveMessage),
        buttonTitle && buttonOnClick && (React.createElement(Button, { onClick: buttonOnClick }, buttonTitle))));
};
export default StatusError;
