import React, { useEffect, useMemo, useState } from "react";
import Button from "../button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import FinanceOptionsExplainerStep from "./FinanceOptionsExplainerStep";
import ContentPanel from "../contentPanel/ContentPanel";
import SelinaLoanCalculator from "../selinaLoanCalculator/SelinaLoanCalculator";
import FinanceOptionScreen from "./FinanceOptionScreen";
import Tooltip from "../tooltip/Tooltip";
var FinanceExplainerType;
(function (FinanceExplainerType) {
    FinanceExplainerType["SIMPLE"] = "simple";
    FinanceExplainerType["SELINA"] = "selina";
})(FinanceExplainerType || (FinanceExplainerType = {}));
var FinanceOptionsExplainer = function (_a) {
    var overlayIsActive = _a.overlayIsActive, hasSimpleFinanceOption = _a.hasSimpleFinanceOption, hasSelinaFinanceOption = _a.hasSelinaFinanceOption, goToSimpleFinanceApply = _a.goToSimpleFinanceApply, goToSelinaFinanceApply = _a.goToSelinaFinanceApply;
    var _b = useState(null), activeFinanceExplainerType = _b[0], setActiveFinanceExplainerType = _b[1];
    useEffect(function () {
        setActiveFinanceExplainerType(null);
    }, [overlayIsActive]);
    var hasMultipleFinanceOptions = useMemo(function () {
        return hasSimpleFinanceOption && hasSelinaFinanceOption;
    }, [hasSimpleFinanceOption, hasSelinaFinanceOption]);
    var footerDisclaimerContent = useMemo(function () {
        return "Payaca Ltd, company number 11173058. Registered with the Information Commissioner's Office in compliance with the Data Protection Act 2018 under registration ZA426301. Registered office at Hamilton House, 80 Stokes Croft, Bristol, England, BS1 3QY. Payaca Ltd is authorised and regulated by the Financial Conduct Authority 811775. We act as a credit broker not a lender. We can introduce you to a limited number of lenders who may be able to offer you finance facilities for your purchase. We will only introduce you to these lenders. We may receive a commission payment from the finance provider if you decide to enter into an agreement with them. You may be able to obtain finance for your purchase from other lenders and you are encouraged to seek alternative quotations.";
    }, []);
    var representativeExampleContent = useMemo(function () {
        return (React.createElement("div", { className: "representative-example-content" },
            React.createElement("p", null, "Your home may be repossessed if you do not keep up repayments."),
            React.createElement("p", null, "Representative Example: A Home FlexiLoan of \u00A350,000 drawn out in full over 25 years at a fixed rate of 4.95% for 5 years and then our variable rate of 4.45% for the remaining term would result in 60 monthly payments of \u00A3296.63 and 240 monthly payments of \u00A3284.33."),
            React.createElement("p", null, "The total cost after 25 years is \u00A386,037.30, which includes \u00A335,042.30 interest and a \u00A3995 product fee added to the balance."),
            React.createElement("p", null, "The overall cost for comparison is 4.96% APRC representative.")));
    }, []);
    var financeOptionsExplainerContent = useMemo(function () {
        return (React.createElement("div", { className: "finance-options-explainer-content" },
            React.createElement("h1", null, "I am interested in..."),
            React.createElement("div", { className: "buttons-container flex-container" },
                hasSimpleFinanceOption && (React.createElement(Button, { onClick: function () {
                        return setActiveFinanceExplainerType(FinanceExplainerType.SIMPLE);
                    } },
                    React.createElement("span", null,
                        "Unsecured loans",
                        React.createElement(Tooltip, { text: "An unsecured loan, or personal loan, is a loan you can get without putting up something you own (like your home or car) as collateral. Lenders rely on your financial history to decide if you qualify and how much you can borrow, so it helps if you have good credit and a financial history that shows you're reliable." })),
                    React.createElement("p", { className: "key-figure" }, "< £25k"))),
                hasSelinaFinanceOption && (React.createElement(Button, { onClick: function () {
                        return setActiveFinanceExplainerType(FinanceExplainerType.SELINA);
                    } },
                    React.createElement("span", null,
                        "Secured loans",
                        React.createElement(Tooltip, { text: "With a secured loan, you put up an asset that you own as a guarantee you'll pay the lender back. This is usually a home that you own or pay the mortgage on, so they're often called homeowner loans. If you don't keep up with the repayments, the lender can seize the asset \u2013 meaning you could lose your home." })),
                    React.createElement("p", { className: "key-figure" }, "\u00A325k - \u00A31M"))))));
    }, [hasSimpleFinanceOption, hasSelinaFinanceOption]);
    var simpleFinanceExplainerContent = useMemo(function () {
        return (React.createElement("div", { className: "simple-finance-explainer-content" },
            React.createElement(ContentPanel, { hasBoxShadow: true },
                React.createElement("h1", { className: "simple-finance-explainer-title" }, "How it works"),
                React.createElement("hr", null),
                React.createElement("div", { className: "simple-finance-explainer-steps-outer-container" },
                    React.createElement("div", { className: "simple-finance-explainer-steps-container" },
                        React.createElement(FinanceOptionsExplainerStep, { iconPath: "https://storage.googleapis.com/payaca-prod-assets/payaca-icon-marketing.png", description: "Choose the amount you want to borrow between \u00A3100 and \u00A325k" }),
                        React.createElement(FontAwesomeIcon, { icon: faArrowRight }),
                        React.createElement(FinanceOptionsExplainerStep, { iconPath: "https://storage.googleapis.com/payaca-prod-assets/payaca-icon-select-finance-option.png", description: "Get a decision in miutes with no impact on your credit score" }),
                        React.createElement(FontAwesomeIcon, { icon: faArrowRight }),
                        React.createElement(FinanceOptionsExplainerStep, { iconPath: "https://storage.googleapis.com/payaca-prod-assets/payaca-icon-receive-finance.png", description: "See your personalised rates and select an offer" }),
                        React.createElement(FontAwesomeIcon, { icon: faArrowRight }),
                        React.createElement(FinanceOptionsExplainerStep, { iconPath: "https://storage.googleapis.com/payaca-prod-assets/payaca-icon-card.png", description: "Funds can be used to pay service provider directly" }))),
                React.createElement(Button, { onClick: goToSimpleFinanceApply, className: "finance-apply-button", iconAfter: faAngleRight }, "Check your eligibility"))));
    }, [goToSimpleFinanceApply]);
    var selinaFinanceExplainerContent = useMemo(function () {
        return (React.createElement("div", { className: "selina-finance-explainer-content" },
            React.createElement("div", { className: "cobrand-logos-container flex-container" },
                React.createElement("img", { src: "https://storage.googleapis.com/payaca-prod-assets/logo-white.png", alt: "payaca logo" }),
                React.createElement("img", { src: "https://storage.googleapis.com/payaca-prod-assets/selina-logo-white.svg", alt: "selina logo" })),
            React.createElement("h1", null, "A Home FlexiLoan for you"),
            React.createElement("p", { className: "introduction" }, "Borrow from \u00A325,000 - \u00A31,000,000 with a FlexiLoan from Selina Finance and access flexible finance"),
            React.createElement(ContentPanel, { hasBoxShadow: true },
                React.createElement(SelinaLoanCalculator, { renderActions: function (isValid) {
                        if (isValid) {
                            return (React.createElement(Button, { iconAfter: faAngleRight, onClick: function () {
                                    return goToSelinaFinanceApply && goToSelinaFinanceApply(null);
                                } }, "Find out more"));
                        }
                    } }))));
    }, [goToSelinaFinanceApply]);
    return (React.createElement("div", { className: "finance-options-explainer-wrapper" },
        hasMultipleFinanceOptions && (React.createElement(FinanceOptionScreen, { className: "finance-options-explainer " + (!activeFinanceExplainerType ? "active" : ""), backgroundImagePath: "https://storage.googleapis.com/payaca-prod-assets/kitchen-renovation-stock-photo.jpg", footerDisclaimerContent: footerDisclaimerContent }, financeOptionsExplainerContent)),
        hasSimpleFinanceOption && (React.createElement(FinanceOptionScreen, { className: "simple-finance-explainer " + (activeFinanceExplainerType === FinanceExplainerType.SIMPLE ||
                !hasMultipleFinanceOptions
                ? "active"
                : ""), backgroundImagePath: "https://storage.googleapis.com/payaca-prod-assets/diy-chair-stock-photo.jpg", footerDisclaimerContent: footerDisclaimerContent, onNavigateBack: hasMultipleFinanceOptions
                ? function () { return setActiveFinanceExplainerType(null); }
                : undefined }, simpleFinanceExplainerContent)),
        hasSelinaFinanceOption && (React.createElement(FinanceOptionScreen, { className: "selina-finance-explainer " + (activeFinanceExplainerType === FinanceExplainerType.SELINA ||
                !hasMultipleFinanceOptions
                ? "active"
                : ""), backgroundImagePath: "https://storage.googleapis.com/payaca-prod-assets/modern-kitchen-stock-photo.jpg", backgroundImageOverlayColour: "rgba(0,0,0,.3)", footerDisclaimerContent: React.createElement("div", null,
                representativeExampleContent,
                React.createElement("hr", null),
                footerDisclaimerContent), onNavigateBack: hasMultipleFinanceOptions
                ? function () { return setActiveFinanceExplainerType(null); }
                : undefined }, selinaFinanceExplainerContent))));
};
export default FinanceOptionsExplainer;
