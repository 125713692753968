var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useCallback, useMemo, } from 'react';
import { useDispatch } from 'react-redux';
import { actions as appActions } from '@/api/app';
import { ValidatedForm } from '@payaca/components';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { FeedbackMessage, FeedbackLevel, } from '@payaca/components/feedbackMessage';
import { getIsRequiredFieldValidator, getFieldValuesMustMatchValidator, } from '@payaca/helpers/fieldValidationHelper';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import './ResetPasswordForm.sass';
import { PrivateField } from '@payaca/components/privateField';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { getFormServiceError } from '@/helpers/formHelper';
import { minimumLengthFieldValidator, mustContainLetterFieldValidator, mustContainNumberFieldValidator, } from '@payaca/helpers/passwordValidationHelper';
import PasswordValidationFeedback from '../passwordValidationFeedback/PasswordValidationFeedback';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
var ResetPasswordForm = function (_a) {
    var resetToken = _a.resetToken, onSuccessCallback = _a.onSuccessCallback;
    var dispatch = useDispatch();
    var _b = useState(false), showValidationFeedbackMessages = _b[0], setShowValidationFeedbackMessages = _b[1];
    var _c = useState(false), isResettingPassword = _c[0], setIsResettingPassword = _c[1];
    var _d = useState(), resetPasswordErrorMessage = _d[0], setResetPasswordErrorMessage = _d[1];
    var onSubmit = useCallback(function (newPassword, confirmNewPassword) {
        setIsResettingPassword(true);
        setResetPasswordErrorMessage(undefined);
        var payload = {
            password: newPassword,
            confirm: confirmNewPassword,
        };
        dispatch(appActions.resetPassword(resetToken, payload, function (error) {
            if (!error) {
                onSuccessCallback && onSuccessCallback();
            }
            else {
                setResetPasswordErrorMessage(getFormServiceError('resetPassword', error));
            }
            setIsResettingPassword(false);
        }));
    }, [dispatch, resetToken, onSuccessCallback]);
    var fieldValidators = useMemo(function () {
        var isRequiredFieldValidator = getIsRequiredFieldValidator();
        return {
            newPassword: [
                isRequiredFieldValidator,
                minimumLengthFieldValidator,
                mustContainLetterFieldValidator,
                mustContainNumberFieldValidator,
            ],
            confirmNewPassword: [
                isRequiredFieldValidator,
                getFieldValuesMustMatchValidator('newPassword', {
                    customErrorMessage: 'The passwords entered do not match',
                }),
            ],
        };
    }, []);
    var initialFormState = useMemo(function () {
        return {
            newPassword: '',
            confirmNewPassword: '',
        };
    }, []);
    var renderValidationFeedbackMessage = useCallback(function (validationFeedbackMessage, isValidationPassed) {
        return (React.createElement(FeedbackMessage, { message: validationFeedbackMessage, feedbackLevel: isValidationPassed ? FeedbackLevel.SUCCESS : undefined, iconBefore: isValidationPassed ? faCheck : faTimes }));
    }, []);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        var minimumLengthValidationResult = minimumLengthFieldValidator('newPassword', formState);
        var mustContainNumberValidationResult = mustContainNumberFieldValidator('newPassword', formState);
        var mustContainLetterValidationResult = mustContainLetterFieldValidator('newPassword', formState);
        return (React.createElement(React.Fragment, null,
            React.createElement(ValidatedFieldWrapper, { validationResult: __assign(__assign({}, validationState['newPassword']), { errors: [] }), isTouched: touchedState['newPassword'] || false },
                React.createElement(PrivateField, { styleVariant: InputStyleVariant.OUTSIZE, name: "newPassword", value: formState.newPassword, label: 'New password', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, additionalInputProps: {
                        onFocus: function () { return setShowValidationFeedbackMessages(true); },
                    } })),
            React.createElement(ValidatedFieldWrapper, { validationResult: validationState['confirmNewPassword'], isTouched: touchedState['confirmNewPassword'] || false },
                React.createElement(PrivateField, { styleVariant: InputStyleVariant.OUTSIZE, name: "confirmNewPassword", value: formState.confirmNewPassword, label: 'Confirm password', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch })),
            React.createElement("div", { className: "button-container" },
                React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, isDisabled: !isValid, isProcessing: isResettingPassword, onClick: function () {
                        return !isResettingPassword &&
                            onSubmit(formState.newPassword, formState.confirmNewPassword);
                    } }, "Reset Password")),
            React.createElement("div", { style: {
                    visibility: showValidationFeedbackMessages ? 'visible' : 'hidden',
                } },
                React.createElement(PasswordValidationFeedback, { password: formState.newPassword }))));
    }, [isResettingPassword, onSubmit, showValidationFeedbackMessages]);
    return (React.createElement("div", { className: "reset-password-form-container" },
        React.createElement(ValidatedForm, { initialFormState: initialFormState, fieldValidators: fieldValidators, renderFormContents: renderFormContents })));
};
export default ResetPasswordForm;
