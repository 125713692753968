import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '@payaca/components/modal';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
var ConfirmModal = function (_a) {
    var classes = _a.classes, open = _a.open, onClose = _a.onClose, primaryAction = _a.primaryAction, primaryText = _a.primaryText, primaryLink = _a.primaryLink, primaryTo = _a.primaryTo, hidePrimaryButton = _a.hidePrimaryButton, secondaryAction = _a.secondaryAction, secondaryText = _a.secondaryText, tertiaryAction = _a.tertiaryAction, tertiaryText = _a.tertiaryText, text = _a.text, _b = _a.title, title = _b === void 0 ? '' : _b, size = _a.size;
    var onCloseModal = function () {
        return onClose ? onClose() : null;
    };
    var modalClass = 'confirm-modal' + (classes ? " " + classes : '');
    var actions = useMemo(function () {
        return (React.createElement(React.Fragment, null,
            primaryLink && (React.createElement("a", { target: "_blank", rel: "noreferrer", href: primaryLink, onClick: onClose },
                React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE }, primaryText || 'Yes'))),
            primaryTo && (React.createElement(Link, { to: primaryTo, onClick: onClose, target: "_blank" },
                React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE }, primaryText || 'Yes'))),
            !hidePrimaryButton && (React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, onClick: primaryAction }, primaryText || 'Yes')),
            secondaryAction && (React.createElement(Button, { onClick: secondaryAction, styleVariant: ButtonStyleVariant.ANCHOR }, secondaryText || 'No')),
            tertiaryAction && (React.createElement(Button, { onClick: tertiaryAction, styleVariant: ButtonStyleVariant.ANCHOR }, tertiaryText || 'No'))));
    }, [
        tertiaryAction,
        secondaryAction,
        hidePrimaryButton,
        primaryAction,
        primaryLink,
        onClose,
        primaryTo,
        primaryText,
    ]);
    return (React.createElement(Modal, { isOpen: open, title: title, onClose: onCloseModal, size: size || 'xs', className: modalClass, actions: actions }, text && React.createElement("div", null, text)));
};
export default ConfirmModal;
