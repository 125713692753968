import React from 'react';
import IncludedFeatureTable from './IncludedFeatureTable';
import Button from '../button/Button';
import core from './pricingData/core.json';
import quoting from './pricingData/quoting.json';
import invoicing from './pricingData/invoicing.json';
import crm from './pricingData/crm.json';
import integrations from './pricingData/integrations.json';
import business from './pricingData/business.json';
import support from './pricingData/support.json';
import './SubscriptionPricingInformation.css';
export var PopulatedFeatureTable = function () {
    return (React.createElement(IncludedFeatureTable, { data: [
            { data: core },
            { label: 'Quoting', data: quoting },
            { label: 'Invoicing', data: invoicing },
            { label: 'CRM', data: crm },
            { label: 'Integrations', data: integrations },
            { label: 'Business', data: business },
            { label: 'Support', data: support },
        ] }));
};
var SubscriptionPricingInformation = function (_a) {
    var onBookCall = _a.onBookCall, onSelectPlan = _a.onSelectPlan, selectedPlan = _a.selectedPlan, showSignUpButton = _a.showSignUpButton, selectedPlanContent = _a.selectedPlanContent, stepText = _a.stepText;
    var renderPlans = function () {
        var plans = [
            { title: 'Starter', annual: 19, jobs: '1 - 50' },
            { title: 'Professional', annual: 59, jobs: '51 - 150' },
            { title: 'Premium', annual: 99, jobs: '151 - 500' },
            { title: 'Enterprise', contactUs: true },
        ];
        return plans.map(function (plan) {
            return (React.createElement("div", { className: "payaca-plan " + (selectedPlan === plan.title ? 'selected' : ''), style: {
                    height: '200px',
                    cursor: onSelectPlan && !plan.contactUs ? 'pointer' : 'default',
                }, onClick: function () {
                    return onSelectPlan && !plan.contactUs && onSelectPlan(plan.title);
                } },
                React.createElement("div", { className: "plan-title" }, plan.title),
                !plan.contactUs && (React.createElement("div", { className: "main-plan-price" },
                    React.createElement("div", { className: "pricing-cost-amount" },
                        "\u00A3",
                        plan.annual,
                        " ",
                        React.createElement("span", { className: "plus-vat" }, "+VAT")),
                    React.createElement("div", null, "per month, billed annually"))),
                plan.contactUs && (React.createElement("div", { className: "contact-us" },
                    "Need a custom plan? Contact us to discuss",
                    React.createElement("div", null,
                        React.createElement("a", { style: { color: '#FFC232' }, href: "tel:03330509475" }, "0333 050 9475"))))));
        });
    };
    return (React.createElement("div", { className: "subscription-pricing-information" },
        (showSignUpButton || onBookCall) && (React.createElement("div", null,
            React.createElement("div", { style: { marginBottom: 20 } },
                showSignUpButton && (React.createElement("a", { href: "https://web.payaca.com/register", style: { marginRight: 20 } },
                    React.createElement(Button, null, "Sign up"))),
                onBookCall && (React.createElement(Button, { onClick: function () { return onBookCall(); } }, "Book a call"))),
            React.createElement("div", { style: { color: '#000', marginTop: 30, marginBottom: 20 } },
                "Want to call us now?",
                ' ',
                React.createElement("a", { style: { color: '#FFC232' }, href: "tel:03330509475" }, "0333 050 9475")))),
        stepText && React.createElement("div", null, stepText),
        React.createElement("div", { className: "payaca-plan-most-popular-wrapper" },
            React.createElement("div", { className: "payaca-plan-most-popular" }, "MOST POPULAR")),
        React.createElement("div", { className: "payaca-plans" }, renderPlans()),
        selectedPlanContent && (React.createElement("div", { className: "selected-plan-content" }, selectedPlanContent)),
        React.createElement("div", null,
            React.createElement(PopulatedFeatureTable, null))));
};
export default SubscriptionPricingInformation;
