import React, { useMemo } from 'react';
import './DealPaymentSummary.sass';
import { currencyPrice } from '@/helpers/financeHelper';
import { getTotalCompletedPaymentValueFromJobPayments } from '@bit/payaca-tech.payaca-core.helpers.job-payment';
import { isInvoice } from '@bit/payaca-tech.payaca-core.helpers.job-status';
import { useSelector } from 'react-redux';
import { getActiveJobFinanceInformationByDealId, getJobPaymentsByDealId, getJobsByDealId, } from '@/utils/stateAccessors';
var DealPaymentSummary = function (_a) {
    var dealId = _a.dealId;
    var jobs = useSelector(function (state) {
        return getJobsByDealId(state, dealId);
    });
    var jobPayments = useSelector(function (state) {
        return getJobPaymentsByDealId(state, dealId);
    });
    var activeJobFinanceInformation = useSelector(function (state) {
        return getActiveJobFinanceInformationByDealId(state, dealId);
    });
    var totalsJob = useMemo(function () {
        if (!jobs || !jobs.length)
            return;
        //...sort jobs by createdat date, most recently created first
        var sortedJobs = jobs.sort(function (j1, j2) {
            return j1.createdAt > j2.createdAt ? -1 : 1;
        });
        var invoice = sortedJobs.find(function (x) { return isInvoice(x.status); });
        if (invoice)
            return invoice;
        return sortedJobs[0];
    }, [jobs]);
    var receivedPaymentsTotal = useMemo(function () {
        if (!(jobPayments === null || jobPayments === void 0 ? void 0 : jobPayments.length))
            return 0;
        return getTotalCompletedPaymentValueFromJobPayments(jobPayments);
    }, [jobPayments]);
    if (!totalsJob)
        return null;
    if (!isInvoice(totalsJob.status) && !totalsJob.acceptedAt)
        return null;
    return (React.createElement("div", { className: 'deal-payment-summary flex-container' },
        React.createElement("div", { className: 'deal-payment-summary-amount' },
            React.createElement("h4", null, "Total"),
            React.createElement("div", null, currencyPrice(totalsJob.total))),
        !!(activeJobFinanceInformation === null || activeJobFinanceInformation === void 0 ? void 0 : activeJobFinanceInformation.customerAgreementSignedAt) && (React.createElement(React.Fragment, null,
            !!receivedPaymentsTotal && (React.createElement("div", { className: 'deal-payment-summary-amount' },
                React.createElement("h4", null, "Deposit received"),
                React.createElement("div", null, currencyPrice(receivedPaymentsTotal)))),
            !!totalsJob.depositAmount &&
                totalsJob.depositAmount > receivedPaymentsTotal && (React.createElement("div", { className: 'deal-payment-summary-amount' },
                React.createElement("h4", null, "Deposit outstanding"),
                React.createElement("div", null, currencyPrice(totalsJob.depositAmount - receivedPaymentsTotal)))),
            React.createElement("div", { className: 'deal-payment-summary-amount' },
                React.createElement("h4", null, "Finance amount"),
                React.createElement("div", null, currencyPrice(totalsJob.total - (totalsJob.depositAmount || 0)))))),
        !(activeJobFinanceInformation === null || activeJobFinanceInformation === void 0 ? void 0 : activeJobFinanceInformation.customerAgreementSignedAt) && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'deal-payment-summary-amount' },
                React.createElement("h4", null, "Received"),
                React.createElement("div", null, currencyPrice(receivedPaymentsTotal))),
            React.createElement("div", { className: 'deal-payment-summary-amount' },
                React.createElement("h4", null, "Outstanding"),
                React.createElement("div", null, currencyPrice(totalsJob.total - receivedPaymentsTotal)))))));
};
export default DealPaymentSummary;
