var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
import { actions as dealsActions } from '@bit/payaca-tech.payaca-core.store.deals';
import './EditJobControl.sass';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { JobCustomerControl } from '@/ui/components';
import JobSidePanel from '../jobSidePanel/JobSidePanel';
import { canSetCustomerForDeal } from '@bit/payaca-tech.payaca-core.helpers.deal';
import JobAttachmentsControl from '../jobAttachmentsControl/JobAttachmentsControl';
import { CheckboxField } from '@payaca/components/checkboxField';
import { getInitialUpdateJobRequestDataFromBaseJob } from '@bit/payaca-tech.payaca-core.helpers.job-v2';
import { actions as jobsActions } from '@bit/payaca-tech.payaca-core.store.jobs';
import { useState } from 'react';
import { useEffect } from 'react';
import JobLineItemGroupsControl from '../jobLineItemGroupsControl/JobLineItemGroupsControl';
import { isInvoice } from '@/helpers/jobHelper';
import InvoiceLineItemsControl from '../invoiceLineItemsControl/InvoiceLineItemsControl';
import { actions as lineItemGroupActions } from '@bit/payaca-tech.payaca-core.store.line-item-groups';
import { getDealByJobId, getJob, getJobsByDealId, } from '@/utils/stateAccessors';
import BodyWithSidePanelContentWrapper from '../bodyWithSidePanelContentWrapper/BodyWithSidePanelContentWrapper';
import EditJobPreviewButton from '../editJobPreviewButton/EditJobPreviewButton';
import EditJobSaveFeedback from '../editJobSaveFeedback/EditJobSaveFeedback';
import { TitledContentPanel } from '@payaca/components/titledContentPanel';
import { ResponsiveViewWrapper } from '@payaca/components/responsiveViewWrapper';
var EditJobControl = function (_a) {
    var jobId = _a.jobId, proceedToPreview = _a.proceedToPreview;
    var dispatch = useDispatch();
    var job = useSelector(function (state) {
        return getJob(state, jobId);
    });
    var deal = useSelector(function (state) {
        return getDealByJobId(state, jobId);
    });
    var onSelectCustomer = useCallback(function (customerId) {
        if (!job)
            return;
        dispatch(dealsActions.requestSetDealCustomer(job.dealId, customerId));
    }, [job === null || job === void 0 ? void 0 : job.dealId, dispatch]);
    var jobIsInvoice = useMemo(function () {
        if (!job)
            return false;
        return isInvoice(job.status);
    }, [job === null || job === void 0 ? void 0 : job.status]);
    var jobsBelongingToDeal = useSelector(function (state) {
        return deal ? getJobsByDealId(state, deal.id) : [];
    });
    var canSelectCustomer = useMemo(function () {
        return canSetCustomerForDeal(jobsBelongingToDeal);
    }, [jobsBelongingToDeal]);
    var _b = useState(), updateJobRequestData = _b[0], setUpdateJobRequestData = _b[1];
    useEffect(function () {
        if (!job)
            return;
        setUpdateJobRequestData(getInitialUpdateJobRequestDataFromBaseJob(job));
    }, [job]);
    useEffect(function () {
        dispatch(lineItemGroupActions.requestGetLineItemGroups());
    }, []);
    var onSelectContact = useCallback(function (contactId) {
        if (!updateJobRequestData)
            return;
        var newUpdateJobRequestData = __assign(__assign({}, updateJobRequestData), { contactId: contactId });
        setUpdateJobRequestData(newUpdateJobRequestData);
        dispatch(jobsActions.requestUpdateJob(newUpdateJobRequestData, function () {
            return dispatch(jobsActions.requestGetJob(updateJobRequestData.jobId));
        }));
    }, [updateJobRequestData, dispatch]);
    var updateHideItemPrices = useCallback(function (hideItemPrices) {
        if (!updateJobRequestData)
            return;
        var newUpdateJobRequestData = __assign(__assign({}, updateJobRequestData), { hideItemPrices: hideItemPrices });
        setUpdateJobRequestData(newUpdateJobRequestData);
        dispatch(jobsActions.requestUpdateJob(newUpdateJobRequestData, function () {
            return dispatch(jobsActions.requestGetJob(updateJobRequestData.jobId));
        }));
    }, [dispatch, updateJobRequestData]);
    var titleComponent = useMemo(function () {
        if (!job)
            return;
        var jobIsInvoice = isInvoice(job.status);
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement("h1", null,
                    "Edit ",
                    jobIsInvoice ? 'invoice' : 'proposal')),
            React.createElement(EditJobSaveFeedback, { jobId: job.id })));
    }, [job]);
    var sidebarContent = useMemo(function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "scrollable-content-container" },
                React.createElement(JobSidePanel, { jobId: jobId })),
            React.createElement("div", { className: "static-content-container" },
                React.createElement(EditJobPreviewButton, { jobId: jobId, proceedToPreview: proceedToPreview }))));
    }, [jobId, proceedToPreview]);
    return (React.createElement(ResponsiveViewWrapper, { className: "edit-job-control", downBreakpointSm: 700 },
        React.createElement(BodyWithSidePanelContentWrapper, { title: titleComponent, sidebarContent: sidebarContent },
            React.createElement(TitledContentPanel, { title: 'Customer', hasBoxShadow: true },
                React.createElement(JobCustomerControl, { canSelectCustomer: canSelectCustomer, customerId: deal === null || deal === void 0 ? void 0 : deal.customerId, onSelectCustomer: onSelectCustomer, jobContactId: (job === null || job === void 0 ? void 0 : job.contactId) || null, onSelectContact: onSelectContact, onPersistCustomerSuccess: function () {
                        dispatch(jobsActions.requestGetJob(jobId));
                    } })),
            React.createElement(TitledContentPanel, { title: React.createElement("div", { className: "flex-container flex-center" },
                    React.createElement("h4", { className: "flex-grow" }, "Items"),
                    React.createElement(CheckboxField, { name: "hideItemPrices", value: updateJobRequestData === null || updateJobRequestData === void 0 ? void 0 : updateJobRequestData.hideItemPrices, label: "Hide item prices", onChange: function (value) {
                            return updateHideItemPrices(value.hideItemPrices);
                        } })), hasBoxShadow: true },
                React.createElement("div", null, jobIsInvoice ? (React.createElement(InvoiceLineItemsControl, { jobId: jobId, jobIsInvoice: jobIsInvoice })) : (React.createElement(JobLineItemGroupsControl, { jobId: jobId, jobIsInvoice: jobIsInvoice })))),
            React.createElement(TitledContentPanel, { title: 'Attachments', hasBoxShadow: true },
                React.createElement(JobAttachmentsControl, { jobId: jobId })))));
};
export default EditJobControl;
