import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubscriptionPaymentPreview from '../subscriptionPaymentPreview/SubscriptionPaymentPreview';
import SelectedProductOverview from '../selectedProductOverview/SelectedProductOverview';
import UserSeatsExceededWarning from '../userSeatsExceededWarning/UserSeatsExceededWarning';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import SubscriptionControlHeader from '../subscriptionControlHeader/SubscriptionControlHeader';
import { actions as subscriptionActions } from '@payaca/store/subscription';
import './UpdateSubscriptionAdditionalUserSeatsControl.sass';
var TAX_RATE_PERCENTAGE = process.env
    .REACT_APP_SUBSCRIPTION_TAX_RATE_PERCENTAGE;
var UpdateSubscriptionAdditionalUserSeatsControl = function (_a) {
    var onUpdateSubscription = _a.onUpdateSubscription;
    var dispatch = useDispatch();
    var _b = useState(0), additionalUserSeats = _b[0], setAdditionalUserSeats = _b[1];
    var _c = useState(false), isSubmitted = _c[0], setIsSubmitted = _c[1];
    var accountSubscription = useSelector(function (state) { return state.subscription.accountSubscription; });
    var isSubscriptionUpdatedSuccessfully = useSelector(function (state) {
        return state.subscription.isSubscriptionUpdatedSuccessfully ||
            state.subscription.isSubscriptionCreatedSuccessfully;
    });
    var subscriptionPaymentPreview = useSelector(function (state) { return state.subscription.subscriptionPaymentPreview; });
    var isGettingSubscriptionPaymentPreview = useSelector(function (state) { return state.subscription.isGettingSubscriptionPaymentPreview; });
    var paymentMethodDescription = useMemo(function () {
        if (!accountSubscription)
            return null;
        var subscriptionInformation = accountSubscription.subscriptionInformation;
        return subscriptionInformation.stripePaymentMethodBrand + "   \u2022\u2022\u2022\u2022 " + subscriptionInformation.stripePaymentMethodLast4 + "   " + subscriptionInformation.stripePaymentMethodExpiryMonth + "/" + subscriptionInformation.stripePaymentMethodExpiryYear;
    }, [accountSubscription]);
    var isUpdatingSubscription = useSelector(function (state) {
        return state.subscription.isUpdatingSubscription ||
            state.subscription.isCreatingSubscription;
    });
    var monthlyAdditionalUserSeatCost = useMemo(function () {
        var _a, _b;
        if ((accountSubscription === null || accountSubscription === void 0 ? void 0 : accountSubscription.recurringInterval) === 'month') {
            return (_a = accountSubscription === null || accountSubscription === void 0 ? void 0 : accountSubscription.productPrice) === null || _a === void 0 ? void 0 : _a.additionalUserSeatCost;
        }
        else {
            return ((_b = accountSubscription === null || accountSubscription === void 0 ? void 0 : accountSubscription.productPrice) === null || _b === void 0 ? void 0 : _b.additionalUserSeatCost) ? accountSubscription.productPrice.additionalUserSeatCost / 12
                : undefined;
        }
    }, [accountSubscription]);
    var activeUsers = useSelector(function (state) {
        return state.users.accountUsers.filter(function (accountUser) {
            return !accountUser.deactivatedAt || accountUser.inviteToken;
        });
    });
    var selectedUserSeats = useMemo(function () {
        if (!accountSubscription)
            return 0;
        return (accountSubscription.subscriptionProduct.numberOfUserSeats +
            additionalUserSeats);
    }, [accountSubscription, additionalUserSeats]);
    var activeUsersExceedsSelectedUserSeats = useMemo(function () {
        if (!accountSubscription)
            return false;
        return (selectedUserSeats +
            accountSubscription.subscriptionInformation.bonusAdditionalUserSeats <
            ((activeUsers === null || activeUsers === void 0 ? void 0 : activeUsers.length) || 0));
    }, [activeUsers, accountSubscription, selectedUserSeats]);
    var updateSubscriptionPaymentPreview = useCallback(function () {
        if (!accountSubscription)
            return;
        if (subscriptionPaymentPreview &&
            additionalUserSeats === subscriptionPaymentPreview.additionalUserSeats) {
            return;
        }
        dispatch(subscriptionActions.requestGetSubscriptionPaymentPreview({
            stripeSubscriptionId: accountSubscription.subscriptionInformation.stripeSubscriptionId,
            stripePriceId: accountSubscription.productPrice.stripeId,
            additionalUserSeats: additionalUserSeats,
        }));
    }, [
        dispatch,
        subscriptionPaymentPreview,
        accountSubscription,
        additionalUserSeats,
    ]);
    var onSubmit = useCallback(function () {
        var updateSubscriptionRequestData = {
            stripePriceId: accountSubscription.productPrice.stripeId,
            stripePaymentMethodId: accountSubscription.subscriptionInformation.stripePaymentMethodId,
            stripeSubscriptionId: accountSubscription.subscriptionInformation.stripeSubscriptionId,
            additionalUserSeats: additionalUserSeats,
        };
        dispatch(subscriptionActions.requestUpdateSubscription(updateSubscriptionRequestData));
        setIsSubmitted(true);
    }, [dispatch, additionalUserSeats]);
    useEffect(function () {
        if (accountSubscription) {
            setAdditionalUserSeats(accountSubscription.subscriptionInformation.additionalUserSeats);
        }
    }, [accountSubscription]);
    useEffect(function () {
        updateSubscriptionPaymentPreview();
    }, [additionalUserSeats]);
    useEffect(function () {
        if (isSubmitted &&
            !isUpdatingSubscription &&
            isSubscriptionUpdatedSuccessfully) {
            onUpdateSubscription && onUpdateSubscription();
        }
    }, [
        isSubmitted,
        isUpdatingSubscription,
        isSubscriptionUpdatedSuccessfully,
        onUpdateSubscription,
    ]);
    if (!accountSubscription)
        return null;
    return (React.createElement("div", { className: "update-subscription-additional-user-seats-control" },
        React.createElement("div", { className: "selected-product-overview-container" },
            React.createElement(SubscriptionControlHeader, { selectedProduct: accountSubscription.subscriptionProduct, recurringInterval: accountSubscription.recurringInterval }),
            React.createElement(SelectedProductOverview, { additionalUserSeats: additionalUserSeats, recurringInterval: accountSubscription.recurringInterval, taxRatePercentage: TAX_RATE_PERCENTAGE, selectedProduct: accountSubscription.subscriptionProduct, selectedPrice: accountSubscription.productPrice, enableEmbeddedUserSeatControl: true, onChangeAdditionalUserSeats: setAdditionalUserSeats })),
        React.createElement(SubscriptionPaymentPreview, { subscriptionPaymentPreview: subscriptionPaymentPreview, isLoading: isGettingSubscriptionPaymentPreview }),
        activeUsersExceedsSelectedUserSeats && (React.createElement(UserSeatsExceededWarning, { selectedUserSeats: selectedUserSeats, bonusAdditionalUserSeats: accountSubscription.subscriptionInformation
                .bonusAdditionalUserSeats, activeUsersCount: activeUsers.length })),
        !activeUsersExceedsSelectedUserSeats && (React.createElement("div", { className: "review-payment-method-container" },
            React.createElement("p", null,
                "Payment method: ",
                React.createElement("strong", null, paymentMethodDescription)),
            React.createElement("div", { className: "actions-container" },
                React.createElement(Button, { isProcessing: isUpdatingSubscription, isDisabled: additionalUserSeats ===
                        accountSubscription.subscriptionInformation
                            .additionalUserSeats, onClick: !isUpdatingSubscription ? function () { return onSubmit(); } : undefined, styleVariant: ButtonStyleVariant.OUTSIZE }, "Update subscription"))))));
};
export default UpdateSubscriptionAdditionalUserSeatsControl;
