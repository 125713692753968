import React from 'react';
import { ContentPanel } from '@payaca/components/contentPanel';
import './TrainingVideosContentPanel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
var trainingVideos = [
    {
        name: '10 minute introduction to web',
        url: 'https://player.vimeo.com/video/560836621',
    },
    {
        name: '3 minute introduction to the mobile app',
        url: 'https://www.loom.com/share/f80defe8a7094bd2901c14a3e8ddd7eb',
    },
];
var TrainingVideosContentPanel = function () {
    return (React.createElement(ContentPanel, { className: "training-videos-content-panel" },
        React.createElement("h4", null, "Training videos"),
        React.createElement("ul", null, trainingVideos.map(function (trainingVideo, index) {
            return (React.createElement("li", { key: index },
                React.createElement("a", { href: trainingVideo.url, target: "_blank", rel: "noopener noreferrer" },
                    trainingVideo.name,
                    React.createElement(FontAwesomeIcon, { icon: faArrowRight }))));
        }))));
};
export default TrainingVideosContentPanel;
