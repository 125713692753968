import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faEye,
  faEyeSlash,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { TextareaAutosize } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import PhoneInput from 'react-phone-input-2';
import MiniLoader from '@bit/payaca-tech.payaca-core.component.mini-loader';

import FieldLabel from './FieldLabel';

import { ExternalFormValidationErrors } from '@/helpers/formHelper';
import { actions as userActions } from '@/api/users';

import {
  FieldType,
  getAutoComplete,
  getCharacterLimit,
  getPlaceholder,
} from '@/helpers/formHelper';

import 'react-phone-input-2/lib/bootstrap.css';
import './BasicField.css';

class ValidatedField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInput: false,
      isPassword: false,
      isValidating: false,
      isValidateAttempted: false,
      isValid: false,
      externalValidationTimeout: null,
    };
  }

  componentDidMount() {
    if (this.props.type === 'password') {
      this.setState({ isPassword: true });
    }
    this.onFieldBlur();
  }

  onInputChange(e) {
    // invalidate fields while typing, as they will get validated onBlur
    if (
      Object.keys(ExternalFormValidationErrors).find(
        (er) => er === this.props.name
      )
    ) {
      this.setState({ isValid: false });
    }
    const { formatPreChange, onChange, inputChangePattern } = this.props;
    const value = formatPreChange
      ? formatPreChange(e.target.value)
      : e.target.value;

    if (inputChangePattern && !inputChangePattern.test(value)) {
      return;
    }

    if (this.props.requiresExternalValidation) {
      this.state.externalValidationTimeout &&
        clearTimeout(this.state.externalValidationTimeout);
      this.setState({
        isValidateAttempted: false,
        externalValidationTimeout: setTimeout(
          () => this.runExternalValidation(),
          1000
        ),
      });
    }

    onChange(this.props.name, value);
  }

  onTelInputChange(phone, country) {
    let formattedPhone = phone;
    // remove any leading zeros after country prefix
    if (phone[2] === '0') {
      formattedPhone = phone.slice(0, 2) + phone.slice(3, phone.length);
    }
    // if phone value is dialcode clear input value
    if (phone === country.dialCode) {
      formattedPhone = '';
    }
    this.onInputChange({ target: { value: formattedPhone } });
  }

  toggleVisibility() {
    this.setState({ showInput: !this.state.showInput });
  }

  runExternalValidation() {
    const { name, value, requiresExternalValidation } = this.props;
    if (!requiresExternalValidation) return;

    const onValidationCallback = (err, resp) => {
      this.setState({
        isValidating: false,
        isValidateAttempted: true,
        isValid: resp,
      });
      this.props.isExternallyValidating(false);
      this.props.onExternalValidation(name, resp);
    };
    const onEmptyValue = () => {
      // clear errors so local errors can overwrite
      this.setState({
        isValidating: false,
        isValidateAttempted: false,
      });
      this.props.isExternallyValidating(false);
      this.props.onExternalValidation(name, true);
    };

    this.setState({ isValidating: true });
    this.props.isExternallyValidating(true);
    if (name === 'email') {
      if (value) {
        this.props.users.validateEmail(value, (err, resp) => {
          onValidationCallback(err, resp);
        });
      } else {
        onEmptyValue();
      }
    } else if (name === 'contactNumber') {
      if (value) {
        this.props.users.validatePhone(value, (err, resp) => {
          onValidationCallback(err, resp);
        });
      } else {
        onEmptyValue();
      }
    }
  }

  onFieldBlur() {
    const { defaultOnBlur, onChange, name, value } = this.props;
    if (this.props.requiresExternalValidation) {
      this.runExternalValidation();
    }
    // set input to default value on blur if required
    if (defaultOnBlur && !defaultOnBlur.pattern.test(value)) {
      onChange(name, defaultOnBlur.defaultValue);
    }
  }

  renderField() {
    const { fieldType, name, readOnly, rowsMin, type, value } = this.props;
    const isTextAreaFieldType = fieldType === FieldType.TEXTAREA;

    let inputType = type;
    if (this.state.isPassword && this.state.showInput) {
      inputType = 'text';
    }

    if (readOnly) {
      return (
        <input
          readOnly
          type={inputType || 'text'}
          value={value}
          name={name}
          onChange={(e) => this.onInputChange(e)}
          autoComplete={getAutoComplete(name)}
          placeholder={getPlaceholder(name)}
        />
      );
    } else {
      if (isTextAreaFieldType) {
        return (
          <TextareaAutosize
            value={value}
            name={name}
            onChange={(e) => this.onInputChange(e)}
            placeholder={getPlaceholder(name)}
            maxLength={null}
            rowsMin={rowsMin || 2}
          />
        );
      } else {
        switch (inputType) {
          case 'formattedNumberTemplate': {
            const format = {
              sortCode: '##-##-##',
              accountNumber: '########',
            };
            return (
              <NumberFormat
                value={value}
                onValueChange={(values) =>
                  this.onInputChange({ target: { value: values.value } })
                }
                name={name}
                format={format[name]}
                allowEmptyFormatting
                onBlur={(e) => this.onFieldBlur(e)}
              />
            );
          }
          case 'formattedNumber': {
            return (
              <NumberFormat
                value={value}
                thousandSeparator={true}
                name={name}
                onValueChange={(values) =>
                  this.onInputChange({ target: { value: values.value } })
                }
                isNumericString={true}
                onBlur={(e) => this.onFieldBlur(e)}
              />
            );
          }
          case 'tel': {
            return (
              <PhoneInput
                country="gb"
                value={value || '44'} // set value to gb default if value is falsy, otherwise dialcode is cleared
                onChange={(phone, country) =>
                  this.onTelInputChange(phone, country)
                }
                name={name}
                countryCodeEditable={false}
                onBlur={(e) => this.onFieldBlur()}
                enableLongNumbers={12}
              />
            );
          }
          default: {
            return (
              <input
                type={inputType || 'text'}
                value={value}
                name={name}
                onChange={(e) => this.onInputChange(e)}
                autoComplete={getAutoComplete(name)}
                placeholder={getPlaceholder(name)}
                onBlur={() => this.onFieldBlur()}
              />
            );
          }
        }
      }
    }
  }

  render() {
    const {
      fieldType,
      isRequired,
      label,
      name,
      preInputText,
      tooltip,
      value,
      postInputText,
    } = this.props;

    const fieldClass = `field validated-field${
      this.state.error ? ' error' : ''
    }`;
    const showHideIcon = this.state.showInput ? faEyeSlash : faEye;
    const hasError =
      (!this.props.requiresExternalValidation ||
        this.state.isValidateAttempted) &&
      this.props.allowValidation &&
      this.props.error;
    const inputContainerClass =
      'input-container' +
      (hasError ? ' error' : '') +
      (preInputText ? ' with-pre-text' : '');
    const isTextAreaFieldType = fieldType === FieldType.TEXTAREA;
    const characterLimit = isTextAreaFieldType && getCharacterLimit(name);

    return (
      <div className={fieldClass}>
        <FieldLabel label={label} isRequired={isRequired} tooltip={tooltip} />
        <div className={inputContainerClass}>
          {preInputText && (
            <label className="pre-input-text">{preInputText}</label>
          )}
          {this.renderField()}
          {this.state.isPassword && (
            <FontAwesomeIcon
              icon={showHideIcon}
              className="show-hide-icon"
              onClick={() => this.toggleVisibility()}
            />
          )}
          {/* External validating */}
          {this.state.isValidating && <MiniLoader />}
          {!this.state.isValidating &&
            this.state.isValidateAttempted &&
            this.state.isValid && (
              <FontAwesomeIcon icon={faCheck} className="check-icon" />
            )}
          {!this.state.isValidating &&
            this.state.isValidateAttempted &&
            !this.state.isValid && (
              <FontAwesomeIcon icon={faTimes} className="cross-icon" />
            )}
          {postInputText && (
            <label className="post-input-text">{postInputText}</label>
          )}
        </div>
        {(hasError || characterLimit) && (
          <div className="validation-container">
            {hasError && <label className="error">{this.props.error}</label>}
            {characterLimit && (
              <div className="character-count">{`${
                value ? value.length : '0'
              }/${characterLimit}`}</div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => ({
    users: bindActionCreators(userActions, dispatch),
  })
)(ValidatedField);
