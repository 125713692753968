import React from "react";
import "./JobOverviewNotes.css";
var JobOverviewNotes = function (_a) {
    var title = _a.title, notes = _a.notes;
    return (React.createElement("div", { className: "job-overview-notes" },
        title && (React.createElement("p", null,
            React.createElement("strong", null, title))),
        React.createElement("p", null, notes)));
};
export default JobOverviewNotes;
