import React from 'react';
import { CompanyType, } from '@payaca/types/subsidisedFinanceInformationTypes';
import './SubsidisedFinanceInformationOverview.css';
import BusinessInformationOverview from './BusinessInformationOverview';
import FinancialInformationOverview from './FinancialInformationOverview';
import RegulatoryRequirementsOverview from './RegulatoryRequirementsOverview';
import AdditionalInformationOverview from './AdditionalInformationOverview';
import AuthorityToSearchOverview from './AuthorityToSearchOverview';
import SupportingDocumentsOverview from './SupportingDocumentsOverview';
import ResponsiveViewWrapper from '../responsiveViewWrapper/ResponsiveViewWrapper';
var SubsidisedFinanceInformationOverview = function (_a) {
    var businessInformation = _a.businessInformation, financialInformation = _a.financialInformation, regulatoryRequirements = _a.regulatoryRequirements, additionalInformation = _a.additionalInformation, authorityToSearch = _a.authorityToSearch, supportingDocuments = _a.supportingDocuments;
    return (React.createElement(ResponsiveViewWrapper, { className: "subsidised-finance-information-overview", downBreakpointSm: 700 },
        React.createElement("section", null,
            React.createElement("h3", null, "Business Information"),
            React.createElement(BusinessInformationOverview, { businessInformation: businessInformation })),
        React.createElement("section", null,
            React.createElement("h3", null, "Financial Information"),
            React.createElement(FinancialInformationOverview, { financialInformation: financialInformation })),
        React.createElement("section", null,
            React.createElement("h3", null, "Regulatory Requirements"),
            React.createElement(RegulatoryRequirementsOverview, { regulatoryRequirements: regulatoryRequirements })),
        React.createElement("section", null,
            React.createElement("h3", null, "Additional Information"),
            React.createElement(AdditionalInformationOverview, { additionalInformation: additionalInformation })),
        React.createElement("section", null,
            React.createElement("h3", null, "Authority to search"),
            React.createElement(AuthorityToSearchOverview, { authorityToSearch: authorityToSearch })),
        React.createElement("section", null,
            React.createElement("h3", null, "Supporting documents"),
            React.createElement(SupportingDocumentsOverview, { supportingDocuments: supportingDocuments, directors: businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.directors, isSoleTrader: (businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.companyType) === CompanyType.SOLE_TRADER }))));
};
export default SubsidisedFinanceInformationOverview;
