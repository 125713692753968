import React from 'react';
import { Button } from '@payaca/components/button';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { BasicField } from '@payaca/components/basicField';
import './QuantityControl.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var QuantityControl = function (_a) {
    var _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, value = _a.value, _c = _a.minimumValue, minimumValue = _c === void 0 ? 0 : _c, onChange = _a.onChange, _d = _a.showInputField, showInputField = _d === void 0 ? false : _d;
    return (React.createElement("div", { className: "quantity-control flex-container flex-center" },
        React.createElement(Button, { isOutlined: true, isDisabled: value <= minimumValue || isDisabled, onClick: function () { return onChange(value - 1); } },
            React.createElement(FontAwesomeIcon, { icon: faMinus })),
        showInputField ? (React.createElement(BasicField, { name: "quantity", type: "number", value: value, onChange: function (value) { return onChange(parseInt(value.quantity)); } })) : (React.createElement("span", null, value)),
        React.createElement(Button, { isOutlined: true, isDisabled: isDisabled, onClick: function () { return onChange(value + 1); } },
            React.createElement(FontAwesomeIcon, { icon: faPlus }))));
};
export default QuantityControl;
