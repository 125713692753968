import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Switch, Route, Router } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Intercom from 'react-intercom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Splash from '@/ui/pages/splashPage/SplashPage';
import MaintenancePage from '@/ui/pages/maintenancePage/MaintenancePage';

import PublicRouting from '@/routes/PublicRouting';
import PrivateRouting from '@/routes/PrivateRouting';

import history from '@/services/history';

import { actions as appActions, selectors as appSelectors } from '@/api/app';
import { AssumeToken } from '@/routes/AssumeToken';

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

const stripePromise = loadStripe(STRIPE_KEY);

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: 'Poppins',
  },
  palette: {
    type: 'light',
    primary: {
      main: '#fab62d',
      contrastText: '#fff',
    },
    secondary: {
      main: '#14a99d',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: '#fff',
      },
      containedSecondary: {
        color: '#fff',
      },
    },
  },
});

const App = () => {
  const dispatch = useDispatch();
  const loaded = useSelector((state) => appSelectors.isLoaded(state.app));
  const loggedIn = useSelector((state) => appSelectors.isLoggedIn(state.app));

  const [lastUnfocusAt, setLastUnfocusAt] = useState(0);

  const lastUnfocusAtRef = useRef(lastUnfocusAt);

  const setLastUnfocusAtRef = (time) => {
    lastUnfocusAtRef.current = time;
    setLastUnfocusAt(time);
  };

  useEffect(() => {
    dispatch(appActions.start());

    setLastUnfocusAtRef(new Date().getTime());
    // Listeners to reload page on focus if page hasnt been focused on for 24 hours
    window.addEventListener('blur', handleBlur); // set timer
    window.addEventListener('focus', handleFocus); // check timer - reload if last click was > day ago
    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  const handleBlur = () => setLastUnfocusAtRef(new Date().getTime());

  const handleFocus = () => {
    if (
      new Date().getTime() - lastUnfocusAtRef.current >=
      1000 * 60 * 60 * 24
    ) {
      // reload page if page hasnt been focused on for 24 hours
      window.location.replace(window.location.href);
    }
  };

  const currentMaintenanceSchedule = useSelector((state) => {
    return state.app.maintenanceSchedule?.current;
  });

  const Maintenance = useCallback(
    ({ children }) =>
      currentMaintenanceSchedule ? <Switch>{children}</Switch> : null,
    [currentMaintenanceSchedule]
  );

  const Loading = useCallback(
    ({ children }) => (!loaded ? <Switch>{children}</Switch> : null),
    [loaded]
  );

  const Unauthorized = useCallback(
    ({ children }) =>
      loaded && !loggedIn && !currentMaintenanceSchedule ? (
        <Switch>{children}</Switch>
      ) : null,
    [currentMaintenanceSchedule, loaded, loggedIn]
  );

  const Authorized = useCallback(
    ({ children }) =>
      loaded && loggedIn && !currentMaintenanceSchedule ? children : null,
    [currentMaintenanceSchedule, loaded, loggedIn]
  );

  return (
    <Router history={history}>
      <Elements stripe={stripePromise}>
        <MuiThemeProvider theme={theme}>
          <div className="App">
            <Route
              exact
              path="/redirectToMobile"
              render={() => {
                window.location = 'Payaca://';
              }}
            />

            <Route exact path="/assume-token" component={AssumeToken} />

            <Loading>
              <Route path="*" component={Splash} />
            </Loading>

            <Maintenance>
              <Route path="*" component={MaintenancePage} />
            </Maintenance>
            <Unauthorized>
              <Route path="/" component={PublicRouting} />
            </Unauthorized>

            <Authorized>
              <Route path="/" component={PrivateRouting} />
            </Authorized>

            <Intercom appID={INTERCOM_APP_ID} />
          </div>
        </MuiThemeProvider>
      </Elements>
    </Router>
  );
};

export default App;
