var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import './ClickableArea.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ClickableArea from './ClickableArea';
var CreateArea = function (props) {
    return (React.createElement(ClickableArea, __assign({}, props, { children: React.createElement("div", { className: "flex-container flex-center" },
            React.createElement("div", { className: "create-icon-wrapper" },
                React.createElement("div", { className: "create-icon" },
                    React.createElement(FontAwesomeIcon, { icon: faPlus }))),
            React.createElement("span", null, props.children)), className: "create-area " + (props.className ? props.className : '') })));
};
export default CreateArea;
