import React from 'react';
import './CategoriesKey.css';
var CategoriesKey = function (_a) {
    var categories = _a.categories;
    return (React.createElement("div", { className: "categories-key flex-container flex-center" },
        React.createElement("span", null, "Key:"),
        React.createElement("div", { className: "key-items-container flex-container" }, categories &&
            Object.keys(categories).map(function (categoryLabel, i) {
                return (React.createElement("div", { key: i, className: "key-item flex-container flex-center flex-contiguous" },
                    React.createElement("div", { className: categoryLabel + " key-item-colour" }),
                    React.createElement("span", { className: "key-item-label" }, categories[categoryLabel])));
            }))));
};
export default CategoriesKey;
