var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useSelector } from 'react-redux';
import { Banner, ConfirmModal, AccountAccessBanner, MaintenanceBanner, OfflineBanner, } from '@/ui/components';
import DevelopmentToolsInitialiser from '../developmentToolsInitialiser/DevelopmentToolsInitialiser';
import './PageWrapper.sass';
var PageWrapper = function (_a) {
    var pageBanners = _a.pageBanners, children = _a.children, className = _a.className;
    var banner = useSelector(function (state) { return state.app.banner; });
    var modal = useSelector(function (state) { return state.app.modal; });
    return (React.createElement("div", { className: "page-wrapper " + (className ? className : '') },
        React.createElement(DevelopmentToolsInitialiser, null),
        React.createElement("div", { className: "page-banners-container" },
            React.createElement(OfflineBanner, null),
            React.createElement(AccountAccessBanner, null),
            React.createElement(MaintenanceBanner, null),
            pageBanners,
            banner && React.createElement(Banner, { options: banner })),
        React.createElement("div", { className: "page-wrapper-inner" }, children),
        modal && React.createElement(ConfirmModal, __assign({}, modal, { open: true }))));
};
export default PageWrapper;
