// ======================================================================================================
// Customer helper functions
// ======================================================================================================
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var parseFirstAddressLine = function (address) {
    return parseAddressArrayToString([
        address.flatNumber,
        address.houseNumber,
        address.houseName,
    ]);
};
var parseSecondAddressLine = function (address) {
    return parseAddressArrayToString([address.street1, address.street2]);
};
// Parse reponse from lookupAddress service
var parseLookupAddress = function (address) {
    return {
        firstLineAddress: parseFirstAddressLine(address),
        secondLineAddress: parseSecondAddressLine(address),
        city: address.postTown,
        postcode: address.postCode,
    };
};
// Parse response from fetched customer
var parseCustomerAddress = function (address) {
    if (address) {
        var addressArr = address.split(', ');
        var firstLineAddress = addressArr.shift() || '';
        var secondLineAddress = addressArr.join(', ');
        return {
            firstLineAddress: firstLineAddress,
            secondLineAddress: secondLineAddress,
        };
    }
    return {
        firstLineAddress: '',
        secondLineAddress: '',
    };
};
var formatParsedFullAddress = function (firstLineAddress, secondLineAddress, city, postcode) {
    return parseAddressArrayToString([
        firstLineAddress,
        secondLineAddress,
        city,
        postcode,
    ]);
};
var parseAddressArrayToString = function (strArr) {
    return strArr.filter(function (v) { return v; }).join(', ');
};
var parseCustomer = function (customer) {
    // Just use "firstLineAddress" and "secondLineAddress" instead of "address"
    var parsedAddress = parseCustomerAddress((customer === null || customer === void 0 ? void 0 : customer.address) || '');
    var parsedCustomer = __assign(__assign({}, customer), { firstLineAddress: parsedAddress.firstLineAddress, secondLineAddress: parsedAddress.secondLineAddress });
    return parsedCustomer;
};
var parseCustomers = function (customers) {
    return customers.map(function (customer) { return parseCustomer(customer); });
};
export { formatParsedFullAddress, parseAddressArrayToString, parseCustomer, parseCustomers, parseCustomerAddress, parseFirstAddressLine, parseLookupAddress, parseSecondAddressLine, };
