import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import './ListedUsersTable.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, } from '@fortawesome/free-solid-svg-icons';
import MiniLoader from '@bit/payaca-tech.payaca-core.component.mini-loader';
import { Badge } from '@payaca/components/badge';
import { QuickActionsTableCell } from '@payaca/components/quickActionsTableCell';
import { Checkbox } from '@payaca/components/checkbox';
import { RowSelectionTableCell } from '@payaca/components/rowSelectionTableCell';
import { PaginationControl } from '@payaca/components/paginationControl';
var renderStatusTableCell = function (listedUser) {
    var status = listedUser.status.toLowerCase();
    var colour = '#fff';
    var backgroundColour = '#919191';
    switch (status) {
        case 'active':
            colour = '#263E59';
            backgroundColour = '#75E582';
            break;
        case 'invited':
            colour = '#263E59';
            backgroundColour = '#FABB00';
            break;
    }
    return (React.createElement("td", { className: "status-table-cell tr-end " + listedUser.status.toLowerCase() },
        React.createElement(Badge, { colour: colour, backgroundColour: backgroundColour }, listedUser.status)));
};
var renderRoleTableCell = function (listedUser) {
    return (React.createElement("td", { className: "role-table-cell" }, listedUser.role === 'Admin' ? (React.createElement("strong", null, listedUser.role)) : (React.createElement("span", null, listedUser.role))));
};
var renderIsPrimaryUserTableCell = function (listedUser) {
    return (React.createElement("td", { className: "align-center is-primary-user-table-cell" }, listedUser.isPrimaryUserForAccount && (React.createElement(FontAwesomeIcon, { icon: faCheckCircle }))));
};
var ListedUsersTable = function (_a) {
    var selectedUserIds = _a.selectedUserIds, actionBehaviours = _a.actionBehaviours, onSelectUsers = _a.onSelectUsers, onUnselectUsers = _a.onUnselectUsers, actionProcessing = _a.actionProcessing, onSelectPage = _a.onSelectPage;
    var currentUserId = useSelector(function (state) {
        return state.users.myProfile.id;
    });
    var listedUsersPage = useSelector(function (state) {
        var _a;
        return (_a = state.listedUsers) === null || _a === void 0 ? void 0 : _a.listedUsersPage;
    });
    var isGettingListedUsersPage = useSelector(function (state) { return state.listedUsers.isGettingListedUsersPage; });
    var totalColumnCount = useMemo(function () {
        return 7;
    }, []);
    var allSelectableUserIds = useMemo(function () {
        if (!(listedUsersPage === null || listedUsersPage === void 0 ? void 0 : listedUsersPage.items))
            return [];
        return listedUsersPage.items
            .filter(function (listedUser) {
            return listedUser.userId !== currentUserId &&
                !listedUser.isPrimaryUserForAccount;
        })
            .map(function (listedUser) { return listedUser.userId; });
    }, [listedUsersPage, currentUserId]);
    var isAllUsersSelected = useMemo(function () {
        return (allSelectableUserIds.sort().join(',') === selectedUserIds.sort().join(','));
    }, [allSelectableUserIds, selectedUserIds]);
    var renderTableHead = useMemo(function () {
        return (React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement("th", { className: "row-selection-table-cell" },
                    React.createElement(Checkbox, { isChecked: isAllUsersSelected, onChange: function () {
                            isAllUsersSelected
                                ? onUnselectUsers(allSelectableUserIds)
                                : onSelectUsers(allSelectableUserIds);
                        } })),
                React.createElement("th", null, "Name"),
                React.createElement("th", null, "Email"),
                React.createElement("th", null, "Role"),
                React.createElement("th", { className: "align-center" }, "Primary user?"),
                React.createElement("th", null, "Status"),
                React.createElement("th", null))));
    }, [
        isAllUsersSelected,
        allSelectableUserIds,
        onUnselectUsers,
        onSelectUsers,
    ]);
    var renderTableRow = useCallback(function (listedUser, index) {
        var userIsSelected = selectedUserIds.includes(listedUser.userId);
        var isCurrentUser = currentUserId === listedUser.userId;
        return (React.createElement("tr", { key: index, className: "" + (userIsSelected ? 'selected' : '') },
            !isCurrentUser && !listedUser.isPrimaryUserForAccount ? (React.createElement(RowSelectionTableCell, { isSelected: userIsSelected, onChange: function () {
                    userIsSelected
                        ? onUnselectUsers([listedUser.userId])
                        : onSelectUsers([listedUser.userId]);
                } })) : (React.createElement("td", { className: "tr-exclude" })),
            React.createElement("td", { className: "tr-start" },
                React.createElement("strong", null, listedUser.name)),
            React.createElement("td", null, listedUser.emailAddress),
            renderRoleTableCell(listedUser),
            renderIsPrimaryUserTableCell(listedUser),
            renderStatusTableCell(listedUser),
            React.createElement(QuickActionsTableCell, { quickActions: listedUser.availableQuickActionTypes.map(function (quickActionType) {
                    var actionBehaviour = actionBehaviours[quickActionType]
                        ? actionBehaviours[quickActionType]
                        : function (userId) {
                            return console.log('No behaviour for this action');
                        };
                    return {
                        actionName: quickActionType,
                        actionBehaviour: actionBehaviour,
                        isActionProcessing: quickActionType === actionProcessing,
                    };
                }), recordId: listedUser.userId })));
    }, [
        currentUserId,
        actionProcessing,
        actionBehaviours,
        selectedUserIds,
        onSelectUsers,
        onUnselectUsers,
    ]);
    var paginationControl = useMemo(function () {
        if (!listedUsersPage)
            return;
        return (React.createElement(PaginationControl, { pageNumber: listedUsersPage.pageNumber, pageSize: listedUsersPage.pageSize, totalItemCount: listedUsersPage.totalItemCount, onSelectPage: onSelectPage }));
    }, [listedUsersPage, onSelectPage]);
    return (React.createElement("div", { className: "listed-users-table" },
        React.createElement("div", { className: "scrollable-table-wrapper styled-table-wrapper" },
            React.createElement("table", { className: "styled-table" },
                renderTableHead,
                React.createElement("tbody", null,
                    isGettingListedUsersPage && (React.createElement("tr", null,
                        React.createElement("td", { colSpan: totalColumnCount, className: "tr-exclude" },
                            React.createElement("div", { className: "loader-container" },
                                React.createElement(MiniLoader, null))))),
                    !isGettingListedUsersPage && !(listedUsersPage === null || listedUsersPage === void 0 ? void 0 : listedUsersPage.totalItemCount) && (React.createElement("tr", null,
                        React.createElement("td", { colSpan: totalColumnCount, className: "tr-exclude" },
                            React.createElement("div", { className: "no-results-container" },
                                React.createElement("span", null, "No results found"))))),
                    !isGettingListedUsersPage && (listedUsersPage === null || listedUsersPage === void 0 ? void 0 : listedUsersPage.items) &&
                        !!(listedUsersPage === null || listedUsersPage === void 0 ? void 0 : listedUsersPage.totalItemCount) && (React.createElement(React.Fragment, null, listedUsersPage.items.map(renderTableRow)))))),
        paginationControl));
};
export default ListedUsersTable;
