var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState, useCallback, useMemo, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { actions as subsidisedFinanceInformationActions } from '@payaca/store/subsidisedFinanceInformation';
import { CompanyType, } from '@payaca/types/subsidisedFinanceInformationTypes';
import { getFieldValidatorsForSupportingDocuments } from '@payaca/helpers/subsidisedFinanceInformationValidationHelper';
import { MiniLoader, ValidatedForm } from '@payaca/components';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { FileUploadPersistRemoveControl } from '@payaca/components/fileUploadPersistRemoveControl';
import SupportingDocumentsProofOfAddressModal from './SupportingDocumentsProofOfAddressModal';
import './SupportingDocumentsFormSection.css';
var SupportingDocumentsFormSection = function () {
    var dispatch = useDispatch();
    var _a = useState(false), isProofOfAddressModalOpen = _a[0], setIsProofOfAddressModalOpen = _a[1];
    var directors = useSelector(function (state) {
        var _a, _b;
        return (_b = (_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.businessInformation) === null || _b === void 0 ? void 0 : _b.directors;
    });
    var isSoleTrader = useSelector(function (state) {
        var _a, _b;
        return (((_b = (_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.businessInformation) === null || _b === void 0 ? void 0 : _b.companyType) ===
            CompanyType.SOLE_TRADER);
    });
    var supportingDocuments = useSelector(function (state) {
        var _a;
        return (_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.supportingDocuments;
    });
    var isGettingOrPersisting = useSelector(function (state) {
        var _a, _b;
        return (((_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.isGettingSupportingDocuments) || ((_b = state.subsidisedFinanceInformation) === null || _b === void 0 ? void 0 : _b.isPersistingSupportingDocuments));
    });
    var _b = useState(), triggerValidationAt = _b[0], setTriggerValidationAt = _b[1];
    var fieldValidators = useMemo(function () {
        var directorIds = directors
            ? directors.map(function (director) { return director.id; })
            : [];
        return getFieldValidatorsForSupportingDocuments(directorIds);
    }, [directors]);
    useEffect(function () {
        dispatch(subsidisedFinanceInformationActions.requestGetSupportingDocuments());
        dispatch(subsidisedFinanceInformationActions.requestGetBusinessInformation());
    }, []);
    var onContinue = useCallback(function () {
        dispatch(subsidisedFinanceInformationActions.requestCompleteSupportingDocuments());
    }, [dispatch]);
    var renderActionButtons = useCallback(function (isValid, formState) {
        return (React.createElement("div", { className: "action-buttons-container" }, isGettingOrPersisting ? (React.createElement("div", { className: "loader-container flex-grow" },
            React.createElement(MiniLoader, null))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { onClick: function () { return !isValid && setTriggerValidationAt(new Date()); } },
                React.createElement(Button, { isDisabled: !isValid, onClick: function () { return isValid && onContinue(); }, iconAfter: faChevronRight, styleVariant: ButtonStyleVariant.OUTSIZE }, 'Continue'))))));
    }, [isGettingOrPersisting, onContinue]);
    var persistGenericSupportingDocument = useCallback(function (file) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    dispatch(subsidisedFinanceInformationActions.requestPersistGenericSupportingDocument(file, function (error) {
                        error ? reject(error) : resolve();
                    }));
                })];
        });
    }); }, [dispatch]);
    var persistDirectorIdDocument = useCallback(function (directorId, file) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    dispatch(subsidisedFinanceInformationActions.requestPersistDirectorIdDocument(directorId, file, function (error) {
                        error ? reject(error) : resolve();
                    }));
                })];
        });
    }); }, [dispatch]);
    var persistDirectorAddressDocument = useCallback(function (directorId, file) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    dispatch(subsidisedFinanceInformationActions.requestPersistDirectorAddressDocument(directorId, file, function (error) {
                        error ? reject(error) : resolve();
                    }));
                })];
        });
    }); }, [dispatch]);
    var persistBankStatementDocument = useCallback(function (file) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    dispatch(subsidisedFinanceInformationActions.requestPersistBankStatementDocument(file, function (error) {
                        error ? reject(error) : resolve();
                    }));
                })];
        });
    }); }, [dispatch]);
    var removeSupportingDocument = useCallback(function (documentId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    dispatch(subsidisedFinanceInformationActions.requestRemoveSupportingDocument(documentId, function (error) {
                        error ? reject(error) : resolve();
                    }));
                })];
        });
    }); }, [dispatch]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "field-group" },
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.genericSupportingDocuments, isTouched: touchedState.genericSupportingDocuments || false },
                    React.createElement(FileUploadPersistRemoveControl, { isRequired: true, persistedFiles: formState.genericSupportingDocuments
                            ? formState.genericSupportingDocuments.map(function (supportingDocument) {
                                return {
                                    fileName: supportingDocument.fileName,
                                    identifier: supportingDocument.id,
                                };
                            })
                            : [], persistFile: function (file) {
                            return persistGenericSupportingDocument(file).then(function () {
                                onFieldTouch('genericSupportingDocuments');
                            });
                        }, removePersistedFile: function (documentId) {
                            return removeSupportingDocument(documentId).then(function () {
                                onFieldTouch('genericSupportingDocuments');
                            });
                        }, allowMultipleUploads: true, label: isSoleTrader
                            ? 'Please upload your most recent tax return.'
                            : 'Please upload your most recent accounts filed with Companies House. If these are older than 9 months please also attach updated management accounts.' }))),
            React.createElement("div", { className: "field-group" },
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.bankStatementDocument, isTouched: touchedState.bankStatementDocument || false },
                    React.createElement(FileUploadPersistRemoveControl, { isRequired: true, persistedFiles: formState.bankStatementDocument
                            ? [
                                {
                                    fileName: formState.bankStatementDocument.fileName,
                                    identifier: formState.bankStatementDocument.id,
                                },
                            ]
                            : [], persistFile: function (file) {
                            return persistBankStatementDocument(file).then(function () {
                                onFieldTouch('bankStatementDocument');
                            });
                        }, removePersistedFile: function (documentId) {
                            return removeSupportingDocument(documentId).then(function () {
                                onFieldTouch('bankStatementDocument');
                            });
                        }, allowMultipleUploads: false, label: "Please upload a copy of a bank statement dated within the last 3 months", description: "This statement must include company name, sort code, account number and display a date that is within the last 3 months." }))),
            React.createElement("div", { className: "field-group director-id-field-group" },
                React.createElement("div", null,
                    React.createElement("span", null, "Please upload a scan or image of a passport or driving licence as photo ID for the following people:")),
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.directorIdDocuments, isTouched: touchedState.directorIdDocuments || false },
                    React.createElement("div", { className: "director-id-field-container" }, directors &&
                        directors.map(function (director, index) {
                            var _a, _b;
                            var directorIdDocument = (_b = (_a = formState.directorIdDocuments) === null || _a === void 0 ? void 0 : _a.find(function (directorIdDocument) {
                                return directorIdDocument.directorId === director.id;
                            })) === null || _b === void 0 ? void 0 : _b.document;
                            return (React.createElement(FileUploadPersistRemoveControl, { key: index, isRequired: true, persistedFiles: directorIdDocument
                                    ? [
                                        {
                                            identifier: directorIdDocument.id,
                                            fileName: directorIdDocument.fileName,
                                        },
                                    ]
                                    : [], persistFile: function (file) {
                                    return persistDirectorIdDocument(director.id, file);
                                }, removePersistedFile: removeSupportingDocument, allowMultipleUploads: false, label: director.name || '' }));
                        })))),
            React.createElement("div", { className: "field-group director-address-field-group" },
                React.createElement("div", null,
                    React.createElement("span", null,
                        "Please upload a scan or image of a",
                        ' ',
                        React.createElement("span", { className: "proof-of-address", onClick: function () { return setIsProofOfAddressModalOpen(true); } }, "proof of address document"),
                        ' ',
                        "for the following people:")),
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.directorAddressDocuments, isTouched: touchedState.directorAddressDocuments || false },
                    React.createElement("div", { className: "director-id-field-container" }, directors &&
                        directors.map(function (director, index) {
                            var _a, _b;
                            var directorAddressDocument = (_b = (_a = formState.directorAddressDocuments) === null || _a === void 0 ? void 0 : _a.find(function (directorAddressDocument) {
                                return (directorAddressDocument.directorId === director.id);
                            })) === null || _b === void 0 ? void 0 : _b.document;
                            return (React.createElement(FileUploadPersistRemoveControl, { key: index, isRequired: true, persistedFiles: directorAddressDocument
                                    ? [
                                        {
                                            identifier: directorAddressDocument.id,
                                            fileName: directorAddressDocument.fileName,
                                        },
                                    ]
                                    : [], persistFile: function (file) {
                                    return persistDirectorAddressDocument(director.id, file);
                                }, removePersistedFile: removeSupportingDocument, allowMultipleUploads: false, label: director.name || '' }));
                        })))),
            renderActionButtons(isValid, formState),
            React.createElement(SupportingDocumentsProofOfAddressModal, { isOpen: isProofOfAddressModalOpen, onClose: function () { return setIsProofOfAddressModalOpen(false); } })));
    }, [
        directors,
        isProofOfAddressModalOpen,
        renderActionButtons,
        persistBankStatementDocument,
        persistDirectorAddressDocument,
        persistDirectorIdDocument,
        persistGenericSupportingDocument,
        removeSupportingDocument,
    ]);
    return (React.createElement("div", { className: "supporting-documents-form-section", style: { position: 'relative' } },
        React.createElement(ValidatedForm, { triggerValidationAt: triggerValidationAt, initialFormState: supportingDocuments, fieldValidators: fieldValidators, renderFormContents: renderFormContents })));
};
export default SupportingDocumentsFormSection;
