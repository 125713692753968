var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { takeEvery, call, put, race, delay, takeLatest, } from 'redux-saga/effects';
import { ActionType, } from './jobPaymentsTypes';
import { confirmJobPaymentSuccess, confirmJobPaymentFailure, recordJobPaymentFailure, recordJobPaymentSuccess, clearJobPayments, getJobPaymentFailure, getJobPaymentSuccess, } from './jobPaymentsActions';
import { refreshAuthToken } from '../auth/authSaga';
var jobPaymentsSagaCreator = function (_a) {
    var apiBaseurl = _a.apiBaseurl, getAuthHeader = _a.getAuthHeader;
    function handleRecordJobPayment(action) {
        var timeout, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(recordJobPayment, action.payload.recordJobPaymentRequestData),
                            timeout: delay(20000),
                        })];
                case 3:
                    timeout = (_a.sent()).timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    return [4 /*yield*/, put(recordJobPaymentFailure(new Error('Record job payment timed out.')))];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(recordJobPaymentSuccess())];
                case 6:
                    _a.sent();
                    _a.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_1 = _a.sent();
                    return [4 /*yield*/, put(recordJobPaymentFailure(error_1))];
                case 9:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleConfirmJobPayment(action) {
        var timeout, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(confirmJobPayment, action.payload.confirmJobPaymentRequestData),
                            timeout: delay(15000),
                        })];
                case 3:
                    timeout = (_a.sent()).timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    return [4 /*yield*/, put(confirmJobPaymentFailure(new Error('Confirm job payment timed out.')))];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(confirmJobPaymentSuccess())];
                case 6:
                    _a.sent();
                    _a.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_2 = _a.sent();
                    return [4 /*yield*/, put(confirmJobPaymentFailure(error_2))];
                case 9:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleGetJobPayment(action) {
        var _a, response, timeout, errorMessage, error_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(getJobPayment, action.payload.jobPaymentId),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Get job payment timed out.';
                    return [4 /*yield*/, put(getJobPaymentFailure(action.payload.jobPaymentId, new Error(errorMessage)))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(getJobPaymentSuccess(action.payload.jobPaymentId, response))];
                case 6:
                    _b.sent();
                    _b.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_3 = _b.sent();
                    return [4 /*yield*/, put(getJobPaymentFailure(action.payload.jobPaymentId, error_3))];
                case 9:
                    _b.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleGetJobPaymentsForDeal(action) {
        var _a, response, timeout, _i, response_1, jobPayment, error_4;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 9]);
                    return [4 /*yield*/, race({
                            response: call(getJobPaymentsForDeal, action.payload.dealId),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!!timeout) return [3 /*break*/, 7];
                    _i = 0, response_1 = response;
                    _b.label = 4;
                case 4:
                    if (!(_i < response_1.length)) return [3 /*break*/, 7];
                    jobPayment = response_1[_i];
                    return [4 /*yield*/, put(getJobPaymentSuccess(jobPayment.id, jobPayment))];
                case 5:
                    _b.sent();
                    _b.label = 6;
                case 6:
                    _i++;
                    return [3 /*break*/, 4];
                case 7: return [3 /*break*/, 9];
                case 8:
                    error_4 = _b.sent();
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }
    var getJobPaymentsForDeal = function (dealId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/job_payments/deal/" + dealId, {
                            method: 'GET',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/, response.json()];
                                }
                                else {
                                    throw new Error("getJobPaymentsForDeal failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var getJobPayment = function (jobPaymentId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/job_payments/" + jobPaymentId, {
                            method: 'GET',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/, response.json()];
                                }
                                else {
                                    throw new Error("getJobPayment failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var recordJobPayment = function (recordJobPaymentRequestData) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/job_payments/record", {
                            method: 'POST',
                            body: JSON.stringify(recordJobPaymentRequestData),
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Job': 'true',
                            },
                        }).then(function (response) {
                            if (response.ok) {
                                return;
                            }
                            else {
                                throw new Error("Record job payment failed: " + response.status + " " + response.statusText);
                            }
                        })];
            }
        });
    }); };
    var confirmJobPayment = function (confirmJobPaymentRequestData) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/job_payments/confirm", {
                            method: 'POST',
                            body: JSON.stringify(confirmJobPaymentRequestData),
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Job': 'true',
                            },
                        }).then(function (response) {
                            if (response.ok) {
                                return;
                            }
                            else {
                                throw new Error("Confirm job payment failed: " + response.status + " " + response.statusText);
                            }
                        })];
            }
        });
    }); };
    function handleAppLogout() {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, put(clearJobPayments())];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    return function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, takeEvery(ActionType.REQUEST_RECORD_JOB_PAYMENT, handleRecordJobPayment)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(ActionType.REQUEST_CONFIRM_JOB_PAYMENT, handleConfirmJobPayment)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.REQUEST_GET_JOB_PAYMENT, handleGetJobPayment)];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.REQUEST_GET_JOB_PAYMENTS_FOR_DEAL, handleGetJobPaymentsForDeal)];
                case 4:
                    _a.sent();
                    return [4 /*yield*/, takeEvery('auth.logout', handleAppLogout)];
                case 5:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    };
};
export default jobPaymentsSagaCreator;
