import React, { useState } from 'react';
import TaxesTable from './TaxesTable';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { CheckboxField, Modal } from '@payaca/components';
import './AccountingSetupModal.sass';
var taxes = [
    {
        payacaLabel: 'VAT 20%',
        payacaPercentage: 20,
        accountingLabel: '20.0% S',
        accountingPercentage: 20,
    },
    {
        payacaLabel: 'VAT 5%',
        payacaPercentage: 5,
        accountingLabel: '5.0% R',
        accountingPercentage: 5,
    },
    {
        payacaLabel: 'VAT 0%',
        payacaPercentage: 0,
        accountingLabel: '0.0% Z',
        accountingPercentage: 0,
    },
    {
        payacaLabel: 'No VAT',
        payacaPercentage: 0,
        accountingLabel: 'No VAT',
        accountingPercentage: 0,
    },
    {
        payacaLabel: 'Exempt',
        payacaPercentage: 0,
        accountingLabel: 'Exempt',
        accountingPercentage: 0,
    },
];
var QuickbooksSetupModal = function (_a) {
    var closeQuickbooksSetupModal = _a.closeQuickbooksSetupModal;
    var _b = useState(false), areTaxesCorrect = _b[0], setAreTaxesCorrect = _b[1];
    var _c = useState(null), updateQuickbooksMessage = _c[0], setUpdateQuickbooksMessage = _c[1];
    return (React.createElement(Modal, { isOpen: true, title: "Complete Quickbooks setup", size: "sm" },
        React.createElement(TaxesTable, { accountingName: "Quickbooks", taxes: taxes }),
        React.createElement("div", null,
            React.createElement(CheckboxField, { label: "I confirm that my taxes are correct. Otherwise I will have to do manual reconciliation in Quickbooks.", onChange: function () { return setAreTaxesCorrect(!areTaxesCorrect); }, value: areTaxesCorrect, name: "confirmTaxes" })),
        React.createElement("div", { className: "error" }, updateQuickbooksMessage),
        React.createElement("div", { className: "save-connection-button-container" },
            React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, isDisabled: !areTaxesCorrect, onClick: function () {
                    if (!areTaxesCorrect) {
                        setUpdateQuickbooksMessage('Please confirm your taxes are correct');
                    }
                    else {
                        closeQuickbooksSetupModal();
                    }
                } }, "Save"))));
};
export default QuickbooksSetupModal;
