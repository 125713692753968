import React, { Fragment } from 'react';
import { faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContentPanel } from '@payaca/components/contentPanel';
import './ListedItemGroupControl.css';
var ListedItemGroupControl = function (_a) {
    var itemGroup = _a.itemGroup, onClick = _a.onClick;
    return (React.createElement(ContentPanel, { className: "listed-item-group-control" },
        React.createElement("div", { className: "listed-item-group-control-inner" + (itemGroup ? '' : ' add-group'), onClick: onClick }, itemGroup ? (React.createElement(Fragment, null,
            React.createElement("span", { className: "bold-weight group-name" }, itemGroup.name || 'Unnamed group'),
            React.createElement("div", { className: "listed-item-group-control-name-description" },
                React.createElement("span", { className: "group-description" }, itemGroup.description)),
            React.createElement("div", { className: "icon-wrapper" },
                React.createElement(FontAwesomeIcon, { icon: faChevronRight })))) : (React.createElement(Fragment, null,
            React.createElement("span", { className: "bold-weight add-group-name" }, "Add new group"),
            React.createElement("div", { className: "icon-wrapper" },
                React.createElement(FontAwesomeIcon, { icon: faPlus })))))));
};
export default ListedItemGroupControl;
