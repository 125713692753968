import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SubscriptionProductsSelectionControl from '../subscriptionProductsSelectionControl/SubscriptionProductsSelectionControl';
import './SelectSubscriptionControl.sass';
import { RecurringInterval } from '@bit/payaca-tech.payaca-core.types.subscription-product';
import UpdateSubscriptionModal from '../updateSubscriptionModal/UpdateSubscriptionModal';
var SelectSubscriptionControl = function (_a) {
    var _b;
    var onUpdateSubscription = _a.onUpdateSubscription;
    var accountSubscription = useSelector(function (state) { return state.subscription.accountSubscription; });
    var _c = useState(), priceId = _c[0], setPriceId = _c[1];
    var _d = useState(), productId = _d[0], setProductId = _d[1];
    var _e = useState(false), showUpdateSubscriptionModal = _e[0], setShowUpdateSubscriptionModal = _e[1];
    return (React.createElement("div", { className: "select-subscription-control" },
        React.createElement(SubscriptionProductsSelectionControl, { recurringInterval: RecurringInterval.YEAR, selectedProductId: (accountSubscription === null || accountSubscription === void 0 ? void 0 : accountSubscription.recurringInterval) === RecurringInterval.MONTH
                ? null
                : (_b = accountSubscription === null || accountSubscription === void 0 ? void 0 : accountSubscription.subscriptionProduct) === null || _b === void 0 ? void 0 : _b.stripeId, additionalUserSeats: 0, enableEmbeddedUserSeatControl: false, onChange: function (value) {
                setPriceId(value.priceId);
                setProductId(value.productId);
                setShowUpdateSubscriptionModal(true);
            } }),
        priceId && productId && (React.createElement(UpdateSubscriptionModal, { isOpen: showUpdateSubscriptionModal, stripePriceId: priceId, stripeProductId: productId, onClose: function () { return setShowUpdateSubscriptionModal(false); }, onUpdateSubscription: onUpdateSubscription }))));
};
export default SelectSubscriptionControl;
