import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Sentry from '@sentry/browser';
import './CreateEditLineItemControl.css';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { ValidatedForm } from '@payaca/components';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { CheckboxField } from '@payaca/components/checkboxField';
import { actions as lineItemActions } from '@bit/payaca-tech.payaca-core.store.line-items';
import { actions as lineItemV2Actions } from '@payaca/store/lineItemsV2';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import { currencyPrice, displaySupplierTotals, itemTotal, } from '@/helpers/financeHelper';
import { FileUploadType, getUpdatedFiles } from '@/helpers/fileHelper';
import { getFormServiceError } from '@/helpers/formHelper';
import { BasicField } from '@payaca/components/basicField';
import { TextareaField } from '@payaca/components/textareaField';
import { CurrencyField } from '@payaca/components/currencyField';
import CostSummary from '../costSummary/CostSummary';
import { JobAttachments } from '@payaca/components/jobAttachments';
import { getIsRequiredFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import { useHistory } from 'react-router';
import VatSettingsField from '../vatSettingsField/VatSettingsField';
var CreateEditLineItemControl = function (_a) {
    var lineItemId = _a.lineItemId;
    var dispatch = useDispatch();
    var history = useHistory();
    var _b = useState(null), createUpdateError = _b[0], setCreateUpdateError = _b[1];
    var _c = useState(), attachmentsToSave = _c[0], setAttachmentsToSave = _c[1];
    var currentLineItem = useSelector(function (state) { return state.lineItems.currentLineItem; });
    var isCisSubcontractor = useSelector(function (state) { var _a; return (_a = state.users.myProfile.accounts[0]) === null || _a === void 0 ? void 0 : _a.isCisSubcontractor; });
    var cisDeductionRate = useSelector(function (state) { var _a; return (_a = state.users.myProfile.accounts[0]) === null || _a === void 0 ? void 0 : _a.cisDeductionRate; });
    var isCreatingUpdatingLineItem = useSelector(function (state) { return state.lineItems.isCreatingUpdatingLineItem; });
    var isCreatingUpdatingLineItemAttachments = useSelector(function (state) { return state.lineItems.isCreatingUpdatingLineItemAttachments; });
    var creatingUpdatingLineItemError = useSelector(function (state) { return state.lineItems.creatingUpdatingLineItemError; });
    var creatingUpdatingLineItemAttachmentsError = useSelector(function (state) { return state.lineItems.creatingUpdatingLineItemAttachmentsError; });
    var isLineItemSuccessfullyCreatedUpdated = useSelector(function (state) { return state.lineItems.isLineItemSuccessfullyCreatedUpdated; });
    var isLineItemAttachmentsSuccessfullyCreatedUpdated = useSelector(function (state) {
        return state.lineItems.isLineItemAttachmentsSuccessfullyCreatedUpdated;
    });
    useEffect(function () {
        // Get item from query params or initialise new item
        if (!lineItemId) {
            dispatch(lineItemActions.initialiseNewLineItem());
        }
        else {
            dispatch(lineItemV2Actions.requestGetLineItem(lineItemId, function (lineItem) {
                dispatch(lineItemActions.storeCurrentLineItem(lineItem));
            }));
        }
        return function () {
            // clear current line item
            dispatch(lineItemActions.clearCurrentLineItem());
        };
    }, [lineItemId]);
    var isProcessing = useMemo(function () {
        return isCreatingUpdatingLineItem || isCreatingUpdatingLineItemAttachments;
    }, [isCreatingUpdatingLineItem, isCreatingUpdatingLineItemAttachments]);
    var initialFormState = useMemo(function () {
        return {
            id: currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.id,
            price: currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.price,
            name: currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.name,
            description: currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.description,
            vatAmount: (currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.vatAmount) === undefined
                ? 20
                : currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.vatAmount,
            vatIncluded: (currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.vatIncluded) || true,
            isReverseChargeVat: (currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.isReverseChargeVat) || false,
            supplierPrice: currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.supplierPrice,
            supplierName: currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.supplierName,
            attachments: (currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.attachments) || [],
            currency: (currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.currency) || 'GBP',
            cisDeductionRate: (currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.cisDeductionRate) === undefined
                ? null
                : currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.cisDeductionRate,
        };
    }, [currentLineItem]);
    var fieldValidators = useMemo(function () {
        return {
            description: [getIsRequiredFieldValidator()],
        };
    }, []);
    var renderCostsSummarySection = useCallback(function (formState) {
        var lineItemTotal = itemTotal(formState.price, formState.vatAmount, formState.vatIncluded, 1, formState.isReverseChargeVat);
        var supplierTotals = displaySupplierTotals(formState.price, formState.supplierPrice, 1, formState.currency);
        var cisSuffered = formState.cisDeductionRate !== null
            ? formState.price * (formState.cisDeductionRate / 100)
            : 0;
        return (React.createElement("section", { className: "costs-summary-wrapper" },
            React.createElement(CostSummary, { title: "Total (inc VAT)", value: lineItemTotal }),
            isCisSubcontractor && formState.cisDeductionRate !== null && (React.createElement(CostSummary, { title: "CIS suffered", value: currencyPrice(cisSuffered) })),
            React.createElement(CostSummary, { title: "Net profit", value: supplierTotals.netProfitCurrency }),
            React.createElement(CostSummary, { title: "Net profit margin", value: supplierTotals.netProfitMarginPercentage })));
    }, [isCisSubcontractor]);
    var onSubmit = useCallback(function (formState) {
        setAttachmentsToSave(formState.attachments);
        setCreateUpdateError(null);
        dispatch(lineItemActions.requestCreateUpdateLineItem({
            id: formState.id,
            name: formState.name,
            description: formState.description,
            price: formState.price,
            vatAmount: formState.vatAmount,
            vatIncluded: formState.vatIncluded,
            supplierName: formState.supplierName,
            supplierPrice: formState.supplierPrice,
            cisDeductionRate: formState.cisDeductionRate,
            isReverseChargeVat: formState.isReverseChargeVat,
        }));
    }, []);
    var saveLineItemAttachments = useCallback(function () {
        dispatch(lineItemActions.requestCreateUpdateLineItemAttachments(currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.id, attachmentsToSave || []));
    }, [currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.id, attachmentsToSave, dispatch]);
    useEffect(function () {
        if ((currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.id) &&
            !isCreatingUpdatingLineItem &&
            isLineItemSuccessfullyCreatedUpdated) {
            if (creatingUpdatingLineItemError) {
                setCreateUpdateError(getFormServiceError('item', lineItemId ? 'update' : 'create'));
            }
            else {
                saveLineItemAttachments();
            }
        }
    }, [
        currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.id,
        isCreatingUpdatingLineItem,
        isLineItemSuccessfullyCreatedUpdated,
        creatingUpdatingLineItemError,
    ]);
    useEffect(function () {
        if (!isCreatingUpdatingLineItemAttachments &&
            isLineItemAttachmentsSuccessfullyCreatedUpdated) {
            // item has finished creating/updating
            if (creatingUpdatingLineItemAttachmentsError) {
                setCreateUpdateError(getFormServiceError('item', lineItemId ? 'update' : 'create'));
            }
            else {
                history.push('/items');
            }
        }
    }, [
        history,
        creatingUpdatingLineItemAttachmentsError,
        isCreatingUpdatingLineItemAttachments,
    ]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "form-section" },
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.name, isTouched: touchedState.name || false },
                    React.createElement(BasicField, { styleVariant: InputStyleVariant.OUTSIZE, label: 'Item reference', description: "Your internal reference or code. This will not be seen by your customers.", value: formState.name || '', name: "name", onChange: onFieldChange, onTouch: onFieldTouch })),
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.description, isTouched: touchedState.description || false },
                    React.createElement(TextareaField, { styleVariant: InputStyleVariant.OUTSIZE, label: "Description", description: "Description for your customers. This will be shown on your estimates, quotes and invoices.", isRequired: true, value: formState.description || '', name: "description", onChange: onFieldChange, onTouch: onFieldTouch, rows: 3 })),
                React.createElement("div", { className: "flex-container flex-center" },
                    React.createElement("div", { className: "inline-field-container" },
                        React.createElement(ValidatedFieldWrapper, { validationResult: validationState.price, isTouched: touchedState.price || false },
                            React.createElement(CurrencyField, { styleVariant: InputStyleVariant.OUTSIZE, label: 'Price', description: "Ex VAT", value: formState.price, name: "price", onChange: onFieldChange, onTouch: onFieldTouch }))),
                    React.createElement("div", { className: "inline-field-container" },
                        React.createElement(VatSettingsField, { styleVariant: InputStyleVariant.OUTSIZE, vatAmount: formState.vatAmount, isVatIncluded: formState.vatIncluded, isReverseChargeVat: formState.isReverseChargeVat, onChange: onFieldChange, onTouch: onFieldTouch })))),
            React.createElement("div", { className: "form-section" },
                React.createElement("div", { className: "flex-container flex-center inline-field-container" },
                    React.createElement("div", { className: "inline-field-container" },
                        React.createElement(ValidatedFieldWrapper, { validationResult: validationState.supplierName, isTouched: touchedState.supplierName || false },
                            React.createElement(BasicField, { styleVariant: InputStyleVariant.OUTSIZE, label: 'Supplier name', value: formState.supplierName || '', name: "supplierName", onChange: onFieldChange, onTouch: onFieldTouch }))),
                    React.createElement("div", { className: "inline-field-container" },
                        React.createElement(ValidatedFieldWrapper, { validationResult: validationState.supplierPrice, isTouched: touchedState.supplierPrice || false },
                            React.createElement(CurrencyField, { styleVariant: InputStyleVariant.OUTSIZE, label: 'Cost price', value: formState.supplierPrice || '', name: "supplierPrice", onChange: onFieldChange, onTouch: onFieldTouch }))))),
            isCisSubcontractor && (React.createElement("div", { className: "form-section" },
                React.createElement(CheckboxField, { name: "cisApplies", label: "CIS applies", value: formState.cisDeductionRate !== null, description: cisDeductionRate + "% deduction rate", onChange: function (value) {
                        onFieldChange({
                            cisDeductionRate: value.cisApplies
                                ? cisDeductionRate
                                : null,
                        });
                    } }))),
            renderCostsSummarySection(formState),
            React.createElement(JobAttachments, { jobAttachments: formState.attachments || [], onFileChange: function (file, remove) {
                    // upload images to currenteditingacoutn
                    onFieldChange({
                        attachments: getUpdatedFiles(file, formState.attachments, remove),
                    });
                }, 
                // show existing line items and pending line items
                uploadType: FileUploadType.LINE_ITEM_ATTACHMENT, readOnly: false, onAddLabel: "Add image", title: "", onError: function (err) { return Sentry.captureMessage(err); }, quantityLimit: 1, imagesOnly: true }),
            createUpdateError && (React.createElement("p", { className: "error-message" }, createUpdateError)),
            React.createElement("div", { className: "button-container" },
                React.createElement(Button, { isDisabled: !isValid, onClick: function () { return !isProcessing && onSubmit(formState); }, isProcessing: isProcessing, styleVariant: ButtonStyleVariant.OUTSIZE }, "Save"))));
    }, [
        renderCostsSummarySection,
        onSubmit,
        isProcessing,
        cisDeductionRate,
        isCisSubcontractor,
    ]);
    return (React.createElement("div", { className: "create-edit-line-item-control" },
        React.createElement(ValidatedForm, { fieldValidators: fieldValidators, initialFormState: initialFormState, renderFormContents: renderFormContents })));
};
export default CreateEditLineItemControl;
