import React from 'react';
import './FormSection.css';
var FormSection = function (_a) {
    var additionalTitleContent = _a.additionalTitleContent, classes = _a.classes, title = _a.title, children = _a.children;
    var formSectionClass = 'form-section' + (classes ? " " + classes : '');
    return (React.createElement("div", { className: formSectionClass },
        title && (React.createElement("div", { className: "form-section-title-wrapper" },
            React.createElement("h4", { className: "form-section-title" }, title),
            additionalTitleContent)),
        React.createElement("div", { className: "form-section-content" }, children)));
};
export default FormSection;
