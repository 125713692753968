var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import ConfirmModal from '../modal/ConfirmModal';
import { getModal } from '@/helpers/modalHelper';
import SelectItemControl from '../selectItemControl/SelectItemControl';
import { Modal } from '@payaca/components/modal';
var SelectItemModal = function (_a) {
    var isOpen = _a.isOpen, disabledItemIds = _a.disabledItemIds, onClose = _a.onClose, onSelectItem = _a.onSelectItem;
    var _b = useState(false), showRepeatedItemConfirmation = _b[0], setShowRepeatedItemConfirmation = _b[1];
    var _c = useState(), itemId = _c[0], setItemId = _c[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { isOpen: isOpen, title: 'Select an item', onClose: onClose, className: 'select-item-modal', size: "md" },
            React.createElement(SelectItemControl, { onSelectItem: onSelectItem, disabledItemIds: disabledItemIds, onSelectDisabledItem: function (itemId) {
                    setItemId(itemId);
                    setShowRepeatedItemConfirmation(true);
                } })),
        React.createElement(ConfirmModal, __assign({}, getModal('ADD_REPEATED_ITEM'), { onClose: function () {
                setItemId(undefined);
                setShowRepeatedItemConfirmation(false);
            }, secondaryAction: function () {
                setItemId(undefined);
                setShowRepeatedItemConfirmation(false);
            }, primaryAction: function () {
                onSelectItem(itemId);
                setItemId(undefined);
                setShowRepeatedItemConfirmation(false);
            }, open: showRepeatedItemConfirmation }))));
};
export default SelectItemModal;
