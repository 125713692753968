import React, { useMemo } from 'react';
import moment from 'moment';
var JobOverviewAcceptedSignature = function (_a) {
    var jobAcceptedAt = _a.jobAcceptedAt, jobContact = _a.jobContact, acceptedSignatureImageData = _a.acceptedSignatureImageData;
    var formattedAcceptedAtDate = useMemo(function () {
        return moment(jobAcceptedAt).format('Do MMMM YYYY [at] HH:mm');
    }, [jobAcceptedAt]);
    return (React.createElement("div", { className: "job-overview-accepted-signature" },
        acceptedSignatureImageData && (React.createElement("img", { src: acceptedSignatureImageData, alt: (jobContact === null || jobContact === void 0 ? void 0 : jobContact.name) + "'s signature" })),
        React.createElement("p", null, acceptedSignatureImageData
            ? "Signed by " + (jobContact === null || jobContact === void 0 ? void 0 : jobContact.name) + " on " + formattedAcceptedAtDate
            : "Accepted on " + formattedAcceptedAtDate)));
};
export default JobOverviewAcceptedSignature;
