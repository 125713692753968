import React, { useMemo } from 'react';
import moment from 'moment';
import LabelValuePair from '../labelValuePair/LabelValuePair';
import { hasCustomerAgreementSignedFinanceApplication, isPendingCustomerAgreementSigned, } from '@payaca/helpers/jobFinanceApplicationHelper';
import { isQuoteOrEstimate } from '@payaca/helpers/jobStatusHelper';
import { getJobContactFromCustomer, getPrimaryAddressFromCustomer, } from '@payaca/helpers/customerHelper';
import './JobOverviewHeaderLower.sass';
var dateFormat = 'DD/MM/YYYY';
var JobOverviewHeaderLower = function (_a) {
    var _b, _c, _d, _e;
    var customer = _a.customer, forceDisplayAsProposition = _a.forceDisplayAsProposition, job = _a.job, jobFinanceInformation = _a.jobFinanceInformation, jobType = _a.jobType;
    var isJobQuoteOrEstimate = useMemo(function () {
        return isQuoteOrEstimate(job.status);
    }, [job]);
    var jobDepositDueDate = useMemo(function () {
        return job.dueAt &&
            job.depositAmount &&
            hasCustomerAgreementSignedFinanceApplication(jobFinanceInformation)
            ? job.dueAt
            : null;
    }, [job, jobFinanceInformation]);
    var displayAsProposition = useMemo(function () {
        return isJobQuoteOrEstimate || forceDisplayAsProposition;
    }, [forceDisplayAsProposition, isJobQuoteOrEstimate]);
    var jobDueDate = useMemo(function () {
        return displayAsProposition ? null : job.dueAt;
    }, [displayAsProposition, job]);
    var jobValidUntilDate = useMemo(function () {
        return displayAsProposition ||
            isPendingCustomerAgreementSigned(jobFinanceInformation)
            ? job.validUntil
            : null;
    }, [displayAsProposition, job, jobFinanceInformation]);
    var jobContact = useMemo(function () { return getJobContactFromCustomer(customer, job.contactId); }, [customer, job]);
    var primaryCustomerAddress = useMemo(function () { return getPrimaryAddressFromCustomer(customer); }, [customer]);
    var jobReference = useMemo(function () {
        if (job.customReference)
            return job.customReference;
        return "#" + job.reference;
    }, [job]);
    var jobSentDate = useMemo(function () {
        var date = job.sentAt;
        return date || new Date();
    }, [displayAsProposition, job]);
    var jobDateLabel = useMemo(function () {
        if (jobDepositDueDate) {
            return 'Deposit due date';
        }
        else if (jobValidUntilDate) {
            return 'Valid until';
        }
        else if (jobDueDate) {
            return 'Due date';
        }
        else {
            return '';
        }
    }, [jobDepositDueDate, jobDueDate, jobValidUntilDate]);
    var jobDate = useMemo(function () {
        var date;
        if (jobDepositDueDate) {
            date = jobDepositDueDate;
        }
        else if (jobValidUntilDate) {
            date = jobValidUntilDate;
        }
        else if (jobDueDate) {
            date = jobDueDate;
        }
        return date ? moment(date).format(dateFormat) : '';
    }, [jobDepositDueDate, jobDueDate, jobValidUntilDate]);
    return (React.createElement("div", { className: "job-overview-header-lower" },
        React.createElement("div", { className: "to-information-container" },
            React.createElement("span", { className: "customer-name" }, customer.name),
            jobContact && customer.name !== jobContact.name && (React.createElement("span", { className: "contact-name" }, jobContact.name)),
            React.createElement("div", { className: "address" },
                (((_b = primaryCustomerAddress === null || primaryCustomerAddress === void 0 ? void 0 : primaryCustomerAddress.address) === null || _b === void 0 ? void 0 : _b.line1) || ((_c = primaryCustomerAddress === null || primaryCustomerAddress === void 0 ? void 0 : primaryCustomerAddress.address) === null || _c === void 0 ? void 0 : _c.line2)) && (React.createElement("span", null,
                    primaryCustomerAddress.address.line1,
                    primaryCustomerAddress.address.line1 ? ', ' : '',
                    primaryCustomerAddress.address.line2)),
                ((_d = primaryCustomerAddress === null || primaryCustomerAddress === void 0 ? void 0 : primaryCustomerAddress.address) === null || _d === void 0 ? void 0 : _d.city) && (React.createElement("span", null, primaryCustomerAddress.address.city)),
                ((_e = primaryCustomerAddress === null || primaryCustomerAddress === void 0 ? void 0 : primaryCustomerAddress.address) === null || _e === void 0 ? void 0 : _e.postcode) && (React.createElement("span", null, primaryCustomerAddress.address.postcode)))),
        React.createElement("div", { className: "job-information-container" },
            React.createElement(LabelValuePair, { label: jobType + " reference", value: jobReference ? jobReference : '' }),
            React.createElement(LabelValuePair, { label: "Issued", value: moment(jobSentDate).format(dateFormat) }),
            jobDateLabel && (React.createElement(LabelValuePair, { label: jobDateLabel, value: jobDate })))));
};
export default JobOverviewHeaderLower;
