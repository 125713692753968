import React from 'react';
import LabelValuePair from '../labelValuePair/LabelValuePair';
var RegulatoryRequirementsOverview = function (_a) {
    var regulatoryRequirements = _a.regulatoryRequirements;
    return (React.createElement("div", { className: "regulatory-requirements-overview" },
        React.createElement("div", null,
            React.createElement(LabelValuePair, { label: "Are you approved by the Financial Conduct Authority?", value: (regulatoryRequirements === null || regulatoryRequirements === void 0 ? void 0 : regulatoryRequirements.isFcaAuthorised) ? 'YES' : 'NO' }),
            (regulatoryRequirements === null || regulatoryRequirements === void 0 ? void 0 : regulatoryRequirements.isFcaAuthorised) && (React.createElement(React.Fragment, null,
                React.createElement(LabelValuePair, { label: "Firm Registration Number (FRN)", value: regulatoryRequirements === null || regulatoryRequirements === void 0 ? void 0 : regulatoryRequirements.fcaFirmRegistrationNumber }),
                React.createElement(LabelValuePair, { label: "FCA complaints contact", value: (regulatoryRequirements === null || regulatoryRequirements === void 0 ? void 0 : regulatoryRequirements.fcaComplaintsContactName) + " (" + (regulatoryRequirements === null || regulatoryRequirements === void 0 ? void 0 : regulatoryRequirements.fcaComplaintsContactEmailAddress) + ")" }))),
            !(regulatoryRequirements === null || regulatoryRequirements === void 0 ? void 0 : regulatoryRequirements.isFcaAuthorised) && (React.createElement(LabelValuePair, { label: "Are you in the process of applying for Financial Conduct Authority approval?", value: (regulatoryRequirements === null || regulatoryRequirements === void 0 ? void 0 : regulatoryRequirements.hasAppliedForFcaAuthorisation) ? 'YES'
                    : 'NO' }))),
        React.createElement("div", null,
            React.createElement(LabelValuePair, { label: "Are you registered with the Information Commisioners Office (ICO)?", value: (regulatoryRequirements === null || regulatoryRequirements === void 0 ? void 0 : regulatoryRequirements.isIcoRegistered) ? 'YES' : 'NO' }),
            (regulatoryRequirements === null || regulatoryRequirements === void 0 ? void 0 : regulatoryRequirements.isIcoRegistered) && (React.createElement(React.Fragment, null,
                React.createElement(LabelValuePair, { label: "Data Protection registration number", value: regulatoryRequirements === null || regulatoryRequirements === void 0 ? void 0 : regulatoryRequirements.icoDprReferenceNumber }),
                React.createElement(LabelValuePair, { label: "Have any issues ever been raised by the Information Comissioner?", value: (regulatoryRequirements === null || regulatoryRequirements === void 0 ? void 0 : regulatoryRequirements.hasAnyIcoIssues) ? 'YES' : 'NO' }),
                (regulatoryRequirements === null || regulatoryRequirements === void 0 ? void 0 : regulatoryRequirements.hasAnyIcoIssues) && (React.createElement("div", { className: "multiline-label-value" },
                    React.createElement(LabelValuePair, { label: "Please provide details of any issues that have been raised by the Information Comissioner", value: (regulatoryRequirements === null || regulatoryRequirements === void 0 ? void 0 : regulatoryRequirements.icoIssuesDetails) || '' }))))))));
};
export default RegulatoryRequirementsOverview;
