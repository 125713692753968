import { createStore, combineReducers, applyMiddleware } from 'redux';
import SagaMiddleware from 'redux-saga';

import { reducer as form } from 'redux-form';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import { reducer as app, saga as appSaga } from './app';
import { reducer as users, saga as usersSaga } from './users';
import { reducer as jobs, saga as jobsSaga } from './jobs';
import {
  reducer as accountSettings,
  saga as accountSettingsSaga,
} from './accountSettings';
import {
  reducer as analyticsReducer,
  sagaCreator as analyticsSagaCreator,
} from '@bit/payaca-tech.payaca-core.store.analytics';
import {
  reducer as subscriptionReducer,
  sagaCreator as subscriptionSagaCreator,
} from '@bit/payaca-tech.payaca-core.store.subscription';
import {
  reducer as jobTimelineReducer,
  sagaCreator as jobTimelineSagaCreator,
} from '@bit/payaca-tech.payaca-core.store.job-timeline';
import {
  reducer as accountReducer,
  sagaCreator as accountSagaCreator,
} from '@bit/payaca-tech.payaca-core.store.account';
import {
  reducer as authReducer,
  sagaCreator as authSagaCreator,
} from '@bit/payaca-tech.payaca-core.store.auth';
import {
  reducer as subsidisedFinanceInformationReducer,
  sagaCreator as subsidisedFinanceInformationSagaCreator,
} from '@payaca/store/subsidisedFinanceInformation';
import {
  reducer as listedJobsReducer,
  sagaCreator as listedJobsSagaCreator,
} from '@bit/payaca-tech.payaca-core.store.listed-jobs';
import {
  reducer as listedItemsReducer,
  sagaCreator as listedItemsSagaCreator,
} from '@bit/payaca-tech.payaca-core.store.listed-items';
import {
  reducer as listedUsersReducer,
  sagaCreator as listedUsersSagaCreator,
} from '@bit/payaca-tech.payaca-core.store.listed-users';
import {
  reducer as jobPaymentsReducer,
  sagaCreator as jobPaymentsSagaCreator,
} from '@payaca/store/jobPayments';
import {
  reducer as jobFinanceInformationReducer,
  sagaCreator as jobFinanceInformationSagaCreator,
} from '@bit/payaca-tech.payaca-core.store.job-finance-information';
import {
  reducer as jobsStoreReducer,
  sagaCreator as jobsStoreSagaCreator,
} from '@payaca/store/jobs';
import {
  reducer as lineItemsReducer,
  sagaCreator as lineItemsSagaCreator,
} from '@bit/payaca-tech.payaca-core.store.line-items';
import {
  reducer as customerReducer,
  sagaCreator as customerSagaCreator,
} from '@bit/payaca-tech.payaca-core.store.customer';
import {
  reducer as userReducer,
  sagaCreator as userSagaCreator,
} from '@bit/payaca-tech.payaca-core.store.user';
import {
  reducer as jobPreviewsReducer,
  sagaCreator as jobPreviewsSagaCreator,
} from '@bit/payaca-tech.payaca-core.store.job-previews';
import {
  reducer as dealTimelineReducer,
  sagaCreator as dealTimelineSagaCreator,
} from '@bit/payaca-tech.payaca-core.store.deal-timeline';
import {
  reducer as lineItemGroupsReducer,
  sagaCreator as lineItemGroupsSagaCreator,
} from '@bit/payaca-tech.payaca-core.store.line-item-groups';
import {
  reducer as lineItemsV2Reducer,
  sagaCreator as lineItemsV2SagaCreator,
} from '@payaca/store/lineItemsV2';
import {
  reducer as dealsReducer,
  sagaCreator as dealsSagaCreator,
} from '@payaca/store/deals';
import {
  reducer as fileDownloadReducer,
  sagaCreator as fileDownloadSagaCreator,
} from '@payaca/store/fileDownload';

import { sagaCreator as productAnalyticsSagaCreator } from '@bit/payaca-tech.payaca-core.store.product-analytics';
import posthog from 'posthog-js';

// initialise analytics variables
let posthogClient;
let gaClient;
const { REACT_APP_POSTHOG_PROJECT_ID, REACT_APP_ENV } = process.env;
if (REACT_APP_ENV !== 'development' && REACT_APP_POSTHOG_PROJECT_ID) {
  posthog.init(REACT_APP_POSTHOG_PROJECT_ID, {
    api_host: 'https://posthog.payaca.com',
  });
  posthogClient = posthog;
  gaClient = window.dataLayer;
}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['app'],
};

const appConfig = {
  key: 'app',
  storage,
  blacklist: ['modal'],
};

const TOKEN_KEY = 'payacav1';
const REFRESH_TOKEN_KEY = 'payacav1-refreshToken';

const sagaMiddleware = new SagaMiddleware();

const reducer = combineReducers({
  app: persistReducer(appConfig, app),
  users,
  jobs,
  accountSettings,
  form,
  analytics: analyticsReducer,
  jobTimeline: jobTimelineReducer,
  account: accountReducer,
  auth: authReducer,
  subsidisedFinanceInformation: subsidisedFinanceInformationReducer,
  listedJobs: listedJobsReducer,
  listedUsers: listedUsersReducer,
  listedItems: listedItemsReducer,
  jobPayments: jobPaymentsReducer,
  jobFinanceInformation: jobFinanceInformationReducer,
  jobPreviews: jobPreviewsReducer,
  jobsStore: jobsStoreReducer,
  lineItems: lineItemsReducer,
  subscription: subscriptionReducer,
  customer: customerReducer,
  user: userReducer,
  deals: dealsReducer,
  dealTimeline: dealTimelineReducer,
  lineItemGroups: lineItemGroupsReducer,
  lineItemsV2: lineItemsV2Reducer,
  fileDownload: fileDownloadReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const logger = () => {
  return (next) => (action) => {
    if (process.env.NODE_ENV === 'development') {
      // console.log('[ACTION]:', action);
    }
    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action);
    // Likely the action itself, unless a middleware further in chain changed it.
    return returnValue;
  };
};
export const store = createStore(
  persistedReducer,
  applyMiddleware(sagaMiddleware, logger)
);

export const persistor = persistStore(store);

const analyticsSaga = analyticsSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
});

const jobTimelineSaga = jobTimelineSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
});

const accountSaga = accountSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: async () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
  isNativeApp: false,
});

const subscriptionSaga = subscriptionSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: async () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
});

const subsidisedFinanceInformationSaga =
  subsidisedFinanceInformationSagaCreator({
    apiBaseurl: process.env.REACT_APP_API_HOST,
    getAuthHeader: () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
  });

const listedJobsSaga = listedJobsSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: async () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
});

const listedItemsSaga = listedItemsSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: async () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
});

const authSaga = authSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: async () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
  getRefreshToken: async () => {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },
  storeAuthTokens: async (authTokens) => {
    localStorage.setItem(TOKEN_KEY, authTokens.token);
    localStorage.setItem(REFRESH_TOKEN_KEY, authTokens.refreshToken);
  },
});

const jobPaymentsSaga = jobPaymentsSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: async () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
});

const jobFinanceInformationSaga = jobFinanceInformationSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: async () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
});

const jobsStoreSaga = jobsStoreSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: async () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
  isNativeApp: false,
});

const lineItemsSaga = lineItemsSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: async () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
  isNativeApp: false,
});

const customerSaga = customerSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: async () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
});

const listedUsersSaga = listedUsersSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: async () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
});

const userSaga = userSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: async () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
});
const lineItemGroupsSaga = lineItemGroupsSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: async () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
  isNativeApp: false,
});
const lineItemsV2Saga = lineItemsV2SagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: async () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
  isNativeApp: false,
});

const productAnalyticsSaga = productAnalyticsSagaCreator({
  posthog: posthogClient,
  ga: gaClient,
  isNativeApp: false,
});

const jobPreviewsSaga = jobPreviewsSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
});

const dealsSaga = dealsSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
});

const dealTimelineSaga = dealTimelineSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
});

const fileDownloadSaga = fileDownloadSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
  isNativeApp: false,
});

sagaMiddleware.run(appSaga);
sagaMiddleware.run(usersSaga);
sagaMiddleware.run(jobsSaga);
sagaMiddleware.run(accountSettingsSaga);
sagaMiddleware.run(analyticsSaga);
sagaMiddleware.run(jobTimelineSaga);
sagaMiddleware.run(accountSaga);
sagaMiddleware.run(authSaga);
sagaMiddleware.run(subsidisedFinanceInformationSaga);
sagaMiddleware.run(listedJobsSaga);
sagaMiddleware.run(listedUsersSaga);
sagaMiddleware.run(listedItemsSaga);
sagaMiddleware.run(jobPaymentsSaga);
sagaMiddleware.run(jobFinanceInformationSaga);
sagaMiddleware.run(jobsStoreSaga);
sagaMiddleware.run(lineItemsSaga);
sagaMiddleware.run(subscriptionSaga);
sagaMiddleware.run(customerSaga);
sagaMiddleware.run(userSaga);
sagaMiddleware.run(jobPreviewsSaga);
sagaMiddleware.run(dealTimelineSaga);
sagaMiddleware.run(lineItemGroupsSaga);
sagaMiddleware.run(lineItemsV2Saga);
sagaMiddleware.run(dealsSaga);
sagaMiddleware.run(productAnalyticsSaga);
sagaMiddleware.run(fileDownloadSaga);
