import React, { useCallback } from 'react';
import { Login } from '@/ui/components';
import UnauthenticatedPageWrapper from '../pageWrappers/unauthenticatedPageWrapper/UnauthenticatedPageWrapper';
import './LoginPage.sass';
var LoginPage = function (_a) {
    var history = _a.history;
    var goToRegisterPage = useCallback(function () {
        history.push('/register');
    }, [history]);
    return (React.createElement(UnauthenticatedPageWrapper, { headerContent: React.createElement("div", { className: "flex-container" },
            React.createElement("span", { className: "flex-grow" }),
            React.createElement("span", { className: "sign-up-prompt" },
                "No account? ",
                React.createElement("a", { onClick: goToRegisterPage }, "Sign up"))), className: "login-page" },
        React.createElement("h1", null, "Log in"),
        React.createElement(Login, null)));
};
export default LoginPage;
