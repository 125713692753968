import React from 'react';
import { ContentPanel } from '@payaca/components';
import { Button } from '@payaca/components';
import './AdvertisingMaterialContentPanel.css';
var AdvertisingMaterialContentPanel = function () {
    return (React.createElement(ContentPanel, { className: "advertising-material-content-panel" },
        React.createElement("div", { className: "flex-container flex-center" },
            React.createElement("h4", { className: "flex-grow" }, "Advertising banners"),
            React.createElement("a", { href: "https://drive.google.com/open?id=1Oe4K0QTzkpZpZu8UJz9xtmhhHOquIJWJ", target: "_blank", rel: "noreferrer" },
                React.createElement(Button, null, "View advertising material"))),
        React.createElement("span", null, "This folder contains banners that can be used on your website and social media. If you would like a custom format or design please get in touch.")));
};
export default AdvertisingMaterialContentPanel;
