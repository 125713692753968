import React, { useState } from 'react';
import './PersonalSettingsPage.css';
import ProfilePageWrapper from '../pageWrappers/profilePageWrapper/ProfilePageWrapper';
import PersonalInformationForm from '@/ui/components/personalInformationForm/PersonalInformationForm';
import { Modal } from '@payaca/components/modal';
import ChangePasswordForm from '@/ui/components/changePasswordForm/ChangePasswordForm';
var PersonalSettingsPage = function () {
    var _a = useState(false), showChangePasswordModal = _a[0], setShowChangePasswordModal = _a[1];
    return (React.createElement(ProfilePageWrapper, { title: "Account settings" },
        React.createElement(PersonalInformationForm, { onRequestChangePassword: function () { return setShowChangePasswordModal(true); } }),
        React.createElement(Modal, { isOpen: showChangePasswordModal, onClose: function () { return setShowChangePasswordModal(false); }, title: "Change password", size: "xs" },
            React.createElement(ChangePasswordForm, { onSuccessCallback: function () { return setShowChangePasswordModal(false); } }))));
};
export default PersonalSettingsPage;
