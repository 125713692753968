import React from 'react';
import './TrialPeriodExpiryHoldingPage.css';
import { TrialPeriodExpiryCallout } from '@/ui/components';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
var TrialPeriodExpiryHoldingPage = function () {
    return (React.createElement(AuthenticatedPageWrapper, { className: "trial-period-expiry-holding-page" },
        React.createElement("div", { className: "trial-period-expiry-callout-container" },
            React.createElement(TrialPeriodExpiryCallout, null))));
};
export default TrialPeriodExpiryHoldingPage;
