import React, { useCallback, useMemo } from 'react';
import './HeaderNavigation.sass';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as authActions } from '@bit/payaca-tech.payaca-core.store.auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
var HeaderNavigation = function () {
    var history = useHistory();
    var dispatch = useDispatch();
    var myProfile = useSelector(function (state) { return state.users.myProfile; });
    var isAdmin = useMemo(function () { return myProfile === null || myProfile === void 0 ? void 0 : myProfile.isAdmin; }, [myProfile]);
    var accountAccessInformation = useSelector(function (state) { return state.account.accountAccessInformation; });
    var trialPeriodIsExpired = useMemo(function () {
        return accountAccessInformation
            ? !accountAccessInformation.hasActiveSubscription &&
                accountAccessInformation.isTrialPeriodExpired
            : false;
    }, [accountAccessInformation]);
    var hasExpiredConnection = useMemo(function () {
        var _a, _b, _c, _d;
        var hasExpiredXeroConnection = !!((_d = (_c = (_b = (_a = myProfile === null || myProfile === void 0 ? void 0 : myProfile.accounts) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.integrations) === null || _c === void 0 ? void 0 : _c.xero) === null || _d === void 0 ? void 0 : _d.hasRefreshTokenExpired);
        var oAuthConnections = (myProfile === null || myProfile === void 0 ? void 0 : myProfile.oAuthConnections) || [];
        var zapierConnection = oAuthConnections.find(function (p) { return p.clientName === 'Zapier' || p.clientName === 'zapier test'; });
        var hasExpiredZapierConnection = zapierConnection && zapierConnection.hasConnectionExpired;
        return hasExpiredXeroConnection || hasExpiredZapierConnection;
    }, [myProfile]);
    var isNotOnboarded = useMemo(function () {
        return accountAccessInformation
            ? !accountAccessInformation.hasActiveSubscription &&
                !accountAccessInformation.hasInactiveSubscription &&
                !accountAccessInformation.isOnboarded
            : true;
    }, [accountAccessInformation]);
    var hasExceededUserSeats = useMemo(function () {
        if (!accountAccessInformation)
            return false;
        return (accountAccessInformation.hasActiveSubscription &&
            accountAccessInformation.hasExceededActiveSubscriptionUserSeats);
    }, [accountAccessInformation]);
    var renderNavigationItem = useCallback(function (title, onClick, isDisabled) {
        return (React.createElement("div", { role: "menuitem", className: "navigation-item " + (isDisabled ? 'disabled' : ''), onClick: function () { return !isDisabled && onClick(); } }, title));
    }, [history]);
    var logout = useCallback(function () {
        dispatch(authActions.logout());
    }, [dispatch]);
    return (React.createElement("nav", { className: "header-navigation", "aria-label": "secondary-header" },
        renderNavigationItem('Profile', function () { return history.push('/personalSettings'); }, false),
        isAdmin &&
            renderNavigationItem('My company', function () { return history.push('/companySettings'); }, trialPeriodIsExpired || isNotOnboarded || hasExceededUserSeats),
        isAdmin &&
            renderNavigationItem('Manage subscription', function () { return history.push('/upgradeAccount'); }, false),
        React.createElement("hr", null),
        renderNavigationItem('Finance options', function () { return history.push('/financeOptions'); }, isNotOnboarded),
        renderNavigationItem('Refer a friend', function () { return history.push('/referAFriend'); }, trialPeriodIsExpired || isNotOnboarded),
        isAdmin && (React.createElement(React.Fragment, null,
            React.createElement("hr", null),
            renderNavigationItem(React.createElement("span", { className: "flex-container flex-center" },
                React.createElement("span", { className: "flex-grow" }, "Connections"),
                hasExpiredConnection && (React.createElement(FontAwesomeIcon, { icon: faExclamationTriangle }))), function () { return history.push('/connections'); }, trialPeriodIsExpired || isNotOnboarded || hasExceededUserSeats))),
        React.createElement("hr", null),
        renderNavigationItem('Log out', logout, false)));
};
export default HeaderNavigation;
