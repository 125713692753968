import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '@bit/payaca-tech.payaca-core.component.tooltip';
import JobOptions from './JobOptions';
import { CheckboxField } from '@payaca/components/checkboxField';
var FinanceOptions = function (_a) {
    var hasZeroPercentFinanceOption = _a.hasZeroPercentFinanceOption, onChange = _a.onChange, onZeroPercentFinanceChanged = _a.onZeroPercentFinanceChanged, showFinanceOptions = _a.showFinanceOptions, showZeroPercentFinance = _a.showZeroPercentFinance, showSelinaFinance = _a.showSelinaFinance;
    var isZeroPercentFinanceApproved = useSelector(function (state) { var _a, _b; return (_b = (_a = state.users.myProfile.accounts[0].integrations) === null || _a === void 0 ? void 0 : _a.zeroPercentFinance) === null || _b === void 0 ? void 0 : _b.isApproved; });
    var hasZeroPercentFinanceFeature = useSelector(function (state) { var _a, _b; return (_b = (_a = state.account.accountAccessInformation) === null || _a === void 0 ? void 0 : _a.features) === null || _b === void 0 ? void 0 : _b.hasZeroPercentFinance; });
    var hasEnabledFinancePlan = useSelector(function (state) { var _a, _b; return !!((_b = (_a = state.users) === null || _a === void 0 ? void 0 : _a.financePlans) === null || _b === void 0 ? void 0 : _b.find(function (fp) { return !fp.isDisabled; })); });
    var hasSelinaFinanceFeature = useSelector(function (state) {
        var _a, _b;
        return (_b = (_a = state.account.accountAccessInformation) === null || _a === void 0 ? void 0 : _a.features) === null || _b === void 0 ? void 0 : _b.hasSelinaFinance;
    });
    var disableZeroPercent = useMemo(function () {
        return !(hasZeroPercentFinanceFeature &&
            isZeroPercentFinanceApproved &&
            hasEnabledFinancePlan);
    }, [
        hasEnabledFinancePlan,
        hasZeroPercentFinanceFeature,
        isZeroPercentFinanceApproved,
    ]);
    useEffect(function () {
        // disable showZeroPercentFinace on disabling the checkbox
        if (disableZeroPercent) {
            onChange({
                showZeroPercentFinance: false,
            });
        }
    }, [disableZeroPercent]);
    return (React.createElement(JobOptions, { title: "Finance options" },
        React.createElement("div", null,
            React.createElement(CheckboxField, { name: "showFinanceOptions", label: React.createElement("span", null,
                    "Show unsecured options (up to \u00A325k)",
                    React.createElement(Tooltip, { text: "Available on all plans." })), value: !!showFinanceOptions, onChange: onChange })),
        React.createElement("div", null,
            React.createElement(CheckboxField, { name: "showSelinaFinance", label: React.createElement("span", null,
                    "Show secured options (\u00A325k - \u00A31M)",
                    React.createElement(Tooltip, { text: "Available on Premium and Enterprise plans." })), isDisabled: !hasSelinaFinanceFeature, value: showSelinaFinance, onChange: onChange })),
        hasZeroPercentFinanceOption && (React.createElement("div", null,
            React.createElement(CheckboxField, { name: "showZeroPercentFinance", label: React.createElement("span", null,
                    "Offer bespoke finance plans",
                    React.createElement(Tooltip, { text: "Available on Professional plans and above for eligible businesses and products." })), value: showZeroPercentFinance, onChange: function (value) {
                    onChange(value);
                    onZeroPercentFinanceChanged && onZeroPercentFinanceChanged();
                }, isDisabled: disableZeroPercent })))));
};
export default FinanceOptions;
