import React, { useMemo, useRef } from 'react';
import { isEstimate, isInvoice, isQuote, } from '@payaca/helpers/jobStatusHelper';
import { useSmallViewListener } from '@payaca/utilities/hookUtilities';
import JobOverviewHeaderLower from './JobOverviewHeaderLower';
import JobOverviewHeaderUpper from './JobOverviewHeaderUpper';
import './JobOverviewHeader.sass';
var JobOverviewHeader = function (_a) {
    var account = _a.account, _b = _a.forceDisplayAsProposition, forceDisplayAsProposition = _b === void 0 ? false : _b, job = _a.job, jobFinanceInformation = _a.jobFinanceInformation, customer = _a.customer;
    var jobOverviewHeaderRef = useRef(null);
    var isSmallView = useSmallViewListener(jobOverviewHeaderRef, 600)[0];
    var jobType = useMemo(function () {
        if (isInvoice(job.status))
            return 'Invoice';
        if (isQuote(job.status))
            return 'Quote';
        if (isEstimate(job.status))
            return 'Estimate';
        return 'Job';
    }, [job]);
    var jobDescription = useMemo(function () { return job.jobDescription; }, [job]);
    return (React.createElement("div", { ref: jobOverviewHeaderRef, className: "job-overview-header" + (isSmallView ? ' small-view' : '') },
        React.createElement(JobOverviewHeaderUpper, { account: account }),
        React.createElement(JobOverviewHeaderLower, { customer: customer, jobType: jobType, job: job, jobFinanceInformation: jobFinanceInformation, forceDisplayAsProposition: forceDisplayAsProposition }),
        jobDescription && (React.createElement("div", { className: "job-overview-description-container" },
            React.createElement("p", null, jobDescription)))));
};
export default JobOverviewHeader;
