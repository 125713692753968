import React from 'react';
import './AnalyticsDashboardUnsubscribedHoldingContent.css';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ContentPanel } from '@payaca/components/contentPanel';
import { Button } from '@payaca/components';
var AnalyticsDashboardUnsubscribedHoldingContent = function () {
    return (React.createElement("div", { className: "analytics-dashboard-unsubscribed-holding-content" },
        React.createElement(ContentPanel, null,
            React.createElement("h4", null, "Example"),
            React.createElement("p", null, 'If you subscribe you can track your business performance and increasing profitability here.'),
            React.createElement("div", { className: "screenshot-container" },
                React.createElement("img", { src: "https://storage.googleapis.com/payaca-prod-assets/analytics-dashboard-screenshot.png" })),
            React.createElement("a", { href: "/upgradeAccount" },
                React.createElement(Button, { iconAfter: faChevronRight }, "Subscribe now")))));
};
export default AnalyticsDashboardUnsubscribedHoldingContent;
