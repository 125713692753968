import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJob, getActiveJobFinanceInformationByJobId, getDealByJobId, } from '@/utils/stateAccessors';
import JobOverview from './JobOverview';
import { FileDownloadTypes } from '@payaca/types/fileDownloadTypes';
import { actions as dealsActions } from '@bit/payaca-tech.payaca-core.store.deals';
import { actions as jobPaymentActions } from '@bit/payaca-tech.payaca-core.store.job-payments';
import { actions as customerActions } from '@bit/payaca-tech.payaca-core.store.customer';
import { actions as jobsActions } from '@bit/payaca-tech.payaca-core.store.jobs';
import { actions as jobFinanceInformationActions } from '@bit/payaca-tech.payaca-core.store.job-finance-information';
import { actions as fileDownloadActions } from '@payaca/store/fileDownload';
var JobOverviewRetrievalWrapper = function (_a) {
    var jobId = _a.jobId;
    var dispatch = useDispatch();
    var job = useSelector(function (state) {
        return getJob(state, jobId);
    });
    var deal = useSelector(function (state) {
        return getDealByJobId(state, jobId);
    });
    var activeJobFinanceInformation = useSelector(function (state) {
        return getActiveJobFinanceInformationByJobId(state, jobId);
    });
    useEffect(function () {
        dispatch(jobsActions.requestGetJobWithJobGroupsAndJobLineItems(jobId));
        dispatch(jobsActions.requestGetJobAttachmentsForJob(jobId));
        dispatch(fileDownloadActions.requestDownloadFile(jobId, FileDownloadTypes.QUOTE_SIGNATURE_IMAGE));
    }, []);
    useEffect(function () {
        if (job) {
            dispatch(dealsActions.requestGetDeal(job.dealId));
            dispatch(jobPaymentActions.requestGetJobPaymentsForDeal(job.dealId));
            dispatch(jobFinanceInformationActions.requestGetJobFinanceInformationForDeal(job.dealId));
            job.jobLineItemIds.forEach(function (jobLineItemId) {
                dispatch(jobsActions.requestGetJobLineItemAttachmentsForJobLineItem(jobLineItemId));
            });
        }
    }, [job]);
    useEffect(function () {
        if (deal) {
            deal.customerId &&
                dispatch(customerActions.requestGetAndSetCurrentCustomer(deal.customerId));
        }
    }, [deal]);
    useEffect(function () {
        if (activeJobFinanceInformation) {
            dispatch(fileDownloadActions.requestDownloadFile(jobId, FileDownloadTypes.SATISFACTION_NOTE_SIGNATURE));
        }
    }, [activeJobFinanceInformation]);
    return React.createElement(JobOverview, { jobId: jobId });
};
export default JobOverviewRetrievalWrapper;
