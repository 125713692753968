import { faCheck, faPen, faSave, faTimes, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import BasicField from '../basicField/BasicField';
import MiniLoader from '../miniLoader/MiniLoader';
import './PersistedFileControl.sass';
var PersistedFileControl = function (_a) {
    var persistedFile = _a.persistedFile, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, _c = _a.allowFileNameModification, allowFileNameModification = _c === void 0 ? false : _c, _d = _a.isPersistingFileName, isPersistingFileName = _d === void 0 ? false : _d, _e = _a.isRemoving, isRemoving = _e === void 0 ? false : _e, persistFileName = _a.persistFileName, removeFile = _a.removeFile;
    var _f = useState(false), isModifyingFileName = _f[0], setIsModifyingFileName = _f[1];
    var _g = useState(persistedFile.fileName), fileName = _g[0], setFileName = _g[1];
    var _h = useState(false), fileNamePersistIsRequired = _h[0], setFileNamePersistIsRequired = _h[1];
    useEffect(function () {
        if (!isPersistingFileName && persistedFile.fileName === fileName) {
            setIsModifyingFileName(false);
        }
    }, [isPersistingFileName, persistedFile]);
    useEffect(function () {
        if (fileNamePersistIsRequired) {
            persistFileName && persistFileName(fileName);
            setFileNamePersistIsRequired(false);
        }
    }, [fileName, fileNamePersistIsRequired, persistFileName]);
    return (React.createElement("div", { className: "persisted-file-control" },
        isRemoving || isPersistingFileName ? (React.createElement(MiniLoader, null)) : (!isModifyingFileName && React.createElement(FontAwesomeIcon, { icon: faCheck })),
        !isModifyingFileName && (React.createElement("span", { className: "file-name" }, persistedFile.fileName)),
        allowFileNameModification &&
            !isModifyingFileName &&
            persistFileName &&
            !isDisabled && (React.createElement("span", { className: "modify-file-name-trigger", onClick: function () {
                setIsModifyingFileName(true);
            } },
            React.createElement(FontAwesomeIcon, { icon: faPen }))),
        isModifyingFileName && persistFileName && (React.createElement(React.Fragment, null,
            React.createElement(BasicField, { name: "fileName", value: fileName, onChange: function (value) {
                    setFileName(value.fileName);
                }, changeTimeoutMs: 2000, onChangeTimeout: function () { return setFileNamePersistIsRequired(true); } }),
            React.createElement("span", { className: "persist-file-name-trigger", onClick: function () { return setFileNamePersistIsRequired(true); } },
                React.createElement(FontAwesomeIcon, { icon: faSave }),
                " save"))),
        !isDisabled && !isModifyingFileName && (React.createElement("span", { className: "remove-file-trigger", onClick: function () { return !isRemoving && removeFile(persistedFile.identifier); } },
            React.createElement(FontAwesomeIcon, { icon: faTimes })))));
};
export default PersistedFileControl;
