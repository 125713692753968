import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { CompanyType, } from '@payaca/types/subsidisedFinanceInformationTypes';
import LabelValuePair from '../labelValuePair/LabelValuePair';
var BusinessInformationOverview = function (_a) {
    var businessInformation = _a.businessInformation;
    var isSoleTrader = useMemo(function () {
        return (businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.companyType) === CompanyType.SOLE_TRADER;
    }, [businessInformation]);
    var principalPlaceOfBusiness = useMemo(function () {
        if (businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.isPrincipalPlaceOfBusinessSameAsRegisteredAddress) {
            return businessInformation.registeredAddress;
        }
        return businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.principalPlaceOfBusiness;
    }, [businessInformation]);
    var renderAddress = useCallback(function (address) {
        var addressComponents = [
            address.line1,
            address.line2,
            address.city,
            address.postcode,
        ].filter(function (x) { return !!x; });
        return (React.createElement("div", null, addressComponents.length ? addressComponents.join(',\n') : '-'));
    }, []);
    var renderDirectors = useCallback(function (directors) {
        if (!directors || !directors.length) {
            return React.createElement("span", null, "None");
        }
        return (React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { align: "left" }, "Name"),
                    React.createElement("th", { align: "left" }, "Date of birth"),
                    !isSoleTrader && React.createElement("th", { align: "left" }, "Title within company"),
                    React.createElement("th", { align: "left" }, "Telephone number"),
                    React.createElement("th", { align: "left" }, "Email address"),
                    React.createElement("th", { align: "left" }, "Home address"),
                    React.createElement("th", { align: "left" }, "Previous home address"))),
            React.createElement("tbody", null, directors.map(function (director, index) {
                return (React.createElement("tr", { key: index },
                    React.createElement("td", { align: "left" },
                        React.createElement("span", { className: "sm-view-label" }, "Name:"),
                        director.name),
                    React.createElement("td", { align: "left" },
                        React.createElement("span", { className: "sm-view-label" }, "Date of birth:"),
                        moment(director.dateOfBirth).format('DD MMM yyyy')),
                    !isSoleTrader && (React.createElement("td", { align: "left" },
                        React.createElement("span", { className: "sm-view-label" }, "Title within company:"),
                        director.titleWithinCompany)),
                    React.createElement("td", { align: "left" },
                        React.createElement("span", { className: "sm-view-label" }, "Telephone number:"),
                        "+",
                        director.telephoneNumber),
                    React.createElement("td", { align: "left" },
                        React.createElement("span", { className: "sm-view-label" }, "Email address:"),
                        director.emailAddress),
                    React.createElement("td", { align: "left", className: "address-container" },
                        React.createElement("span", { className: "sm-view-label" }, "Home address:"),
                        renderAddress(director.homeAddress)),
                    React.createElement("td", { align: "left", className: "address-container" },
                        React.createElement("span", { className: "sm-view-label" }, "Previous home address:"),
                        renderAddress(director.previousHomeAddress))));
            }))));
    }, []);
    var renderCompanyContacts = useCallback(function (companyContacts) {
        if (!companyContacts || !companyContacts.length) {
            return React.createElement("span", null, "None");
        }
        return (React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { align: "left" }, "Department"),
                    React.createElement("th", { align: "left" }, "Name"),
                    React.createElement("th", { align: "left" }, "Email address"))),
            React.createElement("tbody", null, companyContacts.map(function (companyContact, index) {
                return (React.createElement("tr", { key: index },
                    React.createElement("td", { align: "left" },
                        React.createElement("span", { className: "sm-view-label" }, "Department:"),
                        companyContact.department),
                    React.createElement("td", { align: "left" },
                        React.createElement("span", { className: "sm-view-label" }, "Name:"),
                        companyContact.name),
                    React.createElement("td", { align: "left" },
                        React.createElement("span", { className: "sm-view-label" }, "Email:"),
                        companyContact.emailAddress)));
            }))));
    }, []);
    var renderAssociatedBusinesses = useCallback(function (associatedBusinesses) {
        if (!associatedBusinesses || !associatedBusinesses.length) {
            return React.createElement("span", null, "None");
        }
        return (React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { align: "left" }, "Company name"),
                    React.createElement("th", { align: "left" }, "Registration details"),
                    React.createElement("th", { align: "left" }, "Nature of relationship"))),
            React.createElement("tbody", null, associatedBusinesses.map(function (associatedBusiness, index) {
                return (React.createElement("tr", { key: index },
                    React.createElement("td", { align: "left" },
                        React.createElement("span", { className: "sm-view-label" }, "Company name:"),
                        associatedBusiness.companyName),
                    React.createElement("td", { align: "left" },
                        React.createElement("span", { className: "sm-view-label" }, "Registration details:"),
                        React.createElement("div", { style: { whiteSpace: 'pre-wrap' } }, associatedBusiness.registrationDetails)),
                    React.createElement("td", { align: "left" },
                        React.createElement("span", { className: "sm-view-label" }, "Nature of relationship:"),
                        React.createElement("div", { style: { whiteSpace: 'pre-wrap' } }, associatedBusiness.natureOfRelationship))));
            }))));
    }, []);
    return (React.createElement("div", { className: "business-information-overview" },
        React.createElement("div", { className: "flex-container" },
            React.createElement("div", { className: "basic-information-container flex-grow" },
                React.createElement("div", null,
                    React.createElement(LabelValuePair, { label: "Company name", value: (businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.companyName) || '-' }),
                    React.createElement(LabelValuePair, { label: "Company type", value: (businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.companyType) || '-' })),
                React.createElement("div", null,
                    React.createElement(LabelValuePair, { label: "Trading names", value: (businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.tradingNames) || '-' }),
                    !isSoleTrader && (React.createElement(React.Fragment, null,
                        React.createElement(LabelValuePair, { label: "Company registration number", value: (businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.companyRegistrationNumber) || '-' }),
                        React.createElement(LabelValuePair, { label: "Business trading commencement date", value: (businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.businessTradingCommencementDate) ? moment(businessInformation.businessTradingCommencementDate).format('DD MMMM yyyy')
                                : '-' }),
                        React.createElement(LabelValuePair, { label: "Main telephone number", value: (businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.mainTelephoneNumber) || '-' }))),
                    React.createElement(LabelValuePair, { label: "Website addresses", value: (businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.websiteAddresses) || '-' }))),
            !isSoleTrader && (React.createElement("div", { className: "flex-grow addresses-container" },
                React.createElement("div", null,
                    React.createElement("strong", null, "Address of registered office"),
                    (businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.registeredAddress) &&
                        renderAddress(businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.registeredAddress)),
                React.createElement("div", null,
                    React.createElement("strong", null, "Principal place of business"),
                    principalPlaceOfBusiness &&
                        renderAddress(principalPlaceOfBusiness))))),
        React.createElement("div", { className: "directors-container" },
            React.createElement("div", null,
                React.createElement("strong", null, isSoleTrader ? 'Your contact information' : 'Directors')),
            renderDirectors(businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.directors)),
        !isSoleTrader && (React.createElement("div", { className: "company-contacts-container" },
            React.createElement("div", null,
                React.createElement("strong", null, "Company contacts")),
            renderCompanyContacts(businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.companyContacts))),
        React.createElement("div", { className: "associated-businesses-container" },
            React.createElement("div", null,
                React.createElement("strong", null, "Associated businesses")),
            renderAssociatedBusinesses(businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.associatedBusinesses))));
};
export default BusinessInformationOverview;
