import React, { Fragment, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHammer } from '@fortawesome/free-solid-svg-icons';
import { actions as appActions } from '@/api/app';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import './MaintenanceBanner.sass';
var MaintenanceBanner = function () {
    var dispatch = useDispatch();
    useEffect(function () {
        dispatch(appActions.requestGetMaintenance());
    }, []);
    var maintenanceSchedule = useSelector(function (state) { return state.app.maintenanceSchedule; });
    var hideMaintenanceBanner = useSelector(function (state) { return state.app.hideMaintenanceBanner; });
    var lastReadFutureMaintenaceMessageDownTimeAt = useSelector(function (state) { return state.app.lastReadFutureMaintenaceMessageDownTimeAt; });
    var lastReadPastMaintenanceUpTimeAt = localStorage.getItem('payaca-pastMaintenaceMessageUpTimeAt');
    var hasUnreadPastMessage = useMemo(function () {
        if (!(maintenanceSchedule === null || maintenanceSchedule === void 0 ? void 0 : maintenanceSchedule.past)) {
            return false;
        }
        // show past maintenace message if user has not closed banner with it in (stored in local storage)
        return (maintenanceSchedule.past.upTimeAt !== lastReadPastMaintenanceUpTimeAt);
    }, [lastReadPastMaintenanceUpTimeAt, maintenanceSchedule]);
    var canHideFutureMessage = useMemo(function () {
        if (!(maintenanceSchedule === null || maintenanceSchedule === void 0 ? void 0 : maintenanceSchedule.future)) {
            return true;
        }
        // can hide future maintenance message if user has seen it and closed it (saved to store)
        return (maintenanceSchedule.future.downTimeAt ===
            lastReadFutureMaintenaceMessageDownTimeAt);
    }, [lastReadFutureMaintenaceMessageDownTimeAt, maintenanceSchedule]);
    // user has closed banner, future message has been read and no unread past message
    var hideBanner = useMemo(function () {
        return (hideMaintenanceBanner && canHideFutureMessage && !hasUnreadPastMessage);
    }, [canHideFutureMessage, hasUnreadPastMessage, hideMaintenanceBanner]);
    // no unread past message and no future message
    if (!hasUnreadPastMessage && !(maintenanceSchedule === null || maintenanceSchedule === void 0 ? void 0 : maintenanceSchedule.future)) {
        return null;
    }
    return (React.createElement("div", { className: "maintenance-banner" + (hideBanner ? ' hidden' : '') },
        (maintenanceSchedule === null || maintenanceSchedule === void 0 ? void 0 : maintenanceSchedule.future) && (React.createElement("span", { className: "hammer-icon-wrapper", onClick: function () { return dispatch(appActions.hideMaintenanceBanner(false)); } },
            React.createElement(FontAwesomeIcon, { icon: faHammer, className: "hammer-icon" }))),
        !hideBanner && (React.createElement(Fragment, null,
            React.createElement("div", { className: "inner-banner-wrapper" },
                hasUnreadPastMessage && (React.createElement("div", { className: "maintenace-message past" },
                    "We made some software updates, click refresh to see whats new.",
                    React.createElement(Button, { className: "reload", onClick: function () {
                            localStorage.setItem('payaca-pastMaintenaceMessageUpTimeAt', maintenanceSchedule.past.upTimeAt);
                            window.location.replace(window.location.href);
                        } }, "Refresh"))),
                (maintenanceSchedule === null || maintenanceSchedule === void 0 ? void 0 : maintenanceSchedule.future) && !hasUnreadPastMessage && (React.createElement("div", { className: "maintenace-message future" },
                    "We have scheduled maintenance from",
                    ' ',
                    React.createElement("span", { className: "bold-weight" }, maintenanceSchedule.future.downTimeAt),
                    ' ',
                    "until",
                    ' ',
                    React.createElement("span", { className: "bold-weight" }, maintenanceSchedule.future.upTimeAt),
                    ' ',
                    "in which our site will be unavailable. We are sorry for any inconvenience, have a nice day.",
                    React.createElement(Button, { className: "dismiss", styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () {
                            if (maintenanceSchedule === null || maintenanceSchedule === void 0 ? void 0 : maintenanceSchedule.future) {
                                dispatch(appActions.setLastReadFutureMaintenaceMessageDownTimeAt(maintenanceSchedule.future.downTimeAt));
                            }
                            dispatch(appActions.hideMaintenanceBanner(true));
                        } }, "Dismiss"))))))));
};
export default MaintenanceBanner;
