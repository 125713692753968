import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import LoaderOverlay from '@bit/payaca-tech.payaca-core.component.loader-overlay';
import { StatusError } from '@/ui/components';
import PayacaLogo from '@/assets/images/oAuth/icon.png';
import { actions as authActions } from '@bit/payaca-tech.payaca-core.store.auth';
import { getAppLogo, getScopeList } from '@/helpers/oAuthHelper';
import './GrantAccessPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faExchangeAlt, } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@payaca/components';
var GrantAccessPage = function (_a) {
    var location = _a.location, history = _a.history;
    var dispatch = useDispatch();
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(null), accessError = _c[0], setAccessError = _c[1];
    var _d = useState(''), appName = _d[0], setAppName = _d[1];
    var _e = useState([]), appScopes = _e[0], setAppScopes = _e[1];
    var _f = useState(false), allowingAuthorise = _f[0], setAllowingAuthorise = _f[1];
    var _g = useState(false), denyingAuthorise = _g[0], setDenyingAuthorise = _g[1];
    var _h = useState({ title: '', onClick: function () { return null; } }), statusErrorButton = _h[0], setStatusErrorButton = _h[1];
    var isAdmin = useSelector(function (state) { return state.users.myProfile.isAdmin; });
    var previousPathname = useSelector(function (state) { return state.app.previousPathname; });
    useEffect(function () {
        if (!query.path || !query.app || !query.scope) {
            // missing query params - show error
            setAccessError({
                error: 'Invalid url',
                error_description: 'Grant access query is invalid',
            });
        }
        else if (!isAdmin) {
            // user is not admin - deny access
            setAccessError({
                error: 'User does not have permission',
                error_description: "Connecting with " + query.app + " requires you to have a Payaca admin role",
                error_resolve: 'Try contacting an account admin for this permission',
            });
            setStatusErrorButton({
                title: 'Log in with a different email',
                onClick: function () { return logout(); },
            });
        }
        else {
            setAppName(decodeURIComponent(query.app));
            // set scopes on page
            var scopes = getScopeList(query.scope);
            setAppScopes(scopes);
        }
        setIsLoading(false);
    }, []);
    var logout = useCallback(function () {
        dispatch(authActions.logout());
        history.push(previousPathname);
    }, [history, previousPathname]);
    var query = useMemo(function () {
        return qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });
    }, [location]);
    var grantAccess = function (doAllow) {
        setAllowingAuthorise(doAllow);
        setDenyingAuthorise(!doAllow);
        var payload = {
            path: query.path,
            scopes: query.scope,
        };
        dispatch(authActions.authorise(payload, doAllow, function (err, resp) {
            if (resp) {
                console.log("Access " + (doAllow ? 'allowed' : 'denied') + ", redirecting...");
                // @ts-ignore
                window.location.href = resp.redirectUri;
            }
            else {
                setAccessError(err);
                setAllowingAuthorise(false);
                setDenyingAuthorise(false);
            }
        }));
    };
    if (isLoading) {
        return React.createElement(LoaderOverlay, null);
    }
    else if (accessError) {
        return (React.createElement(StatusError, { title: "Authorisation Error", status: accessError.status, errorName: accessError.error, errorDescriptionMessage: accessError.error_description, errorResolveMessage: accessError.error_resolve ||
                "Try going back to " + appName + " and authorising again. If the problem persists, please contact us for help.", buttonTitle: statusErrorButton.title, buttonOnClick: statusErrorButton.onClick }));
    }
    else {
        return (React.createElement("div", { className: "grant-access" },
            React.createElement("div", { className: "grant-access-content" },
                React.createElement("div", { className: "logo-wrapper" },
                    React.createElement("img", { className: "logo", src: getAppLogo(appName), alt: "3rd party logo" }),
                    React.createElement(FontAwesomeIcon, { icon: faExchangeAlt, className: "exchange icon" }),
                    React.createElement("img", { className: "logo", src: PayacaLogo, alt: "Payaca logo" })),
                React.createElement("h3", null,
                    appName,
                    " would like access to your Payaca account"),
                appScopes && (React.createElement("div", { className: "app-scopes-wrapper" },
                    React.createElement("h4", null,
                        "This will allow ",
                        appName,
                        " to:"),
                    React.createElement("div", { className: "app-scopes" }, appScopes.map(function (scope, i) {
                        // TODO: show some sort of scope error if there are no scopes
                        return (React.createElement("div", { className: "app-scope", key: i },
                            scope.icon && (React.createElement(FontAwesomeIcon, { icon: scope.icon, className: "scope icon" })),
                            scope.description));
                    })))),
                React.createElement(Button, { onClick: function () { return grantAccess(true); }, isProcessing: allowingAuthorise, isDisabled: allowingAuthorise || denyingAuthorise }, "Allow access"),
                React.createElement(Button, { onClick: function () { return grantAccess(false); }, isProcessing: denyingAuthorise, isDisabled: allowingAuthorise || denyingAuthorise }, "Deny access")),
            React.createElement("div", { className: "grant-access-footer" },
                React.createElement("span", { onClick: function () { return logout(); }, className: "logout-action" },
                    "Log in with a different email",
                    React.createElement(FontAwesomeIcon, { icon: faChevronRight })))));
    }
};
export default GrantAccessPage;
