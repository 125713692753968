var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState, useCallback, useMemo, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { actions as subsidisedFinanceInformationActions } from '@payaca/store/subsidisedFinanceInformation';
import { getFieldValidatorsForRegulatoryRequirements } from '@payaca/helpers/subsidisedFinanceInformationValidationHelper';
import { RadioGroupField } from '@payaca/components/radioGroupField';
import { MiniLoader, ResponsiveViewWrapper, ValidatedForm, } from '@payaca/components';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { TextareaField } from '@payaca/components/textareaField';
import { BasicField } from '@payaca/components/basicField';
import FcaComplaintsContactFormSubsection from '../fcaCpomplaintsContactFormSubsection/FcaComplaintsContactFormSubsection';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import './RegulatoryRequirementsFormSection.sass';
var IS_FCA_AUTHORISED_OPTIONS = [
    { value: false, label: 'No' },
    { value: true, label: 'Yes' },
];
var HAS_APPLIED_FOR_FCA_AUTHORISATION_OPTIONS = [
    {
        value: false,
        label: 'No, I wish to remain unregulated on 12 months Interest Free Credit Only',
    },
    { value: true, label: 'Yes' },
];
var IS_ICO_REGISTERED_OPTIONS = [
    { value: false, label: 'No' },
    { value: true, label: 'Yes' },
];
var HAS_ICO_ISSUES_OPTIONS = [
    { value: false, label: 'No' },
    { value: true, label: 'Yes' },
];
var RegulatoryRequirementsFormSection = function () {
    var dispatch = useDispatch();
    var companyContacts = useSelector(function (state) {
        var _a, _b;
        return (_b = (_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.businessInformation) === null || _b === void 0 ? void 0 : _b.companyContacts;
    });
    var regulatoryRequirements = useSelector(function (state) {
        var _a;
        return (_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.regulatoryRequirements;
    });
    var isGettingOrPersisting = useSelector(function (state) {
        var _a, _b;
        return (((_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.isGettingRegulatoryRequirements) || ((_b = state.subsidisedFinanceInformation) === null || _b === void 0 ? void 0 : _b.isPersistingRegulatoryRequirements));
    });
    var _a = useState(), triggerValidationAt = _a[0], setTriggerValidationAt = _a[1];
    var fieldValidators = useMemo(function () {
        return getFieldValidatorsForRegulatoryRequirements();
    }, []);
    useEffect(function () {
        dispatch(subsidisedFinanceInformationActions.requestGetRegulatoryRequirements());
        dispatch(subsidisedFinanceInformationActions.requestGetBusinessInformation());
    }, []);
    var icoGuidanceCopy = useMemo(function () {
        return (React.createElement("p", null,
            "As you are ICO registered, we require your Data Protection Registration number in order to complete your application. Please ensure that you provide the correct number for your company.",
            ' ',
            React.createElement("a", { href: "https://ico.org.uk/ESDWebPages/Search", target: "_blank", rel: "noopener noreferrer" }, "Click here"),
            ' ',
            "to check."));
    }, []);
    var fcaGuidanceCopy = useMemo(function () {
        return (React.createElement("p", null,
            "As you are FCA approved, we require your FRN number in order to complete your application. Please ensure that you provide the correct FRN number for your company.",
            ' ',
            React.createElement("a", { href: "https://register.fca.org.uk/s/", target: "_blank", rel: "noopener noreferrer" }, "Click here"),
            ' ',
            "to check."));
    }, []);
    var onSaveProgress = useCallback(function (modifiedRegulatoryRequirements) {
        dispatch(subsidisedFinanceInformationActions.requestPersistRegulatoryRequirements(modifiedRegulatoryRequirements));
    }, [dispatch]);
    var onSaveAndContinue = useCallback(function (modifiedRegulatoryRequirements) {
        dispatch(subsidisedFinanceInformationActions.requestPersistCompleteRegulatoryRequirements(modifiedRegulatoryRequirements));
    }, [dispatch]);
    var renderActionButtons = useCallback(function (isValid, formState) {
        var modifiedRegulatoryRequirements = formState;
        return (React.createElement("div", { className: "action-buttons-container" }, isGettingOrPersisting ? (React.createElement("div", { className: "loader-container flex-grow" },
            React.createElement(MiniLoader, null))) : (React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: function () { return onSaveProgress(modifiedRegulatoryRequirements); }, styleVariant: ButtonStyleVariant.OUTSIZE, isOutlined: true }, 'Save progress'),
            React.createElement("div", { onClick: function () { return !isValid && setTriggerValidationAt(new Date()); } },
                React.createElement(Button, { isDisabled: !isValid, onClick: function () {
                        return isValid &&
                            onSaveAndContinue(modifiedRegulatoryRequirements);
                    }, styleVariant: ButtonStyleVariant.OUTSIZE, iconAfter: faChevronRight }, 'Save and continue'))))));
    }, [onSaveProgress, onSaveAndContinue, isGettingOrPersisting]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "field-group fca-field-group" },
                React.createElement(RadioGroupField, { value: formState.isFcaAuthorised || false, options: IS_FCA_AUTHORISED_OPTIONS, label: "Are you approved by the Financial Conduct Authority?", onChange: function (value) {
                        return onFieldChange(__assign(__assign({}, value), { hasAppliedForFcaAuthorisation: false, fcaFirmRegistrationNumber: null, fcaComplaintsContactEmailAddress: null, fcaComplaintsContactName: null }));
                    }, onTouch: onFieldTouch, name: "isFcaAuthorised" }),
                !formState.isFcaAuthorised && (React.createElement(RadioGroupField, { value: formState.hasAppliedForFcaAuthorisation || false, options: HAS_APPLIED_FOR_FCA_AUTHORISATION_OPTIONS, label: "Are you in the process of applying for Financial Conduct Authority approval?", onChange: onFieldChange, onTouch: onFieldTouch, name: "hasAppliedForFcaAuthorisation" })),
                formState.isFcaAuthorised && (React.createElement(React.Fragment, null,
                    fcaGuidanceCopy,
                    React.createElement(ValidatedFieldWrapper, { validationResult: validationState.fcaFirmRegistrationNumber, isTouched: touchedState.fcaFirmRegistrationNumber || false },
                        React.createElement(BasicField, { label: "Firm Registration Number (FRN)", description: "Please double check that you have entered this correctly before submitting", value: formState.fcaFirmRegistrationNumber || '', isRequired: true, name: 'fcaFirmRegistrationNumber', onChange: onFieldChange, onTouch: onFieldTouch, styleVariant: InputStyleVariant.OUTSIZE })),
                    React.createElement(FcaComplaintsContactFormSubsection, { existingCompanyContacts: companyContacts, onChange: onFieldChange, onTouch: onFieldTouch, validationState: validationState, touchedState: touchedState, fcaComplaintsContactEmailAddress: formState.fcaComplaintsContactEmailAddress, fcaComplaintsContactName: formState.fcaComplaintsContactName })))),
            React.createElement("div", { className: "field-group ico-field-group" },
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.isIcoRegistered, isTouched: touchedState.isIcoRegistered || false },
                    React.createElement(RadioGroupField, { value: formState.isIcoRegistered || false, options: IS_ICO_REGISTERED_OPTIONS, label: "Are you registered with the Information Commisioners Office (ICO)?", description: React.createElement("a", { href: "https://ico.org.uk/for-organisations/data-protection-fee/", target: "_blank", rel: "noopener noreferrer" }, "Register with the ICO here"), onChange: function (value) {
                            return onFieldChange(__assign(__assign({}, value), { icoDprReferenceNumber: null, hasAnyIcoIssues: false, icoIssuesDetails: null }));
                        }, onTouch: onFieldTouch, name: "isIcoRegistered", isRequired: true })),
                formState.isIcoRegistered && (React.createElement(React.Fragment, null,
                    icoGuidanceCopy,
                    React.createElement(ValidatedFieldWrapper, { validationResult: validationState.icoDprReferenceNumber, isTouched: touchedState.icoDprReferenceNumber || false },
                        React.createElement(BasicField, { label: "Please enter your Data Protection registration number", description: "Please double check that you have entered this correctly before submitting", value: formState.icoDprReferenceNumber || '', isRequired: true, name: 'icoDprReferenceNumber', onChange: onFieldChange, onTouch: onFieldTouch, styleVariant: InputStyleVariant.OUTSIZE })),
                    React.createElement(ValidatedFieldWrapper, { validationResult: validationState.hasAnyIcoIssues, isTouched: touchedState.hasAnyIcoIssues || false },
                        React.createElement(RadioGroupField, { value: formState.hasAnyIcoIssues || false, options: HAS_ICO_ISSUES_OPTIONS, label: "Have any issues ever been raised by the Information Comissioner?", onChange: function (value) {
                                return onFieldChange(__assign(__assign({}, value), { icoIssuesDetails: null }));
                            }, onTouch: onFieldTouch, name: "hasAnyIcoIssues", isRequired: true })),
                    formState.hasAnyIcoIssues && (React.createElement(ValidatedFieldWrapper, { validationResult: validationState.icoIssuesDetails, isTouched: touchedState.icoIssuesDetails || false },
                        React.createElement(TextareaField, { name: "icoIssuesDetails", value: formState.icoIssuesDetails || '', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, label: "Please provide details of any issues that have been raised by the Information Comissioner", styleVariant: InputStyleVariant.OUTSIZE })))))),
            renderActionButtons(isValid, formState)));
    }, [companyContacts, renderActionButtons]);
    return (React.createElement(ResponsiveViewWrapper, { className: "regulatory-requirements-form-section", downBreakpointSm: 600 },
        React.createElement("div", { style: { position: 'relative' } },
            React.createElement(ValidatedForm, { triggerValidationAt: triggerValidationAt, initialFormState: regulatoryRequirements, fieldValidators: fieldValidators, renderFormContents: renderFormContents }))));
};
export default RegulatoryRequirementsFormSection;
