import React from 'react';
import PageWrapper from '../pageWrapper/PageWrapper';
import './SplitPageWrapper.sass';
var SplitPageWrapper = function (_a) {
    var className = _a.className, lhsContent = _a.lhsContent, rhsContent = _a.rhsContent;
    return (React.createElement(PageWrapper, { className: "split-page-wrapper " + (className ? className : '') },
        React.createElement("div", { className: "lhs-rhs-container" },
            React.createElement("div", { className: "lhs" },
                React.createElement("div", { className: "lhs-inner" }, lhsContent)),
            React.createElement("div", { className: "rhs" },
                React.createElement("div", { className: "rhs-inner" }, rhsContent)))));
};
export default SplitPageWrapper;
