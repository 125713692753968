var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useCallback, useMemo, useState, } from 'react';
import { CardElement, useStripe, useElements, PaymentRequestButtonElement, } from '@stripe/react-stripe-js';
import { InputStyleVariant, InputWrapper, } from '@payaca/components/inputWrapper';
import './SubscriptionPaymentMethodControl.sass';
var SubscriptionPaymentMethodControl = function (_a) {
    var paymentAmount = _a.paymentAmount, onPaymentMethodChange = _a.onPaymentMethodChange, renderActions = _a.renderActions;
    var stripe = useStripe();
    var elements = useElements();
    var _b = useState(), paymentRequest = _b[0], setPaymentRequest = _b[1];
    var _c = useState(), error = _c[0], setError = _c[1];
    var applePayButton = useMemo(function () {
        if (paymentRequest) {
            return (React.createElement(PaymentRequestButtonElement, { options: {
                    paymentRequest: paymentRequest,
                }, key: Math.random() }));
        }
    }, [paymentRequest]);
    useEffect(function () {
        var cardElement = elements === null || elements === void 0 ? void 0 : elements.getElement(CardElement);
        if (cardElement) {
            cardElement.on('change', function () {
                setError(undefined);
            });
        }
    }, [elements]);
    useEffect(function () {
        if (stripe && paymentAmount !== undefined) {
            var pr_1 = stripe.paymentRequest({
                country: 'GB',
                currency: 'gbp',
                total: {
                    label: 'subscription payment',
                    amount: paymentAmount,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });
            // Check the availability of the Payment Request API.
            pr_1.canMakePayment().then(function (result) {
                if (result) {
                    setPaymentRequest(pr_1);
                    pr_1.on('paymentmethod', function (ev) {
                        onPaymentMethodChange(ev.paymentMethod);
                    });
                }
            });
        }
    }, [stripe, paymentAmount, onPaymentMethodChange]);
    var createPaymentMethod = useCallback(function (cardElement) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!stripe)
                return [2 /*return*/];
            return [2 /*return*/, stripe
                    .createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                })
                    .then(function (result) {
                    if (result.error) {
                        setError(result.error);
                        onPaymentMethodChange(null);
                    }
                    else {
                        (result === null || result === void 0 ? void 0 : result.paymentMethod) &&
                            onPaymentMethodChange(result.paymentMethod);
                    }
                })];
        });
    }); }, [stripe, onPaymentMethodChange]);
    var handleCreatePaymentMethod = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!stripe || !elements) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, createPaymentMethod(elements.getElement(CardElement))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [createPaymentMethod, stripe, elements]);
    return (React.createElement("div", { className: "subscription-payment-method-control" },
        React.createElement("div", { className: "card-element-container" },
            React.createElement(InputWrapper, { styleVariant: InputStyleVariant.UNSTYLED },
                React.createElement(CardElement, null))),
        error && React.createElement("p", { className: "error-message" }, error.message),
        renderActions && (React.createElement("div", { className: "actions-container" }, renderActions(handleCreatePaymentMethod, applePayButton)))));
};
export default SubscriptionPaymentMethodControl;
