import React from 'react';
//@ts-ignore
import { Helmet } from 'react-helmet';
import './BookDemoModal.sass';
import { Modal } from '@payaca/components/modal';
var BookDemoModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose;
    return (React.createElement(Modal, { isOpen: isOpen, size: "md", onClose: onClose, className: "book-demo-modal" },
        React.createElement("div", { className: "meetings-iframe-container", "data-src": "https://book.payaca.com/meetings/luke-johnson/payaca-product-demo?embed=true" }),
        React.createElement(Helmet, null,
            React.createElement("script", { type: "text/javascript", src: "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js" }))));
};
export default BookDemoModal;
