var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useState } from 'react';
import './TextareaField.sass';
import FieldLabel from '../fieldLabel/FieldLabel';
import InputWrapper from '../inputWrapper/InputWrapper';
import TextareaAutosize from 'react-textarea-autosize';
var TextareaField = function (_a) {
    var styleVariant = _a.styleVariant, _b = _a.autoSize, autoSize = _b === void 0 ? false : _b, description = _a.description, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c, _d = _a.isRequired, isRequired = _d === void 0 ? false : _d, label = _a.label, name = _a.name, onChange = _a.onChange, onTouch = _a.onTouch, placeholder = _a.placeholder, _e = _a.rows, rows = _e === void 0 ? 3 : _e, value = _a.value, onBlur = _a.onBlur, onChangeTimeout = _a.onChangeTimeout, _f = _a.changeTimeoutMs, changeTimeoutMs = _f === void 0 ? 1000 : _f, additionalInputProps = _a.additionalInputProps;
    var _g = useState(), changeTimeout = _g[0], setChangeTimeout = _g[1];
    var onInputChange = useCallback(function (event) {
        var _a;
        onChange &&
            onChange((_a = {},
                _a[event.target.name] = event.target.value,
                _a));
        changeTimeout && clearTimeout(changeTimeout);
        setChangeTimeout(setTimeout(function () {
            onChangeTimeout && onChangeTimeout();
        }, changeTimeoutMs));
    }, [onChange, onChangeTimeout, changeTimeout, changeTimeoutMs]);
    var onInputTouch = useCallback(function (event) {
        onTouch && onTouch(event.target.name);
    }, [onTouch]);
    return (React.createElement("div", { className: "text-area-field" },
        React.createElement(FieldLabel, { label: label, isRequired: isRequired, description: description }),
        React.createElement(InputWrapper, { isDisabled: isDisabled, styleVariant: styleVariant }, autoSize ? (React.createElement(TextareaAutosize, { value: value, name: name, onChange: onInputChange, placeholder: placeholder, minRows: rows, onBlur: function (event) {
                onInputTouch && onInputTouch(event);
                onBlur && onBlur();
            } })) : (React.createElement("textarea", __assign({ value: value, name: name, rows: rows, onChange: onInputChange, disabled: isDisabled, onBlur: function (event) {
                onInputTouch && onInputTouch(event);
                onBlur && onBlur();
            } }, additionalInputProps))))));
};
export default TextareaField;
