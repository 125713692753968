var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionType } from "./lineItemsTypes";
export var initialState = {
    lineItems: {},
    isGettingLineItem: false,
    gettingLineItemError: null,
};
var listedJobsReducer = function (state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        //  GETTING LINE ITEM
        case ActionType.REQUEST_GET_LINE_ITEM:
            return __assign(__assign({}, state), { isGettingLineItem: true, gettingLineItemError: null });
        case ActionType.GET_LINE_ITEM_SUCCESS:
            var fetchedEntity = state.lineItems && state.lineItems[action.payload.lineItemId];
            return __assign(__assign({}, state), { isGettingLineItem: false, gettingLineItemError: null, lineItems: __assign(__assign({}, state.lineItems), (_a = {}, _a[action.payload.lineItemId] = __assign(__assign({}, fetchedEntity), { entity: action.payload.lineItem, isFetching: false, fetchSucceededAt: new Date() }), _a)) });
        case ActionType.GET_LINE_ITEM_FAILURE:
            return __assign(__assign({}, state), { isGettingLineItem: false, gettingLineItemError: action.payload.errorMessage });
        // CLEAR LINE ITEMS
        case ActionType.CLEAR_LINE_ITEMS:
            return __assign({}, initialState);
        default:
            return state;
    }
};
export default listedJobsReducer;
