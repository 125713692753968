export var AccountActionTypes;
(function (AccountActionTypes) {
    AccountActionTypes["REQUEST_GET_ACCOUNT_ACCESS_INFORMATION"] = "account.requestGetAccountAccessInformation";
    AccountActionTypes["GET_ACCOUNT_ACCESS_INFORMATION_SUCCESS"] = "account.getAccountAccessInformationSuccess";
    AccountActionTypes["GET_ACCOUNT_ACCESS_INFORMATION_FAILURE"] = "account.getAccountAccessInformationFailure";
    AccountActionTypes["CLEAR_ACCOUNT_ACCESS_INFORMATION"] = "account.clearAccountAccessInformation";
    AccountActionTypes["SET_REQUIRES_UPDATE_BUSINESS_SETTINGS_PROMPT"] = "account.setRequiresUpdateBusinessSettingsPrompt";
    AccountActionTypes["REQUEST_BOOK_DEMO"] = "account.requestBookDemo";
    AccountActionTypes["BOOK_DEMO_SUCCESS"] = "account.bookDemoSuccess";
    AccountActionTypes["BOOK_DEMO_FAILURE"] = "account.bookDemoFailure";
    AccountActionTypes["REQUEST_GET_EMAIL_TEMPLATES"] = "account.requestGetEmailTemplates";
    AccountActionTypes["REQUEST_UPDATE_EMAIL_TEMPLATES"] = "account.requestUpdateEmailTemplates";
    AccountActionTypes["GET_EMAIL_TEMPLATES_SUCCESS"] = "account.getEmailTemplatesSuccess";
    AccountActionTypes["GET_EMAIL_TEMPLATES_FAILURE"] = "account.getEmailTemplatesFailure";
    AccountActionTypes["CLEAR_EMAIL_TEMPLATES"] = "account.clearEmailTemplates";
    AccountActionTypes["STORE_EMAIL_TEMPLATES"] = "account.storeEmailTemplates";
    AccountActionTypes["REQUEST_SEND_DEMO_QUOTE"] = "account.requestSendDemoQuote";
    AccountActionTypes["SEND_DEMO_QUOTE_SUCCESS"] = "account.sendDemoQuoteSuccess";
    AccountActionTypes["SEND_DEMO_QUOTE_FAILURE"] = "account.sendDemoQuoteFailure";
})(AccountActionTypes || (AccountActionTypes = {}));
