import React, { useMemo } from 'react';
import './AddressInputControl.css';
import BasicField from '../basicField/BasicField';
import ValidatedFieldWrapper from '../validatedFieldWrapper/ValidatedFieldWrapper';
var AddressInputControl = function (_a) {
    var styleVariant = _a.styleVariant, address = _a.address, _b = _a.isRequired, isRequired = _b === void 0 ? false : _b, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c, _d = _a.fieldNamePrefix, fieldNamePrefix = _d === void 0 ? '' : _d, touchedState = _a.touchedState, validationState = _a.validationState, onChange = _a.onChange, onTouch = _a.onTouch;
    var line1FieldName = useMemo(function () {
        return fieldNamePrefix ? fieldNamePrefix + ".line1" : 'line1';
    }, [fieldNamePrefix]);
    var line2FieldName = useMemo(function () {
        return fieldNamePrefix ? fieldNamePrefix + ".line2" : 'line2';
    }, [fieldNamePrefix]);
    var cityFieldName = useMemo(function () {
        return fieldNamePrefix ? fieldNamePrefix + ".city" : 'city';
    }, [fieldNamePrefix]);
    var postcodeFieldName = useMemo(function () {
        return fieldNamePrefix ? fieldNamePrefix + ".postcode" : 'postcode';
    }, [fieldNamePrefix]);
    return (React.createElement("div", { className: "address-input-control" },
        React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[line1FieldName]) || false, validationResult: validationState && validationState[line1FieldName] },
            React.createElement(BasicField, { styleVariant: styleVariant, label: "Address line 1", value: (address === null || address === void 0 ? void 0 : address.line1) || '', isRequired: isRequired, isDisabled: isDisabled, name: line1FieldName, onChange: onChange, onTouch: onTouch })),
        React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[line2FieldName]) || false, validationResult: validationState && validationState[line2FieldName] },
            React.createElement(BasicField, { styleVariant: styleVariant, label: "Address line 2", value: (address === null || address === void 0 ? void 0 : address.line2) || '', isRequired: false, isDisabled: isDisabled, name: line2FieldName, onChange: onChange, onTouch: onTouch })),
        React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[cityFieldName]) || false, validationResult: validationState && validationState[cityFieldName] },
            React.createElement(BasicField, { styleVariant: styleVariant, label: "Town / city", value: (address === null || address === void 0 ? void 0 : address.city) || '', isRequired: isRequired, isDisabled: isDisabled, name: cityFieldName, onChange: onChange, onTouch: onTouch })),
        React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[postcodeFieldName]) || false, validationResult: validationState && validationState[postcodeFieldName] },
            React.createElement(BasicField, { styleVariant: styleVariant, label: "Postcode", value: (address === null || address === void 0 ? void 0 : address.postcode) || '', isRequired: isRequired, isDisabled: isDisabled, name: postcodeFieldName, onChange: onChange, onTouch: onTouch }))));
};
export default AddressInputControl;
