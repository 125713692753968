import React, { useMemo } from 'react';
import './CompanyContactFormSubsection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { TitledContentPanel } from '@payaca/components/titledContentPanel';
import { BasicField } from '@payaca/components/basicField';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
var CompanyContactFormSubsection = function (_a) {
    var _b = _a.canModifyDepartment, canModifyDepartment = _b === void 0 ? true : _b, companyContact = _a.companyContact, fieldNamePrefix = _a.fieldNamePrefix, touchedState = _a.touchedState, validationState = _a.validationState, onTouch = _a.onTouch, onChange = _a.onChange, onRemove = _a.onRemove;
    var titleElement = useMemo(function () {
        var titleElements = [];
        companyContact.department && titleElements.push(companyContact.department);
        companyContact.name && titleElements.push(companyContact.name);
        return (React.createElement("div", { className: "flex-container flex-center" },
            React.createElement("span", { className: "flex-grow" }, titleElements.join(' - ')),
            onRemove && React.createElement(FontAwesomeIcon, { icon: faTimes, onClick: onRemove })));
    }, [companyContact, onRemove]);
    var departmentFieldName = useMemo(function () {
        return fieldNamePrefix ? fieldNamePrefix + ".department" : 'department';
    }, [fieldNamePrefix]);
    var nameFieldName = useMemo(function () {
        return fieldNamePrefix ? fieldNamePrefix + ".name" : 'name';
    }, [fieldNamePrefix]);
    var emailAddressFieldName = useMemo(function () {
        return fieldNamePrefix ? fieldNamePrefix + ".emailAddress" : 'emailAddress';
    }, [fieldNamePrefix]);
    return (React.createElement(TitledContentPanel, { className: "company-contact-form-subsection", hasBoxShadow: true, title: titleElement },
        React.createElement("div", { className: "flex-container" },
            React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[departmentFieldName]) || false, validationResult: validationState && validationState[departmentFieldName] },
                React.createElement(BasicField, { label: "Department", value: companyContact.department || '', name: departmentFieldName, isDisabled: !canModifyDepartment, onChange: onChange, onTouch: onTouch, isRequired: true, styleVariant: InputStyleVariant.OUTSIZE })),
            React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[nameFieldName]) || false, validationResult: validationState && validationState[nameFieldName] },
                React.createElement(BasicField, { label: "Name", value: companyContact.name || '', name: nameFieldName, onChange: onChange, onTouch: onTouch, isRequired: true, styleVariant: InputStyleVariant.OUTSIZE })),
            React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[emailAddressFieldName]) || false, validationResult: validationState && validationState[emailAddressFieldName] },
                React.createElement(BasicField, { label: "Email address", value: companyContact.emailAddress || '', name: emailAddressFieldName, onChange: onChange, onTouch: onTouch, isRequired: true, styleVariant: InputStyleVariant.OUTSIZE })))));
};
export default CompanyContactFormSubsection;
