import React, { useMemo } from 'react';
import { Modal } from '@payaca/components/modal';
import ConfirmPaymentControl from '../confirmPaymentControl/ConfirmPaymentControl';
import { getJobPayment, getJobsByDealId } from '@/utils/stateAccessors';
import { useSelector } from 'react-redux';
import { isInvoice } from '@payaca/helpers/jobStatusHelper';
import { getJobContactFromCustomer } from '@bit/payaca-tech.payaca-core.helpers.customer';
var ConfirmPaymentModal = function (_a) {
    var jobPaymentId = _a.jobPaymentId, isOpen = _a.isOpen, confirmPaymentCallback = _a.confirmPaymentCallback, onClose = _a.onClose;
    var jobPayment = useSelector(function (state) {
        return getJobPayment(state, jobPaymentId);
    });
    var jobs = useSelector(function (state) {
        if (!jobPayment)
            return;
        return getJobsByDealId(state, jobPayment.dealId);
    });
    var customer = useSelector(function (state) {
        return state.customer.currentCustomer;
    });
    var job = useMemo(function () {
        if (!jobPayment || !jobs)
            return;
        return jobs.find(function (job) {
            return !job.inactivatedAt &&
                !job.archivedAt &&
                isInvoice(job.status) === !jobPayment.isDepositPayment;
        });
    }, [jobs, jobPayment]);
    var customerContact = useMemo(function () {
        if (!job)
            return;
        return getJobContactFromCustomer(customer, job.contactId);
    }, [job, customer]);
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose, title: "Confirm a payment" }, job && jobPayment && (React.createElement(ConfirmPaymentControl, { jobPayment: jobPayment, customerName: (customerContact === null || customerContact === void 0 ? void 0 : customerContact.name) || '', jobReference: job.customReference || String(job.reference), confirmPaymentCallback: confirmPaymentCallback, canSendReceipt: !!(customerContact === null || customerContact === void 0 ? void 0 : customerContact.emailAddress) }))));
};
export default ConfirmPaymentModal;
