import React from 'react';
import { Modal } from '@payaca/components/modal';
import { Call, Message } from 'react-iconly';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import './ContactUsModal.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedinIn, faTwitter, } from '@fortawesome/free-brands-svg-icons';
var ContactUsModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose;
    return (React.createElement(Modal, { className: "contact-us-modal", isOpen: isOpen, onClose: onClose, title: "Contact us" },
        React.createElement("div", { className: "contact-methods-container" },
            React.createElement("a", { href: "mailto:info@payaca.com", className: "contact-method" },
                React.createElement(Message, { set: "light", size: "xlarge" }),
                React.createElement("span", null, "info@payaca.com")),
            React.createElement("a", { href: "tel:03330509475", className: "contact-method" },
                React.createElement(Call, { set: "light", size: "xlarge" }),
                React.createElement("span", null, "0333 050 9475"))),
        React.createElement("div", { className: "feedback-prompt-container" },
            React.createElement("h3", null, "Got something to mention?"),
            React.createElement("p", null, "We value all feedback left by our clients"),
            React.createElement("a", null,
                React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE }, "Leave a review"))),
        React.createElement("div", { className: "socials-container" },
            React.createElement("h3", null, "Follow us on socials"),
            React.createElement("div", { className: "social-icons-container flex-container flex-center" },
                React.createElement("a", { href: "https://www.facebook.com/app.payaca", rel: "noopener noreferrer", target: "_blank" },
                    React.createElement(FontAwesomeIcon, { icon: faFacebook })),
                React.createElement("a", { href: "https://www.linkedin.com/company/payaca/", rel: "noopener noreferrer", target: "_blank" },
                    React.createElement(FontAwesomeIcon, { icon: faLinkedinIn })),
                React.createElement("a", { href: "https://twitter.com/payaca_app", rel: "noopener noreferrer", target: "_blank" },
                    React.createElement(FontAwesomeIcon, { icon: faTwitter })),
                React.createElement("a", { href: "https://www.instagram.com/payaca_app/", rel: "noopener noreferrer", target: "_blank" },
                    React.createElement(FontAwesomeIcon, { icon: faInstagram }))))));
};
export default ContactUsModal;
