import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import values from 'lodash.values';
import { FileSizeLimit, FileType, getMbFileLimit, ImageFileType, } from '@payaca/helpers/fileHelper';
import JobAttachment from './JobAttachment';
import './JobAttachments.sass';
var JobAttachments = function (_a) {
    var jobAttachments = _a.jobAttachments, // list of files
    onAddLabel = _a.onAddLabel, // label for add button
    uploadType = _a.uploadType, // name of upload type to determine the file size limit
    _b = _a.readOnly, // name of upload type to determine the file size limit
    readOnly = _b === void 0 ? true : _b, // editable-ness for uploading i.e. for admin
    onFileChange = _a.onFileChange, _c = _a.title, title = _c === void 0 ? 'Attachments' : _c, onError = _a.onError, _d = _a.quantityLimit, quantityLimit = _d === void 0 ? null : _d, _e = _a.imagesOnly, imagesOnly = _e === void 0 ? false : _e, _f = _a.isDownloadDisabled, isDownloadDisabled = _f === void 0 ? false : _f;
    var _g = useState(false), exceededFileLimit = _g[0], setExceededFileLimit = _g[1];
    var randomKey = Math.floor(Math.random() * 10000000000); // when multiple instances of component exist on one page
    var canAddFile = useMemo(function () {
        return onFileChange && !readOnly && quantityLimit !== null
            ? jobAttachments.length < quantityLimit
            : onFileChange && !readOnly;
    }, [quantityLimit, jobAttachments, onFileChange, readOnly]);
    var onNewFile = function (e) {
        // clear any errors
        setExceededFileLimit(false);
        var file = e.target.files[0];
        if (file) {
            var fileSizeLimit = uploadType ? FileSizeLimit[uploadType] : null;
            if (fileSizeLimit && file.size > fileSizeLimit) {
                setExceededFileLimit(true);
                // return error to monitor if file limit should be adjusted
                onError &&
                    onError("File upload attempt of " + file.size + " for " + uploadType + " exceeds limit of " + fileSizeLimit);
            }
            else {
                setExceededFileLimit(false);
                var defaultFileName = file.name.split('.')[0];
                onFileChange &&
                    onFileChange({
                        fileName: defaultFileName,
                        file: file,
                    });
            }
        }
        // Allow uploading of the same file
        if (e.target && e.target.value) {
            e.target.value = '';
        }
    };
    var renderJobAttachments = function () {
        return jobAttachments.map(function (attachment, i) {
            return (React.createElement(JobAttachment, { file: attachment, key: i, isUpload: !!onFileChange, readOnly: readOnly, onFileChange: function (change, remove) {
                    return onFileChange && onFileChange(change, remove);
                }, isDownloadDisabled: isDownloadDisabled }));
        });
    };
    // list of accepted files for input
    var fileTypesAccepted = imagesOnly ? ImageFileType : FileType;
    var acceptedFiles = values(fileTypesAccepted)
        .map(function (v) { return v.name[0]; })
        .join(', ');
    return (React.createElement("div", { className: "job-attachments" },
        title && React.createElement("p", null, title),
        React.createElement("div", { className: "job-attachments-wrapper" },
            canAddFile && (React.createElement("div", { className: "job-attachment add" },
                React.createElement("div", { className: "file-wrapper" },
                    React.createElement("label", { htmlFor: "file-upload-" + randomKey },
                        onAddLabel && React.createElement("div", null, onAddLabel),
                        React.createElement(FontAwesomeIcon, { icon: faPlus, className: "plus-icon" })),
                    React.createElement("input", { id: "file-upload-" + randomKey, type: "file", onChange: function (e) { return onNewFile(e); }, accept: acceptedFiles, className: "hidden" })))),
            renderJobAttachments()),
        exceededFileLimit && uploadType && (React.createElement("div", { className: "error" },
            "File size exceeds limit of ",
            getMbFileLimit(FileSizeLimit[uploadType])))));
};
export default JobAttachments;
