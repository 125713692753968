var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldLabel from '../fieldLabel/FieldLabel';
import InputWrapper from '../inputWrapper/InputWrapper';
import './BasicField.sass';
var BasicField = function (_a) {
    var className = _a.className, name = _a.name, styleVariant = _a.styleVariant, value = _a.value, label = _a.label, description = _a.description, _b = _a.type, type = _b === void 0 ? 'text' : _b, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c, _d = _a.isRequired, isRequired = _d === void 0 ? false : _d, iconBefore = _a.iconBefore, iconAfter = _a.iconAfter, _e = _a.additionalInputProps, additionalInputProps = _e === void 0 ? {} : _e, _f = _a.changeTimeoutMs, changeTimeoutMs = _f === void 0 ? 1000 : _f, onChange = _a.onChange, onTouch = _a.onTouch, onBlur = _a.onBlur, onChangeTimeout = _a.onChangeTimeout, textBefore = _a.textBefore;
    var _g = useState(), changeTimeout = _g[0], setChangeTimeout = _g[1];
    var onInputChange = useCallback(function (event) {
        var _a;
        onChange &&
            onChange((_a = {},
                _a[event.target.name] = event.target.value,
                _a));
        changeTimeout && clearTimeout(changeTimeout);
        setChangeTimeout(setTimeout(function () {
            onChangeTimeout && onChangeTimeout();
        }, changeTimeoutMs));
    }, [onChange, onChangeTimeout, changeTimeout, changeTimeoutMs]);
    var onInputTouch = useCallback(function (event) {
        onTouch && onTouch(event.target.name);
    }, [onTouch]);
    return (React.createElement("div", { className: "basic-field " + (className ? className : '') },
        React.createElement(FieldLabel, { label: label, isRequired: isRequired, description: description }),
        React.createElement(InputWrapper, { isDisabled: isDisabled, styleVariant: styleVariant },
            React.createElement(React.Fragment, null,
                iconBefore &&
                    (React.isValidElement(iconBefore) ? (iconBefore) : (
                    // @ts-ignore
                    React.createElement(FontAwesomeIcon, { icon: iconBefore }))),
                textBefore && React.createElement("span", { className: "pre-text" }, textBefore),
                React.createElement("input", __assign({ value: value, name: name, type: type, onChange: onInputChange, onBlur: function (event) {
                        onInputTouch && onInputTouch(event);
                        onBlur && onBlur();
                    }, disabled: isDisabled }, additionalInputProps)),
                iconAfter &&
                    (React.isValidElement(iconAfter) ? (iconAfter) : (
                    // @ts-ignore
                    React.createElement(FontAwesomeIcon, { icon: iconAfter })))))));
};
export default BasicField;
