var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileUploadPersistRemoveControl } from '@payaca/components';
import { actions as accountSettingsActions } from '@/api/accountSettings';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
var CompanySettingsBranding = function (_a) {
    var readOnly = _a.readOnly;
    var dispatch = useDispatch();
    var pendingAccount = useSelector(function (state) { return state.accountSettings.pendingAccount; });
    var pendingBusinessAttachments = useSelector(function (state) { return state.accountSettings.pendingBusinessAttachments; });
    var onPersistLogo = useCallback(function (file) {
        return new Promise(function (resolve, reject) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                dispatch(accountSettingsActions.storeAccountPendingChanges({
                    logoUrl: { uri: reader.result, file: file },
                }));
            };
            resolve();
        });
    }, [dispatch]);
    var onRemoveLogo = useCallback(function (fileIdentifier) {
        return new Promise(function (resolve, reject) {
            dispatch(accountSettingsActions.storeAccountPendingChanges({
                logoUrl: null,
            }));
            resolve();
        });
    }, [dispatch]);
    var onPersistBusinessAttachment = useCallback(function (file) {
        return new Promise(function (resolve, reject) {
            dispatch(accountSettingsActions.storeAccountPendingBusinessAttachments(__spreadArrays(pendingBusinessAttachments, [
                __assign(__assign({}, file), { fileName: file.name, identifier: file.lastModified }),
            ])));
            resolve();
        });
    }, [dispatch, pendingBusinessAttachments]);
    var onRemoveBusinessAttachment = useCallback(function (fileIdentifier) {
        var updatedBusinessAttachments = __spreadArrays(pendingBusinessAttachments);
        // find file to remove in persisted files
        var fileToRemoveIndex = pendingBusinessAttachments.findIndex(function (f) { return f.identifier === fileIdentifier || f.id === fileIdentifier; });
        updatedBusinessAttachments.splice(fileToRemoveIndex, 1);
        return new Promise(function (resolve, reject) {
            dispatch(accountSettingsActions.storeAccountPendingBusinessAttachments(updatedBusinessAttachments));
            resolve();
        });
    }, [dispatch, pendingBusinessAttachments]);
    var accountLogoUrl = useMemo(function () { var _a; return (_a = pendingAccount.logoUrl) === null || _a === void 0 ? void 0 : _a.uri; }, [pendingAccount]);
    var persistedBusinessAttachments = useMemo(function () {
        return (pendingBusinessAttachments === null || pendingBusinessAttachments === void 0 ? void 0 : pendingBusinessAttachments.length) ? pendingBusinessAttachments.map(function (pendingBusinessAttachment) { return ({
            fileName: pendingBusinessAttachment.fileName,
            identifier: pendingBusinessAttachment.id,
        }); })
            : [];
    }, [pendingBusinessAttachments]);
    return (React.createElement("div", { className: "company-settings-branding" },
        React.createElement("div", { className: "settings-sub-section" },
            React.createElement("h4", null, "Logo"),
            React.createElement("div", { className: "logo-upload-wrapper" },
                accountLogoUrl && React.createElement("img", { src: accountLogoUrl }),
                !accountLogoUrl && (React.createElement(FileUploadPersistRemoveControl, { acceptFileTypes: ['.png, .jpeg'], persistedFiles: accountLogoUrl
                        ? [{ fileName: 'logo', identifier: accountLogoUrl }]
                        : [], persistFile: onPersistLogo, removePersistedFile: onRemoveLogo, showAsDragAndDrop: true, showUploadImageButton: true }))),
            accountLogoUrl && (React.createElement("div", { className: "button-container" },
                React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, className: "remove-logo", onClick: function () {
                        var newLogo = !pendingAccount.logoUrl || !pendingAccount.logoUrl.uri
                            ? null
                            : { uri: null };
                        dispatch(accountSettingsActions.storeAccountPendingChanges({
                            logoUrl: newLogo,
                        }));
                    } }, "Remove image"))),
            React.createElement("h4", null, "Attach marketing material"),
            React.createElement("span", { className: "marketing-description" }, "* Documents or images added here will be included on every quote and invoice."),
            React.createElement("div", { className: "marketing-upload-wrapper" },
                React.createElement(FileUploadPersistRemoveControl, { acceptFileTypes: ['.png, .jpeg'], allowMultipleUploads: true, showAsDragAndDrop: true, persistFile: onPersistBusinessAttachment, removePersistedFile: onRemoveBusinessAttachment, persistedFiles: persistedBusinessAttachments })))));
};
export default CompanySettingsBranding;
