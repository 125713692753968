var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { actions as jobsActions } from '@bit/payaca-tech.payaca-core.store.jobs';
import { ContentPanel } from '@payaca/components/contentPanel';
import SelectItemGroupModal from '../selectItemGroupModal/SelectItemGroupModal';
import ConfirmModal from '../modal/ConfirmModal';
import { getModal } from '@/helpers/modalHelper';
import JobLineItemsControl from '../jobLineItemsControl/JobLineItemsControl';
import './InvoiceLineItemsControl.sass';
import { getJob } from '@/utils/stateAccessors';
import { CreateArea } from '@payaca/components/clickableArea';
var InvoiceLineItemsControl = function (_a) {
    var _b;
    var jobIsInvoice = _a.jobIsInvoice, jobId = _a.jobId;
    var dispatch = useDispatch();
    var job = useSelector(function (state) {
        return getJob(state, jobId);
    });
    var isAddingLineItemGroupToJob = useSelector(function (state) {
        return state.jobsStore.isAddingLineItemGroupToJob;
    });
    var lineItemGroups = useSelector(function (state) { return state.lineItemGroups.lineItemGroups; });
    var _c = useState(false), showLineItemGroupModal = _c[0], setShowLineItemGroupModal = _c[1];
    var _d = useState(false), showItemGroupConfirmation = _d[0], setShowItemGroupConfirmation = _d[1];
    var _e = useState(null), lineItemGroupToAdd = _e[0], setLineItemGroupToAdd = _e[1];
    var hasAnyLineItemGroups = useMemo(function () {
        var _a;
        return !!((_a = Object.values(lineItemGroups)) === null || _a === void 0 ? void 0 : _a.length);
    }, [lineItemGroups]);
    var addLineItemGroupToJob = useCallback(function (lineItemGroupId) {
        dispatch(jobsActions.requestAddLineItemGroupToJob(jobId, lineItemGroupId, function () {
            dispatch(jobsActions.requestGetJobWithJobGroupsAndJobLineItems(jobId));
        }));
    }, [jobId]);
    return (React.createElement("div", { className: "invoice-line-items-control" },
        React.createElement(ContentPanel, { hasBoxShadow: false },
            React.createElement(React.Fragment, null, (_b = job === null || job === void 0 ? void 0 : job.jobLineItemGroupIds) === null || _b === void 0 ? void 0 : _b.map(function (jobLineItemGroupId, index) { return (React.createElement(JobLineItemsControl, { jobIsInvoice: jobIsInvoice, jobLineItemGroupId: jobLineItemGroupId, key: "job-line-items-control-" + index })); }))),
        hasAnyLineItemGroups && (React.createElement("div", { className: "create-or-add-existing-container line-item-group" },
            React.createElement(CreateArea, { onClick: function () { return setShowLineItemGroupModal(true); }, isProcessing: isAddingLineItemGroupToJob }, "Search existing groups"))),
        React.createElement(SelectItemGroupModal, { isOpen: showLineItemGroupModal, onClose: function () { return setShowLineItemGroupModal(false); }, onSelectItemGroup: function (lineItemGroup) {
                if (lineItemGroupToAdd) {
                    // don't allow selecting a group if already adding one
                    return;
                }
                var hasOptionalMultipleChoiceItems = lineItemGroup.lineItemRelations.filter(function (lir) { return lir.isOptional || lir.isSelected; }).length;
                if (!hasOptionalMultipleChoiceItems) {
                    addLineItemGroupToJob(lineItemGroup.id);
                }
                else {
                    setLineItemGroupToAdd(lineItemGroup.id);
                    setShowItemGroupConfirmation(true);
                }
                setShowLineItemGroupModal(false);
            } }),
        React.createElement(ConfirmModal, __assign({}, getModal('ADD_ITEM_GROUP_WITH_OPTIONAL_MULTIPLE_CHOICE'), { onClose: function () {
                setLineItemGroupToAdd(null);
                setShowItemGroupConfirmation(false);
            }, secondaryAction: function () {
                setLineItemGroupToAdd(null);
                setShowItemGroupConfirmation(false);
            }, primaryAction: function () {
                addLineItemGroupToJob(lineItemGroupToAdd);
                setLineItemGroupToAdd(null);
                setShowItemGroupConfirmation(false);
            }, open: showItemGroupConfirmation }))));
};
export default InvoiceLineItemsControl;
