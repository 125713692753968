import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '@payaca/components/modal';
import { TextareaField } from '@payaca/components';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import ResetIcon from './reset-icon.svg';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
var EmailTemplateModal = function (_a) {
    var buttonText = _a.buttonText, defaultValue = _a.defaultValue, isOpen = _a.isOpen, title = _a.title, name = _a.name, onChange = _a.onChange, onClose = _a.onClose, readOnly = _a.readOnly, value = _a.value;
    var _b = useState(false), isResetting = _b[0], setIsResetting = _b[1];
    var pendingAccount = useSelector(function (state) { return state.accountSettings.pendingAccount; });
    useEffect(function () {
        if (isResetting) {
            setTimeout(function () { return setIsResetting(false); }, 400);
        }
    }, [isResetting]);
    var showReset = useMemo(function () { return (defaultValue && value !== defaultValue) || isResetting; }, [defaultValue, isResetting, value]);
    var accountLogoUrl = useMemo(function () { var _a; return (_a = pendingAccount.logoUrl) === null || _a === void 0 ? void 0 : _a.uri; }, [pendingAccount]);
    var emailText = useMemo(function () {
        if (!value) {
            return {
                preButton: '',
                postButton: '',
            };
        }
        var splitEmail;
        if (value.includes('\n[button]\n')) {
            splitEmail = value.split('\n[button]\n');
        }
        else {
            splitEmail = value.split('[button]');
        }
        return {
            preButton: splitEmail[0],
            postButton: splitEmail[1],
        };
    }, [value]);
    return (React.createElement(Modal, { isOpen: isOpen, title: title, size: "md", onClose: onClose, className: "email-template-modal" },
        React.createElement("div", { className: "template-wrapper" },
            accountLogoUrl && (React.createElement("img", { src: accountLogoUrl, className: "account-logo" })),
            React.createElement(TextareaField, { autoSize: true, isRequired: true, value: emailText === null || emailText === void 0 ? void 0 : emailText.preButton, name: name, onChange: function (change) {
                    var _a;
                    //add button and postbutton text back on
                    onChange((_a = {},
                        _a[name] = change[name] + "\n[button]\n" + emailText.postButton,
                        _a));
                }, rows: 3, isDisabled: readOnly, styleVariant: InputStyleVariant.UNSTYLED }),
            React.createElement(Button, { isDisabled: true, styleVariant: ButtonStyleVariant.OUTSIZE }, buttonText),
            React.createElement(TextareaField, { autoSize: true, isRequired: true, value: emailText === null || emailText === void 0 ? void 0 : emailText.postButton, name: name, onChange: function (change) {
                    var _a;
                    // add prebutton text and button back on
                    onChange((_a = {},
                        _a[name] = emailText.preButton + "\n[button]\n" + change[name],
                        _a));
                }, rows: 2, isDisabled: readOnly, styleVariant: InputStyleVariant.UNSTYLED })),
        React.createElement("div", { className: "button-container" },
            showReset && (React.createElement("div", { className: "reset-container", onClick: function () {
                    var _a;
                    setIsResetting(true);
                    onChange((_a = {}, _a[name] = defaultValue, _a));
                } },
                React.createElement("img", { src: ResetIcon, className: "reset-icon" + (isResetting ? ' spin' : '') }),
                React.createElement("span", null, "Reset template"))),
            React.createElement(Button, { onClick: onClose, styleVariant: ButtonStyleVariant.OUTSIZE }, "Continue"))));
};
export default EmailTemplateModal;
