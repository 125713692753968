import React from "react";
var Step = function (_a) {
    var name = _a.name, index = _a.index, isCompleted = _a.isCompleted, isCurrent = _a.isCurrent, stepIndicatorSize = _a.stepIndicatorSize, onClick = _a.onClick;
    return (React.createElement("div", { className: "step" + (isCompleted ? " completed-step" : "") + (isCurrent ? " current-step" : "") },
        React.createElement("div", { className: "step-inner", style: { marginLeft: -stepIndicatorSize / 2, width: stepIndicatorSize } },
            React.createElement("div", { className: "step-indicator" + (onClick ? " clickable" : ""), style: { width: stepIndicatorSize, height: stepIndicatorSize }, onClick: onClick },
                React.createElement("span", { className: "step-indicator-label" }, index + 1)),
            React.createElement("span", { className: "step-name", style: {
                    marginLeft: -stepIndicatorSize / 4,
                    marginRight: -stepIndicatorSize / 4,
                } }, name))));
};
export default Step;
