import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { faDownload, faUpload, } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '@payaca/components/modal';
import { Button } from '@payaca/components/button';
import BulkUploadInstruction from './BulkUploadInstruction';
import { actions as lineItemActions } from '@bit/payaca-tech.payaca-core.store.line-items';
import { usePrevious } from '@/utils/customHooks';
import './BulkUploadModal.css';
var BulkUploadModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onUploadSuccess = _a.onUploadSuccess, title = _a.title;
    var dispatch = useDispatch();
    var isGettingItemsCsvTemplate = useSelector(function (state) { return state.lineItems.isGettingItemsCsvTemplate; });
    var itemsCsvTemplateData = useSelector(function (state) { return state.lineItems.itemsCsvTemplateData; });
    var itemsCsvGuidelines = useSelector(function (state) { return state.lineItems.itemsCsvGuidelines; });
    var itemsCsvTemplateError = useSelector(function (state) { return state.lineItems.itemsCsvTemplateError; });
    var isUploadingItemsCsv = useSelector(function (state) { return state.lineItems.isUploadingItemsCsv; });
    var uploadItemsCsvError = useSelector(function (state) { return state.lineItems.uploadItemsCsvError; });
    var _b = useState(false), showUploadSuccessMessage = _b[0], setShowUploadSuccessMessage = _b[1];
    var previousIsUploadingItemsCsv = usePrevious(isUploadingItemsCsv);
    useEffect(function () {
        return function () {
            dispatch(lineItemActions.clearItemsCsv());
        };
    }, []);
    useEffect(function () {
        if (isOpen) {
            dispatch(lineItemActions.requestGetItemsCsvTemplate());
        }
    }, [dispatch, isOpen]);
    useEffect(function () {
        if (previousIsUploadingItemsCsv &&
            !isUploadingItemsCsv &&
            !uploadItemsCsvError) {
            // upload completed successfully
            setShowUploadSuccessMessage(true);
            onUploadSuccess();
        }
    }, [
        previousIsUploadingItemsCsv,
        isUploadingItemsCsv,
        onUploadSuccess,
        uploadItemsCsvError,
    ]);
    var uploadError = useMemo(function () {
        if (uploadItemsCsvError) {
            var isValidationError = Array.isArray(uploadItemsCsvError);
            if (isValidationError) {
                return (React.createElement("div", null,
                    "Sorry, there was an error uploading your items, please address the following before trying again.",
                    React.createElement("ul", { className: "errors-wrapper" }, uploadItemsCsvError.map(function (bulkUploadItemError, bulkUploadItemErrorIndex) {
                        return !bulkUploadItemError.rowNumber ? (
                        // invalid row
                        React.createElement("li", { key: bulkUploadItemErrorIndex },
                            "\"",
                            bulkUploadItemError.columnHeader,
                            "\" is an invalid column")) : (
                        // invalid cell
                        React.createElement("li", { key: bulkUploadItemErrorIndex },
                            React.createElement("span", null,
                                "Error on row ",
                                bulkUploadItemError.rowNumber,
                                " with",
                                ' ',
                                bulkUploadItemError.columnHeader,
                                ":",
                                ' '),
                            bulkUploadItemError.errors.map(function (error, bulkUploadItemErrorIndex) {
                                return (React.createElement("span", { key: bulkUploadItemErrorIndex }, error));
                            })));
                    }))));
            }
            else if (uploadItemsCsvError === 'UPLOAD_LIMIT_EXCEEDED') {
                return (React.createElement("div", null, "Sorry, your file exceeds the limit. Please provide a file containing less than 3000 items."));
            }
            else {
                return React.createElement("div", null, "Sorry, there was an error uploading your items");
            }
        }
        return null;
    }, [uploadItemsCsvError]);
    var instructions = useMemo(function () {
        return [
            {
                title: 'Download the template',
                content: (React.createElement("div", { className: "download-button-wrapper" },
                    React.createElement(CSVLink, { data: itemsCsvTemplateData || '', filename: 'payaca-upload-items-template.csv' },
                        React.createElement(Button, { iconBefore: faDownload, isDisabled: isGettingItemsCsvTemplate ||
                                !itemsCsvTemplateData ||
                                !!itemsCsvTemplateError }, "Download template")))),
            },
            {
                title: 'Fill out the template considering the following:',
                content: (React.createElement("ul", { className: "guidelines-wrapper" },
                    React.createElement("li", { key: "bulk-upload-instruction-guideline-limit" },
                        React.createElement("span", null, "File must be a maximum of 3000 items")),
                    itemsCsvGuidelines &&
                        itemsCsvGuidelines.map(function (bulkUploadItemsCsvGuideline, i) {
                            return (React.createElement("li", { key: "bulk-upload-instruction-guideline-" + i },
                                React.createElement("span", null, bulkUploadItemsCsvGuideline.header),
                                React.createElement("span", null,
                                    ": ",
                                    bulkUploadItemsCsvGuideline.guideline)));
                        }))),
            },
            {
                title: 'Upload the completed template',
                content: (React.createElement("div", { className: "upload-button-wrapper" },
                    React.createElement(Button, { type: 'submit', iconBefore: faUpload, isProcessing: isUploadingItemsCsv }, "Upload"),
                    React.createElement("label", { htmlFor: "file-upload" }),
                    React.createElement("input", { id: 'file-upload', type: "file", accept: ".csv", onChange: function (e) {
                            var _a, _b;
                            // prevent file upload if mid-validate/upload
                            if (!isUploadingItemsCsv && ((_b = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0])) {
                                setShowUploadSuccessMessage(false);
                                dispatch(lineItemActions.requestUploadItemsCsv(e.target.files[0]));
                            }
                            // Allow uploading of the same file
                            if (e.target && e.target.value) {
                                e.target.value = '';
                            }
                        }, hidden: true }))),
            },
        ].map(function (instruction, i) {
            return (React.createElement(BulkUploadInstruction, { key: "bulk-upload-instruction-" + i, instructionNumber: i + 1, instructionTitle: instruction.title }, instruction.content));
        });
    }, [
        dispatch,
        itemsCsvGuidelines,
        itemsCsvTemplateData,
        itemsCsvTemplateError,
        isGettingItemsCsvTemplate,
        isUploadingItemsCsv,
    ]);
    return (React.createElement(Modal, { isOpen: isOpen, title: title, size: "sm", onClose: function () {
            setShowUploadSuccessMessage(false);
            onClose();
            dispatch(lineItemActions.clearItemsCsv());
        }, className: "bulk-upload-modal" },
        React.createElement("div", null,
            React.createElement("div", { className: "upload-instructions-wrapper" }, instructions),
            uploadError,
            showUploadSuccessMessage && (React.createElement("div", null, "Your items were successfully uploaded")))));
};
export default BulkUploadModal;
