import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import './TrialPeriodCountdownBanner.sass';
var TrialPeriodCountdownBanner = function () {
    var history = useHistory();
    var currentUserFirstName = useSelector(function (state) {
        var _a;
        return (_a = state.users.myProfile) === null || _a === void 0 ? void 0 : _a.firstname;
    });
    var currentUserIsAdmin = useSelector(function (state) {
        var _a;
        return !!((_a = state.users.myProfile) === null || _a === void 0 ? void 0 : _a.isAdmin);
    });
    var accountAccessInformation = useSelector(function (state) {
        return state.account.accountAccessInformation;
    });
    return (React.createElement("div", { className: "trial-period-countdown-banner" },
        React.createElement("span", null,
            "Hi ",
            currentUserFirstName,
            ", you have",
            ' ',
            React.createElement("strong", null, accountAccessInformation === null || accountAccessInformation === void 0 ? void 0 :
                accountAccessInformation.trialPeriodDaysRemaining,
                ' ',
                (accountAccessInformation === null || accountAccessInformation === void 0 ? void 0 : accountAccessInformation.trialPeriodDaysRemaining) === 1
                    ? 'day'
                    : 'days',
                ' ',
                "left"),
            ' ',
            "on your demo.",
            !currentUserIsAdmin &&
                ' Contact your account administrator to subscribe.'),
        currentUserIsAdmin && (React.createElement(Button, { onClick: function () { return history.push('/upgradeAccount'); }, styleVariant: ButtonStyleVariant.ANCHOR, className: "upgrade-account" }, "Manage your subscription"))));
};
export default TrialPeriodCountdownBanner;
