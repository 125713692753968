var _a;
import isEqual from 'lodash.isequal';
var VatTypes;
(function (VatTypes) {
    VatTypes["ZERO_PERCENT"] = "zero-percent";
    VatTypes["FIVE_PERCENT"] = "five-percent";
    VatTypes["TWENTY_PERCENT"] = "twenty-percent";
    VatTypes["TWENTY_PERCENT_REVERSE_CHARGE"] = "twenty-percent-reverse-charge";
    VatTypes["FIVE_PERCENT_REVERSE_CHARGE"] = "five-percent-reverse-charge";
    VatTypes["NONE"] = "none";
})(VatTypes || (VatTypes = {}));
var VAT_OPTIONS = [
    {
        label: 'VAT 20%',
        value: VatTypes.TWENTY_PERCENT,
    },
    {
        label: 'VAT 5%',
        value: VatTypes.FIVE_PERCENT,
    },
    {
        label: 'VAT 0%',
        value: VatTypes.ZERO_PERCENT,
    },
    {
        label: 'VAT 20% REVERSE CHARGE',
        value: VatTypes.TWENTY_PERCENT_REVERSE_CHARGE,
    },
    {
        label: 'VAT 5% REVERSE CHARGE',
        value: VatTypes.FIVE_PERCENT_REVERSE_CHARGE,
    },
    {
        label: 'No VAT',
        value: VatTypes.NONE,
    },
];
var VAT_SETTINGS = (_a = {},
    _a[VatTypes.TWENTY_PERCENT] = {
        vatAmount: 20,
        vatIncluded: true,
        isReverseChargeVat: false,
    },
    _a[VatTypes.FIVE_PERCENT] = {
        vatAmount: 5,
        vatIncluded: true,
        isReverseChargeVat: false,
    },
    _a[VatTypes.ZERO_PERCENT] = {
        vatAmount: 0,
        vatIncluded: true,
        isReverseChargeVat: false,
    },
    _a[VatTypes.NONE] = {
        vatAmount: null,
        vatIncluded: false,
        isReverseChargeVat: false,
    },
    _a[VatTypes.TWENTY_PERCENT_REVERSE_CHARGE] = {
        vatAmount: 20,
        vatIncluded: true,
        isReverseChargeVat: true,
    },
    _a[VatTypes.FIVE_PERCENT_REVERSE_CHARGE] = {
        vatAmount: 5,
        vatIncluded: true,
        isReverseChargeVat: true,
    },
    _a);
export var getVatLabelFromVatSettings = function (vatAmount, isReverseChargeVat, isVatIncluded) {
    var _a;
    var vatSettings = {
        vatAmount: isVatIncluded ? vatAmount : null,
        vatIncluded: isVatIncluded,
        isReverseChargeVat: isReverseChargeVat,
    };
    var vatType = Object.keys(VAT_SETTINGS).find(function (key) {
        return isEqual(VAT_SETTINGS[key], vatSettings);
    });
    return (_a = VAT_OPTIONS.find(function (x) { return x.value === vatType; })) === null || _a === void 0 ? void 0 : _a.label;
};
