import { getActiveJobFinanceInformationByDealId, getJobPaymentsByDealId, getJobsByDealId, } from '@/utils/stateAccessors';
import { CreateButton } from '@payaca/components/button';
import { isInvoice } from '@payaca/helpers/jobStatusHelper';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import RecordPaymentModal from '../recordPaymentModal/RecordPaymentModal';
import { getOutstandingPaymentValue } from '@payaca/helpers/jobHelperV2';
import TooltipUI from '@material-ui/core/Tooltip';
var RecordDealPaymentControl = function (_a) {
    var dealId = _a.dealId, onJobUpdateSuccess = _a.onJobUpdateSuccess;
    var _b = useState(false), showRecordPaymentModal = _b[0], setShowRecordPaymentModal = _b[1];
    var customer = useSelector(function (state) { return state.customer.currentCustomer; });
    var activeJobs = useSelector(function (state) {
        return getJobsByDealId(state, dealId).filter(function (x) { return !x.inactivatedAt && !x.archivedAt; });
    });
    var activeJobFinanceInformation = useSelector(function (state) {
        return getActiveJobFinanceInformationByDealId(state, dealId);
    });
    var jobPayments = useSelector(function (state) {
        return getJobPaymentsByDealId(state, dealId);
    });
    var activeJob = useMemo(function () {
        if (!activeJobs.length)
            return;
        var sentJobs = activeJobs.filter(function (x) { return !!x.sentAt; });
        if (!sentJobs.length)
            return;
        var invoice = sentJobs.find(function (x) { return isInvoice(x.status); });
        if (invoice)
            return invoice;
        return sentJobs[0];
    }, [activeJobs]);
    var outstandingPaymentValue = useMemo(function () {
        if (!activeJob)
            return null;
        return getOutstandingPaymentValue(activeJob, jobPayments);
    }, [activeJob, jobPayments]);
    var canRecordPayment = useMemo(function () {
        if (!activeJob)
            return false;
        if (!activeJobs.some(function (x) { return !!x.sentAt; }))
            return false;
        if (activeJob.showBespokeFinancePlans || !!activeJobFinanceInformation) {
            if (!outstandingPaymentValue)
                return false;
        }
        return true;
    }, [
        outstandingPaymentValue,
        activeJob,
        activeJobs,
        activeJobFinanceInformation,
    ]);
    return (React.createElement("div", { className: "add-new-deal-item-control" },
        !canRecordPayment ? (React.createElement(TooltipUI, { title: "this option is not available", arrow: true, placement: "top" },
            React.createElement("div", null,
                React.createElement(CreateButton, { className: "disabled" }, "Record a payment")))) : (React.createElement(CreateButton, { onClick: function () { return setShowRecordPaymentModal(true); } }, "Record a payment")),
        activeJob && customer && (React.createElement(RecordPaymentModal, { job: activeJob, jobPayments: jobPayments, customer: customer, isOpen: showRecordPaymentModal, onClose: function () { return setShowRecordPaymentModal(false); }, recordPaymentCallback: function () {
                onJobUpdateSuccess(activeJob.id);
            }, confirmPaymentCallback: function () {
                onJobUpdateSuccess(activeJob.id);
            } }))));
};
export default RecordDealPaymentControl;
