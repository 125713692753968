import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Button } from '@payaca/components';
import { getTotalCompletedPaymentValueFromJobPayments } from '@bit/payaca-tech.payaca-core.helpers.job-payment';
import { getSpecificJobTypeText, JobTypeSpecificText, } from '@/helpers/jobHelper';
import { currencyPrice } from '@/helpers/financeHelper';
import { getJobContactFromCustomer } from '@bit/payaca-tech.payaca-core.helpers.customer';
import { TextareaField } from '@payaca/components/textareaField';
var CustomerEmailPreview = function (_a) {
    var emailTemplate = _a.emailTemplate, job = _a.job, jobPayments = _a.jobPayments, customer = _a.customer, onChange = _a.onChange, preButtonEmailText = _a.preButtonEmailText, postButtonEmailText = _a.postButtonEmailText;
    var myProfile = useSelector(function (state) { return state.users.myProfile; });
    var companyName = useMemo(function () { var _a, _b; return (_b = (_a = myProfile === null || myProfile === void 0 ? void 0 : myProfile.accounts) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.companyName; }, [myProfile]);
    var logoUrl = useMemo(function () { var _a, _b; return (_b = (_a = myProfile === null || myProfile === void 0 ? void 0 : myProfile.accounts) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.logoUrl; }, [myProfile]);
    var jobType = useMemo(function () { return getSpecificJobTypeText(job.status); }, [job]);
    var amountDue = useMemo(function () {
        if (jobType === JobTypeSpecificText.INVOICE) {
            var jobTotal = job.total;
            var amountPaid = getTotalCompletedPaymentValueFromJobPayments(jobPayments || []);
            return "" + currencyPrice(jobTotal - amountPaid);
        }
        return '';
    }, [job, jobPayments, jobType]);
    var dueDate = useMemo(function () { return moment(job.dueAt).format('Do MMMM YYYY'); }, [job.dueAt]);
    var jobContact = useMemo(function () { return getJobContactFromCustomer(customer, job.contactId); }, [customer]);
    var email = useMemo(function () {
        var email = emailTemplate === null || emailTemplate === void 0 ? void 0 : emailTemplate.replaceAll('[customer_name]', (jobContact === null || jobContact === void 0 ? void 0 : jobContact.name) || '').replaceAll('[company_name]', companyName);
        if (jobType === JobTypeSpecificText.INVOICE) {
            email = email === null || email === void 0 ? void 0 : email.replaceAll('[amount_due]', amountDue).replaceAll('[due_date]', dueDate);
        }
        return email || '';
    }, [amountDue, companyName, dueDate, emailTemplate, jobContact, jobType]);
    useEffect(function () {
        // set email from email template
        var splitEmail;
        if (email.includes('\n[button]\n')) {
            splitEmail = email.split('\n[button]\n');
        }
        else {
            splitEmail = email.split('[button]');
        }
        onChange({
            preButtonEmailText: splitEmail[0],
            postButtonEmailText: splitEmail[1],
        });
    }, [email]);
    return (React.createElement("div", { className: "customer-email-preview-wrapper" },
        React.createElement("div", { className: "customer-email-preview" },
            logoUrl && React.createElement("img", { className: "logo", src: logoUrl }),
            React.createElement(TextareaField, { name: "preButtonEmailText", value: preButtonEmailText, onChange: function (change) { return onChange(change); }, autoSize: true }),
            React.createElement(Button, { isDisabled: true },
                "View ",
                jobType),
            React.createElement(TextareaField, { name: "postButtonEmailText", value: postButtonEmailText, onChange: function (change) { return onChange(change); }, autoSize: true }))));
};
export default CustomerEmailPreview;
