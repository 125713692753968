import React, { useCallback, useEffect, useState, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as usersActions } from '@/api/users';
import { usePrevious } from '@/utils/customHooks';
import { Button } from '@payaca/components/button';
import { ConnectionControl, ConnectionContolAlert, ConnectionControlBenefitList, QuickbooksSetupModal, } from '@/ui/components';
import QuickbooksLogo from './quickbooks.svg';
var QUICKBOOKS_CLIENT_ID = process.env.REACT_APP_QUICKBOOKS_CLIENT_ID;
var QuickbooksConnectionControl = function () {
    var dispatch = useDispatch();
    var _a = useState(false), isImportingQuickbooksItems = _a[0], setIsImportingQuickbooksItems = _a[1];
    var _b = useState(), importItemsMessage = _b[0], setImportItemsMessage = _b[1];
    var _c = useState(false), isImportingQuickbooksContacts = _c[0], setIsImportingQuickbooksContacts = _c[1];
    var _d = useState(), importContactsMessage = _d[0], setImportContactsMessage = _d[1];
    var _e = useState(false), showSetupModal = _e[0], setShowSetupModal = _e[1];
    var isConnectedToQuickbooks = useSelector(function (state) {
        var _a;
        return (_a = state.users.myProfile.accounts[0].integrations) === null || _a === void 0 ? void 0 : _a.quickbooks;
    });
    var previousIsConnectedToQuickbooks = usePrevious(isConnectedToQuickbooks);
    var quickbooksRealmName = useSelector(function (state) {
        var _a, _b;
        return (_b = (_a = state.users.myProfile.accounts[0].integrations) === null || _a === void 0 ? void 0 : _a.quickbooks) === null || _b === void 0 ? void 0 : _b.quickbooksRealmName;
    });
    var isAdmin = useSelector(function (state) {
        return state.users.myProfile.isAdmin;
    });
    var isCisSubcontractor = useSelector(function (state) { var _a; return (_a = state.users.myProfile.accounts[0]) === null || _a === void 0 ? void 0 : _a.isCisSubcontractor; });
    useEffect(function () {
        if (previousIsConnectedToQuickbooks === undefined)
            return;
        if (isConnectedToQuickbooks && !previousIsConnectedToQuickbooks) {
            setShowSetupModal(true);
        }
    }, [isConnectedToQuickbooks, previousIsConnectedToQuickbooks]);
    var importQuickbooksItems = useCallback(function () {
        setIsImportingQuickbooksItems(true);
        dispatch(usersActions.importQuickbooksItems(function (err, data) {
            if (!err) {
                setImportItemsMessage("Quickbooks items imported; " + data.newQuickbooksItems + " new items added, " + data.updatedQuickbooksItems + " items updated");
                setIsImportingQuickbooksItems(false);
            }
            else {
                setImportItemsMessage('An error occurred importing Quickbooks items');
                setIsImportingQuickbooksItems(false);
            }
        }));
    }, [dispatch]);
    var importQuickbooksContacts = useCallback(function () {
        setIsImportingQuickbooksContacts(true);
        dispatch(usersActions.importQuickbooksCustomers(function (err, data) {
            if (!err) {
                setImportContactsMessage("Quickbooks customers imported; " + data.newQuickbooksCustomers + " new customers added, " + data.matchedQuickbooksCustomers + " customers matched");
                setIsImportingQuickbooksContacts(false);
            }
            else {
                setImportContactsMessage('An error occurred importing Quickbooks contacts');
                setIsImportingQuickbooksContacts(false);
            }
        }));
    }, [dispatch]);
    var renderImportButtons = useCallback(function () {
        return (React.createElement("div", { className: "connection-buttons quickbooks-import-buttons" },
            React.createElement("div", { className: "connection-button" },
                isConnectedToQuickbooks && (React.createElement("div", null,
                    React.createElement(Button, { onClick: importQuickbooksItems, isProcessing: isImportingQuickbooksItems, isOutlined: true }, "Import Quickbook items"),
                    React.createElement("div", null, "This will transfer all item details from Quickbooks to Payaca where they don\u2018t already exist."))),
                importItemsMessage && (React.createElement("div", { className: "status-message" }, importItemsMessage))),
            React.createElement("div", { className: "connection-button" },
                isConnectedToQuickbooks && (React.createElement("div", null,
                    React.createElement(Button, { onClick: importQuickbooksContacts, isProcessing: isImportingQuickbooksContacts, isOutlined: true }, "Import Quickbook customers"),
                    React.createElement("div", null, "This will transfer all customers details from Quickbooks to Payaca where they don\u2018t already exist."))),
                importContactsMessage && (React.createElement("div", { className: "status-message" }, importContactsMessage)))));
    }, [
        importContactsMessage,
        importItemsMessage,
        importQuickbooksContacts,
        importQuickbooksItems,
        isConnectedToQuickbooks,
        isImportingQuickbooksContacts,
        isImportingQuickbooksItems,
    ]);
    return (React.createElement(ConnectionControl, { connectionName: "Quickbooks", isConnected: isConnectedToQuickbooks, allowConnectDisconnect: isAdmin, onAddConnection: function () {
            return (window.location.href = "https://appcenter.intuit.com/app/connect/oauth2?client_id=" + QUICKBOOKS_CLIENT_ID + "&scope=com.intuit.quickbooks.accounting%20openid%20profile%20email%20phone%20address&redirect_uri=" + window.location.origin + "/connections/quickbooks&response_type=code&state=payaca123");
        }, onRemoveConnection: function (onConnectionRemoved) {
            return dispatch(usersActions.removeQuickbooksConnection(onConnectionRemoved));
        }, logo: QuickbooksLogo },
        React.createElement(React.Fragment, null,
            !isConnectedToQuickbooks && (React.createElement("div", null,
                React.createElement("div", null, "Get all the benefits of Payaca and Quickbooks with our seamless integration."),
                React.createElement(ConnectionControlBenefitList, { benefits: [
                        'Send an invoice in Payaca and all details will automatically be transferred to Quickbooks and marked as sent.',
                        'Import all of your customer and item details from Quickbooks to Payaca so they can be added to a quote or invoice with one click.',
                    ] }))),
            isConnectedToQuickbooks && (React.createElement("div", { className: "connected-name" },
                "You are connected to",
                ' ',
                React.createElement("span", { style: { fontWeight: 'bold' } }, quickbooksRealmName),
                ".",
                ' ')),
            isAdmin && isConnectedToQuickbooks && renderImportButtons(),
            isConnectedToQuickbooks && isCisSubcontractor && (React.createElement(ConnectionContolAlert, null, "You have enabled yourself as a CIS subcontractor in Payaca, but our Quickbooks integration does not currently support CIS behaviours.")),
            showSetupModal && (React.createElement(QuickbooksSetupModal, { closeQuickbooksSetupModal: function () { return setShowSetupModal(false); } })))));
};
export default QuickbooksConnectionControl;
