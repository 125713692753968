import React from 'react';
import DoneIcon from '@material-ui/icons/Done';
import './ConnectionControlBenefitList.sass';
var ConnectionControlBenefitList = function (_a) {
    var benefits = _a.benefits;
    return (React.createElement("ul", { className: "connection-control-benefit-list" }, benefits.map(function (benefit, i) {
        return (React.createElement("li", { key: i },
            React.createElement(DoneIcon, { style: { fontSize: 15 } }),
            React.createElement("span", null, benefit)));
    })));
};
export default ConnectionControlBenefitList;
