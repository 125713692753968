import React from 'react';
import moment from 'moment';
import './JobTimelineEvent.sass';
import { DATE_TIMESTAMP_FORMAT } from '@payaca/constants';
var JobTimelineEvent = function (_a) {
    var jobTimelineEvent = _a.jobTimelineEvent;
    return (React.createElement("div", { className: "job-timeline-event flex-container flex-center " + jobTimelineEvent.type.replace(/_/g, '-') },
        React.createElement("div", { className: "event-information-container" },
            React.createElement("span", { className: "name" },
                React.createElement("strong", null,
                    jobTimelineEvent.name,
                    ":")),
            React.createElement("span", { className: "description" }, jobTimelineEvent.description)),
        React.createElement("span", { className: "timestamp" }, moment(jobTimelineEvent.timestamp).format(DATE_TIMESTAMP_FORMAT))));
};
export default JobTimelineEvent;
