import React from 'react';
import { faCheck, faTimes, faPaperclip, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MiniLoader } from '../miniLoader';
import './PersistedDragDropFileControl.sass';
var PersistedFileDragDropControl = function (_a) {
    var file = _a.file, _b = _a.isUploading, isUploading = _b === void 0 ? false : _b, _c = _a.isRemoving, isRemoving = _c === void 0 ? false : _c, removeFile = _a.removeFile;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "persisted-drag-drop-file-control" },
            React.createElement("div", { className: "persisted-drag-drop-file-control-inner" },
                React.createElement(FontAwesomeIcon, { icon: faPaperclip }),
                React.createElement("div", { className: "file-name-upload-wrapper" },
                    React.createElement("div", null,
                        React.createElement("span", { className: "file-name" }, file.fileName)),
                    React.createElement("div", { className: "upload-bar-wrapper" },
                        React.createElement("div", { className: "upload-bar" }, isUploading && React.createElement("div", { className: "upload-bar-inner" })))),
                React.createElement("div", { className: "check-icon-wrapper" + (isUploading ? ' uploading' : '') },
                    React.createElement(FontAwesomeIcon, { icon: faCheck })))),
        React.createElement("div", { className: "remove-button", onClick: removeFile },
            React.createElement("span", null, "Remove"),
            React.createElement("div", { className: "cross-icon-wrapper" }, isRemoving ? React.createElement(MiniLoader, null) : React.createElement(FontAwesomeIcon, { icon: faTimes })))));
};
export default PersistedFileDragDropControl;
