import React from 'react';
import { Offline } from 'react-detect-offline';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import './OfflineBanner.sass';
var OfflineBanner = function () {
    return (React.createElement(Offline, null,
        React.createElement("div", { className: "offline-banner" },
            React.createElement(WifiOffIcon, null),
            "Looks like you're offline, please check your connection. Your Payaca experience may be affected until you're back online.")));
};
export default OfflineBanner;
