var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useCallback, useMemo, useState, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SubscriptionPaymentMethodControl from '../subscriptionPaymentMethodControl/SubscriptionPaymentMethodControl';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { actions as subscriptionActions } from '@payaca/store/subscription';
import { actions as userActions } from '@/api/users';
import SelectedProductOverview from '../selectedProductOverview/SelectedProductOverview';
import { getSubscriptionCostIncludingVat } from '@payaca/helpers/subscriptionHelper';
import { useHistory } from 'react-router-dom';
import { ValidatedForm } from '@payaca/components';
import SubscriptionPaymentPreview from '../subscriptionPaymentPreview/SubscriptionPaymentPreview';
import UserSeatsExceededWarning from '../userSeatsExceededWarning/UserSeatsExceededWarning';
import SubscriptionControlHeader from '../subscriptionControlHeader/SubscriptionControlHeader';
import { RecurringInterval, } from '@payaca/types/subscriptionProductTypes';
import './UpdateSubscriptionControl.sass';
var TAX_RATE_PERCENTAGE = process.env
    .REACT_APP_SUBSCRIPTION_TAX_RATE_PERCENTAGE;
var UpdateSubscriptionControl = function (_a) {
    var onUpdateSubscription = _a.onUpdateSubscription, stripeProductId = _a.stripeProductId, stripePriceId = _a.stripePriceId, _b = _a.recurringInterval, recurringInterval = _b === void 0 ? RecurringInterval.YEAR : _b, _c = _a.additionalUserSeats, additionalUserSeats = _c === void 0 ? 0 : _c;
    var history = useHistory();
    var dispatch = useDispatch();
    var _d = useState(false), isSubmitted = _d[0], setIsSubmitted = _d[1];
    var _e = useState(false), updatePaymentMethod = _e[0], setUpdatePaymentMethod = _e[1];
    var _f = useState(false), isCreatingPaymentMethod = _f[0], setIsCreatingPaymentMethod = _f[1];
    var _g = useState(false), initialPaymentPreviewInitialised = _g[0], setInitialPaymentPreviewInitialised = _g[1];
    var isUpdatingSubscription = useSelector(function (state) {
        return state.subscription.isUpdatingSubscription ||
            state.subscription.isCreatingSubscription;
    });
    var accountSubscription = useSelector(function (state) { return state.subscription.accountSubscription; });
    var subscriptionPaymentPreview = useSelector(function (state) { return state.subscription.subscriptionPaymentPreview; });
    var isGettingSubscriptionPaymentPreview = useSelector(function (state) { return state.subscription.isGettingSubscriptionPaymentPreview; });
    var bonusAdditionalUserSeats = useMemo(function () {
        if (!(accountSubscription === null || accountSubscription === void 0 ? void 0 : accountSubscription.subscriptionInformation))
            return 0;
        return accountSubscription.subscriptionInformation.bonusAdditionalUserSeats;
    }, [accountSubscription]);
    var isSubscriptionUpdatedSuccessfully = useSelector(function (state) {
        return state.subscription.isSubscriptionUpdatedSuccessfully ||
            state.subscription.isSubscriptionCreatedSuccessfully;
    });
    var products = useSelector(function (state) { return state.subscription.products; });
    var product = useMemo(function () { return products === null || products === void 0 ? void 0 : products.find(function (x) { return x.stripeId === stripeProductId; }); }, [products, stripeProductId]);
    var productPrice = useMemo(function () {
        if (recurringInterval === 'month') {
            return product.monthlyPrice;
        }
        else {
            return product.annualPrice;
        }
    }, [product, recurringInterval]);
    var canBuyAdditionalUserSeats = useMemo(function () {
        return !!(productPrice === null || productPrice === void 0 ? void 0 : productPrice.canBuyAdditionalUserSeats);
    }, [productPrice]);
    var monthlyAdditionalUserSeatCost = useMemo(function () {
        if (recurringInterval === 'month') {
            return productPrice === null || productPrice === void 0 ? void 0 : productPrice.additionalUserSeatCost;
        }
        else {
            return (productPrice === null || productPrice === void 0 ? void 0 : productPrice.additionalUserSeatCost) ? productPrice.additionalUserSeatCost / 12
                : undefined;
        }
    }, [productPrice, recurringInterval]);
    var activeUsers = useSelector(function (state) {
        return state.users.accountUsers.filter(function (accountUser) {
            return !accountUser.deactivatedAt || accountUser.inviteToken;
        });
    });
    var isProcessing = useMemo(function () {
        return isUpdatingSubscription || isCreatingPaymentMethod;
    }, [isUpdatingSubscription, isCreatingPaymentMethod]);
    var initialFormState = useMemo(function () {
        var _a, _b;
        return {
            recurringInterval: recurringInterval,
            productId: stripeProductId,
            priceId: stripePriceId,
            additionalUserSeats: additionalUserSeats,
            subscriptionId: (_a = accountSubscription === null || accountSubscription === void 0 ? void 0 : accountSubscription.subscriptionInformation) === null || _a === void 0 ? void 0 : _a.stripeSubscriptionId,
            paymentMethodId: (_b = accountSubscription === null || accountSubscription === void 0 ? void 0 : accountSubscription.subscriptionInformation) === null || _b === void 0 ? void 0 : _b.stripePaymentMethodId,
        };
    }, [
        accountSubscription,
        stripeProductId,
        stripePriceId,
        recurringInterval,
        additionalUserSeats,
    ]);
    var paymentMethodDescription = useMemo(function () {
        if (!accountSubscription)
            return null;
        var subscriptionInformation = accountSubscription.subscriptionInformation;
        return subscriptionInformation.stripePaymentMethodBrand + "   \u2022\u2022\u2022\u2022 " + subscriptionInformation.stripePaymentMethodLast4 + "   " + subscriptionInformation.stripePaymentMethodExpiryMonth + "/" + subscriptionInformation.stripePaymentMethodExpiryYear;
    }, [accountSubscription]);
    var onSubmit = useCallback(function (formState) {
        if (formState.subscriptionId) {
            var updateSubscriptionRequestData = {
                stripePriceId: formState.priceId,
                stripePaymentMethodId: formState.paymentMethodId,
                stripeSubscriptionId: formState.subscriptionId,
                additionalUserSeats: formState.additionalUserSeats,
            };
            dispatch(subscriptionActions.requestUpdateSubscription(updateSubscriptionRequestData));
        }
        else {
            var createSubscriptionRequestData = {
                stripePriceId: formState.priceId,
                stripePaymentMethodId: formState.paymentMethodId,
                additionalUserSeats: formState.additionalUserSeats,
            };
            dispatch(subscriptionActions.requestCreateSubscription(createSubscriptionRequestData));
        }
        setIsSubmitted(true);
    }, [dispatch]);
    var calculateCostIncludingVat = useCallback(function (productId, recurringInterval, additionalUserSeats) {
        var product = products === null || products === void 0 ? void 0 : products.find(function (x) { return x.stripeId === productId; });
        if (!product)
            return;
        return getSubscriptionCostIncludingVat(product, recurringInterval, additionalUserSeats, TAX_RATE_PERCENTAGE);
    }, [products]);
    useEffect(function () {
        if (isSubmitted &&
            !isUpdatingSubscription &&
            isSubscriptionUpdatedSuccessfully) {
            onUpdateSubscription && onUpdateSubscription();
        }
    }, [
        isSubmitted,
        isUpdatingSubscription,
        isSubscriptionUpdatedSuccessfully,
        onUpdateSubscription,
    ]);
    useEffect(function () {
        dispatch(userActions.getAccountUsers());
        dispatch(subscriptionActions.clearSubscriptionPaymentPreview());
        return function () {
            dispatch(subscriptionActions.clearSubscriptionPaymentPreview());
        };
    }, []);
    var subscriptionPaymentPreviewElement = useMemo(function () {
        return (React.createElement(SubscriptionPaymentPreview, { subscriptionPaymentPreview: subscriptionPaymentPreview, isLoading: isGettingSubscriptionPaymentPreview }));
    }, [subscriptionPaymentPreview, isGettingSubscriptionPaymentPreview]);
    var updateSubscriptionPaymentPreview = useCallback(function (stripeSubscriptionId, stripePriceId, additionalUserSeats) {
        if (subscriptionPaymentPreview &&
            stripeSubscriptionId ===
                subscriptionPaymentPreview.stripeSubscriptionId &&
            stripePriceId === subscriptionPaymentPreview.stripePriceId &&
            additionalUserSeats === subscriptionPaymentPreview.additionalUserSeats) {
            return;
        }
        dispatch(subscriptionActions.requestGetSubscriptionPaymentPreview({
            stripeSubscriptionId: stripeSubscriptionId,
            stripePriceId: stripePriceId,
            additionalUserSeats: additionalUserSeats,
        }));
    }, [dispatch, subscriptionPaymentPreview]);
    useEffect(function () {
        if ((initialFormState === null || initialFormState === void 0 ? void 0 : initialFormState.subscriptionId) && !initialPaymentPreviewInitialised) {
            updateSubscriptionPaymentPreview(initialFormState.subscriptionId, initialFormState.priceId, initialFormState.additionalUserSeats);
            // we only want this initial preview to generate onComponentMount
            // there is a separate update flow for formState changes
            setInitialPaymentPreviewInitialised(true);
        }
    }, [initialFormState, updateSubscriptionPaymentPreview]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        var selectedUserSeats = product
            ? product.numberOfUserSeats + formState.additionalUserSeats
            : undefined;
        var activeUsersExceedsSelectedUserSeats = selectedUserSeats + bonusAdditionalUserSeats <
            ((activeUsers === null || activeUsers === void 0 ? void 0 : activeUsers.length) || 0);
        var getSubscriptionPaymentMethodControl = function (buttonCopy) { return (React.createElement(SubscriptionPaymentMethodControl, { paymentAmount: calculateCostIncludingVat(formState.productId, formState.recurringInterval, formState.additionalUserSeats), onPaymentMethodChange: function (paymentMethod) {
                setIsCreatingPaymentMethod(false);
                if (paymentMethod) {
                    onSubmit(__assign(__assign({}, formState), { paymentMethodId: paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.id }));
                }
            }, renderActions: function (handleCreatePaymentMethod, applePayButton) {
                return (React.createElement(React.Fragment, null,
                    applePayButton,
                    React.createElement(Button, { onClick: !isProcessing
                            ? function () {
                                setIsCreatingPaymentMethod(true);
                                handleCreatePaymentMethod();
                            }
                            : undefined, isProcessing: isProcessing, styleVariant: ButtonStyleVariant.OUTSIZE }, buttonCopy)));
            } })); };
        return (React.createElement(React.Fragment, null,
            React.createElement(SubscriptionControlHeader, { selectedProduct: accountSubscription.subscriptionProduct, recurringInterval: accountSubscription.recurringInterval }),
            React.createElement("div", { className: "form-section" },
                formState.productId && (React.createElement("div", { className: "selected-product-overview-container" },
                    React.createElement(SelectedProductOverview, { additionalUserSeats: formState.additionalUserSeats, recurringInterval: formState.recurringInterval, taxRatePercentage: TAX_RATE_PERCENTAGE, selectedProduct: product, selectedPrice: productPrice, enableEmbeddedUserSeatControl: true, onChangeAdditionalUserSeats: function (additionalUserSeats) {
                            formState.subscriptionId &&
                                updateSubscriptionPaymentPreview(formState.subscriptionId, formState.priceId, additionalUserSeats);
                            onFieldChange({
                                additionalUserSeats: additionalUserSeats,
                            });
                        } }))),
                subscriptionPaymentPreviewElement),
            formState.productId &&
                formState.subscriptionId &&
                activeUsersExceedsSelectedUserSeats && (React.createElement("div", { className: "form-section" },
                React.createElement(UserSeatsExceededWarning, { selectedUserSeats: selectedUserSeats, bonusAdditionalUserSeats: bonusAdditionalUserSeats, activeUsersCount: activeUsers.length }))),
            formState.productId &&
                (!formState.subscriptionId ||
                    !activeUsersExceedsSelectedUserSeats) && (React.createElement("div", { className: "form-section" },
                !formState.subscriptionId && (React.createElement(React.Fragment, null,
                    React.createElement("h3", { className: "form-section-header" }, "Provide a payment method"),
                    React.createElement("div", { className: "add-payment-method-container" }, getSubscriptionPaymentMethodControl('Create subscription')))),
                !activeUsersExceedsSelectedUserSeats && (React.createElement("div", { className: "review-update-payment-method-container" },
                    React.createElement("h3", { className: "form-section-header" }, "Review your payment method"),
                    !updatePaymentMethod && (React.createElement("div", { className: "review-payment-method-container" },
                        React.createElement("p", null,
                            "Would you like to continue using your current payment method",
                            React.createElement("span", null,
                                React.createElement("strong", null, paymentMethodDescription),
                                " ?")),
                        React.createElement("div", { className: "actions-container" },
                            React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, onClick: function () { return setUpdatePaymentMethod(true); } }, "Change payment method"),
                            React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, isProcessing: isProcessing, onClick: !isProcessing
                                    ? function () { return onSubmit(formState); }
                                    : undefined }, "Update subscription")))),
                    updatePaymentMethod && (React.createElement("div", { className: "update-payment-method-container" }, getSubscriptionPaymentMethodControl('Update subscription'))))),
                isSubscriptionUpdatedSuccessfully === false && isSubmitted && (React.createElement("p", { className: "error-message failed-update-error-message" },
                    "Failed to ",
                    formState.subscriptionId ? 'update' : 'create',
                    ' ',
                    "your subscription. Please check that the payment method you have provided is correct."))))));
    }, [
        monthlyAdditionalUserSeatCost,
        canBuyAdditionalUserSeats,
        product,
        bonusAdditionalUserSeats,
        history,
        activeUsers,
        isSubmitted,
        isProcessing,
        updatePaymentMethod,
        paymentMethodDescription,
        isSubscriptionUpdatedSuccessfully,
        subscriptionPaymentPreviewElement,
        onSubmit,
        calculateCostIncludingVat,
        updateSubscriptionPaymentPreview,
    ]);
    return (React.createElement("div", { className: "update-subscription-control" },
        React.createElement(ValidatedForm, { renderFormContents: renderFormContents, initialFormState: initialFormState })));
};
export default UpdateSubscriptionControl;
