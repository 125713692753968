var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useMemo } from 'react';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import { actions as customerActions } from '@bit/payaca-tech.payaca-core.store.customer';
import { useBlockedEmails } from '@/utils/customHooks';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatedForm } from '@payaca/components';
import { getCustomerContactEmailFieldValidator } from '@bit/payaca-tech.payaca-core.helpers.customer-validation';
import { getIsRequiredFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import { getJobContactFromCustomer } from '@bit/payaca-tech.payaca-core.helpers.customer';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import LoqateAdvisoryEmailInputField from '../loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '@payaca/components/modal';
var AddCustomerEmailModal = function (_a) {
    var _b = _a.isOpen, isOpen = _b === void 0 ? true : _b, customer = _a.customer, customerUpdateCallback = _a.customerUpdateCallback, jobContactId = _a.jobContactId, onClose = _a.onClose;
    var dispatch = useDispatch();
    var blockedEmails = useBlockedEmails();
    var isPersistingCustomer = useSelector(function (state) { return state.customer.isPersistingCustomer; });
    var jobContact = useMemo(function () { return getJobContactFromCustomer(customer, jobContactId); }, [customer, jobContactId]);
    var onSubmit = useCallback(function (customerEmail) {
        var contacts = customer.contacts.map(function (customerContact, index) {
            if (customerContact.id === (jobContact === null || jobContact === void 0 ? void 0 : jobContact.id)) {
                // update email on job contact
                return __assign(__assign({}, customerContact), { emailAddress: customerEmail });
            }
            else {
                return customerContact;
            }
        });
        var customerToPersist = __assign(__assign({}, customer), { contacts: contacts });
        dispatch(customerActions.requestPersistCustomer(customerToPersist, function (customerId) {
            customerUpdateCallback && customerUpdateCallback();
        }));
    }, [customerUpdateCallback, customer, jobContact]);
    var fieldValidators = useMemo(function () {
        var customerContactEmailFieldValidator = getCustomerContactEmailFieldValidator(blockedEmails);
        return {
            emailAddress: [
                getIsRequiredFieldValidator(),
                customerContactEmailFieldValidator,
            ],
        };
    }, [blockedEmails]);
    var initialFormState = useMemo(function () {
        return {
            emailAddress: jobContact === null || jobContact === void 0 ? void 0 : jobContact.emailAddress,
        };
    }, [customer, jobContact]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ValidatedFieldWrapper, { validationResult: validationState['emailAddress'], isTouched: touchedState['emailAddress'] || false },
                React.createElement(LoqateAdvisoryEmailInputField, { label: 'To send this via email please add a customer email address.', value: formState.emailAddress, name: "emailAddress", onChange: onFieldChange, onTouch: onFieldTouch, disableValidation: validationState['emailAddress']
                        ? !validationState['emailAddress'].isValid
                        : false, styleVariant: InputStyleVariant.OUTSIZE })),
            React.createElement("div", { className: "actions-container" },
                React.createElement(Button, { onClick: function () {
                        return !isPersistingCustomer && onSubmit(formState.emailAddress);
                    }, isDisabled: !isValid, isProcessing: isPersistingCustomer, iconAfter: faChevronRight, styleVariant: ButtonStyleVariant.OUTSIZE }, "Ok"))));
    }, [onSubmit, isPersistingCustomer]);
    return (React.createElement(Modal, { isOpen: isOpen, title: "Missing customer email", onClose: onClose },
        React.createElement(ValidatedForm, { renderFormContents: renderFormContents, fieldValidators: fieldValidators, initialFormState: initialFormState })));
};
export default AddCustomerEmailModal;
