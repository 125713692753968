import React, { useRef, useEffect, useState, useCallback, } from "react";
var ResponsiveViewWrapper = function (_a) {
    var downBreakpointSm = _a.downBreakpointSm, downBreakpointXs = _a.downBreakpointXs, className = _a.className, children = _a.children;
    var ref = useRef(null);
    var _b = useState(""), responsiveViewClassName = _b[0], setResponsiveViewClassName = _b[1];
    var calculateResponsiveViewClassName = useCallback(function () {
        if (!ref.current) {
            setResponsiveViewClassName("");
            return;
        }
        var width = ref.current.offsetWidth;
        if (downBreakpointXs && width <= downBreakpointXs) {
            setResponsiveViewClassName("xs-view");
        }
        else if (downBreakpointSm && width <= downBreakpointSm) {
            setResponsiveViewClassName("sm-view");
        }
        else {
            setResponsiveViewClassName("");
        }
    }, [ref, downBreakpointSm, downBreakpointXs]);
    useEffect(function () {
        typeof window !== "undefined" &&
            window.addEventListener("resize", calculateResponsiveViewClassName);
        calculateResponsiveViewClassName();
        return function () {
            typeof window !== "undefined" &&
                window.removeEventListener("resize", calculateResponsiveViewClassName);
        };
    }, [ref, calculateResponsiveViewClassName]);
    return (React.createElement("div", { className: "responsive-view-wrapper " + className + " " + responsiveViewClassName, ref: ref }, children));
};
export default ResponsiveViewWrapper;
