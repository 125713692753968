import React from 'react';
import CompanyLogo from '../companyLogo/CompanyLogo';
import './JobOverviewHeaderUpper.sass';
var JobOverviewHeaderUpper = function (_a) {
    var account = _a.account;
    return (React.createElement("div", { className: "job-overview-header-upper" },
        React.createElement(CompanyLogo, { logoUrl: account.logoUrl }),
        React.createElement("div", { className: "company-details-wrapper" },
            React.createElement("span", { className: "company-name" }, account.companyName),
            React.createElement("p", { className: "company-contact" },
                account.email && React.createElement("span", null, account.email),
                account.contactNumber && React.createElement("span", null, account.contactNumber),
                account.address && React.createElement("span", null, account.address),
                account.city && React.createElement("span", null, account.city),
                account.postcode && React.createElement("span", null, account.postcode)))));
};
export default JobOverviewHeaderUpper;
