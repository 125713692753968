import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import './MarkAsCompleteModal.css';
import { ModalWrapper } from '@/ui/components';
import { Button } from '@payaca/components';
import { actions as jobActions } from '@/api/jobs';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
var MarkAsCompleteModal = function (_a) {
    var isOpen = _a.isOpen, jobId = _a.jobId, onClose = _a.onClose, markAsCompleteCallback = _a.markAsCompleteCallback;
    var dispatch = useDispatch();
    var _b = useState(false), isProcessing = _b[0], setIsProcessing = _b[1];
    var onMarkAsComplete = useCallback(function () {
        setIsProcessing(true);
        dispatch(jobActions.markAsComplete(jobId, function () {
            markAsCompleteCallback && markAsCompleteCallback();
            onClose && onClose();
            setIsProcessing(false);
        }));
    }, [jobId, dispatch, markAsCompleteCallback]);
    return (React.createElement(ModalWrapper, { open: isOpen, title: "Send satisfaction note", size: "xs", onClose: onClose, classes: "mark-as-complete-modal" },
        React.createElement("p", null, "By sending a satisfaction note to my customer I declare that all necessary work for this job is complete."),
        React.createElement("div", { className: "button-container" },
            React.createElement(Button, { onClick: onMarkAsComplete, iconAfter: faChevronRight, isProcessing: isProcessing }, "Continue"))));
};
export default MarkAsCompleteModal;
