var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
export var getDeal = function (state, dealId) {
    var _a;
    return state.deals.deals && ((_a = state.deals.deals[dealId]) === null || _a === void 0 ? void 0 : _a.entity);
};
export var getDealByJobId = function (state, jobId) {
    var job = getJob(state, jobId);
    if (!job)
        return;
    return getDeal(state, job.dealId);
};
export var getJob = function (state, jobId) {
    var _a;
    return state.jobsStore.jobs && ((_a = state.jobsStore.jobs[jobId]) === null || _a === void 0 ? void 0 : _a.entity);
};
var sortableJobTimestamps = ['createdAt'];
export var getLineItemGroup = function (state, lineItemGroupId) {
    var _a;
    return (state.lineItemGroups.lineItemGroups && ((_a = state.lineItemGroups.lineItemGroups[lineItemGroupId]) === null || _a === void 0 ? void 0 : _a.entity));
};
export var getLineItemsByLineItemGroupId = function (state, lineItemGroupId) {
    var _a;
    var lineItemGroup = getLineItemGroup(state, lineItemGroupId);
    if (!lineItemGroup)
        return [];
    if (!state.lineItemsV2.lineItems)
        return [];
    var lineItemIds = ((_a = lineItemGroup === null || lineItemGroup === void 0 ? void 0 : lineItemGroup.lineItemRelations) === null || _a === void 0 ? void 0 : _a.map(function (x) { return x.lineItemId; })) || [];
    var lineItems = lineItemIds.map(function (lineItemId) {
        var _a;
        var lineItem = (_a = state.lineItemsV2.lineItems[lineItemId]) === null || _a === void 0 ? void 0 : _a.entity;
        return lineItem;
    });
    return lineItems.filter(function (x) { return !!x; });
};
export var getJobsByDealId = function (state, dealId) {
    var _a;
    var deal = getDeal(state, dealId);
    if (!deal)
        return [];
    if (!state.jobsStore.jobs)
        return [];
    var jobIds = (deal === null || deal === void 0 ? void 0 : deal.propositionIds) || [];
    if ((_a = deal === null || deal === void 0 ? void 0 : deal.invoiceIds) === null || _a === void 0 ? void 0 : _a.length) {
        jobIds = jobIds.concat(deal.invoiceIds);
    }
    var jobs = jobIds.map(function (jobId) {
        var _a;
        var job = (_a = state.jobsStore.jobs[jobId]) === null || _a === void 0 ? void 0 : _a.entity;
        if (!job)
            return null;
        var sortTimestamp = sortableJobTimestamps.find(function (t) { return job[t]; });
        return __assign(__assign({}, job), { sortTimestamp: sortTimestamp ? job[sortTimestamp] : '' });
    });
    return jobs
        .filter(function (x) { return !!x; })
        .sort(function (a, b) {
        return new Date(b.sortTimestamp || '').getTime() -
            new Date(a.sortTimestamp || '').getTime();
    });
};
export var getJobPreviewsByDealId = function (state, dealId) {
    var _a, _b;
    var deal = getDeal(state, dealId);
    if (!deal)
        return [];
    if (!((_a = state === null || state === void 0 ? void 0 : state.jobPreviews) === null || _a === void 0 ? void 0 : _a.jobPreviews))
        return [];
    var jobIds = (deal === null || deal === void 0 ? void 0 : deal.propositionIds) || [];
    if ((_b = deal === null || deal === void 0 ? void 0 : deal.invoiceIds) === null || _b === void 0 ? void 0 : _b.length) {
        jobIds = jobIds.concat(deal.invoiceIds);
    }
    var jobPreviews = jobIds.map(function (jobId) {
        var _a;
        return (_a = state.jobPreviews.jobPreviews[jobId]) === null || _a === void 0 ? void 0 : _a.entity;
    });
    return jobPreviews
        .filter(function (x) { return !!x; })
        .sort(function (a, b) {
        return new Date(b.job.updatedAt || '').getTime() -
            new Date(a.job.updatedAt || '').getTime();
    });
};
export var getActiveJobFinanceInformationByJobId = function (state, jobId) {
    var job = getJob(state, jobId);
    if (!job)
        return;
    return getActiveJobFinanceInformationByDealId(state, job.dealId);
};
export var getActiveJobFinanceInformationByDealId = function (state, dealId) {
    var _a, _b;
    var deal = getDeal(state, dealId);
    if (!deal)
        return;
    if (!deal.activeJobFinanceInformationId)
        return;
    if (!((_a = state.jobFinanceInformation) === null || _a === void 0 ? void 0 : _a.jobFinanceInformation))
        return;
    return (_b = state.jobFinanceInformation.jobFinanceInformation[deal.activeJobFinanceInformationId]) === null || _b === void 0 ? void 0 : _b.entity;
};
export var getJobFinanceInformationByDealId = function (state, dealId) {
    var _a;
    var deal = getDeal(state, dealId);
    if (!deal)
        return [];
    if (!((_a = state.jobFinanceInformation) === null || _a === void 0 ? void 0 : _a.jobFinanceInformation))
        return [];
    var jobFinanceInformation = deal.jobFinanceInformationIds.map(function (jobFinanceInformationId) {
        var _a;
        return (_a = state.jobFinanceInformation.jobFinanceInformation[jobFinanceInformationId]) === null || _a === void 0 ? void 0 : _a.entity;
    });
    return jobFinanceInformation.filter(function (x) { return !!x; });
};
export var getJobPayment = function (state, jobPaymentId) {
    var _a;
    return (state.jobPayments.jobPayments && ((_a = state.jobPayments.jobPayments[jobPaymentId]) === null || _a === void 0 ? void 0 : _a.entity));
};
export var getJobPaymentsByJobId = function (state, jobId) {
    var job = getJob(state, jobId);
    if (!job)
        return [];
    return getJobPaymentsByDealId(state, job.dealId);
};
export var getJobPaymentsByDealId = function (state, dealId) {
    var _a;
    var deal = getDeal(state, dealId);
    if (!deal)
        return [];
    if (!((_a = state.jobPayments) === null || _a === void 0 ? void 0 : _a.jobPayments))
        return [];
    var jobPayments = deal.jobPaymentIds.map(function (jobPaymentId) {
        var _a;
        return (_a = state.jobPayments.jobPayments[jobPaymentId]) === null || _a === void 0 ? void 0 : _a.entity;
    });
    return jobPayments.filter(function (x) { return !!x; });
};
export var getJobByJobLineItemGroupId = function (state, jobLineItemGroupId) {
    var jobLineItemGroup = getJobLineItemGroup(state, jobLineItemGroupId);
    if (!jobLineItemGroup)
        return;
    return getJob(state, jobLineItemGroup.jobId);
};
export var getJobLineItem = function (state, jobLineItemId) {
    var _a;
    return (state.jobsStore.jobLineItems && ((_a = state.jobsStore.jobLineItems[jobLineItemId]) === null || _a === void 0 ? void 0 : _a.entity));
};
export var getJobLineItemGroup = function (state, jobLineItemGroupId) {
    var _a;
    return (state.jobsStore.jobLineItemGroups && ((_a = state.jobsStore.jobLineItemGroups[jobLineItemGroupId]) === null || _a === void 0 ? void 0 : _a.entity));
};
export var getJobLineItemAttachmentByJobLineItemId = function (state, jobLineItemId) {
    var _a;
    if (!jobLineItemId)
        return;
    var jobLineItem = state.jobsStore.jobLineItems && ((_a = state.jobsStore.jobLineItems[jobLineItemId]) === null || _a === void 0 ? void 0 : _a.entity);
    var jobLineItemAttachmentIds = (jobLineItem === null || jobLineItem === void 0 ? void 0 : jobLineItem.jobLineItemAttachmentIds) || [];
    var jobLineItemAttachments = jobLineItemAttachmentIds.map(function (jobLineItemAttachmentId) {
        var _a;
        return (state.jobsStore.jobLineItemAttachments && ((_a = state.jobsStore.jobLineItemAttachments[jobLineItemAttachmentId]) === null || _a === void 0 ? void 0 : _a.entity));
    });
    return jobLineItemAttachments.find(function (x) { return !!x; });
};
export var getJobAttachmentsByJobId = function (state, jobId) {
    var job = getJob(state, jobId);
    if (!job)
        return [];
    if (!state.jobsStore.jobAttachments)
        return [];
    var jobAttachmentIds = (job === null || job === void 0 ? void 0 : job.jobAttachmentIds) || [];
    var jobAttachments = jobAttachmentIds.map(function (jobAttachmentId) {
        var _a;
        return (_a = state.jobsStore.jobAttachments[jobAttachmentId]) === null || _a === void 0 ? void 0 : _a.entity;
    });
    return jobAttachments.filter(function (x) { return !!x; });
};
export var getJobLineItemGroupsByJobId = function (state, jobId) {
    var job = getJob(state, jobId);
    if (!job)
        return [];
    var lineItemGroups = job.jobLineItemGroupIds.map(function (id) {
        return getJobLineItemGroup(state, id);
    });
    return lineItemGroups.filter(function (x) { return !!x; });
};
export var getJobLineItemsByJobId = function (state, jobId) {
    var job = getJob(state, jobId);
    if (!job)
        return [];
    var lineItems = job.jobLineItemIds.map(function (id) {
        return getJobLineItem(state, id);
    });
    return lineItems.filter(function (x) { return !!x; });
};
export var getJobLineItemAttachments = function (state, jobLineItemAttachmentIds) {
    return jobLineItemAttachmentIds
        .map(function (jobLineItemAttachmentId) {
        var _a, _b;
        return ((_a = state.jobsStore) === null || _a === void 0 ? void 0 : _a.jobLineItemAttachments) && ((_b = state.jobsStore.jobLineItemAttachments[jobLineItemAttachmentId]) === null || _b === void 0 ? void 0 : _b.entity);
    })
        .filter(function (x) { return !!x; });
};
export var getJobLineItemAttachmentsByJobId = function (state, jobId) {
    var job = getJob(state, jobId);
    if (!job)
        return [];
    var jobLineItems = getJobLineItemsByJobId(state, jobId);
    var attachments = jobLineItems.reduce(function (acc, jli) {
        return __spreadArrays(acc, getJobLineItemAttachments(state, jli.jobLineItemAttachmentIds));
    }, []);
    return attachments.filter(function (x) { return !!x; });
};
export var getRelatedJobByDealId = function (state, dealId, jobId) {
    var jobs = getJobsByDealId(state, dealId);
    return jobs.find(function (job) { return job.id != jobId; });
};
export var getJobDownloadByJobId = function (state, jobId, type) {
    var _a, _b, _c, _d;
    if (!jobId)
        return '';
    return ((_d = (_c = (_b = (_a = state.fileDownload) === null || _a === void 0 ? void 0 : _a.fileDownloads) === null || _b === void 0 ? void 0 : _b[jobId]) === null || _c === void 0 ? void 0 : _c[type]) === null || _d === void 0 ? void 0 : _d.entity) || '';
};
