import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { actions as jobsActions } from '@bit/payaca-tech.payaca-core.store.jobs';
import JobLineItemControl from '../jobLineItemControl/JobLineItemControl';
import './JobLineItemsControl.css';
import { useState } from 'react';
import SelectItemModal from '../selectItemModal/SelectItemModal';
import { getJobByJobLineItemGroupId, getJobLineItemGroup, } from '@/utils/stateAccessors';
import { ClickableArea, CreateArea } from '@payaca/components/clickableArea';
var JobLineItemsControl = function (_a) {
    var _b;
    var jobLineItemGroupId = _a.jobLineItemGroupId, jobIsInvoice = _a.jobIsInvoice;
    var dispatch = useDispatch();
    var _c = useState(false), isCreatingJobLineItem = _c[0], setIsCreatingJobLineItem = _c[1];
    var _d = useState(false), isAddingExistingJobLineItem = _d[0], setIsAddingExistingJobLineItem = _d[1];
    var _e = useState(false), showLineItemSearchModal = _e[0], setShowLineItemSearchModal = _e[1];
    var jobLineItemGroup = useSelector(function (state) { return getJobLineItemGroup(state, jobLineItemGroupId); });
    var job = useSelector(function (state) {
        return getJobByJobLineItemGroupId(state, jobLineItemGroupId);
    });
    var createJobLineItem = useCallback(function (lineItemId) {
        if (!jobLineItemGroup)
            return;
        lineItemId
            ? setIsAddingExistingJobLineItem(true)
            : setIsCreatingJobLineItem(true);
        dispatch(jobsActions.requestCreateJobLineItem({
            jobId: jobLineItemGroup.jobId,
            jobLineItemGroupId: jobLineItemGroup.id,
            lineItemId: lineItemId,
        }, function () {
            setIsCreatingJobLineItem(false);
            setIsAddingExistingJobLineItem(false);
            dispatch(jobsActions.requestGetJobWithJobGroupsAndJobLineItems(jobLineItemGroup.jobId));
        }));
    }, [jobLineItemGroup, dispatch]);
    return (React.createElement("div", { className: "job-line-items-control" },
        React.createElement("div", { className: "job-line-item-group-controls-container" }, (_b = jobLineItemGroup === null || jobLineItemGroup === void 0 ? void 0 : jobLineItemGroup.jobLineItemIds) === null || _b === void 0 ? void 0 : _b.sort().map(function (jobLineItemId, index) { return (React.createElement(JobLineItemControl, { jobIsInvoice: jobIsInvoice, key: "job-line-item-control-" + index, jobLineItemId: jobLineItemId })); })),
        React.createElement("div", { className: "create-or-add-existing-container" },
            React.createElement(ClickableArea, { onClick: function () { return setShowLineItemSearchModal(true); }, isProcessing: isAddingExistingJobLineItem }, "Search existing items"),
            React.createElement("span", { className: "or" }, "or"),
            React.createElement(CreateArea, { isProcessing: isCreatingJobLineItem, onClick: function () { return createJobLineItem(); } }, "Create new item")),
        React.createElement(SelectItemModal, { isOpen: showLineItemSearchModal, onClose: function () { return setShowLineItemSearchModal(false); }, onSelectItem: function (lineItemId) {
                createJobLineItem(lineItemId);
                setShowLineItemSearchModal(false);
            }, disabledItemIds: (job === null || job === void 0 ? void 0 : job.lineItemIds) || [] })));
};
export default JobLineItemsControl;
