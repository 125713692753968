import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as usersActions } from '@/api/users';
import { usePrevious } from '@/utils/customHooks';
import { Button } from '@payaca/components/button';
import { ConnectionControl, ConnectionContolAlert, ConnectionControlBenefitList, XeroSetupModal, } from '@/ui/components';
import XeroLogo from '@/assets/images/xero.svg';
var XERO_CLIENT_ID = process.env.REACT_APP_XERO_CLIENT_ID;
var XeroConnectionControl = function () {
    var dispatch = useDispatch();
    var _a = useState(false), isImportingXeroItems = _a[0], setIsImportingXeroItems = _a[1];
    var _b = useState(), importItemsMessage = _b[0], setImportItemsMessage = _b[1];
    var _c = useState(false), isImportingXeroContacts = _c[0], setIsImportingXeroContacts = _c[1];
    var _d = useState(), importContactsMessage = _d[0], setImportContactsMessage = _d[1];
    var _e = useState(false), showSetupModal = _e[0], setShowSetupModal = _e[1];
    var xeroConnection = useSelector(function (state) {
        var _a;
        return (_a = state.users.myProfile.accounts[0].integrations) === null || _a === void 0 ? void 0 : _a.xero;
    });
    var hasXeroConnection = useMemo(function () { return !!xeroConnection; }, [xeroConnection]);
    var hasXeroConnectionExpired = useMemo(function () { return xeroConnection === null || xeroConnection === void 0 ? void 0 : xeroConnection.hasRefreshTokenExpired; }, [xeroConnection]);
    var previousHasXeroConnection = usePrevious(hasXeroConnection);
    var xeroTenantName = useMemo(function () { return xeroConnection === null || xeroConnection === void 0 ? void 0 : xeroConnection.xeroTenantName; }, [xeroConnection]);
    var defaultRevenueAccountCode = useMemo(function () { return xeroConnection === null || xeroConnection === void 0 ? void 0 : xeroConnection.xeroDefaultRevenueAccountCode; }, [xeroConnection]);
    var isConnectedToXero = useMemo(function () { return hasXeroConnection && !hasXeroConnectionExpired; }, [hasXeroConnection, hasXeroConnectionExpired]);
    var isAdmin = useSelector(function (state) {
        return state.users.myProfile.isAdmin;
    });
    var xeroAccounts = useSelector(function (state) {
        return state.users.xeroAccounts;
    });
    var isCisSubcontractor = useSelector(function (state) { var _a; return (_a = state.users.myProfile.accounts[0]) === null || _a === void 0 ? void 0 : _a.isCisSubcontractor; });
    var cisDeductionRate = useSelector(function (state) { var _a; return (_a = state.users.myProfile.accounts[0]) === null || _a === void 0 ? void 0 : _a.cisDeductionRate; });
    useEffect(function () {
        if (previousHasXeroConnection === undefined) {
            return;
        }
        if (isConnectedToXero && !previousHasXeroConnection) {
            setShowSetupModal(true);
        }
        else if (isConnectedToXero) {
            // connected to xero already, check that xeroDefaultRevenueAccountCode exists
            if (!defaultRevenueAccountCode || isNaN(defaultRevenueAccountCode)) {
                // code does not exist get xero accounts and show modal
                dispatch(usersActions.getXeroAccounts(function () { return setShowSetupModal(true); }));
            }
        }
    }, [
        defaultRevenueAccountCode,
        dispatch,
        isConnectedToXero,
        previousHasXeroConnection,
    ]);
    var importXeroItems = useCallback(function () {
        setIsImportingXeroItems(true);
        dispatch(usersActions.importXeroItems(function (err, data) {
            if (!err) {
                setImportItemsMessage("Xero items imported; " + data.newXeroItems + " new items added, " + data.updatedXeroItems + " items updated");
                setIsImportingXeroItems(false);
            }
            else {
                setImportItemsMessage('An error occurred importing Xero items');
                setIsImportingXeroItems(false);
            }
        }));
    }, [dispatch]);
    var importXeroContacts = useCallback(function () {
        setIsImportingXeroContacts(true);
        dispatch(usersActions.importXeroContacts(function (err, data) {
            if (!err) {
                setImportContactsMessage("Xero customers imported; " + data.newXeroCustomers + " new customers added, " + data.matchedXeroCustomers + " customers matched");
                setIsImportingXeroContacts(false);
            }
            else {
                setImportContactsMessage('An error occurred importing Xero contacts');
                setIsImportingXeroContacts(false);
            }
        }));
    }, [dispatch]);
    var updateXeroSettings = useCallback(function (newDefaultRevenueAccountCode) {
        dispatch(usersActions.updateXeroSettings({
            xeroDefaultRevenueAccountCode: newDefaultRevenueAccountCode,
        }, function () {
            dispatch(usersActions.getProfile());
        }));
    }, [dispatch]);
    var renderImportButtons = useCallback(function () {
        return (React.createElement("div", { className: "connection-buttons xero-import-buttons" },
            React.createElement("div", { className: "connection-button" },
                React.createElement("div", null,
                    React.createElement(Button, { onClick: importXeroItems, isProcessing: isImportingXeroItems, isOutlined: true }, "Import Xero items"),
                    React.createElement("div", null, "This will transfer all item details from Xero to Payaca where they don\u2018t already exist.")),
                importItemsMessage && (React.createElement("div", { className: "status-message" }, importItemsMessage))),
            React.createElement("div", { className: "connection-button" },
                React.createElement("div", null,
                    React.createElement(Button, { onClick: importXeroContacts, isProcessing: isImportingXeroContacts, isOutlined: true }, "Import Xero customers"),
                    React.createElement("div", null, "This will transfer all customers details from Xero to Payaca where they don\u2018t already exist")),
                importContactsMessage && (React.createElement("div", { className: "status-message" }, importContactsMessage)))));
    }, [
        importContactsMessage,
        importItemsMessage,
        importXeroContacts,
        importXeroItems,
        isImportingXeroContacts,
        isImportingXeroItems,
    ]);
    return (React.createElement(ConnectionControl, { hasConnectionExpired: hasXeroConnectionExpired, connectionName: "Xero", isConnected: isConnectedToXero, allowConnectDisconnect: isAdmin, logo: XeroLogo, onAddConnection: function () {
            return (window.location.href = "https://login.xero.com/identity/connect/authorize?response_type=code&client_id=" + XERO_CLIENT_ID + "&redirect_uri=" + window.location.origin + "/connections/xero&scope=openid profile email accounting.contacts accounting.settings accounting.transactions offline_access&state=123");
        }, onRemoveConnection: function (onConnectionRemoved) {
            dispatch(usersActions.removeXeroConnection(onConnectionRemoved));
        } },
        React.createElement(React.Fragment, null,
            !hasXeroConnection && (React.createElement("div", null,
                React.createElement("div", null, "Get all the benefits of Payaca and Xero with our seamless integration."),
                React.createElement(ConnectionControlBenefitList, { benefits: [
                        'Send an invoice in Payaca and all details will automatically be transferred to Xero and marked as sent.',
                        'Import all of your customer and item details from Xero to Payaca so they can be added to a quote or invoice with one click.',
                    ] }))),
            xeroTenantName &&
                (!hasXeroConnectionExpired ? (React.createElement("div", { className: "connected-name" },
                    "You are connected to",
                    ' ',
                    React.createElement("span", { style: { fontWeight: 'bold' } }, xeroTenantName),
                    ".")) : (React.createElement("div", { className: "connected-name" },
                    "Your connection with",
                    ' ',
                    React.createElement("span", { style: { fontWeight: 'bold' } }, xeroTenantName),
                    " has expired, please click the button below to reconnect."))),
            isAdmin && isConnectedToXero && renderImportButtons(),
            isConnectedToXero && isCisSubcontractor && (React.createElement(ConnectionContolAlert, null,
                React.createElement("div", { style: { fontSize: '0.9rem' } },
                    React.createElement("p", null,
                        "As you have enabled yourself as a CIS subcontractor in Payaca, you must have a corresponding account in Xero for us to send your CIS labour income to. Please",
                        ' ',
                        React.createElement("strong", null,
                            React.createElement("a", { href: "https://central.xero.com/s/article/Enable-CIS-in-your-organisation", target: "_blank", rel: "noopener noreferrer" }, "follow these instructions")),
                        ' ',
                        "to enable CIS for your organisation in Xero if you haven't already."),
                    React.createElement("p", null,
                        "Make sure your CIS subcontractor deduction rates in Xero and Payaca match. The subcontractor deduction rate you have set in Payaca is ",
                        React.createElement("strong", null,
                            cisDeductionRate,
                            "%"),
                        ".")))),
            showSetupModal && (React.createElement(XeroSetupModal, { xeroAccounts: xeroAccounts, updateXeroSettings: updateXeroSettings, closeXeroSetupModal: function () { return setShowSetupModal(false); }, currentXeroDefaultRevenueAccountCode: defaultRevenueAccountCode })))));
};
export default XeroConnectionControl;
