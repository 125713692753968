import React from 'react';
import './SetupUserPage.sass';
import UnauthenticatedPageWrapper from '../pageWrappers/unauthenticatedPageWrapper/UnauthenticatedPageWrapper';
import SetupUserForm from '@/ui/components/setupUserForm/SetupUserForm';
var SetupUserPage = function () {
    return (React.createElement(UnauthenticatedPageWrapper, { className: "setup-user-page" },
        React.createElement("h1", { className: "page-title" }, "Join Payaca"),
        React.createElement(SetupUserForm, null)));
};
export default SetupUserPage;
