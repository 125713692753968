import React, { Component } from 'react';
import union from 'lodash.union';
import get from 'lodash.get';
import values from 'lodash.values';

import { ValidatedField } from '@/ui/components';

import { getFieldErrors } from '@/helpers/formHelper';

export default class PasswordConfirmationFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      allowedValidations: [],
    };
  }

  componentDidMount() {
    this.validateForm();
  }

  validateForm(newName, newValue) {
    let newStateErrors = { ...this.state.errors };

    // validate each field, add error to state errors object
    ['newPassword', 'confirmPassword'].forEach((n) => {
      let name = n;
      let value = this.props[name];
      if (newName && newName === n) {
        value = newValue;
        name = newName;
      }
      const errorsForField = this.getErrorsForField(name, value);
      if (errorsForField) {
        newStateErrors[name] = errorsForField;
      }
    });

    // emit invalid state
    this.props.onInvalidInput(
      !!values(newStateErrors).filter((v) => v.length).length
    );
    // set errors
    this.setState({
      errors: newStateErrors,
    });
  }

  onInputChange(name, value) {
    // // add field to allowed validations
    const newAllowedValidations = union(this.state.allowedValidations, [name]);
    this.setState({
      allowedValidations: newAllowedValidations,
    });
    // validate form on callback of field change so most newPassword is set to parent state require for "passwordMatch"
    this.props.onFieldChange({ [name]: value }, (name, value) =>
      this.validateForm(name, value)
    );
  }

  getErrorsForField(name, value) {
    const fieldValidations = get(this.props, ['validations', name]);
    return getFieldErrors(value, fieldValidations, this.props.newPassword);
  }

  renderInputs() {
    return [
      {
        name: 'newPassword',
        label: 'New password',
      },
      {
        name: 'confirmPassword',
        label: 'Confirm password',
      },
    ].map(({ name, label }, i) => {
      // get first error on field
      const fieldError = get(this.state.errors, [name, 0]);
      return (
        <ValidatedField
          key={i}
          name={name}
          label={label}
          value={this.props[name]}
          onChange={(name, value) => this.onInputChange(name, value)}
          fieldType="password"
          error={fieldError && fieldError.error}
          allowValidation={this.state.allowedValidations.find(
            (n) => n === name
          )}
          type="password"
        />
      );
    });
  }
  render() {
    return <div>{this.renderInputs()}</div>;
  }
}
