import React, { useMemo, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { actions as usersActions } from '@/api/users';
import { actions as appActions } from '@/api/app';
import { actions as jobsActions } from '@bit/payaca-tech.payaca-core.store.jobs';
import { actions as dealsActions } from '@bit/payaca-tech.payaca-core.store.deals';
import { actions as jobPaymentsActions } from '@bit/payaca-tech.payaca-core.store.job-payments';
import './EditJobPage.sass';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditJobControl from '@/ui/components/editJobControl/EditJobControl';
import { getJobContactFromCustomer } from '@bit/payaca-tech.payaca-core.helpers.customer';
import { getModal } from '@/helpers/modalHelper';
import { IntercomAPI } from 'react-intercom';
import { getUrlSearchParam } from '@bit/payaca-tech.payaca-core.helpers.url';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import PreviewAndSendJobControl from '@/ui/components/previewAndSendJobControl/PreviewAndSendJobControl';
import { Modal } from '@payaca/components/modal';
import PreviewJobControl from '@/ui/components/previewJobControl/PreviewJobControl';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
export var ViewType;
(function (ViewType) {
    ViewType["EDIT"] = "create";
    ViewType["PREVIEW"] = "preview";
    ViewType["SEND"] = "send";
})(ViewType || (ViewType = {}));
var EditJobPage = function (_a) {
    var jobId = _a.jobId, viewType = _a.viewType, sendJobToCustomer = _a.sendJobToCustomer;
    var dispatch = useDispatch();
    var history = useHistory();
    var location = useLocation();
    var _b = useState(viewType || ViewType.EDIT), view = _b[0], setView = _b[1];
    var _c = useState(), validationResult = _c[0], setValidationResult = _c[1];
    var job = useSelector(function (state) {
        var _a;
        return state.jobsStore.jobs && ((_a = state.jobsStore.jobs[jobId]) === null || _a === void 0 ? void 0 : _a.entity);
    });
    useEffect(function () {
        if (job && job.bouncedAt) {
            setView(ViewType.SEND);
        }
    }, [job === null || job === void 0 ? void 0 : job.bouncedAt]);
    var navigateToView = useMemo(function () {
        return getUrlSearchParam(location.search, 'view');
    }, [location]);
    useEffect(function () {
        if (navigateToView === ViewType.SEND &&
            validationResult &&
            validationResult.isValid) {
            setView(ViewType.SEND);
        }
    }, [navigateToView, validationResult]);
    useEffect(function () {
        dispatch(jobsActions.requestGetJobValidationResult(jobId, function (validationResult) {
            setValidationResult(validationResult);
        }));
        return function () {
            dispatch(jobsActions.clearJobUpdateResults());
            dispatch(jobsActions.clearJobLineItemUpdateResults());
            dispatch(jobsActions.clearJobLineItemGroupUpdateResults());
        };
    }, []);
    useEffect(function () {
        if (jobId) {
            dispatch(jobsActions.requestGetJobWithJobGroupsAndJobLineItems(jobId));
            dispatch(jobsActions.requestGetJobAttachmentsForJob(jobId));
        }
    }, [jobId]);
    useEffect(function () {
        if (job &&
            !job.sentAt &&
            validationResult &&
            !validationResult.isValid &&
            view == ViewType.PREVIEW) {
            setView(ViewType.EDIT);
        }
    }, [validationResult, view, job]);
    var integrations = useSelector(function (state) {
        var _a, _b, _c;
        return (_c = (_b = (_a = state.users.myProfile) === null || _a === void 0 ? void 0 : _a.accounts) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.integrations;
    });
    var features = useSelector(function (state) {
        var _a;
        return (_a = state.account.accountAccessInformation) === null || _a === void 0 ? void 0 : _a.features;
    });
    var customer = useSelector(function (state) {
        return state.customer.currentCustomer;
    });
    var isJobMarkedAsSentSuccessfully = useSelector(function (state) {
        return state.jobsStore.isJobMarkedAsSentSuccessfully;
    });
    var markJobAsSentErrorMessage = useSelector(function (state) {
        return state.jobsStore.markJobAsSentErrorMessage;
    });
    useEffect(function () {
        if (job && (job === null || job === void 0 ? void 0 : job.dealId)) {
            dispatch(dealsActions.requestGetDeal(job === null || job === void 0 ? void 0 : job.dealId));
            dispatch(jobPaymentsActions.requestGetJobPaymentsForDeal(job === null || job === void 0 ? void 0 : job.dealId));
            dispatch(jobsActions.requestGetJobsForDeal(job === null || job === void 0 ? void 0 : job.dealId));
        }
    }, [job === null || job === void 0 ? void 0 : job.dealId]);
    var isAllowedZeroPercent = useMemo(function () {
        // accout is approved and subscription has feature
        return !!((integrations === null || integrations === void 0 ? void 0 : integrations.zeroPercentFinance.isApproved) && (features === null || features === void 0 ? void 0 : features.hasZeroPercentFinance));
    }, [integrations, features]);
    useEffect(function () {
        if (isAllowedZeroPercent) {
            dispatch(usersActions.requestGetFinancePlans());
        }
        return function () {
            dispatch(usersActions.clearFinancePlans());
        };
    }, [isAllowedZeroPercent]);
    var isJobSentSuccessfully = useMemo(function () {
        if (!job)
            return false;
        return !job.bouncedAt && !!job.sentAt;
    }, [job]);
    var handleErrorMessageOnSendAttempt = useCallback(function (errorMessage) {
        if (!job)
            return;
        if (errorMessage === 'DEMO_ENDED') {
            dispatch(appActions.showModal(getModal('DEMO_ENDED', {
                primaryAction: function () {
                    dispatch(appActions.hideModal());
                    history.push('/upgradeAccount');
                },
                secondaryAction: function () {
                    IntercomAPI('show');
                    dispatch(appActions.hideModal());
                },
                onClose: function () { return dispatch(appActions.hideModal()); },
            })));
        }
        else if (errorMessage === 'You have exceeded your subscription plan') {
            dispatch(appActions.showModal(getModal('EXCEEDED_SUBSCRIPTION', {
                primaryAction: function () {
                    dispatch(appActions.hideModal());
                    history.push('/upgradeAccount');
                },
                secondaryAction: function () {
                    IntercomAPI('show');
                    dispatch(appActions.hideModal());
                },
                onClose: function () { return dispatch(appActions.hideModal()); },
            })));
        }
        else if (errorMessage === 'EMAIL_INACTIVE') {
            var primaryContact = getJobContactFromCustomer(customer, (job === null || job === void 0 ? void 0 : job.contactId) || null);
            dispatch(appActions.showModal(getModal('INACTIVE_EMAIL', {
                primaryAction: function () {
                    dispatch(appActions.hideModal());
                },
                text: [primaryContact === null || primaryContact === void 0 ? void 0 : primaryContact.emailAddress],
            })));
        }
    }, [dispatch, history, customer]);
    var navigateToDeal = useCallback(function () {
        history.replace({
            pathname: "/deals/" + (job === null || job === void 0 ? void 0 : job.dealId),
        });
    }, [job]);
    var markAsSentCallback = useCallback(function () {
        if (isJobMarkedAsSentSuccessfully) {
            navigateToDeal();
        }
        else if (!isJobMarkedAsSentSuccessfully && markJobAsSentErrorMessage) {
            handleErrorMessageOnSendAttempt(markJobAsSentErrorMessage);
        }
    }, [
        markJobAsSentErrorMessage,
        isJobMarkedAsSentSuccessfully,
        handleErrorMessageOnSendAttempt,
        navigateToDeal,
    ]);
    return (React.createElement(AuthenticatedPageWrapper, { className: "edit-job-page", pageBanners: (job === null || job === void 0 ? void 0 : job.archivedAt) ? [
            React.createElement("div", { key: "archived-banner", className: "archived-banner" }, "This job has been deleted"),
        ]
            : undefined, title: React.createElement(Button, { iconBefore: faChevronLeft, styleVariant: ButtonStyleVariant.ANCHOR, onClick: navigateToDeal }, "Back to deal") },
        job && isJobSentSuccessfully && (React.createElement(PreviewJobControl, { jobId: jobId, onJobUpdateSuccess: function () {
                dispatch(jobsActions.requestGetJob(jobId));
            }, markAsSentCallback: markAsSentCallback })),
        job && !isJobSentSuccessfully && (React.createElement(React.Fragment, null,
            (view === ViewType.EDIT ||
                (view === ViewType.SEND && !job.bouncedAt)) && (React.createElement(React.Fragment, null,
                React.createElement(EditJobControl, { jobId: jobId, proceedToPreview: function () {
                        setView(ViewType.SEND);
                    } }),
                React.createElement(Modal, { className: "preview-and-send-job-modal", isOpen: view === ViewType.SEND, size: "lg", onClose: function () { return setView(ViewType.EDIT); } },
                    React.createElement(PreviewAndSendJobControl, { jobId: jobId, sendJobToCustomer: sendJobToCustomer })))),
            job.bouncedAt && (React.createElement(PreviewAndSendJobControl, { jobId: jobId, sendJobToCustomer: sendJobToCustomer }))))));
};
export default EditJobPage;
