import React from 'react';
import './AnalyticsDashboardContent.css';
import { SentQuotesChart, SentQuoteValueChart, SentInvoicesChart, SentInvoiceValueChart, KeyIndicatorsData, } from '@/ui/components';
import { ContentPanel } from '@payaca/components/contentPanel';
import Tooltip from '@bit/payaca-tech.payaca-core.component.tooltip';
var AnalyticsDashboardContent = function () {
    return (React.createElement("div", { className: "analytics-dashboard-content" },
        React.createElement("div", { className: "analytics-dashboard-content-inner" },
            React.createElement(ContentPanel, { className: "full-width" },
                React.createElement(KeyIndicatorsData, null)),
            React.createElement(ContentPanel, null,
                React.createElement("div", { className: "flex-container flex-center" },
                    React.createElement("h3", { className: "flex-grow" }, "Sent quotes"),
                    React.createElement(Tooltip, { text: "For the purposes of this visualisation, any quotes sent with bespoke finance enabled will be counted as 'accepted' once the customer finance agreement has been signed." })),
                React.createElement(SentQuotesChart, null)),
            React.createElement(ContentPanel, null,
                React.createElement("div", { className: "flex-container flex-center" },
                    React.createElement("h3", { className: "flex-grow" }, "Quote value"),
                    React.createElement("span", { className: "microcopy" }, "VAT excluded"),
                    React.createElement(Tooltip, { text: "For the purposes of this visualisation, any quotes sent with bespoke finance enabled will be counted as 'accepted' once the customer finance agreement has been signed." })),
                React.createElement(SentQuoteValueChart, null)),
            React.createElement(ContentPanel, null,
                React.createElement("div", { className: "flex-container flex-center" },
                    React.createElement("h3", { className: "flex-grow" }, "Sent invoices"),
                    React.createElement(Tooltip, { text: "For the purposes of this visualisation, any bespoke finance 'payout approved' invoices will count as having been paid." })),
                React.createElement(SentInvoicesChart, null)),
            React.createElement(ContentPanel, null,
                React.createElement("div", { className: "flex-container flex-center" },
                    React.createElement("h3", { className: "flex-grow" }, "Invoice value"),
                    React.createElement("span", { className: "microcopy" }, "VAT excluded"),
                    React.createElement(Tooltip, { text: "For the purposes of this visualisation, any bespoke finance 'payout approved' invoices will count as having been paid." })),
                React.createElement(SentInvoiceValueChart, null)))));
};
export default AnalyticsDashboardContent;
