import React, { useEffect, useState, useMemo, useCallback, } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { actions as usersActions } from '@/api/users';
import { getUrlSearchParam } from '@bit/payaca-tech.payaca-core.helpers.url';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import XeroConnectionControl from '@/ui/components/xeroConnectionControl/XeroConnectionControl';
import QuickbooksConnectionControl from '@/ui/components/quickbooksConnectionControl/QuickbooksConnectionControl';
import StripeConnectionControl from '@/ui/components/stripeConnectionControl/StripeConnectionControl';
import ZapierConnectionControl from '@/ui/components/zapierConnectionControl/ZapierConnectionControl';
import './ConnectionsPage.sass';
var ConnectionsPage = function () {
    var dispatch = useDispatch();
    var location = useLocation();
    var params = useParams();
    var history = useHistory();
    var _a = useState(false), isCompletingXeroConnection = _a[0], setIsCompletingXeroConnection = _a[1];
    var _b = useState(false), isCompletingQuickbooksConnection = _b[0], setIsCompletingQuickbooksConnection = _b[1];
    var _c = useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var code = useMemo(function () {
        return getUrlSearchParam(location.search, 'code');
    }, [location]);
    var realmId = useMemo(function () {
        return getUrlSearchParam(location.search, 'realmId');
    }, [location]);
    var service = useMemo(function () {
        return params.service;
    }, [params]);
    var connectWithXero = useCallback(function () {
        setIsCompletingXeroConnection(true);
        dispatch(usersActions.connectWithXero(code, function (error) {
            if (!error) {
                dispatch(usersActions.getProfile(function () {
                    dispatch(usersActions.getXeroAccounts(function () {
                        history.push('/connections');
                        setIsCompletingXeroConnection(false);
                    }));
                }));
            }
            else {
                setErrorMessage('Unable to connect with Xero');
                setIsCompletingXeroConnection(false);
            }
        }));
    }, [dispatch, history, code]);
    var connectWithQuickbooks = useCallback(function () {
        setIsCompletingQuickbooksConnection(true);
        dispatch(usersActions.connectWithQuickbooks({
            code: code,
            realmId: realmId,
        }, function (error) {
            if (!error) {
                dispatch(usersActions.getProfile(function () {
                    history.push('/connections');
                    setIsCompletingQuickbooksConnection(false);
                }));
            }
            else {
                setErrorMessage('Unable to connect with Quickbooks');
                setIsCompletingQuickbooksConnection(false);
            }
        }));
    }, [dispatch, history, code]);
    useEffect(function () {
        if (code) {
            if (service === 'xero') {
                connectWithXero();
            }
            else if (service === 'quickbooks') {
                connectWithQuickbooks();
            }
        }
    }, []);
    return (React.createElement(AuthenticatedPageWrapper, { className: "connections-page", title: "Connections" },
        React.createElement("div", { className: "connections-wrapper" },
            React.createElement(XeroConnectionControl, null),
            React.createElement(QuickbooksConnectionControl, null),
            React.createElement(ZapierConnectionControl, null),
            React.createElement(StripeConnectionControl, null))));
};
export default ConnectionsPage;
