import { parseCustomerAddress } from './customerHelper';
const parseAccountUsers = (users) => {
  return users.map((user) => {
    return user;
  });
};

const getAccountUsers = (users) => {
  // remove any deactivated users from list without an inviteLink i.e. have previously been on account but have been removed
  return users.filter((user) => {
    return !(user.deactivatedAt && !user.inviteToken);
  });
};

const UserStatus = {
  INVITED: 'Invited',
  ACTIVE: 'Active',
};

const userStatus = (inviteToken) =>
  inviteToken ? UserStatus.INVITED : UserStatus.ACTIVE;

const userRole = (isAdmin) => (isAdmin ? 'Admin' : 'Non-admin');

const formatContactNumberToIntl = (phoneNumber) => {
  // if start of phone number is "0" and second number isn't "0", assume GB number and format
  let formattedNumber = phoneNumber;
  if (phoneNumber) {
    if (phoneNumber[0] === '0' && phoneNumber[1] !== '0') {
      // e.g. 07
      formattedNumber = `+44${phoneNumber.slice(1)}`;
    } else if (phoneNumber[0] === '0' && phoneNumber[1] === '0') {
      // e.g. 0044
      formattedNumber = `+${phoneNumber.slice(2)}`;
    }
  }
  return formattedNumber;
};

const parseContactNumber = (phoneNumber) =>
  formatContactNumberToIntl(phoneNumber);

const parseAccount = (account) => {
  const address = account.address;
  const { firstLineAddress, secondLineAddress } = parseCustomerAddress(address);
  return {
    businessName: account.companyName,
    businessNotes: account.businessNotes,
    email: account.email,
    contactNumber: parseContactNumber(account.contactNumber),
    firstLineAddress: firstLineAddress,
    secondLineAddress: secondLineAddress,
    city: account.city,
    postcode: account.postcode,
    logoUrl: {
      uri: account.logoUrl,
    },
    legalBusinessName: account.legalBusinessName,
    vatNumber: account.vatNumber,
    paymentTerms: account.paymentTerms,
    accountName: account.accountNameRaw,
    accountNumber: account.accountNumberRaw,
    sortCode: account.sortCodeRaw,
    sendInvoiceReminderAfterDue7Days: account.sendInvoiceReminderAfterDue7Days,
    sendInvoiceReminderAfterDue3Days: account.sendInvoiceReminderAfterDue3Days,
    sendInvoiceReminderWhenDue: account.sendInvoiceReminderWhenDue,
    sendInvoiceReminderBeforeDue3Days:
      account.sendInvoiceReminderBeforeDue3Days,
    brandColour: account.brandColour,
    isCisSubcontractor: account.isCisSubcontractor,
    cisDeductionRate: account.cisDeductionRate,
    hideItemPrices: account.hideItemPrices,
  };
};
export {
  getAccountUsers,
  parseAccount,
  parseAccountUsers,
  parseContactNumber,
  userRole,
  userStatus,
};
