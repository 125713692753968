export var validateForm = function (formState, fieldValidators) {
    var validationState = {};
    if (!fieldValidators)
        return validationState;
    var _loop_1 = function (fieldName) {
        var validators = fieldValidators[fieldName];
        if (!validators)
            return "continue";
        var validationResults = validators.map(function (validator) {
            return validator(fieldName, formState);
        });
        var combinedValidationResult = validationResults.reduce(function (combinedValidationResult, validationResult) {
            var _a;
            if (!combinedValidationResult.isValid) {
                return combinedValidationResult;
            }
            if (!validationResult.isValid) {
                combinedValidationResult.isValid = false;
            }
            if (((_a = validationResult.errors) === null || _a === void 0 ? void 0 : _a.length) &&
                combinedValidationResult.errors) {
                combinedValidationResult.errors = combinedValidationResult.errors.concat(validationResult.errors);
            }
            return combinedValidationResult;
        }, { isValid: true, errors: [] });
        validationState[fieldName] = combinedValidationResult;
    };
    for (var fieldName in fieldValidators) {
        _loop_1(fieldName);
    }
    return validationState;
};
