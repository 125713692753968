import React from 'react';
import FontAwesome from 'react-fontawesome';
import { withStyles } from '@material-ui/core/styles';
import { TextareaAutosize } from '@material-ui/core';
import FieldLabel from './FieldLabel';

import { getPlaceholder } from '@/helpers/formHelper';

import './BasicField.css';

const styles = () => ({
  basicField: {
    marginBottom: 10,
  },
  inputWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 8,
    border: '1px solid #c4c4c4',
    borderRadius: 5,
    '&:focus-within:not(:read-only)': {
      borderColor: '#FAB62D',
    },
  },
  iconWrapper: {
    position: 'absolute',
    height: '100%',
    right: 0,
    width: 50,
    background: '#fff2cc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    cursor: 'pointer',
  },
  icon: {
    height: 25,
    width: 25,
  },
  startAdornment: {
    marginRight: 5,
  },
});

const BasicField = (props) => {
  const {
    classes,
    icon,
    label,
    loading,
    name,
    onChange,
    onIconClick,
    parseChange,
    readonly,
    startAdornment,
    textArea,
    textAreaMinRows,
    tooltip,
    type,
    value,
  } = props;

  const onInputChange = (e) => {
    onChange &&
      onChange({
        [e.target.name]: parseChange
          ? parseChange(e.target.value)
          : e.target.value,
      });
  };

  const onClick = () => {
    if (!loading) return onIconClick();
  };

  return (
    <div className={`${classes.basicField} field`}>
      <FieldLabel label={label} tooltip={tooltip} />

      <div className={classes.inputWrapper}>
        {startAdornment && (
          <div className={classes.startAdornment}>{startAdornment}</div>
        )}
        {readonly && (
          <input
            type={type}
            value={value}
            name={name}
            onChange={(e) => onInputChange(e)}
            readOnly
          />
        )}
        {!readonly &&
          (textArea ? (
            <TextareaAutosize
              value={value}
              name={name}
              onChange={(e) => onInputChange(e)}
              placeholder={getPlaceholder(name) || ''}
              rowsMin={textAreaMinRows || 2}
            />
          ) : (
            <input
              type={type}
              value={value}
              name={name}
              onChange={(e) => onInputChange(e)}
            />
          ))}
        {icon && (
          <div className={classes.iconWrapper} onClick={onClick}>
            {!loading ? (
              <img alt="Input icon" className={classes.icon} src={icon} />
            ) : (
              <FontAwesome name="spinner fa-spin" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(BasicField);
