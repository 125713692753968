export var SubscriptionActionTypes;
(function (SubscriptionActionTypes) {
    SubscriptionActionTypes["REQUEST_GET_PRODUCTS"] = "subscription.requestGetProducts";
    SubscriptionActionTypes["GET_PRODUCTS_SUCCESS"] = "subscription.getProductsSuccess";
    SubscriptionActionTypes["GET_PRODUCTS_FAILURE"] = "subscription.getProductsFailure";
    SubscriptionActionTypes["CLEAR_PRODUCTS"] = "subscription.clearProducts";
    SubscriptionActionTypes["REQUEST_CREATE_SUBSCRIPTION"] = "subscription.requestCreateSubscription";
    SubscriptionActionTypes["CREATE_SUBSCRIPTION_SUCCESS"] = "subscription.createSubscriptionSuccess";
    SubscriptionActionTypes["CREATE_SUBSCRIPTION_FAILURE"] = "subscription.createSubscriptionFailure";
    SubscriptionActionTypes["REQUEST_GET_ACCOUNT_SUBSCRIPTION"] = "subscription.requestGetAccountSubscription";
    SubscriptionActionTypes["GET_ACCOUNT_SUBSCRIPTION_SUCCESS"] = "subscription.getAccountSubscriptionSuccess";
    SubscriptionActionTypes["GET_ACCOUNT_SUBSCRIPTION_FAILURE"] = "subscription.getAccountSubscriptionFailure";
    SubscriptionActionTypes["CLEAR_ACCOUNT_SUBSCRIPTION"] = "subscription.clearAccountSubscription";
    SubscriptionActionTypes["REQUEST_UPDATE_SUBSCRIPTION"] = "subscription.requestUpdateSubscription";
    SubscriptionActionTypes["UPDATE_SUBSCRIPTION_SUCCESS"] = "subscription.updateSubscriptionSuccess";
    SubscriptionActionTypes["UPDATE_SUBSCRIPTION_FAILURE"] = "subscription.updateSubscriptionFailure";
    SubscriptionActionTypes["REQUEST_RESTORE_SUBSCRIPTION"] = "subscription.requestRestoreSubscription";
    SubscriptionActionTypes["RESTORE_SUBSCRIPTION_SUCCESS"] = "subscription.restoreSubscriptionSuccess";
    SubscriptionActionTypes["RESTORE_SUBSCRIPTION_FAILURE"] = "subscription.restoreSubscriptionFailure";
    SubscriptionActionTypes["REQUEST_UPDATE_PAYMENT_METHOD"] = "subscription.requestUpdatePaymentMethod";
    SubscriptionActionTypes["UPDATE_PAYMENT_METHOD_SUCCESS"] = "subscription.updatePaymentMethodSuccess";
    SubscriptionActionTypes["UPDATE_PAYMENT_METHOD_FAILURE"] = "subscription.updatePaymentMethodFailure";
    SubscriptionActionTypes["REQUEST_GET_SUBSCRIPTION_PAYMENT_PREVIEW"] = "subscription.requestGetSubscriptionPaymentPreview";
    SubscriptionActionTypes["GET_SUBSCRIPTION_PAYMENT_PREVIEW_SUCCESS"] = "subscription.getSubscriptionPaymentPreviewSuccess";
    SubscriptionActionTypes["GET_SUBSCRIPTION_PAYMENT_PREVIEW_FAILURE"] = "subscription.getSubscriptionPaymentPreviewFailure";
    SubscriptionActionTypes["CLEAR_SUBSCRIPTION_PAYMENT_PREVIEW"] = "subscription.clearSubscriptionPaymentPreview";
})(SubscriptionActionTypes || (SubscriptionActionTypes = {}));
