import { action } from 'typesafe-actions';
import { ActionType, } from './jobsTypes';
// RESEND JOB
export var requestResendJob = function (jobId, params) {
    return action(ActionType.REQUEST_RESEND_JOB, {
        jobId: jobId,
        params: params,
    });
};
export var resendJobSuccess = function () {
    return action(ActionType.RESEND_JOB_SUCCESS);
};
export var resendJobFailure = function (error, errorMessage) {
    return action(ActionType.RESEND_JOB_FAILURE, { errorMessage: errorMessage }, null, error);
};
export var clearResendJob = function () {
    return action(ActionType.CLEAR_RESEND_JOB);
};
// EDIT JOB
export var requestEditJob = function (jobId) {
    return action(ActionType.REQUEST_EDIT_JOB, { jobId: jobId });
};
export var editJobSuccess = function (newJob) {
    return action(ActionType.EDIT_JOB_SUCCESS, { newJob: newJob });
};
export var editJobFailure = function (error, errorMessage) {
    return action(ActionType.EDIT_JOB_FAILURE, { errorMessage: errorMessage }, null, error);
};
export var clearEditJob = function () {
    return action(ActionType.CLEAR_EDIT_JOB);
};
// MARK AS SENT
export var requestMarkJobAsSent = function (jobId) {
    return action(ActionType.REQUEST_MARK_JOB_AS_SENT, {
        jobId: jobId,
    });
};
export var markJobAsSentSuccess = function () {
    return action(ActionType.MARK_JOB_AS_SENT_SUCCESS);
};
export var markJobAsSentFailure = function (error, errorMessage) {
    return action(ActionType.MARK_JOB_AS_SENT_FAILURE, { errorMessage: errorMessage }, null, error);
};
export var clearMarkJobAsSent = function () {
    return action(ActionType.CLEAR_MARK_JOB_AS_SENT);
};
// GET JOBS
export var requestGetJob = function (jobId) {
    return action(ActionType.REQUEST_GET_JOB, {
        jobId: jobId,
    });
};
export var requestGetJobWithJobGroupsAndJobLineItems = function (jobId) {
    return action(ActionType.REQUEST_GET_JOB_WITH_JOB_GROUPS_AND_JOB_LINE_ITEMS, {
        jobId: jobId,
    });
};
export var getJobSuccess = function (jobId, job) {
    return action(ActionType.GET_JOB_SUCCESS, {
        jobId: jobId,
        job: job,
    });
};
export var getJobFailure = function (jobId, error) {
    return action(ActionType.GET_JOB_FAILURE, {
        jobId: jobId,
    }, null, error);
};
export var clearJob = function (jobId) {
    return action(ActionType.CLEAR_JOB, {
        jobId: jobId,
    });
};
export var clearJobs = function () {
    return action(ActionType.CLEAR_JOBS);
};
export var requestUpdateJob = function (updateJobRequestData, callback) {
    return action(ActionType.REQUEST_UPDATE_JOB, {
        updateJobRequestData: updateJobRequestData,
        callback: callback,
    });
};
export var updateJobSuccess = function (jobId) {
    return action(ActionType.UPDATE_JOB_SUCCESS, {
        jobId: jobId,
    });
};
export var updateJobFailure = function (jobId, error) {
    return action(ActionType.UPDATE_JOB_FAILURE, { jobId: jobId }, null, error);
};
export var requestGetJobsForDeal = function (dealId) {
    return action(ActionType.REQUEST_GET_JOBS_FOR_DEAL, {
        dealId: dealId,
    });
};
export var requestGetJobAttachmentsForJob = function (jobId) {
    return action(ActionType.REQUEST_GET_JOB_ATTACHMENTS_FOR_JOB, {
        jobId: jobId,
    });
};
export var getJobAttachmentSuccess = function (jobAttachmentId, jobAttachment) {
    return action(ActionType.GET_JOB_ATTACHMENT_SUCCESS, {
        jobAttachmentId: jobAttachmentId,
        jobAttachment: jobAttachment,
    });
};
export var getJobAttachmentFailure = function (jobAttachmentId, error) {
    return action(ActionType.GET_JOB_ATTACHMENT_FAILURE, {
        jobAttachmentId: jobAttachmentId,
    }, null, error);
};
export var clearJobAttachment = function (jobAttachmentId) {
    return action(ActionType.CLEAR_JOB_ATTACHMENT, {
        jobAttachmentId: jobAttachmentId,
    });
};
export var clearJobAttachments = function () {
    return action(ActionType.CLEAR_JOB_ATTACHMENTS);
};
export var requestUpdateJobAttachment = function (updateJobAttachmentRequestData, callback) {
    return action(ActionType.REQUEST_UPDATE_JOB_ATTACHMENT, {
        updateJobAttachmentRequestData: updateJobAttachmentRequestData,
        callback: callback,
    });
};
export var updateJobAttachmentSuccess = function (jobAttachmentId) {
    return action(ActionType.UPDATE_JOB_ATTACHMENT_SUCCESS, {
        jobAttachmentId: jobAttachmentId,
    });
};
export var updateJobAttachmentFailure = function (jobAttachmentId, error) {
    return action(ActionType.UPDATE_JOB_ATTACHMENT_FAILURE, { jobAttachmentId: jobAttachmentId }, null, error);
};
export var requestGetJobLineItemAttachmentsForJobLineItem = function (jobLineItemId) {
    return action(ActionType.REQUEST_GET_JOB_LINE_ITEM_ATTACHMENTS_FOR_JOB_LINE_ITEM, {
        jobLineItemId: jobLineItemId,
    });
};
export var getJobLineItemAttachmentSuccess = function (jobLineItemAttachmentId, jobLineItemAttachment) {
    return action(ActionType.GET_JOB_LINE_ITEM_ATTACHMENT_SUCCESS, {
        jobLineItemAttachmentId: jobLineItemAttachmentId,
        jobLineItemAttachment: jobLineItemAttachment,
    });
};
export var getJobLineItemAttachmentFailure = function (jobLineItemAttachmentId, error) {
    return action(ActionType.GET_JOB_LINE_ITEM_ATTACHMENT_FAILURE, {
        jobLineItemAttachmentId: jobLineItemAttachmentId,
    }, null, error);
};
export var clearJobLineItemAttachment = function (jobLineItemAttachmentId) {
    return action(ActionType.CLEAR_JOB_LINE_ITEM_ATTACHMENT, {
        jobLineItemAttachmentId: jobLineItemAttachmentId,
    });
};
export var clearJobLineItemAttachments = function () {
    return action(ActionType.CLEAR_JOB_LINE_ITEM_ATTACHMENTS);
};
export var requestAddAttachmentToJobLineItem = function (jobLineItemId, attachment, updateLineItemAttachment, callback) {
    if (updateLineItemAttachment === void 0) { updateLineItemAttachment = false; }
    return action(ActionType.REQUEST_ADD_ATTACHMENT_TO_JOB_LINE_ITEM, {
        jobLineItemId: jobLineItemId,
        attachment: attachment,
        updateLineItemAttachment: updateLineItemAttachment,
        callback: callback,
    });
};
export var addAttachmentToJobLineItemSuccess = function () {
    return action(ActionType.ADD_ATTACHMENT_TO_JOB_LINE_ITEM_SUCCESS);
};
export var addAttachmentToJobLineItemFailure = function (error) {
    return action(ActionType.ADD_ATTACHMENT_TO_JOB_LINE_ITEM_FAILURE, null, null, error);
};
export var requestRemoveAttachmentFromJobLineItem = function (jobLineItemId, attachmentId, updateLineItemAttachment, callback) {
    if (updateLineItemAttachment === void 0) { updateLineItemAttachment = false; }
    return action(ActionType.REQUEST_REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM, {
        jobLineItemId: jobLineItemId,
        attachmentId: attachmentId,
        updateLineItemAttachment: updateLineItemAttachment,
        callback: callback,
    });
};
export var removeAttachmentFromJobLineItemSuccess = function () {
    return action(ActionType.REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM_SUCCESS);
};
export var removeAttachmentFromJobLineItemFailure = function (error) {
    return action(ActionType.REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM_FAILURE, null, null, error);
};
export var requestGetJobLineItemGroupsForJob = function (jobId) {
    return action(ActionType.REQUEST_GET_JOB_LINE_ITEM_GROUPS_FOR_JOB, {
        jobId: jobId,
    });
};
export var getJobLineItemGroupSuccess = function (jobLineItemGroupId, jobLineItemGroup) {
    return action(ActionType.GET_JOB_LINE_ITEM_GROUP_SUCCESS, {
        jobLineItemGroupId: jobLineItemGroupId,
        jobLineItemGroup: jobLineItemGroup,
    });
};
export var getJobLineItemGroupFailure = function (jobLineItemGroupId, error) {
    return action(ActionType.GET_JOB_LINE_ITEM_GROUP_FAILURE, {
        jobLineItemGroupId: jobLineItemGroupId,
    }, null, error);
};
export var clearJobLineItemGroup = function (jobLineItemGroupId) {
    return action(ActionType.CLEAR_JOB_LINE_ITEM_GROUP, {
        jobLineItemGroupId: jobLineItemGroupId,
    });
};
export var clearJobLineItemGroups = function () {
    return action(ActionType.CLEAR_JOB_LINE_ITEM_GROUPS);
};
export var requestGetJobLineItemsForJob = function (jobId) {
    return action(ActionType.REQUEST_GET_JOB_LINE_ITEMS_FOR_JOB, {
        jobId: jobId,
    });
};
export var getJobLineItemSuccess = function (jobLineItemId, jobLineItem) {
    return action(ActionType.GET_JOB_LINE_ITEM_SUCCESS, {
        jobLineItemId: jobLineItemId,
        jobLineItem: jobLineItem,
    });
};
export var getJobLineItemFailure = function (jobLineItemId, error) {
    return action(ActionType.GET_JOB_LINE_ITEM_FAILURE, {
        jobLineItemId: jobLineItemId,
    }, null, error);
};
export var clearJobLineItem = function (jobLineItemId) {
    return action(ActionType.CLEAR_JOB_LINE_ITEM, {
        jobLineItemId: jobLineItemId,
    });
};
export var clearJobLineItems = function () {
    return action(ActionType.CLEAR_JOB_LINE_ITEMS);
};
export var requestGetJobValidationResult = function (jobId, callback) {
    return action(ActionType.REQUEST_GET_JOB_VALIDATION_RESULT, {
        jobId: jobId,
        callback: callback,
    });
};
export var getJobValidationResultSuccess = function (validationResult) {
    return action(ActionType.GET_JOB_VALIDATION_RESULT_SUCCESS, {
        validationResult: validationResult,
    });
};
export var getJobValidationResultFailure = function (error) {
    return action(ActionType.GET_JOB_VALIDATION_RESULT_FAILURE, null, null, error);
};
export var requestCreateJobLineItemGroup = function (createJobLineItemGroupRequestData, callback) {
    return action(ActionType.REQUEST_CREATE_JOB_LINE_ITEM_GROUP, {
        createJobLineItemGroupRequestData: createJobLineItemGroupRequestData,
        callback: callback,
    });
};
export var createJobLineItemGroupSuccess = function () {
    return action(ActionType.CREATE_JOB_LINE_ITEM_GROUP_SUCCESS);
};
export var createJobLineItemGroupFailure = function (error) {
    return action(ActionType.CREATE_JOB_LINE_ITEM_GROUP_FAILURE, null, null, error);
};
export var requestUpdateJobLineItemGroup = function (updateJobLineItemGroupRequestData, callback) {
    return action(ActionType.REQUEST_UPDATE_JOB_LINE_ITEM_GROUP, {
        updateJobLineItemGroupRequestData: updateJobLineItemGroupRequestData,
        callback: callback,
    });
};
export var updateJobLineItemGroupSuccess = function (jobLineItemGroupId) {
    return action(ActionType.UPDATE_JOB_LINE_ITEM_GROUP_SUCCESS, {
        jobLineItemGroupId: jobLineItemGroupId,
    });
};
export var updateJobLineItemGroupFailure = function (jobLineItemGroupId, error) {
    return action(ActionType.UPDATE_JOB_LINE_ITEM_GROUP_FAILURE, { jobLineItemGroupId: jobLineItemGroupId }, null, error);
};
export var requestDeleteJobLineItemGroup = function (jobLineItemGroupId, callback) {
    return action(ActionType.REQUEST_DELETE_JOB_LINE_ITEM_GROUP, {
        jobLineItemGroupId: jobLineItemGroupId,
        callback: callback,
    });
};
export var deleteJobLineItemGroupSuccess = function () {
    return action(ActionType.DELETE_JOB_LINE_ITEM_GROUP_SUCCESS);
};
export var deleteJobLineItemGroupFailure = function (error) {
    return action(ActionType.DELETE_JOB_LINE_ITEM_GROUP_FAILURE, null, null, error);
};
export var requestAddLineItemGroupToJob = function (jobId, lineItemGroupId, callback) {
    return action(ActionType.REQUEST_ADD_LINE_ITEM_GROUP_TO_JOB, {
        jobId: jobId,
        lineItemGroupId: lineItemGroupId,
        callback: callback,
    });
};
export var addLineItemGroupToJobSuccess = function () {
    return action(ActionType.ADD_LINE_ITEM_GROUP_TO_JOB_SUCCESS);
};
export var addLineItemGroupToJobFailure = function (error) {
    return action(ActionType.ADD_LINE_ITEM_GROUP_TO_JOB_FAILURE, null, null, error);
};
export var requestCreateJobLineItem = function (createJobLineItemRequestData, callback) {
    return action(ActionType.REQUEST_CREATE_JOB_LINE_ITEM, {
        createJobLineItemRequestData: createJobLineItemRequestData,
        callback: callback,
    });
};
export var createJobLineItemSuccess = function () {
    return action(ActionType.CREATE_JOB_LINE_ITEM_SUCCESS);
};
export var createJobLineItemFailure = function (error) {
    return action(ActionType.CREATE_JOB_LINE_ITEM_FAILURE, null, null, error);
};
export var requestUpdateJobLineItem = function (updateJobLineItemRequestData, callback) {
    return action(ActionType.REQUEST_UPDATE_JOB_LINE_ITEM, {
        updateJobLineItemRequestData: updateJobLineItemRequestData,
        callback: callback,
    });
};
export var requestUpdateJobLineItemFromLineItem = function (jobLineItemId, lineItemId, callback) {
    return action(ActionType.REQUEST_UPDATE_JOB_LINE_ITEM_FROM_LINE_ITEM, {
        jobLineItemId: jobLineItemId,
        lineItemId: lineItemId,
        callback: callback,
    });
};
export var updateJobLineItemSuccess = function (jobLineItemId) {
    return action(ActionType.UPDATE_JOB_LINE_ITEM_SUCCESS, { jobLineItemId: jobLineItemId });
};
export var updateJobLineItemFailure = function (jobLineItemId, error) {
    return action(ActionType.UPDATE_JOB_LINE_ITEM_FAILURE, { jobLineItemId: jobLineItemId }, null, error);
};
export var requestDeleteJobLineItem = function (jobLineItemId, callback) {
    return action(ActionType.REQUEST_DELETE_JOB_LINE_ITEM, {
        jobLineItemId: jobLineItemId,
        callback: callback,
    });
};
export var deleteJobLineItemSuccess = function () {
    return action(ActionType.DELETE_JOB_LINE_ITEM_SUCCESS);
};
export var deleteJobLineItemFailure = function (error) {
    return action(ActionType.DELETE_JOB_LINE_ITEM_FAILURE, null, null, error);
};
export var clearJobLineItemGroupUpdateResults = function () {
    return action(ActionType.CLEAR_JOB_LINE_ITEM_GROUP_UPDATE_RESULTS);
};
export var clearJobLineItemUpdateResults = function () {
    return action(ActionType.CLEAR_JOB_LINE_ITEM_UPDATE_RESULTS);
};
export var clearJobUpdateResults = function () {
    return action(ActionType.CLEAR_JOB_UPDATE_RESULTS);
};
