var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { faCheck, faFrown, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useState, } from 'react';
import { getIsRequiredFieldValidator, getNumericalRangeFieldValidator, } from '@payaca/helpers/fieldValidationHelper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import CalloutPanel from '../calloutPanel/CalloutPanel';
import CurrencyField from '../currencyField/CurrencyField';
import ValidatedForm from '../validatedForm/ValidatedForm';
import './SelinaLoanCalculator.css';
import { format } from 'd3-format';
import ResponsiveViewWrapper from '../responsiveViewWrapper/ResponsiveViewWrapper';
var LOANABLE_PROPERTY_VALUE_PERCENTAGE = 75;
var abbreviatedNumberFormatter = format('.2s');
var SelinaLoanCalculator = function (_a) {
    var renderActions = _a.renderActions;
    var _b = useState(), maximumCreditLimit = _b[0], setMaximumCreditLimit = _b[1];
    var _c = useState(), ltv = _c[0], setLtv = _c[1];
    var initialFormState = useMemo(function () {
        return {};
    }, []);
    var fieldValidators = useMemo(function () {
        var isRequiredFieldValidator = getIsRequiredFieldValidator();
        var validators = {
            requestedCreditLimit: [
                isRequiredFieldValidator,
                getNumericalRangeFieldValidator(2499999, 100000001, undefined, 'The minimum value for requested credit is £25k', 'The maximum value for requested credit is £1M'),
            ],
            mortgageOutstanding: [isRequiredFieldValidator],
            propertyValue: [isRequiredFieldValidator],
        };
        if (maximumCreditLimit) {
            validators.requestedCreditLimit.push(getNumericalRangeFieldValidator(undefined, maximumCreditLimit + 1, undefined, undefined, "Requested credit can't exceed the maximum limit of " + currencyPrice(maximumCreditLimit)));
        }
        return validators;
    }, [maximumCreditLimit]);
    var interestRate = useMemo(function () {
        if (ltv === undefined) {
            return undefined;
        }
        else if (ltv <= 65) {
            return 3.95;
        }
        else if (ltv <= 70) {
            return 4.2;
        }
        else {
            return 4.95;
        }
    }, [ltv]);
    var handleSetMaximumCreditLimit = useCallback(function (formState) {
        if (!formState.propertyValue ||
            formState.mortgageOutstanding === null ||
            formState.mortgageOutstanding === undefined) {
            setMaximumCreditLimit(undefined);
        }
        else {
            setMaximumCreditLimit((formState.propertyValue * LOANABLE_PROPERTY_VALUE_PERCENTAGE) / 100 -
                formState.mortgageOutstanding);
        }
    }, []);
    var handleSetLtv = useCallback(function (formState) {
        if (!formState.requestedCreditLimit) {
            setLtv(undefined);
        }
        else {
            setLtv(((formState.requestedCreditLimit + formState.mortgageOutstanding) /
                formState.propertyValue) *
                100);
        }
    }, []);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        handleSetMaximumCreditLimit(formState);
        handleSetLtv(formState);
        var isMaximumCreditLimitValid = maximumCreditLimit && maximumCreditLimit >= 2500000;
        return (React.createElement("div", { className: "form-contents" },
            React.createElement("div", { className: "flex-container" },
                React.createElement("div", { className: "flex-grow" },
                    React.createElement(CurrencyField, { name: "propertyValue", value: formState.propertyValue, label: "Home value", allowPennies: false, onChange: function (value) {
                            onFieldChange(__assign(__assign({}, value), { requestedCreditLimit: 2500000 }));
                        } })),
                React.createElement("div", { className: "flex-grow" },
                    React.createElement(CurrencyField, { name: "mortgageOutstanding", value: formState.mortgageOutstanding, label: "Mortgage outstanding", allowPennies: false, onChange: function (value) {
                            onFieldChange(__assign(__assign({}, value), { requestedCreditLimit: 2500000 }));
                        } }))),
            maximumCreditLimit && !isMaximumCreditLimitValid && (React.createElement(CalloutPanel, { icon: faFrown }, "Sorry, you are not eligible for a loan with Selina Finance at this time as your outstanding mortgage is too high relative to the value of your property.")),
            maximumCreditLimit && isMaximumCreditLimitValid && (React.createElement(React.Fragment, null,
                React.createElement(CalloutPanel, { colour: "#8bc34a" },
                    React.createElement("div", { className: "maximum-credit-limit-information-container" },
                        React.createElement("div", { className: "icons-container" },
                            React.createElement(FontAwesomeIcon, { icon: faHome }),
                            React.createElement(FontAwesomeIcon, { icon: faCheck })),
                        React.createElement("p", null, "You may be eligible to borrow a maximum of"),
                        React.createElement("p", { className: "maximum-credit-limit" }, currencyPrice(maximumCreditLimit)),
                        React.createElement("p", null, "against your property"),
                        ' ',
                        React.createElement("small", null, "Interest rates between 3.95% and 4.95%"))))),
            renderActions && (React.createElement("div", { className: "actions-container" }, renderActions(isValid)))));
    }, [
        maximumCreditLimit,
        // interestRate,
        handleSetLtv,
        handleSetMaximumCreditLimit,
        renderActions,
    ]);
    return (React.createElement(ResponsiveViewWrapper, { downBreakpointSm: 450, className: "selina-loan-calculator" },
        React.createElement("h2", null, "Check how much you could borrow"),
        React.createElement(ValidatedForm, { renderFormContents: renderFormContents, initialFormState: initialFormState, fieldValidators: fieldValidators })));
};
export default SelinaLoanCalculator;
