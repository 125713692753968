import React from 'react';
import './BodyWithSidePanelContentWrapper.sass';
var BodyWithSidePanelContentWrapper = function (_a) {
    var sidebarContent = _a.sidebarContent, title = _a.title, children = _a.children;
    return (React.createElement("div", { className: "body-with-sidebar-content-wrapper flex-container" },
        React.createElement("div", { className: "main" },
            title && (React.createElement("div", { className: "title-bar" }, typeof title === 'string' ? React.createElement("h1", null, title) : title)),
            React.createElement("div", { className: "body-content" }, children)),
        React.createElement("div", { className: "sidebar" }, sidebarContent)));
};
export default BodyWithSidePanelContentWrapper;
