export var getDepositPaymentMethodFromJobPayments = function (jobPayments) {
    var depositPayment = jobPayments.find(function (x) { return x.isDepositPayment && !x.paymentFailedConfirmationAt; });
    return depositPayment === null || depositPayment === void 0 ? void 0 : depositPayment.paymentMethod;
};
export var getInvoicePaymentMethodFromJobPayments = function (jobPayments) {
    var invoicePayment = jobPayments.find(function (x) { return !x.isDepositPayment && !x.paymentFailedConfirmationAt; });
    return invoicePayment === null || invoicePayment === void 0 ? void 0 : invoicePayment.paymentMethod;
};
export var getTotalCompletedPaymentValueFromJobPayments = function (jobPayments) {
    var completedPayments = jobPayments.filter(function (x) { return !!x.paymentCompletedConfirmationAt; });
    return completedPayments.reduce(function (accumulator, x) { return accumulator + x.paymentValue; }, 0);
};
export var getCompletedPaymentValueExcludingDepositFromJobPayments = function (jobPayments) {
    var completedPaymentsExcludingDeposit = jobPayments.filter(function (x) { return !!x.paymentCompletedConfirmationAt && !x.isDepositPayment; });
    return completedPaymentsExcludingDeposit.reduce(function (accumulator, x) { return accumulator + x.paymentValue; }, 0);
};
export var getCompletedDepositPaymentValueFromJobPayments = function (jobPayments) {
    var completedDepositPayments = jobPayments.filter(function (x) { return !!x.paymentCompletedConfirmationAt && x.isDepositPayment; });
    return completedDepositPayments.reduce(function (accumulator, x) { return accumulator + x.paymentValue; }, 0);
};
export var getAttemptedDepositPaymentValueFromJobPayments = function (jobPayments) {
    var depositPayments = jobPayments.filter(function (x) { return x.isDepositPayment && !x.paymentFailedConfirmationAt; });
    return depositPayments.reduce(function (accumulator, x) { return accumulator + x.paymentValue; }, 0);
};
export var getTotalAttemptedPaymentValueFromJobPayments = function (jobPayments) {
    var attemptedPayments = jobPayments.filter(function (x) { return !x.paymentFailedConfirmationAt; });
    return attemptedPayments.reduce(function (accumulator, x) { return accumulator + x.paymentValue; }, 0);
};
export var getBacsPendingPayments = function (jobPayments) {
    return jobPayments.filter(function (x) {
        return !!x.bacsPaymentMadeAt &&
            !x.paymentCompletedConfirmationAt &&
            !x.paymentFailedConfirmationAt;
    });
};
export var getBacsPendingDepositPaymentsFromJobPayments = function (jobPayments) {
    return jobPayments.filter(function (x) {
        return !!x.bacsPaymentMadeAt &&
            !x.paymentCompletedConfirmationAt &&
            !x.paymentFailedConfirmationAt &&
            !!x.isDepositPayment;
    });
};
export var getBacsPendingPaymentsExcludingDepositFromJobPayments = function (jobPayments) {
    return jobPayments.filter(function (x) {
        return !!x.bacsPaymentMadeAt &&
            !x.paymentCompletedConfirmationAt &&
            !x.paymentFailedConfirmationAt &&
            !x.isDepositPayment;
    });
};
