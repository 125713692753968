import React, { useMemo, } from 'react';
import './CustomerContactInputControl.css';
import { BasicField } from '@payaca/components/basicField';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { CheckboxField } from '@payaca/components/checkboxField';
import LoqateAdvisoryEmailInputField from '../loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';
import LoqateAdvisoryPhoneInputField from '../loqateAdvisoryPhoneInputField/LoqateAdvisoryPhoneInputField';
import { TextareaField } from '@payaca/components/textareaField';
var CustomerContactInputControl = function (_a) {
    var inputStyleVariant = _a.inputStyleVariant, customerContact = _a.customerContact, fieldNamePrefix = _a.fieldNamePrefix, touchedState = _a.touchedState, validationState = _a.validationState, onTouch = _a.onTouch, onChange = _a.onChange, onMakePrimaryContact = _a.onMakePrimaryContact;
    var nameFieldName = useMemo(function () {
        return fieldNamePrefix ? fieldNamePrefix + ".name" : 'name';
    }, [fieldNamePrefix]);
    var descriptionFieldName = useMemo(function () {
        return fieldNamePrefix ? fieldNamePrefix + ".description" : 'description';
    }, [fieldNamePrefix]);
    var emailAddressFieldName = useMemo(function () {
        return fieldNamePrefix ? fieldNamePrefix + ".emailAddress" : 'emailAddress';
    }, [fieldNamePrefix]);
    var telephoneNumberFieldName = useMemo(function () {
        return fieldNamePrefix
            ? fieldNamePrefix + ".telephoneNumber"
            : 'telephoneNumber';
    }, [fieldNamePrefix]);
    return (React.createElement("div", { className: "customer-contact-input-control" },
        React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[nameFieldName]) || false, validationResult: validationState && validationState[nameFieldName] },
            React.createElement(BasicField, { styleVariant: inputStyleVariant, label: "Name", isRequired: true, value: customerContact.name || '', name: nameFieldName, onChange: onChange, onTouch: onTouch })),
        React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[descriptionFieldName]) || false, validationResult: validationState && validationState[descriptionFieldName] },
            React.createElement(TextareaField, { styleVariant: inputStyleVariant, autoSize: true, rows: 1, label: "Description", value: customerContact.description || '', name: descriptionFieldName, description: "For internal use only - this description won't be printed on any of your quotes or invoices", onChange: onChange, onTouch: onTouch })),
        React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[emailAddressFieldName]) || false, validationResult: validationState && validationState[emailAddressFieldName] },
            React.createElement(LoqateAdvisoryEmailInputField, { styleVariant: inputStyleVariant, label: "Email address", value: customerContact.emailAddress || '', name: emailAddressFieldName, onChange: onChange, onTouch: onTouch, disableValidation: validationState && validationState[emailAddressFieldName]
                    ? !validationState[emailAddressFieldName].isValid
                    : false })),
        React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[telephoneNumberFieldName]) || false, validationResult: validationState && validationState[telephoneNumberFieldName] },
            React.createElement(LoqateAdvisoryPhoneInputField, { styleVariant: inputStyleVariant, label: "Telephone number", value: customerContact.telephoneNumber || '', name: telephoneNumberFieldName, onChange: onChange, onTouch: onTouch, disableValidation: validationState && validationState[telephoneNumberFieldName]
                    ? !validationState[telephoneNumberFieldName].isValid
                    : false })),
        React.createElement(CheckboxField, { name: 'isPrimaryContact', label: "This is the primary contact", value: customerContact.isPrimaryContact, onChange: function (value) { return value.isPrimaryContact && onMakePrimaryContact(); }, onTouch: onTouch })));
};
export default CustomerContactInputControl;
