import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BasicField } from '@payaca/components';
import FormattedNumberField, { NumberFormatVariant, } from '@payaca/components/formattedNumberField/FormattedNumberField';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import { actions as accountSettingsActions } from '@/api/accountSettings';
var CompanySettingsGettingPaid = function (_a) {
    var readOnly = _a.readOnly;
    var dispatch = useDispatch();
    var pendingAccount = useSelector(function (state) { return state.accountSettings.pendingAccount; });
    var onFieldChange = useCallback(function (change) {
        dispatch(accountSettingsActions.storeAccountPendingChanges(change));
    }, [dispatch]);
    return (React.createElement("div", { className: "company-settings-getting-paid" },
        React.createElement("div", null,
            React.createElement("p", null, "If you want to accept payment via bank transfer then add your bank details here.")),
        React.createElement("div", { className: "settings-sub-section" },
            React.createElement(BasicField, { name: "vatNumber", label: "VAT number", value: pendingAccount.vatNumber, isRequired: true, styleVariant: InputStyleVariant.OUTSIZE, onChange: onFieldChange, textBefore: "GB-", isDisabled: readOnly }),
            React.createElement(BasicField, { name: "paymentTerms", label: "Payment terms", value: pendingAccount.paymentTerms, styleVariant: InputStyleVariant.OUTSIZE, onChange: onFieldChange, isDisabled: readOnly }),
            React.createElement(BasicField, { name: "accountName", label: "Account name", value: pendingAccount.accountName, styleVariant: InputStyleVariant.OUTSIZE, onChange: onFieldChange, isDisabled: readOnly }),
            React.createElement(FormattedNumberField, { name: "sortCode", label: "Sort code", value: pendingAccount.sortCode, styleVariant: InputStyleVariant.OUTSIZE, onChange: onFieldChange, formatVariant: NumberFormatVariant.SORT_CODE, isDisabled: readOnly }),
            React.createElement(FormattedNumberField, { name: "accountNumber", label: "Account number", value: pendingAccount.accountNumber, styleVariant: InputStyleVariant.OUTSIZE, onChange: onFieldChange, formatVariant: NumberFormatVariant.ACCOUNT_NUMBER, isDisabled: readOnly }))));
};
export default CompanySettingsGettingPaid;
