var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import values from "lodash.values";
import { faFile, faFileDownload, faFileImage, faFilePdf, faFileWord, } from "@fortawesome/free-solid-svg-icons";
var FileUploadType = {
    JOB_ATTACHMENT: "jobAttachment",
    BUSINESS_TERMS: "businessTerms",
    BUSINESS_ATTACHMENTS: "businessAttachments",
    ACCOUNT_LOGO: "accountLogo",
    LINE_ITEM_ATTACHMENT: "lineItemAttachements",
};
var bytesInKb = 1024;
var FileSizeLimit = (_a = {},
    _a[FileUploadType.JOB_ATTACHMENT] = 20 * bytesInKb * bytesInKb,
    _a[FileUploadType.BUSINESS_TERMS] = 20 * bytesInKb * bytesInKb,
    _a[FileUploadType.BUSINESS_ATTACHMENTS] = 20 * bytesInKb * bytesInKb,
    _a[FileUploadType.ACCOUNT_LOGO] = 20 * bytesInKb * bytesInKb,
    _a[FileUploadType.LINE_ITEM_ATTACHMENT] = 20 * bytesInKb * bytesInKb,
    _a);
// Postmark email has total email size limit of 10mb, temporarily reduced file size to 7mb to allow for file size increase on base64 encoding which is used to attach to postmark email.
var getMbFileLimit = function (fileLimit) {
    return fileLimit / bytesInKb / bytesInKb + "mb";
};
var ImageFileType = {
    JPEG: {
        name: ["image/jpeg", ".jpeg"],
        icon: faFileImage,
    },
    PNG: {
        name: ["image/png", ".png"],
        icon: faFileImage,
    },
};
var FileType = __assign(__assign({}, ImageFileType), { PDF: {
        name: ["application/pdf", ".pdf"],
        icon: faFilePdf,
    }, DOC: {
        name: ["application/msword", ".doc"],
        icon: faFileWord,
    } });
var getFileType = function (url) {
    var filename = url && url.split("/").pop();
    // Infer the type of the image
    if (filename) {
        var match = /\.(\w+)$/.exec(filename);
        return match && match[0];
    }
    return null;
};
var getSpecificFileTypeIcon = function (file) {
    var type = null;
    if (file.file) {
        // locally uploaded file (i.e not uploaded to BE yet)
        type = file.file.type;
    }
    else {
        // uploaded file with a url
        var url = file.attachmentUrl || file.termUrl;
        if (file.type) {
            type = file.type;
        }
        else if (url) {
            type = getFileType(url);
        }
    }
    return values(FileType).find(function (v) {
        return v.name.find(function (n) { return n === type; });
    });
};
// Get file type icon, defaults to generic file icon if type not found
var getFileTypeIcon = function (file) {
    var found = getSpecificFileTypeIcon(file);
    return found ? found.icon : faFile;
};
// Get file type icon, defaults to download file icon if type not found
var getFileTypeIconDownload = function (file) {
    var found = getSpecificFileTypeIcon(file);
    return found ? found.icon : faFileDownload;
};
export { FileSizeLimit, FileType, FileUploadType, getFileType, getFileTypeIcon, getFileTypeIconDownload, getMbFileLimit, ImageFileType, };
