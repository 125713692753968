import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import qs from 'qs';

import { actions as appActions } from '@/api/app';
import { actions as userActions } from '@/api/users';

import './EnableCustomerPaymentsPage.css';
import BasicPageWrapper from '../pageWrappers/basicPageWrapper/BasicPageWrapper';

class EnableCustomerPaymentsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    if (query.code) {
      console.log(query.code);

      this.props.users.connectWithStripe(query.code, (err) => {
        console.log(err);
      });
    }
  }

  render() {
    return (
      <BasicPageWrapper>
        <div className="successful-setup" style={{ padding: 15 }}>
          <div style={{ marginBottom: 15 }}>
            You‘ve successfully connected your Stripe account to Payaca and can
            now accept card payments.
          </div>
          <div>
            Please close this tab and return to the existing Payaca tab.
          </div>
        </div>
      </BasicPageWrapper>
    );
  }
}

export default connect(
  (state) => ({
    myProfile: state.users.myProfile,
  }),
  (dispatch) => ({
    app: bindActionCreators(appActions, dispatch),
    users: bindActionCreators(userActions, dispatch),
  })
)(EnableCustomerPaymentsPage);
