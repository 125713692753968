import React, { useCallback, useMemo, useState } from 'react';
import './SetupUserForm.sass';
import { ValidatedForm } from '@payaca/components';
import { getIsRequiredFieldValidator, getPhoneNumberFieldValidator, } from '@payaca/helpers/fieldValidationHelper';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { useDispatch } from 'react-redux';
import { actions as userActions } from '@/api/users';
import { useHistory } from 'react-router';
import { ErrorMessage } from '@payaca/components/feedbackMessage';
import LoqateAdvisoryPhoneInputField from '../loqateAdvisoryPhoneInputField/LoqateAdvisoryPhoneInputField';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
var SetupUserForm = function () {
    var dispatch = useDispatch();
    var history = useHistory();
    var _a = useState(), setupUserErrorMessage = _a[0], setSetupUserErrorMessage = _a[1];
    var _b = useState(false), isProcessing = _b[0], setIsProcessing = _b[1];
    var fieldValidators = useMemo(function () {
        var isRequiredFieldValidator = getIsRequiredFieldValidator();
        return {
            contactNumber: [isRequiredFieldValidator, getPhoneNumberFieldValidator()],
        };
    }, []);
    var onSubmit = useCallback(function (formState) {
        setIsProcessing(true);
        setSetupUserErrorMessage(undefined);
        dispatch(userActions.updateProfile(formState, function (error) {
            setIsProcessing(false);
            if (!error) {
                history.push('/');
            }
            else {
                setSetupUserErrorMessage('Sorry, there was an error updating your details. Please try again.');
            }
        }));
    }, [dispatch]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ValidatedFieldWrapper, { validationResult: validationState['contactNumber'], isTouched: touchedState['contactNumber'] || false },
                React.createElement(LoqateAdvisoryPhoneInputField, { styleVariant: InputStyleVariant.OUTSIZE, name: "contactNumber", value: formState.contactNumber, label: 'Phone number', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, disableValidation: validationState['contactNumber']
                        ? !validationState['contactNumber'].isValid
                        : false })),
            React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement(Button, { isProcessing: isProcessing, onClick: function () { return !isProcessing && onSubmit(formState); }, styleVariant: ButtonStyleVariant.OUTSIZE, isDisabled: !isValid }, "Continue"),
                    setupUserErrorMessage && (React.createElement(ErrorMessage, { message: setupUserErrorMessage }))))));
    }, [isProcessing, setupUserErrorMessage, onSubmit]);
    return (React.createElement("div", { className: "setup-user-form-container" },
        React.createElement(ValidatedForm, { renderFormContents: renderFormContents, fieldValidators: fieldValidators })));
};
export default SetupUserForm;
