var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState, useCallback, useMemo, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as subsidisedFinanceInformationActions } from '@payaca/store/subsidisedFinanceInformation';
import { getFieldValidatorsForAdditionalInformation } from '@payaca/helpers/subsidisedFinanceInformationValidationHelper';
import { RadioGroupField } from '@payaca/components/radioGroupField';
import { MiniLoader, ValidatedForm } from '@payaca/components';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { TextareaField } from '@payaca/components/textareaField';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import './AdditionalInformationFormSection.css';
var YES_NO_OPTIONS = [
    { value: false, label: 'No' },
    { value: true, label: 'Yes' },
];
var AdditionalInformationFormSection = function () {
    var dispatch = useDispatch();
    var additionalInformation = useSelector(function (state) {
        var _a;
        return (_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.additionalInformation;
    });
    var isGettingOrPersisting = useSelector(function (state) {
        var _a, _b;
        return (((_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.isGettingAdditionalInformation) || ((_b = state.subsidisedFinanceInformation) === null || _b === void 0 ? void 0 : _b.isPersistingAdditionalInformation));
    });
    var _a = useState(), triggerValidationAt = _a[0], setTriggerValidationAt = _a[1];
    var fieldValidators = useMemo(function () {
        return getFieldValidatorsForAdditionalInformation();
    }, []);
    useEffect(function () {
        dispatch(subsidisedFinanceInformationActions.requestGetAdditionalInformation());
    }, []);
    var onSaveProgress = useCallback(function (modifiedAdditionalInformation) {
        dispatch(subsidisedFinanceInformationActions.requestPersistAdditionalInformation(modifiedAdditionalInformation));
    }, [dispatch]);
    var onSaveAndContinue = useCallback(function (modifiedAdditionalInformation) {
        dispatch(subsidisedFinanceInformationActions.requestPersistCompleteAdditionalInformation(modifiedAdditionalInformation));
    }, [dispatch]);
    var renderActionButtons = useCallback(function (isValid, formState) {
        var modifiedAdditionalInformation = formState;
        return (React.createElement("div", { className: "action-buttons-container" }, isGettingOrPersisting ? (React.createElement("div", { className: "loader-container flex-grow" },
            React.createElement(MiniLoader, null))) : (React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: function () { return onSaveProgress(modifiedAdditionalInformation); }, styleVariant: ButtonStyleVariant.OUTSIZE, isOutlined: true }, 'Save progress'),
            React.createElement("div", { onClick: function () { return !isValid && setTriggerValidationAt(new Date()); } },
                React.createElement(Button, { isDisabled: !isValid, onClick: function () {
                        return isValid && onSaveAndContinue(modifiedAdditionalInformation);
                    }, iconAfter: faChevronRight, styleVariant: ButtonStyleVariant.OUTSIZE }, 'Save and continue'))))));
    }, [onSaveProgress, onSaveAndContinue, isGettingOrPersisting]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "field-group" },
                React.createElement(RadioGroupField, { value: formState.hasCriminalConvictions || false, options: YES_NO_OPTIONS, label: "Are there any criminal offences for which any directors have been convicted (except convictions spent under the Rehabilitation of Offenders Act)?", onChange: function (value) {
                        return onFieldChange(__assign(__assign({}, value), { criminalConvictionsDetails: null }));
                    }, onTouch: onFieldTouch, name: "hasCriminalConvictions" }),
                formState.hasCriminalConvictions && (React.createElement(ValidatedFieldWrapper, { validationResult: validationState.criminalConvictionsDetails, isTouched: touchedState.criminalConvictionsDetails || false },
                    React.createElement(TextareaField, { name: "criminalConvictionsDetails", value: formState.criminalConvictionsDetails || '', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, label: "Please provide more information", styleVariant: InputStyleVariant.OUTSIZE })))),
            React.createElement("div", { className: "field-group" },
                React.createElement(RadioGroupField, { value: formState.hasBankruptcyClaims || false, options: YES_NO_OPTIONS, label: "Are there any bankruptcy claims or voluntary arrangements any directors have made with creditors within the last 5 years?", onChange: function (value) {
                        return onFieldChange(__assign(__assign({}, value), { bankruptcyClaimsDetails: null }));
                    }, onTouch: onFieldTouch, name: "hasBankruptcyClaims" }),
                formState.hasBankruptcyClaims && (React.createElement(ValidatedFieldWrapper, { validationResult: validationState.bankruptcyClaimsDetails, isTouched: touchedState.bankruptcyClaimsDetails || false },
                    React.createElement(TextareaField, { name: "bankruptcyClaimsDetails", value: formState.bankruptcyClaimsDetails || '', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, label: "Please provide more information", styleVariant: InputStyleVariant.OUTSIZE })))),
            React.createElement("div", { className: "field-group" },
                React.createElement(RadioGroupField, { value: formState.hasLenderFacilityTermination || false, options: YES_NO_OPTIONS, label: "Have you ever had a lender facility terminated?", onChange: function (value) {
                        return onFieldChange(__assign(__assign({}, value), { lenderFacilityTerminationDetails: null }));
                    }, onTouch: onFieldTouch, name: "hasLenderFacilityTermination" }),
                formState.hasLenderFacilityTermination && (React.createElement(ValidatedFieldWrapper, { validationResult: validationState.lenderFacilityTerminationDetails, isTouched: touchedState.lenderFacilityTerminationDetails || false },
                    React.createElement(TextareaField, { name: "lenderFacilityTerminationDetails", value: formState.lenderFacilityTerminationDetails || '', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, label: "Please provide more information", styleVariant: InputStyleVariant.OUTSIZE })))),
            React.createElement("div", { className: "field-group" },
                React.createElement(RadioGroupField, { value: formState.hasOutstandingCcjs || false, options: YES_NO_OPTIONS, label: "Does the company or its directors have any outstanding CCJs or adverse financial information registered?", onChange: function (value) {
                        return onFieldChange(__assign(__assign({}, value), { outstandingCcjsDetails: null }));
                    }, onTouch: onFieldTouch, name: "hasOutstandingCcjs" }),
                formState.hasOutstandingCcjs && (React.createElement(ValidatedFieldWrapper, { validationResult: validationState.outstandingCcjsDetails, isTouched: touchedState.outstandingCcjsDetails || false },
                    React.createElement(TextareaField, { name: "outstandingCcjsDetails", value: formState.outstandingCcjsDetails || '', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, label: "Please provide more information", styleVariant: InputStyleVariant.OUTSIZE })))),
            React.createElement("div", { className: "field-group" },
                React.createElement(RadioGroupField, { value: formState.hasRecentDisputesLitigationClaims || false, options: YES_NO_OPTIONS, label: "Has the company had any litigation, disputes or claims within the last 12 months?", onChange: function (value) {
                        return onFieldChange(__assign(__assign({}, value), { recentDisputesLitigationClaimsDetails: null }));
                    }, onTouch: onFieldTouch, name: "hasRecentDisputesLitigationClaims" }),
                formState.hasRecentDisputesLitigationClaims && (React.createElement(ValidatedFieldWrapper, { validationResult: validationState.recentDisputesLitigationClaimsDetails, isTouched: touchedState.recentDisputesLitigationClaimsDetails || false },
                    React.createElement(TextareaField, { name: "recentDisputesLitigationClaimsDetails", value: formState.recentDisputesLitigationClaimsDetails || '', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, label: "Please provide more information", styleVariant: InputStyleVariant.OUTSIZE })))),
            renderActionButtons(isValid, formState)));
    }, [renderActionButtons]);
    return (React.createElement("div", { className: "additional-information-form-section", style: { position: 'relative' } },
        React.createElement(ValidatedForm, { triggerValidationAt: triggerValidationAt, initialFormState: additionalInformation, fieldValidators: fieldValidators, renderFormContents: renderFormContents })));
};
export default AdditionalInformationFormSection;
