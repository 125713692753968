import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@payaca/components';
import { CheckboxField } from '@payaca/components/checkboxField';
import CustomerEmailPreview from './CustomerEmailPreview';
import CustomerEmailControl from '../customerEmailControl/CustomerEmailControl';
import { getSpecificJobTypeText } from '@/helpers/jobHelper';
import { isEstimate, isQuote, isInvoice, } from '@bit/payaca-tech.payaca-core.helpers.job-status';
import { getJobContactFromCustomer } from '@bit/payaca-tech.payaca-core.helpers.customer';
import { actions as accountActions } from '@bit/payaca-tech.payaca-core.store.account';
import { actions as usersActions } from '@/api/users';
import { actions as jobActions } from '@/api/jobs';
import './SendJob.sass';
import { ContentPanel } from '@payaca/components/contentPanel';
import { Modal } from '@payaca/components/modal';
var SendJob = function (_a) {
    var job = _a.job, jobPayments = _a.jobPayments, customer = _a.customer, preButtonEmailText = _a.preButtonEmailText, postButtonEmailText = _a.postButtonEmailText, sendMeACopy = _a.sendMeACopy, onUpdate = _a.onUpdate, onSetIsSendJobDisabled = _a.onSetIsSendJobDisabled;
    var dispatch = useDispatch();
    var _b = useState(false), showSendJobFailureModal = _b[0], setShowSendJobFailureModal = _b[1];
    var _c = useState([]), blockedEmails = _c[0], setBlockedEmails = _c[1];
    useEffect(function () {
        dispatch(usersActions.getBlockedEmails(function (error, _blockedEmails) {
            setBlockedEmails(_blockedEmails);
        }));
    }, []);
    var currentAccount = useSelector(function (state) { var _a, _b; return (_b = (_a = state.users) === null || _a === void 0 ? void 0 : _a.myProfile) === null || _b === void 0 ? void 0 : _b.accounts[0]; });
    var sendJobError = useSelector(function (state) {
        return state.jobs.sendInvoiceToCustomerError;
    });
    useEffect(function () {
        dispatch(jobActions.storeSendInvoiceToCustomerError(null));
        dispatch(accountActions.requestGetEmailTemplates(currentAccount.id, true));
        return function () {
            dispatch(accountActions.clearEmailTemplates());
        };
    }, []);
    useEffect(function () {
        if (sendJobError) {
            setShowSendJobFailureModal(true);
        }
    }, [sendJobError]);
    var emailTemplates = useSelector(function (state) { return state.account.emailTemplates; });
    var companyName = useSelector(function (state) { return state.users.myProfile.accounts[0].companyName; });
    var emailTemplate = useMemo(function () {
        var jobStatus = job.status;
        if (emailTemplates) {
            if (isQuote(jobStatus)) {
                return emailTemplates.sendQuote;
            }
            else if (isEstimate(jobStatus)) {
                return emailTemplates.sendEstimate;
            }
            else if (isInvoice(jobStatus)) {
                return emailTemplates.sendInvoice;
            }
        }
        return '';
    }, [job, emailTemplates]);
    var jobFailureModalContent = useMemo(function () {
        if (sendJobError == 'XERO_EXPIRED') {
            return (React.createElement("div", { className: "send-invoice-error-modal" },
                React.createElement("div", null, "Your connection with Xero has expired, please head over to the Connections page to reconnect."),
                React.createElement("div", { className: "send-invoice-error-modal-button-wrapper" },
                    React.createElement("a", { href: "/connections" },
                        React.createElement(Button, { iconAfter: faChevronRight }, "Go to Connections")))));
        }
        else {
            return React.createElement("div", null, sendJobError);
        }
    }, [sendJobError]);
    var customerContactEmail = useMemo(function () {
        var _a;
        return (_a = getJobContactFromCustomer(customer, job.contactId)) === null || _a === void 0 ? void 0 : _a.emailAddress;
    }, [customer === null || customer === void 0 ? void 0 : customer.contacts]);
    var isCustomerEmailBlocked = useMemo(function () {
        return (!!customerContactEmail &&
            !!blockedEmails &&
            blockedEmails.includes(customerContactEmail));
    }, [blockedEmails === null || blockedEmails === void 0 ? void 0 : blockedEmails.length, customerContactEmail]);
    useEffect(function () {
        // ensure everything has loaded before enabling the "Send" button
        if (!!customerContactEmail &&
            !isCustomerEmailBlocked &&
            typeof postButtonEmailText === 'string' &&
            typeof preButtonEmailText === 'string') {
            return onSetIsSendJobDisabled(false);
        }
    }, [
        isCustomerEmailBlocked,
        postButtonEmailText,
        preButtonEmailText,
        customerContactEmail,
    ]);
    return (React.createElement("div", { className: "send-job" },
        React.createElement("div", { className: "addressee-and-subject-container" },
            React.createElement("div", { className: "addressee-container" }, customer && (React.createElement("div", null,
                React.createElement("div", null,
                    React.createElement("strong", null, "To:")),
                (job === null || job === void 0 ? void 0 : job.bouncedAt) ? (React.createElement(CustomerEmailControl, { blockedEmails: blockedEmails, customer: customer, onPersistCustomer: function (customerId) {
                        onSetIsSendJobDisabled(false);
                    }, jobContactId: job.contactId })) : (React.createElement("span", null, customerContactEmail))))),
            React.createElement("div", { className: "subject-container" },
                React.createElement("div", null,
                    React.createElement("strong", null, "Subject:")),
                React.createElement("div", null,
                    "Your ",
                    getSpecificJobTypeText(job.status),
                    " from ",
                    companyName))),
        React.createElement("div", { className: "email-options-container" },
            React.createElement(CheckboxField, { name: "sendMeACopy", label: "Send me a copy", onChange: onUpdate, value: sendMeACopy })),
        React.createElement(ContentPanel, { className: "email-content-container" },
            React.createElement(CustomerEmailPreview, { emailTemplate: emailTemplate, job: job, jobPayments: jobPayments, customer: customer, preButtonEmailText: preButtonEmailText, postButtonEmailText: postButtonEmailText, onChange: onUpdate }),
            React.createElement(Modal, { isOpen: showSendJobFailureModal, onClose: function () { return setShowSendJobFailureModal(false); }, title: "Invoice couldn't be sent" }, jobFailureModalContent))));
};
export default SendJob;
