import React, { useMemo } from 'react';
import { DropdownField } from '@payaca/components/dropdownField';
export var RequiredSettingsType;
(function (RequiredSettingsType) {
    RequiredSettingsType["REQUIRED"] = "required";
    RequiredSettingsType["MULTIPLE_CHOICE"] = "multiple-choice";
    RequiredSettingsType["OPTIONAL"] = "optional";
})(RequiredSettingsType || (RequiredSettingsType = {}));
export var REQUIRED_SETTINGS_OPTIONS = [
    {
        label: 'Required',
        value: RequiredSettingsType.REQUIRED,
    },
    {
        label: 'Multiple choice',
        value: RequiredSettingsType.MULTIPLE_CHOICE,
    },
    {
        label: 'Optional',
        value: RequiredSettingsType.OPTIONAL,
    },
];
export var getRequiredSettingsType = function (formState) {
    return formState.isOptional
        ? RequiredSettingsType.OPTIONAL
        : formState.isMultipleChoice
            ? RequiredSettingsType.MULTIPLE_CHOICE
            : RequiredSettingsType.REQUIRED;
};
var RequiredSettingsDropdown = function (_a) {
    var fieldNamePrefix = _a.fieldNamePrefix, value = _a.value, onChange = _a.onChange;
    var requiredSettingsType = useMemo(function () {
        return getRequiredSettingsType(value);
    }, [value]);
    return (React.createElement(DropdownField, { name: "requiredSettingsType", value: requiredSettingsType, options: REQUIRED_SETTINGS_OPTIONS, onChange: function (value) {
            var _a;
            var updateObject = (_a = {},
                _a[fieldNamePrefix
                    ? fieldNamePrefix + ".isMultipleChoice"
                    : 'isMultipleChoice'] = value.requiredSettingsType === RequiredSettingsType.MULTIPLE_CHOICE,
                _a[fieldNamePrefix ? fieldNamePrefix + ".isOptional" : 'isOptional'] = value.requiredSettingsType === RequiredSettingsType.OPTIONAL,
                _a[fieldNamePrefix ? fieldNamePrefix + ".isSelected" : 'isSelected'] = value.requiredSettingsType !== RequiredSettingsType.REQUIRED,
                _a);
            onChange(updateObject);
        } }));
};
export default RequiredSettingsDropdown;
