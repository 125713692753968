var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AuthActionTypes } from './authTypes';
export var initialState = {
    isLoggingIn: false,
    isLoggedInSuccessfully: false,
    isSigningUp: false,
    isSignedUpSuccessfully: false,
};
var authReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AuthActionTypes.REQUEST_LOGIN:
        case AuthActionTypes.REQUEST_LOGIN_WITH_APPLE:
        case AuthActionTypes.REQUEST_LOGIN_WITH_FACEBOOK:
        case AuthActionTypes.REQUEST_LOGIN_WITH_GOOGLE:
        case AuthActionTypes.REQUEST_LOGIN_WITH_XERO:
        case AuthActionTypes.REQUEST_LOGIN_WITH_TOKEN:
            return __assign(__assign({}, state), { isLoggingIn: true, isLoggedInSuccessfully: undefined });
        case AuthActionTypes.LOGIN_FAILURE:
            return __assign(__assign({}, state), { isLoggingIn: false, isLoggedInSuccessfully: false });
        case AuthActionTypes.LOGIN_SUCCESS:
            return __assign(__assign({}, state), { isLoggingIn: false, isLoggedInSuccessfully: true });
        case AuthActionTypes.REQUEST_SIGN_UP_WITH_APPLE:
        case AuthActionTypes.REQUEST_SIGN_UP_WITH_FACEBOOK:
        case AuthActionTypes.REQUEST_SIGN_UP_WITH_GOOGLE:
        case AuthActionTypes.REQUEST_SIGN_UP_WITH_XERO:
            return __assign(__assign({}, state), { isSigningUp: true, isSignedUpSuccessfully: undefined });
        case AuthActionTypes.SIGN_UP_FAILURE:
            return __assign(__assign({}, state), { isSigningUp: false, isSignedUpSuccessfully: false });
        case AuthActionTypes.SIGN_UP_SUCCESS:
            return __assign(__assign({}, state), { isSigningUp: false, isSignedUpSuccessfully: true });
        default:
            return state;
    }
};
export default authReducer;
