var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { CheckboxField } from '@payaca/components/checkboxField';
import { calculateItemProfit } from '@bit/payaca-tech.payaca-core.helpers.job-totals';
import { actions as jobsActions } from '@bit/payaca-tech.payaca-core.store.jobs';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MiniLoader from '@bit/payaca-tech.payaca-core.component.mini-loader';
import { ValidatedForm } from '@payaca/components';
import { BasicField } from '@payaca/components/basicField';
import Tooltip from '@bit/payaca-tech.payaca-core.component.tooltip';
import { TextareaField } from '@payaca/components/textareaField';
import { CurrencyField } from '@payaca/components/currencyField';
import _ from 'lodash';
import { RadioButton } from '@payaca/components/radioButton';
import VatSettingsField from '../vatSettingsField/VatSettingsField';
import { CollapsiblePanel, CollapsiblePanelStyleVariant, } from '@payaca/components/collapsiblePanel';
import './JobLineItemControl.sass';
import LabelValuePair from '@bit/payaca-tech.payaca-core.component.label-value-pair';
import { faChevronDown, faPaperclip, faTimes, } from '@fortawesome/free-solid-svg-icons';
import { Checkbox } from '@payaca/components/checkbox';
import ResponsiveViewWrapper from '@bit/payaca-tech.payaca-core.component.responsive-view-wrapper';
import { currencyPrice } from '@/helpers/financeHelper';
import { useEffect } from 'react';
import JobLineItemAttachmentsaModal from '../jobLineItemAttachmentsModal/JobLineItemAttachmentsModal';
import { getJobLineItem, getJobLineItemAttachmentByJobLineItemId, } from '../../../utils/stateAccessors';
import RequiredSettingsDropdown from '../requiredSettingsDropdown/RequiredSettingsDropdown';
var RequiredSettingsType;
(function (RequiredSettingsType) {
    RequiredSettingsType["REQUIRED"] = "required";
    RequiredSettingsType["MULTIPLE_CHOICE"] = "multiple-choice";
    RequiredSettingsType["OPTIONAL"] = "optional";
})(RequiredSettingsType || (RequiredSettingsType = {}));
var REQUIRED_SETTINGS_OPTIONS = [
    {
        label: 'Required',
        value: RequiredSettingsType.REQUIRED,
    },
    {
        label: 'Multiple choice',
        value: RequiredSettingsType.MULTIPLE_CHOICE,
    },
    {
        label: 'Optional',
        value: RequiredSettingsType.OPTIONAL,
    },
];
var getRequiredSettingsType = function (formState) {
    return formState.isOptional
        ? RequiredSettingsType.OPTIONAL
        : formState.isMultipleChoice
            ? RequiredSettingsType.MULTIPLE_CHOICE
            : RequiredSettingsType.REQUIRED;
};
var JobLineItemControl = function (_a) {
    var jobLineItemId = _a.jobLineItemId, jobIsInvoice = _a.jobIsInvoice;
    var dispatch = useDispatch();
    var _b = useState(false), isFooterExpanded = _b[0], setIsFooterExpanded = _b[1];
    var _c = useState(false), isSaveRequired = _c[0], setIsSaveRequired = _c[1];
    var _d = useState(false), isUpdatingLineItem = _d[0], setIsUpdatingLineItem = _d[1];
    var _e = useState(false), isDeletingJobLineItem = _e[0], setIsDeletingJobLineItem = _e[1];
    var _f = useState(false), showJobLineItemAttachmentsModal = _f[0], setShowJobLineItemAttachmentsModal = _f[1];
    var isCisSubcontractor = useSelector(function (state) { var _a; return (_a = state.users.myProfile.accounts[0]) === null || _a === void 0 ? void 0 : _a.isCisSubcontractor; });
    var cisDeductionRate = useSelector(function (state) { var _a; return (_a = state.users.myProfile.accounts[0]) === null || _a === void 0 ? void 0 : _a.cisDeductionRate; });
    var jobLineItem = useSelector(function (state) {
        return getJobLineItem(state, jobLineItemId);
    });
    var jobLineItemAttachment = useSelector(function (state) {
        return getJobLineItemAttachmentByJobLineItemId(state, jobLineItemId);
    });
    useEffect(function () {
        if (jobLineItemId) {
            dispatch(jobsActions.requestGetJobLineItemAttachmentsForJobLineItem(jobLineItemId));
        }
    }, [jobLineItemId]);
    var jobLineItemProfit = useMemo(function () {
        return jobLineItem &&
            typeof jobLineItem.supplierPrice === 'number' &&
            calculateItemProfit(jobLineItem.price, jobLineItem.supplierPrice, jobLineItem.quantity);
    }, [jobLineItem]);
    var initialFormState = useMemo(function () {
        if (!jobLineItem)
            return {};
        return {
            jobLineItemId: jobLineItem.id,
            description: jobLineItem.description,
            reference: jobLineItem.name,
            isMultipleChoice: jobLineItem.isMultipleChoice,
            isOptional: jobLineItem.isOptional,
            isSelected: jobLineItem.isSelected,
            quantity: jobLineItem.quantity,
            price: jobLineItem.price,
            vatAmount: jobLineItem.vatAmount,
            vatIncluded: jobLineItem.vatIncluded,
            isReverseChargeVat: jobLineItem.isReverseChargeVat,
            supplierName: jobLineItem.supplierName,
            supplierPrice: jobLineItem.supplierPrice,
            cisApplies: !!jobLineItem.cisDeductionRate,
            lineItemId: jobLineItem.lineItemId,
            updateLineItem: isUpdatingLineItem,
        };
    }, [jobLineItem === null || jobLineItem === void 0 ? void 0 : jobLineItem.id, jobLineItem === null || jobLineItem === void 0 ? void 0 : jobLineItem.isSelected, isUpdatingLineItem]);
    var onJobLineItemUpdateSuccess = useCallback(function () {
        if (!jobLineItem)
            return;
        dispatch(jobsActions.requestGetJobWithJobGroupsAndJobLineItems(jobLineItem.jobId));
    }, [dispatch, jobLineItem]);
    var isUpdatingJobLineItem = useSelector(function (state) {
        return state.jobsStore.isUpdatingJobLineItem;
    });
    var isFetchingJobLineItem = useSelector(function (state) {
        var _a;
        return (state.jobsStore.jobLineItems && ((_a = state.jobsStore.jobLineItems[jobLineItemId]) === null || _a === void 0 ? void 0 : _a.isFetching));
    });
    var requiresUpdateJobLineItem = useCallback(function (formState) {
        if (isFetchingJobLineItem || isUpdatingJobLineItem)
            return true;
        return !_.isEqual(formState, initialFormState);
    }, [isFetchingJobLineItem, isUpdatingJobLineItem, initialFormState]);
    var updateJobLineItem = useCallback(function (formState) {
        var updateJobLineItemRequestData = formState;
        if (!updateJobLineItemRequestData.jobLineItemId)
            return;
        if (requiresUpdateJobLineItem(formState)) {
            dispatch(jobsActions.requestUpdateJobLineItem(updateJobLineItemRequestData, onJobLineItemUpdateSuccess));
        }
    }, [dispatch, onJobLineItemUpdateSuccess, requiresUpdateJobLineItem]);
    var deleteJobLineItem = useCallback(function () {
        if (!jobLineItemId)
            return;
        setIsDeletingJobLineItem(true);
        dispatch(jobsActions.requestDeleteJobLineItem(jobLineItemId, function () {
            setIsDeletingJobLineItem(false);
            onJobLineItemUpdateSuccess();
        }));
    }, [dispatch, onJobLineItemUpdateSuccess, jobLineItemId, jobLineItem === null || jobLineItem === void 0 ? void 0 : jobLineItem.jobId]);
    var renderIsSelectedContainer = useCallback(function (formState, onFieldChange) {
        var requiredSettingsType = getRequiredSettingsType(formState);
        return (requiredSettingsType !== RequiredSettingsType.REQUIRED && (React.createElement("div", { className: "is-selected-container" },
            requiredSettingsType === RequiredSettingsType.OPTIONAL && (React.createElement(Checkbox, { isChecked: formState.isSelected, onChange: function () {
                    var updateItem = {
                        isSelected: !formState.isSelected,
                    };
                    onFieldChange(updateItem);
                    updateJobLineItem(__assign(__assign({}, formState), updateItem));
                } })),
            requiredSettingsType === RequiredSettingsType.MULTIPLE_CHOICE && (React.createElement(RadioButton, { isSelected: formState.isSelected, onClick: function () {
                    if (formState.isSelected)
                        return;
                    var updateItem = {
                        isSelected: !formState.isSelected,
                    };
                    onFieldChange(updateItem);
                    updateJobLineItem(__assign(__assign({}, formState), updateItem));
                } })))));
    }, [updateJobLineItem]);
    var renderFooterHeader = useCallback(function (formState, onFieldChange) {
        var requiredSettingsType = getRequiredSettingsType(formState);
        return (React.createElement("div", { className: "flex-container footer-header", onClick: function (event) {
                event.stopPropagation();
            } },
            !jobIsInvoice && (React.createElement(RequiredSettingsDropdown, { value: {
                    isMultipleChoice: formState.isMultipleChoice,
                    isOptional: formState.isOptional,
                    isSelected: formState.isSelected,
                }, onChange: function (value) {
                    onFieldChange(value);
                    updateJobLineItem(__assign(__assign({}, formState), value));
                } })),
            React.createElement("div", null,
                React.createElement("div", { className: "checkboxes-container" },
                    isCisSubcontractor && (React.createElement(CheckboxField, { name: "cisApplies", label: "CIS applies (" + cisDeductionRate + "%)", value: formState.cisApplies, onChange: function (value) {
                            onFieldChange(value);
                            updateJobLineItem(__assign(__assign({}, formState), value));
                        } })),
                    formState.lineItemId && (React.createElement(CheckboxField, { name: "updateLineItem", label: "Also update existing saved item", value: formState.updateLineItem, onChange: function (value) {
                            setIsUpdatingLineItem(value.updateLineItem);
                            onFieldChange(value);
                            updateJobLineItem(__assign(__assign({}, formState), value));
                        } }))))));
    }, [cisDeductionRate, isCisSubcontractor, updateJobLineItem, jobIsInvoice]);
    var renderFooterBody = useCallback(function (formState, onFieldChange) {
        var _a;
        return (React.createElement("div", { className: "footer-body" },
            React.createElement("div", { className: "flex-container supplier-profit-container" },
                React.createElement("div", { className: "supplier-name-container" },
                    React.createElement(BasicField, { value: formState.supplierName, name: "supplierName", label: "Supplier name", onChange: onFieldChange, onBlur: function () { return setIsSaveRequired(true); }, onChangeTimeout: function () { return setIsSaveRequired(true); } })),
                React.createElement("div", { className: "supplier-price-container" },
                    React.createElement(CurrencyField, { value: formState.supplierPrice, name: "supplierPrice", label: "Supplier price", onChange: onFieldChange, onBlur: function () { return setIsSaveRequired(true); }, onChangeTimeout: function () { return setIsSaveRequired(true); } })),
                jobLineItemProfit && (React.createElement("div", { className: "profit-container" },
                    React.createElement(LabelValuePair, { label: "Profit", value: currencyPrice(jobLineItemProfit.netProfit || 0) }),
                    jobLineItemProfit.netProfitMargin !== undefined &&
                        jobLineItemProfit.netProfitMargin !== null && (React.createElement(LabelValuePair, { label: "Profit margin", value: ((_a = jobLineItemProfit.netProfitMargin) === null || _a === void 0 ? void 0 : _a.toFixed(2)) + "%" })))))));
    }, [cisDeductionRate, jobLineItemProfit]);
    var actionsContainer = useMemo(function () {
        return (React.createElement("div", { className: "actions-container" },
            React.createElement("div", { className: "attachments-container flex-container flex-center", onClick: function () { return setShowJobLineItemAttachmentsModal(true); } },
                jobLineItemAttachment && (React.createElement("span", null, jobLineItemAttachment.fileName)),
                React.createElement(FontAwesomeIcon, { icon: faPaperclip })),
            React.createElement("div", { className: "delete-container" }, isDeletingJobLineItem ? (React.createElement(MiniLoader, null)) : (React.createElement(FontAwesomeIcon, { icon: faTimes, onClick: deleteJobLineItem })))));
    }, [isDeletingJobLineItem, deleteJobLineItem, jobLineItemAttachment]);
    var totalsContainer = useMemo(function () {
        if (!jobLineItem)
            return;
        var vatMultiplier = 1 +
            ((jobLineItem.vatIncluded &&
                !jobLineItem.isReverseChargeVat &&
                jobLineItem.vatAmount / 100) ||
                0);
        var total = jobLineItem.price * jobLineItem.quantity * vatMultiplier;
        var cisTotal = jobLineItem.cisDeductionRate
            ? (jobLineItem.price *
                jobLineItem.quantity *
                jobLineItem.cisDeductionRate) /
                100
            : null;
        return (React.createElement("div", { className: "totals-container" },
            React.createElement(LabelValuePair, { label: "Total (inc VAT)", value: currencyPrice(total) }),
            cisTotal && (React.createElement(LabelValuePair, { label: "CIS Total", value: currencyPrice(cisTotal) }))));
    }, [jobLineItem]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        if (isSaveRequired) {
            updateJobLineItem(formState);
            setIsSaveRequired(false);
        }
        return (React.createElement("div", { className: "form-contents-wrapper" },
            !jobIsInvoice && renderIsSelectedContainer(formState, onFieldChange),
            React.createElement("div", { className: "form-contents" },
                actionsContainer,
                React.createElement("div", { className: "job-line-item-form-body" },
                    React.createElement("div", { className: "flex-container flex-center" },
                        React.createElement("div", { className: "reference-container field-tooltip-wrapper" },
                            React.createElement(BasicField, { name: "reference", value: formState.reference, additionalInputProps: {
                                    placeholder: 'Reference',
                                }, onChange: onFieldChange, onBlur: function () { return setIsSaveRequired(true); }, onChangeTimeout: function () { return setIsSaveRequired(true); } }),
                            React.createElement(Tooltip, { text: "Your internal reference or code. This will not be seen by your customers." }))),
                    React.createElement("div", { className: "flex-container primary-fields-container" },
                        React.createElement("div", { className: "field-tooltip-wrapper description-container" },
                            React.createElement(TextareaField, { isRequired: true, name: "description", value: formState.description, rows: 4, additionalInputProps: {
                                    placeholder: 'Description (required)',
                                }, onChange: onFieldChange, onBlur: function () { return setIsSaveRequired(true); }, onChangeTimeout: function () { return setIsSaveRequired(true); } }),
                            React.createElement(Tooltip, { text: "Description for your customers. This will be shown on your estimates, quotes and invoices." })),
                        React.createElement("div", null,
                            React.createElement("div", { className: "flex-grow flex-container quantity-price-vat-container" },
                                React.createElement("div", { className: "quantity-container" },
                                    React.createElement(BasicField, { isRequired: true, value: formState.quantity, name: "quantity", label: "Quantity", type: "number", onChange: onFieldChange, onBlur: function () { return setIsSaveRequired(true); }, onChangeTimeout: function () { return setIsSaveRequired(true); } })),
                                React.createElement("div", { className: "price-container" },
                                    React.createElement(CurrencyField, { isRequired: true, value: formState.price, name: "price", label: "Price", onChange: onFieldChange, onBlur: function () { return setIsSaveRequired(true); }, onChangeTimeout: function () { return setIsSaveRequired(true); } })),
                                React.createElement("div", { className: "vat-settings-container" },
                                    React.createElement(VatSettingsField, { vatAmount: formState.vatAmount, isVatIncluded: formState.vatIncluded, isReverseChargeVat: formState.isReverseChargeVat, onChange: function (value) {
                                            onFieldChange(value);
                                            updateJobLineItem(__assign(__assign({}, formState), value));
                                        } }))),
                            totalsContainer))),
                React.createElement("div", { className: "job-line-item-form-footer" },
                    React.createElement(CollapsiblePanel, { styleVariant: CollapsiblePanelStyleVariant.UNSTYLED, closeControlIcon: faChevronDown, isOpen: isFooterExpanded, onClose: function () { return setIsFooterExpanded(false); }, onOpen: function () { return setIsFooterExpanded(true); }, title: renderFooterHeader(formState, onFieldChange) }, renderFooterBody(formState, onFieldChange))))));
    }, [
        jobIsInvoice,
        totalsContainer,
        isFooterExpanded,
        isCisSubcontractor,
        cisDeductionRate,
        isSaveRequired,
        updateJobLineItem,
        renderIsSelectedContainer,
        renderFooterHeader,
        renderFooterBody,
        actionsContainer,
    ]);
    return (React.createElement(ResponsiveViewWrapper, { className: "job-line-item-control", downBreakpointSm: 700, downBreakpointXs: 400 },
        React.createElement(ValidatedForm, { renderFormContents: renderFormContents, initialFormState: initialFormState }),
        React.createElement(JobLineItemAttachmentsaModal, { updateLineItemAttachment: isUpdatingLineItem, isOpen: showJobLineItemAttachmentsModal, onClose: function () { return setShowJobLineItemAttachmentsModal(false); }, jobLineItemId: jobLineItemId })));
};
export default JobLineItemControl;
