var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback } from 'react';
import './DealListedJobsTable.sass';
import { useSelector } from 'react-redux';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
import { currencyPrice } from '@/helpers/financeHelper';
import { isInvoice } from '@payaca/helpers/jobStatusHelper';
import { getOutstandingPaymentValue, getReadableJobStatus, getReadableJobStatusStyle, } from '@payaca/helpers/jobHelperV2';
import { getActiveJobFinanceInformationByDealId, getJobPaymentsByDealId, getJobsByDealId, } from '@/utils/stateAccessors';
import moment from 'moment';
import { QuickActionsTableCell } from '@payaca/components';
import { getAvailableActionsForJob } from '@payaca/helpers/jobActionsHelper';
import { Badge } from '@payaca/components/badge';
import { useHistory } from 'react-router';
import { DATE_SHORT_FORMAT } from '@payaca/constants';
var renderCustomerContactTableCell = function (job, customer) {
    var contact = getJobContactFromCustomer(customer, job.contactId);
    return (React.createElement("td", { className: "customer-contact-table-cell" },
        React.createElement("strong", null, contact === null || contact === void 0 ? void 0 : contact.name),
        (contact === null || contact === void 0 ? void 0 : contact.emailAddress) && React.createElement("small", null, contact.emailAddress),
        (contact === null || contact === void 0 ? void 0 : contact.telephoneNumber) && React.createElement("small", null, contact.telephoneNumber)));
};
var renderAmountDueTableCell = function (job, jobPayments) {
    if (!job || !jobPayments)
        return React.createElement("td", null);
    var outstandingPaymentValue = getOutstandingPaymentValue(job, jobPayments);
    return (React.createElement("td", { className: "amount-due-table-cell align-right" }, outstandingPaymentValue === null
        ? '-'
        : currencyPrice(outstandingPaymentValue)));
};
export var DealListedJobsTable = function (_a) {
    var dealId = _a.dealId, jobs = _a.jobs, quickActionDefinitions = _a.quickActionDefinitions;
    var history = useHistory();
    var customer = useSelector(function (state) { return state.customer.currentCustomer; });
    var jobPayments = useSelector(function (state) {
        return getJobPaymentsByDealId(state, dealId);
    });
    var dealJobs = useSelector(function (state) {
        return getJobsByDealId(state, dealId);
    });
    var jobFinanceInformation = useSelector(function (state) {
        return getActiveJobFinanceInformationByDealId(state, dealId);
    });
    var getQuickActionsForJob = useCallback(function (job) {
        var relatedJobs = dealJobs.filter(function (x) { return x.id !== job.id; });
        var availableActions = getAvailableActionsForJob(job, customer, jobPayments, relatedJobs, jobFinanceInformation);
        return availableActions
            .map(function (availableAction) { return quickActionDefinitions[availableAction]; })
            .filter(function (x) { return !!x; });
    }, [
        customer,
        jobPayments,
        dealJobs,
        jobFinanceInformation,
        quickActionDefinitions,
    ]);
    var navigateToJob = useCallback(function (job) {
        var jobIsInvoice = isInvoice(job.status);
        history.push("/" + (jobIsInvoice ? 'invoices' : 'quotes') + "/" + job.id);
    }, [history]);
    var renderTableRow = useCallback(function (job, index) {
        var quickActions = getQuickActionsForJob(job);
        var sentInvoice = dealJobs.find(function (x) { return x.id !== job.id && isInvoice(x.status) && x.sentAt; });
        var status = getReadableJobStatus(job, jobPayments, jobFinanceInformation, !!sentInvoice);
        var statusStyle = getReadableJobStatusStyle(status);
        return (React.createElement("tr", { key: "table-row-" + index, className: "clickable", onClick: function () { return navigateToJob(job); } },
            React.createElement("td", { className: "reference-table-cell" },
                React.createElement("strong", null, job.customReference || job.reference)),
            renderCustomerContactTableCell(job, customer),
            React.createElement("td", { className: "align-right value-table-cell" }, currencyPrice(job.total)),
            renderAmountDueTableCell(job, jobPayments),
            React.createElement("td", { className: "status-table-cell" },
                React.createElement(Badge, __assign({}, statusStyle), status)),
            React.createElement("td", { className: "tr-end updated-at-table-cell" }, moment(job.updatedAt).format(DATE_SHORT_FORMAT)),
            React.createElement(QuickActionsTableCell, { recordId: job.id, quickActions: quickActions })));
    }, [
        customer,
        jobPayments,
        getQuickActionsForJob,
        jobPayments,
        jobFinanceInformation,
        navigateToJob,
    ]);
    return (React.createElement("div", { className: "deal-listed-jobs-table styled-table-wrapper" },
        React.createElement("table", { className: "styled-table" },
            React.createElement("thead", null,
                React.createElement("th", null, "Reference"),
                React.createElement("th", null, "Contact"),
                React.createElement("th", { className: "align-right" }, "Value"),
                React.createElement("th", { className: "align-right" }, "Due"),
                React.createElement("th", null, "Status"),
                React.createElement("th", null, "Last updated"),
                React.createElement("th", null)),
            React.createElement("tbody", null, jobs.map(renderTableRow)))));
};
export default DealListedJobsTable;
