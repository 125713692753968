import React from 'react';
import { Modal } from '@payaca/components/modal';
import { PopulatedFeatureTable } from '@payaca/components/subscriptionPricingInformation/SubscriptionPricingInformation';
import './SubscriptionComparisonModal.sass';
var EmailTemplateModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose;
    return (React.createElement(Modal, { isOpen: isOpen, title: "Comparison chart", size: "md", onClose: onClose, className: "subscription-comparison-modal" },
        React.createElement(PopulatedFeatureTable, null)));
};
export default EmailTemplateModal;
