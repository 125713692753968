import { ValidatedForm } from '@payaca/components';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { BasicField } from '@payaca/components/basicField';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import React, { useCallback, useMemo, useState, } from 'react';
import LoqateAdvisoryEmailInputField from '../loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';
import ResponsiveViewWrapper from '@bit/payaca-tech.payaca-core.component.responsive-view-wrapper';
import './PersonalInformationForm.sass';
import LoqateAdvisoryPhoneInputField from '../loqateAdvisoryPhoneInputField/LoqateAdvisoryPhoneInputField';
import { useDispatch, useSelector } from 'react-redux';
import { actions as userActions } from '@/api/users';
import isEqual from 'lodash.isequal';
import { getEmailFieldValidator, getIsRequiredFieldValidator, getPhoneNumberFieldValidator, } from '@payaca/helpers/fieldValidationHelper';
import { ErrorMessage } from '@payaca/components/feedbackMessage';
var PersonalInformationForm = function (_a) {
    var onRequestChangePassword = _a.onRequestChangePassword;
    var dispatch = useDispatch();
    var _b = useState(false), isProcessing = _b[0], setIsProcessing = _b[1];
    var _c = useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = useState(), discardChangesTimestamp = _d[0], setDiscardChangesTimestamp = _d[1];
    var myProfile = useSelector(function (state) {
        return state.users.myProfile;
    });
    var isGettingProfile = useSelector(function (state) {
        return state.users.gettingProfile;
    });
    var initialFormState = useMemo(function () {
        return {
            firstName: myProfile === null || myProfile === void 0 ? void 0 : myProfile.firstname,
            lastName: myProfile === null || myProfile === void 0 ? void 0 : myProfile.lastname,
            emailAddress: myProfile === null || myProfile === void 0 ? void 0 : myProfile.email,
            phoneNumber: myProfile === null || myProfile === void 0 ? void 0 : myProfile.contactNumber,
            discardChangesTimestamp: discardChangesTimestamp,
        };
    }, [myProfile, discardChangesTimestamp]);
    var fieldValidators = useMemo(function () {
        var isRequiredFieldValidator = getIsRequiredFieldValidator();
        return {
            firstName: [isRequiredFieldValidator],
            lastName: [isRequiredFieldValidator],
            emailAddress: [isRequiredFieldValidator, getEmailFieldValidator()],
            phoneNumber: [isRequiredFieldValidator, getPhoneNumberFieldValidator()],
        };
    }, []);
    var onSubmit = useCallback(function (formState) {
        setIsProcessing(true);
        setErrorMessage(undefined);
        var payload = {
            firstName: formState.firstName,
            lastName: formState.lastName,
            email: formState.emailAddress,
            contactNumber: formState.phoneNumber,
        };
        dispatch(userActions.updateProfile(payload, function (error) {
            setIsProcessing(false);
            error &&
                setErrorMessage('Sorry, there was an error updating your details. Please try again.');
        }));
    }, [dispatch]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        var hasAnyChanges = !isEqual(formState, initialFormState);
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "name-image-container flex-container flex-center" },
                React.createElement("div", { className: "name-container" },
                    React.createElement("h4", null, "Personal information"),
                    React.createElement(ValidatedFieldWrapper, { validationResult: validationState['firstName'], isTouched: touchedState['firstName'] || false },
                        React.createElement(BasicField, { styleVariant: InputStyleVariant.OUTSIZE, label: "First name", name: "firstName", value: formState.firstName, onTouch: onFieldTouch, onChange: onFieldChange })),
                    React.createElement(ValidatedFieldWrapper, { validationResult: validationState['lastName'], isTouched: touchedState['lastName'] || false },
                        React.createElement(BasicField, { styleVariant: InputStyleVariant.OUTSIZE, label: "Last name", name: "lastName", value: formState.lastName, onTouch: onFieldTouch, onChange: onFieldChange })),
                    React.createElement(ValidatedFieldWrapper, { validationResult: validationState['phoneNumber'], isTouched: touchedState['phoneNumber'] || false },
                        React.createElement(LoqateAdvisoryPhoneInputField, { styleVariant: InputStyleVariant.OUTSIZE, label: 'Phone number', value: formState.phoneNumber, name: "phoneNumber", onChange: onFieldChange, onTouch: onFieldTouch, disableValidation: validationState['phoneNumber']
                                ? !validationState['phoneNumber'].isValid
                                : false })))),
            React.createElement(ValidatedFieldWrapper, { validationResult: validationState['emailAddress'], isTouched: touchedState['emailAddress'] || false },
                React.createElement(LoqateAdvisoryEmailInputField, { styleVariant: InputStyleVariant.OUTSIZE, label: 'Email address', value: formState.emailAddress, name: "emailAddress", onChange: onFieldChange, onTouch: onFieldTouch, disableValidation: validationState['emailAddress']
                        ? !validationState['emailAddress'].isValid
                        : false })),
            React.createElement("div", { className: "change-password-trigger-container" },
                React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: onRequestChangePassword }, "Change password")),
            React.createElement("hr", null),
            React.createElement("div", { className: "actions-container flex-center flex-container" },
                React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, isDisabled: !hasAnyChanges, onClick: function () { return setDiscardChangesTimestamp(new Date()); } }, "Discard changes"),
                React.createElement("div", { className: "flex-grow" }),
                React.createElement("div", null,
                    React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, isDisabled: !hasAnyChanges || !isValid, isProcessing: isProcessing, onClick: function () { return !isProcessing && onSubmit(formState); } }, "Save changes"),
                    errorMessage && React.createElement(ErrorMessage, { message: errorMessage })))));
    }, [
        onSubmit,
        isProcessing,
        initialFormState,
        onRequestChangePassword,
        errorMessage,
    ]);
    return (React.createElement(ResponsiveViewWrapper, { className: "personal-information-form-container", downBreakpointSm: 500 },
        React.createElement(React.Fragment, null,
            React.createElement(ValidatedForm, { renderFormContents: renderFormContents, initialFormState: initialFormState, fieldValidators: fieldValidators }))));
};
export default PersonalInformationForm;
