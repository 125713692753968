import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '@payaca/components/modal';
import UpdateSubscriptionControl from '../updateSubscriptionControl/UpdateSubscriptionControl';
import './UpdateSubscriptionModal.sass';
var UpdateSubscriptionModal = function (_a) {
    var isOpen = _a.isOpen, stripeProductId = _a.stripeProductId, stripePriceId = _a.stripePriceId, _b = _a.additionalUserSeats, additionalUserSeats = _b === void 0 ? 0 : _b, onClose = _a.onClose, onUpdateSubscription = _a.onUpdateSubscription;
    var _c = useState(false), subscriptionIsUpdated = _c[0], setSubscriptionIsUpdated = _c[1];
    useEffect(function () {
        if (isOpen) {
            setSubscriptionIsUpdated(false);
        }
    }, [isOpen]);
    return (React.createElement(Modal, { isOpen: isOpen, title: '', size: "md", onClose: onClose, className: "update-subscription-modal" },
        !subscriptionIsUpdated && (React.createElement(UpdateSubscriptionControl, { onUpdateSubscription: function () {
                setSubscriptionIsUpdated(true);
                onUpdateSubscription && onUpdateSubscription();
            }, stripeProductId: stripeProductId, stripePriceId: stripePriceId, additionalUserSeats: additionalUserSeats })),
        subscriptionIsUpdated && (React.createElement("div", { className: "subscription-update-success-message" },
            React.createElement(FontAwesomeIcon, { icon: faCheck }),
            React.createElement("p", null, "Success!")))));
};
export default UpdateSubscriptionModal;
