import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FieldLabel from '../basicField/FieldLabel';
const styles = () => ({
  textField: {
    '& .MuiOutlinedInput-root': {
      padding: '0px',
    },
  },
  selectField: {
    backgroundColor: '#fff',
    width: '100%',
    border: '1px solid #c4c4c4',
    borderRadius: 5,
    lineHeight: '1.4285em',
    marginBottom: 10,
    '& .MuiSelect-select': {
      padding: '8px',
    },
    '& .MuiSelect-icon': {
      top: 'auto',
    },
  },
  selectFieldLabel: {
    fontWeight: 'bold',
  },
});

function SelectField(props) {
  const [open, setOpen] = useState(false);
  const {
    items,
    label,
    value,
    onChange,
    classes,
    onSelectOpen,
    onSelectClose,
    isRequired,
    tooltip,
    name,
  } = props;

  const handleClose = () => {
    onSelectClose && onSelectClose();
    setOpen(false);
  };

  const handleOpen = () => {
    onSelectOpen && onSelectOpen();
    setOpen(true);
  };

  const renderItems = () => {
    return items.map((item) => {
      return (
        <MenuItem
          key={item.value}
          value={item.value}
          className={classes.menuItem}
        >
          {item.label}
        </MenuItem>
      );
    });
  };

  return (
    <div>
      <FieldLabel label={label} isRequired={isRequired} tooltip={tooltip} />
      <Select
        name={name}
        className={classes.selectField}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        displayEmpty={true}
        disableUnderline
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {items && renderItems()}
      </Select>
    </div>
  );
}

export default withStyles(styles)(SelectField);
