import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import CustomerInformation from '@/ui/components/customerInformation/CustomerInformation';
import SelectCustomerModal from '@/ui/components/selectCustomerModal/SelectCustomerModal';
import CreateEditJobCustomerModal from '@/ui/components/createEditJobCustomerModal/CreateEditJobCustomerModal';
import { actions as dealsActions } from '@bit/payaca-tech.payaca-core.store.deals';
import { actions as customerActions } from '@bit/payaca-tech.payaca-core.store.customer';
import { CreateArea } from '@payaca/components/clickableArea';
import './DealCustomerControl.sass';
import { getDeal, getJobsByDealId } from '@/utils/stateAccessors';
var DealCustomerControl = function (_a) {
    var dealId = _a.dealId;
    var dispatch = useDispatch();
    var customer = useSelector(function (state) {
        return state.customer.currentCustomer;
    });
    var onSelectCustomer = function (customerId) {
        setCustomerModalView('add-existing');
        dispatch(dealsActions.requestSetDealCustomer(dealId, customerId));
    };
    var _b = useState(false), showSelectCustomerModal = _b[0], setShowSelectCustomerModal = _b[1];
    var _c = useState('add-existing'), customerModalView = _c[0], setCustomerModalView = _c[1];
    var deal = useSelector(function (state) {
        return getDeal(state, dealId);
    });
    var jobs = useSelector(function (state) {
        return getJobsByDealId(state, dealId);
    });
    var dealHasAnySentJobs = useMemo(function () {
        return jobs.some(function (i) { return !!i.sentAt; });
    }, [jobs]);
    var canDeleteCustomer = useMemo(function () {
        return !dealHasAnySentJobs;
    }, [dealHasAnySentJobs]);
    var onRemoveCustomer = function () {
        dispatch(dealsActions.requestUnsetDealCustomer(dealId));
        dispatch(customerActions.clearCurrentCustomer());
    };
    var onToggleAddCustomerView = function () {
        return setCustomerModalView(customerModalView === 'create-edit' ? 'add-existing' : 'create-edit');
    };
    var onEditCustomer = function () {
        setCustomerModalView('create-edit');
        setShowSelectCustomerModal(true);
    };
    var onAddCustomer = function () {
        setShowSelectCustomerModal(true);
    };
    var onEditCustomerClose = function () {
        setCustomerModalView('add-existing');
        setShowSelectCustomerModal(false);
    };
    return (React.createElement("div", { className: 'deal-customer-control' },
        customer && (deal === null || deal === void 0 ? void 0 : deal.customerId) ? (React.createElement("div", { className: 'deal-customer' },
            React.createElement("div", { className: 'flex-container actions-container' },
                React.createElement(FontAwesomeIcon, { icon: faPen, onClick: onEditCustomer, role: "button" }),
                canDeleteCustomer && (React.createElement(FontAwesomeIcon, { icon: faTimes, onClick: onRemoveCustomer, role: "button" }))),
            React.createElement(CustomerInformation, { customer: customer, showPrimaryContactOnly: true }))) : (deal && React.createElement(CreateArea, { onClick: onAddCustomer }, "Add Customer")),
        React.createElement(SelectCustomerModal, { isOpen: showSelectCustomerModal && customerModalView === 'add-existing', onClose: onEditCustomerClose, onSelectCustomer: function (customerId) {
                setShowSelectCustomerModal(false);
                onSelectCustomer(customerId);
            }, actions: customer ? undefined : (React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, onClick: onToggleAddCustomerView }, "Add New")) }),
        React.createElement(CreateEditJobCustomerModal, { isOpen: showSelectCustomerModal && customerModalView === 'create-edit', onClose: onEditCustomerClose, onPersistCustomer: function (customerId) {
                setShowSelectCustomerModal(false);
                onSelectCustomer(customerId);
            }, headerContent: customer ? undefined : (React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, onClick: onToggleAddCustomerView }, "Choose Existing")), customer: (deal === null || deal === void 0 ? void 0 : deal.customerId) && customer })));
};
export default DealCustomerControl;
