import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import './DealPage.sass';
import { TabbedView } from '@payaca/components/tabbedView';
import { actions as dealsActions } from '@bit/payaca-tech.payaca-core.store.deals';
import { actions as jobFinanceInformationActions } from '@bit/payaca-tech.payaca-core.store.job-finance-information';
import { actions as jobPaymentActions } from '@bit/payaca-tech.payaca-core.store.job-payments';
import { actions as customerActions } from '@bit/payaca-tech.payaca-core.store.customer';
import { actions as jobActions } from '@bit/payaca-tech.payaca-core.store.jobs';
import DealHeader from '@/ui/components/dealHeader/DealHeader';
import DealTimeline from '@/ui/components/dealTimeline/DealTimeline';
import DealPayments from '@/ui/components/dealPayments/DealPayments';
import { DealOverview } from '@/ui/components/dealOverview/DealOverview';
import { getDeal, getJobPaymentsByDealId, getJobsByDealId, } from '@/utils/stateAccessors';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { ResponsiveViewWrapper } from '@payaca/components/responsiveViewWrapper';
import AddNewDealItemControl from '@/ui/components/addNewDealItemControl/AddNewDealItemControl';
import RecordDealPaymentControl from '@/ui/components/recordDealPaymentControl/RecordDealPaymentControl';
import { getBacsPendingPayments } from '@payaca/helpers/jobPaymentHelper';
import ConfirmPaymentModal from '@/ui/components/confirmPaymentModal/ConfirmPaymentModal';
var ViewType;
(function (ViewType) {
    ViewType["OVERVIEW"] = "overview";
    ViewType["PAYMENTS_AND_FINANCE"] = "payments-and-finance";
    ViewType["TIMELINE"] = "timeline";
})(ViewType || (ViewType = {}));
var DealPage = function () {
    var dispatch = useDispatch();
    var history = useHistory();
    var dealId = useParams().dealId;
    var _a = useState(ViewType.OVERVIEW), view = _a[0], setView = _a[1];
    var _b = useState(), confirmPaymentId = _b[0], setConfirmPaymentId = _b[1];
    var _c = useState(false), showMarkAsCompleteModal = _c[0], setShowMarkAsCompleteModal = _c[1];
    var deal = useSelector(function (state) {
        return getDeal(state, +dealId);
    });
    var errorFetchingDeal = useSelector(function (state) {
        var _a, _b;
        return !!(((_a = state.deals) === null || _a === void 0 ? void 0 : _a.deals) && ((_b = state.deals.deals[dealId]) === null || _b === void 0 ? void 0 : _b.fetchFailedAt));
    });
    var jobs = useSelector(function (state) {
        return getJobsByDealId(state, +dealId);
    });
    var jobPayments = useSelector(function (state) {
        return getJobPaymentsByDealId(state, +dealId);
    });
    var latestJob = useMemo(function () { return jobs[0]; }, [jobs]);
    var _d = useState(false), jobDataInitialised = _d[0], setJobDataInitialised = _d[1];
    useEffect(function () {
        if (latestJob && !jobDataInitialised) {
            setJobDataInitialised(true);
            dispatch(jobActions.requestGetJobWithJobGroupsAndJobLineItems(latestJob.id));
        }
    }, [latestJob, jobDataInitialised]);
    useEffect(function () {
        if (jobPayments) {
            var pendingPayments = getBacsPendingPayments(jobPayments);
            if (pendingPayments.length)
                setConfirmPaymentId(pendingPayments[0].id);
        }
    }, [!!jobPayments.length]);
    useEffect(function () {
        dispatch(dealsActions.requestGetDeal(+dealId));
        dispatch(jobActions.requestGetJobsForDeal(+dealId));
        dispatch(jobFinanceInformationActions.requestGetJobFinanceInformationForDeal(+dealId));
        dispatch(jobPaymentActions.requestGetJobPaymentsForDeal(+dealId));
    }, [dealId]);
    useEffect(function () {
        dispatch(customerActions.clearCurrentCustomer());
    }, [dispatch]);
    useEffect(function () {
        if (deal === null || deal === void 0 ? void 0 : deal.customerId) {
            dispatch(customerActions.requestGetAndSetCurrentCustomer(deal.customerId));
        }
    }, [deal]);
    useEffect(function () {
        // redirect to "deals" page in the event we shouldn't be accessing this deal
        errorFetchingDeal && history.push('/deals');
    }, [errorFetchingDeal]);
    var onDealUpdateSuccess = useCallback(function (jobId) {
        var updatedJobId = jobId || latestJob.id;
        dispatch(dealsActions.requestGetDeal(+dealId));
        dispatch(jobActions.requestGetJob(updatedJobId));
        dispatch(jobPaymentActions.requestGetJobPaymentsForDeal(+dealId));
    }, [dealId, latestJob]);
    var quoteOrInvoiceSent = useMemo(function () {
        if (!jobs.length)
            return false;
        return !!jobs.find(function (i) { return i.sentAt; });
    }, [jobs]);
    var tabs = useMemo(function () {
        return [
            {
                label: ViewType.OVERVIEW,
                title: 'Overview',
                content: (React.createElement(DealOverview, { dealId: +dealId, onDealUpdateSuccess: onDealUpdateSuccess })),
            },
            {
                title: 'Payments + Finance',
                label: ViewType.PAYMENTS_AND_FINANCE,
                isDisabled: !quoteOrInvoiceSent,
                content: (React.createElement(DealPayments, { dealId: +dealId, onDealUpdateSuccess: onDealUpdateSuccess })),
            },
            {
                title: 'Timeline',
                isDisabled: !quoteOrInvoiceSent,
                label: ViewType.TIMELINE,
                content: React.createElement(DealTimeline, { dealId: +dealId }),
            },
        ];
    }, [dealId, quoteOrInvoiceSent, onDealUpdateSuccess]);
    return (React.createElement(AuthenticatedPageWrapper, { className: "deal-page", title: React.createElement("div", { className: "flex-grow title-bar-inner" },
            React.createElement(DealHeader, { dealId: +dealId })) },
        view !== ViewType.TIMELINE && (React.createElement(ResponsiveViewWrapper, { downBreakpointSm: 650, className: "additional-tab-header-content-wrapper" },
            React.createElement("div", { className: "additional-tab-header-content flex-container flex-center" },
                view === ViewType.OVERVIEW && (React.createElement(AddNewDealItemControl, { dealId: +dealId })),
                view === ViewType.PAYMENTS_AND_FINANCE && (React.createElement(RecordDealPaymentControl, { dealId: +dealId, onJobUpdateSuccess: onDealUpdateSuccess }))))),
        React.createElement(TabbedView, { tabs: tabs, activeTabLabel: view, onChangeTab: function (label) { return setView(label); } }),
        !!confirmPaymentId && (React.createElement(ConfirmPaymentModal, { jobPaymentId: confirmPaymentId, isOpen: !!confirmPaymentId, onClose: function () {
                setConfirmPaymentId(undefined);
            }, confirmPaymentCallback: function () {
                setConfirmPaymentId(undefined);
                onDealUpdateSuccess();
            } }))));
};
export default DealPage;
