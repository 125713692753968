import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './UserSeatsExceededBanner.css';
var UserSeatsExceededBanner = function () {
    var currentUserIsAdmin = useSelector(function (state) {
        var _a;
        return !!((_a = state.users.myProfile) === null || _a === void 0 ? void 0 : _a.isAdmin);
    });
    return (React.createElement("div", { className: "user-seats-exceeded-banner" },
        React.createElement("span", null,
            "The number of users exceeds the user seat capacity of this account.",
            ' ',
            !currentUserIsAdmin && 'Please contact your account administrator.',
            currentUserIsAdmin && (React.createElement(React.Fragment, null,
                "Please ",
                React.createElement("a", { href: "/users" }, "remove users"),
                " or",
                ' ',
                React.createElement("a", { href: "/upgradeAccount" },
                    "upgrade the account",
                    React.createElement(FontAwesomeIcon, { icon: faChevronRight })))))));
};
export default UserSeatsExceededBanner;
