var _a;
import React, { useCallback, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import FieldLabel from '../fieldLabel/FieldLabel';
import InputWrapper from '../inputWrapper/InputWrapper';
import './FormattedNumberField.sass';
export var NumberFormatVariant;
(function (NumberFormatVariant) {
    NumberFormatVariant["SORT_CODE"] = "sortCode";
    NumberFormatVariant["ACCOUNT_NUMBER"] = "accountNumber";
})(NumberFormatVariant || (NumberFormatVariant = {}));
var numberFormat = (_a = {},
    _a[NumberFormatVariant.SORT_CODE] = '##-##-##',
    _a[NumberFormatVariant.ACCOUNT_NUMBER] = '########',
    _a);
var FormattedNumberField = function (_a) {
    var _b = _a.changeTimeoutMs, changeTimeoutMs = _b === void 0 ? 1000 : _b, description = _a.description, formatVariant = _a.formatVariant, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c, _d = _a.isRequired, isRequired = _d === void 0 ? false : _d, label = _a.label, name = _a.name, onBlur = _a.onBlur, onChange = _a.onChange, onChangeTimeout = _a.onChangeTimeout, onTouch = _a.onTouch, styleVariant = _a.styleVariant, value = _a.value;
    var _e = useState(), changeTimeout = _e[0], setChangeTimeout = _e[1];
    var onInputChange = useCallback(function (value) {
        var _a;
        onChange &&
            onChange((_a = {},
                _a[name] = value,
                _a));
        changeTimeout && clearTimeout(changeTimeout);
        setChangeTimeout(setTimeout(function () {
            onChangeTimeout && onChangeTimeout();
        }, changeTimeoutMs));
    }, [onChange, onChangeTimeout, changeTimeout, changeTimeoutMs]);
    var onInputTouch = useCallback(function () {
        onTouch && onTouch(name);
    }, [onTouch]);
    var format = useMemo(function () {
        return numberFormat[formatVariant];
    }, []);
    return (React.createElement("div", { className: "formatted-number-field" },
        React.createElement(FieldLabel, { label: label, isRequired: isRequired, description: description }),
        React.createElement(InputWrapper, { isDisabled: isDisabled, styleVariant: styleVariant },
            React.createElement(React.Fragment, null,
                React.createElement(NumberFormat, { value: value, onValueChange: function (values) { return onInputChange(values.value); }, name: name, format: format, allowEmptyFormatting: true, onBlur: function () {
                        onInputTouch && onInputTouch();
                        onBlur && onBlur();
                    }, isNumericString: true })))));
};
export default FormattedNumberField;
