import React from 'react';
import { SteppedProgressTracker } from '@payaca/components';
import { StepLabel } from '@payaca/types/subsidisedFinanceInformationTypes';
import './SubsidisedFinanceInformationFormProgressTracker.css';
var STEPS = [
    {
        name: 'Business information',
        label: StepLabel.BUSINESS_INFORMATION,
    },
    {
        name: 'Financial information',
        label: StepLabel.FINANCIAL_INFORMATION,
    },
    {
        name: 'Regulatory requirements',
        label: StepLabel.REGULATORY_REQUIREMENTS,
    },
    {
        name: 'Additional information',
        label: StepLabel.ADDITIONAL_INFORMATION,
    },
    {
        name: 'Authority to search',
        label: StepLabel.AUTHORITY_TO_SEARCH,
    },
    {
        name: 'Supporting documents',
        label: StepLabel.SUPPORTING_DOCUMENTS,
    },
    {
        name: 'Review',
        label: StepLabel.REVIEW,
    },
];
var SubsidisedFinanceInformationFormProgressTracker = function (_a) {
    var currentStepLabel = _a.currentStepLabel, onClickStep = _a.onClickStep;
    return (React.createElement("div", { className: "subsidised-finance-information-form-progress-tracker" },
        React.createElement(SteppedProgressTracker, { steps: STEPS, currentStepLabel: currentStepLabel, onClickStep: onClickStep })));
};
export default SubsidisedFinanceInformationFormProgressTracker;
