import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './InitialRegisterForm.sass';
import { ValidatedForm } from '@payaca/components';
import { getEmailFieldValidator, getIsRequiredFieldValidator, } from '@payaca/helpers/fieldValidationHelper';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import LoqateAdvisoryEmailInputField from '../loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { CollapsiblePanel, CollapsiblePanelStyleVariant, } from '@payaca/components/collapsiblePanel';
import { BasicField } from '@payaca/components/basicField';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
var InitialRegisterForm = function (_a) {
    var referralToken = _a.referralToken, onSubmit = _a.onSubmit;
    var _b = useState(false), showReferralTokenInput = _b[0], setShowReferralTokenInput = _b[1];
    useEffect(function () {
        referralToken && setShowReferralTokenInput(true);
    }, [referralToken]);
    var initialFormState = useMemo(function () {
        return {
            referralToken: referralToken,
        };
    }, [referralToken]);
    var fieldValidators = useMemo(function () {
        return {
            emailAddress: [getIsRequiredFieldValidator(), getEmailFieldValidator()],
        };
    }, []);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ValidatedFieldWrapper, { validationResult: validationState['emailAddress'], isTouched: touchedState['emailAddress'] || false },
                React.createElement(LoqateAdvisoryEmailInputField, { styleVariant: InputStyleVariant.OUTSIZE, isRequired: true, label: "Email", name: "emailAddress", value: formState.emailAddress, onTouch: onFieldTouch, onChange: onFieldChange, disableValidation: validationState['emailAddress']
                        ? !validationState['emailAddress'].isValid
                        : false })),
            React.createElement("div", null,
                React.createElement(CollapsiblePanel, { isOpen: showReferralTokenInput, title: showReferralTokenInput ? ('Referral code') : (React.createElement("div", { className: "enter-referral-code-prompt" },
                        React.createElement("span", null, "Got a referral code? "),
                        React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR }, "Click here"))), onOpen: function () { return setShowReferralTokenInput(true); }, onClose: function () { return setShowReferralTokenInput(false); }, styleVariant: CollapsiblePanelStyleVariant.UNSTYLED },
                    React.createElement(BasicField, { styleVariant: InputStyleVariant.OUTSIZE, name: "referralToken", value: formState.referralToken, onTouch: onFieldTouch, onChange: onFieldChange }))),
            React.createElement(Button, { onClick: function () { return onSubmit && onSubmit(formState); }, styleVariant: ButtonStyleVariant.OUTSIZE, isDisabled: !isValid }, "Create account")));
    }, [onSubmit, showReferralTokenInput]);
    return (React.createElement("div", { className: "initial-register-form-container" },
        React.createElement(ValidatedForm, { renderFormContents: renderFormContents, fieldValidators: fieldValidators, initialFormState: initialFormState })));
};
export default InitialRegisterForm;
