import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopupMenu from '../popupMenu/PopupMenu';
import React, { useMemo, useRef, useState, } from 'react';
import './Tile.sass';
var Tile = function (_a) {
    var onClick = _a.onClick, className = _a.className, title = _a.title, children = _a.children, footer = _a.footer, _b = _a.hasBoxShadow, hasBoxShadow = _b === void 0 ? false : _b, quickActions = _a.quickActions;
    var ref = useRef(null);
    var _c = useState(false), isOpen = _c[0], setIsOpen = _c[1];
    var hasAnyQuickActions = useMemo(function () {
        return !!(quickActions === null || quickActions === void 0 ? void 0 : quickActions.length);
    }, [quickActions]);
    var popupMenuOptions = useMemo(function () {
        return quickActions === null || quickActions === void 0 ? void 0 : quickActions.map(function (quickAction) {
            return {
                name: quickAction.actionName,
                onClick: function () { return quickAction.actionBehaviour(); },
                isProcessing: quickAction.isActionProcessing || false,
            };
        });
    }, [quickActions]);
    return (React.createElement("div", { onClick: onClick, className: "tile " + (className ? className : '') + " " + (hasBoxShadow ? 'box-shadow' : '') },
        (title || hasAnyQuickActions) && (React.createElement("div", { className: "title-container flex-container flex-center" },
            React.createElement("div", { className: "flex-grow", style: { overflow: 'hidden' } }, typeof title === 'string' ? React.createElement("h3", null, title) : title),
            hasAnyQuickActions && (React.createElement("div", { ref: ref, onClick: function (e) {
                    setIsOpen(true);
                    e.stopPropagation();
                } },
                React.createElement(FontAwesomeIcon, { icon: faEllipsisV }),
                React.createElement(PopupMenu, { options: popupMenuOptions || [], isOpen: isOpen, onClose: function () {
                        setIsOpen(false);
                    }, anchorElement: ref.current }))))),
        React.createElement("div", { className: "body-container" }, children),
        footer && React.createElement("div", { className: "footer-container" }, footer)));
};
export default Tile;
