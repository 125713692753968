import { action } from 'typesafe-actions';
import { ActionType } from './dealsTypes';
// GET DEALS
export var requestGetDeal = function (dealId) {
    return action(ActionType.REQUEST_GET_DEAL, {
        dealId: dealId,
    });
};
export var getDealSuccess = function (dealId, deal) {
    return action(ActionType.GET_DEAL_SUCCESS, {
        dealId: dealId,
        deal: deal,
    });
};
export var getDealFailure = function (dealId, error) {
    return action(ActionType.GET_DEAL_FAILURE, { dealId: dealId }, null, error);
};
export var requestCreateDeal = function (onDealCreated) {
    return action(ActionType.REQUEST_CREATE_DEAL, {
        onDealCreated: onDealCreated,
    });
};
export var createDealSuccess = function (dealId) {
    return action(ActionType.CREATE_DEAL_SUCCESS, {
        dealId: dealId,
    });
};
export var createDealFailure = function (error) {
    return action(ActionType.CREATE_DEAL_FAILURE, null, error);
};
export var requestActivateDealView = function (accountId, onActivated) {
    return action(ActionType.REQUEST_ACTIVATE_DEAL_VIEW, {
        accountId: accountId,
        onActivated: onActivated,
    });
};
export var activateDealViewSuccess = function () {
    return action(ActionType.ACTIVATE_DEAL_VIEW_SUCCESS);
};
export var activateDealViewFailure = function (error) {
    return action(ActionType.ACTIVATE_DEAL_VIEW_FAILURE, null, null, error);
};
export var clearDeal = function (dealId) {
    return action(ActionType.CLEAR_DEAL, {
        dealId: dealId,
    });
};
export var clearDeals = function () {
    return action(ActionType.CLEAR_DEALS);
};
// UPDATE DEAL
export var requestUpdateDeal = function (dealId, dealUpdates) {
    return action(ActionType.REQUEST_UPDATE_DEAL, {
        dealId: dealId,
        dealUpdates: dealUpdates,
    });
};
export var updateDealSuccess = function (dealId, updatedDeal) {
    return action(ActionType.UPDATE_DEAL_SUCCESS, {
        dealId: dealId,
        deal: updatedDeal,
    });
};
export var updateDealFailure = function (dealId, error) {
    return action(ActionType.UPDATE_DEAL_FAILURE, null, null, error);
};
export var requestSetDealCustomer = function (dealId, customerId) {
    return action(ActionType.REQUEST_SET_DEAL_CUSTOMER, {
        dealId: dealId,
        customerId: customerId,
    });
};
export var setDealCustomerSuccess = function (dealId) {
    return action(ActionType.SET_DEAL_CUSTOMER_SUCCESS, {
        dealId: dealId,
    });
};
export var setDealCustomerFailure = function (error) {
    return action(ActionType.SET_DEAL_CUSTOMER_FAILURE, null, null, error);
};
export var requestUnsetDealCustomer = function (dealId) {
    return action(ActionType.REQUEST_UNSET_DEAL_CUSTOMER, {
        dealId: dealId,
    });
};
export var unsetDealCustomerSuccess = function (dealId) {
    return action(ActionType.UNSET_DEAL_CUSTOMER_SUCCESS, {
        dealId: dealId,
    });
};
export var unsetDealCustomerFailure = function (error) {
    return action(ActionType.UNSET_DEAL_CUSTOMER_FAILURE, null, null, error);
};
export var requestGetListedDealsPage = function (getListedDealsRequestData) {
    return action(ActionType.REQUEST_GET_LISTED_DEALS_PAGE, {
        getListedDealsRequestData: getListedDealsRequestData,
    });
};
export var clearListedDealsPage = function () {
    return action(ActionType.CLEAR_LISTED_DEALS_PAGE);
};
export var getListedDealsPageSuccess = function (listedDealsPage) {
    return action(ActionType.GET_LISTED_DEALS_PAGE_SUCCESS, {
        listedDealsPage: listedDealsPage,
    });
};
export var getListedDealsPageFailure = function (error) {
    return action(ActionType.GET_LISTED_DEALS_PAGE_FAILURE, null, null, error);
};
export var requestArchiveDeals = function (dealIds, onArchiveSuccess) {
    return action(ActionType.REQUEST_ARCHIVE_DEALS, {
        dealIds: dealIds,
        onArchiveSuccess: onArchiveSuccess,
    });
};
export var archiveDealsSuccess = function () {
    return action(ActionType.ARCHIVE_DEALS_SUCCESS);
};
export var archiveDealsFailure = function (error) {
    return action(ActionType.ARCHIVE_DEALS_FAILURE, null, null, error);
};
