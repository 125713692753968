import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import Button, { ButtonStyleVariant } from '../button/Button';
import './EditableElementControl.css';
var EditableElementControl = function (_a) {
    var _b = _a.initialIsEditingState, initialIsEditingState = _b === void 0 ? false : _b, renderTemplate = _a.renderTemplate, editTemplate = _a.editTemplate, onEdit = _a.onEdit, onSave = _a.onSave, customEditComponent = _a.customEditComponent, customSaveComponent = _a.customSaveComponent, customIsEditingState = _a.customIsEditingState;
    var _c = useState(initialIsEditingState), isEditing = _c[0], setIsEditing = _c[1];
    useEffect(function () {
        // provide override for internal state
        if (typeof customIsEditingState === 'boolean') {
            setIsEditing(customIsEditingState);
        }
    }, [customIsEditingState]);
    var defaultEditButton = (React.createElement(Button, { className: 'edit-button', styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () {
            // provide override for internal state
            if (typeof customIsEditingState !== 'boolean') {
                setIsEditing(true);
            }
            onEdit && onEdit();
        } },
        React.createElement(FontAwesomeIcon, { icon: faPen })));
    var defaultSaveButton = (React.createElement(Button, { className: 'save-button', styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () {
            // are we controlling the isEditing state internally?
            if (typeof customIsEditingState !== 'boolean') {
                setIsEditing(false);
            }
            onSave && onSave();
        } }, "Save"));
    return (React.createElement("div", { className: 'editable-element-control flex-container' }, isEditing ? (React.createElement(React.Fragment, null,
        editTemplate,
        customSaveComponent || defaultSaveButton)) : (React.createElement(React.Fragment, null,
        renderTemplate,
        " ",
        customEditComponent || defaultEditButton))));
};
export default EditableElementControl;
