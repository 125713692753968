import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IntercomAPI } from 'react-intercom';
import * as Sentry from '@sentry/browser';
import { actions as usersActions } from '@/api/users';
import { selectors as appSelectors } from '@/api/app';
var DevelopmentToolsInitialiser = function () {
    var dispatch = useDispatch();
    var myProfile = useSelector(function (state) { return state.users.myProfile; });
    var isLoggedIn = useSelector(function (state) {
        return appSelectors.isLoggedIn(state.app);
    });
    useEffect(function () {
        isLoggedIn && dispatch(usersActions.getProfile());
    }, [isLoggedIn]);
    var setupIntercom = useCallback(function () {
        // TODO: only send to intercom if specifc details changed
        if (myProfile.name && myProfile.name !== 'undefined undefined') {
            IntercomAPI('registerIdentifiedUser', {
                userId: myProfile.name,
            });
            var userDetails = {
                user_hash: myProfile.intercomHash,
                email: myProfile.email,
                user_id: myProfile.name,
                name: myProfile.name,
                phone: myProfile.contactNumber,
                using_tradesperson_web: true,
                company: {
                    id: myProfile.accounts &&
                        myProfile.accounts[0] &&
                        myProfile.accounts[0].id,
                    name: myProfile.accounts &&
                        myProfile.accounts[0] &&
                        myProfile.accounts[0].companyName,
                },
            };
            IntercomAPI('update', userDetails);
        }
    }, [myProfile]);
    var setupSentry = useCallback(function () {
        // set user email in sentry data
        Sentry.configureScope(function (scope) {
            scope.setUser({ email: myProfile.email });
        });
    }, [myProfile]);
    var setupProfitWell = useCallback(function () {
        var _a, _b;
        var stripeCustomerId = (_b = (_a = myProfile === null || myProfile === void 0 ? void 0 : myProfile.accounts) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.stripeCustomerId;
        window.profitwell('start', {
            user_email: myProfile.email,
            user_id: stripeCustomerId,
        });
    }, [myProfile]);
    var handleSetupProfitwell = useCallback(function () {
        if (myProfile && window.profitwell) {
            if (process.env.NODE_ENV !== 'development' &&
                process.env.REACT_APP_ENV !== 'staging') {
                // only send to profitwell on prod
                setupProfitWell();
            }
        }
    }, [window.profitwell, setupProfitWell, myProfile]);
    useEffect(function () {
        //  setup data when profile has loaded
        if (myProfile && Object.keys(myProfile).length > 0) {
            setupIntercom();
            handleSetupProfitwell();
            if (process.env.NODE_ENV !== 'development') {
                setupSentry();
            }
        }
    }, [myProfile]);
    return null;
};
export default DevelopmentToolsInitialiser;
