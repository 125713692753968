import get from 'lodash.get';
import { getIsRequiredFieldValidator, getPhoneNumberFieldValidator, getEmailFieldValidator, getArrayMustNotBeEmptyFieldValidator, getIsRequiredIfTrueConditionValidator, getMustEqualValueValidator, } from './fieldValidationHelper';
import { CompanyType, } from '@payaca/types/subsidisedFinanceInformationTypes';
var isRequiredFieldValidator = getIsRequiredFieldValidator();
var phoneNumberFieldValidator = getPhoneNumberFieldValidator();
var emailFieldValidator = getEmailFieldValidator();
var isRequiredUnlessSoleTraderValidator = function (fieldName, formState) {
    if (formState.companyType === CompanyType.SOLE_TRADER) {
        return {
            isValid: true,
        };
    }
    return isRequiredFieldValidator(fieldName, formState);
};
var phoneNumberUnlessSoleTraderValidator = function (fieldName, formState) {
    if (formState.companyType === CompanyType.SOLE_TRADER) {
        return {
            isValid: true,
        };
    }
    return phoneNumberFieldValidator(fieldName, formState);
};
var directorsValidator = getArrayMustNotBeEmptyFieldValidator({
    customErrorMessage: 'You must provide details for at least one director',
});
var companyContactsValidator = getArrayMustNotBeEmptyFieldValidator({
    customErrorMessage: 'You must provide details for at least one company contact',
});
var conditionalCompanyContactsValidator = function (fieldName, formState) {
    if (formState.companyType === CompanyType.SOLE_TRADER) {
        return {
            isValid: true,
        };
    }
    return companyContactsValidator(fieldName, formState);
};
var principalPlaceOfBusinessValidator = function (fieldName, formState) {
    if (formState.companyType === CompanyType.SOLE_TRADER ||
        formState.isPrincipalPlaceOfBusinessSameAsRegisteredAddress) {
        return {
            isValid: true,
        };
    }
    return isRequiredFieldValidator(fieldName, formState);
};
var productsValidator = getArrayMustNotBeEmptyFieldValidator({
    customErrorMessage: 'You must provide details for at least one product',
});
var creditServiceProvidersValidator = getIsRequiredIfTrueConditionValidator(function (formState) {
    return formState.hasCurrentCreditServiceProvider;
});
var getInsuranceBackedByValidator = function (productIndex) {
    return getIsRequiredIfTrueConditionValidator(function (formState) {
        return get(formState, "products[" + productIndex + "].isInsuranceBacked");
    });
};
var isRequiredIfIsFcaAuthorisedValidator = getIsRequiredIfTrueConditionValidator(function (formState) {
    return formState.isFcaAuthorised;
});
var emailIfIsFcaAuthorisedValidator = function (fieldName, formState) {
    if (!formState.isFcaAuthorised) {
        return { isValid: true };
    }
    return emailFieldValidator(fieldName, formState);
};
var isIcoRegisteredValidator = function (fieldName, formState) {
    if (formState[fieldName] === true) {
        return {
            isValid: true,
        };
    }
    return {
        isValid: false,
        errors: ['Your company must be ICO registered in order to proceed'],
    };
};
var isRequiredIfIsIcoRegisteredValidator = getIsRequiredIfTrueConditionValidator(function (formState) {
    return formState.isIcoRegistered;
});
var icoIssuesDetailsValidator = getIsRequiredIfTrueConditionValidator(function (formState) {
    return formState.isIcoRegistered && formState.hasAnyIcoIssues;
});
export var getFieldValidatorsForGetStarted = function () {
    var fieldValidators = {
        companyType: [isRequiredFieldValidator],
        companyRegistrationNumber: [isRequiredUnlessSoleTraderValidator],
    };
    return fieldValidators;
};
export var getFieldValidatorsForBusinessInformation = function (companyContactsCount, directorsCount) {
    var fieldValidators = {
        companyName: [isRequiredFieldValidator],
        companyType: [isRequiredFieldValidator],
        companyRegistrationNumber: [isRequiredUnlessSoleTraderValidator],
        businessTradingCommencementDate: [isRequiredUnlessSoleTraderValidator],
        mainTelephoneNumber: [
            isRequiredUnlessSoleTraderValidator,
            phoneNumberUnlessSoleTraderValidator,
        ],
        'registeredAddress.line1': [isRequiredUnlessSoleTraderValidator],
        'registeredAddress.city': [isRequiredUnlessSoleTraderValidator],
        'registeredAddress.postcode': [isRequiredUnlessSoleTraderValidator],
        'principalPlaceOfBusiness.line1': [principalPlaceOfBusinessValidator],
        'principalPlaceOfBusiness.city': [principalPlaceOfBusinessValidator],
        'principalPlaceOfBusiness.postcode': [principalPlaceOfBusinessValidator],
        directors: [directorsValidator],
        companyContacts: [conditionalCompanyContactsValidator],
    };
    for (var i = 0; i < companyContactsCount; i++) {
        fieldValidators["companyContacts[" + i + "].department"] = [
            isRequiredFieldValidator,
        ];
        fieldValidators["companyContacts[" + i + "].name"] = [isRequiredFieldValidator];
        fieldValidators["companyContacts[" + i + "].emailAddress"] = [
            isRequiredFieldValidator,
            emailFieldValidator,
        ];
    }
    for (var i = 0; i < directorsCount; i++) {
        fieldValidators["directors[" + i + "].name"] = [isRequiredFieldValidator];
        fieldValidators["directors[" + i + "].dateOfBirth"] = [isRequiredFieldValidator];
        fieldValidators["directors[" + i + "].titleWithinCompany"] = [
            isRequiredUnlessSoleTraderValidator,
        ];
        fieldValidators["directors[" + i + "].telephoneNumber"] = [
            isRequiredFieldValidator,
            phoneNumberFieldValidator,
        ];
        fieldValidators["directors[" + i + "].emailAddress"] = [
            isRequiredFieldValidator,
            emailFieldValidator,
        ];
        fieldValidators["directors[" + i + "].homeAddress.line1"] = [
            isRequiredFieldValidator,
        ];
        fieldValidators["directors[" + i + "].homeAddress.city"] = [
            isRequiredFieldValidator,
        ];
        fieldValidators["directors[" + i + "].homeAddress.postcode"] = [
            isRequiredFieldValidator,
        ];
    }
    return fieldValidators;
};
export var getFieldValidatorsForFinancialInformation = function (productsCount) {
    var fieldValidators = {
        creditServiceProviders: [creditServiceProvidersValidator],
        next12MonthsBusinessValueUsingCreditTerms: [isRequiredFieldValidator],
        numberOfBranches: [isRequiredFieldValidator],
        numberOfSalespeople: [isRequiredFieldValidator],
        products: [productsValidator],
    };
    for (var i = 0; i < productsCount; i++) {
        fieldValidators["products[" + i + "].description"] = [isRequiredFieldValidator];
        fieldValidators["products[" + i + "].averageOrderValue"] = [
            isRequiredFieldValidator,
        ];
        fieldValidators["products[" + i + "].totalSalesTurnoverPercentage"] = [
            isRequiredFieldValidator,
        ];
        fieldValidators["products[" + i + "].guaranteeOfferMonths"] = [
            isRequiredFieldValidator,
        ];
        fieldValidators["products[" + i + "].insuranceBackedBy"] = [
            getInsuranceBackedByValidator(i),
        ];
    }
    return fieldValidators;
};
export var getFieldValidatorsForRegulatoryRequirements = function () {
    var fieldValidators = {
        fcaFirmRegistrationNumber: [isRequiredIfIsFcaAuthorisedValidator],
        fcaComplaintsContactName: [isRequiredIfIsFcaAuthorisedValidator],
        fcaComplaintsContactEmailAddress: [
            isRequiredIfIsFcaAuthorisedValidator,
            emailIfIsFcaAuthorisedValidator,
        ],
        isIcoRegistered: [isIcoRegisteredValidator],
        icoDprReferenceNumber: [isRequiredIfIsIcoRegisteredValidator],
        icoIssuesDetails: [icoIssuesDetailsValidator],
    };
    return fieldValidators;
};
var isRequiredIfHasCriminalConvictionsValidator = getIsRequiredIfTrueConditionValidator(function (formState) {
    return formState.hasCriminalConvictions;
});
var isRequiredIfHasBankruptcyClaimsValidator = getIsRequiredIfTrueConditionValidator(function (formState) {
    return formState.hasBankruptcyClaims;
});
var isRequiredIfHasLenderFacilityTerminationValidator = getIsRequiredIfTrueConditionValidator(function (formState) {
    return formState.hasLenderFacilityTermination;
});
var isRequiredIfHasOutstandingCcjsValidator = getIsRequiredIfTrueConditionValidator(function (formState) {
    return formState.hasOutstandingCcjs;
});
var isRequiredIfHasRecentDisputesLitigationClaimsValidator = getIsRequiredIfTrueConditionValidator(function (formState) {
    return formState.hasRecentDisputesLitigationClaims;
});
var hasReadAndAcceptedAuthorityToSearch = getMustEqualValueValidator(true, {
    customErrorMessage: 'You must agree in order to continue',
});
export var getFieldValidatorsForAdditionalInformation = function () {
    var fieldValidators = {
        criminalConvictionsDetails: [isRequiredIfHasCriminalConvictionsValidator],
        bankruptcyClaimsDetails: [isRequiredIfHasBankruptcyClaimsValidator],
        lenderFacilityTerminationDetails: [
            isRequiredIfHasLenderFacilityTerminationValidator,
        ],
        outstandingCcjsDetails: [isRequiredIfHasOutstandingCcjsValidator],
        recentDisputesLitigationClaimsDetails: [
            isRequiredIfHasRecentDisputesLitigationClaimsValidator,
        ],
    };
    return fieldValidators;
};
export var getFieldValidatorsForAuthorityToSearch = function () {
    var fieldValidators = {
        bankName: [isRequiredFieldValidator],
        sortCode: [isRequiredFieldValidator],
        accountNumber: [isRequiredFieldValidator],
        accountName: [isRequiredFieldValidator],
        hasReadAndAcceptedAuthorityToSearch: [hasReadAndAcceptedAuthorityToSearch],
    };
    return fieldValidators;
};
var genericSuportingDocumentsValidator = getArrayMustNotBeEmptyFieldValidator({
    customErrorMessage: 'You must upload a minimum of one document',
});
var getDirectorIdDocumentsValidator = function (directorIds, options) {
    var errorMessage = (options === null || options === void 0 ? void 0 : options.customErrorMessage) ||
        "You must upload one document for each of the " + ((options === null || options === void 0 ? void 0 : options.readableName) ? options === null || options === void 0 ? void 0 : options.readableName : 'directors');
    return function (fieldName, formState) {
        var directorIdDocuments = formState[fieldName];
        var hasDocumentForEachDirector = directorIds.reduce(function (accumulator, directorId) {
            var directorIdDocument = directorIdDocuments === null || directorIdDocuments === void 0 ? void 0 : directorIdDocuments.find(function (directorIdDocument) {
                return directorIdDocument.directorId === directorId;
            });
            if (!directorIdDocument)
                return false;
            return accumulator;
        }, true);
        if (!hasDocumentForEachDirector) {
            return {
                isValid: false,
                errors: [errorMessage],
            };
        }
        return {
            isValid: true,
        };
    };
};
var getDirectorAddressDocumentsValidator = function (directorIds, options) {
    var errorMessage = (options === null || options === void 0 ? void 0 : options.customErrorMessage) ||
        "You must upload one document for each of the " + ((options === null || options === void 0 ? void 0 : options.readableName) ? options === null || options === void 0 ? void 0 : options.readableName : 'directors');
    return function (fieldName, formState) {
        var directorAddressDocuments = formState[fieldName];
        var hasDocumentForEachDirector = directorIds.reduce(function (accumulator, directorId) {
            var directorAddressDocument = directorAddressDocuments === null || directorAddressDocuments === void 0 ? void 0 : directorAddressDocuments.find(function (directorAddressDocument) {
                return directorAddressDocument.directorId === directorId;
            });
            if (!directorAddressDocument)
                return false;
            return accumulator;
        }, true);
        if (!hasDocumentForEachDirector) {
            return {
                isValid: false,
                errors: [errorMessage],
            };
        }
        return {
            isValid: true,
        };
    };
};
export var getFieldValidatorsForSupportingDocuments = function (directorIds) {
    var fieldValidators = {
        genericSupportingDocuments: [genericSuportingDocumentsValidator],
        bankStatementDocument: [isRequiredFieldValidator],
        directorIdDocuments: [getDirectorIdDocumentsValidator(directorIds)],
        directorAddressDocuments: [
            getDirectorAddressDocumentsValidator(directorIds),
        ],
    };
    return fieldValidators;
};
