import React from 'react';
import Tooltip from '@bit/payaca-tech.payaca-core.component.tooltip';
import './FieldLabel.css';
var FieldLabel = function (_a) {
    var isRequired = _a.isRequired, label = _a.label, tooltip = _a.tooltip;
    return (React.createElement("div", { className: "label-container" },
        React.createElement("label", { className: "label" }, label),
        isRequired && React.createElement("span", { className: "required-indicator" }, "*"),
        tooltip && React.createElement(Tooltip, { text: tooltip })));
};
export default FieldLabel;
