import React from 'react';
import { ModalWrapper } from '@/ui/components';
var SupportingDocumentsProofOfAddressModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose;
    return (React.createElement(ModalWrapper, { open: isOpen, title: "Accepted proof of address documents", size: "md", onClose: onClose, classes: "proof-of-address-modal", showCloseIcon: true },
        React.createElement("div", null,
            React.createElement("span", { className: "bold-weight" }, "Must not be from the same source as the proof of ID document"),
            React.createElement("ul", null,
                React.createElement("li", null, "Valid UK photocard driving licence"),
                React.createElement("li", null, "Valid UK old style driving licence (not provisional)"),
                React.createElement("li", null, "Annual council tax bill/demand letter *"),
                React.createElement("li", null, "Bank, building society, credit card, or credit union statement **"),
                React.createElement("li", null, "Mortgage statement from a recognised lender *"),
                React.createElement("li", null, "Utility bills (not mobile or broadband/tv provider) **")),
            React.createElement("div", null, "* Must be the most recently issued and less than 12 months old."),
            React.createElement("div", null, "** Must be the most recently issued and less than three months old (except for annual utility bills/bank statements which must be less than 12 months old)."))));
};
export default SupportingDocumentsProofOfAddressModal;
