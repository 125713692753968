import React, { useEffect } from 'react';
import './SentQuotesChart.css';
import { AggregateValueBarChart, CategoriesKey } from '@/ui/components';
import { useDispatch, useSelector } from 'react-redux';
import { actions as analyticsActions } from '@bit/payaca-tech.payaca-core.store.analytics';
import * as d3 from 'd3';
var formatXAxisTickValue = function (value) {
    return Number.isInteger(value) ? d3.format('d')(value) : '';
};
var SentQuotesChart = function () {
    var dispatch = useDispatch();
    var sentQuotesAggregateChartData = useSelector(function (state) {
        return state.analytics.sentQuotesAggregateChartData;
    });
    useEffect(function () {
        dispatch(analyticsActions.requestGetSentQuotesAggregateChartData());
        return function () {
            dispatch(analyticsActions.clearSentQuotesAggregateChartData());
        };
    }, []);
    return (React.createElement("div", { className: "sent-quotes-chart" },
        React.createElement(CategoriesKey, { categories: sentQuotesAggregateChartData === null || sentQuotesAggregateChartData === void 0 ? void 0 : sentQuotesAggregateChartData.aggregateCategories }),
        React.createElement(AggregateValueBarChart, { data: sentQuotesAggregateChartData, xAxisLabel: "Quotes sent", yAxisLabel: "Month sent", overrideChartPadding: { left: 75, bottom: 35, top: 0, right: 10 }, formatXAxisTickValue: formatXAxisTickValue })));
};
export default SentQuotesChart;
