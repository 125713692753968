import React, { useMemo } from 'react';
import './PaymentMethodCard.sass';
var PaymentMethodCard = function (_a) {
    var brand = _a.brand, expiryMonth = _a.expiryMonth, expiryYear = _a.expiryYear, cardNumberLast4 = _a.cardNumberLast4;
    var cardBackground = useMemo(function () {
        if (brand === 'visa')
            return require('./card-background-visa.svg').default;
        if (brand === 'mastercard')
            return require('./card-background-mastercard.svg').default;
        return;
    }, [brand]);
    var readableBrand = useMemo(function () {
        if (brand === 'visa')
            return 'Visa';
        if (brand === 'mastercard')
            return 'Mastercard';
        return brand;
    }, [brand]);
    return (React.createElement("div", { className: "payment-method-card", style: {
            backgroundImage: "url('" + cardBackground + "')",
        } },
        React.createElement("span", { className: "brand" }, readableBrand),
        React.createElement("div", { className: "card-number" },
            React.createElement("label", null, "Card number"),
            React.createElement("div", { className: "card-number-digits" },
                React.createElement("span", null, "\u2022\u2022\u2022\u2022"),
                React.createElement("span", null, "\u2022\u2022\u2022\u2022"),
                React.createElement("span", null, "\u2022\u2022\u2022\u2022"),
                React.createElement("span", null, cardNumberLast4))),
        React.createElement("div", { className: "expiry-date" },
            React.createElement("label", null, "Expiry date"),
            React.createElement("div", null,
                expiryMonth.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                }),
                "/",
                expiryYear))));
};
export default PaymentMethodCard;
