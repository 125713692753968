var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useMemo, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as jobsActions } from '@bit/payaca-tech.payaca-core.store.jobs';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { getModal } from '@/helpers/modalHelper';
import { IntercomAPI } from 'react-intercom';
import { actions as customerActions } from '@bit/payaca-tech.payaca-core.store.customer';
import './PreviewAndSendJobControl.sass';
import { getJob, getJobPaymentsByJobId } from '@/utils/stateAccessors';
import { getJobType } from '@bit/payaca-tech.payaca-core.helpers.job-v2';
import JobOverview from '../jobOverview/JobOverviewRetrievalWrapper';
import { ContentPanel } from '@payaca/components/contentPanel';
import { AddCustomerEmailModal, JobPreviewUpdateReminders, SendJob } from '..';
import PreviewJobControlWrapper from '../previewJobControlWrapper/PreviewJobControlWrapper';
import PreviewJobHeader from '../previewJobHeader/PreviewJobHeader';
import PreviewJobActionButtons from '../previewJobActionButtons/PreviewJobActionButtons';
import { useHistory } from 'react-router';
import { actions as appActions } from '@/api/app';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
var PreviewAndSendJobControl = function (_a) {
    var jobId = _a.jobId, sendJobToCustomer = _a.sendJobToCustomer;
    var dispatch = useDispatch();
    var history = useHistory();
    var _b = useState(true), isSendJobDisabled = _b[0], setIsSendJobDisabled = _b[1];
    var _c = useState(false), isSendingJob = _c[0], setIsSendingJob = _c[1];
    var _d = useState(false), showAddCustomerEmailModal = _d[0], setShowAddCustomerEmailModal = _d[1];
    var _e = useState({
        sendMeACopy: true,
        preButtonEmailText: '',
        postButtonEmailText: '',
    }), sendJobPayload = _e[0], setSendJobPayload = _e[1];
    var job = useSelector(function (state) {
        return getJob(state, jobId);
    });
    var customer = useSelector(function (state) {
        return state.customer.currentCustomer;
    });
    var jobPayments = useSelector(function (state) {
        return getJobPaymentsByJobId(state, jobId);
    });
    var onJobUpdateSuccess = useCallback(function () {
        dispatch(jobsActions.requestGetJob(jobId));
    }, [dispatch, jobId]);
    var jobType = useMemo(function () {
        if (!job)
            return;
        return getJobType(job);
    }, [job]);
    var handleErrorMessageOnSendAttempt = useCallback(function (errorMessage) {
        if (!job)
            return;
        if (errorMessage === 'DEMO_ENDED') {
            dispatch(appActions.showModal(getModal('DEMO_ENDED', {
                primaryAction: function () {
                    dispatch(appActions.hideModal());
                    history.push('/upgradeAccount');
                },
                secondaryAction: function () {
                    IntercomAPI('show');
                    dispatch(appActions.hideModal());
                },
                onClose: function () { return dispatch(appActions.hideModal()); },
            })));
        }
        else if (errorMessage === 'You have exceeded your subscription plan') {
            dispatch(appActions.showModal(getModal('EXCEEDED_SUBSCRIPTION', {
                primaryAction: function () {
                    dispatch(appActions.hideModal());
                    history.push('/upgradeAccount');
                },
                secondaryAction: function () {
                    IntercomAPI('show');
                    dispatch(appActions.hideModal());
                },
                onClose: function () { return dispatch(appActions.hideModal()); },
            })));
        }
        else if (errorMessage === 'EMAIL_INACTIVE') {
            var primaryContact = getJobContactFromCustomer(customer, (job === null || job === void 0 ? void 0 : job.contactId) || null);
            dispatch(appActions.showModal(getModal('INACTIVE_EMAIL', {
                primaryAction: function () {
                    dispatch(appActions.hideModal());
                },
                text: [primaryContact === null || primaryContact === void 0 ? void 0 : primaryContact.emailAddress],
                onClose: function () { return dispatch(appActions.hideModal()); },
            })));
        }
    }, [dispatch, history, customer]);
    var navigateToDeal = useCallback(function () {
        history.push("/deals/" + (job === null || job === void 0 ? void 0 : job.dealId));
    }, [history, job]);
    var sendJob = useCallback(function () {
        var payload = {
            sendMeACopy: sendJobPayload.sendMeACopy,
            preButtonEmailText: sendJobPayload.preButtonEmailText,
            postButtonEmailText: sendJobPayload.postButtonEmailText,
        };
        setIsSendingJob(true);
        sendJobToCustomer(jobId, payload, function (error, response) {
            setIsSendingJob(false);
            if (error) {
                if (error.message)
                    handleErrorMessageOnSendAttempt(error.message.errors || error.message.error);
            }
            else {
                navigateToDeal();
            }
        });
    }, [
        jobId,
        sendJobPayload,
        dispatch,
        navigateToDeal,
        handleErrorMessageOnSendAttempt,
        sendJobToCustomer,
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PreviewJobControlWrapper, { className: "preview-and-send-job-control", previewContent: React.createElement(React.Fragment, null,
                React.createElement("div", { className: "send-control-container" },
                    React.createElement("h2", null, "Preview email"),
                    job && (React.createElement(SendJob, { job: job, customer: customer, jobPayments: jobPayments, preButtonEmailText: sendJobPayload.preButtonEmailText, postButtonEmailText: sendJobPayload.postButtonEmailText, sendMeACopy: sendJobPayload.sendMeACopy, onUpdate: function (value) {
                            if (!value)
                                return;
                            setSendJobPayload(function (sendJobPayload) {
                                return __assign(__assign({}, sendJobPayload), value);
                            });
                        }, onSetIsSendJobDisabled: setIsSendJobDisabled }))),
                React.createElement("div", { className: "job-overview-container" },
                    React.createElement("h2", null,
                        "Preview ",
                        jobType),
                    React.createElement(ContentPanel, { hasBoxShadow: true },
                        React.createElement(JobOverview, { jobId: jobId })))), title: React.createElement(PreviewJobHeader, { jobId: jobId }), sidebarContent: React.createElement(React.Fragment, null,
                React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, isDisabled: isSendJobDisabled, isProcessing: isSendingJob, onClick: function () { return !isSendingJob && sendJob(); }, onClickDisabled: function () { return setShowAddCustomerEmailModal(true); } }, "Send"),
                React.createElement(JobPreviewUpdateReminders, null),
                React.createElement(PreviewJobActionButtons, { jobId: jobId, onJobUpdateSuccess: onJobUpdateSuccess, markAsSentCallback: navigateToDeal })) }),
        job && (React.createElement(AddCustomerEmailModal, { customer: customer, isOpen: showAddCustomerEmailModal, jobContactId: job === null || job === void 0 ? void 0 : job.contactId, onClose: function () { return setShowAddCustomerEmailModal(false); }, customerUpdateCallback: function () {
                dispatch(customerActions.requestGetAndSetCurrentCustomer(customer.id));
                setShowAddCustomerEmailModal(false);
            } }))));
};
export default PreviewAndSendJobControl;
