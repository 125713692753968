import React, { useEffect } from 'react';
import './SentInvoicesChart.css';
import { AggregateValueBarChart, CategoriesKey } from '@/ui/components';
import { useDispatch, useSelector } from 'react-redux';
import { actions as analyticsActions } from '@bit/payaca-tech.payaca-core.store.analytics';
import * as d3 from 'd3';
var formatXAxisTickValue = function (value) {
    return Number.isInteger(value) ? d3.format('d')(value) : '';
};
var SentInvoicesChart = function () {
    var dispatch = useDispatch();
    var sentInvoicesAggregateChartData = useSelector(function (state) {
        return state.analytics.sentInvoicesAggregateChartData;
    });
    useEffect(function () {
        dispatch(analyticsActions.requestGetSentInvoicesAggregateChartData());
        return function () {
            dispatch(analyticsActions.clearSentInvoicesAggregateChartData());
        };
    }, []);
    return (React.createElement("div", { className: "sent-invoices-chart" },
        React.createElement(CategoriesKey, { categories: sentInvoicesAggregateChartData === null || sentInvoicesAggregateChartData === void 0 ? void 0 : sentInvoicesAggregateChartData.aggregateCategories }),
        React.createElement(AggregateValueBarChart, { data: sentInvoicesAggregateChartData, xAxisLabel: "Invoices sent", yAxisLabel: "Month sent", overrideChartPadding: { left: 75, bottom: 35, top: 0, right: 10 }, formatXAxisTickValue: formatXAxisTickValue })));
};
export default SentInvoicesChart;
