import React from 'react';
var BulkUploadInstruction = function (_a) {
    var children = _a.children, instructionNumber = _a.instructionNumber, instructionTitle = _a.instructionTitle;
    return (React.createElement("div", { className: "upload-instruction" },
        React.createElement("span", null,
            instructionNumber,
            ". ",
            instructionTitle),
        children));
};
export default BulkUploadInstruction;
