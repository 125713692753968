import { faPen, faTimes, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState, useEffect, useCallback, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomerInformation from '../customerInformation/CustomerInformation';
import { actions as customerActions } from '@bit/payaca-tech.payaca-core.store.customer';
import CreateEditJobCustomerModal from '../createEditJobCustomerModal/CreateEditJobCustomerModal';
import SelectCustomerModal from '../selectCustomerModal/SelectCustomerModal';
import MiniLoader from '@bit/payaca-tech.payaca-core.component.mini-loader';
import { ContentPanel } from '@payaca/components/contentPanel';
import { RadioButton } from '@payaca/components/radioButton';
import './JobCustomerControl.sass';
import { getPrimaryContactFromCustomer } from '@bit/payaca-tech.payaca-core.helpers.customer';
import { ClickableArea, CreateArea } from '@payaca/components/clickableArea';
var JobCustomerControl = function (_a) {
    var _b = _a.canSelectCustomer, canSelectCustomer = _b === void 0 ? true : _b, customerId = _a.customerId, jobContactId = _a.jobContactId, onPersistCustomerSuccess = _a.onPersistCustomerSuccess, onSelectContact = _a.onSelectContact, onSelectCustomer = _a.onSelectCustomer;
    var dispatch = useDispatch();
    var _c = useState(false), showSearchTableModal = _c[0], setShowSearchTableModal = _c[1];
    var _d = useState(false), showCreateEditJobCustomerModal = _d[0], setShowCreateEditJobCustomerModal = _d[1];
    var customer = useSelector(function (state) { return state.customer.currentCustomer; });
    var isGettingCustomer = useSelector(function (state) { return state.customer.isGettingCustomer; });
    useEffect(function () {
        dispatch(customerActions.clearCurrentCustomer());
        if (customerId) {
            dispatch(customerActions.requestGetAndSetCurrentCustomer(customerId));
            onPersistCustomerSuccess && onPersistCustomerSuccess();
        }
    }, [customerId]);
    var primaryCustomerContactId = useMemo(function () {
        if (!customer)
            return;
        var primaryContact = getPrimaryContactFromCustomer(customer);
        return primaryContact === null || primaryContact === void 0 ? void 0 : primaryContact.id;
    }, [customer]);
    var selectedContactId = useMemo(function () {
        if (customerId) {
            return jobContactId || primaryCustomerContactId;
        }
    }, [customerId, jobContactId, primaryCustomerContactId]);
    var renderCustomerContact = useCallback(function (customerContact, index, originalComponent) {
        return (React.createElement("div", { className: "customer-contact-select-wrapper", key: "customer-contact-" + index },
            React.createElement(RadioButton, { onClick: function () {
                    return onSelectContact && onSelectContact(customerContact.id);
                }, isSelected: customerContact.id === selectedContactId }),
            originalComponent));
    }, [onSelectContact, selectedContactId]);
    var readView = useMemo(function () {
        if (!customer)
            return;
        return (React.createElement("div", { className: "read-view" },
            React.createElement("div", { className: "actions-container" },
                React.createElement(FontAwesomeIcon, { icon: faPen, className: "edit-customer-trigger", onClick: function () {
                        setShowCreateEditJobCustomerModal(true);
                    } }),
                canSelectCustomer && (React.createElement(FontAwesomeIcon, { icon: faTimes, className: "remove-customer-trigger", onClick: function () {
                        onSelectCustomer && onSelectCustomer();
                    } }))),
            React.createElement(CustomerInformation, { customer: customer, showPrimaryContactOnly: false, renderCustomerContact: renderCustomerContact })));
    }, [customer, onSelectCustomer, canSelectCustomer, renderCustomerContact]);
    var onPersistCustomer = useCallback(function (newCustomerId) {
        if (customerId) {
            // updating customer
            dispatch(customerActions.requestGetAndSetCurrentCustomer(customerId));
            onPersistCustomerSuccess && onPersistCustomerSuccess();
        }
        else {
            // setting new customer
            onSelectCustomer && onSelectCustomer(newCustomerId);
        }
        setShowCreateEditJobCustomerModal(false);
    }, [dispatch, customerId, onSelectCustomer, onPersistCustomerSuccess]);
    return (React.createElement("div", { className: "job-customer-control" },
        !isGettingCustomer && customer && (React.createElement(ContentPanel, { hasBoxShadow: false },
            React.createElement(React.Fragment, null, readView))),
        !isGettingCustomer && !customer && (React.createElement("div", { className: "create-or-add-existing-container" },
            React.createElement(ClickableArea, { onClick: function () { return setShowSearchTableModal(true); } }, "Search existing customers"),
            React.createElement("span", { className: "or" }, "or"),
            React.createElement(CreateArea, { onClick: function () { return setShowCreateEditJobCustomerModal(true); } }, "Add new customer"))),
        isGettingCustomer && (React.createElement("div", { className: "loader-container" },
            React.createElement(MiniLoader, null))),
        React.createElement(CreateEditJobCustomerModal, { isOpen: showCreateEditJobCustomerModal && !showSearchTableModal, onClose: function () { return setShowCreateEditJobCustomerModal(false); }, customer: customer, onPersistCustomer: onPersistCustomer }),
        canSelectCustomer && (React.createElement(SelectCustomerModal, { isOpen: showSearchTableModal, onClose: function () { return setShowSearchTableModal(false); }, onSelectCustomer: function (customerId) {
                setShowSearchTableModal(false);
                onSelectCustomer && onSelectCustomer(customerId);
            } }))));
};
export default JobCustomerControl;
