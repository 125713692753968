var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { takeEvery, call, race, delay, takeLatest, put, take, } from 'redux-saga/effects';
import { AuthActionTypes, } from './authTypes';
import { clearAnalyticsUserId, loginFailure, loginSuccess, signUpFailure, signUpSuccess, refreshAuthTokenFailure, refreshAuthTokenSuccess, requestRefreshAuthToken, storeTokens, storeTokensSuccess, } from './authActions';
export function refreshAuthToken() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, put(requestRefreshAuthToken())];
            case 1:
                _a.sent();
                return [4 /*yield*/, take([
                        AuthActionTypes.REFRESH_AUTH_TOKEN_FAILURE,
                        AuthActionTypes.STORE_TOKENS_SUCCESS,
                    ])];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function handleLoginResult(response, timeout) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!timeout) return [3 /*break*/, 2];
                return [4 /*yield*/, put(loginFailure(new Error('Login request timed out')))];
            case 1:
                _a.sent();
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, put(loginSuccess(response.token, response.refreshToken))];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}
function handleSignUpResult(response, timeout) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!timeout) return [3 /*break*/, 2];
                return [4 /*yield*/, put(signUpFailure(new Error('Sign up request timed out')))];
            case 1:
                _a.sent();
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, put(signUpSuccess(response.token, response.refreshToken))];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}
var analyticsSagaCreator = function (_a) {
    var apiBaseurl = _a.apiBaseurl, storeAuthTokens = _a.storeAuthTokens, getRefreshToken = _a.getRefreshToken, getAuthHeader = _a.getAuthHeader;
    function handleLogout() {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(storeAuthTokens, {
                        token: '',
                        refreshToken: '',
                    })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, put(clearAnalyticsUserId())];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    function handleClearAnalyticsUserId() {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(resetAnalyticsState)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    function handleRefreshAuthTokenSuccess(action) {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, put(storeTokens(action.payload.token, action.payload.refreshToken))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    function handleLoginSuccess(action) {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, put(storeTokens(action.payload.token, action.payload.refreshToken))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    function handleSignUpSuccess(action) {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, put(storeTokens(action.payload.token, action.payload.refreshToken))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    function handleStoreTokens(action) {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(storeAuthTokens, {
                        token: action.payload.token,
                        refreshToken: action.payload.refreshToken,
                    })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, put(storeTokensSuccess())];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    function handleAuthorise(action) {
        var _a, response, timeout, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, race({
                            response: call(authorise, action.payload.payload, action.payload.allow),
                            timeout: delay(15000),
                        })];
                case 1:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (timeout) {
                        throw 'Authorising connection timed out';
                    }
                    if (action.payload.callback) {
                        action.payload.callback(null, response);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _b.sent();
                    if (action.payload.callback) {
                        action.payload.callback(error_1, null);
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }
    function handleLogin(action) {
        var _a, response, timeout, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 5]);
                    return [4 /*yield*/, race({
                            response: call(login, action.payload.email, action.payload.password, action.payload.isPayacaAdminLogin),
                            timeout: delay(15000),
                        })];
                case 1:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    return [4 /*yield*/, call(handleLoginResult, response, timeout)];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _b.sent();
                    return [4 /*yield*/, put(loginFailure(error_2))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }
    function handleLoginWithToken(action) {
        var _a, response, timeout, error_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 5]);
                    return [4 /*yield*/, race({
                            response: call(loginWithToken, action.payload.token),
                            timeout: delay(15000),
                        })];
                case 1:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    return [4 /*yield*/, call(handleLoginResult, response, timeout)];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_3 = _b.sent();
                    return [4 /*yield*/, put(loginFailure(error_3))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }
    function handleLoginWithFacebook(action) {
        var _a, response, timeout, error_4;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 5]);
                    return [4 /*yield*/, race({
                            response: call(loginWithSocial, 'facebook', action.payload.data),
                            timeout: delay(15000),
                        })];
                case 1:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    return [4 /*yield*/, call(handleLoginResult, response, timeout)];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_4 = _b.sent();
                    return [4 /*yield*/, put(loginFailure(error_4))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }
    function handleLoginWithXero(action) {
        var _a, response, timeout, error_5;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 5]);
                    return [4 /*yield*/, race({
                            response: call(loginWithSocial, 'xero', action.payload.data),
                            timeout: delay(15000),
                        })];
                case 1:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    return [4 /*yield*/, call(handleLoginResult, response, timeout)];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_5 = _b.sent();
                    return [4 /*yield*/, put(loginFailure(error_5))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }
    function handleLoginWithGoogle(action) {
        var _a, response, timeout, error_6;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 5]);
                    return [4 /*yield*/, race({
                            response: call(loginWithSocial, 'google', action.payload.data),
                            timeout: delay(15000),
                        })];
                case 1:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    return [4 /*yield*/, call(handleLoginResult, response, timeout)];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_6 = _b.sent();
                    return [4 /*yield*/, put(loginFailure(error_6))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }
    function handleLoginWithApple(action) {
        var _a, response, timeout, error_7;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 5]);
                    return [4 /*yield*/, race({
                            response: call(loginWithSocial, 'apple', action.payload.data),
                            timeout: delay(15000),
                        })];
                case 1:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    return [4 /*yield*/, call(handleLoginResult, response, timeout)];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_7 = _b.sent();
                    return [4 /*yield*/, put(loginFailure(error_7))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }
    function handleSignUpWithFacebook(action) {
        var _a, response, timeout, error_8;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 5]);
                    return [4 /*yield*/, race({
                            response: call(registerWithSocial, 'facebook', action.payload.data),
                            timeout: delay(15000),
                        })];
                case 1:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    return [4 /*yield*/, call(handleSignUpResult, response, timeout)];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_8 = _b.sent();
                    return [4 /*yield*/, put(signUpFailure(error_8))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }
    function handleSignUpWithXero(action) {
        var _a, response, timeout, error_9;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 5]);
                    return [4 /*yield*/, race({
                            response: call(registerWithSocial, 'xero', action.payload.data),
                            timeout: delay(15000),
                        })];
                case 1:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    return [4 /*yield*/, call(handleSignUpResult, response, timeout)];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_9 = _b.sent();
                    return [4 /*yield*/, put(signUpFailure(error_9))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }
    function handleSignUpWithGoogle(action) {
        var _a, response, timeout, error_10;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 5]);
                    return [4 /*yield*/, race({
                            response: call(registerWithSocial, 'google', action.payload.data),
                            timeout: delay(15000),
                        })];
                case 1:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    return [4 /*yield*/, call(handleSignUpResult, response, timeout)];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_10 = _b.sent();
                    return [4 /*yield*/, put(signUpFailure(error_10))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }
    function handleSignUpWithApple(action) {
        var _a, response, timeout, error_11;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 5]);
                    return [4 /*yield*/, race({
                            response: call(registerWithSocial, 'apple', action.payload.data),
                            timeout: delay(15000),
                        })];
                case 1:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    return [4 /*yield*/, call(handleSignUpResult, response, timeout)];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_11 = _b.sent();
                    return [4 /*yield*/, put(signUpFailure(error_11))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }
    function handleRefreshAuthToken(action) {
        var refreshToken, _a, response, timeout, error_12;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 7, , 9]);
                    return [4 /*yield*/, call(getRefreshToken)];
                case 1:
                    refreshToken = _b.sent();
                    return [4 /*yield*/, race({
                            response: call(refreshAuthToken, refreshToken),
                            timeout: delay(15000),
                        })];
                case 2:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 4];
                    return [4 /*yield*/, put(refreshAuthTokenFailure(new Error('Refresh auth token request timed out')))];
                case 3:
                    _b.sent();
                    return [3 /*break*/, 6];
                case 4: return [4 /*yield*/, put(refreshAuthTokenSuccess(response.token, response.refreshToken))];
                case 5:
                    _b.sent();
                    _b.label = 6;
                case 6: return [3 /*break*/, 9];
                case 7:
                    error_12 = _b.sent();
                    return [4 /*yield*/, put(refreshAuthTokenFailure(error_12))];
                case 8:
                    _b.sent();
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }
    function handleRegisterOAuthClient(action) {
        var _a, response, timeout, error_13;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, race({
                            response: call(registerOAuthClient, action.payload.payload),
                            timeout: delay(15000),
                        })];
                case 1:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (timeout) {
                        throw 'Registering OAuth client timed out';
                    }
                    if (action.payload.callback) {
                        action.payload.callback(null, response);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_13 = _b.sent();
                    if (action.payload.callback) {
                        action.payload.callback(error_13, null);
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }
    function handleRemoveOAuthClient(action) {
        var _a, response, timeout, error_14;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, race({
                            response: call(removeOAuthConnection, action.payload.payload),
                            timeout: delay(15000),
                        })];
                case 1:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (timeout) {
                        throw 'Removing OAuth client timed out';
                    }
                    if (action.payload.callback) {
                        action.payload.callback(null, response);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_14 = _b.sent();
                    if (action.payload.callback) {
                        action.payload.callback(error_14, null);
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }
    function handleVerifyOAuth(action) {
        var _a, response, timeout, error_15;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, race({
                            response: call(verifyOAuth, action.payload.payload),
                            timeout: delay(15000),
                        })];
                case 1:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (timeout) {
                        throw 'Verifying oauth timed out';
                    }
                    if (action.payload.callback) {
                        action.payload.callback(null, response);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_15 = _b.sent();
                    if (action.payload.callback) {
                        action.payload.callback(error_15, null);
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }
    var resetAnalyticsState = function () {
        var _a;
        // we'll un-assign the id of the active user against all analytics activity
        // @ts-ignore
        if ((_a = window === null || window === void 0 ? void 0 : window.posthog) === null || _a === void 0 ? void 0 : _a.reset) {
            // @ts-ignore
            window.posthog.reset();
        }
    };
    var authorise = function (payload, allow) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = fetch;
                    _b = [apiBaseurl + "/oauth/authorise?allow=" + allow];
                    _c = {
                        method: 'POST'
                    };
                    _d = {};
                    return [4 /*yield*/, getAuthHeader()];
                case 1: return [2 /*return*/, _a.apply(void 0, _b.concat([(_c.headers = (_d.Authorization = _e.sent(),
                            _d['Content-Type'] = 'application/json',
                            _d['X-Simple-Job'] = 'true',
                            _d),
                            _c.body = JSON.stringify({ path: payload.path, scopes: payload.scopes }),
                            _c)])).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.ok) return [3 /*break*/, 2];
                                    return [4 /*yield*/, response.json()];
                                case 1: return [2 /*return*/, _a.sent()];
                                case 2: throw __assign({ status: response.status }, response.json());
                            }
                        });
                    }); })];
            }
        });
    }); };
    var registerOAuthClient = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = fetch;
                    _b = [apiBaseurl + "/oauth/register_client"];
                    _c = {
                        method: 'POST'
                    };
                    _d = {};
                    return [4 /*yield*/, getAuthHeader()];
                case 1: return [2 /*return*/, _a.apply(void 0, _b.concat([(_c.headers = (_d.Authorization = _e.sent(),
                            _d['Content-Type'] = 'application/json',
                            _d['X-Simple-Job'] = 'true',
                            _d),
                            _c.body = JSON.stringify({
                                clientName: payload.clientName,
                                redirectUrl: payload.redirectUrl,
                            }),
                            _c)])).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.ok) return [3 /*break*/, 2];
                                    return [4 /*yield*/, response.json()];
                                case 1: return [2 /*return*/, _a.sent()];
                                case 2: throw new Error("registerOAuthClient failed: " + response.status + " " + response.statusText);
                            }
                        });
                    }); })];
            }
        });
    }); };
    var removeOAuthConnection = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = fetch;
                    _b = [apiBaseurl + "/oauth/revoke_access"];
                    _c = {
                        method: 'POST'
                    };
                    _d = {};
                    return [4 /*yield*/, getAuthHeader()];
                case 1: return [2 /*return*/, _a.apply(void 0, _b.concat([(_c.headers = (_d.Authorization = _e.sent(),
                            _d['Content-Type'] = 'application/json',
                            _d['X-Simple-Job'] = 'true',
                            _d),
                            _c.body = JSON.stringify({
                                clientId: payload.clientId,
                            }),
                            _c)])).then(function (response) {
                        if (response.ok) {
                            return response;
                        }
                        else {
                            throw new Error("removeOAuthConnection failed: " + response.status + " " + response.statusText);
                        }
                    })];
            }
        });
    }); };
    var verifyOAuth = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = fetch;
                    _b = [apiBaseurl + "/oauth/verify"];
                    _c = {
                        method: 'POST'
                    };
                    _d = {};
                    return [4 /*yield*/, getAuthHeader()];
                case 1: return [2 /*return*/, _a.apply(void 0, _b.concat([(_c.headers = (_d.Authorization = _e.sent(),
                            _d['Content-Type'] = 'application/json',
                            _d['X-Simple-Job'] = 'true',
                            _d),
                            _c.body = JSON.stringify({
                                redirectUri: payload.redirectUri,
                                clientId: payload.clientId,
                                responseType: payload.responseType,
                                state: payload.state,
                                scopes: payload.scope,
                            }),
                            _c)])).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.ok) return [3 /*break*/, 2];
                                    return [4 /*yield*/, response.json()];
                                case 1: return [2 /*return*/, _a.sent()];
                                case 2: throw __assign({ status: response.status }, response.json());
                            }
                        });
                    }); })];
            }
        });
    }); };
    var login = function (email, password, isPayacaAdminLogin) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = fetch;
                    _b = [apiBaseurl + "/login"];
                    _c = {
                        method: 'POST'
                    };
                    _d = {};
                    return [4 /*yield*/, getAuthHeader()];
                case 1: return [2 /*return*/, _a.apply(void 0, _b.concat([(_c.headers = (_d.Authorization = _e.sent(),
                            _d['Content-Type'] = 'application/json',
                            _d['X-Simple-Job'] = 'true',
                            _d),
                            _c.body = JSON.stringify({
                                email: email,
                                password: password,
                                isPayacaAdminLogin: isPayacaAdminLogin,
                            }),
                            _c)])).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.ok) return [3 /*break*/, 2];
                                    return [4 /*yield*/, response.json()];
                                case 1: return [2 /*return*/, _a.sent()];
                                case 2: throw new Error("Login failed: " + response.status + " " + response.statusText);
                            }
                        });
                    }); })];
            }
        });
    }); };
    var loginWithToken = function (token) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = fetch;
                    _b = [apiBaseurl + "/login/" + token];
                    _c = {
                        method: 'POST'
                    };
                    _d = {};
                    return [4 /*yield*/, getAuthHeader()];
                case 1: return [2 /*return*/, _a.apply(void 0, _b.concat([(_c.headers = (_d.Authorization = _e.sent(),
                            _d['Content-Type'] = 'application/json',
                            _d['X-Simple-Job'] = 'true',
                            _d),
                            _c)])).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.ok) return [3 /*break*/, 2];
                                    return [4 /*yield*/, response.json()];
                                case 1: return [2 /*return*/, _a.sent()];
                                case 2: throw new Error("Login failed: " + response.status + " " + response.statusText);
                            }
                        });
                    }); })];
            }
        });
    }); };
    var loginWithSocial = function (loginProvider, data) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = fetch;
                    _b = [apiBaseurl + "/login"];
                    _c = {
                        method: 'POST'
                    };
                    _d = {};
                    return [4 /*yield*/, getAuthHeader()];
                case 1: return [2 /*return*/, _a.apply(void 0, _b.concat([(_c.headers = (_d.Authorization = _e.sent(),
                            _d['Content-Type'] = 'application/json',
                            _d['X-Simple-Job'] = 'true',
                            _d),
                            _c.body = JSON.stringify(__assign({ provider: loginProvider }, data)),
                            _c)])).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.ok) return [3 /*break*/, 2];
                                    return [4 /*yield*/, response.json()];
                                case 1: return [2 /*return*/, _a.sent()];
                                case 2: throw new Error("Login failed: " + response.status + " " + response.statusText);
                            }
                        });
                    }); })];
            }
        });
    }); };
    var registerWithSocial = function (loginProvider, data) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = fetch;
                    _b = [apiBaseurl + "/users"];
                    _c = {
                        method: 'POST'
                    };
                    _d = {};
                    return [4 /*yield*/, getAuthHeader()];
                case 1: return [2 /*return*/, _a.apply(void 0, _b.concat([(_c.headers = (_d.Authorization = _e.sent(),
                            _d['Content-Type'] = 'application/json',
                            _d['X-Simple-Job'] = 'true',
                            _d),
                            _c.body = JSON.stringify(__assign({ provider: loginProvider }, data)),
                            _c)])).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.ok) return [3 /*break*/, 2];
                                    return [4 /*yield*/, response.json()];
                                case 1: return [2 /*return*/, _a.sent()];
                                case 2: throw new Error("Login failed: " + response.status + " " + response.statusText);
                            }
                        });
                    }); })];
            }
        });
    }); };
    var refreshAuthToken = function (refreshToken) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = fetch;
                    _b = [apiBaseurl + "/token"];
                    _c = {
                        method: 'POST'
                    };
                    _d = {};
                    return [4 /*yield*/, getAuthHeader()];
                case 1: return [2 /*return*/, _a.apply(void 0, _b.concat([(_c.headers = (_d.Authorization = _e.sent(),
                            _d['Content-Type'] = 'application/json',
                            _d['X-Simple-Job'] = 'true',
                            _d),
                            _c.body = JSON.stringify({
                                refreshToken: refreshToken,
                            }),
                            _c)])).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.ok) return [3 /*break*/, 2];
                                    return [4 /*yield*/, response.json()];
                                case 1: return [2 /*return*/, _a.sent()];
                                case 2: throw new Error("Refresh auth token failed: " + response.status + " " + response.statusText);
                            }
                        });
                    }); })];
            }
        });
    }); };
    return function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, takeEvery(AuthActionTypes.AUTHORISE, handleAuthorise)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(AuthActionTypes.REGISTER_O_AUTH_CLIENT, handleRegisterOAuthClient)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(AuthActionTypes.REMOVE_O_AUTH_CONNECTION, handleRemoveOAuthClient)];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(AuthActionTypes.VERIFY_O_AUTH, handleVerifyOAuth)];
                case 4:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(AuthActionTypes.REQUEST_LOGIN, handleLogin)];
                case 5:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(AuthActionTypes.REQUEST_LOGIN_WITH_TOKEN, handleLoginWithToken)];
                case 6:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(AuthActionTypes.REQUEST_LOGIN_WITH_APPLE, handleLoginWithApple)];
                case 7:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(AuthActionTypes.REQUEST_LOGIN_WITH_GOOGLE, handleLoginWithGoogle)];
                case 8:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(AuthActionTypes.REQUEST_LOGIN_WITH_FACEBOOK, handleLoginWithFacebook)];
                case 9:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(AuthActionTypes.REQUEST_LOGIN_WITH_XERO, handleLoginWithXero)];
                case 10:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(AuthActionTypes.REQUEST_SIGN_UP_WITH_APPLE, handleSignUpWithApple)];
                case 11:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(AuthActionTypes.REQUEST_SIGN_UP_WITH_GOOGLE, handleSignUpWithGoogle)];
                case 12:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(AuthActionTypes.REQUEST_SIGN_UP_WITH_FACEBOOK, handleSignUpWithFacebook)];
                case 13:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(AuthActionTypes.REQUEST_SIGN_UP_WITH_XERO, handleSignUpWithXero)];
                case 14:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(AuthActionTypes.REQUEST_REFRESH_AUTH_TOKEN, handleRefreshAuthToken)];
                case 15:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(AuthActionTypes.STORE_TOKENS, handleStoreTokens)];
                case 16:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(AuthActionTypes.LOGIN_SUCCESS, handleLoginSuccess)];
                case 17:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(AuthActionTypes.SIGN_UP_SUCCESS, handleSignUpSuccess)];
                case 18:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(AuthActionTypes.REFRESH_AUTH_TOKEN_SUCCESS, handleRefreshAuthTokenSuccess)];
                case 19:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(AuthActionTypes.LOGOUT, handleLogout)];
                case 20:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(AuthActionTypes.CLEAR_ANALYTICS_USER_ID, handleClearAnalyticsUserId)];
                case 21:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    };
};
export default analyticsSagaCreator;
