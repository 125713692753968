import customError from '../utils/customError';
import { selectors as appSelectors } from '../api/app';
import moment from 'moment-timezone';
import { captureException } from '@sentry/browser';
import {
  parseAddressArrayToString,
  parseCustomer,
} from '@/helpers/customerHelper';

/* ----------- DEPENDENCY INJECTION ---------- */
let store;
export function setStore(s) {
  store = s;
}

const TOKEN_KEY = 'payacav1';

/* ----------- EXCEPTIONS ---------- */
export const UnableToConnectException = customError(
  'UnableToConnectException',
  'Unable to connect to the server.'
);
export const ServerErrorException = customError(
  'ServerErrorException',
  'There was an error on the server.'
);
export const NotFoundException = customError(
  'NotFoundException',
  'Endpoint not found.'
);
export const AuthenticationException = customError(
  'AuthenticationException',
  'User is not authenticated.'
);
export const BadRequestException = customError(
  'BadRequestException',
  'Bad request sent.'
);
export const TimeoutException = customError(
  'TimeoutException',
  'The request timed out.'
);

/* ----------- PRIVATE FUNCTIONS ---------- */

const ADDRESS = process.env.REACT_APP_API_HOST;
const formatContactNumber = (phone) =>
  phone && phone[0] !== '+' ? `+${phone}` : phone;
const overridingErrors = [
  '/oauth/verify',
  '/oauth/authorise?allow=true',
  '/oauth/authorise?allow=false',
];

function getAuthHeader() {
  return 'Bearer ' + localStorage.getItem(TOKEN_KEY);
}

async function request(path, settings) {
  let response;
  try {
    response = await fetch(`${ADDRESS}${path}`, settings);
  } catch (e) {
    captureException(e);
    throw new UnableToConnectException();
  }

  if (
    response.status !== 200 &&
    overridingErrors.find((o) => response.url.endsWith(o))
  ) {
    throw {
      status: response.status,
      ...(await response.json()),
    };
  }
  if (response.status === 404)
    throw new BadRequestException(await response.json());
  if (response.status === 500)
    throw new BadRequestException(await response.json());
  if (response.status === 401) throw new AuthenticationException();
  if (response.status === 403) throw new AuthenticationException();
  if (response.status === 400)
    throw new BadRequestException(await response.json());
  if (response.status === 422)
    throw new BadRequestException(await response.json());
  if (response.status === 409)
    throw new BadRequestException(await response.json());

  return response;
}

async function get(path) {
  return await request(path, {
    headers: {
      Authorization: getAuthHeader(),
      'X-Native-App': false,
      'X-Simple-Job': true,
    },
  });
}

async function del(path) {
  return await request(path, {
    method: 'DELETE',
    headers: {
      Authorization: getAuthHeader(),
      'X-Native-App': false,
      'X-Simple-Job': true,
    },
  });
}

async function post(path, body) {
  return await request(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthHeader(),
      'X-Native-App': false,
      'X-Simple-Job': true,
    },
    body: JSON.stringify(body),
  });
}

async function put(path, body) {
  return await request(path, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: getAuthHeader(),
      'X-Native-App': false,
      'X-Simple-Job': true,
    },
    body: JSON.stringify(body),
  });
}

async function postFile(path, image) {
  const { file, fileName } = image;

  // Upload the image using the fetch and FormData APIs
  let formData = new FormData();
  formData.append('file', file, fileName);

  return await request(path, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      Authorization: getAuthHeader(),
    },
    body: formData,
  });
}

/* ----------- PUBLIC FUNCTIONS ----------- */

export async function signUp({
  email,
  password,
  firstName,
  lastName,
  contactNumber,
  referralToken,
  hasSubscribedToNewsletter,
  hasAgreedToTermsAndConditions,
}) {
  const response = await post('/users', {
    email,
    password,
    firstname: firstName,
    lastname: lastName,
    contactNumber: formatContactNumber(contactNumber),
    referraltoken: referralToken,
    agreedToPrivacy: hasAgreedToTermsAndConditions,
    newsletter: hasSubscribedToNewsletter,
  });
  return await response.json();
}

export async function sendPasswordResetEmail(emailAddress) {
  const response = await post('/forgot', {
    email: emailAddress,
  });
  return await response;
}

export async function registerUserDevice(deviceToken) {
  const response = await post('/users/devices', {
    deviceId: deviceToken,
  });

  return await response;
}

export async function getGlobalSettings() {
  const response = await get('/settings');

  return await response.json();
}

export async function getProfile() {
  const response = await get('/users/me');
  return await response.json();
}

export async function updateProfile({
  email,
  firstName,
  lastName,
  contactNumber,
}) {
  const response = await put('/users/me', {
    email,
    firstname: firstName,
    lastname: lastName,
    contactNumber: formatContactNumber(contactNumber),
  });
  return await response.json();
}

export async function updateNotificationSettings({
  enableAllNotifications,
  notificationSettings,
}) {
  const response = await put('/users/me', {
    enableAllNotifications,
    notificationSettings,
  });
  return await response.json();
}

export async function updateSignUpPreferences(
  agreedToPrivacy,
  subscribedToNewsletter
) {
  const response = await put('/users/me', {
    agreedToPrivacy,
    newsletter: subscribedToNewsletter,
  });
  return await response.json();
}

export async function updatePassword({ email, currentPassword, newPassword }) {
  const response = await put('/users/me/password', {
    email,
    password: currentPassword,
    newPassword: newPassword,
  });
  return await response.json();
}

export async function resendEmailVerification(email) {
  const response = await post('/verify', {
    email,
  });
  return await response;
}

export async function verifyAccountToken(token) {
  const response = await put(`/verify/${token}`);
  return await response;
}

export async function validResetToken(token) {
  const response = await get(`/reset/${token}`);
  return await response;
}

export async function resetPassword(token, passwords) {
  const response = await post(`/reset/${token}`, {
    ...passwords,
  });
  return await response;
}

export async function lookupPostcode(postcode) {
  const response = await get(`/addresses/lookup?postcode=${postcode}`);
  return await response.json();
}

export async function lookupAddress(addressId) {
  const response = await get(`/addresses/retrieve?id=${addressId}`);
  return await response.json();
}

export async function validateEmail(email) {
  const response = await get(`/validate/email?email=${email}`);
  return await response.json();
}

export async function getBlockedEmails() {
  const response = await get('/validate/blocked_outbound');
  return await response.json();
}

export async function validatePhone(phone) {
  const response = await get(`/validate/phone?phone=${phone}`);
  return await response.json();
}

export async function getCustomers() {
  const response = await get('/customers');
  return await response.json();
}

export async function createBusinessAccount(payload) {
  const response = await post('/accounts', {
    companyName: payload.name,
    legalBusinessName: payload.legalBusinessName,
    contactNumber: formatContactNumber(payload.contactNumber),
    email: payload.email,
  });
  return await response.json();
}

export async function uploadAccountLogo(accountId, imageFile) {
  try {
    const response = await postFile(`/accounts/${accountId}/logos`, imageFile);
    return await response.json();
  } catch (e) {
    console.log('error is:', e);
  }
}

export async function getBusinessAttachments() {
  const response = await get(`/accounts/businessAttachments`);
  return await response.json();
}

export async function manageBusinessAttachments(
  documentsToAdd,
  documentsToRemove
) {
  try {
    for (let i = 0; i < documentsToAdd.length; i++) {
      await uploadBusinessAttachments(documentsToAdd[i]);
    }
    for (let i = 0; i < documentsToRemove.length; i++) {
      await removeBusinessAttachments(documentsToRemove[i]);
    }
    return { success: true };
  } catch (e) {
    const err = JSON.parse(e.message);
    const getFailedFileNames = (docArray, failedDoc) => {
      let arr = docArray;
      const failedDocIdx = docArray.findIndex(
        (doc) => doc.fileName === failedDoc
      );
      return arr
        .splice(failedDocIdx, docArray.length - failedDocIdx)
        .map((d) => d.fileName);
    };

    let message = { remove: getFailedFileNames(documentsToRemove, err.remove) };
    if (err.upload) {
      message.upload = getFailedFileNames(documentsToAdd, err.upload);
    }
    throw new Error(JSON.stringify(message));
  }
}

export async function uploadBusinessAttachments(payload) {
  try {
    const response = await postFile(`/accounts/businessAttachments`, payload);
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ upload: payload.fileName }));
  }
}

export async function removeBusinessAttachments(payload) {
  try {
    const businessAttachmentId = payload.id;
    const response = await del(
      `/accounts/businessAttachments/${businessAttachmentId}`
    );
    return await response;
  } catch (e) {
    throw new Error(JSON.stringify({ remove: payload.fileName }));
  }
}
export async function getAccountTerms(accountId) {
  const response = await get(`/accounts/${accountId}/terms/`);
  return await response.json();
}

export async function manageTermsDocuments(
  accountId,
  documentsToAdd,
  documentsToRemove
) {
  try {
    for (let i = 0; i < documentsToAdd.length; i++) {
      await uploadTermsDocument(accountId, documentsToAdd[i]);
    }
    for (let i = 0; i < documentsToRemove.length; i++) {
      await removeTermsDocument(accountId, documentsToRemove[i]);
    }
    return { success: true };
  } catch (e) {
    const err = JSON.parse(e.message);
    const getFailedFileNames = (docArray, failedDoc) => {
      let arr = docArray;
      const failedDocIdx = docArray.findIndex(
        (doc) => doc.fileName === failedDoc
      );
      return arr
        .splice(failedDocIdx, docArray.length - failedDocIdx)
        .map((d) => d.fileName);
    };

    let message = { remove: getFailedFileNames(documentsToRemove, err.remove) };
    if (err.upload) {
      message.upload = getFailedFileNames(documentsToAdd, err.upload);
    }
    throw new Error(JSON.stringify(message));
  }
}

export async function uploadTermsDocument(accountId, payload) {
  try {
    const response = await postFile(`/accounts/${accountId}/terms`, payload);
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ upload: payload.fileName }));
  }
}

export async function removeTermsDocument(accountId, payload) {
  try {
    const termsDocumentId = payload.id;
    const response = await del(
      `/accounts/${accountId}/terms/${termsDocumentId}`
    );
    return await response;
  } catch (e) {
    throw new Error(JSON.stringify({ remove: payload.fileName }));
  }
}

export async function updateBusinessAccount(accountId, payload) {
  let address = undefined;

  const addressElements = [
    payload.firstLineAddress,
    payload.secondLineAddress,
  ].filter((x) => x !== undefined);

  if (addressElements.length) {
    address = addressElements.join(', ');
  }

  const response = await put(`/accounts/${accountId}/`, {
    accountName: payload.accountName,
    accountNumber: payload.accountNumber,
    address: address,
    brandColour: payload.brandColour,
    businessNotes: payload.businessNotes,
    city: payload.city,
    companyName: payload.businessName,
    contactNumber: formatContactNumber(payload.contactNumber),
    email: payload.email,
    logoUrl: payload.logoUrl,
    paymentTerms: payload.paymentTerms,
    postcode: payload.postcode,
    sendInvoiceReminderAfterDue3Days: payload.sendInvoiceReminderAfterDue3Days,
    sendInvoiceReminderAfterDue7Days: payload.sendInvoiceReminderAfterDue7Days,
    sendInvoiceReminderBeforeDue3Days:
      payload.sendInvoiceReminderBeforeDue3Days,
    sendInvoiceReminderWhenDue: payload.sendInvoiceReminderWhenDue,
    sortCode: payload.sortCode,
    vatNumber: payload.vatNumber,
    isCisSubcontractor: payload.isCisSubcontractor,
    cisDeductionRate: payload.cisDeductionRate,
    hideItemPrices: payload.hideItemPrices,
  });
  return await response.json();
}

export async function connectWithXero(code) {
  const response = await post('/accounts/connect_to_xero', {
    code,
  });
  return await response.json();
}

export async function importXeroContacts() {
  const response = await post('/accounts/xero/sync_contacts');
  return await response.json();
}

export async function importXeroItems() {
  const response = await post('/accounts/xero/sync_items');
  return await response.json();
}

export async function removeXeroConnection() {
  const response = await del('/accounts/xero');
  return await response.json();
}

export async function getXeroAccounts() {
  const response = await get('/accounts/xero/accounts');
  return await response.json();
}

export async function updateXeroSettings(payload) {
  const response = await put('/accounts/xero', payload);
  return await response.json();
}

export async function connectWithQuickbooks(payload) {
  const response = await post('/accounts/connect_to_quickbooks', payload);
  return await response.json();
}

export async function importQuickbooksCustomers() {
  const response = await post('/accounts/quickbooks/sync_customers');
  return await response.json();
}

export async function importQuickbooksItems() {
  const response = await post('/accounts/quickbooks/sync_items');
  return await response.json();
}

export async function removeQuickbooksConnection() {
  const response = await del('/accounts/quickbooks');
  return await response.json();
}

export async function manageSubscription() {
  const response = await post('/accounts/subscription/manage_subscription');
  return await response.json();
}

export async function connectWithStripe(code) {
  const response = await post('/accounts/connect_to_stripe', {
    code,
  });
  return await response.json();
}

export async function removeStripeConnection() {
  const response = await del('/accounts/stripe');
  return await response.json();
}

export async function createUpdateItemGroupsOnJob(jobId, itemGroups) {
  let formData = new FormData();
  itemGroups.forEach((itemGroup) => {
    itemGroup.jobLineItems.forEach((item) => {
      if (item.attachments) {
        item.attachments.forEach((itemAttachment) => {
          if (itemAttachment.file) {
            // file needs uploading
            formData.append(
              'attachmentsToUpload',
              itemAttachment.file,
              itemAttachment.fileName
            );
          }
        });
      }
    });
  });

  formData.append('jobItemGroups', JSON.stringify(itemGroups));
  const response = await request(`/jobs/${jobId}/line_items`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      Authorization: getAuthHeader(),
    },
    body: formData,
  });
  return await response;
}

export async function addAttachmentToJob(jobId, payload) {
  try {
    const response = await postFile(`/jobs/${jobId}/job_attachments`, payload);
    return await response.json();
  } catch (e) {
    console.log('error is:', e);
  }
}

export async function removeAttachmentFromJob(jobId, jobAttachmentId) {
  const response = await del(
    `/jobs/${jobId}/job_attachments/${jobAttachmentId}`
  );
  return await response;
}

export async function createJob(isProposition, dealId) {
  const payload = { dealId };
  if (isProposition) {
    let quoteValidUntilDays =
      localStorage.getItem('payaca-quoteValidFor') || '30';
    payload.quoteValidUntil = moment()
      .add(quoteValidUntilDays, 'days')
      .endOf('day');
    payload.status = 'new_quote';
  } else {
    let invoiceDueInDays = localStorage.getItem('payaca-invoiceDueIn') || '14';
    payload.invoiceDue = moment().add(invoiceDueInDays, 'days').endOf('day');
    payload.status = 'new_invoice';
  }

  const response = await post('/jobs', payload);
  return await response.json();
}

export async function getJob(jobId) {
  const response = await get(`/jobs/${jobId}`);
  return await response.json();
}

export async function getJobPayments(jobId) {
  const response = await get(`/jobs/${jobId}/job_payments`);
  return await response.json();
}

export async function getJobPreview(jobId) {
  const response = await get(`/jobs/${jobId}/preview_email_v2`);
  const data = await response.json();
  return data;
}

export async function updateJob(jobId, payload) {
  if (payload.quoteValidUntilDays) {
    localStorage.setItem('payaca-quoteValidFor', payload.quoteValidUntilDays);
  }
  if (payload.invoiceDueInDays) {
    localStorage.setItem('payaca-invoiceDueIn', payload.invoiceDueInDays);
  }
  const response = await put(`/jobs/${jobId}`, {
    job_reference: payload.reference,
    customReference: payload.customReference,
    userId: payload.user.id || null,
    customerId: payload.customer.id,
    quoteMessage: payload.quoteNotes,
    invoiceMessage: payload.invoiceNotes,
    status: payload.status,
    depositPercentage: payload.depositPercentage,
    depositAmount: Math.round(payload.depositAmount),
    minimumDepositPercentage: payload.minimumDepositPercentage,
    minimumDepositAmount: Math.round(payload.minimumDepositAmount),
    markupPercentage: payload.markupPercentage,
    markupAmount: Math.round(payload.markupAmount),
    discountPercentage: payload.discountPercentage,
    discountAmount: Math.round(payload.discountAmount),
    invoiceDue:
      payload.invoiceDueInDays &&
      new Date(moment().add(payload.invoiceDueInDays, 'days').endOf('day')),
    quoteValidUntil:
      payload.quoteValidUntilDays &&
      new Date(moment().add(payload.quoteValidUntilDays, 'days').endOf('day')),
    showFinanceOptions: payload.showFinanceOptions,
    showZeroPercentFinance: payload.showZeroPercentFinance,
    showSelinaFinance: payload.showSelinaFinance,
    showStripePaymentOption: payload.showStripePaymentOption,
    showBACSPaymentOption: payload.showBACSPaymentOption,
    jobDescription: payload.jobDescription,
    hideItemPrices: payload.hideItemPrices,
  });
  return await response.json();
}

export async function deleteJob(jobId) {
  const response = await del(`/jobs/${jobId}`);
  return await response;
}

export async function archiveJobs(jobIds) {
  const response = [];
  for (let i = 0; i < jobIds.length; i++) {
    const jobId = jobIds[i];
    const r = await put(`/jobs/${jobId}/archive`);
    response.push(r);
  }

  return await response;
}

export async function sendQuoteToCustomer(jobId, params) {
  const response = await post(`/jobs/${jobId}/send_quote_v2`, params);
  return response;
}

export async function archiveJob(jobId) {
  const response = await put(`/jobs/${jobId}/archive`);
  return response;
}

export async function unarchiveJob(jobId) {
  const response = await put(`/jobs/${jobId}/unarchive`);
  return response;
}

export async function acceptQuote(jobId) {
  const response = await put(`/jobs/${jobId}/accept`);
  return response;
}

export async function unacceptQuote(jobId) {
  const response = await put(`/jobs/${jobId}/unaccept`);
  return response;
}

export async function declineJob(jobId) {
  const response = await put(`/jobs/${jobId}/decline`);
  return response;
}

export async function markJobAsComplete(jobId) {
  const response = await post(
    `/job_finance_applications/${jobId}/mark_as_complete`
  );
  return response;
}

export async function convertJobToInvoice(jobId) {
  const response = await put(`/jobs/${jobId}/convert_to_invoice`);
  const data = await response.json();
  return data;
}

export async function sendInvoiceToCustomer(jobId, params) {
  const response = await post(`/jobs/${jobId}/send_invoice_v2`, params);
  return response;
}

export async function duplicateJob(jobId) {
  const response = await post(`/jobs/${jobId}/duplicate`);
  return await response.json();
}

export async function assignJob(jobId, userId) {
  const response = await put(`/jobs/${jobId}/assign`, {
    userId,
  });
  return response;
}

export async function unassignJob(jobId) {
  const response = await assignJob(jobId, null);
  return response;
}

export async function assignJobs(jobIds, userId) {
  const response = [];
  for (let i = 0; i < jobIds.length; i++) {
    const jobId = jobIds[i];
    const r = await assignJob(jobId, userId);
    response.push(r);
  }
  return response;
}

export async function unassignJobs(jobIds) {
  const response = [];
  for (let i = 0; i < jobIds.length; i++) {
    const jobId = jobIds[i];
    const r = await unassignJob(jobId);
    response.push(r);
  }
  return response;
}

export async function getAccountUsers() {
  const response = await get('/accounts/users');
  return await response.json();
}

export async function inviteUserToAccount(payload) {
  const response = await post('/accounts/users', {
    email: payload.email,
    firstname: payload.firstName,
    lastname: payload.lastName,
  });
  return response;
}

export async function reactivateUser(userId) {
  const response = await put(`/accounts/users/${userId}/reactivate`);
  return await response;
}

export async function validUserInviteToken(token) {
  const response = await get(`/invite/${token}`);
  return await response;
}

export async function registerInvitedUser(token, payload) {
  const response = await post(`/invite/${token}`, {
    password: payload.password,
    agreedToPrivacy: payload.agreeToPrivacy,
    newsletter: payload.subscribeToNewsletter,
  });
  return await response.json();
}

export async function deleteUserFromAccount(userId) {
  // deactivates the user from the current account
  const response = await put(`/accounts/users/${userId}/deactivate`);
  return await response;
}

export async function deleteUsersFromAccount(userIds) {
  const response = await put(`/accounts/users/bulk_deactivate`, {
    userIds: userIds,
  });
  return await response;
}

export async function updateUserAccountRole(userId, payload) {
  const response = await put(`/accounts/users/${userId}/update_role`, {
    admin: payload.setAdminRole,
  });
  return await response;
}

export async function updateUsersAccountRole(userIds, payload) {
  const response = [];
  for (let i = 0; i < userIds.length; i++) {
    const userId = userIds[i];
    const r = await updateUserAccountRole(userId, payload);
    response.push(r);
  }
  return await response;
}

export async function trackError(error) {
  console.log(error);
  return true;
}

export async function requestGetFinancePlans(includeDisabledPlans) {
  const response = await get(
    `/finance_plans?includeDisabledPlans=${includeDisabledPlans}`
  );
  return await response.json();
}

export async function requestUpdateFinancePlans(financePlans) {
  const response = await put(`/finance_plans`, { financePlans });
  return await response.json();
}

export async function requestGetMaintenance() {
  const response = await get('/maintenance');
  return await response.json();
}
