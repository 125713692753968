import React, { useEffect } from 'react';
import { JobTimelineEvents } from '@/ui/components';
import { useDispatch, useSelector } from 'react-redux';
import { actions as dealTimelineActions } from '@bit/payaca-tech.payaca-core.store.deal-timeline';
import MiniLoader from '@bit/payaca-tech.payaca-core.component.mini-loader';
import './DealTimeline.sass';
var DealTimeline = function (_a) {
    var dealId = _a.dealId;
    var dispatch = useDispatch();
    useEffect(function () {
        dispatch(dealTimelineActions.requestGetDealTimelineEvents(dealId));
        return function () {
            dispatch(dealTimelineActions.clearDealTimelineEvents());
        };
    }, [dealId]);
    var dealTimelineEvents = useSelector(function (state) {
        return state.dealTimeline.jobTimelineEvents;
    });
    return !dealTimelineEvents ? (React.createElement("div", { className: 'loading-container' },
        React.createElement(MiniLoader, null))) : (React.createElement(JobTimelineEvents, { jobTimelineEvents: dealTimelineEvents })
    // <div className={'deal-timeline-line-container'}>
    //   <div className={'deal-timeline-line'} />
    //   <JobTimelineEvents jobTimelineEvents={dealTimelineEvents} />
    // </div>
    );
};
export default DealTimeline;
