import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BasicField } from '@payaca/components/basicField';
import { DateField } from '@payaca/components/dateField';
import { PhoneNumberField } from '@payaca/components/phoneNumberField';
import { AddressInputControl } from '@payaca/components/addressInputControl';
import { TitledContentPanel } from '@payaca/components/titledContentPanel';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import './DirectorFormSubsection.css';
var DirectorFormSubSection = function (_a) {
    var _b;
    var title = _a.title, director = _a.director, _c = _a.isSoleTrader, isSoleTrader = _c === void 0 ? false : _c, fieldNamePrefix = _a.fieldNamePrefix, touchedState = _a.touchedState, validationState = _a.validationState, onTouch = _a.onTouch, onChange = _a.onChange, onRemove = _a.onRemove;
    var nameFieldName = useMemo(function () {
        return fieldNamePrefix ? fieldNamePrefix + ".name" : 'name';
    }, [fieldNamePrefix]);
    var dateOfBirthFieldName = useMemo(function () {
        return fieldNamePrefix ? fieldNamePrefix + ".dateOfBirth" : 'dateOfBirth';
    }, [fieldNamePrefix]);
    var titleWithinCompanyFieldName = useMemo(function () {
        return fieldNamePrefix
            ? fieldNamePrefix + ".titleWithinCompany"
            : 'titleWithinCompany';
    }, [fieldNamePrefix]);
    var telephoneNumberFieldName = useMemo(function () {
        return fieldNamePrefix
            ? fieldNamePrefix + ".telephoneNumber"
            : 'telephoneNumber';
    }, [fieldNamePrefix]);
    var emailAddressFieldName = useMemo(function () {
        return fieldNamePrefix ? fieldNamePrefix + ".emailAddress" : 'emailAddress';
    }, [fieldNamePrefix]);
    var homeAddressFieldName = useMemo(function () {
        return fieldNamePrefix ? fieldNamePrefix + ".homeAddress" : 'homeAddress';
    }, [fieldNamePrefix]);
    var previousHomeAddressFieldName = useMemo(function () {
        return fieldNamePrefix
            ? fieldNamePrefix + ".previousHomeAddress"
            : 'previousHomeAddress';
    }, [fieldNamePrefix]);
    var titleElement = useMemo(function () {
        return (React.createElement("div", { className: "flex-container flex-center" },
            React.createElement("span", { className: "flex-grow" },
                title,
                director.name && " - " + director.name),
            onRemove && React.createElement(FontAwesomeIcon, { icon: faTimes, onClick: onRemove })));
    }, [title, director, onRemove]);
    return (React.createElement(TitledContentPanel, { className: "director-form-subsection", hasBoxShadow: true, title: titleElement },
        React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[nameFieldName]) || false, validationResult: validationState && validationState[nameFieldName] },
            React.createElement(BasicField, { label: "Full name", isRequired: true, value: director.name, name: nameFieldName, onChange: onChange, onTouch: onTouch, styleVariant: InputStyleVariant.OUTSIZE })),
        React.createElement("div", { className: "flex-container" },
            React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[dateOfBirthFieldName]) || false, validationResult: validationState && validationState[dateOfBirthFieldName] },
                React.createElement(DateField, { label: "Date of birth", isRequired: true, value: (_b = director.dateOfBirth) !== null && _b !== void 0 ? _b : undefined, name: dateOfBirthFieldName, onChange: onChange, onTouch: onTouch, additionalPickerProps: {
                        openTo: 'year',
                        maxDate: new Date(),
                    }, styleVariant: InputStyleVariant.OUTSIZE })),
            !isSoleTrader && (React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[titleWithinCompanyFieldName]) ||
                    false, validationResult: validationState && validationState[titleWithinCompanyFieldName] },
                React.createElement(BasicField, { label: "Title within company", isRequired: true, value: director.titleWithinCompany, name: titleWithinCompanyFieldName, onChange: onChange, onTouch: onTouch, styleVariant: InputStyleVariant.OUTSIZE })))),
        React.createElement("div", { className: "flex-container" },
            React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[telephoneNumberFieldName]) || false, validationResult: validationState && validationState[telephoneNumberFieldName] },
                React.createElement(PhoneNumberField, { iconBefore: faPhone, isRequired: true, label: "Contact telephone number", value: director.telephoneNumber, name: telephoneNumberFieldName, onChange: onChange, styleVariant: InputStyleVariant.OUTSIZE, onTouch: onTouch })),
            React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[emailAddressFieldName]) || false, validationResult: validationState && validationState[emailAddressFieldName] },
                React.createElement(BasicField, { label: "Email address", isRequired: true, value: director.emailAddress, name: emailAddressFieldName, onChange: onChange, onTouch: onTouch, styleVariant: InputStyleVariant.OUTSIZE }))),
        React.createElement("div", { className: "flex-container" },
            React.createElement(TitledContentPanel, { title: "Home address", hasBoxShadow: true },
                React.createElement(AddressInputControl, { address: director.homeAddress, onChange: onChange, onTouch: onTouch, isRequired: true, fieldNamePrefix: homeAddressFieldName, validationState: validationState, touchedState: touchedState, styleVariant: InputStyleVariant.OUTSIZE })),
            React.createElement(TitledContentPanel, { title: "Previous address (if at current address for less than 3 years)", hasBoxShadow: true },
                React.createElement(AddressInputControl, { address: director.previousHomeAddress, onChange: onChange, onTouch: onTouch, fieldNamePrefix: previousHomeAddressFieldName, validationState: validationState, touchedState: touchedState, styleVariant: InputStyleVariant.OUTSIZE })))));
};
export default DirectorFormSubSection;
