// ======================================================================================================
// Social login button
// - Button for socials, on register/login pages
// ======================================================================================================
import React, { useCallback, useMemo, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { GoogleLogin } from 'react-google-login';
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import get from 'lodash.get';
import { LoginProvider } from '@/helpers/providerHelper';
import { actions as authActions } from '@bit/payaca-tech.payaca-core.store.auth';
import './SocialLoginButton.sass';
import { Button, ButtonStyleVariant, ButtonColourVariant, } from '@payaca/components/button';
var XERO_CLIENT_ID = process.env.REACT_APP_XERO_CLIENT_ID;
var GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
var FACEBOOK_CLIENT_ID = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
var SocialLoginButton = function (_a) {
    var provider = _a.provider, buttonPreText = _a.buttonPreText, referralToken = _a.referralToken, loginServiceCallback = _a.loginServiceCallback;
    var dispatch = useDispatch();
    var _b = useState(false), isSubmitted = _b[0], setIsSubmitted = _b[1];
    var isLoggingIn = useSelector(function (state) { return state.auth.isLoggingIn; });
    var isProcessing = useMemo(function () { return isSubmitted && isLoggingIn; }, [isSubmitted, isLoggingIn]);
    var loginWithFacebook = useCallback(function (data) {
        var name = data.name.split(' ');
        var payload = {
            referraltoken: referralToken,
            publicId: data.id,
            name: {
                givenName: name[0],
                familyName: name[1],
            },
            email: data.email,
            picture: get(data, ['picture', 'data', 'url'], ''),
        };
        setIsSubmitted(true);
        dispatch(authActions.requestLoginWithFacebook(payload));
    }, [referralToken, loginServiceCallback, dispatch]);
    var loginWithGoogle = useCallback(function (data) {
        var profileObj = get(data, 'profileObj', {});
        var payload = {
            referraltoken: referralToken,
            publicId: data.googleId,
            name: {
                givenName: profileObj.givenName,
                familyName: profileObj.familyName,
            },
            email: profileObj.email,
            picture: profileObj.imageUrl,
        };
        setIsSubmitted(true);
        dispatch(authActions.requestLoginWithGoogle(payload));
    }, [referralToken, loginServiceCallback, dispatch]);
    var facebookCallback = useCallback(function (response) {
        if (response && !response.status) {
            loginWithFacebook(response);
        }
    }, [loginWithFacebook]);
    var buttonText = useMemo(function () {
        return buttonPreText + " " + provider;
    }, [buttonPreText, provider]);
    return (React.createElement("div", { className: "social-login-button-container" },
        provider === LoginProvider.GOOGLE ? (React.createElement(GoogleLogin, { clientId: GOOGLE_CLIENT_ID || '', onSuccess: loginWithGoogle, cookiePolicy: 'single_host_origin', render: function (renderProps) { return (React.createElement(Button, { hasBoxShadow: true, isOutlined: true, styleVariant: ButtonStyleVariant.OUTSIZE, colourVariant: ButtonColourVariant.WHITE, onClick: function () { return !isProcessing && renderProps.onClick(); }, isProcessing: isProcessing },
                React.createElement("img", { src: require('./google-logo.svg').default, style: {
                        maxWidth: 20,
                    } }),
                React.createElement("span", null, buttonText))); } })) : null,
        provider === LoginProvider.FACEBOOK ? (React.createElement(FacebookLogin, { appId: FACEBOOK_CLIENT_ID || '', callback: facebookCallback, fields: "name, email, picture", render: function (renderProps) { return (React.createElement(Button, { isOutlined: true, isProcessing: isProcessing, hasBoxShadow: true, styleVariant: ButtonStyleVariant.OUTSIZE, colourVariant: ButtonColourVariant.WHITE, onClick: function () { return !isProcessing && renderProps.onClick(); } },
                React.createElement(FontAwesomeIcon, { icon: faFacebook }),
                React.createElement("span", null, buttonText))); } })) : null,
        provider === LoginProvider.APPLE && (React.createElement("div", { id: "appleid-signin", "data-color": "black", "data-border": "true", "data-type": "sign in", 
            // @ts-ignore
            onClick: function () { return window.AppleID.auth.signIn(); } })),
        provider === LoginProvider.XERO && (React.createElement("a", { href: "https://login.xero.com/identity/connect/authorize?response_type=code&client_id=" + XERO_CLIENT_ID + "&redirect_uri=" + window.location.origin + "/xero/callback&scope=openid profile email\n          ", style: { textDecoration: 'none' } },
            React.createElement(Button, { isOutlined: true, isProcessing: isProcessing, hasBoxShadow: true, styleVariant: ButtonStyleVariant.OUTSIZE, colourVariant: ButtonColourVariant.WHITE },
                React.createElement("img", { src: require('./xero-logo.png').default, style: {
                        maxWidth: 20,
                    } }),
                React.createElement("span", null, buttonText))))));
};
export default SocialLoginButton;
