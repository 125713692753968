import React, { useCallback, useEffect, useState, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DoneIcon from '@material-ui/icons/Done';
import { actions as usersActions } from '@/api/users';
import { ConnectionControl } from '@/ui/components';
import StripeLogo from './stripe-logo.svg';
var STRIPE_CLIENT_ID = process.env.REACT_APP_STRIPE_CLIENT_ID;
var StripeConnectionControl = function () {
    var dispatch = useDispatch();
    var _a = useState(), checkStripeStatusInterval = _a[0], setCheckStripeStatusInterval = _a[1];
    var isConnectedToStripe = useSelector(function (state) {
        return state.users.myProfile.accounts[0].connectedToStripe;
    });
    var isAdmin = useSelector(function (state) {
        return state.users.myProfile.isAdmin;
    });
    useEffect(function () {
        isConnectedToStripe &&
            checkStripeStatusInterval &&
            clearInterval(checkStripeStatusInterval);
        setCheckStripeStatusInterval(undefined);
    }, [isConnectedToStripe, checkStripeStatusInterval]);
    var handleCheckStripeStatus = useCallback(function () {
        checkStripeStatusInterval && clearInterval(checkStripeStatusInterval);
        setCheckStripeStatusInterval(setInterval(function () { return dispatch(usersActions.getProfile()); }, 3000));
    }, [checkStripeStatusInterval, dispatch]);
    return (React.createElement(ConnectionControl, { connectionName: "Stripe", isConnected: isConnectedToStripe, allowConnectDisconnect: isAdmin, onAddConnection: function () {
            handleCheckStripeStatus();
            window.location.href = "https://connect.stripe.com/oauth/authorize?response_type=code&client_id=" + STRIPE_CLIENT_ID + "&scope=read_write&redirect_uri=" + window.location.origin + "/enableCustomerPayments";
        }, onRemoveConnection: function (onConnectionRemoved) {
            return dispatch(usersActions.removeStripeConnection(onConnectionRemoved));
        }, logo: StripeLogo },
        React.createElement(React.Fragment, null, !isConnectedToStripe ? (React.createElement("div", null,
            React.createElement("div", null, "Accept card payments with Stripe"),
            React.createElement("div", null,
                React.createElement("div", { style: { marginBottom: '10px' } }, "Stripe is a secure payment platform, trusted by over a million businesses all over the world."),
                React.createElement("div", { style: { marginBottom: '10px' } }, "If you already have a Stripe account you can connect to it or if you don\u2018t you can create one."),
                React.createElement("div", { style: { textAlign: 'left', marginBottom: '10px' } },
                    React.createElement("div", null,
                        React.createElement(DoneIcon, { style: { fontSize: 15 } }),
                        " Make it easy for customers to pay fast with a button on your quotes and invoices."),
                    React.createElement("div", null,
                        React.createElement(DoneIcon, { style: { fontSize: 15 } }),
                        " Fast, easy setup"),
                    React.createElement("div", null,
                        React.createElement(DoneIcon, { style: { fontSize: 15 } }),
                        " Fees for European cards are 1.4% +20p, you can find out more",
                        ' ',
                        React.createElement("a", { href: "https://stripe.com/gb/pricing", target: "_blank", rel: "noopener noreferrer" }, "here"),
                        "."))))) : (React.createElement("p", null, "Your stripe account has successfully been connected to Payaca")))));
};
export default StripeConnectionControl;
