import React, { Component } from 'react';

import WalkthroughStep from './WalkthroughStep';

import FinanceConnecterIcon from '@/assets/images/finance-connecter.svg';

import './Walkthrough.css';

export default class Walkthrough extends Component {
  renderWalkthrough() {
    return this.props.steps.map(({ img, title, description }, i) => {
      const reversed = i % 2 !== 0;
      return (
        <div key={i} className="walkthrough-step-wrapper">
          {reversed && (
            <img
              src={FinanceConnecterIcon}
              className="connecter-dashed"
              alt=""
            />
          )}
          <WalkthroughStep
            img={img}
            title={title}
            description={description}
            reversed={reversed}
          />
        </div>
      );
    });
  }

  render() {
    return (
      <div className="walkthrough-wrapper">
        {this.renderWalkthrough()}
        <div className="connecter"> </div>
      </div>
    );
  }
}
