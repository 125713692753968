import React from 'react';
import { default as MaterialUiCheckbox } from '@material-ui/core/Checkbox';
import './Checkbox.sass';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var Checkbox = function (_a) {
    var isChecked = _a.isChecked, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, colour = _a.colour, onChange = _a.onChange;
    return (React.createElement("div", { className: "checkbox" },
        React.createElement(MaterialUiCheckbox, { color: "default", checked: isChecked, onChange: onChange, inputProps: { 'aria-label': 'checkbox' }, disabled: isDisabled, checkedIcon: React.createElement("div", { className: "checkmark-wrapper" },
                React.createElement(FontAwesomeIcon, { icon: faCheck })), icon: React.createElement("div", { className: "checkmark-wrapper" }) })));
};
export default Checkbox;
