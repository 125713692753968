var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useState, } from 'react';
import { useDispatch } from 'react-redux';
import ListedCustomersTable from '../listedCustomersTable/ListedCustomersTable';
import { actions as customerActions } from '@bit/payaca-tech.payaca-core.store.customer';
import './SelectCustomerControl.css';
import { BasicField } from '@payaca/components/basicField';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import { ContentPanel } from '@payaca/components/contentPanel';
var SelectCustomerControl = function (_a) {
    var onSelectCustomer = _a.onSelectCustomer;
    var dispatch = useDispatch();
    var _b = useState({
        pageSize: 20,
        pageNumber: 1,
        searchTerm: '',
    }), getListedCustomersRequestData = _b[0], setGetListedCustomersRequestData = _b[1];
    useEffect(function () {
        requestGetListedCustomersPage();
    }, [getListedCustomersRequestData]);
    var requestGetListedCustomersPage = useCallback(function () {
        dispatch(customerActions.requestGetListedCustomersPage(getListedCustomersRequestData));
    }, [getListedCustomersRequestData, dispatch]);
    var onSelectPage = useCallback(function (pageNumber) {
        setGetListedCustomersRequestData(function (getListedCustomersRequestData) {
            return __assign(__assign({}, getListedCustomersRequestData), { pageNumber: pageNumber });
        });
    }, []);
    var _c = useState(), searchTermTimeout = _c[0], setSearchTermTimeout = _c[1];
    var onSearchTermChange = useCallback(function (searchTerm) {
        setGetListedCustomersRequestData(function (getListedCustomersRequestData) {
            return __assign(__assign({}, getListedCustomersRequestData), { pageNumber: 1, searchTerm: searchTerm });
        });
    }, []);
    var onSearchTermType = useCallback(function (value) {
        searchTermTimeout && clearTimeout(searchTermTimeout);
        setSearchTermTimeout(setTimeout(function () {
            onSearchTermChange(value.searchTerm);
        }, 500));
    }, [searchTermTimeout, onSearchTermChange]);
    return (React.createElement("div", { className: "select-customer-control" },
        React.createElement("div", { className: "search-term-field-container" },
            React.createElement(BasicField, { styleVariant: InputStyleVariant.OUTSIZE, name: "searchTerm", onChange: onSearchTermType, iconAfter: faSearch, additionalInputProps: {
                    placeholder: 'Search',
                } })),
        React.createElement(ContentPanel, null,
            React.createElement(ListedCustomersTable, { onClickRow: onSelectCustomer, onSelectPage: onSelectPage }))));
};
export default SelectCustomerControl;
