import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actions as lineItemGroupActions } from '@bit/payaca-tech.payaca-core.store.line-item-groups';
import './ListedLineItemGroups.sass';
import ListedLineItemGroupTile from '../listedLineItemGroupTile/ListedLineItemGroupTile';
import { ResponsiveViewWrapper } from '@payaca/components/responsiveViewWrapper';
import { EmptyState } from '..';
var ListedLineItemGroups = function (_a) {
    var onLineItemGroupClick = _a.onLineItemGroupClick;
    var dispatch = useDispatch();
    var history = useHistory();
    var lineItemGroups = useSelector(function (state) { return state.lineItemGroups.lineItemGroups; });
    var listedLineItemGroups = useMemo(function () {
        return Object.values(lineItemGroups)
            .map(function (x) { return x.entity; })
            .filter(function (x) { return !!x; });
    }, [lineItemGroups]);
    useEffect(function () {
        dispatch(lineItemGroupActions.requestGetLineItemGroups());
    }, []);
    return (React.createElement(ResponsiveViewWrapper, { className: "listed-line-item-groups", downBreakpointSm: 1000, downBreakpointXs: 600 },
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: "listed-line-item-groups-inner" }, listedLineItemGroups.map(function (lineItemGroup, index) {
                return (React.createElement(ListedLineItemGroupTile, { onClick: function () { return onLineItemGroupClick(lineItemGroup.id); }, key: "listed-line-item-group-tile-" + index, lineItemGroupId: lineItemGroup.id }));
            })),
            !(listedLineItemGroups === null || listedLineItemGroups === void 0 ? void 0 : listedLineItemGroups.length) && (React.createElement(EmptyState, { configName: 'itemgroups', arrowIndication: false })))));
};
export default ListedLineItemGroups;
