import React, { useCallback } from 'react';
import Switch from '../switch/Switch';
import FieldLabel from '../fieldLabel/FieldLabel';
import './SwitchField.sass';
var SwitchField = function (_a) {
    var name = _a.name, _b = _a.value, value = _b === void 0 ? false : _b, label = _a.label, description = _a.description, isDisabled = _a.isDisabled, isRequired = _a.isRequired, onChange = _a.onChange, onTouch = _a.onTouch;
    var onSwitchChange = useCallback(function () {
        var _a;
        onChange && onChange((_a = {}, _a[name] = !value, _a));
        onTouch && onTouch(name);
    }, [onChange, onTouch, value, name]);
    return (React.createElement("div", { className: "switch-field" },
        React.createElement("div", { className: "switch-wrapper" },
            React.createElement(Switch, { isChecked: value, isDisabled: isDisabled, onChange: onSwitchChange })),
        React.createElement(FieldLabel, { label: label, description: description, isRequired: isRequired })));
};
export default SwitchField;
