import React from 'react';
import { CompanySettings, CompanySettingsSaveButton } from '@/ui/components';
import { Modal } from '@payaca/components/modal';
import './BusinessSettingsModal.css';
var BusinessSettingsModal = function (_a) {
    var open = _a.open, onClose = _a.onClose;
    return (React.createElement(Modal, { title: "My Company", isOpen: open, onClose: function () { return onClose(); }, size: "md", className: "business-settings", actions: React.createElement(CompanySettingsSaveButton, { onSaveSuccess: function () { return onClose(); }, isModal: true, buttonTitle: "Save and close" }) },
        React.createElement(CompanySettings, null)));
};
export default BusinessSettingsModal;
