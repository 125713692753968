var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useCallback, useMemo, useState, } from 'react';
import { v4 as uuidv4 } from 'uuid';
import FileUploadField from '../fileUploadField/FileUploadField';
import MiniLoader from '../miniLoader/MiniLoader';
import PersistedDragDropFileControl from './PersistedDragDropFileControl';
import PersistedFileControl from './PersistedFileControl';
import './FileUploadPersistRemoveControl.sass';
var FileUploadPersistRemoveControl = function (_a) {
    var acceptFileTypes = _a.acceptFileTypes, _b = _a.allowFileNameModification, allowFileNameModification = _b === void 0 ? false : _b, _c = _a.allowMultipleUploads, allowMultipleUploads = _c === void 0 ? false : _c, description = _a.description, label = _a.label, _d = _a.isDisabled, isDisabled = _d === void 0 ? false : _d, _e = _a.isRequired, isRequired = _e === void 0 ? false : _e, persistedFiles = _a.persistedFiles, persistFile = _a.persistFile, persistFileName = _a.persistFileName, removePersistedFile = _a.removePersistedFile, _f = _a.showAsDragAndDrop, showAsDragAndDrop = _f === void 0 ? false : _f, _g = _a.showUploadImageButton, showUploadImageButton = _g === void 0 ? false : _g;
    var _h = useState([]), persistingFiles = _h[0], setPersistingFiles = _h[1];
    var _j = useState([]), removingFileIdentifiers = _j[0], setRemovingFileIdentifiers = _j[1];
    var _k = useState([]), persistingFileNameIdentifiers = _k[0], setPersistingFileNameIdentifiers = _k[1];
    var handlePersistFileName = useCallback(function (fileIdentifier, fileName) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!persistFileName)
                        return [2 /*return*/];
                    setPersistingFileNameIdentifiers(function (persistingFileNameIdentifiers) { return __spreadArrays(persistingFileNameIdentifiers, [
                        fileIdentifier,
                    ]); });
                    // TODO make this persist filename instead of removepersistedfile
                    return [4 /*yield*/, persistFileName(fileIdentifier, fileName).then(function () {
                            setPersistingFileNameIdentifiers(function (persistingFileNameIdentifiers) { return __spreadArrays(persistingFileNameIdentifiers.filter(function (persistingFileNameIdentifier) {
                                return persistingFileNameIdentifier !== fileIdentifier;
                            })); });
                        })];
                case 1:
                    // TODO make this persist filename instead of removepersistedfile
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [persistFileName]);
    var handlePersistFile = useCallback(function (file) { return __awaiter(void 0, void 0, void 0, function () {
        var fileIdentifier;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    fileIdentifier = uuidv4();
                    setPersistingFiles(function (persistingFiles) { return __spreadArrays(persistingFiles, [
                        {
                            fileName: file.name,
                            identifier: fileIdentifier,
                            fileSize: file.size,
                        },
                    ]); });
                    return [4 /*yield*/, persistFile(file).then(function () {
                            setPersistingFiles(function (persistingFiles) { return __spreadArrays(persistingFiles.filter(function (persistingFile) {
                                return persistingFile.identifier !== fileIdentifier;
                            })); });
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [persistFile]);
    var handleRemoveFile = useCallback(function (fileIdentifier) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setRemovingFileIdentifiers(function (removingFileIdentifiers) { return __spreadArrays(removingFileIdentifiers, [
                        fileIdentifier,
                    ]); });
                    return [4 /*yield*/, removePersistedFile(fileIdentifier).then(function () {
                            setRemovingFileIdentifiers(function (removingFileIdentifiers) { return __spreadArrays(removingFileIdentifiers.filter(function (removingFileIdentifier) {
                                return removingFileIdentifier !== fileIdentifier;
                            })); });
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [removePersistedFile]);
    var persistFromFileList = useCallback(function (fileList) {
        var fileArray = Array.prototype.slice.call(fileList);
        fileArray.map(function (file) { return handlePersistFile(file); });
    }, [handlePersistFile]);
    var onChange = useCallback(function (value) {
        var fileList = value.fileList;
        if (fileList) {
            persistFromFileList(fileList);
        }
    }, [persistFromFileList]);
    var persistingFilesElement = useMemo(function () {
        if (persistingFiles.length === 0) {
            return null;
        }
        return (React.createElement("ul", { className: "persisting-files" }, persistingFiles.map(function (persistingFile, index) {
            return (React.createElement("li", { key: index }, showAsDragAndDrop ? (React.createElement(PersistedDragDropFileControl, { file: persistingFile, isUploading: true })) : (React.createElement(React.Fragment, null,
                React.createElement(MiniLoader, null),
                " ",
                React.createElement("span", null, persistingFile.fileName)))));
        })));
    }, [persistingFiles, showAsDragAndDrop]);
    var persistedFilesElement = useMemo(function () {
        if (persistedFiles.length === 0) {
            return null;
        }
        return (React.createElement("ul", { className: "persisted-files" }, persistedFiles.map(function (persistedFile, index) {
            var isRemoving = removingFileIdentifiers.includes(persistedFile.identifier);
            var isPersistingFileName = persistingFileNameIdentifiers.includes(persistedFile.identifier);
            return (React.createElement("li", { key: index }, showAsDragAndDrop ? (React.createElement(PersistedDragDropFileControl, { file: persistedFile, removeFile: function () { return handleRemoveFile(persistedFile.identifier); }, isRemoving: isRemoving })) : (React.createElement(PersistedFileControl, { persistedFile: persistedFile, isDisabled: isDisabled, allowFileNameModification: allowFileNameModification, isRemoving: isRemoving, isPersistingFileName: isPersistingFileName, persistFileName: function (fileName) {
                    handlePersistFileName(persistedFile.identifier, fileName);
                }, removeFile: function () { return handleRemoveFile(persistedFile.identifier); } }))));
        })));
    }, [
        persistedFiles,
        isDisabled,
        handleRemoveFile,
        removingFileIdentifiers,
        persistingFileNameIdentifiers,
        allowFileNameModification,
        handlePersistFileName,
        showAsDragAndDrop,
    ]);
    var showFileUploadField = useMemo(function () {
        return !(showAsDragAndDrop &&
            !allowMultipleUploads &&
            (persistedFiles.length || persistingFiles.length));
    }, [
        allowMultipleUploads,
        persistedFiles,
        persistingFiles,
        showAsDragAndDrop,
    ]);
    return (React.createElement("div", { className: "file-upload-persist-remove-control" + (showAsDragAndDrop ? ' drag-and-drop' : '') },
        showFileUploadField && (React.createElement(FileUploadField, { name: "fileList", label: label, description: description, isDisabled: isDisabled || (!allowMultipleUploads && !!persistedFiles.length), isRequired: isRequired, allowMultipleUploads: allowMultipleUploads, acceptFileTypes: acceptFileTypes, onChange: onChange, showAsDragAndDrop: showAsDragAndDrop, showUploadImageButton: showUploadImageButton })),
        React.createElement("div", { className: "file-list-container" },
            persistingFilesElement,
            persistedFilesElement)));
};
export default FileUploadPersistRemoveControl;
