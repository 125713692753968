import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as authActions } from '@bit/payaca-tech.payaca-core.store.auth';
import { ValidatedForm } from '@payaca/components';
import { BasicField } from '@payaca/components/basicField';
import { PrivateField } from '@payaca/components/privateField';
import { ErrorMessage } from '@payaca/components/feedbackMessage';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import { getEmailFieldValidator, getIsRequiredFieldValidator, getLengthFieldValidator, } from '@payaca/helpers/fieldValidationHelper';
import './LoginForm.sass';
var LoginForm = function (_a) {
    var onLoginCallback = _a.onLoginCallback;
    var dispatch = useDispatch();
    var _b = useState(false), isSubmitted = _b[0], setIsSubmitted = _b[1];
    var _c = useState(), loginError = _c[0], setLoginError = _c[1];
    var isLoggingIn = useSelector(function (state) { return state.auth.isLoggingIn; });
    var isLoggedInSuccessfully = useSelector(function (state) { return state.auth.isLoggedInSuccessfully; });
    var _d = useState({}), formState = _d[0], setFormState = _d[1];
    useEffect(function () {
        if (isSubmitted && !isLoggingIn) {
            if (isLoggedInSuccessfully) {
                onLoginCallback && onLoginCallback();
            }
            else {
                setLoginError('Email or password not recognised.');
            }
        }
    }, [isSubmitted, isLoggingIn, isLoggedInSuccessfully, onLoginCallback]);
    var onSubmit = useCallback(function () {
        if (isLoggingIn)
            return;
        setLoginError(undefined);
        setIsSubmitted(true);
        console.log(formState);
        dispatch(authActions.requestLogin(formState.emailAddress, formState.password));
    }, [dispatch, formState, isLoggingIn]);
    var fieldValidators = useMemo(function () {
        var isRequiredFieldValidator = getIsRequiredFieldValidator();
        return {
            emailAddress: [isRequiredFieldValidator, getEmailFieldValidator()],
            password: [isRequiredFieldValidator, getLengthFieldValidator({ min: 6 })],
        };
    }, []);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        setFormState(formState);
        return (React.createElement(React.Fragment, null,
            React.createElement(BasicField, { styleVariant: InputStyleVariant.OUTSIZE, label: 'Email', value: formState.emailAddress, name: "emailAddress", onChange: onFieldChange, onTouch: onFieldTouch }),
            React.createElement(PrivateField, { styleVariant: InputStyleVariant.OUTSIZE, label: 'Password', value: formState.password, name: "password", onChange: onFieldChange, onTouch: onFieldTouch }),
            React.createElement(Button, { type: "submit", isDisabled: !isValid, isProcessing: isLoggingIn, styleVariant: ButtonStyleVariant.OUTSIZE }, "Log in")));
    }, [onSubmit, isLoggingIn, loginError]);
    return (React.createElement("div", { className: "login-form-container" },
        React.createElement(ValidatedForm, { onFormSubmit: function (e) {
                e.preventDefault();
                onSubmit();
            }, renderFormContents: renderFormContents, fieldValidators: fieldValidators }),
        loginError && React.createElement(ErrorMessage, { message: loginError })));
};
export default LoginForm;
