import React from 'react';
import './JobTimelineEvents.sass';
import { JobTimelineEvent as JobTimelineEventComponent } from '@/ui/components';
var JobTimelineEvents = function (_a) {
    var jobTimelineEvents = _a.jobTimelineEvents;
    return (React.createElement("div", { className: "job-timeline-events" },
        React.createElement("div", { className: "timeline" }),
        React.createElement("div", { className: "job-timeline-events-container" }, jobTimelineEvents.map(function (jobTimelineEvent, j) { return (React.createElement(JobTimelineEventComponent, { key: j, jobTimelineEvent: jobTimelineEvent })); }))));
};
export default JobTimelineEvents;
