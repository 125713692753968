import React from 'react';
import './ProcessFlow.sass';
var ProcessFlow = function (_a) {
    var processFlowSteps = _a.processFlowSteps;
    return (React.createElement("div", { className: "process-flow" },
        React.createElement("div", { className: "process-flow-inner" }, processFlowSteps.map(function (step, index) {
            return (React.createElement("div", { className: "process-flow-step detail", key: index },
                React.createElement("div", { className: "step-indicator-container" },
                    React.createElement("div", { className: "step-indicator" }, step.icon)),
                React.createElement("span", { className: "step-description" }, step.stepDescription)));
        }))));
};
export default ProcessFlow;
