import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './HeaderInformationResourcesNavigation.sass';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import ContactUsModal from '../contactUsModal/ContactUsModal';
var HeaderInformationResourcesNavigation = function () {
    var history = useHistory();
    var _a = useState(false), showContactUsModal = _a[0], setShowContactUsModal = _a[1];
    var accountAccessInformation = useSelector(function (state) { return state.account.accountAccessInformation; });
    var trialPeriodIsExpired = useMemo(function () {
        return accountAccessInformation
            ? !accountAccessInformation.hasActiveSubscription &&
                accountAccessInformation.isTrialPeriodExpired
            : false;
    }, [accountAccessInformation]);
    var isNotOnboarded = useMemo(function () {
        return accountAccessInformation
            ? !accountAccessInformation.hasActiveSubscription &&
                !accountAccessInformation.hasInactiveSubscription &&
                !accountAccessInformation.isOnboarded
            : true;
    }, [accountAccessInformation]);
    var renderNavigationItem = useCallback(function (title, onClick, isDisabled) {
        if (isDisabled === void 0) { isDisabled = false; }
        return (React.createElement("div", { role: "menuitem", className: "navigation-item " + (isDisabled ? 'disabled' : ''), onClick: function () { return !isDisabled && onClick && onClick(); } }, title));
    }, [history]);
    useEffect(function () {
        var script = document.createElement('script');
        script.async = true;
        script.src = 'https://cdn.headwayapp.co/widget.js';
        document.head.appendChild(script);
        var config = {
            selector: '.headway-widget-badge-container',
            account: 'xaNPe7',
            trigger: '.headway-widget-trigger',
        };
        script.onload = function () {
            // @ts-ignore
            window.Headway.init(config);
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("nav", { className: "header-information-resources-navigation", "aria-label": "tertiary-header" },
            renderNavigationItem('Help articles', function () { return window.open('https://help.payaca.com/en/'); }, false),
            renderNavigationItem('Resources', function () { return history.push('/resources'); }, trialPeriodIsExpired || isNotOnboarded),
            renderNavigationItem('Contact us', function () { return setShowContactUsModal(true); }),
            renderNavigationItem(React.createElement("span", { className: "headway-widget-trigger flex-container flex-center" },
                React.createElement("span", null, "Product updates"),
                React.createElement("span", { className: "headway-widget-badge-container" })))),
        React.createElement(ContactUsModal, { isOpen: showContactUsModal, onClose: function () { return setShowContactUsModal(false); } })));
};
export default HeaderInformationResourcesNavigation;
