var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import HeaderRow from './HeaderRow';
import TableHeader from './TableHeader';
import { getTableConfig } from '@/helpers/tableHelper';
import { StringUtil } from '@/utils';
import { EmptyState, Pagination } from '@/ui/components';
import './Table.css';
import { RowSelectionTableCell } from '@payaca/components/rowSelectionTableCell';
import { QuickActionsTableCell } from '@payaca/components/quickActionsTableCell';
var Table = function (_a) {
    var addButton = _a.addButton, bulkActions = _a.bulkActions, configName = _a.configName, data = _a.data, disableData = _a.disableData, hideEmptyArrowIndication = _a.hideEmptyArrowIndication, _b = _a.onDisabledRowClick, onDisabledRowClick = _b === void 0 ? null : _b, onRowClick = _a.onRowClick, singleActions = _a.singleActions;
    var _c = useState({
        value: null,
        reversed: false,
    }), sort = _c[0], setSort = _c[1];
    var _d = useState([]), tableData = _d[0], setTableData = _d[1];
    var _e = useState([]), pageData = _e[0], setPageData = _e[1];
    var _f = useState(''), inputValue = _f[0], setInputValue = _f[1];
    var _g = useState([]), selected = _g[0], setSelected = _g[1];
    var _h = useState({ target: null, idx: null }), popupMenu = _h[0], setPopupMenu = _h[1];
    var myProfile = useSelector(function (state) {
        var _a;
        return (_a = state === null || state === void 0 ? void 0 : state.users) === null || _a === void 0 ? void 0 : _a.myProfile;
    });
    var tableConfig = useMemo(function () {
        return getTableConfig(configName);
    }, [configName]);
    useEffect(function () {
        var parsedData = parseData(data);
        // sort data once it has been loaded
        if (parsedData) {
            sortData(parsedData, tableConfig.initialSortBy, tableConfig.initialSortDescending);
        }
    }, [data, tableConfig]);
    var shownColumns = useMemo(function () {
        return tableConfig.columns.filter(function (column) {
            return !(column.hidden === true || (column.hidden && column.hidden(myProfile)));
        });
    }, [myProfile, tableConfig]);
    var parseData = function (rawData) {
        var parsedPageData = rawData &&
            rawData.map(function (d) {
                var tableData = {};
                // get a parsed value for each tableConfig column
                tableConfig.columns.forEach(function (config, configIdx) {
                    var rawValue = null;
                    if (config.targets) {
                        rawValue = {};
                        config.targets.forEach(function (target) {
                            rawValue[target] = get(d, target);
                        });
                    }
                    else {
                        rawValue = get(d, config.target);
                    }
                    var style = {};
                    if (config.style) {
                        style = config.style(rawValue);
                    }
                    var value = rawValue;
                    if (config.format) {
                        value = config.format(rawValue);
                    }
                    tableData[config.name] = {
                        value: value,
                        rawValue: rawValue,
                        style: style,
                        config: config,
                    };
                });
                return __assign(__assign({}, d), { tableData: tableData });
            });
        return parsedPageData;
    };
    var showEmptyState = useMemo(function () { return data && data.length === 0; }, [data]);
    var showTable = useMemo(function () { return !showEmptyState && tableConfig && data && data.length; }, [data, showEmptyState, tableConfig]);
    var sortData = useCallback(function (data, sortBy, reverseSort) {
        var sortedData = data;
        var isReversed = sort.reversed;
        if (typeof sortBy === 'string') {
            var doReverse = reverseSort && sort.value === sortBy && !isReversed;
            sortedData = StringUtil.sort(data, sortBy, doReverse);
            isReversed = doReverse;
        }
        else if (typeof sortBy === 'function') {
            sortedData = sortBy(data);
        }
        setSort({
            value: sortBy || '',
            reversed: isReversed,
        });
        setTableData(sortedData);
    }, [sort]);
    var searchData = function (inputValue) {
        var searchedData = StringUtil.search(data, tableConfig, inputValue);
        var parsedData = parseData(searchedData);
        // sort data once it has been loaded
        if (parsedData) {
            sortData(parsedData, tableConfig.initialSortBy, tableConfig.initialSortDescending);
        }
        setInputValue(inputValue);
    };
    var toggleRow = useCallback(function (row) {
        var selectedRows = __spreadArrays(selected);
        var selectedIndex = selectedRows.findIndex(function (s) { return isEqual(s, row); });
        selectedIndex >= 0
            ? selectedRows.splice(selectedIndex, 1)
            : selectedRows.push(row);
        setSelected(selectedRows);
    }, [selected]);
    var renderSingleQuickActionsForJob = useCallback(function (dataItem, idx, rowDisabled) {
        // get actions allowed by role, data item and with relating function
        var quickActions = dataItem && singleActions && !rowDisabled
            ? tableConfig
                .singleQuickActions(dataItem, myProfile, singleActions)
                .map(function (x) {
                return { actionName: x.name, actionBehaviour: x.onClick };
            })
            : [];
        return (React.createElement(QuickActionsTableCell, { recordId: dataItem.id, quickActions: quickActions }));
    }, [myProfile, popupMenu, singleActions, tableConfig]);
    var renderSelectCell = useCallback(function (data, rowDisabled) {
        var isSelected = !!selected.find(function (s) { return isEqual(s, data); });
        if (!rowDisabled) {
            return (React.createElement(RowSelectionTableCell, { isSelected: isSelected, onChange: function () { return toggleRow(data); } }));
        }
        else {
            return React.createElement("td", null);
        }
    }, [selected, toggleRow]);
    var renderDataCell = function (rowData, colName, rowDisabled) {
        var tableData = rowData.tableData;
        if (!tableData) {
            return;
        }
        var cellData = tableData[colName];
        var config = cellData.config;
        var elementClass = 'table-element' + (config.classes ? " " + config.classes : '');
        return (React.createElement("td", { className: elementClass, key: config.name + "-", onClick: function () {
                return rowDisabled
                    ? onDisabledRowClick && onDisabledRowClick(rowData)
                    : onRowClick(rowData);
            } },
            React.createElement("div", { style: cellData.style, title: config.name === 'Status' ? cellData.value : null }, cellData.value)));
    };
    var disableRow = function (rowData) {
        return (tableConfig.disableRow &&
            tableConfig.disableRow(rowData, {
                profile: myProfile,
                disableData: disableData,
            }));
    };
    var selectableData = useMemo(function () {
        return tableData.filter(function (d) { return !disableRow(d); });
    }, [tableData]);
    var renderRows = useCallback(function () {
        var children = [];
        var noOfRows = tableConfig.defaultRowsPerPage || pageData.length;
        var _loop_1 = function (i) {
            var rowData = pageData[i];
            if (rowData) {
                var rowDisabled_1 = disableRow(rowData);
                var rowClass = 'row' +
                    (i === popupMenu.idx ? ' selected' : '') +
                    (rowDisabled_1 ? ' disabled' : '');
                children.push(React.createElement("tr", { className: rowClass, key: i },
                    bulkActions && renderSelectCell(rowData, rowDisabled_1),
                    shownColumns.map(function (col) {
                        return renderDataCell(rowData, col.name, rowDisabled_1);
                    }),
                    tableConfig.singleQuickActions &&
                        renderSingleQuickActionsForJob(rowData, i, rowDisabled_1)));
            }
        };
        for (var i = 0; i < noOfRows; i++) {
            _loop_1(i);
        }
        return children;
    }, [
        bulkActions,
        pageData,
        popupMenu,
        renderSelectCell,
        renderSingleQuickActionsForJob,
        shownColumns,
        tableConfig,
    ]);
    var renderTableHeader = useCallback(function () {
        return (React.createElement(TableHeader, { addButton: addButton, bulkActions: bulkActions, tableConfig: tableConfig, onSearch: function (value) { return searchData(value); }, selected: selected, inputValue: inputValue, myProfile: myProfile, setSelected: function (selected) { return setSelected(selected); } }));
    }, [
        addButton,
        bulkActions,
        tableConfig,
        searchData,
        selected,
        inputValue,
        myProfile,
        setSelected,
    ]);
    var renderHeaderRow = useCallback(function () {
        return (React.createElement(HeaderRow, { onColumnHeaderClick: function (targetName) {
                sortData(pageData, targetName, true);
            }, onSelectAll: function (selectedRows) {
                setSelected(selectedRows);
            }, selectableData: selectableData, selectedRows: selected, showQuickActionsColumn: !!tableConfig.singleQuickActions, showBulkActionsColumn: bulkActions, shownColumns: shownColumns, sortData: sort }));
    }, [
        pageData,
        selected,
        shownColumns,
        selectableData,
        sort,
        sortData,
        tableConfig,
        tableData,
    ]);
    var renderPagination = useCallback(function () {
        return (React.createElement(Pagination, { onPageChange: function (pageData) {
                setPageData(pageData);
            }, data: tableData, dataItemsPerPage: tableConfig.defaultRowsPerPage }));
    }, [tableConfig, tableData]);
    return (React.createElement("div", { className: "searchable-table-container" + (tableConfig.tableClassName ? " " + tableConfig.tableClassName : '') },
        renderTableHeader(),
        React.createElement("div", { className: "styled-table-wrapper" },
            showEmptyState && (React.createElement(EmptyState, { configName: configName, arrowIndication: !hideEmptyArrowIndication })),
            showTable && (React.createElement("div", { className: "scrollable-table-wrapper" },
                React.createElement("table", { className: "styled-table" },
                    renderHeaderRow(),
                    React.createElement("tbody", null, renderRows())))),
            tableConfig && renderPagination())));
};
export default Table;
