var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionType } from "./jobPaymentsTypes";
export var initialState = {
    isRecordingJobPayment: false,
    isJobPaymentRecordedSuccessfully: undefined,
    isConfirmingJobPayment: false,
    isJobPaymentConfirmedSuccessfully: undefined,
    jobPayments: {},
};
var jobPaymentsReducer = function (state, action) {
    var _a, _b, _c;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionType.REQUEST_RECORD_JOB_PAYMENT:
            return __assign(__assign({}, state), { isRecordingJobPayment: true, isJobPaymentRecordedSuccessfully: undefined });
        case ActionType.RECORD_JOB_PAYMENT_SUCCESS:
            return __assign(__assign({}, state), { isRecordingJobPayment: false, isJobPaymentRecordedSuccessfully: true });
        case ActionType.RECORD_JOB_PAYMENT_FAILURE:
            return __assign(__assign({}, state), { isRecordingJobPayment: false, isJobPaymentRecordedSuccessfully: false });
        case ActionType.REQUEST_CONFIRM_JOB_PAYMENT:
            return __assign(__assign({}, state), { isConfirmingJobPayment: true, isJobPaymentConfirmedSuccessfully: undefined });
        case ActionType.CONFIRM_JOB_PAYMENT_SUCCESS:
            return __assign(__assign({}, state), { isConfirmingJobPayment: false, isJobPaymentConfirmedSuccessfully: true });
        case ActionType.CONFIRM_JOB_PAYMENT_FAILURE:
            return __assign(__assign({}, state), { isConfirmingJobPayment: false, isJobPaymentConfirmedSuccessfully: false });
        case ActionType.REQUEST_GET_JOB_PAYMENT: {
            var fetchedEntity = state.jobPayments && state.jobPayments[action.payload.jobPaymentId];
            return __assign(__assign({}, state), { jobPayments: __assign(__assign({}, state.jobPayments), (_a = {}, _a[action.payload.jobPaymentId] = __assign(__assign({}, fetchedEntity), { fetchAttemptedAttemptedAt: new Date(), fetchSucceededAt: undefined, fetchFailedAt: undefined, isFetching: true, fetchError: undefined }), _a)) });
        }
        case ActionType.GET_JOB_PAYMENT_SUCCESS: {
            var fetchedEntity = state.jobPayments && state.jobPayments[action.payload.jobPaymentId];
            return __assign(__assign({}, state), { jobPayments: __assign(__assign({}, state.jobPayments), (_b = {}, _b[action.payload.jobPaymentId] = __assign(__assign({}, fetchedEntity), { entity: action.payload.jobPayment, fetchSucceededAt: new Date(), isFetching: false }), _b)) });
        }
        case ActionType.GET_JOB_PAYMENT_FAILURE: {
            var fetchedEntity = state.jobPayments && state.jobPayments[action.payload.jobPaymentId];
            return __assign(__assign({}, state), { jobPayments: __assign(__assign({}, state.jobPayments), (_c = {}, _c[action.payload.jobPaymentId] = __assign(__assign({}, fetchedEntity), { fetchFailedAtAt: new Date(), isFetching: false, fetchError: action.error }), _c)) });
        }
        case ActionType.CLEAR_JOB_PAYMENT: {
            var jobPayments = state.jobPayments || {};
            delete jobPayments[action.payload.jobPaymentId];
            return __assign(__assign({}, state), { jobPayments: jobPayments });
        }
        case ActionType.CLEAR_JOB_PAYMENTS: {
            return __assign(__assign({}, state), { jobPayments: {} });
        }
        default:
            return state;
    }
};
export default jobPaymentsReducer;
