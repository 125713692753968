import get from 'lodash.get';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

import { getSortConfig } from '@/helpers/tableHelper';

export default class StringUtil {
  /**
   * search
   * @param {object} data - data array of objects i.e. jobs, customers
   * @param {string} tableConfig - predefined config property depending on the page of search i.e. quoteRef, name
   * @param {string} searchingValue - typed input value being searched
   * @return {object} - searched data results
   */
  static search(data, tableConfig, searchingValue) {
    // set to all columns in table if no specific searchBy value
    let searchBy =
      tableConfig.search.searchBy ||
      tableConfig.columns.reduce((acc, curr) => [...acc, curr.target], []);

    // find a match on any of the data properties to match on
    const searchedData = data.filter((dataItem) => {
      const match = searchBy.find((property) => {
        const val = get(dataItem, property);
        const searchReg = new RegExp(searchingValue, 'i');
        return searchReg.test(val);
      });
      return match;
    });
    return searchedData;
  }

  /**
   * sort
   * @param {array} list - list to sort i.e. list of jobs, customers
   * @param {string} sortBy - property chosen to sort by
   * @param {?boolean} reverse - optional to reverse the list
   * @return {object} - sorted data results
   */
  static sort(list, sortBy, reverse) {
    let sortedList = [];
    const listToSort = list || [];
    if (sortBy) {
      const sortConfig = getSortConfig(sortBy);

      let sortType = null;
      let sortValue = 'value';
      if (sortConfig) {
        // sortConfig can define sorting type i.e. date or whether to use pre-formatted/raw value
        sortType = sortConfig.sort || null;
        sortValue = sortConfig.raw ? 'rawValue' : sortValue;
      }

      switch (sortType) {
        case 'date': {
          sortedList = listToSort.sort((a, b) => {
            const dateStringA = get(a, ['tableData', sortBy]);
            const dateStringAVal = dateStringA && dateStringA[sortValue];
            const dateATicks = dateStringAVal
              ? moment(dateStringAVal).valueOf()
              : 0;

            const dateStringB = get(b, ['tableData', sortBy]);
            const dateStringBVal = dateStringB && dateStringB[sortValue];
            const dateBTicks = dateStringBVal
              ? moment(dateStringBVal).valueOf()
              : 0;

            return dateATicks > dateBTicks ? 1 : -1;
          });
          break;
        }
        default: {
          sortedList = listToSort.sort((a, b) => {
            const aData = get(a, ['tableData', sortBy]);
            const bData = get(b, ['tableData', sortBy]);
            const aDataVal = aData && aData[sortValue];
            const bDataVal = bData && bData[sortValue];

            if (aDataVal && !bDataVal) {
              return 1;
            }
            if (bDataVal && !aDataVal) {
              return -1;
            }
            return `${aDataVal}`.localeCompare(
              `${bDataVal}`,
              navigator.languages[0] || navigator.language,
              {
                numeric: true, // sort numbers i.e. 2 < 10
                sensitivity: 'base', // ignore cases
              }
            );
          });
        }
      }
    } else {
      sortedList = listToSort.sort();
    }
    return reverse ? sortedList.reverse() : sortedList;
  }

  /**
   * list
   * @param {array} arr - array of items to list out
   * @return {string} - list of items
   */
  static list(arr) {
    return arr.reduce((acc, curr, i) => {
      if (i === 0) {
        acc = curr;
      } else if (i === arr.length - 1) {
        acc = `${acc} and ${curr}`;
      } else {
        acc = `${acc}, ${curr}`;
      }
      return acc;
    }, '');
  }

  /**
   * singularPlural
   * @param {number} length  - number being described
   * @param {string} singularString - string for singular
   * @param {string} pluralString  - string for plural
   * @param {string} singularlengthAlternative - string for an alternative to specfying 1
   */
  static singularPlural(
    length,
    singularString,
    pluralString,
    singularlengthAlternative
  ) {
    if (length === 1) {
      return `${
        singularlengthAlternative ? singularlengthAlternative : length
      } ${singularString}`;
    }
    return `${length} ${pluralString}`;
  }

  /**
   * readableDate
   * @param {string} date - string of date
   * @return {string} readable formatted date
   */
  static readableDate(date, short) {
    if (!date) {
      return null;
    }
    const momentDate = momentTimezone(date);
    return short
      ? momentDate.format('DD-MMM-YY')
      : momentDate.format('Do MMMM YYYY');
  }
}
