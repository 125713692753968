import React, { useMemo } from 'react';
import { currencyPrice } from '@/helpers/financeHelper';
import { Button, ButtonStyleVariant, ButtonColourVariant, } from '@payaca/components/button';
import './SubscriptionProductSelectionControl.sass';
import QuantityControl from '../quantityControl/QuantityControl';
var SubscriptionProductSelectionControl = function (_a) {
    var subscriptionProduct = _a.subscriptionProduct, recurringInterval = _a.recurringInterval, additionalUserSeats = _a.additionalUserSeats, _b = _a.enableEmbeddedUserSeatControl, enableEmbeddedUserSeatControl = _b === void 0 ? false : _b, isSelected = _a.isSelected, badge = _a.badge, renderCta = _a.renderCta, onSelect = _a.onSelect, onChangeAdditionalUserSeats = _a.onChangeAdditionalUserSeats;
    var productPrice = useMemo(function () {
        if (recurringInterval === 'month') {
            return subscriptionProduct.monthlyPrice;
        }
        else {
            return subscriptionProduct.annualPrice;
        }
    }, [subscriptionProduct, recurringInterval]);
    var canBuyAdditionalUserSeats = useMemo(function () {
        return !!(productPrice === null || productPrice === void 0 ? void 0 : productPrice.canBuyAdditionalUserSeats);
    }, [productPrice]);
    var monthlyBasicCost = useMemo(function () {
        if (recurringInterval === 'month') {
            return productPrice === null || productPrice === void 0 ? void 0 : productPrice.basicCost;
        }
        else {
            return productPrice ? productPrice.basicCost / 12 : undefined;
        }
    }, [productPrice, recurringInterval]);
    var monthlyAdditionalUserSeatCost = useMemo(function () {
        if (recurringInterval === 'month') {
            return productPrice === null || productPrice === void 0 ? void 0 : productPrice.additionalUserSeatCost;
        }
        else {
            return (productPrice === null || productPrice === void 0 ? void 0 : productPrice.additionalUserSeatCost) ? productPrice.additionalUserSeatCost / 12
                : undefined;
        }
    }, [productPrice, recurringInterval]);
    return (React.createElement("div", { className: "subscription-product-selection-control-outer" },
        badge,
        React.createElement("div", { className: "subscription-product-selection-control " + (isSelected ? 'selected' : '') },
            React.createElement("div", { className: "subscription-product-selection-control-inner" },
                React.createElement("div", { className: "product-name-container" },
                    React.createElement("h2", { className: "product-name" }, subscriptionProduct.name)),
                React.createElement("hr", null),
                monthlyBasicCost && (React.createElement("div", { className: "cost-container" },
                    React.createElement("p", { className: "cost-per-month" },
                        React.createElement("span", { className: "cost" },
                            "\u00A3",
                            monthlyBasicCost / 100),
                        " + VAT"),
                    React.createElement("p", null,
                        "Per month, billed",
                        ' ',
                        recurringInterval === 'month' ? 'monthly' : 'annually'))),
                React.createElement("div", { className: "features-container" },
                    subscriptionProduct.numberOfJobsPerMonth && (React.createElement("p", null,
                        "Send up to",
                        ' ',
                        React.createElement("strong", null, subscriptionProduct.numberOfJobsPerMonth),
                        " jobs per month")),
                    subscriptionProduct.numberOfUserSeats && (React.createElement("p", null,
                        "Includes",
                        ' ',
                        React.createElement("strong", null, subscriptionProduct.numberOfUserSeats),
                        ' ',
                        subscriptionProduct.numberOfUserSeats === 1
                            ? 'user seat'
                            : 'user seats'))),
                React.createElement("hr", null),
                canBuyAdditionalUserSeats && (React.createElement("div", { className: "additional-user-seats-container" },
                    React.createElement("h3", null, "Additional user seats"),
                    React.createElement("p", { className: "additional-user-seat-cost" },
                        React.createElement("strong", null, currencyPrice(monthlyAdditionalUserSeatCost || 0)),
                        ' ',
                        "+ VAT per seat per month"),
                    isSelected && enableEmbeddedUserSeatControl && (React.createElement(QuantityControl, { isDisabled: !isSelected, value: additionalUserSeats, onChange: onChangeAdditionalUserSeats }))))),
            React.createElement("div", { className: "button-container" }, renderCta ? (renderCta(isSelected, onSelect)) : (React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, colourVariant: ButtonColourVariant.PRIMARY, isDisabled: isSelected, onClick: onSelect }, isSelected ? 'Selected' : 'Select'))))));
};
export default SubscriptionProductSelectionControl;
