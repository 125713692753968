import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SwitchField } from '@payaca/components';
import { actions as accountSettingsActions } from '@/api/accountSettings';
var CompanySettingsReminders = function (_a) {
    var readOnly = _a.readOnly;
    var dispatch = useDispatch();
    var pendingAccount = useSelector(function (state) { return state.accountSettings.pendingAccount; });
    var onChange = useCallback(function (change) {
        dispatch(accountSettingsActions.storeAccountPendingChanges(change));
    }, [dispatch]);
    return (React.createElement("div", { className: "company-settings-reminders" },
        React.createElement("div", { className: "settings-sub-section" },
            React.createElement("h4", null, "Invoice reminder schedule"),
            React.createElement("p", null, "You can choose to send invoice reminders to your customers to remind them to pay."),
            React.createElement(SwitchField, { name: "sendInvoiceReminderBeforeDue3Days", value: pendingAccount.sendInvoiceReminderBeforeDue3Days, onChange: onChange, label: "Send a payment reminder 3 days before invoice is due", isDisabled: readOnly }),
            React.createElement(SwitchField, { name: "sendInvoiceReminderWhenDue", value: pendingAccount.sendInvoiceReminderWhenDue, onChange: onChange, label: "Send a payment reminder when invoice is due", isDisabled: readOnly }),
            React.createElement(SwitchField, { name: "sendInvoiceReminderAfterDue3Days", value: pendingAccount.sendInvoiceReminderAfterDue3Days, onChange: onChange, label: "Send a payment reminder 3 days after invoice is due", isDisabled: readOnly }),
            React.createElement(SwitchField, { name: "sendInvoiceReminderAfterDue7Days", value: pendingAccount.sendInvoiceReminderAfterDue7Days, onChange: onChange, label: "Send a payment reminder 7 days after invoice is due", isDisabled: readOnly }))));
};
export default CompanySettingsReminders;
