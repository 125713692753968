import React, { useCallback, useRef, useState } from 'react';
import SelectSubscriptionControl from '../selectSubscriptionControl/SelectSubscriptionControl';
import SubscriptionComparisonModal from '@/ui/components/subscriptionComparisonModal/SubscriptionComparisonModal';
import { Button, ButtonColourVariant, ButtonStyleVariant, } from '@payaca/components/button';
import './CreateSubscriptionPageContent.sass';
import CreateSubscriptionGuidancePrompts from '../../createSubscriptionGuidancePrompts/CreateSubscriptionGuidancePrompts';
// @ts-ignore
import scrollToWithAnimation from 'scrollto-with-animation';
var CreateSubscriptionPageContent = function (_a) {
    var onCreateSubscription = _a.onCreateSubscription;
    var _b = useState(false), isSubscriptionPlansFocused = _b[0], setIsSubscriptionPlansFocused = _b[1];
    var _c = useState(false), showSubscriptionComparisonModal = _c[0], setShowSubscriptionComparisonModal = _c[1];
    var subscriptionPlansContainerElementRef = useRef(null);
    var scrollToSubscriptionPlans = useCallback(function () {
        var _a;
        var parentElement = document.getElementsByClassName('body-content')[0];
        //@ts-ignore
        var parentElementOffsetTop = (parentElement === null || parentElement === void 0 ? void 0 : parentElement.offsetTop) || 0;
        var subscriptionPlansOffsetTop = ((_a = subscriptionPlansContainerElementRef === null || subscriptionPlansContainerElementRef === void 0 ? void 0 : subscriptionPlansContainerElementRef.current) === null || _a === void 0 ? void 0 : _a.offsetTop) || 0;
        scrollToWithAnimation(document.getElementsByClassName('body-content')[0], 'scrollTop', subscriptionPlansOffsetTop - parentElementOffsetTop - 10, 300, 'easeInOutCirc', function () {
            setIsSubscriptionPlansFocused(true);
            setTimeout(function () { return setIsSubscriptionPlansFocused(false); }, 300);
        });
    }, [subscriptionPlansContainerElementRef]);
    return (React.createElement("div", { className: "create-subscription-page-content" },
        React.createElement(CreateSubscriptionGuidancePrompts, { onGoToSubscriptionPlans: scrollToSubscriptionPlans }),
        React.createElement("div", { ref: subscriptionPlansContainerElementRef, className: "subscription-plans-container-element " + (isSubscriptionPlansFocused ? 'focused' : '') },
            React.createElement(SelectSubscriptionControl, { onUpdateSubscription: onCreateSubscription }),
            React.createElement("div", { className: "view-comparison-chart-trigger-container" },
                React.createElement(Button, { onClick: function () { return setShowSubscriptionComparisonModal(true); }, styleVariant: ButtonStyleVariant.OUTSIZE, colourVariant: ButtonColourVariant.SECONDARY }, "View comparison chart"))),
        React.createElement(SubscriptionComparisonModal, { isOpen: showSubscriptionComparisonModal, onClose: function () { return setShowSubscriptionComparisonModal(false); } })));
};
export default CreateSubscriptionPageContent;
