import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AssociatedBusinessFormSubsection from '../associatedBusinessFormSubsection/AssociatedBusinessFormSubsection';
import './AssociatedBusinessesFormSubsection.css';
var AssociatedBusinessesFormSubsection = function (_a) {
    var associatedBusinesses = _a.associatedBusinesses, fieldNamePrefix = _a.fieldNamePrefix, touchedState = _a.touchedState, validationState = _a.validationState, onTouch = _a.onTouch, onChange = _a.onChange, onAssociatedBusinessAdd = _a.onAssociatedBusinessAdd, onAssociatedBusinessRemove = _a.onAssociatedBusinessRemove;
    var renderAssociatedBusinessFormSubsection = useCallback(function (associatedBusiness, index) {
        return (React.createElement(AssociatedBusinessFormSubsection, { key: index, title: "Associated business " + (index + 1), fieldNamePrefix: fieldNamePrefix + "[" + index + "]", touchedState: touchedState, validationState: validationState, associatedBusiness: associatedBusiness, onTouch: onTouch, onChange: onChange, onRemove: function () { return onAssociatedBusinessRemove(index); } }));
    }, [
        onTouch,
        onChange,
        onAssociatedBusinessRemove,
        validationState,
        touchedState,
        fieldNamePrefix,
    ]);
    return (React.createElement("div", { className: "associated-businesses-form-subsection" },
        associatedBusinesses &&
            associatedBusinesses.map(renderAssociatedBusinessFormSubsection),
        React.createElement("div", { className: "add-button", onClick: onAssociatedBusinessAdd },
            React.createElement(FontAwesomeIcon, { icon: faPlus }),
            "Add associated business")));
};
export default AssociatedBusinessesFormSubsection;
