import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as authActions } from '@bit/payaca-tech.payaca-core.store.auth';
import { ConnectionControl } from '@/ui/components';
import ZapierLogo from './zapier.png';
var ZapierConnectionControl = function () {
    var dispatch = useDispatch();
    var zapierConnection = useSelector(function (state) {
        if (!state.users.myProfile.oAuthConnections) {
            return null;
        }
        return state.users.myProfile.oAuthConnections.find(function (p) { return p.clientName === 'Zapier' || p.clientName === 'zapier test'; });
    });
    return (React.createElement(ConnectionControl, { connectionName: "Zapier", isConnected: zapierConnection, onRemoveConnection: function (onConnectionRemoved) {
            return dispatch(authActions.removeOAuthConnection(zapierConnection, onConnectionRemoved));
        }, allowConnectDisconnect: true, disconnectionError: "Sorry, there was an error removing your connection with Zapier. Please try again or head over to your Zapier account and remove your connection with Payaca.", logo: ZapierLogo, onAddConnection: function () {
            return window.open(process.env.REACT_APP_ENV === 'staging' ||
                process.env.NODE_ENV === 'development'
                ? 'https://zapier.com/developer/public-invite/125577/2765a5b758bd096b961fbc6995e39164/'
                : 'https://zapier.com/apps/payaca/integrations');
        } },
        React.createElement("div", null,
            "Follow",
            ' ',
            React.createElement("a", { className: "inline-link", onClick: function () {
                    return window.open('https://help.payaca.com/en/articles/4602656-how-to-connect-with-zapier');
                } }, "this guide"),
            ' ',
            "to help setup connecting Payaca with your accounting software, tested integrations include",
            ' ',
            React.createElement("a", { className: "inline-link", onClick: function () {
                    return window.open('http://help.payaca.com/en/articles/4602767-how-to-connect-to-freshbooks-using-zapier');
                } }, "Freshbooks"),
            ', ',
            React.createElement("a", { className: "inline-link", onClick: function () {
                    return window.open('http://help.payaca.com/en/articles/4890064-how-to-connect-to-servicem8-using-zapier');
                } }, "ServiceM8"),
            ' ',
            "and ",
            React.createElement("span", null, "Kashflow"),
            zapierConnection && zapierConnection.hasConnectionExpired && (React.createElement("div", { className: "connection-expired-text" }, "Your connection with Zapier has expired. Please head over to \"My Apps\" in Zapier and reconnect your account with Payaca.")))));
};
export default ZapierConnectionControl;
