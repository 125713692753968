import React, { useCallback } from "react";
import "./RadioGroupField.css";
import FieldLabel from "../fieldLabel/FieldLabel";
import RadioButton from "../radioButton/RadioButton";
var RadioGroupField = function (_a) {
    var name = _a.name, value = _a.value, label = _a.label, description = _a.description, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, _c = _a.isRequired, isRequired = _c === void 0 ? false : _c, options = _a.options, onChange = _a.onChange, onTouch = _a.onTouch;
    var onRadioClick = useCallback(function (value) {
        var _a;
        onChange &&
            onChange((_a = {},
                _a[name] = value,
                _a));
        onTouch && onTouch(name);
    }, [name, onChange]);
    return (React.createElement("div", { className: "radio-group-field" },
        React.createElement(FieldLabel, { label: label, isRequired: isRequired, description: description }),
        React.createElement("div", { className: "options-container" }, options.map(function (option, index) {
            return (React.createElement("div", { className: "option-container", key: index },
                React.createElement("div", { className: "radio-button-container" },
                    React.createElement(RadioButton, { isSelected: value === option.value, onClick: function () { return onRadioClick(option.value); }, isDisabled: isDisabled })),
                React.createElement("label", { className: "option-label" }, option.label)));
        }))));
};
export default RadioGroupField;
