export var getProductPriceCostExcludingVat = function (price, additionalUserSeats) {
    return (price.basicCost + (price.additionalUserSeatCost || 0) * additionalUserSeats);
};
export var getSubscriptionCostExcludingVat = function (product, recurringInterval, additionalUserSeats) {
    var price = recurringInterval === 'month' ? product.monthlyPrice : product.annualPrice;
    if (!price)
        return;
    return getProductPriceCostExcludingVat(price, additionalUserSeats);
};
export var getSubscriptionCostIncludingVat = function (product, recurringInterval, additionalUserSeats, taxRatePercentage) {
    var subscriptionCostExcludingVat = getSubscriptionCostExcludingVat(product, recurringInterval, additionalUserSeats);
    if (!subscriptionCostExcludingVat)
        return;
    var vat = subscriptionCostExcludingVat * (taxRatePercentage / 100);
    return subscriptionCostExcludingVat + vat;
};
