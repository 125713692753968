import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { actions as jobsActions } from '@bit/payaca-tech.payaca-core.store.jobs';
import { useState } from 'react';
import ItemGroupHeader from '../itemGroupHeader/ItemGroupHeader';
import JobLineItemsControl from '../jobLineItemsControl/JobLineItemsControl';
import './JobLineItemGroupControl.css';
import { getJobLineItemGroup } from '@/utils/stateAccessors';
import ConfirmModal from '../modal/ConfirmModal';
var JobLineItemGroupControl = function (_a) {
    var canDeleteItemGroup = _a.canDeleteItemGroup, jobLineItemGroupId = _a.jobLineItemGroupId, jobIsInvoice = _a.jobIsInvoice;
    var dispatch = useDispatch();
    var _b = useState(false), isDeleting = _b[0], setIsDeleting = _b[1];
    var _c = useState(false), showConfirmDeleteModal = _c[0], setShowConfirmDeleteModal = _c[1];
    var jobLineItemGroup = useSelector(function (state) { return getJobLineItemGroup(state, jobLineItemGroupId); });
    var isDeletingJobLineItemGroup = useSelector(function (state) {
        return state.jobsStore.isDeletingJobLineItemGroup;
    });
    var getJobLineItemGroups = useCallback(function () {
        if (!jobLineItemGroup)
            return;
        dispatch(jobsActions.requestGetJobLineItemGroupsForJob(jobLineItemGroup.jobId));
    }, [dispatch, jobLineItemGroup]);
    var getJob = useCallback(function () {
        if (!jobLineItemGroup)
            return;
        dispatch(jobsActions.requestGetJob(jobLineItemGroup.jobId));
    }, [dispatch, jobLineItemGroup]);
    var lineItemGroupHasAnyItems = useMemo(function () {
        if (!(jobLineItemGroup === null || jobLineItemGroup === void 0 ? void 0 : jobLineItemGroup.jobLineItemIds))
            return false;
        return jobLineItemGroup.jobLineItemIds.length > 0;
    }, [jobLineItemGroup]);
    var deleteJobLineItemGroup = useCallback(function () {
        setIsDeleting(true);
        dispatch(jobsActions.requestDeleteJobLineItemGroup(jobLineItemGroupId, getJob));
    }, [dispatch, getJob, jobLineItemGroupId]);
    var handleDeleteJobLineItemGroup = useCallback(function () {
        if (lineItemGroupHasAnyItems) {
            setShowConfirmDeleteModal(true);
        }
        else {
            deleteJobLineItemGroup();
        }
    }, [lineItemGroupHasAnyItems, deleteJobLineItemGroup]);
    var updateJobLineItemGroup = useCallback(function (description) {
        dispatch(jobsActions.requestUpdateJobLineItemGroup({
            description: description,
            jobLineItemGroupId: jobLineItemGroupId,
        }, getJobLineItemGroups));
    }, [dispatch, jobLineItemGroupId, getJobLineItemGroups]);
    return (React.createElement("div", { className: "job-line-item-group-control" },
        jobLineItemGroup && (React.createElement(React.Fragment, null,
            React.createElement(ItemGroupHeader, { canDeleteItemGroup: canDeleteItemGroup, isDeleting: isDeleting && isDeletingJobLineItemGroup, itemGroup: jobLineItemGroup, onDeleteItemGroup: handleDeleteJobLineItemGroup, onUpdateItemGroup: function (value) {
                    return updateJobLineItemGroup(value.description);
                } }),
            React.createElement(JobLineItemsControl, { jobLineItemGroupId: jobLineItemGroupId, jobIsInvoice: jobIsInvoice }))),
        React.createElement(ConfirmModal, { title: "Confirm group delete", text: "Are you sure you want to delete the group? This group contains one or more items which will also be deleted.", onClose: function () { return setShowConfirmDeleteModal(false); }, primaryText: "Yes, delete the group", primaryAction: function () {
                setShowConfirmDeleteModal(false);
                deleteJobLineItemGroup();
            }, secondaryText: "Don't delete group", secondaryAction: function () {
                setShowConfirmDeleteModal(false);
            }, open: showConfirmDeleteModal })));
};
export default JobLineItemGroupControl;
