import React, { useEffect, useMemo, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as appActions } from '@/api/app';
import PageWrapper from '../pageWrapper/PageWrapper';
import NavigationSidebar from '@/ui/components/navigationSidebar/NavigationSidebar';
import { AuthenticatedPageHeader } from '@/ui/components';
import './AuthenticatedPageWrapper.sass';
function getWindowDimensions() {
    var width = window.innerWidth, height = window.innerHeight;
    return {
        width: width,
        height: height,
    };
}
function useWindowDimensions() {
    var _a = useState(getWindowDimensions()), windowDimensions = _a[0], setWindowDimensions = _a[1];
    useEffect(function () {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    return windowDimensions;
}
var AuthenticatedPageWrapper = function (_a) {
    var pageBanners = _a.pageBanners, children = _a.children, className = _a.className, title = _a.title, searchFieldConfig = _a.searchFieldConfig;
    var dispatch = useDispatch();
    var windowDimensions = useWindowDimensions();
    var isSmallView = useMemo(function () {
        return windowDimensions.width < 700;
    }, [windowDimensions.width]);
    var isSidebarHidden = useSelector(function (state) { return state.app.navigationSidebarIsHidden; });
    return (React.createElement(PageWrapper, { className: "authenticated-page-wrapper " + (className ? className : '') + " " + (isSmallView ? 'sm-view' : ''), pageBanners: pageBanners },
        React.createElement("div", { className: "navigation-sidebar-container " + (isSidebarHidden ? '' : 'sidebar-visible'), onClick: !isSidebarHidden && isSmallView
                ? function (event) {
                    //@ts-ignore
                    event.target.classList.contains('navigation-sidebar') &&
                        dispatch(appActions.hideNavigationSidebar());
                }
                : undefined },
            React.createElement(NavigationSidebar, { isSmallView: isSmallView })),
        React.createElement("div", { className: "page-content" },
            React.createElement("div", { className: "header-content" },
                React.createElement(AuthenticatedPageHeader, { isSmallView: windowDimensions.width < 900, enableNavigationSidebarToggle: isSmallView, searchFieldConfig: searchFieldConfig }),
                title && (React.createElement("div", { className: "title-bar" }, typeof title === 'string' ? React.createElement("h1", null, title) : title))),
            React.createElement("div", { className: "body-content" }, children))));
};
export default AuthenticatedPageWrapper;
