var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as appActions } from '@/api/app';
import { actions as listedUsersActions } from '@bit/payaca-tech.payaca-core.store.listed-users';
import { actions as usersActions } from '@/api/users';
import { QuickActionType } from '@bit/payaca-tech.payaca-core.types.listed-user';
import { AddUserModal, ChangeUserRoleModal } from '@/ui/components';
import ListedUsersTable from '@/ui/components/listedUsersTable/ListedUsersTable';
import ConfirmMakePrimaryUserModal from '@/ui/components/confirmMakePrimaryUserModal/ConfirmMakePrimaryUserModal';
import './ListedUsersPage.sass';
import { Button, CreateButton, ButtonStyleVariant, } from '@payaca/components/button';
import ConfirmDeleteUserModal from '@/ui/components/confirmDeleteUserModal/ConfirmDeleteUserModal';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
var UsersPage = function () {
    var dispatch = useDispatch();
    var _a = useState([]), selectedUserIds = _a[0], setSelectedUserIds = _a[1];
    var _b = useState(false), showAddUserModal = _b[0], setShowAddUserModal = _b[1];
    var _c = useState(false), showChangeUserRoleModal = _c[0], setShowChangeUserRoleModal = _c[1];
    var _d = useState(false), showConfirmDeleteUserModal = _d[0], setShowConfirmDeleteUserModal = _d[1];
    var _e = useState(false), showConfirmMakePrimaryUserModal = _e[0], setShowConfirmMakePrimaryUserModal = _e[1];
    var _f = useState(), makePrimaryUserId = _f[0], setMakePrimaryUserId = _f[1];
    var _g = useState(null), actionProcessing = _g[0], setActionProcessing = _g[1];
    var _h = useState(false), isInBulkActionsMode = _h[0], setIsInBulkActionsMode = _h[1];
    var _j = useState({
        pageSize: 20,
        pageNumber: 1,
        searchTerm: '',
        includeDeactivatedUsers: false,
    }), getListedUsersRequestData = _j[0], setGetListedUsersRequestData = _j[1];
    var listedUsersPage = useSelector(function (state) {
        var _a;
        return (_a = state.listedUsers) === null || _a === void 0 ? void 0 : _a.listedUsersPage;
    });
    useEffect(function () {
        requestGetListedUsersPage();
    }, [getListedUsersRequestData]);
    var requestGetListedUsersPage = useCallback(function () {
        dispatch(listedUsersActions.requestGetListedUsersPage(getListedUsersRequestData));
    }, [getListedUsersRequestData, dispatch]);
    var userActionCallback = useCallback(function (error) {
        if (error) {
            dispatch(appActions.showBanner({ type: 'error' }));
        }
        setActionProcessing(null);
        setSelectedUserIds([]);
        requestGetListedUsersPage();
    }, [requestGetListedUsersPage, dispatch]);
    var onSelectUsers = useCallback(function (userIds) {
        setSelectedUserIds(function (selectedUserIds) {
            return selectedUserIds
                .concat(userIds)
                .filter(function (userId, index, array) {
                return array.indexOf(userId) === index; // get unique
            });
        });
    }, []);
    var onUnselectUsers = useCallback(function (userIds) {
        setSelectedUserIds(function (selectedUserIds) {
            return selectedUserIds.filter(function (userId) {
                return !userIds.includes(userId);
            });
        });
    }, []);
    var onDeleteUsers = useCallback(function (userIds) {
        setActionProcessing(QuickActionType.DELETE);
        dispatch(usersActions.deleteUsersFromAccount(userIds, userActionCallback));
    }, [dispatch, userActionCallback]);
    var onRequestDeleteUser = useCallback(function (userId) {
        setSelectedUserIds([userId]);
        setShowConfirmDeleteUserModal(true);
    }, [dispatch, userActionCallback]);
    var onSelectPage = useCallback(function (pageNumber) {
        setGetListedUsersRequestData(function (getListedUsersRequestData) {
            return __assign(__assign({}, getListedUsersRequestData), { pageNumber: pageNumber });
        });
    }, []);
    var onChangeUserRole = useCallback(function (userId) {
        setSelectedUserIds([userId]);
        setShowChangeUserRoleModal(true);
    }, []);
    var onBulkChangeUserRoles = useCallback(function () {
        setShowChangeUserRoleModal(true);
    }, []);
    var onMakePrimaryUser = useCallback(function (userId) {
        setMakePrimaryUserId(userId);
        setActionProcessing(QuickActionType.MAKE_PRIMARY_USER);
        setShowConfirmMakePrimaryUserModal(true);
    }, []);
    var actionBehaviours = useMemo(function () {
        var _a;
        return _a = {},
            _a[QuickActionType.DELETE] = onRequestDeleteUser,
            _a[QuickActionType.CHANGE_ROLE] = onChangeUserRole,
            _a[QuickActionType.MAKE_PRIMARY_USER] = onMakePrimaryUser,
            _a;
    }, [onRequestDeleteUser, onChangeUserRole, onMakePrimaryUser]);
    var listedUsersTitleBar = useMemo(function () {
        return (React.createElement("div", { className: "flex-container flex-center" },
            React.createElement("div", null,
                React.createElement("h1", null, "Users")),
            React.createElement(CreateButton, { onClick: function () { return setShowAddUserModal(true); } }, "Create new"),
            React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () { return setIsInBulkActionsMode(true); } }, "Edit users")));
    }, []);
    var listedUsersBulkActionsBar = useMemo(function () {
        var isAnyUsersSelected = !!selectedUserIds.length;
        return (React.createElement("div", { className: "flex-container flex-center flex-grow" },
            React.createElement("div", { className: "flex-container" },
                React.createElement("div", null,
                    React.createElement("h1", null, "Users")),
                React.createElement("span", null, isAnyUsersSelected
                    ? "You have " + selectedUserIds.length + " " + (selectedUserIds.length === 1 ? 'row' : 'rows') + " selected"
                    : 'Check the rows you wish to select')),
            React.createElement("div", { className: "flex-grow" }),
            React.createElement(Button, { isDisabled: !isAnyUsersSelected, onClick: function () { return onDeleteUsers(selectedUserIds); } }, "Delete"),
            React.createElement(Button, { isDisabled: !isAnyUsersSelected, onClick: onBulkChangeUserRoles }, "Change roles"),
            React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () {
                    setIsInBulkActionsMode(false);
                    setSelectedUserIds([]);
                } }, "Cancel")));
    }, [selectedUserIds]);
    var renderListedUsersTable = useMemo(function () {
        return (React.createElement(ListedUsersTable, { actionBehaviours: actionBehaviours, selectedUserIds: selectedUserIds, onSelectUsers: onSelectUsers, onUnselectUsers: onUnselectUsers, actionProcessing: actionProcessing, onSelectPage: onSelectPage }));
    }, [
        selectedUserIds,
        actionBehaviours,
        actionProcessing,
        onSelectUsers,
        onUnselectUsers,
        onSelectPage,
    ]);
    var searchFieldConfig = useMemo(function () {
        return {
            placeholder: 'Search',
            onSearchTermChange: function (searchTerm) {
                setGetListedUsersRequestData(function (getListedUsersRequestData) {
                    return __assign(__assign({}, getListedUsersRequestData), { searchTerm: searchTerm, pageNumber: 1 });
                });
            },
        };
    }, []);
    return (React.createElement(AuthenticatedPageWrapper, { className: "listed-users-page", searchFieldConfig: searchFieldConfig, title: isInBulkActionsMode ? listedUsersBulkActionsBar : listedUsersTitleBar },
        React.createElement("div", { className: "listed-users-table-container " + (isInBulkActionsMode ? 'bulk-actions-mode' : '') }, renderListedUsersTable),
        React.createElement(AddUserModal, { isOpen: showAddUserModal, onClose: function () { return setShowAddUserModal(false); }, onAddUserSuccess: function () {
                userActionCallback();
                setShowAddUserModal(false);
            } }),
        React.createElement(ChangeUserRoleModal, { isOpen: showChangeUserRoleModal, onClose: function () {
                setActionProcessing(null);
                setSelectedUserIds([]);
                setShowChangeUserRoleModal(false);
            }, selectedUserIds: selectedUserIds, onChangeUserRoleSuccess: function () {
                userActionCallback();
                setShowChangeUserRoleModal(false);
            } }),
        React.createElement(ConfirmDeleteUserModal, { isOpen: showConfirmDeleteUserModal, onClose: function () {
                setSelectedUserIds([]);
                setShowConfirmDeleteUserModal(false);
            }, onConfirmDeleteUser: function () {
                setShowConfirmDeleteUserModal(false);
                onDeleteUsers(selectedUserIds);
            } }),
        makePrimaryUserId && (React.createElement(ConfirmMakePrimaryUserModal, { userId: makePrimaryUserId, isOpen: showConfirmMakePrimaryUserModal, onClose: function () {
                setActionProcessing(null);
                setMakePrimaryUserId(undefined);
                setShowConfirmMakePrimaryUserModal(false);
            }, onMakePrimaryUserSuccess: function () {
                userActionCallback();
                setMakePrimaryUserId(undefined);
                setShowConfirmMakePrimaryUserModal(false);
            } }))));
};
export default UsersPage;
