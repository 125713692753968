import React, { useCallback, useState } from 'react';
import { TextareaField } from '@payaca/components/textareaField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import MiniLoader from '@bit/payaca-tech.payaca-core.component.mini-loader';
import './ItemGroupHeader.sass';
var ItemGroupHeader = function (_a) {
    var _b = _a.canDeleteItemGroup, canDeleteItemGroup = _b === void 0 ? true : _b, _c = _a.isDeleting, isDeleting = _c === void 0 ? false : _c, itemGroup = _a.itemGroup, onDeleteItemGroup = _a.onDeleteItemGroup, onUpdateItemGroup = _a.onUpdateItemGroup;
    var _d = useState(false), showGroupDescriptionTextField = _d[0], setShowGroupDescriptionTextField = _d[1];
    var _e = useState(itemGroup.description), description = _e[0], setDescription = _e[1];
    var _f = useState(false), isUpdateRequired = _f[0], setIsUpdateRequired = _f[1];
    var updateItemGroup = useCallback(function () {
        onUpdateItemGroup({
            description: description,
        });
    }, [onUpdateItemGroup, description]);
    useEffect(function () {
        if (isUpdateRequired) {
            updateItemGroup();
            setIsUpdateRequired(false);
        }
    }, [isUpdateRequired, updateItemGroup]);
    return (React.createElement("div", { className: "item-group-header" },
        React.createElement("div", { className: "item-group-header-description" }, showGroupDescriptionTextField || itemGroup.description ? (React.createElement(TextareaField, { name: "description", value: description, placeholder: "Item group description", rows: 1, onChange: function (value) {
                setDescription(value.description);
            }, autoSize: true, onBlur: function () {
                setIsUpdateRequired(true);
                if (!description) {
                    setShowGroupDescriptionTextField(false);
                }
            }, onChangeTimeout: function () { return setIsUpdateRequired(true); } })) : (React.createElement("span", { onClick: function () { return setShowGroupDescriptionTextField(true); }, className: "item-group-description-add-button" },
            React.createElement("span", null, "Add group description"),
            React.createElement(FontAwesomeIcon, { icon: faPen })))),
        canDeleteItemGroup && onDeleteItemGroup && (React.createElement("div", { className: "delete-container" }, isDeleting ? (React.createElement(MiniLoader, null)) : (React.createElement(FontAwesomeIcon, { icon: faTimes, onClick: onDeleteItemGroup }))))));
};
export default ItemGroupHeader;
