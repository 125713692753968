var getTotalSum = function (lineItemIds, lineItemsTotals) {
    var totalsArray = lineItemIds.map(function (x) { return lineItemsTotals[x].total; });
    return totalsArray.reduce(function (a, b) { return a + b; }, 0);
};
var calculateMultipleChoiceTotals = function (lineItemRelations, lineItemsTotals) {
    var total = getTotalSum(lineItemRelations.filter(function (x) { return x.isSelected; }).map(function (x) { return x.lineItemId; }), lineItemsTotals);
    var itemTotals = lineItemRelations.map(function (x) { return lineItemsTotals[x.lineItemId].total; });
    return {
        total: total,
        minTotal: itemTotals.length ? Math.min.apply(Math, itemTotals) : 0,
        maxTotal: itemTotals.length ? Math.max.apply(Math, itemTotals) : 0,
    };
};
var calculateOptionalTotals = function (lineItemRelations, lineItemsTotals) {
    var total = getTotalSum(lineItemRelations.filter(function (x) { return x.isSelected; }).map(function (x) { return x.lineItemId; }), lineItemsTotals);
    var maxTotal = getTotalSum(lineItemRelations.map(function (x) { return x.lineItemId; }), lineItemsTotals);
    return {
        total: total,
        minTotal: 0,
        maxTotal: maxTotal,
    };
};
var calculateLineItemTotals = function (lineItem, quantity) {
    var subtotal = ((lineItem === null || lineItem === void 0 ? void 0 : lineItem.price) || 0) * quantity;
    var vatTotal = (lineItem === null || lineItem === void 0 ? void 0 : lineItem.vatIncluded) && !(lineItem === null || lineItem === void 0 ? void 0 : lineItem.isReverseChargeVat)
        ? subtotal * (((lineItem === null || lineItem === void 0 ? void 0 : lineItem.vatAmount) || 0) / 100)
        : 0;
    return {
        subtotal: subtotal,
        vatTotal: vatTotal,
        total: subtotal + vatTotal,
    };
};
var calculateLineItemsTotals = function (lineItemRelations, lineItems) {
    var lineItemsTotals = {};
    lineItemRelations.forEach(function (lineItemRelation) {
        var lineItemId = lineItemRelation.lineItemId;
        var lineItemTotals = calculateLineItemTotals(lineItems[lineItemId], lineItemRelation.quantity);
        lineItemsTotals[lineItemId] = lineItemTotals;
    });
    return lineItemsTotals;
};
export var calculateLineItemGroupTotals = function (lineItemGroup, lineItems) {
    var lineItemRelations = lineItemGroup.lineItemRelations;
    var lineItemsObject = Object.fromEntries(lineItems.map(function (lineItem) { return [lineItem.id, lineItem]; }));
    var lineItemsTotals = calculateLineItemsTotals(lineItemRelations, lineItemsObject);
    var requiredLineItemRelations = lineItemRelations.filter(function (lineItemRelation) {
        return !lineItemRelation.isMultipleChoice && !lineItemRelation.isOptional;
    });
    var multipleChoiceLineItemRelations = lineItemRelations.filter(function (lineItemRelation) { return lineItemRelation.isMultipleChoice; });
    var optionalLineItemRelations = lineItemRelations.filter(function (lineItemRelation) { return lineItemRelation.isOptional; });
    var requiredTotal = getTotalSum(requiredLineItemRelations.map(function (x) { return x.lineItemId; }), lineItemsTotals);
    var multipleChoiceTotals = calculateMultipleChoiceTotals(multipleChoiceLineItemRelations, lineItemsTotals);
    var optionalTotals = calculateOptionalTotals(optionalLineItemRelations, lineItemsTotals);
    return {
        total: requiredTotal + multipleChoiceTotals.total + optionalTotals.total,
        minTotal: requiredTotal + multipleChoiceTotals.minTotal + optionalTotals.minTotal,
        maxTotal: requiredTotal + multipleChoiceTotals.maxTotal + optionalTotals.maxTotal,
    };
};
