import React, { useState } from 'react';
import { CollapsiblePanel, CollapsiblePanelStyleVariant, } from '@payaca/components/collapsiblePanel';
import './JobOptions.sass';
var JobOptions = function (_a) {
    var children = _a.children, title = _a.title;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    return (React.createElement("div", { className: "job-options" },
        React.createElement(CollapsiblePanel, { title: title, styleVariant: CollapsiblePanelStyleVariant.MINIMAL, isOpen: isOpen, onOpen: function () { return setIsOpen(true); }, onClose: function () { return setIsOpen(false); } },
            React.createElement(React.Fragment, null, children)))
    // <div className="job-options">
    //   <div className="job-options-heading">{title}</div>
    //   <div className="job-options-list">{children}</div>
    // </div>
    );
};
export default JobOptions;
