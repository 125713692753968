import React from 'react';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import './404.sass';
import BasicPageWrapper from '../pageWrappers/basicPageWrapper/BasicPageWrapper';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { actions as authActions } from '@bit/payaca-tech.payaca-core.store.auth';
var FourOhFourPage = function () {
    var history = useHistory();
    var dispatch = useDispatch();
    return (React.createElement(BasicPageWrapper, { className: "four-oh-four-page" },
        React.createElement("div", { className: "information-wrapper" },
            React.createElement("div", { className: "sad-face" },
                React.createElement("img", { src: require('./sad-face.svg').default })),
            React.createElement("div", { className: "information-container" },
                React.createElement("h1", null, "404"),
                React.createElement("h2", null, "Page not found"),
                React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, onClick: function () { return history.push('/'); } }, "Return to dashboard"),
                React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () { return dispatch(authActions.logout()); } }, "Log out")))));
};
export default FourOhFourPage;
