import React, { useCallback } from 'react';
import './HeaderInformationResourcesControl.sass';
import ResponsivePopperAndTrigger from '../responsivePopperAndTrigger/ResponsivePopperAndTrigger';
import HeaderInformationResourcesNavigation from '../headerInformationResourcesNavigation/HeaderInformationResourcesNavigation';
var HeaderInformationResourcesControl = function (_a) {
    var _b = _a.isSmallView, isSmallView = _b === void 0 ? false : _b;
    var renderTriggerContent = useCallback(function () {
        return React.createElement("img", { src: require('./question-circle.svg').default });
    }, []);
    var renderPopperContent = useCallback(function (isSmallView) {
        return React.createElement(HeaderInformationResourcesNavigation, null);
    }, []);
    return (React.createElement("div", { className: "header-information-resources-control" },
        React.createElement(ResponsivePopperAndTrigger, { isSmallView: isSmallView, useDrawerWhenInSmallView: false, popperPlacement: 'bottom', renderTriggerContent: renderTriggerContent, renderPopperContent: renderPopperContent, popperClassName: "header-information-resources-control-popper", drawerClassName: "header-information-resources-control-drawer" })));
};
export default HeaderInformationResourcesControl;
