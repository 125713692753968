var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isInvoice } from "./jobStatusHelper";
var conditionallyParseStringToNumber = function (val) { return (isNaN(Number(val)) ? val : Number(val)); };
var parsePrice = function (price) { return price / 100; };
var parseDisplayPrice = function (price) {
    return price ? parsePrice(price) : price;
};
var parseVat = function (vat) {
    return conditionallyParseStringToNumber(vat);
};
// round before trunc because js can't do maths
var formatPreChange = function (value) {
    return value ? Math.trunc(Math.round(value * 100)) : value;
};
/**
 * @param {number} price - price
 * @param {?string} currency - specified currency
 *
 * @return {string} currency formatted price
 */
var currencyPrice = function (price, currency) {
    var currencyStr = currency || "GBP";
    return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: currencyStr,
    }).format(price / 100);
};
/**
 * currencySymbol
 * @param currencyStr {string} currency - specified currency
 *
 * @return {string} currency symbol string
 */
var currencySymbol = function (currencyStr) {
    var _a;
    var parts = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: currencyStr || "GBP",
    }).formatToParts();
    return ((_a = parts.find(function (t) { return t.type === "currency"; })) === null || _a === void 0 ? void 0 : _a.value) || "£";
};
/**
 * formatPercentage - 2dp, removed trailing zeros
 * @param percentage {number} percentage number to format
 *
 * @return {string}
 */
var formatPercentage = function (percentage) {
    return parseFloat(percentage.toFixed(2));
};
/**
 * @param {number} price - price of item/job
 * @param {number} parsedVat - vat amount
 *
 * @return {number} total price
 */
var calculatedTotalAmount = function (price, parsedVat) {
    return price + price * (parsedVat / 100);
};
/**
 * @param {number} price - price of item/job
 * @param {number} parsedVat - vat amount
 * @param {?string} currency - specified currency
 *
 * @return {string} currency formatted total price
 */
var calculatedTotal = function (price, parsedVat, currency) {
    var total = calculatedTotalAmount(price, parsedVat);
    return currencyPrice(total, currency);
};
/**
 * @param {number} parsedVat -vat amount
 * @param {boolean} vatIncluded - specify if vat is included
 * @param {boolean} shortened - show shortened format
 *
 * @return {string} formatted vat total
 */
var vatTotalFormatted = function (parsedVat, vatIncluded, shortened, isReverseChargeVat) {
    if (isReverseChargeVat === void 0) { isReverseChargeVat = false; }
    var vatIncTotal = (shortened ? "" : "VAT") + " " + parsedVat + "%" + (isReverseChargeVat ? " REVERSE CHARGE" : "");
    return vatIncluded ? vatIncTotal : "No VAT";
};
/**
 * @param {number} price price of job
 * @param {number} depositPercent deposit percent
 * @param {?string} currency specified currency
 *
 * @return {string} currency formatted total deposit
 */
var depositTotal = function (price, depositPercent, currency) {
    var total = price * (depositPercent / 100);
    return currencyPrice(total, currency);
};
/**
 * itemTotal
 * @param {any} price - price of job
 * @param {number} vatAmount - vat amount
 * @param {boolean} vatIncluded - specify if vat is included
 * @param {number} quantity - quantity of a job line item
 *
 * @return {number} currency formatted item total inc. vat
 */
var itemTotal = function (price, vatAmount, vatIncluded, quantity, isReverseChargeVat) {
    if (isReverseChargeVat === void 0) { isReverseChargeVat = false; }
    var priceNo = parseInt(price, 10) || 0; // price is '' for new items, so set to 0 if NaN
    var itemsPrice = priceNo * quantity;
    return calculatedTotal(itemsPrice, vatIncluded && !isReverseChargeVat ? vatAmount : 0);
};
var itemTotalAmount = function (price, vatAmount, vatIncluded, quantity, isReverseChargeVat) {
    if (isReverseChargeVat === void 0) { isReverseChargeVat = false; }
    var priceNo = parseInt(price, 10) || 0; // price is '' for new items, so set to 0 if NaN
    var itemsPrice = priceNo * quantity;
    return calculatedTotalAmount(itemsPrice, vatIncluded && !isReverseChargeVat ? vatAmount : 0);
};
/**
 * lineItemsSubtotal
 * @param {array} items
 *
 * @return {number} subtotal of line items (markup/discount exc)
 */
var lineItemsSubtotal = function (items) {
    return items.reduce(function (overallSubtotal, lineItem) {
        // only add to subtotal if item is mandatory or has been selected
        return (overallSubtotal += lineItem.quantity * lineItem.price);
    }, 0);
};
/**
 * line items subtotal including markup
 * @param items
 * @return {number} subtotal of line items including markup
 */
var lineItemsSubtotalIncMarkup = function (items) {
    return items.reduce(function (overallSubtotal, lineItem) {
        return (overallSubtotal += lineItem.quantity * lineItem.priceIncMarkup);
    }, 0);
};
/**
 * getValueFromAmountPercentage
 * @param {number} total - total price calculating percentage from
 * @param {numnber} amount - parsed amount
 * @param {number} percentage - percentage
 *
 * @return {number} number value from amount and percentage
 */
var getValueFromAmountPercentage = function (total, amountPercentageObject) {
    var amount = amountPercentageObject.amount, percentage = amountPercentageObject.percentage;
    if (amount && !percentage) {
        return amount;
    }
    else if (percentage) {
        return total * (percentage / 100);
    }
    return null;
};
/**
 * Get array of mandatory and selected items (optional or multiple choice and selected) from itemGroups
 * @param itemGroups
 * @returns
 */
var _getMandatoryAndSelectedItems = function (itemGroups) {
    return itemGroups.reduce(function (acc, itemGroup) {
        // get mandatory or selected items on a group
        itemGroup.jobLineItems.forEach(function (item) {
            if (!(item.isMultipleChoice || item.isOptional) || item.isSelected) {
                acc.push(item);
            }
        });
        return acc;
    }, []);
};
/**
 * Filter out any items with no quantity
 * @param itemGroups
 * @returns
 */
var _getValidItemGroups = function (itemGroups) {
    return itemGroups.map(function (itemGroup) {
        var validItems = itemGroup.jobLineItems.filter(function (lineItem) { return lineItem.quantity; });
        return __assign(__assign({}, itemGroup), { jobLineItems: validItems });
    });
};
/**
 * Get array of mandatory items (not optional or multiple choice) from itemGroups
 * @param itemGroups
 * @returns
 */
var _getMandatoryItems = function (itemGroups) {
    return itemGroups.reduce(function (acc, itemGroup) {
        itemGroup.jobLineItems.forEach(function (item) {
            if (!item.isOptional && !item.isMultipleChoice) {
                acc.push(item);
            }
        });
        return acc;
    }, []);
};
/**
 * Decorate items with priceIncMarkup
 * @param itemGroups
 * @param markup
 */
var _decorateItemGroupsWithMarkup = function (itemGroups, markup) {
    var mandatoryItems = _getMandatoryItems(itemGroups);
    var mandatoryItemsJobSubtotal = lineItemsSubtotal(mandatoryItems);
    // go over each item group adding markup to each item
    return itemGroups.map(function (itemGroup) {
        var itemsIncMarkup = itemGroup.jobLineItems.map(function (lineItem) {
            // job markup percentage
            if (markup.percentage) {
                // add markup percentage to all items
                var markupValue = lineItem.price * (markup.percentage / 100);
                lineItem.priceIncMarkup = Math.round(lineItem.price + markupValue);
            }
            // job markup amount and not multiple choice group
            else if (markup.amount &&
                !lineItem.isMultipleChoice &&
                !lineItem.isOptional) {
                // only added to mandatory items (not optional or multiple choice)
                lineItem.priceIncMarkup = Math.round(lineItem.price +
                    (lineItem.price / mandatoryItemsJobSubtotal || 0) * markup.amount);
            }
            // no markup or job markup amount and multiple choice group or optional item
            else {
                lineItem.priceIncMarkup = Math.round(lineItem.price);
            }
            return lineItem;
        });
        return __assign(__assign({}, itemGroup), { jobLineItems: itemsIncMarkup });
    });
};
/**
 * Decorate items with priceIncMarkupDiscount
 * @param itemGroups
 * @param jobSubtotalIncMarkup
 * @param roundedDiscountValue
 * @returns
 */
var _decorateItemGroupsIncMarkupDiscount = function (itemGroupsIncMarkup, jobSubtotalIncMarkup, roundedDiscountValue) {
    // go over each item group adding priceIncMarkupDiscount to items
    return itemGroupsIncMarkup.map(function (itemGroup) {
        var itemsIncMarkupDiscount = itemGroup.jobLineItems.map(function (lineItem) {
            lineItem.priceIncMarkupDiscount = Math.round(lineItem.priceIncMarkup -
                (lineItem.priceIncMarkup / jobSubtotalIncMarkup || 0) *
                    (roundedDiscountValue || 0));
            return lineItem;
        });
        return __assign(__assign({}, itemGroup), { jobLineItems: itemsIncMarkupDiscount });
    });
};
/**
 * Get vat from item groups
 * @param itemGroupsIncMarkupDiscount
 * @returns
 */
var _getJobVat = function (itemGroupsIncMarkupDiscount) {
    return itemGroupsIncMarkupDiscount.reduce(function (acc, itemGroup) {
        // add vat to item if optional selected, multiple choice selected or mandatory
        itemGroup.jobLineItems.forEach(function (lineItem) {
            // selected item in multiple choice group or mandatory or selected optional
            if (!(lineItem.isOptional || lineItem.isMultipleChoice) ||
                lineItem.isSelected) {
                var lineItemTotal = lineItem.quantity * lineItem.priceIncMarkupDiscount;
                var lineItemVATTotal = lineItem.vatIncluded && !lineItem.isReverseChargeVat
                    ? lineItemTotal * (lineItem.vatAmount / 100)
                    : 0;
                acc.overallVATTotal += lineItemVATTotal;
                if (!acc.showVATIncl && lineItem.vatIncluded) {
                    acc.showVATIncl = true;
                }
            }
        });
        return acc;
    }, { overallVATTotal: 0, showVATIncl: false });
};
var _getJobCis = function (itemGroupsIncMarkupDiscount) {
    return itemGroupsIncMarkupDiscount.reduce(function (acc, itemGroup) {
        // add vat to item if optional selected, multiple choice selected or mandatory
        itemGroup.jobLineItems.forEach(function (lineItem) {
            // selected item in multiple choice group or mandatory or selected optional
            if (!(lineItem.isOptional || lineItem.isMultipleChoice) ||
                lineItem.isSelected) {
                var lineItemTotal = lineItem.quantity * lineItem.priceIncMarkupDiscount;
                if (lineItem.cisDeductionRate !== null) {
                    var lineItemCisTotal = lineItemTotal * (lineItem.cisDeductionRate / 100);
                    acc.jobCisTotal += lineItemCisTotal;
                    acc.hasAnyCisDeduction = true;
                }
            }
        });
        return acc;
    }, {
        jobCisTotal: 0,
        hasAnyCisDeduction: false,
    });
};
/**
 * Get job totals
 * @param {array} jobLineItemGroups - item groups
 * @param {JobTotalsAmountPercentagePayload} deposit - deposit amount and percentage
 * @param {JobTotalsAmountPercentagePayload} discount - discount amount and percentage
 * @param {JobTotalsAmountPercentagePayload} markup - markup amount and percentage
 * @param {string} jobType - job status
 *
 * @return {JobTotals} totals
 */
var jobTotals = function (jobLineItemGroups, deposit, minimumDeposit, discount, markup, jobType) {
    // filter out any invalid items
    var validItemGroups = _getValidItemGroups(jobLineItemGroups);
    // SUBTOTAL - job subtotal of all manadatory or selected items (exc markup/discount) - get markup/discount percentage values from this
    var mandatoryAndSelectedItems = _getMandatoryAndSelectedItems(validItemGroups);
    var jobSubtotal = lineItemsSubtotal(mandatoryAndSelectedItems);
    // MARKUP - Job markup - wrt jobSubtotal
    var totalMarkupValue = getValueFromAmountPercentage(jobSubtotal, markup);
    // item groups with markup
    var itemGroupsIncMarkup = _decorateItemGroupsWithMarkup(validItemGroups, markup);
    var jobSubtotalIncMarkup = lineItemsSubtotalIncMarkup(mandatoryAndSelectedItems);
    // DISCOUNT - Job discount - wrt jobSubtotalIncMarkup (itemsTotal + markup)
    var discountValue = getValueFromAmountPercentage(jobSubtotalIncMarkup, discount);
    var roundedDiscountValue = discountValue ? Math.round(discountValue) : null;
    // item groups with markup and discount
    var itemGroupsIncMarkupDiscount = _decorateItemGroupsIncMarkupDiscount(itemGroupsIncMarkup, jobSubtotalIncMarkup, roundedDiscountValue);
    var jobSubtotalIncMarkupDiscount = jobSubtotalIncMarkup - (roundedDiscountValue || 0);
    // VAT - Job total VAT - wrt jobSubtotalIncMarkupDiscount (itemsTotal + markup - discount)
    var jobVat = _getJobVat(itemGroupsIncMarkupDiscount);
    var roundedJobVatTotal = Math.round(jobVat.overallVATTotal);
    var jobCis = _getJobCis(itemGroupsIncMarkupDiscount);
    var roundedJobCisTotal = Math.round(jobCis.jobCisTotal);
    var jobTotal = jobSubtotalIncMarkupDiscount + roundedJobVatTotal - roundedJobCisTotal;
    // DEPOSIT - wrt jobTotal (itemsTotal + markup - discount + VAT)
    var depositValue = getValueFromAmountPercentage(jobTotal, deposit);
    var roundedDepositValue = depositValue !== null ? Math.round(depositValue) : null;
    // MINIMUM DEPOSIT - wrt jobTotal (itemsTotal + markup - discount + VAT)
    var minimumDepositValue = getValueFromAmountPercentage(jobTotal, minimumDeposit);
    var roundedMinimumDepositValue = minimumDepositValue !== null ? Math.round(minimumDepositValue) : null;
    var depositPaid = isInvoice(jobType) ? roundedDepositValue || 0 : 0;
    // TOTAL
    var jobTotalOutstanding = isInvoice(jobType)
        ? Math.round(jobTotal) - depositPaid
        : jobTotal;
    var amountDue = isInvoice(jobType)
        ? jobTotalOutstanding
        : roundedDepositValue || 0;
    return {
        jobSubtotal: jobSubtotal,
        discountValue: roundedDiscountValue,
        markupValue: totalMarkupValue,
        depositValue: roundedDepositValue,
        minimumDepositValue: roundedMinimumDepositValue,
        depositPaid: depositPaid,
        jobVATTotal: roundedJobVatTotal,
        showVATIncl: jobVat.showVATIncl,
        jobTotal: jobTotal,
        amountDue: amountDue,
        jobSubtotalIncMarkup: jobSubtotalIncMarkup,
        jobLineItemGroups: itemGroupsIncMarkupDiscount,
        jobCisTotal: roundedJobCisTotal,
        hasAnyCisDeduction: jobCis.hasAnyCisDeduction,
    };
};
/**
 * Get job totals from job
 * @param {Job} job - job
 * @param {JobLineItemGroup[]} item groups - optional item groups if job does not have item groups on it (item groups on job for dashboard pages)
 *
 * @return object of totals
 */
var jobTotalsFromJob = function (job, jobLineItemGroups) {
    var deposit = {
        amount: job.depositAmount,
        percentage: job.depositPercentage,
    };
    var discount = {
        amount: job.discountAmount,
        percentage: job.discountPercentage,
    };
    var markup = {
        amount: job.markupAmount,
        percentage: job.markupPercentage,
    };
    var minimumDeposit = {
        amount: job.minimumDepositAmount,
        percentage: job.minimumDepositPercentage,
    };
    return jobTotals(job.jobLineItemGroups || jobLineItemGroups, deposit, minimumDeposit, discount, markup, job.status);
};
/**
 * supplierTotals
 * @param price {any} user item price
 * @param supplierPrice {number} supplier item price
 *
 * @return totals
 */
var supplierTotals = function (price, supplierPrice, quantity) {
    // price is '' for new items, so set to 0 if NaN
    var itemPrice = parseInt(price, 10) || 0;
    // itemPrice will always be a number, check supplierPrice exists
    var netProfit = null;
    if (supplierPrice || supplierPrice === 0) {
        netProfit = itemPrice - supplierPrice;
    }
    var netProfitMargin = null;
    if ((netProfit || netProfit === 0) && itemPrice) {
        netProfitMargin = (netProfit / itemPrice) * 100;
    }
    return {
        netProfit: netProfit ? netProfit * quantity : netProfit,
        netProfitMargin: netProfitMargin,
    };
};
/**
 * Get display values for netProfitCurrency and netProfitMarginPercentage
 * @param price {any} user item price
 * @param supplierPrice {number} supplier item price
 * @param {?string} currency - specified currency
 *
 * @return totals
 */
var displaySupplierTotals = function (price, supplierPrice, quantity, currency) {
    if (quantity === void 0) { quantity = 100; }
    var _a = supplierTotals(price, supplierPrice, quantity), netProfit = _a.netProfit, netProfitMargin = _a.netProfitMargin;
    var netProfitCurrency = netProfit || netProfit === 0
        ? currencyPrice(netProfit, currency)
        : currencySymbol(currency);
    var netProfitMarginPercentage = netProfitMargin || netProfitMargin === 0
        ? formatPercentage(netProfitMargin) + "%"
        : "%";
    return {
        netProfitCurrency: netProfitCurrency,
        netProfitMarginPercentage: netProfitMarginPercentage,
    };
};
/**
 * Get jobNetProfit and jobNetProfitMargin for a job
 * @param items
 */
var jobSupplierTotals = function (jobLineItemGroups) {
    // get mandatory items, selected optional items and selected multiple choice group item
    var mandatoryAndSelectedItems = _getMandatoryAndSelectedItems(jobLineItemGroups);
    // check job is eligible for calculating totals - every selected line item should have a supplierPrice
    if (mandatoryAndSelectedItems.length === 0 ||
        mandatoryAndSelectedItems.filter(function (item) { return item.supplierPrice || item.supplierPrice === 0; }).length !== mandatoryAndSelectedItems.length) {
        return {};
    }
    // job net profit - summation of all items net profit using item priceIncMarkupDiscount
    var jobNetProfit = mandatoryAndSelectedItems.reduce(function (acc, item) {
        var netProfit = supplierTotals(item.priceIncMarkupDiscount, item.supplierPrice, item.quantity).netProfit;
        if (netProfit) {
            acc = acc + netProfit;
        }
        return acc;
    }, 0);
    // totalPriceIncMarkupDiscount and totalSupplierPrice using item priceIncMarkupDiscount
    var itemsTotals = mandatoryAndSelectedItems.reduce(function (acc, item) {
        acc = {
            totalPriceIncMarkupDiscount: acc.totalPriceIncMarkupDiscount + item.priceIncMarkupDiscount,
            totalSupplierPrice: item.supplierPrice
                ? acc.totalSupplierPrice + item.supplierPrice
                : acc.totalSupplierPrice,
        };
        return acc;
    }, { totalPriceIncMarkupDiscount: 0, totalSupplierPrice: 0 });
    // job net profit margin - using item priceIncMarkupDiscount
    var jobNetProfitMargin = itemsTotals.totalPriceIncMarkupDiscount
        ? ((itemsTotals.totalPriceIncMarkupDiscount -
            itemsTotals.totalSupplierPrice) /
            itemsTotals.totalPriceIncMarkupDiscount) *
            100
        : null;
    return {
        jobNetProfit: jobNetProfit,
        jobNetProfitMargin: jobNetProfitMargin,
    };
};
/**
 * displayJobSupplierTotals
 * @param items {array} user items
 * @param {?string} currency - specified currency
 *
 * @return totals
 */
var displayJobSupplierTotals = function (jobLineItemGroups, currency) {
    var _a = jobSupplierTotals(jobLineItemGroups), jobNetProfit = _a.jobNetProfit, jobNetProfitMargin = _a.jobNetProfitMargin;
    if (jobNetProfit === undefined && jobNetProfitMargin === undefined) {
        // no items or not all items have supplier price
        return {};
    }
    return {
        jobNetProfitCurrency: currencyPrice(jobNetProfit, currency),
        jobNetProfitMarginPercentage: jobNetProfitMargin
            ? formatPercentage(jobNetProfitMargin) + "%"
            : "%",
    };
};
/**
 * Get minimum job total of quote (mandatory items, lowest priced multiple choice item)
 * @param itemsIncMarkupDiscount
 *
 * @returns {number} minimum total cost of job
 */
var minimumJobTotal = function (jobLineItemGroups) {
    return jobLineItemGroups.reduce(function (acc, jobLineItemGroup) {
        // multiple choice items - item totals sorted ascending
        var sortedItemsTotals = jobLineItemGroup.jobLineItems
            .filter(function (item) { return item.isMultipleChoice; })
            .map(function (item) {
            return itemTotalAmount(item.priceIncMarkupDiscount, item.vatAmount, item.vatIncluded, item.quantity, item.isReverseChargeVat);
        })
            .sort(function (a, b) { return a - b; });
        // get lowest price multiple choice item
        var multipleChoiceItemCost = sortedItemsTotals.length
            ? sortedItemsTotals[0]
            : 0;
        acc += multipleChoiceItemCost;
        // add cost of mandatory items
        jobLineItemGroup.jobLineItems
            .filter(function (item) { return !item.isOptional && !item.isMultipleChoice; })
            .forEach(function (item) {
            var itemTotal = itemTotalAmount(item.priceIncMarkupDiscount, item.vatAmount, item.vatIncluded, item.quantity, item.isReverseChargeVat);
            acc += itemTotal;
        });
        return acc;
    }, 0);
};
/**
 * Get maximum job total of quote (mandatory items, highest priced multiple choice item and all optional items)
 * @param itemsIncMarkupDiscount
 *
 * @returns {number} maximum total cost of job
 */
var maximumJobTotal = function (jobLineItemGroups) {
    return jobLineItemGroups.reduce(function (acc, jobLineItemGroup) {
        // go over each job line item group
        // multiple choice group - item totals sorted descending
        var sortedItemsTotals = jobLineItemGroup.jobLineItems
            .filter(function (item) { return item.isMultipleChoice; })
            .map(function (item) {
            return itemTotalAmount(item.priceIncMarkupDiscount, item.vatAmount, item.vatIncluded, item.quantity, item.isReverseChargeVat);
        })
            .sort(function (a, b) { return b - a; });
        // get highest priced multiple choice item
        var multipleChoiceItemCost = sortedItemsTotals.length
            ? sortedItemsTotals[0]
            : 0;
        acc += multipleChoiceItemCost;
        // add cost of all other non-multiple choice items
        jobLineItemGroup.jobLineItems
            .filter(function (item) { return !item.isMultipleChoice; })
            .forEach(function (item) {
            var itemTotal = itemTotalAmount(item.priceIncMarkupDiscount, item.vatAmount, item.vatIncluded, item.quantity, item.isReverseChargeVat);
            acc += itemTotal;
        });
        return acc;
    }, 0);
};
/**
 * Add deposit/discount/markup/minimumDeposit amounts to job before sending to BE
 * TODO: could be done on BE as this is called on creating job on mobile and web
 * @param job
 * @param jobLineItemGroups
 * @returns
 */
var addJobAmountAmendments = function (job, jobLineItemGroups) {
    var jobDetails = job;
    var jobItemGroups = job.jobLineItemGroups || jobLineItemGroups;
    var jobTotals = jobTotalsFromJob(job, jobItemGroups);
    if (jobItemGroups) {
        if (jobDetails.depositPercentage) {
            jobDetails.depositAmount = jobTotals.depositValue;
        }
        if (jobDetails.discountPercentage) {
            jobDetails.discountAmount = jobTotals.discountValue;
        }
        if (jobDetails.markupPercentage) {
            jobDetails.markupAmount = jobTotals.markupValue;
        }
        if (jobDetails.minimumDepositPercentage) {
            jobDetails.minimumDepositAmount = jobTotals.minimumDepositValue;
        }
    }
    return jobDetails;
};
/**
 * Get minimum total of a line item group
 * @param groupLineItems
 *
 * @returns {number} minimum total cost of group
 */
var groupTotals = function (groupLineItems) {
    var groupAsJobLineItemGroup = [
        { id: 0, description: "", jobId: 0, jobLineItems: groupLineItems },
    ];
    // filter out any invalid items
    var validItemGroups = _getValidItemGroups(groupAsJobLineItemGroup);
    var mandatoryAndSelectedItems = _getMandatoryAndSelectedItems(validItemGroups);
    // item groups with 0 markup
    var itemGroupsIncMarkup = _decorateItemGroupsWithMarkup(validItemGroups, {
        amount: 0,
        percentage: 0,
    });
    var jobSubtotalIncMarkup = lineItemsSubtotal(mandatoryAndSelectedItems);
    // item groups with 0 markup and discount
    var itemGroupsIncMarkupDiscount = _decorateItemGroupsIncMarkupDiscount(itemGroupsIncMarkup, jobSubtotalIncMarkup, null);
    var jobSubtotalIncMarkupDiscount = jobSubtotalIncMarkup - 0;
    // VAT - Job total VAT - wrt jobSubtotalIncMarkupDiscount (itemsTotal + markup - discount)
    var jobVat = _getJobVat(itemGroupsIncMarkupDiscount);
    var roundedJobVatTotal = Math.round(jobVat.overallVATTotal);
    var jobCis = _getJobCis(itemGroupsIncMarkupDiscount);
    var roundedJobCisTotal = Math.round(jobCis.jobCisTotal);
    var jobTotal = jobSubtotalIncMarkupDiscount + roundedJobVatTotal - roundedJobCisTotal;
    var minimumGroupTotal = minimumJobTotal(itemGroupsIncMarkupDiscount);
    var maximumGroupTotal = maximumJobTotal(itemGroupsIncMarkupDiscount);
    return {
        minimumGroupTotal: minimumGroupTotal,
        maximumGroupTotal: maximumGroupTotal,
        groupTotal: jobTotal,
    };
};
export { addJobAmountAmendments, calculatedTotal, calculatedTotalAmount, currencyPrice, currencySymbol, depositTotal, displayJobSupplierTotals, displaySupplierTotals, formatPercentage, formatPreChange, groupTotals, itemTotal, jobSupplierTotals, jobTotals, jobTotalsFromJob, maximumJobTotal, minimumJobTotal, parseDisplayPrice, parsePrice, parseVat, supplierTotals, vatTotalFormatted, };
