import React, { useCallback, useEffect, useMemo, useState } from 'react';
import qs from 'qs';
import { LoginProvider } from '@/helpers/providerHelper';
import './LoginControl.sass';
import SocialLoginButton from '../socialLoginButton/SocialLoginButton';
import { ErrorMessage } from '@payaca/components/feedbackMessage';
import { useHistory } from 'react-router';
import LoginForm from '../loginForm/LoginForm';
var ADDRESS = process.env.REACT_APP_API_HOST;
var socialLoginProviders = [
    LoginProvider.FACEBOOK,
    LoginProvider.GOOGLE,
    LoginProvider.XERO,
    LoginProvider.APPLE,
];
var LoginControl = function () {
    var history = useHistory();
    var _a = useState(), loginError = _a[0], setLoginError = _a[1];
    useEffect(function () {
        var params = {
            clientId: window.location.hostname.startsWith('localhost')
                ? 'staging.webapp.payaca.com'
                : window.location.hostname,
            redirectURI: ADDRESS + "/apple/callback",
            scope: 'name email',
            responseMode: 'form_post',
        };
        // @ts-ignore
        window.AppleID.auth.init(params);
        var query = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });
        if (query.error) {
            // @ts-ignore
            setLoginError(decodeURIComponent(query.error));
        }
    }, []);
    var goToForgotPasswordPage = useCallback(function () {
        history.push('/forgotPassword');
    }, [history]);
    var socialLoginButtons = useMemo(function () {
        return socialLoginProviders.map(function (socialLoginProvider, index) {
            return (React.createElement(SocialLoginButton, { key: "social-login-button-" + index, provider: socialLoginProvider, buttonPreText: 'Log in With' }));
        });
    }, []);
    return (React.createElement("div", { className: "login-control" },
        React.createElement("div", { className: "social-login-buttons-container" }, socialLoginButtons),
        React.createElement("div", { className: "flex-container flex-center login-with-email-prompt" },
            React.createElement("hr", null),
            React.createElement("span", null, "Or log in with email"),
            React.createElement("hr", null)),
        React.createElement("div", null,
            React.createElement(LoginForm, null),
            loginError && React.createElement(ErrorMessage, { message: loginError }),
            React.createElement("div", { className: "forgot-password-prompt" },
                "Forgot your password?",
                ' ',
                React.createElement("a", { onClick: goToForgotPasswordPage }, "Reset password")))));
};
export default LoginControl;
