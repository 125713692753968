const Routes = {
  DEALS: 'deals',
  DEAL_PAGE: 'dealPage',
  CUSTOMERS: 'customers',
  USERS: 'users',
  ITEM_GROUPS: 'itemGroups',
  NEW_QUOTE: 'newQuote',
  EDIT_QUOTE: 'editQuote',
  EDIT_NEW_QUOTE: 'editNewQuote',
  EDIT_NEW_INVOICE: 'editNewInvoice',
  DUPLICATE_QUOTE: 'duplicateQuote',
  NEW_INVOICE: 'newInvoice',
  EDIT_INVOICE: 'editInvoice',
  DUPLICATE_INVOICE: 'duplicateInvoice',
  NEW_ITEM: 'newItem',
  EDIT_ITEM: 'editItem',
  EDIT_ITEM_GROUP: 'editItemGroup',
  NEW_CUSTOMER: 'newCustomer',
  EDIT_CUSTOMER: 'editCustomer',
  PERSONAL_SETTINGS: 'personalSettings',
  COMPANY_SETTINGS: 'companySettings',
  FINANCE_OPTIONS: 'financeOptions',
  CONNECTIONS: 'connections',
  UPGRADE_ACCOUNT: 'upgradeAccount',
  ENABLE_CUSTOMER_PAYMENTS: 'enableCustomerPayments',
  VERIFY_USER: 'verifyUserAccount',
  VERIFY_USER_HOLDING_PAGE: 'verifyUserHoldingPage',
  LOGIN: 'login',
  APPLE_CALLBACK: 'appleCallback',
  XERO_CALLBACK: 'xeroCallback',
  REGISTER: 'register',
  FORGOT_PASSWORD: 'forgotPassword',
  RESET: 'reset',
  USER_INVITE: 'userInvite',
  ANALYTICS_DASHBOARD: 'analyticsDashboard',
  RESOURCES: 'resources',
  SETUP_ACCOUNT_PAGE: 'setupAccountPage',
  SETUP_USER: 'setupUser',
  LOGIN_OAUTH: 'loginOAuth',
  DEV: 'dev',
  GRANT_ACCESS: 'grantAccess',
  SUBSIDISED_FINANCE_INFORMATION_OVERVIEW:
    'subsidisedFinanceInformationOverview',
  SUBSIDISED_FINANCE_INFORMATION_FORM: 'subsidisedFinanceInformationForm',
  REFER_A_FRIEND: 'referAFriend',
};

export { Routes };
