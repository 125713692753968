import React from "react";
import "./LabelValuePair.css";
var LabelValuePair = function (_a) {
    var label = _a.label, value = _a.value, _b = _a.suffixLabelWith, suffixLabelWith = _b === void 0 ? ":" : _b;
    return (React.createElement("div", { className: "label-value-pair" },
        React.createElement("span", { className: "label" },
            label,
            suffixLabelWith),
        React.createElement("span", { className: "value" }, value)));
};
export default LabelValuePair;
