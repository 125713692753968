var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useMemo, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { Folder, Upload } from 'react-iconly';
import Button, { ButtonStyleVariant } from '../button/Button';
import FieldLabel from '../fieldLabel/FieldLabel';
import InputWrapper from '../inputWrapper/InputWrapper';
import './FileUploadField.sass';
var FileUploadField = function (_a) {
    var name = _a.name, value = _a.value, label = _a.label, description = _a.description, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, _c = _a.isRequired, isRequired = _c === void 0 ? false : _c, acceptFileTypes = _a.acceptFileTypes, _d = _a.allowMultipleUploads, allowMultipleUploads = _d === void 0 ? false : _d, onChange = _a.onChange, onTouch = _a.onTouch, _e = _a.showAsDragAndDrop, showAsDragAndDrop = _e === void 0 ? false : _e, _f = _a.showUploadImageButton, showUploadImageButton = _f === void 0 ? false : _f;
    var inputElementRef = useRef(null);
    var onUploadTriggerClick = useCallback(function () {
        var _a;
        (_a = inputElementRef.current) === null || _a === void 0 ? void 0 : _a.click();
    }, [inputElementRef]);
    var onInputChange = useCallback(function (event) {
        var _a;
        console.log(event.target.files);
        onChange &&
            onChange((_a = {},
                _a[event.target.name] = event.target.files,
                _a));
        onTouch && onTouch(event.target.name);
        // Allow uploading of the same file
        if (event.target && event.target.value) {
            event.target.value = '';
        }
    }, [onChange, onTouch]);
    var fileListElement = useMemo(function () {
        if (!value)
            return;
        var fileArray = Array.prototype.slice.call(value);
        return (React.createElement("ul", null, fileArray.map(function (file, index) {
            return React.createElement("li", { key: index }, file.name);
        })));
    }, [value]);
    var onDrop = useCallback(function (files) {
        var _a;
        var filesToUpload = files.slice(0, allowMultipleUploads ? files.length : 1);
        onChange &&
            onChange((_a = {},
                _a[name] = filesToUpload,
                _a));
        onTouch && onTouch(name);
    }, [allowMultipleUploads, onChange, onTouch]);
    var acceptedFiles = useMemo(function () { return acceptFileTypes === null || acceptFileTypes === void 0 ? void 0 : acceptFileTypes.join(','); }, [acceptFileTypes]);
    var _g = useDropzone({
        disabled: isDisabled,
        onDrop: onDrop,
        accept: acceptedFiles,
    }), getRootProps = _g.getRootProps, getInputProps = _g.getInputProps, isDragActive = _g.isDragActive;
    return (React.createElement("div", { className: "file-upload-field" + (showAsDragAndDrop ? ' drag-and-drop' : '') },
        React.createElement(FieldLabel, { label: label, isRequired: isRequired, description: description }),
        !showAsDragAndDrop ? (React.createElement(InputWrapper, { isDisabled: isDisabled },
            React.createElement("input", { ref: inputElementRef, type: "file", style: { display: 'none' }, onChange: onInputChange, multiple: allowMultipleUploads, name: name, accept: acceptedFiles, disabled: isDisabled }),
            React.createElement("span", null,
                React.createElement("span", { className: "file-upload-trigger", onClick: !isDisabled ? onUploadTriggerClick : undefined },
                    React.createElement("span", null, allowMultipleUploads ? 'Choose files' : 'Choose file'),
                    React.createElement(FontAwesomeIcon, { icon: faUpload })),
                (acceptFileTypes === null || acceptFileTypes === void 0 ? void 0 : acceptFileTypes.length) && (React.createElement("small", { className: "accepted-file-types" },
                    "Accepted file types: ",
                    acceptedFiles))))) : (React.createElement(React.Fragment, null,
            React.createElement("div", __assign({}, getRootProps(), { className: "drag-drop-wrapper" + (isDragActive ? ' dragging' : '') + (isDisabled ? ' disabled' : '') }),
                React.createElement(Folder, { set: "light" }),
                React.createElement("input", __assign({}, getInputProps())),
                React.createElement("span", null, allowMultipleUploads
                    ? 'Drag & drop your files here'
                    : 'Drag & drop your file here')),
            React.createElement("span", { className: "or-text" }, "or"),
            React.createElement("input", { ref: inputElementRef, type: "file", style: { display: 'none' }, onChange: onInputChange, multiple: allowMultipleUploads, name: name, accept: acceptedFiles, disabled: isDisabled }),
            showUploadImageButton ? (React.createElement(Button, { onClick: function () {
                    onUploadTriggerClick();
                }, className: "upload-image" },
                React.createElement(Upload, { set: "light" }),
                "Upload image")) : (React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, onClick: function () {
                    onUploadTriggerClick();
                } }, "Search your files")))),
        React.createElement("div", { className: "file-list-container" }, fileListElement)));
};
export default FileUploadField;
