import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ListedItemGroupControl from '@/ui/components/listedItemGroupControl/ListedItemGroupControl';
import { actions as lineItemGroupActions } from '@bit/payaca-tech.payaca-core.store.line-item-groups';
import './ListedItemGroupsControl.css';
var ListedItemGroupsControl = function (_a) {
    var onClick = _a.onClick, _b = _a.showAddGroupButton, showAddGroupButton = _b === void 0 ? false : _b;
    var dispatch = useDispatch();
    var history = useHistory();
    var createdLineItemGroupId = useSelector(function (state) { return state.lineItemGroups.createLineItemGroupSuccessId; });
    var lineItemGroups = useSelector(function (state) { return state.lineItemGroups.lineItemGroups; });
    var listedLineItemGroups = useMemo(function () { return Object.values(lineItemGroups); }, [lineItemGroups]);
    useEffect(function () {
        dispatch(lineItemGroupActions.requestGetLineItemGroups());
        return function () {
            dispatch(lineItemGroupActions.clearCreateLineItemGroup());
        };
    }, []);
    useEffect(function () {
        if (createdLineItemGroupId) {
            history.push("/itemGroups/" + createdLineItemGroupId);
        }
    }, [createdLineItemGroupId]);
    return (React.createElement("div", { className: "listed-item-groups-control" + (!(listedLineItemGroups === null || listedLineItemGroups === void 0 ? void 0 : listedLineItemGroups.length) && !showAddGroupButton
            ? ' no-groups-description'
            : '') },
        listedLineItemGroups.map(function (lineItemGroup, i) {
            var lineItemGroupEntity = lineItemGroup.entity;
            return (React.createElement(ListedItemGroupControl, { key: "item-group-" + i, itemGroup: lineItemGroupEntity, onClick: function () { return onClick(lineItemGroupEntity); } }));
        }),
        showAddGroupButton && (React.createElement(ListedItemGroupControl, { onClick: function () {
                return dispatch(lineItemGroupActions.requestCreateLineItemGroup());
            } })),
        !showAddGroupButton && !(listedLineItemGroups === null || listedLineItemGroups === void 0 ? void 0 : listedLineItemGroups.length) && (React.createElement("div", null,
            "You don't have any item groups, head over to your",
            ' ',
            React.createElement("a", { className: "underline", href: "/itemGroups", target: "_blank", rel: "noopener noreferrer" }, "Item groups"),
            ' ',
            "page to add one."))));
};
export default ListedItemGroupsControl;
