// ======================================================================================================
// Roles config
// - Define new routes and routes specifically for certain roles
// ======================================================================================================
// TODO: include all relevant role configurations here i.e. menu items

import { Routes } from './RoutesConfig';

const rolesConfig = {
  admin: {
    routes: [
      Routes.USERS,
      Routes.COMPANY_SETTINGS,
      Routes.CONNECTIONS,
      Routes.UPGRADE_ACCOUNT,
      Routes.ENABLE_CUSTOMER_PAYMENTS,
      Routes.ANALYTICS_DASHBOARD,
      Routes.SUBSIDISED_FINANCE_INFORMATION_OVERVIEW,
      Routes.SUBSIDISED_FINANCE_INFORMATION_FORM,
      Routes.DEALS,
      Routes.DEAL_PAGE,
    ],
  },
  common: {
    routes: [
      Routes.CUSTOMERS,
      Routes.ITEMS,
      Routes.ITEM_GROUPS,
      Routes.EDIT_ITEM_GROUP,
      Routes.NEW_QUOTE,
      Routes.EDIT_QUOTE,
      Routes.EDIT_NEW_QUOTE,
      Routes.EDIT_NEW_INVOICE,
      Routes.DUPLICATE_QUOTE,
      Routes.NEW_INVOICE,
      Routes.EDIT_INVOICE,
      Routes.DUPLICATE_INVOICE,
      Routes.NEW_ITEM,
      Routes.EDIT_ITEM,
      Routes.NEW_CUSTOMER,
      Routes.EDIT_CUSTOMER,
      Routes.PERSONAL_SETTINGS,
      Routes.FINANCE_OPTIONS,
      Routes.VERIFY_USER,
      Routes.VERIFY_USER_HOLDING_PAGE,
      Routes.RESET,
      Routes.USER_INVITE,
      Routes.RESOURCES,
      Routes.GRANT_ACCESS,
      Routes.LOGIN_OAUTH,
      Routes.REFER_A_FRIEND,
    ],
  },
};

export default rolesConfig;
