import React, { useState } from 'react';
import './EditJobSaveFeedback.sass';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getJob } from '@/utils/stateAccessors';
import { useEffect } from 'react';
import { usePrevious } from '@/utils/customHooks';
import MiniLoader from '@bit/payaca-tech.payaca-core.component.mini-loader';
import { ErrorMessage, SuccessMessage, } from '@payaca/components/feedbackMessage';
var EditJobSaveFeedback = function (_a) {
    var jobId = _a.jobId;
    var _b = useState(false), showSavedMessage = _b[0], setShowSavedMessage = _b[1];
    var _c = useState(), showSavedMessageTimeout = _c[0], setShowSavedMessageTimeout = _c[1];
    var job = useSelector(function (state) { return getJob(state, jobId); });
    var jobUpdateResult = useSelector(function (state) {
        return (state.jobsStore.jobUpdateResults &&
            state.jobsStore.jobUpdateResults[jobId]);
    });
    var jobLineItemUpdateResults = useSelector(function (state) {
        if (!state.jobsStore.jobLineItemUpdateResults || !job)
            return false;
        var jobLineItemIds = job.jobLineItemIds;
        return jobLineItemIds
            .map(function (jobLineItemId) {
            return state.jobsStore.jobLineItemUpdateResults[jobLineItemId];
        })
            .filter(function (x) { return !!x; });
    });
    var jobLineItemGroupUpdateResults = useSelector(function (state) {
        if (!state.jobsStore.jobLineItemGroupUpdateResults || !job)
            return false;
        var jobLineItemGroupIds = job.jobLineItemGroupIds;
        return jobLineItemGroupIds
            .map(function (jobLineItemGroupId) {
            return state.jobsStore.jobLineItemGroupUpdateResults[jobLineItemGroupId];
        })
            .filter(function (x) { return !!x; });
    });
    var hasJobUpdateError = useMemo(function () {
        return (jobUpdateResult === null || jobUpdateResult === void 0 ? void 0 : jobUpdateResult.isUpdatedSuccessfully) === false;
    }, [jobUpdateResult]);
    var hasAnyJobLineItemUpdateErrors = useMemo(function () {
        if (!jobLineItemUpdateResults)
            return false;
        return jobLineItemUpdateResults.some(function (x) { return (x === null || x === void 0 ? void 0 : x.isUpdatedSuccessfully) === false; });
    }, [jobLineItemUpdateResults]);
    var hasAnyJobLineItemGroupUpdateErrors = useMemo(function () {
        if (!jobLineItemGroupUpdateResults)
            return false;
        return jobLineItemGroupUpdateResults.some(function (x) { return (x === null || x === void 0 ? void 0 : x.isUpdatedSuccessfully) === false; });
    }, [jobLineItemGroupUpdateResults]);
    var hasAnyErrors = useMemo(function () {
        return (hasAnyJobLineItemGroupUpdateErrors ||
            hasAnyJobLineItemUpdateErrors ||
            hasJobUpdateError);
    }, [
        hasAnyJobLineItemGroupUpdateErrors,
        hasAnyJobLineItemUpdateErrors,
        hasJobUpdateError,
    ]);
    var jobUpdateErrorMessage = useMemo(function () {
        if (hasAnyErrors) {
            return "Something went wrong - some of your changes haven't been saved";
        }
    }, [hasAnyErrors]);
    var isModifyingJobLineItemsOrJobLineItemGroups = useSelector(function (state) {
        return (state.jobsStore.isUpdatingJobLineItem ||
            state.jobsStore.isDeletingJobLineItem ||
            state.jobsStore.isCreatingJobLineItem ||
            state.jobsStore.isCreatingJobLineItemGroup ||
            state.jobsStore.isUpdatingJobLineItemGroup ||
            state.jobsStore.isDeletingJobLineItemGroup ||
            state.jobsStore.isAddingLineItemGroupToJob ||
            state.jobsStore.isAddingAttachmentToJobLineItem ||
            state.jobsStore.isRemovingAttachmentFromJobLineItem);
    });
    var isUpdatingJob = useSelector(function (state) {
        return state.jobsStore.isUpdatingJob;
    });
    var isPersistingCustomer = useSelector(function (state) {
        return state.customer.isPersistingCustomer;
    });
    var isSettingDealCustomer = useSelector(function (state) {
        return state.deals.isSettingDealCustomer;
    });
    var isProcessing = useMemo(function () {
        return (isUpdatingJob ||
            isPersistingCustomer ||
            isSettingDealCustomer ||
            isModifyingJobLineItemsOrJobLineItemGroups);
    }, [
        isUpdatingJob,
        isPersistingCustomer,
        isSettingDealCustomer,
        isModifyingJobLineItemsOrJobLineItemGroups,
    ]);
    var previousIsProcessing = usePrevious(isProcessing);
    useEffect(function () {
        if (!isProcessing && previousIsProcessing && !hasAnyErrors) {
            setShowSavedMessage(true);
            setShowSavedMessageTimeout(setTimeout(function () {
                setShowSavedMessage(false);
            }, 1000));
        }
    }, [isProcessing]);
    return (React.createElement("div", { className: "edit-job-save-feedback flex-container flex-center" },
        React.createElement("div", { className: "saved-message-container " + (showSavedMessage && !isProcessing ? '' : ' hidden') },
            React.createElement(SuccessMessage, { message: 'Progress saved!' })),
        React.createElement("div", { className: "error-message-container " + (jobUpdateErrorMessage ? '' : ' hidden') }, jobUpdateErrorMessage && (React.createElement(ErrorMessage, { message: jobUpdateErrorMessage }))),
        isProcessing && (React.createElement("div", { className: "processing-container" },
            React.createElement(MiniLoader, null)))));
};
export default EditJobSaveFeedback;
