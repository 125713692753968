var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SwitchField } from '@payaca/components';
import { actions as accountSettingsActions } from '@/api/accountSettings';
var CompanySettingsFinance = function (_a) {
    var readOnly = _a.readOnly;
    var dispatch = useDispatch();
    var pendingFinancePlans = useSelector(function (state) { return state.accountSettings.pendingFinancePlans || []; });
    var onFieldChange = useCallback(function (change, index) {
        var updatedFinancePlans = pendingFinancePlans
            .slice(0, index)
            .concat([change])
            .concat(pendingFinancePlans.slice(index + 1));
        dispatch(accountSettingsActions.storeAccountPendingFinancePlans(updatedFinancePlans));
    }, [dispatch, pendingFinancePlans]);
    return (React.createElement("div", { className: "company-settings-finance" },
        React.createElement("div", { className: "settings-sub-section" },
            React.createElement("h4", null, "Bespoke finance plans"),
            React.createElement("p", null, "You can choose the plans that you want to be available to customers."),
            pendingFinancePlans.map(function (pendingFinancePlan, i) {
                return (React.createElement(SwitchField, { key: i, name: "isFinancePlanEnabled", value: !pendingFinancePlan.isDisabled, onChange: function (value) {
                        onFieldChange(__assign(__assign({}, pendingFinancePlan), { isDisabled: !value.isFinancePlanEnabled }), i);
                    }, label: pendingFinancePlan.text, isDisabled: readOnly }));
            }))));
};
export default CompanySettingsFinance;
