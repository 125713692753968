import React from 'react';
import './DealHeader.sass';
import DealCustomerControl from '../dealCustomerControl/DealCustomerControl';
import DealInformationControl from '../dealInformationControl/DealInformationControl';
import { ResponsiveViewWrapper } from '@payaca/components/responsiveViewWrapper';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';
var DealHeader = function (_a) {
    var dealId = _a.dealId;
    var history = useHistory();
    return (React.createElement(ResponsiveViewWrapper, { className: 'deal-header flex-container', downBreakpointSm: 750 },
        React.createElement("div", { className: "deal-information-control-container" },
            React.createElement("div", { className: "button-container" },
                React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, iconBefore: faChevronLeft, onClick: function () { return history.push('/deals'); } }, "Back")),
            React.createElement(DealInformationControl, { dealId: dealId })),
        React.createElement(DealCustomerControl, { dealId: dealId })));
};
export default DealHeader;
