var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useMemo, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IntercomAPI } from 'react-intercom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { actions as subsidisedFinanceInformationActions } from '@payaca/store/subsidisedFinanceInformation';
import { getFieldValidatorsForGetStarted } from '@payaca/helpers/subsidisedFinanceInformationValidationHelper';
import { CompanyType, } from '@payaca/types/subsidisedFinanceInformationTypes';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { MiniLoader, ValidatedForm } from '@payaca/components';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { DropdownField } from '@payaca/components/dropdownField';
import { BasicField } from '@payaca/components/basicField';
import CompaniesHouseCompanyPreview from '../companiesHouseCompanyPreview/CompaniesHouseCompanyPreview';
import './GetStartedFormSection.css';
var COMPANY_TYPES_OPTIONS = [
    { value: CompanyType.PLC, label: CompanyType.PLC },
    { value: CompanyType.SOLE_TRADER, label: CompanyType.SOLE_TRADER },
    { value: CompanyType.PARTNERSHIP_LLP, label: CompanyType.PARTNERSHIP_LLP },
];
var GetStartedFormSection = function () {
    var dispatch = useDispatch();
    var legalBusinessName = useSelector(function (state) {
        var _a;
        return (_a = state.users.myProfile.accounts[0]) === null || _a === void 0 ? void 0 : _a.legalBusinessName;
    });
    var companiesHouseCompanyPreview = useSelector(function (state) {
        return state.subsidisedFinanceInformation.companiesHouseCompanyPreview;
    });
    var isGettingCompaniesHouseCompanyPreview = useSelector(function (state) {
        return state.subsidisedFinanceInformation.isGettingCompaniesHouseCompanyPreview;
    });
    useEffect(function () {
        dispatch(subsidisedFinanceInformationActions.requestGetBusinessInformation());
    }, []);
    var isGettingOrPersisting = useSelector(function (state) {
        var _a, _b;
        return (((_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.isGettingBusinessInformation) || ((_b = state.subsidisedFinanceInformation) === null || _b === void 0 ? void 0 : _b.isPersistingGetStarted));
    });
    var fieldValidators = useMemo(function () {
        return getFieldValidatorsForGetStarted();
    }, []);
    var requestGetCompaniesHouseCompanyPreview = useCallback(function (companyNumber) {
        dispatch(subsidisedFinanceInformationActions.requestGetCompaniesHouseCompanyPreview(companyNumber));
    }, [dispatch]);
    var onContinue = useCallback(function (modifiedBusinessInformation) {
        dispatch(subsidisedFinanceInformationActions.requestPersistCompleteGetStarted(modifiedBusinessInformation));
    }, [dispatch]);
    var companiesHouseCompanyPreviewElement = useMemo(function () {
        var _a;
        if (!companiesHouseCompanyPreview && !isGettingCompaniesHouseCompanyPreview)
            return null;
        var companyNameMatchesLegalBusinessName = ((_a = companiesHouseCompanyPreview === null || companiesHouseCompanyPreview === void 0 ? void 0 : companiesHouseCompanyPreview.companyName) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === (legalBusinessName === null || legalBusinessName === void 0 ? void 0 : legalBusinessName.toLowerCase());
        return (React.createElement("div", { className: "field-group companies-house-company-preview-container" },
            companiesHouseCompanyPreview &&
                !isGettingCompaniesHouseCompanyPreview && (React.createElement("div", { className: "companies-house-company-preview-wrapper" },
                React.createElement(CompaniesHouseCompanyPreview, { companiesHouseCompanyPreview: companiesHouseCompanyPreview }),
                !companyNameMatchesLegalBusinessName && (React.createElement("p", { className: "company-preview-action-warning" },
                    "The name of this company is not a match with the registered business name ",
                    React.createElement("strong", null, legalBusinessName),
                    " we have stored. If ",
                    React.createElement("strong", null, legalBusinessName),
                    " is not your registered business name please",
                    ' ',
                    React.createElement("span", { className: "clickable", onClick: function () { return IntercomAPI('show'); } }, "contact us directly"),
                    ".")),
                React.createElement("p", { className: "company-preview-action-prompt" },
                    "If this company is yours, please ",
                    React.createElement("strong", null, "continue"),
                    ". If not, try entering a different company number."))),
            isGettingCompaniesHouseCompanyPreview && (React.createElement("div", { className: "loader-container" },
                React.createElement(MiniLoader, null)))));
    }, [
        companiesHouseCompanyPreview,
        isGettingCompaniesHouseCompanyPreview,
        legalBusinessName,
    ]);
    var renderActionButtons = useCallback(function (isValid, formState) {
        var modifiedBusinessInformation = formState;
        return (React.createElement("div", { className: "action-buttons-container" },
            React.createElement(Button, { isDisabled: !isValid, onClick: function () {
                    return isValid &&
                        !isGettingOrPersisting &&
                        onContinue(modifiedBusinessInformation);
                }, iconAfter: faChevronRight, isProcessing: isGettingOrPersisting, styleVariant: ButtonStyleVariant.OUTSIZE }, 'Continue')));
    }, [onContinue, isGettingOrPersisting]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        var _a;
        var isSoleTrader = formState.companyType === CompanyType.SOLE_TRADER;
        var companyNumberMatchesCompanyPreview = formState.companyRegistrationNumber === (companiesHouseCompanyPreview === null || companiesHouseCompanyPreview === void 0 ? void 0 : companiesHouseCompanyPreview.companyNumber);
        var shouldRenderCompanyRegistrationNumber = formState.companyType && !isSoleTrader;
        var canRequestGetCompaniesHouseCompanyPreview = ((_a = formState.companyRegistrationNumber) === null || _a === void 0 ? void 0 : _a.length) >= 8 &&
            !companyNumberMatchesCompanyPreview;
        return (React.createElement(React.Fragment, null,
            React.createElement("h3", null, "Get started"),
            React.createElement("div", { className: "field-group" },
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.companyType, isTouched: touchedState.companyType || false },
                    React.createElement(DropdownField, { label: "Company type", isRequired: true, value: formState.companyType || '', name: "companyType", onChange: function (value) {
                            onFieldChange(__assign(__assign({}, value), { companyRegistrationNumber: null }));
                        }, onTouch: onFieldTouch, options: COMPANY_TYPES_OPTIONS })),
                shouldRenderCompanyRegistrationNumber && (React.createElement(ValidatedFieldWrapper, { validationResult: validationState.companyRegistrationNumber, isTouched: touchedState.companyRegistrationNumber || false },
                    React.createElement("div", { className: "company-registration-number-field flex-container" },
                        React.createElement(BasicField, { label: "Company registration number", value: formState.companyRegistrationNumber || '', isRequired: true, name: "companyRegistrationNumber", onChange: onFieldChange, onTouch: onFieldTouch }),
                        React.createElement("div", { className: "flex-shrink" },
                            React.createElement(Button, { iconAfter: faChevronRight, onClick: function () {
                                    !isGettingCompaniesHouseCompanyPreview &&
                                        requestGetCompaniesHouseCompanyPreview(formState.companyRegistrationNumber);
                                }, isDisabled: !canRequestGetCompaniesHouseCompanyPreview, isProcessing: isGettingCompaniesHouseCompanyPreview }, "Go")))))),
            !isSoleTrader &&
                companyNumberMatchesCompanyPreview &&
                companiesHouseCompanyPreviewElement,
            renderActionButtons(isValid && (isSoleTrader || companyNumberMatchesCompanyPreview), formState)));
    }, [
        renderActionButtons,
        requestGetCompaniesHouseCompanyPreview,
        isGettingCompaniesHouseCompanyPreview,
        companiesHouseCompanyPreview,
        companiesHouseCompanyPreviewElement,
    ]);
    return (React.createElement("div", { className: "get-started-form-section", style: { position: 'relative' } },
        React.createElement(ValidatedForm, { fieldValidators: fieldValidators, renderFormContents: renderFormContents })));
};
export default GetStartedFormSection;
