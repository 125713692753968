import React, { useEffect, useMemo, useCallback, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as subscriptionActions } from '@bit/payaca-tech.payaca-core.store.subscription';
import { actions as accountActions } from '@bit/payaca-tech.payaca-core.store.account';
import { ContactUsCallout, TrialPeriodExpiryCallout } from '@/ui/components';
import CreateSubscriptionPageContent from '@/ui/components/manageSubscription/createSubscriptionPageContent/CreateSubscriptionPageContent';
import ManageSubscriptionPageContent from '@/ui/components/manageSubscription/manageSubscriptionPageContent/ManageSubscriptionPageContent';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import './ManageSubscriptionPage.sass';
import { useHistory } from 'react-router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { PayacaPhoneLink } from '@payaca/components';
var ManageSubscriptionPage = function () {
    var dispatch = useDispatch();
    var history = useHistory();
    var accountAccessInformation = useSelector(function (state) { return state.account.accountAccessInformation; });
    var hasActiveSubscription = useMemo(function () { return !!(accountAccessInformation === null || accountAccessInformation === void 0 ? void 0 : accountAccessInformation.hasActiveSubscription); }, [accountAccessInformation]);
    var canBookDemo = useMemo(function () {
        if (!accountAccessInformation)
            return false;
        return (!accountAccessInformation.hasActiveSubscription &&
            !accountAccessInformation.hasInactiveSubscription &&
            !accountAccessInformation.isOnboarded);
    }, [accountAccessInformation]);
    var isTrialPeriodExpired = useMemo(function () {
        if (!accountAccessInformation)
            return false;
        return (!accountAccessInformation.hasActiveSubscription &&
            accountAccessInformation.isTrialPeriodExpired);
    }, [accountAccessInformation]);
    var requiresUpdateBusinessSettingsPromptOnSubscribe = useMemo(function () {
        return canBookDemo;
    }, [canBookDemo]);
    var onCreateSubscription = useCallback(function () {
        dispatch(accountActions.setRequiresUpdateBusinessSettingsPrompt(requiresUpdateBusinessSettingsPromptOnSubscribe));
    }, [dispatch, requiresUpdateBusinessSettingsPromptOnSubscribe]);
    var contactUsCalloutElement = useMemo(function () {
        if (canBookDemo) {
            return (React.createElement(ContactUsCallout, null,
                React.createElement("span", null,
                    "If you're not ready to subscribe just yet you can",
                    ' ',
                    React.createElement("span", { className: "book-demo-prompt", onClick: function () { return window.open('https://book.payaca.com/demo'); } }, "book a free demo"),
                    ".")));
        }
        if (isTrialPeriodExpired) {
            return React.createElement(TrialPeriodExpiryCallout, null);
        }
    }, [canBookDemo, isTrialPeriodExpired]);
    useEffect(function () {
        dispatch(subscriptionActions.requestGetProducts());
        dispatch(subscriptionActions.requestGetAccountSubscription());
    }, []);
    return (React.createElement(AuthenticatedPageWrapper, { className: "manage-subscription-page", title: React.createElement("div", { className: "flex-grow flex-container flex-center title-bar-inner" },
            React.createElement("div", { className: "button-container" },
                React.createElement(Button, { iconBefore: faChevronLeft, styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () { return history.push('/personalSettings'); } }, "Back")),
            React.createElement("h1", null, "Manage subscription"),
            React.createElement("div", { className: "phone-number-container flex-container flex-center" },
                React.createElement("span", null, "Need help?"),
                React.createElement(PayacaPhoneLink, { showPhoneIcon: true }))) },
        React.createElement("div", { className: "page-content-inner" }, hasActiveSubscription ? (React.createElement(ManageSubscriptionPageContent, null)) : (React.createElement(CreateSubscriptionPageContent, { onCreateSubscription: onCreateSubscription })))));
};
export default ManageSubscriptionPage;
