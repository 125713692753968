var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useMemo, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import values from 'lodash.values';
import { FileUploadPersistRemoveControl } from '@payaca/components/fileUploadPersistRemoveControl';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { actions as jobsActions } from '@bit/payaca-tech.payaca-core.store.jobs';
import { actions as jobActions } from '@/api/jobs';
import { FileSizeLimit, FileType, FileUploadType, getMbFileLimit, } from '@/helpers/fileHelper';
import './JobAttachmentsControl.sass';
var fileSizeLimit = FileSizeLimit[FileUploadType.JOB_ATTACHMENT];
var mbFileLimit = getMbFileLimit(fileSizeLimit);
var JobAttachmentsControl = function (_a) {
    var jobId = _a.jobId;
    var dispatch = useDispatch();
    var _b = useState(false), isFileSizeLimitExceeded = _b[0], setIsFileSizeLimitExceeded = _b[1];
    var _c = useState(false), isFileNameLengthExceeded = _c[0], setIsFileNameLengthExceeded = _c[1];
    var job = useSelector(function (state) {
        var _a;
        return state.jobsStore.jobs && ((_a = state.jobsStore.jobs[jobId]) === null || _a === void 0 ? void 0 : _a.entity);
    });
    var jobAttachments = useSelector(function (state) {
        var _a;
        if (!jobId)
            return [];
        var job = state.jobsStore.jobs && ((_a = state.jobsStore.jobs[jobId]) === null || _a === void 0 ? void 0 : _a.entity);
        var jobAttachmentIds = (job === null || job === void 0 ? void 0 : job.jobAttachmentIds) || [];
        var jobAttachments = jobAttachmentIds.map(function (jobAttachmentId) {
            var _a;
            return (_a = state.jobsStore.jobAttachments[jobAttachmentId]) === null || _a === void 0 ? void 0 : _a.entity;
        });
        return jobAttachments.filter(function (x) { return !!x; });
    });
    var onAttachmentUploadRemoveSuccess = useCallback(function () {
        dispatch(jobsActions.requestGetJob(jobId));
        dispatch(jobsActions.requestGetJobAttachmentsForJob(jobId));
    }, [dispatch, jobId]);
    var uploadAttachmentToJob = useCallback(function (attachmentToAdd) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    setIsFileSizeLimitExceeded(false);
                    if (attachmentToAdd.file.size > fileSizeLimit) {
                        setIsFileSizeLimitExceeded(true);
                        Sentry.captureMessage("File upload attempt of " + attachmentToAdd.file.size + " for job attachment exceeds limit of " + fileSizeLimit, Sentry.Severity.Info);
                        resolve();
                    }
                    else {
                        dispatch(jobActions.addAttachmentToJob(jobId, attachmentToAdd, function (addJobAttachmentError) {
                            if (!addJobAttachmentError) {
                                onAttachmentUploadRemoveSuccess();
                            }
                            resolve();
                        }));
                    }
                })];
        });
    }); }, [dispatch, jobId, onAttachmentUploadRemoveSuccess]);
    var removeAttachmentFromJob = useCallback(function (attachmentIdToRemove) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    dispatch(jobActions.removeAttachmentFromJob(jobId, attachmentIdToRemove, function (removeAttachmentError) {
                        if (!removeAttachmentError) {
                            onAttachmentUploadRemoveSuccess();
                            resolve();
                        }
                        else {
                            reject();
                        }
                    }));
                })];
        });
    }); }, [dispatch, jobId, onAttachmentUploadRemoveSuccess]);
    var updateJobAttachment = useCallback(function (attachmentId, fileName) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    setIsFileNameLengthExceeded(false);
                    if (fileName.length < 1 || fileName.length > 150) {
                        setIsFileNameLengthExceeded(true);
                    }
                    else {
                        dispatch(jobsActions.requestUpdateJobAttachment({
                            jobId: jobId,
                            jobAttachmentId: attachmentId,
                            fileName: fileName,
                        }, function () {
                            dispatch(jobsActions.requestGetJobAttachmentsForJob(jobId));
                            resolve();
                        }));
                    }
                })];
        });
    }); }, [dispatch, jobId, onAttachmentUploadRemoveSuccess]);
    var validationResult = useMemo(function () {
        var errors = [];
        if (isFileSizeLimitExceeded)
            errors.push("One or more of you selected files exceeds the limit of " + mbFileLimit);
        if (isFileNameLengthExceeded)
            errors.push("File names must be between 1 and 150 characters");
        return {
            isValid: !errors.length,
            errors: errors,
        };
    }, [isFileSizeLimitExceeded, isFileNameLengthExceeded]);
    return (React.createElement("div", { className: "job-attachments-control" },
        React.createElement(ValidatedFieldWrapper, { isTouched: true, validationResult: validationResult },
            React.createElement(FileUploadPersistRemoveControl, { persistedFiles: jobAttachments
                    ? jobAttachments.map(function (attachment) {
                        return {
                            fileName: attachment.fileName,
                            identifier: attachment.id,
                        };
                    })
                    : [], persistFile: function (file) {
                    return uploadAttachmentToJob({
                        file: file,
                        fileName: file.name.split('.')[0],
                    });
                }, removePersistedFile: function (attachmentId) {
                    return removeAttachmentFromJob(attachmentId);
                }, persistFileName: function (attachmentId, fileName) {
                    return updateJobAttachment(attachmentId, fileName);
                }, allowFileNameModification: true, allowMultipleUploads: true, acceptFileTypes: values(FileType).map(function (v) { return v.name[0]; }) }))));
};
export default JobAttachmentsControl;
