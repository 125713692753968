var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useMemo, useCallback, useEffect, } from 'react';
import './CustomerContactsControl.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import CustomerContactInputControl from '../customerContactInputControl/CustomerContactInputControl';
import { ContentPanel } from '@payaca/components/contentPanel';
import ResponsiveViewWrapper from '@bit/payaca-tech.payaca-core.component.responsive-view-wrapper';
import { Button } from '@payaca/components/button';
var CustomerContactsControl = function (_a) {
    var inputStyleVariant = _a.inputStyleVariant, customerContacts = _a.customerContacts, fieldNamePrefix = _a.fieldNamePrefix, touchedState = _a.touchedState, validationState = _a.validationState, onTouch = _a.onTouch, onChange = _a.onChange;
    var onCustomerContactAdd = useCallback(function () {
        var _a;
        onChange((_a = {},
            _a[fieldNamePrefix] = __spreadArrays((customerContacts !== null && customerContacts !== void 0 ? customerContacts : []), [{}]),
            _a));
    }, [onChange, customerContacts, fieldNamePrefix]);
    var onCustomerContactRemove = useCallback(function (customerIndex) {
        var _a;
        customerContacts.splice(customerIndex, 1);
        onChange((_a = {}, _a[fieldNamePrefix] = customerContacts, _a));
    }, [onChange, customerContacts, fieldNamePrefix]);
    var isAnyPrimaryContact = useMemo(function () {
        if (!customerContacts)
            return false;
        return customerContacts.some(function (x) { return x.isPrimaryContact; });
    }, [customerContacts === null || customerContacts === void 0 ? void 0 : customerContacts.length]);
    var onMakePrimaryContact = useCallback(function (customerIndex) {
        var changeValues = {};
        var customerContactIndices = Array.from(Array(customerContacts.length).keys());
        customerContactIndices.forEach(function (index) {
            changeValues[fieldNamePrefix + "[" + index + "].isPrimaryContact"] =
                index === customerIndex;
        });
        onChange(changeValues);
    }, [customerContacts, onChange, fieldNamePrefix]);
    useEffect(function () {
        if ((customerContacts === null || customerContacts === void 0 ? void 0 : customerContacts.length) && !isAnyPrimaryContact) {
            onMakePrimaryContact(0);
        }
    }, [customerContacts === null || customerContacts === void 0 ? void 0 : customerContacts.length, isAnyPrimaryContact, onMakePrimaryContact]);
    var allowDeleteContacts = useMemo(function () {
        return customerContacts && customerContacts.length > 1;
    }, [customerContacts === null || customerContacts === void 0 ? void 0 : customerContacts.length]);
    var renderCustomerContactControl = useCallback(function (customerContact, index) {
        return (React.createElement(ContentPanel, { hasBoxShadow: true, key: "customer-contact-control-" + index, className: "customer-contact-control-container" },
            React.createElement(React.Fragment, null,
                allowDeleteContacts && (React.createElement("span", { className: "delete-contact-trigger", onClick: function () { return onCustomerContactRemove(index); } },
                    React.createElement("small", null,
                        "Remove ",
                        React.createElement(FontAwesomeIcon, { icon: faTimes })))),
                React.createElement(CustomerContactInputControl, { inputStyleVariant: inputStyleVariant, fieldNamePrefix: fieldNamePrefix + "[" + index + "]", customerContact: customerContact, validationState: validationState, touchedState: touchedState, onChange: onChange, onTouch: onTouch, onMakePrimaryContact: function () { return onMakePrimaryContact(index); } }))));
    }, [
        onTouch,
        onChange,
        onCustomerContactRemove,
        onMakePrimaryContact,
        validationState,
        touchedState,
        fieldNamePrefix,
        allowDeleteContacts,
    ]);
    return (React.createElement(ResponsiveViewWrapper, { downBreakpointSm: 840, downBreakpointXs: 550, className: "customer-contacts-control" },
        React.createElement("div", { className: "customer-contact-controls-container" },
            customerContacts && customerContacts.map(renderCustomerContactControl),
            React.createElement("div", { className: "add-contact-trigger-container" },
                React.createElement(Button, { onClick: onCustomerContactAdd, isOutlined: true, iconBefore: faPlus }, "Add contact")))));
};
export default CustomerContactsControl;
