import { action } from 'typesafe-actions';
import { ActionType } from './subsidisedFinanceInformationTypes';
export var requestGetBusinessInformation = function () {
    return action(ActionType.REQUEST_GET_BUSINESS_INFORMATION);
};
export var clearBusinessInformation = function () {
    return action(ActionType.CLEAR_BUSINESS_INFORMATION);
};
export var getBusinessInformationSuccess = function (businessInformation) {
    return action(ActionType.GET_BUSINESS_INFORMATION_SUCCESS, {
        businessInformation: businessInformation,
    });
};
export var getBusinessInformationFailure = function (error) {
    return action(ActionType.GET_BUSINESS_INFORMATION_FAILURE, null, null, error);
};
export var requestPersistBusinessInformation = function (businessInformation) {
    return action(ActionType.REQUEST_PERSIST_BUSINESS_INFORMATION, {
        businessInformation: businessInformation,
    });
};
export var persistBusinessInformationSuccess = function () {
    return action(ActionType.PERSIST_BUSINESS_INFORMATION_SUCCESS);
};
export var persistBusinessInformationFailure = function (error) {
    return action(ActionType.PERSIST_BUSINESS_INFORMATION_FAILURE, null, null, error);
};
export var requestPersistCompleteBusinessInformation = function (businessInformation) {
    return action(ActionType.REQUEST_PERSIST_COMPLETE_BUSINESS_INFORMATION, {
        businessInformation: businessInformation,
    });
};
export var persistCompleteBusinessInformationSuccess = function () {
    return action(ActionType.PERSIST_COMPLETE_BUSINESS_INFORMATION_SUCCESS);
};
export var persistCompleteBusinessInformationFailure = function (error) {
    return action(ActionType.PERSIST_COMPLETE_BUSINESS_INFORMATION_FAILURE, null, null, error);
};
export var requestGetFinancialInformation = function () {
    return action(ActionType.REQUEST_GET_FINANCIAL_INFORMATION);
};
export var clearFinancialInformation = function () {
    return action(ActionType.CLEAR_FINANCIAL_INFORMATION);
};
export var getFinancialInformationSuccess = function (financialInformation) {
    return action(ActionType.GET_FINANCIAL_INFORMATION_SUCCESS, {
        financialInformation: financialInformation,
    });
};
export var getFinancialInformationFailure = function (error) {
    return action(ActionType.GET_FINANCIAL_INFORMATION_FAILURE, null, null, error);
};
export var requestPersistFinancialInformation = function (financialInformation) {
    return action(ActionType.REQUEST_PERSIST_FINANCIAL_INFORMATION, {
        financialInformation: financialInformation,
    });
};
export var persistFinancialInformationSuccess = function () {
    return action(ActionType.PERSIST_FINANCIAL_INFORMATION_SUCCESS);
};
export var persistFinancialInformationFailure = function (error) {
    return action(ActionType.PERSIST_FINANCIAL_INFORMATION_FAILURE, null, null, error);
};
export var requestPersistCompleteFinancialInformation = function (financialInformation) {
    return action(ActionType.REQUEST_PERSIST_COMPLETE_FINANCIAL_INFORMATION, {
        financialInformation: financialInformation,
    });
};
export var persistCompleteFinancialInformationSuccess = function () {
    return action(ActionType.PERSIST_COMPLETE_FINANCIAL_INFORMATION_SUCCESS);
};
export var persistCompleteFinancialInformationFailure = function (error) {
    return action(ActionType.PERSIST_COMPLETE_FINANCIAL_INFORMATION_FAILURE, null, null, error);
};
export var requestGetRegulatoryRequirements = function () {
    return action(ActionType.REQUEST_GET_REGULATORY_REQUIREMENTS);
};
export var clearRegulatoryRequirements = function () {
    return action(ActionType.CLEAR_REGULATORY_REQUIREMENTS);
};
export var getRegulatoryRequirementsSuccess = function (regulatoryRequirements) {
    return action(ActionType.GET_REGULATORY_REQUIREMENTS_SUCCESS, {
        regulatoryRequirements: regulatoryRequirements,
    });
};
export var getRegulatoryRequirementsFailure = function (error) {
    return action(ActionType.GET_REGULATORY_REQUIREMENTS_FAILURE, null, null, error);
};
export var requestPersistRegulatoryRequirements = function (regulatoryRequirements) {
    return action(ActionType.REQUEST_PERSIST_REGULATORY_REQUIREMENTS, {
        regulatoryRequirements: regulatoryRequirements,
    });
};
export var persistRegulatoryRequirementsSuccess = function () {
    return action(ActionType.PERSIST_REGULATORY_REQUIREMENTS_SUCCESS);
};
export var persistRegulatoryRequirementsFailure = function (error) {
    return action(ActionType.PERSIST_REGULATORY_REQUIREMENTS_FAILURE, null, null, error);
};
export var requestPersistCompleteRegulatoryRequirements = function (regulatoryRequirements) {
    return action(ActionType.REQUEST_PERSIST_COMPLETE_REGULATORY_REQUIREMENTS, {
        regulatoryRequirements: regulatoryRequirements,
    });
};
export var persistCompleteRegulatoryRequirementsSuccess = function () {
    return action(ActionType.PERSIST_COMPLETE_REGULATORY_REQUIREMENTS_SUCCESS);
};
export var persistCompleteRegulatoryRequirementsFailure = function (error) {
    return action(ActionType.PERSIST_COMPLETE_REGULATORY_REQUIREMENTS_FAILURE, null, null, error);
};
export var requestGetAdditionalInformation = function () {
    return action(ActionType.REQUEST_GET_ADDITIONAL_INFORMATION);
};
export var clearAdditionalInformation = function () {
    return action(ActionType.CLEAR_ADDITIONAL_INFORMATION);
};
export var getAdditionalInformationSuccess = function (additionalInformation) {
    return action(ActionType.GET_ADDITIONAL_INFORMATION_SUCCESS, {
        additionalInformation: additionalInformation,
    });
};
export var getAdditionalInformationFailure = function (error) {
    return action(ActionType.GET_ADDITIONAL_INFORMATION_FAILURE, null, null, error);
};
export var requestPersistAdditionalInformation = function (additionalInformation) {
    return action(ActionType.REQUEST_PERSIST_ADDITIONAL_INFORMATION, {
        additionalInformation: additionalInformation,
    });
};
export var persistAdditionalInformationSuccess = function () {
    return action(ActionType.PERSIST_ADDITIONAL_INFORMATION_SUCCESS);
};
export var persistAdditionalInformationFailure = function (error) {
    return action(ActionType.PERSIST_ADDITIONAL_INFORMATION_FAILURE, null, null, error);
};
export var requestPersistCompleteAdditionalInformation = function (additionalInformation) {
    return action(ActionType.REQUEST_PERSIST_COMPLETE_ADDITIONAL_INFORMATION, {
        additionalInformation: additionalInformation,
    });
};
export var persistCompleteAdditionalInformationSuccess = function () {
    return action(ActionType.PERSIST_COMPLETE_ADDITIONAL_INFORMATION_SUCCESS);
};
export var persistCompleteAdditionalInformationFailure = function (error) {
    return action(ActionType.PERSIST_COMPLETE_ADDITIONAL_INFORMATION_FAILURE, null, null, error);
};
export var requestGetAuthorityToSearch = function () {
    return action(ActionType.REQUEST_GET_AUTHORITY_TO_SEARCH);
};
export var clearAuthorityToSearch = function () {
    return action(ActionType.CLEAR_AUTHORITY_TO_SEARCH);
};
export var getAuthorityToSearchSuccess = function (authorityToSearch) {
    return action(ActionType.GET_AUTHORITY_TO_SEARCH_SUCCESS, {
        authorityToSearch: authorityToSearch,
    });
};
export var getAuthorityToSearchFailure = function (error) {
    return action(ActionType.GET_AUTHORITY_TO_SEARCH_FAILURE, null, null, error);
};
export var requestPersistAuthorityToSearch = function (authorityToSearch) {
    return action(ActionType.REQUEST_PERSIST_AUTHORITY_TO_SEARCH, {
        authorityToSearch: authorityToSearch,
    });
};
export var persistAuthorityToSearchSuccess = function () {
    return action(ActionType.PERSIST_AUTHORITY_TO_SEARCH_SUCCESS);
};
export var persistAuthorityToSearchFailure = function (error) {
    return action(ActionType.PERSIST_AUTHORITY_TO_SEARCH_FAILURE, null, null, error);
};
export var requestPersistCompleteAuthorityToSearch = function (authorityToSearch) {
    return action(ActionType.REQUEST_PERSIST_COMPLETE_AUTHORITY_TO_SEARCH, {
        authorityToSearch: authorityToSearch,
    });
};
export var persistCompleteAuthorityToSearchSuccess = function () {
    return action(ActionType.PERSIST_COMPLETE_AUTHORITY_TO_SEARCH_SUCCESS);
};
export var persistCompleteAuthorityToSearchFailure = function (error) {
    return action(ActionType.PERSIST_COMPLETE_AUTHORITY_TO_SEARCH_FAILURE, null, null, error);
};
export var requestGetSupportingDocuments = function () {
    return action(ActionType.REQUEST_GET_SUPPORTING_DOCUMENTS);
};
export var clearSupportingDocuments = function () {
    return action(ActionType.CLEAR_SUPPORTING_DOCUMENTS);
};
export var getSupportingDocumentsSuccess = function (supportingDocuments) {
    return action(ActionType.GET_SUPPORTING_DOCUMENTS_SUCCESS, {
        supportingDocuments: supportingDocuments,
    });
};
export var getSupportingDocumentsFailure = function (error) {
    return action(ActionType.GET_SUPPORTING_DOCUMENTS_FAILURE, null, null, error);
};
export var requestPersistGenericSupportingDocument = function (file, callback) {
    return action(ActionType.REQUEST_PERSIST_GENERIC_SUPPORTING_DOCUMENT, {
        file: file,
        callback: callback,
    });
};
export var requestPersistDirectorIdDocument = function (directorId, file, callback) {
    return action(ActionType.REQUEST_PERSIST_DIRECTOR_ID_DOCUMENT, {
        directorId: directorId,
        file: file,
        callback: callback,
    });
};
export var requestPersistDirectorAddressDocument = function (directorId, file, callback) {
    return action(ActionType.REQUEST_PERSIST_DIRECTOR_ADDRESS_DOCUMENT, {
        directorId: directorId,
        file: file,
        callback: callback,
    });
};
export var requestPersistBankStatementDocument = function (file, callback) {
    return action(ActionType.REQUEST_PERSIST_BANK_STATEMENT_DOCUMENT, {
        file: file,
        callback: callback,
    });
};
export var requestRemoveSupportingDocument = function (id, callback) {
    return action(ActionType.REQUEST_REMOVE_SUPPORTING_DOCUMENT, {
        id: id,
        callback: callback,
    });
};
export var persistSupportingDocumentSucess = function () {
    return action(ActionType.PERSIST_SUPPORTING_DOCUMENT_SUCCESS);
};
export var persistSupportingDocumentFailure = function (error) {
    return action(ActionType.PERSIST_SUPPORTING_DOCUMENT_FAILURE, null, null, error);
};
export var removeSupportingDocumentFailure = function (error) {
    return action(ActionType.REMOVE_SUPPORTING_DOCUMENT_FAILURE, null, null, error);
};
export var requestCompleteSupportingDocuments = function () {
    return action(ActionType.REQUEST_COMPLETE_SUPPORTING_DOCUMENTS);
};
export var completeSupportingDocumentsSuccess = function () {
    return action(ActionType.COMPLETE_SUPPORTING_DOCUMENTS_SUCCESS);
};
export var completeSupportingDocumentsFailure = function (error) {
    return action(ActionType.COMPLETE_SUPPORTING_DOCUMENTS_FAILURE, null, null, error);
};
export var removeSupportingDocumentSucess = function () {
    return action(ActionType.REMOVE_SUPPORTING_DOCUMENT_SUCCESS);
};
export var requestGetCurrentStepLabel = function () {
    return action(ActionType.REQUEST_GET_CURRENT_STEP_LABEL);
};
export var getCurrentStepLabelSuccess = function (currentStepLabel) {
    return action(ActionType.GET_CURRENT_STEP_LABEL_SUCCESS, {
        currentStepLabel: currentStepLabel,
    });
};
export var getCurrentStepLabelFailure = function (error) {
    return action(ActionType.GET_CURRENT_STEP_LABEL_FAILURE, null, null, error);
};
export var clearCurrentStepLabel = function () {
    return action(ActionType.CLEAR_CURRENT_STEP_LABEL);
};
export var requestSubmitSubsidisedFinanceInformation = function () {
    return action(ActionType.REQUEST_SUBMIT_SUBSIDISED_FINANCE_INFORMATION);
};
export var submitSubsidisedFinanceInformationSuccess = function () {
    return action(ActionType.SUBMIT_SUBSIDISED_FINANCE_INFORMATION_SUCCESS);
};
export var submitSubsidisedFinanceInformationFailure = function (error) {
    return action(ActionType.SUBMIT_SUBSIDISED_FINANCE_INFORMATION_FAILURE, null, null, error);
};
export var requestSetCurrentStep = function (stepLabel) {
    return action(ActionType.REQUEST_SET_CURRENT_STEP, {
        stepLabel: stepLabel,
    });
};
export var setCurrentStepSuccess = function () {
    return action(ActionType.SET_CURRENT_STEP_SUCCESS);
};
export var setCurrentStepFailure = function (error) {
    return action(ActionType.SET_CURRENT_STEP_FAILURE, null, null, error);
};
export var requestGetCompaniesHouseCompanyPreview = function (companyNumber) {
    return action(ActionType.REQUEST_GET_COMPANIES_HOUSE_COMPANY_PREVIEW, {
        companyNumber: companyNumber,
    });
};
export var clearCompaniesHouseCompanyPreview = function () {
    return action(ActionType.CLEAR_COMPANIES_HOUSE_COMPANY_PREVIEW);
};
export var getCompaniesHouseCompanyPreviewSuccess = function (companiesHouseCompanyPreview) {
    return action(ActionType.GET_COMPANIES_HOUSE_COMPANY_PREVIEW_SUCCESS, {
        companiesHouseCompanyPreview: companiesHouseCompanyPreview,
    });
};
export var getCompaniesHouseCompanyPreviewFailure = function (error) {
    return action(ActionType.GET_COMPANIES_HOUSE_COMPANY_PREVIEW_FAILURE, null, null, error);
};
export var requestPersistCompleteGetStarted = function (businessInformation) {
    return action(ActionType.REQUEST_PERSIST_COMPLETE_GET_STARTED, {
        businessInformation: businessInformation,
    });
};
export var persistCompleteGetStartedSuccess = function () {
    return action(ActionType.PERSIST_COMPLETE_GET_STARTED_SUCCESS);
};
export var persistCompleteGetStartedFailure = function (error) {
    return action(ActionType.PERSIST_COMPLETE_GET_STARTED_FAILURE, null, null, error);
};
