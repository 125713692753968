import React from 'react';
import { default as MaterialUiRadioButton } from '@material-ui/core/Radio';
import './RadioButton.sass';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var RadioButton = function (_a) {
    var _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, isSelected = _a.isSelected, onClick = _a.onClick;
    return (React.createElement("div", { className: "radio-button" },
        React.createElement(MaterialUiRadioButton, { color: "default", checked: isSelected, onChange: onClick, inputProps: { 'aria-label': 'radio-button' }, disabled: isDisabled, checkedIcon: React.createElement("div", { className: "radio-wrapper" },
                React.createElement(FontAwesomeIcon, { icon: faCircle })), icon: React.createElement("div", { className: "radio-wrapper" }) })));
};
export default RadioButton;
