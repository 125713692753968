import {
  delay,
  take,
  put,
  all,
  race,
  call,
  takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import { actions as authActions } from '@bit/payaca-tech.payaca-core.store.auth';

import { parseAccountUsers, parseContactNumber } from '@/helpers/userHelper';

import * as api from '../services/api';
import { refreshAuthToken } from '@bit/payaca-tech.payaca-core.store.auth/dist/store/auth/authSaga';

export const initialState = {
  myProfile: {},
  signUpDetails: {
    address: {},
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    contactNumber: '',
  },
  newBusinessAccount: {},
  newCustomerDetails: {
    name: '',
    email: '',
    contactNumber: '',
    firstLineAddress: '',
    secondLineAddress: '',
    city: '',
    postcode: '',
  },
  currentItem: {
    name: '',
    description: '',
    price: '',
    vatAmount: 20,
    vatIncluded: true,
    supplierName: '',
    supplierPrice: null,
    cisDeductionRate: null,
    isReverseChargeVat: false,
  },
  customers: [],
  gettingCustomers: false,
  gettingProfile: false,
  gettingTerms: false,
  terms: [],
  globalSettings: {},
  xeroAccounts: [],
  subscriptionPrices: [],
  accountUsers: [],
  businessAttachments: [],
  financePlans: null,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'users.storeGlobalSettings': {
      return {
        ...state,
        globalSettings: action.globalSettings,
      };
    }

    case 'users.storeMyProfile': {
      return {
        ...state,
        myProfile: {
          ...action.profile,
          contactNumber: parseContactNumber(action.profile.contactNumber),
        },
        gettingProfile: false,
      };
    }

    case 'users.clearMyProfile': {
      return { ...initialState };
    }

    case 'users.buildSignUpDetails': {
      return {
        ...state,
        signUpDetails: {
          ...state.signUpDetails,
          ...action.signUpDetails,
        },
      };
    }

    case 'users.clearSignUpDetails': {
      return {
        ...state,
        myProfile: {},
        signUpDetails: {
          address: {},
        },
        newBusinessAccount: {},
        newCustomerDetails: {
          address: {},
        },
      };
    }

    case 'users.lookupPostcode': {
      return {
        ...state,
        addressResults: [],
      };
    }

    case 'users.storeAddressResults': {
      return {
        ...state,
        addressResults: action.addressResults,
      };
    }

    case 'users.buildNewBusinessAccount': {
      return {
        ...state,
        newBusinessAccount: {
          ...state.newBusinessAccount,
          ...action.newBusinessAccount,
        },
      };
    }

    case 'users.clearNewBusinessAccount': {
      return {
        ...state,
        newBusinessAccount: {},
      };
    }

    case 'users.storeNewAccountLogo': {
      return {
        ...state,
        newAccountLogo: action.image,
      };
    }

    case 'users.storeTerms': {
      return {
        ...state,
        terms: action.terms,
        gettingTerms: false,
      };
    }

    case 'users.storeBusinessAttachments': {
      return {
        ...state,
        businessAttachments: action.businessAttachments,
        gettingBusinessAttachments: false,
      };
    }

    case 'users.storeXeroAccounts': {
      return {
        ...state,
        xeroAccounts: action.xeroAccounts,
      };
    }

    case 'users.storeAccountUsers': {
      const parsedAccountUsers = parseAccountUsers([...action.accountUsers]);
      return {
        ...state,
        accountUsers: parsedAccountUsers,
        gettingUsers: false,
      };
    }

    case 'users.getProfile': {
      return {
        ...state,
        gettingProfile: true,
      };
    }

    case 'users.getAccountUsers': {
      return {
        ...state,
        gettingUsers: true,
      };
    }

    case 'users.storeSubscriptionPrices': {
      return { ...state, subscriptionPrices: action.data };
    }

    case 'users.getAccountTerms': {
      return {
        ...state,
        gettingTerms: false,
      };
    }

    case 'users.getBusinessAttachments': {
      return {
        ...state,
        gettingAttachments: false,
      };
    }

    case 'users.storeFinancePlans': {
      return { ...state, financePlans: action.financePlans };
    }

    case 'users.clearFinancePlans': {
      return { ...state, financePlans: null };
    }

    default: {
      return state;
    }
  }
}

export const actions = {
  // getGlobalSettings: () => ({ type: 'users.getGlobalSettings' }),
  // storeGlobalSettings: globalSettings => ({ type: 'users.storeGlobalSettings', globalSettings }),
  // registerUserDevice: (deviceToken, callback) => ({ type: 'users.registerUserDevice', deviceToken, callback }),
  getProfile: (callback) => ({ type: 'users.getProfile', callback }),
  storeMyProfile: (profile) => ({ type: 'users.storeMyProfile', profile }),
  clearMyProfile: () => ({
    type: 'users.clearMyProfile',
  }),
  updateProfile: (updatedProfile, callback) => ({
    type: 'users.updateProfile',
    updatedProfile,
    callback,
  }),
  setAnalyticsUserId: (userId) => ({
    type: 'users.setAnalyticsUserId',
    userId,
  }),
  // updateNotificationSettings: (payload, callback) => ({ type: 'users.updateNotificationSettings', payload, callback }),
  // agreeToPrivacy: callback => ({ type: 'users.agreeToPrivacy', callback }),
  updatePassword: (payload, callback) => ({
    type: 'users.updatePassword',
    payload,
    callback,
  }),
  updateSignUpPreferences: (
    agreedToPrivacy,
    subscribedToNewsletter,
    callback
  ) => ({
    type: 'users.updateSignUpPreferences',
    agreedToPrivacy,
    subscribedToNewsletter,
    callback,
  }),
  resendEmailVerification: (email, callback) => ({
    type: 'users.resendEmailVerification',
    email,
    callback,
  }),
  buildSignUpDetails: (signUpDetails) => ({
    type: 'users.buildSignUpDetails',
    signUpDetails,
  }),
  // clearSignUpDetails: () => ({ type: 'users.clearSignUpDetails' }),
  // buildNewBusinessAccount: newBusinessAccount => ({ type: 'users.buildNewBusinessAccount', newBusinessAccount }),
  // clearNewBusinessAccount: () => ({ type: 'users.clearNewBusinessAccount' }),
  createBusinessAccount: (businessAccount, callback) => ({
    type: 'users.createBusinessAccount',
    businessAccount,
    callback,
  }),
  updateBusinessAccount: (accountId, payload, callback) => ({
    type: 'users.updateBusinessAccount',
    accountId,
    payload,
    callback,
  }),
  lookupPostcode: (postcode, callback) => ({
    type: 'users.lookupPostcode',
    postcode,
    callback,
  }),
  lookupAddress: (addressId, callback) => ({
    type: 'users.lookupAddress',
    addressId,
    callback,
  }),
  validateEmail: (email, callback) => ({
    type: 'users.validateEmail',
    email,
    callback,
  }),
  validatePhone: (phone, callback) => ({
    type: 'users.validatePhone',
    phone,
    callback,
  }),
  getBlockedEmails: (callback) => ({
    type: 'users.getBlockedEmails',
    callback,
  }),
  storeAddressResults: (addressResults) => ({
    type: 'users.storeAddressResults',
    addressResults,
  }),
  // storeNewAccountLogo: image => ({ type: 'users.storeNewAccountLogo', image }),
  uploadAccountLogo: (accountId, imageFile, callback) => ({
    type: 'users.uploadAccountLogo',
    accountId,
    imageFile,
    callback,
  }),
  getAccountTerms: (accountId, callback) => ({
    type: 'users.getAccountTerms',
    accountId,
    callback,
  }),
  getBusinessAttachments: (callback) => ({
    type: 'users.getBusinessAttachments',
    callback,
  }),
  storeBusinessAttachments: (businessAttachments) => ({
    type: 'users.storeBusinessAttachments',
    businessAttachments,
  }),
  manageBusinessAttachments: (documentsToAdd, documentsToRemove, callback) => ({
    type: 'users.manageBusinessAttachments',
    documentsToAdd,
    documentsToRemove,
    callback,
  }),
  storeTerms: (terms) => ({
    type: 'users.storeTerms',
    terms,
  }),
  manageTermsDocuments: (
    accountId,
    documentsToAdd,
    documentsToRemove,
    callback
  ) => ({
    type: 'users.manageTermsDocuments',
    accountId,
    documentsToAdd,
    documentsToRemove,
    callback,
  }),
  uploadTermsDocument: (accountId, termsDocument, callback) => ({
    type: 'users.uploadTermsDocument',
    accountId,
    termsDocument,
    callback,
  }),
  removeTermsDocument: (accountId, termsDocumentId, callback) => ({
    type: 'users.removeTermsDocument',
    accountId,
    termsDocumentId,
    callback,
  }),
  clearItem: () => ({ type: 'users.clearItem' }),
  connectWithXero: (code, callback) => ({
    type: 'users.connectWithXero',
    code,
    callback,
  }),
  importXeroContacts: (callback) => ({
    type: 'users.importXeroContacts',
    callback,
  }),
  importXeroItems: (callback) => ({
    type: 'users.importXeroItems',
    callback,
  }),
  removeXeroConnection: (callback) => ({
    type: 'users.removeXeroConnection',
    callback,
  }),
  getXeroAccounts: (callback) => ({
    type: 'users.getXeroAccounts',
    callback,
  }),
  storeXeroAccounts: (xeroAccounts) => ({
    type: 'users.storeXeroAccounts',
    xeroAccounts,
  }),
  updateXeroSettings: (payload, callback) => ({
    type: 'users.updateXeroSettings',
    payload,
    callback,
  }),
  connectWithQuickbooks: (payload, callback) => ({
    type: 'users.connectWithQuickbooks',
    payload,
    callback,
  }),
  importQuickbooksCustomers: (callback) => ({
    type: 'users.importQuickbooksCustomers',
    callback,
  }),
  importQuickbooksItems: (callback) => ({
    type: 'users.importQuickbooksItems',
    callback,
  }),
  removeQuickbooksConnection: (callback) => ({
    type: 'users.removeQuickbooksConnection',
    callback,
  }),
  storeSubscriptionPrices: (data) => ({
    type: 'users.storeSubscriptionPrices',
    data,
  }),
  manageSubscription: (callback) => ({
    type: 'users.manageSubscription',
    callback,
  }),
  storeAccountUsers: (accountUsers) => ({
    type: 'users.storeAccountUsers',
    accountUsers,
  }),
  getAccountUsers: (callback) => ({
    type: 'users.getAccountUsers',
    callback,
  }),
  inviteUserToAccount: (payload, callback) => ({
    type: 'users.inviteUserToAccount',
    payload,
    callback,
  }),
  reactivateUser: (userId, callback) => ({
    type: 'users.reactivateUser',
    userId,
    callback,
  }),
  deleteUserFromAccount: (userId, callback) => ({
    type: 'users.deleteUserFromAccount',
    userId,
    callback,
  }),
  deleteUsersFromAccount: (userIds, callback) => ({
    type: 'users.deleteUsersFromAccount',
    userIds,
    callback,
  }),
  updateUsersAccountRole: (userIds, payload, callback) => ({
    type: 'users.updateUsersAccountRole',
    userIds,
    payload,
    callback,
  }),
  connectWithStripe: (code, callback) => ({
    type: 'users.connectWithStripe',
    code,
    callback,
  }),
  removeStripeConnection: (callback) => ({
    type: 'users.removeStripeConnection',
    callback,
  }),
  requestGetFinancePlans: (includeDisabledPlans, callback) => ({
    type: 'users.requestGetFinancePlans',
    includeDisabledPlans,
    callback,
  }),
  requestUpdateFinancePlans: (financePlans, callback) => ({
    type: 'users.requestUpdateFinancePlans',
    financePlans,
    callback,
  }),
  storeFinancePlans: (financePlans) => ({
    type: 'users.storeFinancePlans',
    financePlans,
  }),
  clearFinancePlans: () => ({
    type: 'users.clearFinancePlans',
  }),
};

const watchGetGlobalSettings = function* watchGetGlobalSettings() {
  while (true) {
    yield take('users.getGlobalSettings');
    try {
      const { response, timeout } = yield race({
        response: call(api.getGlobalSettings),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Get global settings timed out.');
      }
      yield put(actions.storeGlobalSettings(response));
    } catch (e) {
      console.log(e);
    }
  }
};

const watchRegisterUserDevice = function* watchRegisterUserDevice() {
  while (true) {
    const { deviceToken, callback } = yield take('users.registerUserDevice');
    try {
      const { response, timeout } = yield race({
        response: call(api.registerUserDevice, deviceToken),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Get profile request timed out.');
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchGetProfile = function* watchGetProfile() {
  while (true) {
    const { callback } = yield take('users.getProfile');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.getProfile),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Get profile request timed out.');
      }
      yield put(actions.storeMyProfile(response));
      yield put(actions.setAnalyticsUserId(response.id));

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchUpdateProfile = function* watchUpdateProfile() {
  while (true) {
    const { updatedProfile, callback } = yield take('users.updateProfile');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.updateProfile, updatedProfile),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Update profile request timed out.');
      }
      yield put(actions.storeMyProfile(response));

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

function* watchAnalyticsUserIdUpdate() {
  while (true) {
    const { userId } = yield take('users.setAnalyticsUserId');

    // we'll assign the id of the active user against all analytics activity
    if (window?.posthog?.indentify && userId) {
      window.posthog.identify(userId);
    }
  }
}

const watchUpdateNotificationSettings =
  function* watchUpdateNotificationSettings() {
    while (true) {
      const { payload, callback } = yield take(
        'users.updateNotificationSettings'
      );
      try {
        console.log('PAYLOAD', payload);
        const { response, timeout } = yield race({
          response: call(api.updateNotificationSettings, payload),
          timeout: delay(15000),
        });
        if (timeout) {
          throw new api.TimeoutException('Update profile request timed out.');
        }
        yield put(actions.storeMyProfile(response));

        if (callback) {
          callback(null, response);
        }
      } catch (e) {
        console.log(e);
        if (callback) {
          callback(e);
        }
      }
    }
  };

const watchUpdateSignUpPreferences = function* watchUpdateSignUpPreferences() {
  while (true) {
    const { agreedToPrivacy, subscribedToNewsletter, callback } = yield take(
      'users.updateSignUpPreferences'
    );
    try {
      const { response, timeout } = yield race({
        response: call(
          api.updateSignUpPreferences,
          agreedToPrivacy,
          subscribedToNewsletter
        ),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Update to sign up preferences request timed out.'
        );
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchUpdatePassword = function* watchUpdatePassword() {
  while (true) {
    const { payload, callback } = yield take('users.updatePassword');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.updatePassword, payload),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Update password request timed out.');
      }

      yield put(authActions.storeTokens(response.token, response.refreshToken));
      yield take('auth.storeTokensSuccess');

      if (callback) {
        callback(null);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchResendEmailVerification = function* watchResendEmailVerification() {
  while (true) {
    const { email, callback } = yield take('users.resendEmailVerification');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.resendEmailVerification, email),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Resend email verification request timed out.'
        );
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchLookupPostcode = function* watchLookupPostcode() {
  while (true) {
    const { postcode, callback } = yield take('users.lookupPostcode');
    yield put(startSubmit('lookupPostcode'));
    try {
      const { response, timeout } = yield race({
        response: call(api.lookupPostcode, postcode),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Lookup postcode request timed out.');
      }
      yield put(actions.storeAddressResults(response));

      yield put(stopSubmit('lookupPostcode'));
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      yield put(stopSubmit('lookupPostcode'));
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchLookupAddress = function* watchLookupAddress() {
  while (true) {
    const { addressId, callback } = yield take('users.lookupAddress');
    yield put(startSubmit('lookupAddress'));
    try {
      const { response, timeout } = yield race({
        response: call(api.lookupAddress, addressId),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Lookup address request timed out.');
      }

      yield put(stopSubmit('lookupAddress'));
      if (callback && response && response.address) {
        callback(null, response.address);
      }
    } catch (e) {
      console.log(e);
      yield put(stopSubmit('lookupAddress'));
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchValidateEmail = function* watchValidateEmail() {
  yield takeEvery('users.validateEmail', function* (action) {
    const email = action.email;
    const callback = action.callback;

    try {
      const { response, timeout } = yield race({
        response: call(api.validateEmail, email),
        timeout: delay(15000),
      });

      if (timeout) {
        throw new api.TimeoutException('Validate email request timed out.');
      }
      if (callback && response) {
        callback(null, response.isValid);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  });
};

const watchValidatePhone = function* watchValidatePhone() {
  yield takeEvery('users.validatePhone', function* (action) {
    const phone = action.phone;
    const callback = action.callback;
    try {
      const { response, timeout } = yield race({
        response: call(api.validatePhone, phone),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Validate phone request timed out.');
      }

      if (callback && response) {
        callback(null, response.isValid);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  });
};

const watchGetBlockedEmails = function* watchGetBlockedEmails() {
  yield takeEvery('users.getBlockedEmails', function* (action) {
    const { callback } = action;
    yield put(startSubmit('getBlockedEmails'));
    try {
      const { response, timeout } = yield race({
        response: call(api.getBlockedEmails),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Get blocked emails list request timed out.'
        );
      }

      yield put(stopSubmit('getBlockedEmails'));
      if (callback) {
        const responseList = response && response.suppressionList;
        const responseEmails =
          responseList && responseList.map((l) => l.EmailAddress);
        callback(null, responseEmails);
      }
    } catch (e) {
      console.log(e);
      yield put(stopSubmit('getBlockedEmails'));
      if (callback) {
        callback(e);
      }
    }
  });
};

const watchCreateBusinessAccount = function* watchCreateBusinessAccount() {
  while (true) {
    const { businessAccount, callback } = yield take(
      'users.createBusinessAccount'
    );
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.createBusinessAccount, businessAccount),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Create business account request timed out.'
        );
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchUpdateBusinessAccount = function* watchUpdateBusinessAccount() {
  while (true) {
    const { accountId, payload, callback } = yield take(
      'users.updateBusinessAccount'
    );
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.updateBusinessAccount, accountId, payload),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Update business account request timed out.'
        );
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e, e.message);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchUploadAccountLogo = function* watchUploadAccountLogo() {
  while (true) {
    const { accountId, imageFile, callback } = yield take(
      'users.uploadAccountLogo'
    );
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.uploadAccountLogo, accountId, imageFile),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Upload account logo request timed out.'
        );
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchGetBusinessAttachments = function* watchGetBusinessAttachments() {
  while (true) {
    const { callback } = yield take('users.getBusinessAttachments');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.getBusinessAttachments),
        timeout: delay(60000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Get business attachments request timed out.'
        );
      }
      yield put(actions.storeBusinessAttachments(response));
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchGetAccountTerms = function* watchGetAccountTerms() {
  while (true) {
    const { accountId, callback } = yield take('users.getAccountTerms');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.getAccountTerms, accountId),
        timeout: delay(60000),
      });
      if (timeout) {
        throw new api.TimeoutException('Get account terms request timed out.');
      }
      yield put(actions.storeTerms(response));
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchManageTermsDocuments = function* watchManageTermsDocuments() {
  while (true) {
    const { accountId, documentsToAdd, documentsToRemove, callback } =
      yield take('users.manageTermsDocuments');
    try {
      const { response, timeout } = yield race({
        response: call(
          api.manageTermsDocuments,
          accountId,
          documentsToAdd,
          documentsToRemove
        ),
        timeout: delay(60000),
      });
      yield call(refreshAuthToken);
      if (timeout) {
        throw new api.TimeoutException(
          'Manage of terms documents to account request timed out.'
        );
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      const message = JSON.parse(e.message);
      if (callback) {
        callback(message);
      }
    }
  }
};
const watchManageBusinessAttachments =
  function* watchManageBusinessAttachments() {
    while (true) {
      const { documentsToAdd, documentsToRemove, callback } = yield take(
        'users.manageBusinessAttachments'
      );
      yield call(refreshAuthToken);
      try {
        const { response, timeout } = yield race({
          response: call(
            api.manageBusinessAttachments,
            documentsToAdd,
            documentsToRemove
          ),
          timeout: delay(60000),
        });
        if (timeout) {
          throw new api.TimeoutException(
            'Manage of business attachments to account request timed out.'
          );
        }

        if (callback) {
          callback(null, response);
        }
      } catch (e) {
        const message = JSON.parse(e.message);
        if (callback) {
          callback(message);
        }
      }
    }
  };

const watchUploadTermsDocument = function* watchUploadTermsDocument() {
  while (true) {
    const { accountId, termsDocument, callback } = yield take(
      'users.uploadTermsDocument'
    );
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.uploadTermsDocument, accountId, termsDocument),
        timeout: delay(60000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Upload terms document to account request timed out.'
        );
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        console.log(e);
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors);
        }
      }
    }
  }
};

const watchRemoveTermsDocument = function* watchRemoveTermsDocument() {
  while (true) {
    const { accountId, termsDocumentId, callback } = yield take(
      'users.removeTermsDocument'
    );
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.removeTermsDocument, accountId, termsDocumentId),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Remove terms document from account request timed out.'
        );
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        console.log(e);
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors);
        }
      }
    }
  }
};

const watchRequestGetFinancePlans = function* watchRequestGetFinancePlans() {
  while (true) {
    const { includeDisabledPlans, callback } = yield take(
      'users.requestGetFinancePlans'
    );
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.requestGetFinancePlans, includeDisabledPlans),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Get finance plans request timed out.');
      }
      yield put(actions.storeFinancePlans(response));
    } catch (e) {
      yield put(actions.storeFinancePlans([]));
      console.log('Get finance plans', e);
      if (callback) {
        callback(e);
      }
    }
  }
};
const watchRequestUpdateFinancePlans =
  function* watchRequestUpdateFinancePlans() {
    while (true) {
      const { financePlans, callback } = yield take(
        'users.requestUpdateFinancePlans'
      );
      yield call(refreshAuthToken);
      try {
        const { response, timeout } = yield race({
          response: call(api.requestUpdateFinancePlans, financePlans),
          timeout: delay(15000),
        });
        if (timeout) {
          throw new api.TimeoutException(
            'Update finance plans request timed out.'
          );
        }

        if (callback) {
          callback(null, response);
        }
        yield put(actions.storeFinancePlans(response));
      } catch (e) {
        yield put(actions.storeFinancePlans([]));
        console.log('Update finance plans', e);
        if (callback) {
          callback(e);
        }
      }
    }
  };

const watchConnectWithXero = function* watchConnectWithXero() {
  while (true) {
    const { code, callback } = yield take('users.connectWithXero');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.connectWithXero, code),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Connect with Xero request timed out.');
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors);
        }
      }
    }
  }
};

const watchSyncXeroContacts = function* watchSyncXeroContacts() {
  while (true) {
    const { callback } = yield take('users.importXeroContacts');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.importXeroContacts),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Sync Xero contacts request timed out.');
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors);
        }
      }
    }
  }
};

const watchSyncXeroItems = function* watchSyncXeroItems() {
  while (true) {
    const { callback } = yield take('users.importXeroItems');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.importXeroItems),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Sync Xero items request timed out.');
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors);
        }
      }
    }
  }
};

const watchRemoveXeroConnection = function* watchRemoveXeroConnection() {
  while (true) {
    const { callback } = yield take('users.removeXeroConnection');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.removeXeroConnection),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Remove Xero connection request timed out.'
        );
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors);
        }
      }
    }
  }
};

const watchGetXeroAccounts = function* watchGetXeroAccounts() {
  while (true) {
    const { callback } = yield take('users.getXeroAccounts');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.getXeroAccounts),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Get Xero accounts request timed out.');
      }
      yield put(actions.storeXeroAccounts(response));

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors);
        }
      }
    }
  }
};

const watchUpdateXeroSettings = function* watchUpdateXeroSettings() {
  while (true) {
    const { payload, callback } = yield take('users.updateXeroSettings');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.updateXeroSettings, payload),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Update Xero settings request timed out.'
        );
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors);
        }
      }
    }
  }
};

const watchConnectWithQuickbooks = function* watchConnectWithQuickbooks() {
  while (true) {
    const { payload, callback } = yield take('users.connectWithQuickbooks');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.connectWithQuickbooks, payload),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Connect with Quickbooks request timed out.'
        );
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors);
        }
      }
    }
  }
};

const watchSyncQuickbooksCustomers = function* watchSyncQuickbooksCustomers() {
  while (true) {
    const { callback } = yield take('users.importQuickbooksCustomers');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.importQuickbooksCustomers),
        timeout: delay(45000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Sync Quickbooks contacts request timed out.'
        );
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors);
        }
      }
    }
  }
};

const watchSyncQuickbooksItems = function* watchSyncQuickbooksItems() {
  while (true) {
    const { callback } = yield take('users.importQuickbooksItems');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.importQuickbooksItems),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Sync Quickbooks items request timed out.'
        );
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors);
        }
      }
    }
  }
};

const watchRemoveQuickbooksConnection =
  function* watchRemoveQuickbooksConnection() {
    while (true) {
      const { callback } = yield take('users.removeQuickbooksConnection');
      yield call(refreshAuthToken);
      try {
        const { response, timeout } = yield race({
          response: call(api.removeQuickbooksConnection),
          timeout: delay(15000),
        });
        if (timeout) {
          throw new api.TimeoutException(
            'Remove Quickbooks connection request timed out.'
          );
        }

        if (callback) {
          callback(null, response);
        }
      } catch (e) {
        if (callback) {
          if (e.message instanceof Array) {
            callback(e.message[0].message.errors);
          } else {
            callback(e.message.errors);
          }
        }
      }
    }
  };

const watchManageSubscription = function* watchManageSubscription() {
  while (true) {
    const { callback } = yield take('users.manageSubscription');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.manageSubscription),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Manage subscription request timed out.'
        );
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors);
        }
      }
    }
  }
};

const watchGetAccountUsers = function* watchGetAccountUsers() {
  while (true) {
    const { callback } = yield take('users.getAccountUsers');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.getAccountUsers),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Get account users request timed out.');
      }
      yield put(actions.storeAccountUsers(response));
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      yield put(actions.storeAccountUsers([]));
      console.log('Get account users error: ', e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchReactivateUser = function* watchReactivateUser() {
  while (true) {
    const { userId, callback } = yield take('users.reactivateUser');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.reactivateUser, userId),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Reactivate user request timed out.');
      }
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log('Reactivate user error: ', e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchInviteUserToAccount = function* watchInviteUserToAccount() {
  while (true) {
    const { payload, callback } = yield take('users.inviteUserToAccount');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.inviteUserToAccount, payload),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Invite user to account request timed out.'
        );
      }
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log('Invite user to account error: ', e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchDeleteUserFromAccount = function* watchDeleteUserFromAccount() {
  while (true) {
    const { userId, callback } = yield take('users.deleteUserFromAccount');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.deleteUserFromAccount, userId),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Delete user from account request timed out.'
        );
      }
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log('Delete user from account error: ', e);
      if (callback) {
        callback(e);
      }
    }
  }
};

function* watchDeleteUsersFromAccount() {
  while (true) {
    const { userIds, callback } = yield take('users.deleteUsersFromAccount');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.deleteUsersFromAccount, userIds),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Delete users from account request timed out.'
        );
      }
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log('Delete users from account error: ', e);
      if (callback) {
        callback(e);
      }
    }
  }
}

const watchUpdateUsersAccountRole = function* watchUpdateUserAccountRole() {
  while (true) {
    const { userIds, payload, callback } = yield take(
      'users.updateUsersAccountRole'
    );
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.updateUsersAccountRole, userIds, payload),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Updating account roles for users request timed out.'
        );
      }
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log('Updating account roles for users error: ', e);
      if (callback) {
        callback(e);
      }
    }
  }
};

const watchConnectWithStripe = function* watchConnectWithStripe() {
  while (true) {
    const { code, callback } = yield take('users.connectWithStripe');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.connectWithStripe, code),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Connect with Stripe request timed out.'
        );
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors);
        }
      }
    }
  }
};

const watchRemoveStripeConnection = function* watchRemoveStripeConnection() {
  while (true) {
    const { callback } = yield take('users.removeStripeConnection');
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(api.removeStripeConnection),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Remove Stripe connection request timed out.'
        );
      }

      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors);
        }
      }
    }
  }
};

const watchLogout = function* watchLogout() {
  while (true) {
    const { callback } = yield take('auth.logout');
    yield put(actions.clearFinancePlans());
    yield put(actions.clearMyProfile());
    // clear table filters
    localStorage.removeItem('payaca-propositions-status-filter');
    localStorage.removeItem('payaca-invoices-status-filter');
    localStorage.removeItem('payaca-propositions-user-filter');
    localStorage.removeItem('payaca-invoices-user-filter');
  }
};

export const saga = function* saga() {
  yield all([
    watchReactivateUser(),
    watchLogout(),
    watchRequestGetFinancePlans(),
    watchRequestUpdateFinancePlans(),
    watchGetGlobalSettings(),
    watchRegisterUserDevice(),
    watchGetProfile(),
    watchUpdateProfile(),
    watchAnalyticsUserIdUpdate(),
    watchUpdateNotificationSettings(),
    watchUpdateSignUpPreferences(),
    watchUpdatePassword(),
    watchResendEmailVerification(),
    watchLookupPostcode(),
    watchLookupAddress(),
    watchValidateEmail(),
    watchValidatePhone(),
    watchCreateBusinessAccount(),
    watchUpdateBusinessAccount(),
    watchUploadAccountLogo(),
    watchGetAccountTerms(),
    watchManageTermsDocuments(),
    watchUploadTermsDocument(),
    watchRemoveTermsDocument(),
    watchConnectWithXero(),
    watchSyncXeroContacts(),
    watchSyncXeroItems(),
    watchGetXeroAccounts(),
    watchRemoveXeroConnection(),
    watchConnectWithQuickbooks(),
    watchSyncQuickbooksCustomers(),
    watchSyncQuickbooksItems(),
    watchRemoveQuickbooksConnection(),
    watchGetBlockedEmails(),
    watchUpdateXeroSettings(),
    watchManageSubscription(),
    watchGetAccountUsers(),
    watchInviteUserToAccount(),
    watchDeleteUserFromAccount(),
    watchDeleteUsersFromAccount(),
    watchUpdateUsersAccountRole(),
    watchConnectWithStripe(),
    watchRemoveStripeConnection(),
    watchGetBusinessAttachments(),
    watchManageBusinessAttachments(),
  ]);
};
