import React, { useEffect, useState } from 'react';
import { BasicField } from '@payaca/components/basicField';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
var SearchField = function (_a) {
    var styleVariant = _a.styleVariant, initSearchTerm = _a.initSearchTerm, placeholder = _a.placeholder, onSearchTermChange = _a.onSearchTermChange, _b = _a.showSearchIcon, showSearchIcon = _b === void 0 ? true : _b;
    var _c = useState(), searchTerm = _c[0], setSearchTerm = _c[1];
    var _d = useState(false), requiresOnChange = _d[0], setRequiresOnChange = _d[1];
    useEffect(function () {
        setSearchTerm(initSearchTerm);
    }, [initSearchTerm]);
    useEffect(function () {
        if (requiresOnChange) {
            onSearchTermChange(searchTerm);
            setRequiresOnChange(false);
        }
    }, [requiresOnChange]);
    return (React.createElement("div", { className: "search-term-field-container flex-grow" },
        React.createElement(BasicField, { styleVariant: styleVariant, name: "searchTerm", onChange: function (value) {
                return setSearchTerm(value.searchTerm);
            }, iconBefore: showSearchIcon ? faSearch : undefined, additionalInputProps: {
                placeholder: placeholder,
            }, onChangeTimeout: function () { return setRequiresOnChange(true); }, onBlur: function () { return setRequiresOnChange(true); } })));
};
export default SearchField;
