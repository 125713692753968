import React from 'react';
import './NavigationMenuItem.sass';
export var CollapseDirection;
(function (CollapseDirection) {
    CollapseDirection["LEFT"] = "left";
    CollapseDirection["RIGHT"] = "right";
})(CollapseDirection || (CollapseDirection = {}));
var NavigationMenuItem = function (_a) {
    var iconElement = _a.iconElement, _b = _a.isComingSoon, isComingSoon = _b === void 0 ? false : _b, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c, _d = _a.isSelected, isSelected = _d === void 0 ? false : _d, onClick = _a.onClick, title = _a.title;
    return (React.createElement("div", { className: "navigation-menu-item " + (isSelected ? 'selected' : '') + " " + (isDisabled ? 'disabled' : ''), "aria-current": isSelected ? 'page' : undefined, role: "menuitem", onClick: function () { return !isDisabled && onClick && onClick(); } },
        React.createElement("div", null,
            React.createElement("div", { className: "icon-container" },
                React.createElement("div", { className: "icon-container-inner" }, iconElement))),
        React.createElement("div", { className: "title-container" },
            React.createElement("span", null, title),
            isComingSoon && (React.createElement("div", { className: "tooltip-container" },
                React.createElement("span", { className: "tooltip-text" }, "Coming soon!"))))));
};
export default NavigationMenuItem;
