import React, { useCallback } from 'react';
import LabelValuePair from '../labelValuePair/LabelValuePair';
import { currencyPrice } from '@payaca/helpers/financeHelper';
var FinancialInformationOverview = function (_a) {
    var _b, _c;
    var financialInformation = _a.financialInformation;
    var renderProducts = useCallback(function (products) {
        if (!products || !products.length) {
            return React.createElement("span", null, "None");
        }
        return (React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { align: "left" }, "Description"),
                    React.createElement("th", { align: "right" }, "Avg order value"),
                    React.createElement("th", { align: "right" }, "Total sales turnover (%)"),
                    React.createElement("th", { align: "right" }, "Guarantee offer (months)"),
                    React.createElement("th", { align: "left" }, "Insurance backed?"))),
            React.createElement("tbody", null, products.map(function (product, index) {
                return (React.createElement("tr", { key: index },
                    React.createElement("td", { align: "left" },
                        React.createElement("span", { className: "sm-view-label" }, "Description:"),
                        product.description),
                    React.createElement("td", { align: "right" },
                        React.createElement("span", { className: "sm-view-label" }, "Avg order value:"),
                        currencyPrice((product.averageOrderValue || 0) * 100)),
                    React.createElement("td", { align: "right" },
                        React.createElement("span", { className: "sm-view-label" }, "Total sales turnover (%):"),
                        product.totalSalesTurnoverPercentage,
                        "%"),
                    React.createElement("td", { align: "right" },
                        React.createElement("span", { className: "sm-view-label" }, "Guarantee offer (months):"),
                        product.guaranteeOfferMonths),
                    React.createElement("td", { align: "left" },
                        React.createElement("span", { className: "sm-view-label" }, "Insurance backed?:"),
                        product.isInsuranceBacked
                            ? "backed by: " + product.insuranceBackedBy
                            : 'NO')));
            }))));
    }, []);
    return (React.createElement("div", { className: "financial-information-overview" },
        React.createElement("div", { className: "credit-service-providers-container" },
            React.createElement(LabelValuePair, { label: "Do you currently have a credit service provider?", value: (financialInformation === null || financialInformation === void 0 ? void 0 : financialInformation.hasCurrentCreditServiceProvider) ? 'Yes' : 'No' }),
            (financialInformation === null || financialInformation === void 0 ? void 0 : financialInformation.hasCurrentCreditServiceProvider) && (React.createElement("div", { className: "multiline-label-value" },
                React.createElement(LabelValuePair, { label: "List any companies who currently or have previously provided your company with credit facilities for your customers", value: (financialInformation === null || financialInformation === void 0 ? void 0 : financialInformation.creditServiceProviders) || '' })))),
        React.createElement("div", null,
            React.createElement(LabelValuePair, { label: "How much business do you expect to write using credit terms in the next 12 months?", value: currencyPrice(((financialInformation === null || financialInformation === void 0 ? void 0 : financialInformation.next12MonthsBusinessValueUsingCreditTerms) ||
                    0) * 100) }),
            React.createElement(LabelValuePair, { label: "Number of branches", value: ((_b = financialInformation === null || financialInformation === void 0 ? void 0 : financialInformation.numberOfBranches) === null || _b === void 0 ? void 0 : _b.toString()) || '' }),
            React.createElement(LabelValuePair, { label: "Number of salespeople", value: ((_c = financialInformation === null || financialInformation === void 0 ? void 0 : financialInformation.numberOfSalespeople) === null || _c === void 0 ? void 0 : _c.toString()) || '' })),
        React.createElement("div", { className: "products-container" },
            React.createElement("div", null,
                React.createElement("strong", null, "Top selling products/services")),
            renderProducts(financialInformation === null || financialInformation === void 0 ? void 0 : financialInformation.products))));
};
export default FinancialInformationOverview;
