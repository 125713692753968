export var ActionType;
(function (ActionType) {
    ActionType["REQUEST_GET_BUSINESS_INFORMATION"] = "subsidisedFinanceInformation.requestGetBusinessInformation";
    ActionType["GET_BUSINESS_INFORMATION_SUCCESS"] = "subsidisedFinanceInformation.getBusinessInformationSuccess";
    ActionType["GET_BUSINESS_INFORMATION_FAILURE"] = "subsidisedFinanceInformation.getBusinessInformationFailure";
    ActionType["CLEAR_BUSINESS_INFORMATION"] = "subsidisedFinanceInformation.clearBusinessInformation";
    ActionType["REQUEST_PERSIST_BUSINESS_INFORMATION"] = "subsidisedFinanceInformation.requestPersistBusinessInformation";
    ActionType["PERSIST_BUSINESS_INFORMATION_SUCCESS"] = "subsidisedFinanceInformation.persistBusinessInformationSuccess";
    ActionType["PERSIST_BUSINESS_INFORMATION_FAILURE"] = "subsidisedFinanceInformation.persistBusinessInformationFailure";
    ActionType["REQUEST_PERSIST_COMPLETE_BUSINESS_INFORMATION"] = "subsidisedFinanceInformation.requestPersistCompleteBusinessInformation";
    ActionType["PERSIST_COMPLETE_BUSINESS_INFORMATION_SUCCESS"] = "subsidisedFinanceInformation.persistCompleteBusinessInformationSuccess";
    ActionType["PERSIST_COMPLETE_BUSINESS_INFORMATION_FAILURE"] = "subsidisedFinanceInformation.persistCompleteBusinessInformationFailure";
    ActionType["REQUEST_GET_FINANCIAL_INFORMATION"] = "subsidisedFinanceInformation.requestGetFinancialInformation";
    ActionType["GET_FINANCIAL_INFORMATION_SUCCESS"] = "subsidisedFinanceInformation.getFinancialInformationSuccess";
    ActionType["GET_FINANCIAL_INFORMATION_FAILURE"] = "subsidisedFinanceInformation.getFinancialInformationFailure";
    ActionType["CLEAR_FINANCIAL_INFORMATION"] = "subsidisedFinanceInformation.clearFinancialInformation";
    ActionType["REQUEST_PERSIST_FINANCIAL_INFORMATION"] = "subsidisedFinanceInformation.requestPersistFinancialInformation";
    ActionType["PERSIST_FINANCIAL_INFORMATION_SUCCESS"] = "subsidisedFinanceInformation.persistFinancialInformationSuccess";
    ActionType["PERSIST_FINANCIAL_INFORMATION_FAILURE"] = "subsidisedFinanceInformation.persistFinancialInformationFailure";
    ActionType["REQUEST_PERSIST_COMPLETE_FINANCIAL_INFORMATION"] = "subsidisedFinanceInformation.requestPersistCompleteFinancialInformation";
    ActionType["PERSIST_COMPLETE_FINANCIAL_INFORMATION_SUCCESS"] = "subsidisedFinanceInformation.persistCompleteFinancialInformationSuccess";
    ActionType["PERSIST_COMPLETE_FINANCIAL_INFORMATION_FAILURE"] = "subsidisedFinanceInformation.persistCompleteFinancialInformationFailure";
    ActionType["REQUEST_GET_REGULATORY_REQUIREMENTS"] = "subsidisedFinanceInformation.requestGetRegulatoryRequirements";
    ActionType["GET_REGULATORY_REQUIREMENTS_SUCCESS"] = "subsidisedFinanceInformation.getRegulatoryRequirementsSuccess";
    ActionType["GET_REGULATORY_REQUIREMENTS_FAILURE"] = "subsidisedFinanceInformation.getRegulatoryRequirementsFailure";
    ActionType["CLEAR_REGULATORY_REQUIREMENTS"] = "subsidisedFinanceInformation.clearRegulatoryRequirements";
    ActionType["REQUEST_PERSIST_REGULATORY_REQUIREMENTS"] = "subsidisedFinanceInformation.requestPersistRegulatoryRequirements";
    ActionType["PERSIST_REGULATORY_REQUIREMENTS_SUCCESS"] = "subsidisedFinanceInformation.persistRegulatoryRequirementsSuccess";
    ActionType["PERSIST_REGULATORY_REQUIREMENTS_FAILURE"] = "subsidisedFinanceInformation.persistRegulatoryRequirementsFailure";
    ActionType["REQUEST_PERSIST_COMPLETE_REGULATORY_REQUIREMENTS"] = "subsidisedFinanceInformation.requestPersistCompleteRegulatoryRequirements";
    ActionType["PERSIST_COMPLETE_REGULATORY_REQUIREMENTS_SUCCESS"] = "subsidisedFinanceInformation.persistCompleteRegulatoryRequirementsSuccess";
    ActionType["PERSIST_COMPLETE_REGULATORY_REQUIREMENTS_FAILURE"] = "subsidisedFinanceInformation.persistCompleteRegulatoryRequirementsFailure";
    ActionType["REQUEST_GET_ADDITIONAL_INFORMATION"] = "subsidisedFinanceInformation.requestGetAdditionalInformation";
    ActionType["GET_ADDITIONAL_INFORMATION_SUCCESS"] = "subsidisedFinanceInformation.getAdditionalInformationSuccess";
    ActionType["GET_ADDITIONAL_INFORMATION_FAILURE"] = "subsidisedFinanceInformation.getAdditionalInformationFailure";
    ActionType["CLEAR_ADDITIONAL_INFORMATION"] = "subsidisedFinanceInformation.clearAdditionalInformation";
    ActionType["REQUEST_PERSIST_ADDITIONAL_INFORMATION"] = "subsidisedFinanceInformation.requestPersistAdditionalInformation";
    ActionType["PERSIST_ADDITIONAL_INFORMATION_SUCCESS"] = "subsidisedFinanceInformation.persistAdditionalInformationSuccess";
    ActionType["PERSIST_ADDITIONAL_INFORMATION_FAILURE"] = "subsidisedFinanceInformation.persistAdditionalInformationFailure";
    ActionType["REQUEST_PERSIST_COMPLETE_ADDITIONAL_INFORMATION"] = "subsidisedFinanceInformation.requestPersistCompleteAdditionalInformation";
    ActionType["PERSIST_COMPLETE_ADDITIONAL_INFORMATION_SUCCESS"] = "subsidisedFinanceInformation.persistCompleteAdditionalInformationSuccess";
    ActionType["PERSIST_COMPLETE_ADDITIONAL_INFORMATION_FAILURE"] = "subsidisedFinanceInformation.persistCompleteAdditionalInformationFailure";
    ActionType["REQUEST_GET_AUTHORITY_TO_SEARCH"] = "subsidisedFinanceInformation.requestGetAuthorityToSearch";
    ActionType["GET_AUTHORITY_TO_SEARCH_SUCCESS"] = "subsidisedFinanceInformation.getAuthorityToSearchSuccess";
    ActionType["GET_AUTHORITY_TO_SEARCH_FAILURE"] = "subsidisedFinanceInformation.getAuthorityToSearchFailure";
    ActionType["CLEAR_AUTHORITY_TO_SEARCH"] = "subsidisedFinanceInformation.clearAuthorityToSearch";
    ActionType["REQUEST_PERSIST_AUTHORITY_TO_SEARCH"] = "subsidisedFinanceInformation.requestPersistAuthorityToSearch";
    ActionType["PERSIST_AUTHORITY_TO_SEARCH_SUCCESS"] = "subsidisedFinanceInformation.persistAuthorityToSearchSuccess";
    ActionType["PERSIST_AUTHORITY_TO_SEARCH_FAILURE"] = "subsidisedFinanceInformation.persistAuthorityToSearchFailure";
    ActionType["REQUEST_PERSIST_COMPLETE_AUTHORITY_TO_SEARCH"] = "subsidisedFinanceInformation.requestPersistCompleteAuthorityToSearch";
    ActionType["PERSIST_COMPLETE_AUTHORITY_TO_SEARCH_SUCCESS"] = "subsidisedFinanceInformation.persistCompleteAuthorityToSearchSuccess";
    ActionType["PERSIST_COMPLETE_AUTHORITY_TO_SEARCH_FAILURE"] = "subsidisedFinanceInformation.persistCompleteAuthorityToSearchFailure";
    ActionType["REQUEST_GET_SUPPORTING_DOCUMENTS"] = "subsidisedFinanceInformation.requestGetSupportingDocuments";
    ActionType["GET_SUPPORTING_DOCUMENTS_SUCCESS"] = "subsidisedFinanceInformation.getSupportingDocumentsSuccess";
    ActionType["GET_SUPPORTING_DOCUMENTS_FAILURE"] = "subsidisedFinanceInformation.getSupportingDocumentsFailure";
    ActionType["CLEAR_SUPPORTING_DOCUMENTS"] = "subsidisedFinanceInformation.clearSupportingDocuments";
    ActionType["REQUEST_PERSIST_GENERIC_SUPPORTING_DOCUMENT"] = "subsidisedFinanceInformation.requestPersistGenericSupportingDocument";
    ActionType["REQUEST_PERSIST_DIRECTOR_ID_DOCUMENT"] = "subsidisedFinanceInformation.requestPersistDirectorIdDocument";
    ActionType["REQUEST_PERSIST_DIRECTOR_ADDRESS_DOCUMENT"] = "subsidisedFinanceInformation.requestPersistDirectorAddressDocument";
    ActionType["REQUEST_PERSIST_BANK_STATEMENT_DOCUMENT"] = "subsidisedFinanceInformation.requestPersistBankStatementDocument";
    ActionType["REQUEST_REMOVE_SUPPORTING_DOCUMENT"] = "subsidisedFinanceInformation.requestRemoveSupportingDocument";
    ActionType["PERSIST_SUPPORTING_DOCUMENT_SUCCESS"] = "subsidisedFinanceInformation.persistSupportingDocumentSuccess";
    ActionType["REMOVE_SUPPORTING_DOCUMENT_SUCCESS"] = "subsidisedFinanceInformation.persistSupportingDocumentSuccess";
    ActionType["PERSIST_SUPPORTING_DOCUMENT_FAILURE"] = "subsidisedFinanceInformation.persistSupportingDocumentFailure";
    ActionType["REMOVE_SUPPORTING_DOCUMENT_FAILURE"] = "subsidisedFinanceInformation.persistSupportingDocumentFailure";
    ActionType["REQUEST_COMPLETE_SUPPORTING_DOCUMENTS"] = "subsidisedFinanceInformation.requestCompleteSupportingDocuments";
    ActionType["COMPLETE_SUPPORTING_DOCUMENTS_SUCCESS"] = "subsidisedFinanceInformation.completeSupportingDocumentsSuccess";
    ActionType["COMPLETE_SUPPORTING_DOCUMENTS_FAILURE"] = "subsidisedFinanceInformation.completeSupportingDocumentsFailure";
    ActionType["REQUEST_GET_CURRENT_STEP_LABEL"] = "subsidisedFinanceInformation.requestGetCurrentStepLabel";
    ActionType["GET_CURRENT_STEP_LABEL_SUCCESS"] = "subsidisedFinanceInformation.getCurrentStepLabelSuccess";
    ActionType["GET_CURRENT_STEP_LABEL_FAILURE"] = "subsidisedFinanceInformation.getCurrentStepLabelFailure";
    ActionType["CLEAR_CURRENT_STEP_LABEL"] = "subsidisedFinanceInformation.clearCurrentStepLabel";
    ActionType["REQUEST_SUBMIT_SUBSIDISED_FINANCE_INFORMATION"] = "subsidisedFinanceInformation.requestSubmitSubsidisedFinanceInformation";
    ActionType["SUBMIT_SUBSIDISED_FINANCE_INFORMATION_SUCCESS"] = "subsidisedFinanceInformation.submitSubsidisedFinanceInformationSuccess";
    ActionType["SUBMIT_SUBSIDISED_FINANCE_INFORMATION_FAILURE"] = "subsidisedFinanceInformation.submitSubsidisedFinanceInformationFailure";
    ActionType["REQUEST_SET_CURRENT_STEP"] = "subsidisedFinanceInformation.requestSetCurrentStep";
    ActionType["SET_CURRENT_STEP_SUCCESS"] = "subsidisedFinanceInformation.setCurrentStepSuccess";
    ActionType["SET_CURRENT_STEP_FAILURE"] = "subsidisedFinanceInformation.setCurrentStepFailure";
    ActionType["REQUEST_GET_COMPANIES_HOUSE_COMPANY_PREVIEW"] = "subsidisedFinanceInformation.requestGetCompaniesHouseCompanyPreview";
    ActionType["GET_COMPANIES_HOUSE_COMPANY_PREVIEW_SUCCESS"] = "subsidisedFinanceInformation.getCompaniesHouseCompanyPreviewSuccess";
    ActionType["GET_COMPANIES_HOUSE_COMPANY_PREVIEW_FAILURE"] = "subsidisedFinanceInformation.getCompaniesHouseCompanyPreviewFailure";
    ActionType["CLEAR_COMPANIES_HOUSE_COMPANY_PREVIEW"] = "subsidisedFinanceInformation.clearCompaniesHouseCompanyPreview";
    ActionType["REQUEST_PERSIST_COMPLETE_GET_STARTED"] = "subsidisedFinanceInformation.requestPersistCompleteGetStarted";
    ActionType["PERSIST_COMPLETE_GET_STARTED_SUCCESS"] = "subsidisedFinanceInformation.persistCompleteGetStartedSuccess";
    ActionType["PERSIST_COMPLETE_GET_STARTED_FAILURE"] = "subsidisedFinanceInformation.persistCompleteGetStartedFailure";
})(ActionType || (ActionType = {}));
