import React, { useCallback } from 'react';
import Tab from './Tab';
import './TabbedView.sass';
var TabbedView = function (_a) {
    var tabs = _a.tabs, activeTabLabel = _a.activeTabLabel, onChangeTab = _a.onChangeTab;
    var renderSelectedTabContent = useCallback(function () { var _a; return (_a = tabs === null || tabs === void 0 ? void 0 : tabs.find(function (x) { return x.label === activeTabLabel; })) === null || _a === void 0 ? void 0 : _a.content; }, [activeTabLabel, tabs]);
    return (React.createElement("div", { className: "tabbed-view-container" },
        React.createElement("div", { className: "tabs-wrapper" },
            React.createElement("div", { className: "tabs" }, tabs.map(function (tab, i) {
                return (React.createElement(Tab, { title: tab.title, isActive: tab.label === activeTabLabel, isDisabled: tab.isDisabled, onTabSelection: function () {
                        onChangeTab(tab.label);
                    }, key: i }));
            }))),
        React.createElement("div", { className: "tab-content-container" }, renderSelectedTabContent())));
};
export default TabbedView;
