import React, { useState, useCallback, useMemo, } from 'react';
import { useDispatch } from 'react-redux';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import './ResendVerificationEmailControl.sass';
import { actions as userActions } from '@/api/users';
import { ValidatedForm } from '@payaca/components';
import { getEmailFieldValidator, getIsRequiredFieldValidator, } from '@payaca/helpers/fieldValidationHelper';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import LoqateAdvisoryEmailInputField from '../loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
var ResendVerificationEmailControl = function (_a) {
    var userEmailAddress = _a.userEmailAddress, _b = _a.allowUserEmailAddressInput, allowUserEmailAddressInput = _b === void 0 ? false : _b;
    var dispatch = useDispatch();
    var _c = useState(false), verificationEmailResent = _c[0], setVerificationEmailResent = _c[1];
    var _d = useState(false), sendingVerificationEmail = _d[0], setSendingVerificationEmail = _d[1];
    var initialFormState = useMemo(function () {
        return {
            emailAddress: userEmailAddress,
        };
    }, [userEmailAddress]);
    var fieldValidators = useMemo(function () {
        return {
            emailAddress: [getIsRequiredFieldValidator(), getEmailFieldValidator()],
        };
    }, []);
    var resendVerificationEmail = useCallback(function (emailAddress) {
        setSendingVerificationEmail(true);
        dispatch(userActions.resendEmailVerification(emailAddress, function (error, response) {
            setSendingVerificationEmail(false);
            if (!error) {
                setVerificationEmailResent(true);
            }
        }));
    }, [dispatch]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        return (React.createElement(React.Fragment, null,
            allowUserEmailAddressInput && (React.createElement(ValidatedFieldWrapper, { validationResult: validationState['emailAddress'], isTouched: touchedState['emailAddress'] || false },
                React.createElement(LoqateAdvisoryEmailInputField, { styleVariant: InputStyleVariant.OUTSIZE, isRequired: true, label: "Email", name: "emailAddress", value: formState.emailAddress, onTouch: onFieldTouch, onChange: function (value) {
                        setVerificationEmailResent(false);
                        onFieldChange(value);
                    }, disableValidation: validationState['emailAddress']
                        ? !validationState['emailAddress'].isValid
                        : false }))),
            React.createElement(Button, { onClick: function () {
                    return !sendingVerificationEmail &&
                        resendVerificationEmail(formState.emailAddress);
                }, styleVariant: ButtonStyleVariant.OUTSIZE, isProcessing: sendingVerificationEmail, isDisabled: verificationEmailResent || !isValid }, verificationEmailResent ? 'Email resent' : 'Resend email')));
    }, [
        allowUserEmailAddressInput,
        sendingVerificationEmail,
        resendVerificationEmail,
        verificationEmailResent,
    ]);
    return (React.createElement("div", { className: "resend-verification-email-control" },
        React.createElement(ValidatedForm, { renderFormContents: renderFormContents, fieldValidators: fieldValidators, initialFormState: initialFormState })));
};
export default ResendVerificationEmailControl;
