var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import './PopupMenu.sass';
var PopupMenu = function (_a) {
    var anchorElement = _a.anchorElement, isOpen = _a.isOpen, onClose = _a.onClose, options = _a.options;
    var renderOptions = function () {
        return options.map(function (option, index) {
            return (React.createElement(MenuItem, { key: index, onClick: option.onClick, className: "menu-option" + (option.isProcessing ? ' processing' : '') },
                option.isProcessing && (React.createElement("span", { className: 'spinner-container' },
                    React.createElement(FontAwesomeIcon, { icon: faCircleNotch, spin: true, style: { fontSize: '0.8rem' } }))),
                React.createElement("span", { className: "option-name" }, option.name)));
        });
    };
    return (React.createElement(Popper, { open: isOpen && !!anchorElement, anchorEl: anchorElement, role: undefined, transition: true, placement: "left-end", className: "popup-menu" }, function (_a) {
        var TransitionProps = _a.TransitionProps;
        return (React.createElement(Grow, __assign({}, TransitionProps),
            React.createElement(Paper, null,
                React.createElement(ClickAwayListener, { onClickAway: onClose },
                    React.createElement(MenuList, { autoFocusItem: isOpen && !!anchorElement, id: "menu-list-grow" }, renderOptions())))));
    }));
};
export default PopupMenu;
