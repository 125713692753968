import React from 'react';
import TooltipUI from '@material-ui/core/Tooltip';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Tooltip.css';
var Tooltip = function (_a) {
    var text = _a.text, _b = _a.placement, placement = _b === void 0 ? 'top' : _b, children = _a.children;
    return (React.createElement(TooltipUI, { title: text, arrow: true, placement: placement, className: "tooltip-wrapper" },
        React.createElement("span", null, children || (React.createElement(FontAwesomeIcon, { icon: faQuestionCircle, className: "tooltip-icon" })))));
};
export default Tooltip;
