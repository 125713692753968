var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionType } from "./subsidisedFinanceInformationTypes";
export var initialState = {
    isSubmitted: false,
    isSubmitting: false,
    currentStepLabel: null,
    businessInformation: null,
    isGettingBusinessInformation: false,
    isPersistingBusinessInformation: false,
    financialInformation: null,
    isGettingFinancialInformation: false,
    isPersistingFinancialInformation: false,
    regulatoryRequirements: null,
    isGettingRegulatoryRequirements: false,
    isPersistingRegulatoryRequirements: false,
    additionalInformation: null,
    isGettingAdditionalInformation: false,
    isPersistingAdditionalInformation: false,
    authorityToSearch: null,
    isGettingAuthorityToSearch: false,
    isPersistingAuthorityToSearch: false,
    supportingDocuments: null,
    isGettingSupportingDocuments: false,
    isPersistingSupportingDocuments: false,
    companiesHouseCompanyPreview: null,
    isGettingCompaniesHouseCompanyPreview: false,
    isPersistingGetStarted: false,
};
var subsidisedFinanceInformationReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionType.REQUEST_GET_COMPANIES_HOUSE_COMPANY_PREVIEW:
            return __assign(__assign({}, state), { isGettingCompaniesHouseCompanyPreview: true });
        case ActionType.GET_COMPANIES_HOUSE_COMPANY_PREVIEW_FAILURE:
            return __assign(__assign({}, state), { companiesHouseCompanyPreview: null, isGettingCompaniesHouseCompanyPreview: false });
        case ActionType.GET_COMPANIES_HOUSE_COMPANY_PREVIEW_SUCCESS:
            return __assign(__assign({}, state), { companiesHouseCompanyPreview: action.payload.companiesHouseCompanyPreview, isGettingCompaniesHouseCompanyPreview: false });
        case ActionType.CLEAR_COMPANIES_HOUSE_COMPANY_PREVIEW:
            return __assign(__assign({}, state), { companiesHouseCompanyPreview: null });
        case ActionType.REQUEST_PERSIST_COMPLETE_GET_STARTED:
            return __assign(__assign({}, state), { isPersistingGetStarted: true });
        case ActionType.PERSIST_COMPLETE_GET_STARTED_SUCCESS:
        case ActionType.PERSIST_COMPLETE_GET_STARTED_FAILURE:
            return __assign(__assign({}, state), { isPersistingGetStarted: false });
        case ActionType.REQUEST_GET_BUSINESS_INFORMATION:
            return __assign(__assign({}, state), { isGettingBusinessInformation: true });
        case ActionType.REQUEST_PERSIST_BUSINESS_INFORMATION:
        case ActionType.REQUEST_PERSIST_COMPLETE_BUSINESS_INFORMATION:
            return __assign(__assign({}, state), { isPersistingBusinessInformation: true });
        case ActionType.GET_BUSINESS_INFORMATION_FAILURE:
            return __assign(__assign({}, state), { businessInformation: null, isGettingBusinessInformation: false });
        case ActionType.CLEAR_BUSINESS_INFORMATION:
            return __assign(__assign({}, state), { businessInformation: null });
        case ActionType.GET_BUSINESS_INFORMATION_SUCCESS:
            return __assign(__assign({}, state), { businessInformation: action.payload.businessInformation, isGettingBusinessInformation: false });
        case ActionType.PERSIST_BUSINESS_INFORMATION_SUCCESS:
        case ActionType.PERSIST_BUSINESS_INFORMATION_FAILURE:
        case ActionType.PERSIST_COMPLETE_BUSINESS_INFORMATION_SUCCESS:
        case ActionType.PERSIST_COMPLETE_BUSINESS_INFORMATION_FAILURE:
            return __assign(__assign({}, state), { isPersistingBusinessInformation: false });
        case ActionType.REQUEST_GET_FINANCIAL_INFORMATION:
            return __assign(__assign({}, state), { isGettingFinancialInformation: true });
        case ActionType.REQUEST_PERSIST_FINANCIAL_INFORMATION:
        case ActionType.REQUEST_PERSIST_COMPLETE_FINANCIAL_INFORMATION:
            return __assign(__assign({}, state), { isPersistingFinancialInformation: true });
        case ActionType.GET_FINANCIAL_INFORMATION_FAILURE:
            return __assign(__assign({}, state), { financialInformation: null, isGettingFinancialInformation: false });
        case ActionType.CLEAR_FINANCIAL_INFORMATION:
            return __assign(__assign({}, state), { financialInformation: null });
        case ActionType.GET_FINANCIAL_INFORMATION_SUCCESS:
            return __assign(__assign({}, state), { financialInformation: action.payload.financialInformation, isGettingFinancialInformation: false });
        case ActionType.PERSIST_FINANCIAL_INFORMATION_SUCCESS:
        case ActionType.PERSIST_FINANCIAL_INFORMATION_FAILURE:
        case ActionType.PERSIST_COMPLETE_FINANCIAL_INFORMATION_SUCCESS:
        case ActionType.PERSIST_COMPLETE_FINANCIAL_INFORMATION_FAILURE:
            return __assign(__assign({}, state), { isPersistingFinancialInformation: false });
        case ActionType.REQUEST_GET_REGULATORY_REQUIREMENTS:
            return __assign(__assign({}, state), { isGettingRegulatoryRequirements: true });
        case ActionType.REQUEST_PERSIST_REGULATORY_REQUIREMENTS:
        case ActionType.REQUEST_PERSIST_COMPLETE_REGULATORY_REQUIREMENTS:
            return __assign(__assign({}, state), { isPersistingRegulatoryRequirements: true });
        case ActionType.GET_REGULATORY_REQUIREMENTS_FAILURE:
            return __assign(__assign({}, state), { regulatoryRequirements: null, isGettingRegulatoryRequirements: false });
        case ActionType.CLEAR_REGULATORY_REQUIREMENTS:
            return __assign(__assign({}, state), { regulatoryRequirements: null });
        case ActionType.GET_REGULATORY_REQUIREMENTS_SUCCESS:
            return __assign(__assign({}, state), { regulatoryRequirements: action.payload.regulatoryRequirements, isGettingRegulatoryRequirements: false });
        case ActionType.PERSIST_REGULATORY_REQUIREMENTS_SUCCESS:
        case ActionType.PERSIST_REGULATORY_REQUIREMENTS_FAILURE:
        case ActionType.PERSIST_COMPLETE_REGULATORY_REQUIREMENTS_SUCCESS:
        case ActionType.PERSIST_COMPLETE_REGULATORY_REQUIREMENTS_FAILURE:
            return __assign(__assign({}, state), { isPersistingRegulatoryRequirements: false });
        case ActionType.REQUEST_GET_ADDITIONAL_INFORMATION:
            return __assign(__assign({}, state), { isGettingAdditionalInformation: true });
        case ActionType.REQUEST_PERSIST_ADDITIONAL_INFORMATION:
        case ActionType.REQUEST_PERSIST_COMPLETE_ADDITIONAL_INFORMATION:
            return __assign(__assign({}, state), { isPersistingAdditionalInformation: true });
        case ActionType.GET_ADDITIONAL_INFORMATION_FAILURE:
            return __assign(__assign({}, state), { additionalInformation: null, isGettingAdditionalInformation: false });
        case ActionType.CLEAR_ADDITIONAL_INFORMATION:
            return __assign(__assign({}, state), { additionalInformation: null });
        case ActionType.GET_ADDITIONAL_INFORMATION_SUCCESS:
            return __assign(__assign({}, state), { additionalInformation: action.payload.additionalInformation, isGettingAdditionalInformation: false });
        case ActionType.PERSIST_ADDITIONAL_INFORMATION_SUCCESS:
        case ActionType.PERSIST_ADDITIONAL_INFORMATION_FAILURE:
        case ActionType.PERSIST_COMPLETE_ADDITIONAL_INFORMATION_SUCCESS:
        case ActionType.PERSIST_COMPLETE_ADDITIONAL_INFORMATION_FAILURE:
            return __assign(__assign({}, state), { isPersistingAdditionalInformation: false });
        case ActionType.REQUEST_GET_AUTHORITY_TO_SEARCH:
            return __assign(__assign({}, state), { isGettingAuthorityToSearch: true });
        case ActionType.REQUEST_PERSIST_AUTHORITY_TO_SEARCH:
        case ActionType.REQUEST_PERSIST_COMPLETE_AUTHORITY_TO_SEARCH:
            return __assign(__assign({}, state), { isPersistingAuthorityToSearch: true });
        case ActionType.GET_AUTHORITY_TO_SEARCH_FAILURE:
            return __assign(__assign({}, state), { authorityToSearch: null, isGettingAuthorityToSearch: false });
        case ActionType.CLEAR_AUTHORITY_TO_SEARCH:
            return __assign(__assign({}, state), { authorityToSearch: null });
        case ActionType.GET_AUTHORITY_TO_SEARCH_SUCCESS:
            return __assign(__assign({}, state), { authorityToSearch: action.payload.authorityToSearch, isGettingAuthorityToSearch: false });
        case ActionType.PERSIST_AUTHORITY_TO_SEARCH_SUCCESS:
        case ActionType.PERSIST_AUTHORITY_TO_SEARCH_FAILURE:
        case ActionType.PERSIST_COMPLETE_AUTHORITY_TO_SEARCH_SUCCESS:
        case ActionType.PERSIST_COMPLETE_AUTHORITY_TO_SEARCH_FAILURE:
            return __assign(__assign({}, state), { isPersistingAuthorityToSearch: false });
        case ActionType.REQUEST_GET_SUPPORTING_DOCUMENTS:
            return __assign(__assign({}, state), { isGettingSupportingDocuments: true });
        case ActionType.GET_SUPPORTING_DOCUMENTS_FAILURE:
            return __assign(__assign({}, state), { supportingDocuments: null, isGettingSupportingDocuments: false });
        case ActionType.CLEAR_SUPPORTING_DOCUMENTS:
            return __assign(__assign({}, state), { supportingDocuments: null });
        case ActionType.GET_SUPPORTING_DOCUMENTS_SUCCESS:
            return __assign(__assign({}, state), { supportingDocuments: action.payload.supportingDocuments, isGettingSupportingDocuments: false });
        case ActionType.REQUEST_COMPLETE_SUPPORTING_DOCUMENTS:
        case ActionType.REQUEST_PERSIST_GENERIC_SUPPORTING_DOCUMENT:
        case ActionType.REQUEST_PERSIST_DIRECTOR_ID_DOCUMENT:
        case ActionType.REQUEST_PERSIST_DIRECTOR_ADDRESS_DOCUMENT:
        case ActionType.REQUEST_PERSIST_BANK_STATEMENT_DOCUMENT:
        case ActionType.REQUEST_REMOVE_SUPPORTING_DOCUMENT:
            return __assign(__assign({}, state), { isPersistingSupportingDocuments: true });
        case ActionType.COMPLETE_SUPPORTING_DOCUMENTS_SUCCESS:
        case ActionType.COMPLETE_SUPPORTING_DOCUMENTS_FAILURE:
        case ActionType.REMOVE_SUPPORTING_DOCUMENT_FAILURE:
        case ActionType.REMOVE_SUPPORTING_DOCUMENT_SUCCESS:
        case ActionType.PERSIST_SUPPORTING_DOCUMENT_FAILURE:
        case ActionType.PERSIST_SUPPORTING_DOCUMENT_SUCCESS:
            return __assign(__assign({}, state), { isPersistingSupportingDocuments: false });
        case ActionType.GET_CURRENT_STEP_LABEL_SUCCESS:
            return __assign(__assign({}, state), { currentStepLabel: action.payload.currentStepLabel });
        case ActionType.CLEAR_CURRENT_STEP_LABEL:
            return __assign(__assign({}, state), { currentStepLabel: null });
        case ActionType.REQUEST_SUBMIT_SUBSIDISED_FINANCE_INFORMATION:
            return __assign(__assign({}, state), { isSubmitting: true });
        case ActionType.SUBMIT_SUBSIDISED_FINANCE_INFORMATION_FAILURE:
            return __assign(__assign({}, state), { isSubmitting: false });
        case ActionType.SUBMIT_SUBSIDISED_FINANCE_INFORMATION_SUCCESS:
            return __assign(__assign({}, state), { isSubmitting: false, isSubmitted: true });
        default:
            return state;
    }
};
export default subsidisedFinanceInformationReducer;
