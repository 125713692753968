var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useMemo } from 'react';
import './BasicCreateEditCustomerControl.sass';
import { ValidatedForm } from '@payaca/components';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { BasicField } from '@payaca/components/basicField';
import CustomerAddressInputControl from '../customerAddressInputControl/CustomerAddressInputControl';
import LoqateAdvisoryEmailInputField from '../loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';
import { getFieldValidatorsForCustomer } from '@bit/payaca-tech.payaca-core.helpers.customer-validation';
import LoqateAdvisoryPhoneInputField from '../loqateAdvisoryPhoneInputField/LoqateAdvisoryPhoneInputField';
import { TextareaField } from '@payaca/components/textareaField';
var BasicCreateEditCustomerControl = function (_a) {
    var inputStyleVariant = _a.inputStyleVariant, initialFormState = _a.initialFormState, blockedEmails = _a.blockedEmails, renderActions = _a.renderActions, onFormStateChange = _a.onFormStateChange;
    var fieldValidators = useMemo(function () { return getFieldValidatorsForCustomer(1, blockedEmails); }, [blockedEmails === null || blockedEmails === void 0 ? void 0 : blockedEmails.length]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        var _a, _b, _c, _d;
        onFormStateChange && onFormStateChange(formState);
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "form-section" },
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.name, isTouched: touchedState.name || false },
                    React.createElement(BasicField, { styleVariant: inputStyleVariant, label: 'Name', isRequired: true, value: formState.name || '', name: "name", onChange: function (value) {
                            var _a;
                            onFieldChange(__assign(__assign({}, value), (_a = {}, _a['contacts[0].name'] = value.name, _a)));
                        }, onTouch: onFieldTouch }))),
            React.createElement("div", { className: "form-section" },
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState['contacts[0].description'], isTouched: touchedState['contacts[0].description'] || false },
                    React.createElement(TextareaField, { autoSize: true, styleVariant: inputStyleVariant, rows: 2, label: "Description", value: ((_a = formState.contacts) === null || _a === void 0 ? void 0 : _a.length) ? formState.contacts[0].description
                            : '', name: "contacts[0].description", description: "For internal use only - this description won't be printed on any of your quotes or invoices", onChange: onFieldChange, onTouch: onFieldTouch }))),
            React.createElement("div", { className: "form-section" },
                React.createElement("div", { className: "contact-details-container" },
                    React.createElement("div", { className: "contact-details-input-container" },
                        React.createElement(ValidatedFieldWrapper, { validationResult: validationState['contacts[0].emailAddress'], isTouched: touchedState['contacts[0].emailAddress'] || false },
                            React.createElement(LoqateAdvisoryEmailInputField, { label: 'Email address', value: ((_b = formState.contacts) === null || _b === void 0 ? void 0 : _b.length) ? formState.contacts[0].emailAddress
                                    : '', styleVariant: inputStyleVariant, name: "contacts[0].emailAddress", onChange: onFieldChange, onTouch: onFieldTouch, disableValidation: validationState['contacts[0].emailAddress']
                                    ? !validationState['contacts[0].emailAddress'].isValid
                                    : false })),
                        React.createElement(ValidatedFieldWrapper, { validationResult: validationState['contacts[0].telephoneNumber'], isTouched: touchedState['contacts[0].telephoneNumber'] || false },
                            React.createElement(LoqateAdvisoryPhoneInputField, { label: 'Telephone number', value: ((_c = formState.contacts) === null || _c === void 0 ? void 0 : _c.length) ? formState.contacts[0].telephoneNumber
                                    : '', styleVariant: inputStyleVariant, name: "contacts[0].telephoneNumber", onChange: onFieldChange, onTouch: onFieldTouch, disableValidation: validationState['contacts[0].telephoneNumber']
                                    ? !validationState['contacts[0].telephoneNumber']
                                        .isValid
                                    : false }))))),
            React.createElement("div", { className: "form-section" },
                React.createElement("div", { className: "address-container" },
                    React.createElement(CustomerAddressInputControl, { inputStyleVariant: inputStyleVariant, customerAddress: ((_d = formState.contacts) === null || _d === void 0 ? void 0 : _d.length) ? formState.addresses[0] : {}, fieldNamePrefix: 'addresses[0]', onChange: onFieldChange, onTouch: onFieldTouch, validationState: validationState, touchedState: touchedState, showDescriptionField: false }))),
            renderActions && (React.createElement("div", { className: "actions-container form-section" }, renderActions(isValid, formState)))));
    }, [renderActions]);
    return (React.createElement("div", { className: "basic-create-edit-customer-control" },
        React.createElement(ValidatedForm, { fieldValidators: fieldValidators, initialFormState: initialFormState, renderFormContents: renderFormContents })));
};
export default BasicCreateEditCustomerControl;
