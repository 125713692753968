var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { take, takeEvery, call, put, race, delay } from 'redux-saga/effects';
import { ActionType } from './lineItemsTypes';
import { clearLineItems, getLineItemFailure, getLineItemSuccess, requestGetLineItem, } from './lineItemsActions';
import { Req } from '@payaca/helpers/storeHelper';
import { refreshAuthToken } from '../auth/authSaga';
export function getLineItem(lineItemId) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, put(requestGetLineItem(lineItemId))];
            case 1:
                _a.sent();
                return [4 /*yield*/, take([
                        ActionType.GET_LINE_ITEM_SUCCESS,
                        ActionType.GET_LINE_ITEM_FAILURE,
                    ])];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
var lineItemsSagaCreator = function (_a) {
    var apiBaseurl = _a.apiBaseurl, getAuthHeader = _a.getAuthHeader, isNativeApp = _a.isNativeApp;
    var req = Req(apiBaseurl, getAuthHeader, isNativeApp);
    // GET LINE ITEM
    function handleGetLineItem(action) {
        var _a, response, timeout, errorMessage, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(getLineItem, action.payload.lineItemId),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Get line item timed out.';
                    return [4 /*yield*/, put(getLineItemFailure(new Error(errorMessage), errorMessage))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 7];
                case 5:
                    action.payload.callback && action.payload.callback(response);
                    return [4 /*yield*/, put(getLineItemSuccess(response))];
                case 6:
                    _b.sent();
                    _b.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_1 = _b.sent();
                    return [4 /*yield*/, put(getLineItemFailure(error_1, error_1.message))];
                case 9:
                    _b.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleGetLineItemsForLineItemGroup(action) {
        var _a, response, timeout, _i, response_1, lineItem, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 9]);
                    return [4 /*yield*/, race({
                            response: call(getLineItemsForLineItemGroup, action.payload.lineItemGroupId),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!!timeout) return [3 /*break*/, 7];
                    _i = 0, response_1 = response;
                    _b.label = 4;
                case 4:
                    if (!(_i < response_1.length)) return [3 /*break*/, 7];
                    lineItem = response_1[_i];
                    return [4 /*yield*/, put(getLineItemSuccess(lineItem))];
                case 5:
                    _b.sent();
                    _b.label = 6;
                case 6:
                    _i++;
                    return [3 /*break*/, 4];
                case 7: return [3 /*break*/, 9];
                case 8:
                    error_2 = _b.sent();
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }
    var getLineItemsForLineItemGroup = function (lineItemGroupId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/line_items/line_item_group/" + lineItemGroupId, {
                            method: 'GET',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/, response.json()];
                                }
                                else {
                                    throw new Error("getLineItemsForLineItemGroup failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    // LOGOUT
    function handleAppLogout() {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, put(clearLineItems())];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    var getLineItem = function (lineItemId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, req.get("/line_item/" + lineItemId)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    return function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, takeEvery(ActionType.REQUEST_GET_LINE_ITEM, handleGetLineItem)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(ActionType.REQUEST_GET_LINE_ITEMS_FOR_LINE_ITEM_GROUP, handleGetLineItemsForLineItemGroup)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, takeEvery('auth.logout', handleAppLogout)];
                case 3:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    };
};
export default lineItemsSagaCreator;
