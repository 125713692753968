import { actions as authActions } from '@bit/payaca-tech.payaca-core.store.auth';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PayacaPhoneLink from '@bit/payaca-tech.payaca-core.component.payaca-phone-link';
import './VerifyUserHoldingPage.sass';
import UnauthenticatedPageWrapper from '../pageWrappers/unauthenticatedPageWrapper/UnauthenticatedPageWrapper';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { Lock } from 'react-iconly';
import ResendVerificationEmailControl from '@/ui/components/resendVerificationEmailControl/ResendVerificationEmailControl';
var VerifyUserHoldingPage = function () {
    var dispatch = useDispatch();
    var userEmail = useSelector(function (state) {
        var _a, _b;
        return (_b = (_a = state === null || state === void 0 ? void 0 : state.users) === null || _a === void 0 ? void 0 : _a.myProfile) === null || _b === void 0 ? void 0 : _b.email;
    });
    var logout = useCallback(function () {
        dispatch(authActions.logout());
    }, [dispatch]);
    // TODO: this
    return (React.createElement(UnauthenticatedPageWrapper, { headerContent: React.createElement("div", { className: "flex-container" },
            React.createElement("div", { className: "flex-grow" }),
            React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: logout }, "Log out")), className: "verify-user-holding-page" },
        React.createElement("div", { className: "main-content" },
            React.createElement("div", null,
                React.createElement(Lock, { size: "xlarge", set: "light" }),
                React.createElement("h2", null, "Verify it's you"),
                React.createElement("p", null,
                    "We have sent an email to ",
                    userEmail,
                    ". Please open the verification link in the email to log in.")),
            React.createElement("div", null,
                React.createElement("p", null, "Didn't recieve the email?"),
                React.createElement(ResendVerificationEmailControl, { userEmailAddress: userEmail }))),
        React.createElement("div", { className: "footer-content" },
            React.createElement("p", null, "Didn't get the email, even in spam?"),
            React.createElement(PayacaPhoneLink, null))));
};
export default VerifyUserHoldingPage;
