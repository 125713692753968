var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import isEqual from 'lodash.isequal';
import { FileSizeLimit, FileType, FileUploadType, getFileTypeIcon, getMbFileLimit, } from '@bit/payaca-tech.payaca-core.helpers.file';
var getUpdatedFiles = function (file, files, remove) {
    var updatedFiles = __spreadArrays(files);
    var existingFileIndex = files.findIndex(function (f) {
        return file.termUrl ? f.termUrl === file.termUrl : isEqual(f.file, file.file);
    });
    if (remove) {
        updatedFiles.splice(existingFileIndex, 1);
    }
    else {
        existingFileIndex > -1
            ? updatedFiles.splice(existingFileIndex, 1, file)
            : updatedFiles.push(file);
    }
    return updatedFiles;
};
export { FileSizeLimit, FileType, FileUploadType, getFileTypeIcon, getMbFileLimit, getUpdatedFiles, };
