import React from 'react';
import SortDirectionControl from '../sortDirectionControl/SortDirectionControl';
import './SortableColumnHeaderTableCell.sass';
var SortableColumnHeaderTableCell = function (_a) {
    var className = _a.className, _b = _a.align, align = _b === void 0 ? 'left' : _b, columnName = _a.columnName, selectedSortDirection = _a.selectedSortDirection, onChangeSortDirection = _a.onChangeSortDirection;
    return (React.createElement("th", { className: "sortable-column-header-table-cell " + className },
        React.createElement("div", { className: "sortable-column-header-inner", style: { textAlign: align } },
            React.createElement("span", null, columnName),
            React.createElement(SortDirectionControl, { selectedSortDirection: selectedSortDirection, onChange: onChangeSortDirection }))));
};
export default SortableColumnHeaderTableCell;
