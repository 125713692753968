import { getRegexMatchFieldValidator } from './fieldValidationHelper';
export var minimumLengthValidationMessage = 'Password be at least 6 characters';
export var mustContainLetterValidationMessage = 'Password must contain 1 letter';
export var mustContainNumberValidationMessage = 'Password must contain 1 number';
export var minimumLengthFieldValidator = getRegexMatchFieldValidator(/^.{6,}$/, {
    customErrorMessage: minimumLengthValidationMessage,
});
export var mustContainLetterFieldValidator = getRegexMatchFieldValidator(/[a-zA-Z]/, {
    customErrorMessage: mustContainLetterValidationMessage,
});
export var mustContainNumberFieldValidator = getRegexMatchFieldValidator(/\d/, {
    customErrorMessage: mustContainNumberValidationMessage,
});
