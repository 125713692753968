var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as customerActions } from '@bit/payaca-tech.payaca-core.store.customer';
import { actions as usersActions } from '@/api/users';
import './CreateEditCustomerControl.css';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import BasicCreateEditCustomerControl from '../basicCreateEditCustomerControl/BasicCreateEditCustomerControl';
import AdvancedCreateEditCustomerControl from '../advancedCreateEditCustomerControl/AdvancedCreateEditCustomerControl';
import ConfirmModal from '../modal/ConfirmModal';
import { getModal } from '@/helpers/modalHelper';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
var CreateEditCustomerControl = function (_a) {
    var overrideInitialFormState = _a.overrideInitialFormState, customer = _a.customer, onPersistCustomer = _a.onPersistCustomer, inputStyleVariant = _a.inputStyleVariant;
    var dispatch = useDispatch();
    var _b = useState(false), showAdvancedControl = _b[0], setShowAdvancedControl = _b[1];
    var _c = useState(), modal = _c[0], setModal = _c[1];
    var _d = useState([]), blockedEmails = _d[0], setBlockedEmails = _d[1];
    var _e = useState(false), isSubmitted = _e[0], setIsSubmitted = _e[1];
    var isPersistingCustomer = useSelector(function (state) { return state.customer.isPersistingCustomer; });
    var accountId = useSelector(function (state) { return state.users.myProfile.accounts[0].id; });
    var _f = useState({}), formState = _f[0], setFormState = _f[1];
    var _g = useState({}), alteredFormState = _g[0], setAlteredFormState = _g[1];
    useEffect(function () {
        var isMounted = true;
        dispatch(usersActions.getBlockedEmails(function (err, blockedEmails) {
            if (blockedEmails && isMounted) {
                setBlockedEmails(blockedEmails);
            }
        }));
        return function () {
            isMounted = false;
        };
    }, []);
    var initialFormState = useMemo(function () {
        var ifs = {
            id: customer === null || customer === void 0 ? void 0 : customer.id,
            accountId: accountId,
            name: customer === null || customer === void 0 ? void 0 : customer.name,
            addresses: (customer === null || customer === void 0 ? void 0 : customer.addresses) ? __spreadArrays(customer.addresses) : [],
            contacts: (customer === null || customer === void 0 ? void 0 : customer.contacts) ? __spreadArrays(customer.contacts) : [{ isPrimaryContact: true }],
        };
        if (!overrideInitialFormState)
            return ifs;
        return __assign(__assign({}, ifs), overrideInitialFormState);
    }, [customer, accountId, overrideInitialFormState]);
    var canShowBasicControl = useMemo(function () {
        var _a, _b, _c;
        return (!customer ||
            !(((_a = customer === null || customer === void 0 ? void 0 : customer.contacts) === null || _a === void 0 ? void 0 : _a.length) > 1 ||
                ((_b = customer === null || customer === void 0 ? void 0 : customer.addresses) === null || _b === void 0 ? void 0 : _b.length) > 1 ||
                (((_c = customer === null || customer === void 0 ? void 0 : customer.contacts) === null || _c === void 0 ? void 0 : _c.length) > 0 &&
                    (customer === null || customer === void 0 ? void 0 : customer.contacts[0].name) !== (customer === null || customer === void 0 ? void 0 : customer.name))));
    }, [customer]);
    useEffect(function () {
        if (!canShowBasicControl) {
            setShowAdvancedControl(true);
        }
    }, [canShowBasicControl]);
    var persistCustomer = useCallback(function (persistCustomerData) {
        setIsSubmitted(true);
        dispatch(customerActions.requestPersistCustomer(persistCustomerData, onPersistCustomer));
    }, [dispatch, onPersistCustomer]);
    var shouldShowMissingEmailAlert = useCallback(function (persistCustomerData) {
        if (!persistCustomerData.contacts.length)
            return true;
        var primaryContact = persistCustomerData.contacts.find(function (x) { return x.isPrimaryContact; });
        return !(primaryContact === null || primaryContact === void 0 ? void 0 : primaryContact.emailAddress);
    }, []);
    var checkMissingEmailAndContinue = useCallback(function (persistCustomerData, onContinue) {
        if (shouldShowMissingEmailAlert(persistCustomerData)) {
            setModal(React.createElement(ConfirmModal, __assign({}, getModal('SAVE_CUSTOMER_WITHOUT_EMAIL'), { onClose: function () { return setModal(undefined); }, primaryAction: function () { return setModal(undefined); }, secondaryAction: function () {
                    setModal(undefined);
                    onContinue();
                }, open: true })));
        }
        else {
            onContinue();
        }
    }, []);
    var checkUpdateCustomerAndContinue = useCallback(function (persistCustomerData, onContinue) {
        if (persistCustomerData.id) {
            setModal(React.createElement(ConfirmModal, __assign({}, getModal('UPDATE_CUSTOMER'), { onClose: function () { return setModal(undefined); }, secondaryAction: function () { return setModal(undefined); }, primaryAction: function () {
                    setModal(undefined);
                    onContinue();
                }, open: true })));
        }
        else {
            onContinue();
        }
    }, []);
    var onSubmit = useCallback(function (persistCustomerData) {
        checkMissingEmailAndContinue(persistCustomerData, function () {
            checkUpdateCustomerAndContinue(persistCustomerData, function () {
                persistCustomer(persistCustomerData);
            });
        });
    }, [
        persistCustomer,
        checkMissingEmailAndContinue,
        checkUpdateCustomerAndContinue,
    ]);
    var onFormStateChange = useCallback(function (formState) {
        setFormState(formState);
    }, []);
    var renderActions = useCallback(function (isValid, formState) {
        return (React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, isDisabled: !isValid, isProcessing: isPersistingCustomer, onClick: function () {
                return !isPersistingCustomer && onSubmit(formState);
            } }, "Save"));
    }, [onSubmit, isPersistingCustomer]);
    var getCreateEditCustomerControl = useCallback(function () {
        var ifs = __assign(__assign({}, initialFormState), alteredFormState);
        if (showAdvancedControl) {
            return (React.createElement(AdvancedCreateEditCustomerControl, { initialFormState: ifs, blockedEmails: blockedEmails, renderActions: renderActions, onFormStateChange: onFormStateChange, inputStyleVariant: inputStyleVariant }));
        }
        // if switching to the basic control, don't allow multiple contacts or addresses.
        ifs.contacts = ifs.contacts.length ? [ifs.contacts[0]] : [];
        ifs.addresses = ifs.addresses.length ? [ifs.addresses[0]] : [];
        return (React.createElement(BasicCreateEditCustomerControl, { initialFormState: ifs, blockedEmails: blockedEmails, renderActions: renderActions, onFormStateChange: onFormStateChange, inputStyleVariant: inputStyleVariant }));
    }, [
        inputStyleVariant,
        showAdvancedControl,
        blockedEmails.length,
        initialFormState,
        renderActions,
        onFormStateChange,
        alteredFormState,
    ]);
    var _h = useState(), createEditCustomerControl = _h[0], setCreateEditCustomerControl = _h[1];
    useEffect(function () {
        setCreateEditCustomerControl(getCreateEditCustomerControl());
    }, [showAdvancedControl, customer]);
    return (React.createElement("div", { className: "create-edit-customer-control" },
        canShowBasicControl && (React.createElement("div", { className: "control-type-actions-container" },
            !showAdvancedControl && (React.createElement(Button, { onClick: function () {
                    setAlteredFormState(formState);
                    setShowAdvancedControl(true);
                }, styleVariant: ButtonStyleVariant.ANCHOR, iconAfter: faChevronRight }, "Go to advanced edit")),
            showAdvancedControl && (React.createElement(Button, { onClick: function () {
                    setAlteredFormState(formState);
                    setShowAdvancedControl(false);
                }, styleVariant: ButtonStyleVariant.ANCHOR, iconAfter: faChevronRight }, "Go to basic edit")))),
        createEditCustomerControl,
        modal));
};
export default CreateEditCustomerControl;
