import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import qs from 'qs';
import { actions as appActions } from '@/api/app';
import { actions as userActions } from '@/api/users';
import './VerifyUserPage.sass';
import { ShieldDone } from 'react-iconly';
import UnauthenticatedPageWrapper from '../pageWrappers/unauthenticatedPageWrapper/UnauthenticatedPageWrapper';
import ResendVerificationEmailControl from '@/ui/components/resendVerificationEmailControl/ResendVerificationEmailControl';
import MiniLoader from '@bit/payaca-tech.payaca-core.component.mini-loader';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
var PageState;
(function (PageState) {
    PageState["VERIFYING"] = "verifying";
    PageState["VERIFY_SUCCESS"] = "verify-success";
    PageState["VERIFY_FAILURE"] = "verify-failure";
})(PageState || (PageState = {}));
var VerifyUserPage = function (_a) {
    var match = _a.match, location = _a.location, history = _a.history;
    var dispatch = useDispatch();
    var _b = useState(false), linkToNativeApp = _b[0], setLinkToNativeApp = _b[1];
    var _c = useState(PageState.VERIFYING), pageState = _c[0], setPageState = _c[1];
    useEffect(function () {
        var token = match.params.token;
        var query = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });
        setLinkToNativeApp(query.linkToNativeApp === 'true');
        dispatch(appActions.verifyAccountToken(token, function (err) {
            if (err) {
                setPageState(PageState.VERIFY_FAILURE);
            }
            else {
                dispatch(userActions.getProfile(function () {
                    setPageState(PageState.VERIFY_SUCCESS);
                }));
            }
        }));
    }, []);
    return (React.createElement(UnauthenticatedPageWrapper, { className: "verify-user-page" },
        pageState === PageState.VERIFYING && (React.createElement("div", { className: "loader-container" },
            React.createElement(MiniLoader, null))),
        pageState === PageState.VERIFY_FAILURE && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "title-introduction-container" },
                React.createElement("h2", null, "Invalid verification link"),
                React.createElement("p", null, "Enter your email to resend a new verification link.")),
            React.createElement("div", null,
                React.createElement(ResendVerificationEmailControl, { allowUserEmailAddressInput: true })))),
        pageState === PageState.VERIFY_SUCCESS && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "title-introduction-container" },
                React.createElement(ShieldDone, { size: "xlarge", set: "light" }),
                React.createElement("h2", null, "Your account has been verified!"),
                React.createElement("p", null, "You can now log in and start using payaca")),
            React.createElement("div", null,
                linkToNativeApp && (React.createElement("a", { href: 'Payaca://', className: "mobile-app-link" },
                    React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, hasBoxShadow: true }, "Continue"))),
                !linkToNativeApp && (React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, hasBoxShadow: true, onClick: function () { return history.push('/deals'); } }, "Continue")))))));
};
export default VerifyUserPage;
