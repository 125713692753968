import React from 'react';
import { Modal } from '@payaca/components/modal';
import './WatchDemoModal.sass';
var WatchDemoModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose;
    return (React.createElement(Modal, { isOpen: isOpen, size: "md", onClose: onClose, className: "watch-demo-modal" },
        React.createElement("div", { className: "video-container" },
            React.createElement("div", { className: "video-iframe-wrapper" },
                React.createElement("iframe", { src: "https://player.vimeo.com/video/560836621?autoplay=1", className: "video-iframe", allow: "autoplay; fullscreen" })))));
};
export default WatchDemoModal;
