import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import './ForgotPasswordPage.sass';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import UnauthenticatedPageWrapper from '../pageWrappers/unauthenticatedPageWrapper/UnauthenticatedPageWrapper';
import ForgotPasswordForm from '@/ui/components/forgotPasswordForm/ForgotPasswordForm';
var ForgotPasswordPage = function () {
    var history = useHistory();
    var goToRegisterPage = useCallback(function () {
        history.push('/register');
    }, [history]);
    var goToLoginPage = useCallback(function () {
        history.push('/login');
    }, [history]);
    return (React.createElement(UnauthenticatedPageWrapper, { headerContent: React.createElement("div", { className: "flex-container" },
            React.createElement(Button, { iconBefore: faChevronLeft, styleVariant: ButtonStyleVariant.ANCHOR, onClick: goToLoginPage }, "Log in"),
            React.createElement("div", { className: "flex-grow" }),
            React.createElement("span", { className: "sign-up-prompt" },
                "No account?",
                ' ',
                React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: goToRegisterPage }, "Sign up"))), className: "forgot-password-page" },
        React.createElement("div", { className: "title-introduction-container" },
            React.createElement("h2", null, "Forgot your password?"),
            React.createElement("p", null, "Enter your email address and we\u2019ll send you the link for resetting your password.")),
        React.createElement(ForgotPasswordForm, null)));
};
export default ForgotPasswordPage;
