import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { SortDirection } from '@payaca/types/listViewTypes';
import './SortDirectionControl.sass';
var SortDirectionControl = function (_a) {
    var selectedSortDirection = _a.selectedSortDirection, onChange = _a.onChange;
    return (React.createElement("div", { className: "sort-direction-control" },
        React.createElement(FontAwesomeIcon, { icon: faCaretUp, className: selectedSortDirection === SortDirection.ASCENDING ? 'active' : '', onClick: function () {
                onChange(SortDirection.ASCENDING);
            } }),
        React.createElement(FontAwesomeIcon, { icon: faCaretDown, className: selectedSortDirection === SortDirection.DESCENDING ? 'active' : '', onClick: function () {
                onChange(SortDirection.DESCENDING);
            } })));
};
export default SortDirectionControl;
