import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './PayacaPhoneLink.css';
var PayacaPhoneLink = function (_a) {
    var _b = _a.showPhoneIcon, showPhoneIcon = _b === void 0 ? false : _b, anchorContent = _a.anchorContent;
    return (React.createElement("a", { className: "payaca-phone-link link", href: "tel:+443330509475", title: "0333 050 9475" },
        showPhoneIcon && React.createElement(FontAwesomeIcon, { icon: faPhoneAlt }),
        anchorContent ? anchorContent : React.createElement("span", null, "0333 050 9475")));
};
export default PayacaPhoneLink;
