import React, { useMemo } from "react";
import "./SatisfactionNoteDeclaration.sass";
var SatisfactionNoteDeclaration = function (_a) {
    var agreementDuration = _a.agreementDuration, companyName = _a.companyName, lenderName = _a.lenderName;
    var show72HourAssumeSatisfiedStatement = useMemo(function () { return lenderName === "SHERMIN-DUOLOGI"; }, [lenderName]);
    return (React.createElement("div", { className: "satisfaction-note-declaration" },
        React.createElement("h3", null,
            "This confirmation activates your",
            " ",
            React.createElement("u", null,
                agreementDuration,
                " month loan agreement"),
            "."),
        show72HourAssumeSatisfiedStatement && (React.createElement("p", null, "Please note, you must respond by choosing one of the options below within 72 hours otherwise we assume you are satisfied and will go ahead and trigger payout.")),
        React.createElement("p", null, "By signing below you agree that:"),
        React.createElement("ol", null,
            React.createElement("li", null,
                "The goods/services ",
                React.createElement("strong", null, "were provided"),
                " in accordance with the original sales contract/quote provided by ",
                companyName,
                " shown above."),
            React.createElement("li", null,
                "You are ",
                React.createElement("strong", null, "satisfied"),
                " with the goods/services provided."),
            React.createElement("li", null,
                "You ",
                React.createElement("strong", null, "fully understand"),
                " the terms of your",
                " ",
                agreementDuration,
                "-month loan agreement and the monthly payments you are required to make to the lender."),
            React.createElement("li", null, "Your payments will be taken monthly via Direct Debit Mandate from your nominated bank account."),
            React.createElement("li", null,
                "You confirm that these monthly payments are affordable and you are not aware of any circumstances that would affect your ability to repay this loan and also understand the",
                " ",
                React.createElement("strong", null, "implications of missing payments"),
                " and the effect this may have on your credit file."))));
};
export default SatisfactionNoteDeclaration;
