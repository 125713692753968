import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

export const AssumeToken = () => {
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    if (!search) return;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const refresh = params.get('refresh');
    if (!token || !refresh) return;
    localStorage.setItem('payacav1', token);
    localStorage.setItem('payacav1-refreshToken', refresh);
    history.push('/deals');
  }, [search]);

  return <></>;
};
