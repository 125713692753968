import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPercent, faPoundSign, faTimes, } from '@fortawesome/free-solid-svg-icons';
import { BasicField } from '@payaca/components/basicField';
import { TitledContentPanel } from '@payaca/components/titledContentPanel';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { Checkbox } from '@payaca/components/checkbox';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import './ProductFormSubsection.css';
var ProductFormSubSection = function (_a) {
    var _b, _c, _d;
    var title = _a.title, product = _a.product, _e = _a.isSoleTrader, isSoleTrader = _e === void 0 ? false : _e, fieldNamePrefix = _a.fieldNamePrefix, touchedState = _a.touchedState, validationState = _a.validationState, onTouch = _a.onTouch, onChange = _a.onChange, onRemove = _a.onRemove;
    var descriptionFieldName = useMemo(function () {
        return fieldNamePrefix ? fieldNamePrefix + ".description" : 'description';
    }, [fieldNamePrefix]);
    var averageOrderValueFieldName = useMemo(function () {
        return fieldNamePrefix
            ? fieldNamePrefix + ".averageOrderValue"
            : 'averageOrderValue';
    }, [fieldNamePrefix]);
    var totalSalesTurnoverPercentageFieldName = useMemo(function () {
        return fieldNamePrefix
            ? fieldNamePrefix + ".totalSalesTurnoverPercentage"
            : 'totalSalesTurnoverPercentage';
    }, [fieldNamePrefix]);
    var guaranteeOfferMonthsFieldName = useMemo(function () {
        return fieldNamePrefix
            ? fieldNamePrefix + ".guaranteeOfferMonths"
            : 'guaranteeOfferMonths';
    }, [fieldNamePrefix]);
    var isInsuranceBackedFieldName = useMemo(function () {
        return fieldNamePrefix
            ? fieldNamePrefix + ".isInsuranceBacked"
            : 'isInsuranceBacked';
    }, [fieldNamePrefix]);
    var insuranceBackedByFieldName = useMemo(function () {
        return fieldNamePrefix
            ? fieldNamePrefix + ".insuranceBackedBy"
            : 'insuranceBackedBy';
    }, [fieldNamePrefix]);
    var titleElement = useMemo(function () {
        return (React.createElement("div", { className: "flex-container flex-center" },
            React.createElement("span", { className: "flex-grow" }, title),
            onRemove && React.createElement(FontAwesomeIcon, { icon: faTimes, onClick: onRemove })));
    }, [title, onRemove]);
    return (React.createElement(TitledContentPanel, { className: "product-form-subsection", hasBoxShadow: true, title: titleElement },
        React.createElement(React.Fragment, null,
            React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[descriptionFieldName]) || false, validationResult: validationState && validationState[descriptionFieldName] },
                React.createElement(BasicField, { label: "Description", isRequired: true, value: product.description || '', name: descriptionFieldName, onChange: onChange, onTouch: onTouch, styleVariant: InputStyleVariant.OUTSIZE })),
            React.createElement("div", { className: "flex-container" },
                React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[averageOrderValueFieldName]) ||
                        false, validationResult: validationState && validationState[averageOrderValueFieldName] },
                    React.createElement(BasicField, { label: "Average order value", isRequired: true, value: (_b = product.averageOrderValue) !== null && _b !== void 0 ? _b : '', name: averageOrderValueFieldName, type: "number", onChange: onChange, onTouch: onTouch, iconBefore: faPoundSign, styleVariant: InputStyleVariant.OUTSIZE })),
                React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState &&
                        touchedState[totalSalesTurnoverPercentageFieldName]) ||
                        false, validationResult: validationState &&
                        validationState[totalSalesTurnoverPercentageFieldName] },
                    React.createElement(BasicField, { label: "% of total sales turnover", isRequired: true, value: (_c = product.totalSalesTurnoverPercentage) !== null && _c !== void 0 ? _c : '', name: totalSalesTurnoverPercentageFieldName, type: "number", onChange: onChange, onTouch: onTouch, iconBefore: faPercent, styleVariant: InputStyleVariant.OUTSIZE })),
                React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[guaranteeOfferMonthsFieldName]) ||
                        false, validationResult: validationState && validationState[guaranteeOfferMonthsFieldName] },
                    React.createElement(BasicField, { label: "Guarantee offer (months)", isRequired: true, value: (_d = product.guaranteeOfferMonths) !== null && _d !== void 0 ? _d : '', name: guaranteeOfferMonthsFieldName, type: "number", onChange: onChange, onTouch: onTouch, styleVariant: InputStyleVariant.OUTSIZE }))),
            React.createElement("div", { className: "checkbox-container flex-container flex-center" },
                React.createElement("div", null,
                    React.createElement(Checkbox, { isChecked: product.isInsuranceBacked || false, onChange: function () {
                            var _a;
                            onChange((_a = {},
                                _a[isInsuranceBackedFieldName] = !product.isInsuranceBacked,
                                _a));
                            onTouch && onTouch(isInsuranceBackedFieldName);
                        } })),
                React.createElement("span", null, "Is this product insurance backed?")),
            product.isInsuranceBacked && (React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[insuranceBackedByFieldName]) ||
                    false, validationResult: validationState && validationState[insuranceBackedByFieldName] },
                React.createElement(BasicField, { label: "Insurance backed guarantee provider", isRequired: true, value: product.insuranceBackedBy || '', name: insuranceBackedByFieldName, onChange: onChange, onTouch: onTouch, styleVariant: InputStyleVariant.OUTSIZE }))))));
};
export default ProductFormSubSection;
