var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './RegisterForm.sass';
import { ValidatedForm } from '@payaca/components';
import { getEmailFieldValidator, getIsRequiredFieldValidator, getPhoneNumberFieldValidator, } from '@payaca/helpers/fieldValidationHelper';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import LoqateAdvisoryEmailInputField from '../loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { CollapsiblePanel, CollapsiblePanelStyleVariant, } from '@payaca/components/collapsiblePanel';
import { BasicField } from '@payaca/components/basicField';
import LoqateAdvisoryPhoneInputField from '../loqateAdvisoryPhoneInputField/LoqateAdvisoryPhoneInputField';
import { PrivateField } from '@payaca/components/privateField';
import PasswordValidationFeedback from '../passwordValidationFeedback/PasswordValidationFeedback';
import { minimumLengthFieldValidator, mustContainLetterFieldValidator, mustContainNumberFieldValidator, } from '@bit/payaca-tech.payaca-core.helpers.password-validation';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import { actions as appActions } from '@/api/app';
import { useDispatch } from 'react-redux';
import { getFormServiceError } from '@/helpers/formHelper';
import { ErrorMessage } from '@payaca/components/feedbackMessage';
import { CheckboxField } from '@payaca/components/checkboxField';
var RegisterForm = function (_a) {
    var initialFormState = _a.initialFormState, onRegisterCallback = _a.onRegisterCallback;
    var dispatch = useDispatch();
    var _b = useState(), registerErrorMessage = _b[0], setRegisterErrorMessage = _b[1];
    var _c = useState(false), isProcessing = _c[0], setIsProcessing = _c[1];
    var _d = useState(false), showReferralTokenInput = _d[0], setShowreferralTokenInput = _d[1];
    var _e = useState(false), showPasswordValidationFeedback = _e[0], setShowPasswordValidationFeedback = _e[1];
    var fieldValidators = useMemo(function () {
        var isRequiredFieldValidator = getIsRequiredFieldValidator();
        return {
            firstName: [isRequiredFieldValidator],
            lastName: [isRequiredFieldValidator],
            emailAddress: [isRequiredFieldValidator, getEmailFieldValidator()],
            phoneNumber: [isRequiredFieldValidator, getPhoneNumberFieldValidator()],
            password: [
                isRequiredFieldValidator,
                minimumLengthFieldValidator,
                mustContainLetterFieldValidator,
                mustContainNumberFieldValidator,
            ],
        };
    }, []);
    useEffect(function () {
        if (initialFormState === null || initialFormState === void 0 ? void 0 : initialFormState.referralToken)
            setShowreferralTokenInput(true);
    }, [initialFormState === null || initialFormState === void 0 ? void 0 : initialFormState.referralToken]);
    var onSubmit = useCallback(function (formState) {
        setIsProcessing(true);
        setRegisterErrorMessage(undefined);
        var registerPayload = {
            firstName: formState.firstName,
            lastName: formState.lastName,
            email: formState.emailAddress,
            password: formState.password,
            contactNumber: formState.phoneNumber,
            referralToken: formState.referralToken,
            hasSubscribedToNewsletter: formState.hasSubscribedToNewsletter,
            hasAgreedToTermsAndConditions: true,
        };
        dispatch(appActions.signUp(registerPayload, function (error) {
            setIsProcessing(false);
            if (error) {
                setRegisterErrorMessage(getFormServiceError('register', error));
            }
            onRegisterCallback && onRegisterCallback(error);
        }));
    }, [dispatch]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ValidatedFieldWrapper, { validationResult: validationState['firstName'], isTouched: touchedState['firstName'] || false },
                React.createElement(BasicField, { styleVariant: InputStyleVariant.OUTSIZE, isRequired: true, label: "First name", name: "firstName", value: formState.firstName, onTouch: onFieldTouch, onChange: onFieldChange })),
            React.createElement(ValidatedFieldWrapper, { validationResult: validationState['lastName'], isTouched: touchedState['lastName'] || false },
                React.createElement(BasicField, { styleVariant: InputStyleVariant.OUTSIZE, isRequired: true, label: "Last name", name: "lastName", value: formState.lastName, onTouch: onFieldTouch, onChange: onFieldChange })),
            React.createElement(ValidatedFieldWrapper, { validationResult: validationState['emailAddress'], isTouched: touchedState['emailAddress'] || false },
                React.createElement(LoqateAdvisoryEmailInputField, { styleVariant: InputStyleVariant.OUTSIZE, isRequired: true, label: "Email", name: "emailAddress", value: formState.emailAddress, onTouch: onFieldTouch, onChange: onFieldChange, disableValidation: validationState['emailAddress']
                        ? !validationState['emailAddress'].isValid
                        : false })),
            React.createElement(ValidatedFieldWrapper, { validationResult: validationState['phoneNumber'], isTouched: touchedState['phoneNumber'] || false },
                React.createElement(LoqateAdvisoryPhoneInputField, { styleVariant: InputStyleVariant.OUTSIZE, isRequired: true, label: "Phone number", name: "phoneNumber", value: formState.phoneNumber, onTouch: onFieldTouch, onChange: onFieldChange, disableValidation: validationState['phoneNumber']
                        ? !validationState['phoneNumber'].isValid
                        : false })),
            React.createElement(ValidatedFieldWrapper, { validationResult: __assign(__assign({}, validationState['password']), { errors: [] }), isTouched: touchedState['password'] || false },
                React.createElement(PrivateField, { styleVariant: InputStyleVariant.OUTSIZE, name: "password", value: formState.password, label: 'Create password', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, additionalInputProps: {
                        onFocus: function () { return setShowPasswordValidationFeedback(true); },
                        onBlur: function () {
                            var _a;
                            onFieldTouch('password');
                            ((_a = validationState['password']) === null || _a === void 0 ? void 0 : _a.isValid) &&
                                setShowPasswordValidationFeedback(false);
                        },
                    } }),
                React.createElement(CollapsiblePanel, { isOpen: showPasswordValidationFeedback, styleVariant: CollapsiblePanelStyleVariant.UNSTYLED, shouldRenderHeader: false },
                    React.createElement(PasswordValidationFeedback, { password: formState.password }))),
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: "marketing-and-terms" },
                    React.createElement(CheckboxField, { name: "hasSubscribedToNewsletter", value: formState.hasSubscribedToNewsletter, onChange: function (value) {
                            onFieldChange({
                                hasSubscribedToNewsletter: value.hasSubscribedToNewsletter,
                            });
                        }, label: "I am happy to receive marketing emails from Payaca" }),
                    React.createElement("p", null,
                        "* by creating your account you are agreeing to Payaca's",
                        ' ',
                        React.createElement("a", { href: process.env.REACT_APP_MARKETING_SITE_URL + "/terms-and-conditions", target: "_blank", rel: "noreferrer" }, 'terms & conditions'),
                        ' ',
                        "and",
                        ' ',
                        React.createElement("a", { href: process.env.REACT_APP_MARKETING_SITE_URL + "/privacy-policy", target: "_blank", rel: "noreferrer" }, "privacy policy"))),
                React.createElement("div", null,
                    React.createElement(Button, { isProcessing: isProcessing, onClick: function () { return !isProcessing && onSubmit(formState); }, styleVariant: ButtonStyleVariant.OUTSIZE, isDisabled: !isValid }, "Create account"),
                    registerErrorMessage && (React.createElement(ErrorMessage, { message: registerErrorMessage }))),
                React.createElement("div", { className: "referral-token-control" },
                    React.createElement(CollapsiblePanel, { isOpen: showReferralTokenInput, title: showReferralTokenInput ? ('Referral code') : (React.createElement("div", { className: "enter-referral-code-prompt" },
                            React.createElement("span", null, "Got a referral code? "),
                            React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR }, "Click here"))), onOpen: function () { return setShowreferralTokenInput(true); }, onClose: function () { return null; }, styleVariant: CollapsiblePanelStyleVariant.UNSTYLED },
                        React.createElement(BasicField, { styleVariant: InputStyleVariant.OUTSIZE, name: "referralToken", value: formState.referralToken, onTouch: onFieldTouch, onChange: onFieldChange }))))));
    }, [
        showReferralTokenInput,
        showPasswordValidationFeedback,
        isProcessing,
        registerErrorMessage,
        onSubmit,
    ]);
    var memoizedInitialFormState = useMemo(function () {
        return __assign({ password: '' }, initialFormState);
    }, [initialFormState]);
    return (React.createElement("div", { className: "register-form-container" },
        React.createElement(ValidatedForm, { renderFormContents: renderFormContents, fieldValidators: fieldValidators, initialFormState: memoizedInitialFormState })));
};
export default RegisterForm;
