import React from 'react';
import PageWrapper from '../pageWrapper/PageWrapper';
import './BasicPageWrapper.sass';
var BasicPageWrapper = function (_a) {
    var className = _a.className, children = _a.children;
    return (React.createElement(PageWrapper, { className: "basic-page-wrapper " + (className ? className : '') },
        React.createElement("div", { className: "page-content" },
            React.createElement("div", { className: "header-content" },
                React.createElement("img", { className: "payaca-logo", src: "https://storage.googleapis.com/payaca-prod-assets/payaca-logo.png" })),
            React.createElement("div", { className: "body-content" }, children))));
};
export default BasicPageWrapper;
