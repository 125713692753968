import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as analyticsActions } from '@bit/payaca-tech.payaca-core.store.analytics';
import './SubscriptionUsageIndicator.sass';
import MiniLoader from '@bit/payaca-tech.payaca-core.component.mini-loader';
var SubscriptionUsageIndicator = function (_a) {
    var updateAdditionalUserSeatsTrigger = _a.updateAdditionalUserSeatsTrigger;
    var dispatch = useDispatch();
    var subscriptionUsageData = useSelector(function (state) {
        return state.analytics.subscriptionUsageData;
    });
    var isGettingSubscriptionUsageData = useSelector(function (state) {
        return state.analytics.isGettingSubscriptionUsageData;
    });
    useEffect(function () {
        dispatch(analyticsActions.requestGetSubscriptionUsageData());
        return function () {
            dispatch(analyticsActions.clearSubscriptionUsageData());
        };
    }, []);
    if (isGettingSubscriptionUsageData)
        return (React.createElement("div", { className: "subscription-usage-indicator loader-container" },
            React.createElement(MiniLoader, null)));
    if (!subscriptionUsageData)
        return null;
    return (React.createElement("div", { className: "subscription-usage-indicator" },
        React.createElement("div", { className: "subscription-usage-indicator-inner" },
            React.createElement("div", { className: "jobs-usage-indicator" },
                React.createElement("h3", null, "Monthly jobs"),
                React.createElement("div", { className: "available-used-container" },
                    React.createElement("div", { className: "available" },
                        React.createElement("span", { className: "figure" }, subscriptionUsageData.numberOfJobsPerMonth),
                        React.createElement("p", null, "available monthly")),
                    React.createElement("div", { className: "divider" }),
                    React.createElement("div", { className: "used" },
                        React.createElement("span", { className: "figure" }, subscriptionUsageData.currentMonthJobsUsed),
                        React.createElement("p", null, "sent this month")))),
            React.createElement("div", { className: "user-seats-indicator" },
                React.createElement("h3", null, "User seats"),
                React.createElement("div", { className: "available-used-container" },
                    React.createElement("div", { className: "available" },
                        React.createElement("span", { className: "figure" }, subscriptionUsageData.numberOfUserSeats),
                        React.createElement("p", null, "available")),
                    React.createElement("div", { className: "divider" }),
                    React.createElement("div", { className: "used " + (subscriptionUsageData.numberOfActiveUsers >
                            subscriptionUsageData.numberOfUserSeats
                            ? 'warning'
                            : '') },
                        React.createElement("span", { className: "figure" }, subscriptionUsageData.numberOfActiveUsers),
                        React.createElement("p", null, "used"))),
                updateAdditionalUserSeatsTrigger && (React.createElement("div", { className: "update-additional-user-seats-trigger-container" }, updateAdditionalUserSeatsTrigger))))));
};
export default SubscriptionUsageIndicator;
