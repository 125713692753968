import React from 'react';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
var TaxesTable = function (_a) {
    var accountingName = _a.accountingName, taxes = _a.taxes;
    return (React.createElement("div", { className: "taxes-table" },
        React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { colSpan: 2 }, "Payaca taxes"),
                    React.createElement("th", { className: "arrow-col" }),
                    React.createElement("th", { colSpan: 2 },
                        accountingName,
                        " taxes"))),
            React.createElement("tbody", null, taxes.map(function (tax, i) {
                return (React.createElement("tr", { key: i },
                    React.createElement("td", null, tax.payacaLabel),
                    React.createElement("td", { className: "percentage-cell" },
                        tax.payacaPercentage,
                        "%"),
                    React.createElement("td", { className: "arrow-cell" },
                        React.createElement(TrendingFlatIcon, null)),
                    React.createElement("td", null, tax.accountingLabel),
                    React.createElement("td", { className: "percentage-cell" },
                        tax.accountingPercentage,
                        "%")));
            })))));
};
export default TaxesTable;
