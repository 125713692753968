var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import set from 'lodash.set';
import { validateForm as validateFormHelper } from '@payaca/helpers/formValidationHelper';
import React, { useCallback, useEffect, useState, useRef, useMemo, } from 'react';
function usePrevious(value) {
    var ref = useRef();
    useEffect(function () {
        ref.current = value;
    });
    return ref.current;
}
var ValidatedForm = function (_a) {
    var onFormSubmit = _a.onFormSubmit, triggerValidationAt = _a.triggerValidationAt, initialFormState = _a.initialFormState, fieldValidators = _a.fieldValidators, renderFormContents = _a.renderFormContents;
    var _b = useState({}), formState = _b[0], setFormState = _b[1];
    var _c = useState({}), validationState = _c[0], setValidationState = _c[1];
    var _d = useState({}), touchedState = _d[0], setTouchedState = _d[1];
    var previousTriggerValidationAt = usePrevious(triggerValidationAt);
    var validateForm = useCallback(function () {
        setValidationState(validateFormHelper(formState, fieldValidators));
    }, [fieldValidators, formState]);
    var onFieldChange = useCallback(function (value) {
        setFormState(function (formState) {
            var modifiedFormState = __assign({}, formState);
            for (var fieldName in value) {
                set(modifiedFormState, fieldName, value[fieldName]);
            }
            return modifiedFormState;
        });
    }, []);
    var onFieldTouch = useCallback(function (fieldName) {
        setTouchedState(function (touchedState) {
            var _a;
            return __assign(__assign({}, touchedState), (_a = {}, _a[fieldName] = true, _a));
        });
    }, []);
    var onTriggerValidate = useCallback(function () {
        var modifiedTouchedState = {};
        for (var fieldName in fieldValidators) {
            modifiedTouchedState[fieldName] = true;
        }
        setTouchedState(function (touchedState) {
            return __assign(__assign({}, touchedState), modifiedTouchedState);
        });
        validateForm();
    }, [fieldValidators, validateForm]);
    var isValid = useMemo(function () {
        var isValid = true;
        for (var fieldName in fieldValidators) {
            var fieldValidationResult = validationState[fieldName];
            if (!fieldValidationResult || !fieldValidationResult.isValid) {
                isValid = false;
            }
        }
        return isValid;
    }, [fieldValidators, validationState]);
    useEffect(function () {
        if (triggerValidationAt &&
            triggerValidationAt !== previousTriggerValidationAt) {
            onTriggerValidate();
        }
    }, [triggerValidationAt, previousTriggerValidationAt, onTriggerValidate]);
    useEffect(function () {
        validateForm();
    }, [formState, validateForm]);
    useEffect(function () {
        if (initialFormState) {
            setFormState(initialFormState);
        }
    }, [initialFormState]);
    return (React.createElement("form", { className: "validated-form", onSubmit: onFormSubmit }, renderFormContents(isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch)));
};
export default ValidatedForm;
