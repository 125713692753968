import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FinanceProvisionOptionOverview, ContactUsCallout, } from '@/ui/components';
import './FinanceOptionsPage.sass';
import { faChevronRight, faChevronLeft, } from '@fortawesome/free-solid-svg-icons';
import { Badge } from '@payaca/components/badge';
import { Button, ButtonStyleVariant, ButtonColourVariant, } from '@payaca/components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IntercomAPI } from 'react-intercom';
import { actions as usersActions } from '@/api/users';
import { actions as accountActions } from '@bit/payaca-tech.payaca-core.store.account';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { Paper, ShieldDone, Wallet } from 'react-iconly';
import { ResponsiveViewWrapper } from '@payaca/components/responsiveViewWrapper';
var getSimpleFinanceProvisionOptionOverview = function (isActiveOption) {
    return (React.createElement(FinanceProvisionOptionOverview, { isActiveOption: isActiveOption, title: "Simple finance", processFlowSteps: [
            {
                icon: React.createElement(Paper, null),
                stepDescription: 'Send a quote for the total cost of your products/services',
            },
            {
                icon: React.createElement("img", { src: require('./person-check.svg').default }),
                stepDescription: 'Client selects amount to borrow and checks results',
            },
            {
                icon: React.createElement(Wallet, null),
                stepDescription: 'Client gets paid directly, often within 2 hours',
            },
            {
                icon: React.createElement("img", { src: require('./money.svg').default }),
                stepDescription: 'You collect payment as normal',
            },
        ], requirements: ["Send a quote using Payaca... that's it!"], benefits: [
            "Simplest option - you only have to subscribe to use our software and it's included in the price.",
            'No FCA authorisation needed as you are not directly involved in the finance process.',
            'How you take payment is up to you; you can request any deposit through the quote, turn on our card payment option for easy tracking or request BACS transfers.',
        ], costIndicatorElement: React.createElement(Badge, { colour: "#ffffff", backgroundColour: "#8bc34a" }, "Included in all plans"), additionalCostInformationElement: React.createElement("div", { className: "additional-cost-information" },
            React.createElement("p", null, "Included in all Payaca subscription plans")) }));
};
var getZeroPercentFinanceProvisionOptionOverview = function (isActiveOption, callToAction) {
    return (React.createElement(FinanceProvisionOptionOverview, { isActiveOption: isActiveOption, title: "0% over 12 months or less", processFlowSteps: [
            {
                icon: React.createElement(Paper, null),
                stepDescription: 'Send a quote for the total cost of your products/services',
            },
            {
                icon: React.createElement("img", { src: require('./person-check.svg').default }),
                stepDescription: 'Client selects "0% finance" and applies directly',
            },
            {
                icon: React.createElement(ShieldDone, null),
                stepDescription: 'We notify you that the client has been approved',
            },
            {
                icon: React.createElement("img", { src: require('./money.svg').default }),
                stepDescription: 'On completion of the work the client signs a satisfaction note and you get paid directly',
            },
        ], requirements: [
            'Professional Payaca subscription',
            '1 years accounts filed at companies house or 1 year tax returns for self employed.',
            'No CCJ’s, Bankruptcies, IVA’s personal or on the business',
            'UK (excluding N.Ireland)',
            'Apply and be approved by the lender.',
        ], benefits: [
            'You are directly offering 0% finance',
            React.createElement("span", { key: "1" },
                "You don't have to be FCA authorised as there is an ",
                React.createElement("a", { href: "https://www.fca.org.uk/firms/authorisation/when-required/exemptions-exclusions/installment-credit", target: "_blank", rel: "noreferrer" }, "exemption.")),
            'You are paid directly by the lender only after the work is complete and the customer signs a satisfaction note.',
        ], costIndicatorElement: React.createElement(Badge, { colour: "#ffffff", backgroundColour: "#5ba7ff" }, "Included in Professional & Premium"), additionalCostInformationElement: React.createElement("div", { className: "additional-cost-information" },
            React.createElement("p", null, "11% subsidy on the credit amount, for example, if the total quoted cost is \u00A31,250 and you set a minimum 20% deposit:"),
            [
                '£250 deposit will be paid to you direct ahead of work commencing.',
                '£890 will be paid to you by the lender when the customer signs a satisfaction note.',
                'The lender will take a subsidy of £110.',
                'The price must remain the same for cash and credit customers, but you can calculate this as a cost to your business and adjust accordingly. E.g. 20% of customers taking this option with a minimum 20% deposit would represent a 1.8% increase in the costs to your business.',
            ].map(function (info, index) { return (React.createElement("div", { className: "flex-container", key: index },
                React.createElement("span", { className: "icon-container" },
                    React.createElement(FontAwesomeIcon, { icon: faChevronRight })),
                React.createElement("span", null, info))); })), callToAction: callToAction }));
};
var getSecuredFinance = function (isActiveOption) {
    return (React.createElement(FinanceProvisionOptionOverview, { isActiveOption: isActiveOption, title: "Secured finance", processFlowSteps: [
            {
                icon: React.createElement(Paper, null),
                stepDescription: 'Send a quote for the total cost of your products/services',
            },
            {
                icon: React.createElement("img", { src: require('./person-check.svg').default }),
                stepDescription: 'Client checks their eligibility and are allocated an account manager to assist in securing the credit facility',
            },
            {
                icon: React.createElement(ShieldDone, null),
                stepDescription: 'Client receives flexible loan facility secured against their property ',
            },
            {
                icon: React.createElement("img", { src: require('./money.svg').default }),
                stepDescription: 'You collect payment as normal',
            },
        ], requirements: [
            'Premium Payaca subscription',
            "Send a quote using Payaca... that's it!",
        ], benefits: [
            'Just like a credit card, for the first 5 years, clients are issued a credit limit that they can borrow against as much as they need (up to your pre-approved credit limit - between £25k and £1M).',
            'Clients are only charged interest when they use funds, so anything unused will be interest free.',
            'Customers avoid the often costly process of re-mortgaging.',
            'No FCA authorisation needed as you are not directly involved in the finance process.',
        ], costIndicatorElement: React.createElement(Badge, { colour: "#ffffff", backgroundColour: "#5ba7ff" }, "Included in Premium") }));
};
var getFcaAuthorisationFinanceProvisionOptionOverview = function () {
    return (React.createElement(FinanceProvisionOptionOverview, { title: "Full FCA authorisation", processFlowSteps: [
            {
                icon: React.createElement(Paper, null),
                stepDescription: 'Send a quote for the total cost of your products/services',
            },
            {
                icon: React.createElement("img", { src: require('./person-check.svg').default }),
                stepDescription: 'Client selects from a range of finance option and applies directly',
            },
            {
                icon: React.createElement(ShieldDone, null),
                stepDescription: 'We notify you that the client has been approved',
            },
            {
                icon: React.createElement("img", { src: require('./money.svg').default }),
                stepDescription: 'On completion of the work the client signs a satisfaction note and you get paid directly',
            },
        ], requirements: [
            'Premium Payaca subscription',
            'All the requirements for 0% over 12 months',
            'You must gain FCA authorisation - we can help with this',
            'Apply and be approved by the lender',
        ], benefits: [
            'Offer a range of finance options including 0% over 2 or 3 years and interest bearing at a fixed rate.',
            'We can build your process into our quote flow.',
        ], costIndicatorElement: React.createElement(Badge, { colour: "#ffffff", backgroundColour: "#5ba7ff" }, "Included in Premium"), callToAction: React.createElement(Button, { onClick: function () { return IntercomAPI('show'); }, styleVariant: ButtonStyleVariant.OUTSIZE, colourVariant: ButtonColourVariant.PRIMARY }, "Register your interest") }));
};
var FinanceOptionsPage = function () {
    var history = useHistory();
    var dispatch = useDispatch();
    useEffect(function () {
        dispatch(usersActions.getProfile());
        dispatch(accountActions.requestGetAccountAccessInformation());
    }, []);
    var hasZeroPercentFinanceApplication = useSelector(function (state) { var _a; return (_a = state.users.myProfile.accounts[0].integrations) === null || _a === void 0 ? void 0 : _a.zeroPercentFinance.hasApplication; });
    var isZeroPercentFinanceApplicationSubmitted = useSelector(function (state) {
        var _a;
        return !!((_a = state.users.myProfile.accounts[0].integrations) === null || _a === void 0 ? void 0 : _a.zeroPercentFinance.submittedAt);
    });
    var hasSimpleFinanceProvision = useSelector(function (state) {
        var accountAccessInformation = state.account.accountAccessInformation;
        if (!accountAccessInformation) {
            return false;
        }
        return (accountAccessInformation.hasActiveSubscription ||
            !accountAccessInformation.isTrialPeriodExpired);
    });
    var hasZeroPercentFinanceProvision = useSelector(function (state) {
        var _a, _b, _c;
        var accountAccessInformation = state.account.accountAccessInformation;
        if (!accountAccessInformation) {
            return false;
        }
        return !!(((_a = accountAccessInformation === null || accountAccessInformation === void 0 ? void 0 : accountAccessInformation.features) === null || _a === void 0 ? void 0 : _a.hasZeroPercentFinance) && ((_c = (_b = state.users.myProfile.accounts[0].integrations) === null || _b === void 0 ? void 0 : _b.zeroPercentFinance) === null || _c === void 0 ? void 0 : _c.isApproved));
    });
    var hasSecuredFinanceProvision = useSelector(function (state) {
        var _a;
        var accountAccessInformation = state.account.accountAccessInformation;
        if (!accountAccessInformation) {
            return false;
        }
        return !!((_a = accountAccessInformation === null || accountAccessInformation === void 0 ? void 0 : accountAccessInformation.features) === null || _a === void 0 ? void 0 : _a.hasSelinaFinance);
    });
    var isCurrentUserAdmin = useSelector(function (state) { return state.users.myProfile.isAdmin; });
    var zeroPercentFinanceProvisionOptionCallToAction = useMemo(function () {
        if (!hasZeroPercentFinanceApplication) {
            return (React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, colourVariant: ButtonColourVariant.PRIMARY, onClick: function () { return history.push('/subsidisedFinanceInformation/overview'); }, isDisabled: !isCurrentUserAdmin }, isCurrentUserAdmin ? 'Apply' : 'Contact your admin to apply'));
        }
        if (hasZeroPercentFinanceApplication &&
            !isZeroPercentFinanceApplicationSubmitted &&
            isCurrentUserAdmin) {
            return (React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, colourVariant: ButtonColourVariant.PRIMARY, onClick: function () { return history.push('/subsidisedFinanceInformation/overview'); } }, "Continue application"));
        }
        if (hasZeroPercentFinanceApplication &&
            isZeroPercentFinanceApplicationSubmitted &&
            isCurrentUserAdmin) {
            return (React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, colourVariant: ButtonColourVariant.PRIMARY, onClick: function () { return history.push('/subsidisedFinanceInformation/overview'); } }, "See application status"));
        }
        return;
    }, [
        hasZeroPercentFinanceApplication,
        isZeroPercentFinanceApplicationSubmitted,
        isCurrentUserAdmin,
    ]);
    return (React.createElement(AuthenticatedPageWrapper, { className: "finance-options-page", title: React.createElement("div", { className: "title-bar-inner" },
            React.createElement("div", { className: "button-container" },
                React.createElement(Button, { iconBefore: faChevronLeft, styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () { return history.push('/personalSettings'); } }, "Back")),
            React.createElement("h1", null, "Finance options")) },
        React.createElement("p", { className: "how-finance-works-page-description" }, "We offer a range of finance options that enable your clients to\n              secure the funding they need to work with you. If you'd like to\n              discuss the best option for your business please call or send a\n              message us and our friendly team will be happy to help."),
        React.createElement("div", null,
            React.createElement(ContactUsCallout, null)),
        React.createElement("hr", null),
        React.createElement(ResponsiveViewWrapper, { className: "flex-container finance-options-container", downBreakpointSm: 900 },
            getSimpleFinanceProvisionOptionOverview(hasSimpleFinanceProvision),
            getZeroPercentFinanceProvisionOptionOverview(hasZeroPercentFinanceProvision, zeroPercentFinanceProvisionOptionCallToAction),
            getSecuredFinance(hasSecuredFinanceProvision),
            getFcaAuthorisationFinanceProvisionOptionOverview())));
};
export default FinanceOptionsPage;
