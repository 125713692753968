import React, { useMemo, useRef } from 'react';
import LabelValuePair from '../labelValuePair/LabelValuePair';
import JobOverviewNotes from '../jobOverviewNotes/JobOverviewNotes';
import { isQuoteOrEstimate } from '@payaca/helpers/jobStatusHelper';
import { isInvoicePaymentRequired, isDepositPaymentRequired, } from '@payaca/helpers/jobHelperV2';
import { useSmallViewListener } from '@payaca/utilities/hookUtilities';
import './JobOverviewBusinessDetails.sass';
var JobOverviewBusinessDetails = function (_a) {
    var account = _a.account, _b = _a.forceDisplayAsProposition, forceDisplayAsProposition = _b === void 0 ? false : _b, job = _a.job, jobFinanceInformation = _a.jobFinanceInformation;
    var jobOverviewBusinessDetailsRef = useRef(null);
    var isSmallView = useSmallViewListener(jobOverviewBusinessDetailsRef, 600)[0];
    var displayAsProposition = useMemo(function () { return isQuoteOrEstimate(job.status) || forceDisplayAsProposition; }, [forceDisplayAsProposition, job]);
    var isPaymentRequired = useMemo(function () {
        if (!job) {
            return false;
        }
        return displayAsProposition
            ? isDepositPaymentRequired(job)
            : isInvoicePaymentRequired(job);
    }, [job, displayAsProposition]);
    var shouldRenderJobOverviewPaymentDetails = useMemo(function () { return !jobFinanceInformation && isPaymentRequired; }, [isPaymentRequired, jobFinanceInformation]);
    var canShowJobOverviewBusinessDetails = useMemo(function () {
        return ((shouldRenderJobOverviewPaymentDetails &&
            job.showBacsPaymentOption &&
            (!!account.accountNameRaw ||
                !!account.accountNumberRaw ||
                !!account.sortCodeRaw ||
                !!account.vatNumber ||
                !!account.paymentTerms)) ||
            account.businessNotes);
    }, []);
    if (!canShowJobOverviewBusinessDetails) {
        return null;
    }
    return (React.createElement("div", { ref: jobOverviewBusinessDetailsRef, className: "job-overview-business-details" + (isSmallView ? ' small-view' : '') },
        shouldRenderJobOverviewPaymentDetails && (React.createElement("div", { className: "payment-section" },
            shouldRenderJobOverviewPaymentDetails &&
                !!job.showBacsPaymentOption && (React.createElement("div", { className: "bacs-payment-details" },
                React.createElement(LabelValuePair, { label: "Payable to", value: account.accountNameRaw }),
                React.createElement(LabelValuePair, { label: "Sort code", value: account.sortCodeRaw }),
                React.createElement(LabelValuePair, { label: "Account number", value: account.accountNumberRaw }),
                account.vatNumber && (React.createElement(LabelValuePair, { label: "VAT number", value: account.vatNumber })))),
            shouldRenderJobOverviewPaymentDetails && account.paymentTerms && (React.createElement(JobOverviewNotes, { title: "Payment terms", notes: account.paymentTerms })))),
        account.businessNotes && (React.createElement(JobOverviewNotes, { title: "Terms of business", notes: account.businessNotes }))));
};
export default JobOverviewBusinessDetails;
