import React, { useEffect, useMemo, useCallback, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// @ts-ignore
import scrollToWithAnimation from 'scrollto-with-animation';
import { SubsidisedFinanceInformationFormProgressTracker, BusinessInformationFormSection, FinancialInformationFormSection, RegulatoryRequirementsFormSection, AdditionalInformationFormSection, AuthorityToSearchFormSection, SupportingDocumentsFormSection, ReviewFormSection, GetStartedFormSection, } from '@/ui/components';
import { ContentPanel } from '@payaca/components/contentPanel';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { actions as subsidisedFinanceInformationActions } from '@payaca/store/subsidisedFinanceInformation';
import { StepLabel } from '@payaca/types/subsidisedFinanceInformationTypes';
import './SubsidisedFinanceInformationFormPage.css';
var SubsidisedFinanceInformationFormPage = function () {
    var dispatch = useDispatch();
    var currentStepLabel = useSelector(function (state) { return state.subsidisedFinanceInformation.currentStepLabel; });
    var scrollToTop = useCallback(function () {
        scrollToWithAnimation(document.getElementsByClassName('page-content')[0], 'scrollTop', 0, 300, 'easeInOutCirc');
    }, []);
    var formSection = useMemo(function () {
        switch (currentStepLabel) {
            case StepLabel.GET_STARTED:
                return React.createElement(GetStartedFormSection, null);
            case StepLabel.BUSINESS_INFORMATION:
                return React.createElement(BusinessInformationFormSection, null);
            case StepLabel.FINANCIAL_INFORMATION:
                return React.createElement(FinancialInformationFormSection, null);
            case StepLabel.REGULATORY_REQUIREMENTS:
                return React.createElement(RegulatoryRequirementsFormSection, null);
            case StepLabel.ADDITIONAL_INFORMATION:
                return React.createElement(AdditionalInformationFormSection, null);
            case StepLabel.AUTHORITY_TO_SEARCH:
                return React.createElement(AuthorityToSearchFormSection, null);
            case StepLabel.SUPPORTING_DOCUMENTS:
                return React.createElement(SupportingDocumentsFormSection, null);
            case StepLabel.REVIEW:
                return React.createElement(ReviewFormSection, null);
            default:
                return null;
        }
    }, [currentStepLabel]);
    var onClickStep = useCallback(function (label) {
        var stepLabel = label;
        if (stepLabel !== currentStepLabel) {
            dispatch(subsidisedFinanceInformationActions.requestSetCurrentStep(stepLabel));
        }
    }, [dispatch]);
    useEffect(function () {
        dispatch(subsidisedFinanceInformationActions.requestGetCurrentStepLabel());
    }, [dispatch]);
    useEffect(function () {
        scrollToTop();
    }, [currentStepLabel]);
    return (React.createElement(AuthenticatedPageWrapper, { className: "subsidised-finance-information-form-page", title: "0% Finance Application" },
        React.createElement("div", { className: "subsidised-finance-information-form-page-content" },
            currentStepLabel !== StepLabel.GET_STARTED && (React.createElement(SubsidisedFinanceInformationFormProgressTracker, { currentStepLabel: currentStepLabel, onClickStep: onClickStep })),
            React.createElement(ContentPanel, null,
                React.createElement(React.Fragment, null, formSection)))));
};
export default SubsidisedFinanceInformationFormPage;
