// ======================================================================================================
// Private routing
// - Routes/redirects accessible to authorised users (role dependent)
// ======================================================================================================

import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PlatformUtil } from '@/utils';
import LoaderOverlay from '@bit/payaca-tech.payaca-core.component.loader-overlay';

import { PrivateRoutingConfig } from './privateRoutingConfig';
import RolesConfig from './rolesConfig';
import RoutesAndRedirects from './RoutesAndRedirects';

import { actions as usersActions } from '@/api/users';
import { actions as accountActions } from '@bit/payaca-tech.payaca-core.store.account';

const PrivateRouting = ({
  dismissMobileMode,
  match,
  accountAccessInformation,
  profile,
  users,
}) => {
  useEffect(() => {
    users.getProfile();
  }, [users]);

  useEffect(() => {
    accountActions.requestGetAccountAccessInformation();
  }, [accountActions]);

  const trialPeriodIsExpired = useMemo(() => {
    if (!accountAccessInformation) return false;
    return (
      !accountAccessInformation.hasActiveSubscription &&
      accountAccessInformation.isTrialPeriodExpired
    );
  }, [accountAccessInformation]);

  const hasExceededUserSeats = useMemo(() => {
    if (!accountAccessInformation) return false;

    return (
      accountAccessInformation.hasActiveSubscription &&
      accountAccessInformation.hasExceededActiveSubscriptionUserSeats
    );
  }, [accountAccessInformation]);

  const trialPeriodExpiredRoutes = useMemo(() => {
    return profile?.isAdmin
      ? PrivateRoutingConfig.AdminTrialPeriodExpiredRoutes
      : PrivateRoutingConfig.TrialPeriodExpiredRoutes;
  }, [profile?.isAdmin]);

  const trialPeriodExpiredRedirects = useMemo(() => {
    return profile?.isAdmin
      ? PrivateRoutingConfig.AdminTrialPeriodExpiredRedirects
      : PrivateRoutingConfig.TrialPeriodExpiredRedirects;
  }, [profile?.isAdmin]);

  const hasExceededUserSeatsRoutes = useMemo(() => {
    return profile?.isAdmin
      ? PrivateRoutingConfig.AdminUserSeatsExceededRoutes
      : PrivateRoutingConfig.UserSeatsExceededRoutes;
  }, [profile?.isAdmin]);

  const hasExceededUserSeatsRedirects = useMemo(() => {
    return profile?.isAdmin
      ? PrivateRoutingConfig.AdminUserSeatsExceededRedirects
      : PrivateRoutingConfig.UserSeatsExceededRedirects;
  }, [profile?.isAdmin]);

  const userIsVerified = useMemo(() => {
    return !!profile?.verifiedAt;
  }, [profile?.verifiedAt]);

  const userHasContactNumber = useMemo(() => {
    return !!profile?.contactNumber;
  }, [profile?.contactNumber]);

  const accountIsSetup = useMemo(() => {
    if (!profile?.accounts) return false;
    return profile.accounts.length > 0;
  }, [profile]);

  const accountIsNotOnboarded = useMemo(() => {
    if (!accountAccessInformation) return false;
    return (
      !accountAccessInformation.hasActiveSubscription &&
      !accountAccessInformation.hasInactiveSubscription &&
      !accountAccessInformation.isOnboarded
    );
  }, [accountAccessInformation]);

  const isDealViewActivated = useMemo(() => {
    return profile.accounts?.[0]?.dealViewActivatedAt;
  }, [profile]);

  const hasShowDealsFeature = useMemo(() => {
    return accountAccessInformation?.features?.showDeals;
  }, [accountAccessInformation]);

  const standardRoutes = {};
  const mobileRoutes = {};

  // add common routes
  RolesConfig.common.routes.forEach((routeString) => {
    standardRoutes[routeString] = PrivateRoutingConfig.Routes[routeString];
    mobileRoutes[routeString] = PrivateRoutingConfig.MobileRoutes[routeString];
  });
  // add role specific routes
  if (profile && profile.isAdmin) {
    RolesConfig.admin.routes.forEach((routeString) => {
      standardRoutes[routeString] = PrivateRoutingConfig.Routes[routeString];
      mobileRoutes[routeString] =
        PrivateRoutingConfig.MobileRoutes[routeString];
    });
  }
  if (
    process.env.NODE_ENV === 'development' &&
    PrivateRoutingConfig.DevRoutes
  ) {
    Object.keys(PrivateRoutingConfig.DevRoutes).forEach((r) => {
      standardRoutes[r] = PrivateRoutingConfig.DevRoutes[r];
    });
  }

  const availableRoutes = useMemo(() => {
    if (!userHasContactNumber) {
      return PrivateRoutingConfig.RequiresSetupUserRoutes;
    }
    if (!accountIsSetup) {
      return PrivateRoutingConfig.RequiresSetupAccountRoutes;
    }
    if (!userIsVerified) {
      return PrivateRoutingConfig.UserUnverifiedRoutes;
    }
    if (accountIsNotOnboarded) {
      return PrivateRoutingConfig.NotOnboardedRoutes;
    }
    if (trialPeriodIsExpired) {
      return trialPeriodExpiredRoutes;
    }
    if (hasExceededUserSeats) {
      return hasExceededUserSeatsRoutes;
    }
    return standardRoutes;
  }, [
    hasExceededUserSeats,
    hasExceededUserSeatsRoutes,
    accountIsNotOnboarded,
    accountIsSetup,
    userIsVerified,
    trialPeriodExpiredRoutes,
    trialPeriodIsExpired,
    standardRoutes,
    userHasContactNumber,
  ]);

  const availableRedirects = useMemo(() => {
    if (!userHasContactNumber) {
      return PrivateRoutingConfig.RequiresSetupUserRedirects;
    }
    if (!accountIsSetup) {
      return PrivateRoutingConfig.RequiresSetupAccountRedirects;
    }
    if (!userIsVerified) {
      return PrivateRoutingConfig.UserUnverifiedRedirects;
    }
    if (accountIsNotOnboarded) {
      return PrivateRoutingConfig.NotOnboardedRedirects;
    }
    if (trialPeriodIsExpired) {
      return trialPeriodExpiredRedirects;
    }
    if (hasExceededUserSeats) {
      return hasExceededUserSeatsRedirects;
    }
    if (!hasShowDealsFeature && !isDealViewActivated) {
      return PrivateRoutingConfig.NoDealViewRedirects;
    }
    return PrivateRoutingConfig.Redirects;
  }, [
    accountIsNotOnboarded,
    accountIsSetup,
    hasShowDealsFeature,
    hasExceededUserSeats,
    hasExceededUserSeatsRedirects,
    isDealViewActivated,
    trialPeriodExpiredRedirects,
    trialPeriodIsExpired,
    userHasContactNumber,
    userIsVerified,
  ]);

  if (profile && Object.keys(profile).length) {
    if (
      PlatformUtil.isMobileScreen() &&
      !dismissMobileMode &&
      !accountIsNotOnboarded
    ) {
      return (
        <RoutesAndRedirects
          availableRoutes={mobileRoutes}
          availableRedirects={PrivateRoutingConfig.MobileRedirects}
          matchPath={match.path}
        />
      );
    }
    return (
      <RoutesAndRedirects
        availableRoutes={availableRoutes}
        availableRedirects={availableRedirects}
        matchPath={match.path}
      />
    );
  }
  return <LoaderOverlay />;
};

export default connect(
  (state) => ({
    dismissMobileMode: state.app.dismissMobileMode,
    profile: state.users.myProfile,
    accountAccessInformation: state.account.accountAccessInformation,
  }),
  (dispatch) => ({
    users: bindActionCreators(usersActions, dispatch),
    accountActions: bindActionCreators(accountActions, dispatch),
  })
)(PrivateRouting);
