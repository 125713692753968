import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import RadioButtons from './RadioButtons';
import './SectionRadioButtons.sass';
var styles = function () { return ({
    radioButtonWrapper: {
        height: 50,
        width: '100%',
        borderRadius: 10,
        borderWidth: 1,
        flexDirection: 'row',
        display: 'flex',
        overflow: 'hidden',
        border: '1px solid #FABb00',
        marginTop: 10,
        marginBottom: 20,
        cursor: 'pointer',
    },
}); };
var SectionRadioButtons = function (_a) {
    var items = _a.items, onChange = _a.onChange, title = _a.title, value = _a.value;
    var getItemStyle = function (selected, isLastItem) {
        var style = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 48,
            width: "calc(100% - " + (41 + (items.length - 1)) + "px)",
            position: 'relative',
            fontWeight: 'bold',
        };
        if (selected) {
            style.backgroundColor = '#fff2cc';
        }
        if (!isLastItem) {
            style.borderRight = '1px solid #FABb00';
        }
        return style;
    };
    return (React.createElement("div", { style: { position: 'relative' }, className: "section-radio-buttons" },
        React.createElement("div", null,
            React.createElement("label", { className: "additional-label" }, title)),
        React.createElement("div", { className: "radio-button-wrapper" },
            React.createElement(RadioButtons, { items: items, value: value, onChange: onChange }))));
};
export default withStyles(styles)(SectionRadioButtons);
