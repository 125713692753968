import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJob } from '@/utils/stateAccessors';
import JobAssignmentDropdown from '../jobAssignmentDropdown/JobAssignmentDropdown';
import { actions as jobActions } from '@/api/jobs';
var JobAssignmentControl = function (_a) {
    var jobId = _a.jobId, onJobUpdateSuccess = _a.onJobUpdateSuccess;
    var dispatch = useDispatch();
    var job = useSelector(function (state) { return getJob(state, jobId); });
    var onAssignedUserChange = useCallback(function (userId) {
        var newAssignedUserId = userId || null;
        dispatch(jobActions.assignJob(jobId, newAssignedUserId, function (err) {
            if (!err) {
                onJobUpdateSuccess && onJobUpdateSuccess();
            }
        }));
    }, [dispatch, jobId, onJobUpdateSuccess]);
    var isCurrentUserAdmin = useSelector(function (state) { return state.users.myProfile.isAdmin; });
    if (!job || !isCurrentUserAdmin)
        return null;
    return (React.createElement(JobAssignmentDropdown, { currentAssignedUser: job.assignedToUserId, onAssignedUserChange: onAssignedUserChange }));
};
export default JobAssignmentControl;
