import React, { useMemo } from 'react';
import './ValidatedFieldWrapper.sass';
import { ErrorMessage } from '../feedbackMessage/FeedbackMessage';
var ValidatedFieldWrapper = function (_a) {
    var children = _a.children, validationResult = _a.validationResult, _b = _a.isTouched, isTouched = _b === void 0 ? true : _b;
    var isInvalid = useMemo(function () {
        if (!validationResult)
            return false;
        if (!isTouched)
            return false;
        return !validationResult.isValid;
    }, [validationResult, isTouched]);
    var validationErrors = useMemo(function () {
        return (validationResult === null || validationResult === void 0 ? void 0 : validationResult.errors) || [];
    }, [validationResult]);
    return (React.createElement("div", { className: "validated-field-wrapper" + (isInvalid ? ' invalid' : '') },
        children,
        validationErrors && !!validationErrors.length && (React.createElement("div", { className: "validation-errors" }, validationErrors.map(function (error, index) {
            return React.createElement(ErrorMessage, { message: error, key: index });
        })))));
};
export default ValidatedFieldWrapper;
