import React, { Component } from 'react';
import isEqual from 'lodash.isequal';

import { StringUtil } from '@/utils';

import './Pagination.css';

export default class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPage: 0,
    };

    this.defaultPage = 0;
  }

  componentDidMount() {
    this.onDataChange();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.onDataChange();
    }
  }

  onDataChange() {
    if (this.pagesEnabled()) {
      this.pageChange(this.defaultPage);
    } else {
      this.props.onPageChange(this.props.data);
    }
  }

  pagesEnabled() {
    return !!this.props.dataItemsPerPage;
  }

  pageChange(pageNumber) {
    const { onPageChange, data, dataItemsPerPage } = this.props;
    const sliceStart = pageNumber * dataItemsPerPage;
    const sliceEnd = (pageNumber + 1) * dataItemsPerPage;
    const pageData = data.slice(sliceStart, sliceEnd);
    onPageChange(pageData);
    this.setState({ selectedPage: pageNumber });
  }

  renderPageNumbers() {
    const pages = [];
    const noOfPages =
      Math.ceil(this.props.data.length / this.props.dataItemsPerPage) || 1;

    for (let i = 0; i < noOfPages; i++) {
      const normalisedIndex = i + 1;
      const classes = `page-number${
        i === this.state.selectedPage ? ' selected' : ''
      }`;
      pages.push(
        <div className={classes} onClick={() => this.pageChange(i)} key={i}>
          {normalisedIndex}
        </div>
      );
    }
    return pages;
  }

  render() {
    const resultsString = StringUtil.singularPlural(
      this.props.data.length,
      'result',
      'results'
    );
    return (
      <div className="pagination">
        <div className="results">{resultsString}</div>
        {this.props.dataItemsPerPage && (
          <div className="pages">{this.renderPageNumbers()}</div>
        )}
      </div>
    );
  }
}
