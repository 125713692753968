import { Button, ButtonColourVariant } from '@payaca/components/button';
import React, { useCallback, useMemo } from 'react';
import './HeaderNavigationControl.sass';
import HeaderNavigation from '../headerNavigation/HeaderNavigation';
import { useSelector } from 'react-redux';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Setting } from 'react-iconly';
import ResponsivePopperAndTrigger from '../responsivePopperAndTrigger/ResponsivePopperAndTrigger';
var HeaderNavigationControl = function (_a) {
    var _b = _a.isSmallView, isSmallView = _b === void 0 ? false : _b;
    var myProfile = useSelector(function (state) { return state.users.myProfile; });
    var companyName = useMemo(function () {
        var _a, _b;
        return (_b = (_a = myProfile === null || myProfile === void 0 ? void 0 : myProfile.accounts) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.companyName;
    }, [myProfile]);
    var renderTriggerContent = useCallback(function (isSmallView) {
        if (!isSmallView)
            return (React.createElement(Button, { colourVariant: ButtonColourVariant.WHITE, iconAfter: faChevronDown, hasBoxShadow: true }, companyName));
        if (isSmallView)
            return React.createElement(Setting, { set: "light" });
    }, [companyName]);
    var renderPopperContent = useCallback(function (isSmallView) {
        if (!isSmallView)
            return React.createElement(HeaderNavigation, null);
        if (isSmallView)
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "account-user-indicator" },
                    React.createElement("span", { className: "account-name" }, companyName),
                    React.createElement("span", { className: "user-name" }, myProfile.firstname + " " + myProfile.lastname)),
                React.createElement(HeaderNavigation, null)));
    }, [myProfile, companyName]);
    return (React.createElement("div", { className: "header-navigation-control " + (isSmallView ? 'sm-view' : '') },
        React.createElement(ResponsivePopperAndTrigger, { isSmallView: isSmallView, renderTriggerContent: renderTriggerContent, renderPopperContent: renderPopperContent, popperClassName: "header-navigation-control-popper", drawerClassName: "header-navigation-control-drawer" })));
};
export default HeaderNavigationControl;
