// ======================================================================================================
// Validation helper functions
// ======================================================================================================
import get from 'lodash.get';
import isEmail from 'validator/es/lib/isEmail';
import { getFormServiceError } from '@bit/payaca-tech.payaca-core.helpers.error';
import { StringUtil } from '@/utils';
var FieldType = {
    TEXTAREA: 'text-area',
};
var minPasswordLength = 6;
var isBlockedEmail = function (emailToCheck, blockedEmails) {
    return !!blockedEmails.find(function (em) { return em === emailToCheck; });
};
var validations = {
    required: {
        validate: function (val) { return !!val && /[\S]/.test(val); },
        error: 'This is a required field',
    },
    priceRequired: {
        validate: function (val) { return !!val || val === 0; },
        error: 'This is a required field',
    },
    passwordCreate: {
        validate: function (password) {
            return password &&
                password.length >= minPasswordLength &&
                /\d/.test(password) &&
                /[a-zA-Z]/.test(password);
        },
        error: 'Password must be at least 6 characters and contain 1 letter and 1 number',
    },
    contactNumber: {
        validate: function (phone) {
            return !phone || phone.replace('+', '').length === 12;
        },
        error: 'Not a recognised phone number',
    },
    passwordLength: {
        validate: function (password) { return password.length >= minPasswordLength; },
        error: ' ',
    },
    email: {
        validate: function (email, options) {
            return !email ||
                (isEmail(email) &&
                    !isBlockedEmail(email, options ? options.blockedEmails : []));
        },
        error: 'Not a recognised email address',
    },
    emailNoError: {
        validate: function (email, options) {
            return !email ||
                (isEmail(email) &&
                    !isBlockedEmail(email, options ? options.blockedEmails : []));
        },
        error: ' ',
    },
    vatNumber: {
        validate: function (vatNumber) { return !vatNumber || vatNumber.length <= 15; },
        error: 'Invalid vat number',
    },
    accountNumber: {
        validate: function (accountNumber) {
            return !accountNumber || /^[0-9]{8}$/.test(accountNumber);
        },
        error: 'Invalid account number',
    },
    sortCode: {
        validate: function (sortCode) { return !sortCode || /^[0-9]{6}$/.test(sortCode); },
        error: 'Invalid sort code',
    },
    passwordMatch: {
        validate: function (confirmPassword, password) {
            return confirmPassword === password;
        },
        error: 'Passwords must match',
    },
};
var FormValidations = {
    customer: {
        name: [validations.required],
        email: [validations.email],
        contactNumber: [],
    },
    item: {
        name: [],
        description: [validations.required],
        price: [validations.priceRequired],
        supplierName: [],
        supplierPrice: [],
    },
    lineItem: {
        description: [validations.required],
        price: [validations.priceRequired],
        quantity: [validations.required],
    },
    forgotPassword: {
        email: [validations.required, validations.email],
    },
    resetPassword: {
        newPassword: [validations.required, validations.passwordCreate],
        confirmPassword: [validations.required, validations.passwordMatch],
    },
    register: {
        firstName: [validations.required],
        lastName: [validations.required],
        email: [validations.required, validations.email],
        password: [validations.required, validations.passwordCreate],
        contactNumber: [validations.required, validations.contactNumber],
    },
    personalDetails: {
        firstName: [validations.required],
        lastName: [validations.required],
        email: [validations.required, validations.email],
        contactNumber: [validations.required, validations.contactNumber],
        currentPassword: [validations.required, validations.passwordLength],
        newPassword: [validations.required, validations.passwordCreate],
    },
    login: {
        email: [validations.required, validations.emailNoError],
        password: [validations.required, validations.passwordLength],
    },
    businessAccount: {
        businessName: [validations.required],
    },
    businessSettings: {
        businessName: [validations.required],
        email: [validations.email],
        contactNumber: [],
        firstLineAddress: [],
        secondLineAddress: [],
        city: [],
        postcode: [],
        vatNumber: [validations.vatNumber],
        paymentTerms: [],
        accountName: [],
        accountNumber: [validations.accountNumber],
        sortCode: [validations.sortCode],
    },
    bacsDetails: {
        accountName: [validations.required],
        accountNumber: [validations.required, validations.accountNumber],
        sortCode: [validations.required, validations.sortCode],
    },
    verifyAccount: {
        email: [validations.required, validations.email],
    },
    addUser: {
        firstName: [validations.required],
        lastName: [validations.required],
        email: [validations.required, validations.email],
    },
    userInvite: {
        password: [validations.required, validations.passwordCreate],
    },
    setupUser: {
        contactNumber: [validations.required, validations.contactNumber],
    },
};
var ExternalFormValidationErrors = {
    email: {
        error: 'This email looks invalid to us, please double check before continuing',
        allowInvalid: true,
    },
    contactNumber: {
        error: 'This phone number looks invalid to us, please double check before continuing',
        allowInvalid: true,
    },
};
var getExternalFieldError = function (name) {
    var error = ExternalFormValidationErrors[name];
    return error || null;
};
// errors are assumed to be invalid if there is an error shown
// TODO: ability to customise this on a per field basis
var getFieldErrors = function (value, validations, options) {
    if (validations) {
        return validations.reduce(function (acc, v) {
            return !v.validate(value, options)
                ? acc.concat({ error: v.error, allowInvalid: false })
                : acc;
        }, []);
    }
    return [];
};
var Autocomplete = {
    firstName: 'given-name',
    lastName: 'family-name',
    email: 'email',
    newPassword: 'new-password',
    currentPassword: 'current-password',
};
var getAutoComplete = function (name) { return get(Autocomplete, name, ''); };
var Placeholder = {
    vatNumber: '123456789',
    paymentTerms: 'e.g. BACS or cheque',
    sortCode: 'e.g. 132457',
    price: ' ',
    quoteNotes: ' ',
    invoiceNotes: ' ',
    businessNotes: 'Text here will be added to the bottom of every quote & invoice',
};
var getPlaceholder = function (name) { return get(Placeholder, name, ''); };
var CharacterLimit = {};
var getCharacterLimit = function (name) { return get(CharacterLimit, name, null); };
var getManageTermsErrors = function (err) {
    var errs = Object.keys(err);
    return errs.reduce(function (acc, curr, i) {
        acc = acc + " " + StringUtil.list(err[curr]) + " " + getFormServiceError('business', curr);
        if (i === errs.length - 1) {
            acc = acc + " Please try again.";
        }
        else if (errs.length !== 1) {
            acc = acc + ' ';
        }
        return acc;
    }, '');
};
var isFieldRequired = function (fieldValidations) {
    return (fieldValidations && fieldValidations.find(function (f) { return f === validations.required; }));
};
export { ExternalFormValidationErrors, FieldType, FormValidations, getAutoComplete, getCharacterLimit, getExternalFieldError, getFieldErrors, getFormServiceError, getPlaceholder, getManageTermsErrors, validations, isFieldRequired, };
