import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJobPaymentsByDealId, getJobsByDealId, } from '@/utils/stateAccessors';
import { isInvoice } from '@payaca/helpers/jobStatusHelper';
import { TitledContentPanel } from '@payaca/components/titledContentPanel';
import './DealOverview.sass';
import { useHistory } from 'react-router';
import DealListedJobsTable from '../dealListedJobsTable/DealListedJobsTable';
import { actions as jobActions } from '@/api/jobs';
import { JobActionType } from '@payaca/types/jobActionsTypes';
import { getModal } from '@/helpers/modalHelper';
import { actions as appActions } from '@/api/app';
import ResendJobModal from '../resendJobModal/ResendJobModal';
import RecordPaymentModal from '../recordPaymentModal/RecordPaymentModal';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
export var DealOverview = function (_a) {
    var dealId = _a.dealId, onDealUpdateSuccess = _a.onDealUpdateSuccess;
    var history = useHistory();
    var dispatch = useDispatch();
    var _b = useState(false), isDuplicatingJob = _b[0], setIsDuplicatingJob = _b[1];
    var _c = useState(false), isDeletingJob = _c[0], setIsDeletingJob = _c[1];
    var _d = useState(false), isMarkingJobAccepted = _d[0], setIsMarkingJobAccepted = _d[1];
    var _e = useState(false), isMarkingJobDeclined = _e[0], setIsMarkingJobDeclined = _e[1];
    var _f = useState(false), isRaisingInvoice = _f[0], setIsRaisingInvoice = _f[1];
    var _g = useState(), resendJobId = _g[0], setResendJobId = _g[1];
    var _h = useState(), recordPaymentJobId = _h[0], setRecordPaymentJobId = _h[1];
    var jobs = useSelector(function (state) {
        return getJobsByDealId(state, dealId);
    });
    var customer = useSelector(function (state) { return state.customer.currentCustomer; });
    var jobPayments = useSelector(function (state) {
        return getJobPaymentsByDealId(state, dealId);
    });
    var proposals = useMemo(function () {
        return jobs === null || jobs === void 0 ? void 0 : jobs.filter(function (x) { return !isInvoice(x.status); });
    }, [jobs, jobs === null || jobs === void 0 ? void 0 : jobs.length]);
    var invoiceableProposalId = useMemo(function () {
        var _a;
        return (_a = proposals.find(function (x) {
            return !x.archivedAt &&
                !x.inactivatedAt &&
                !isInvoice(x.status) &&
                !x.showBespokeFinancePlans;
        })) === null || _a === void 0 ? void 0 : _a.id;
    }, [proposals]);
    var invoices = useMemo(function () {
        return jobs === null || jobs === void 0 ? void 0 : jobs.filter(function (x) { return isInvoice(x.status); });
    }, [jobs, jobs === null || jobs === void 0 ? void 0 : jobs.length]);
    var markJobAccepted = useCallback(function (jobId) {
        setIsMarkingJobAccepted(true);
        dispatch(jobActions.acceptQuote(jobId, function () {
            onDealUpdateSuccess(jobId);
            setIsMarkingJobAccepted(false);
        }));
    }, [dispatch, onDealUpdateSuccess]);
    var markJobDeclined = useCallback(function (jobId) {
        setIsMarkingJobDeclined(true);
        dispatch(jobActions.declineJob(jobId, function () {
            onDealUpdateSuccess(jobId);
            setIsMarkingJobDeclined(false);
        }));
    }, [dispatch, onDealUpdateSuccess]);
    var duplicateJob = useCallback(function (jobId) {
        var job = jobs.find(function (x) { return x.id === jobId; });
        if (!job)
            return;
        setIsDuplicatingJob(true);
        dispatch(jobActions.duplicateJob(jobId, function (error, response) {
            if (!error) {
                history.push('/'); // this is needed so the page actually reloads - otherwise it sees that the route hasn't changed and doesn't do anything
                if (isInvoice(job.status)) {
                    history.push("/quotes/" + response.newJobId);
                }
                else {
                    history.push("/invoices/" + response.newJobId);
                }
            }
            setIsDuplicatingJob(false);
        }));
    }, [dispatch, history, jobs]);
    var invoiceJob = useCallback(function (jobId) {
        setIsRaisingInvoice(true);
        dispatch(jobActions.convertJobToInvoice(jobId, function (err, resp) {
            setIsRaisingInvoice(false);
            if (!err) {
                history.push({
                    pathname: "/invoices/" + resp.invoiceId,
                });
            }
        }));
    }, [dispatch, history]);
    var deleteJob = useCallback(function (jobId) {
        dispatch(appActions.showModal(getModal('DELETE_JOB', {
            primaryAction: function () {
                setIsDeletingJob(true);
                dispatch(jobActions.archiveJob(jobId, function () {
                    dispatch(appActions.hideModal());
                    setIsDeletingJob(false);
                    jobs.forEach(function (job) {
                        onDealUpdateSuccess(job.id);
                    });
                }));
            },
            secondaryAction: function () { return dispatch(appActions.hideModal()); },
            onClose: function () { return dispatch(appActions.hideModal()); },
        })));
    }, [dispatch, history, onDealUpdateSuccess]);
    var goToSendJob = useCallback(function (jobId) {
        var job = jobs.find(function (x) { return x.id === jobId; });
        if (!job)
            return;
        var jobIsInvoice = isInvoice(job.status);
        history.push("/" + (jobIsInvoice ? 'invoices' : 'quotes') + "/" + jobId + "?view=send");
    }, [jobs, history]);
    var quickActionDefinitions = useMemo(function () {
        var _a;
        return _a = {},
            _a[JobActionType.DUPLICATE] = {
                actionName: 'Duplicate',
                actionBehaviour: duplicateJob,
                isActionProcessing: isDuplicatingJob,
            },
            _a[JobActionType.MARK_AS_ACCEPTED] = {
                actionName: 'Mark as accepted',
                actionBehaviour: markJobAccepted,
                isActionProcessing: isMarkingJobAccepted,
            },
            _a[JobActionType.MARK_AS_DECLINED] = {
                actionName: 'Mark as declined',
                actionBehaviour: markJobDeclined,
                isActionProcessing: isMarkingJobDeclined,
            },
            _a[JobActionType.INVOICE] = {
                actionName: 'Invoice',
                actionBehaviour: invoiceJob,
                isActionProcessing: isRaisingInvoice,
            },
            _a[JobActionType.DELETE] = {
                actionName: 'Delete',
                actionBehaviour: deleteJob,
                isActionProcessing: isDeletingJob,
            },
            _a[JobActionType.SEND] = {
                actionName: 'Send',
                actionBehaviour: goToSendJob,
            },
            _a[JobActionType.RESEND] = {
                actionName: 'Resend',
                actionBehaviour: setResendJobId,
            },
            _a[JobActionType.RECORD_DEPOSIT_PAYMENT] = {
                actionName: 'Record payment',
                actionBehaviour: setRecordPaymentJobId,
            },
            _a[JobActionType.RECORD_PAYMENT] = {
                actionName: 'Record payment',
                actionBehaviour: setRecordPaymentJobId,
            },
            _a;
    }, [
        duplicateJob,
        isDuplicatingJob,
        markJobAccepted,
        isMarkingJobAccepted,
        markJobDeclined,
        isMarkingJobDeclined,
        invoiceJob,
        isRaisingInvoice,
        deleteJob,
        isDeletingJob,
        goToSendJob,
    ]);
    return (React.createElement("div", { className: "deal-overview" },
        !(jobs === null || jobs === void 0 ? void 0 : jobs.length) && (React.createElement(React.Fragment, null,
            React.createElement(TitledContentPanel, { title: "Proposals" },
                React.createElement("div", { className: "flex-container" },
                    React.createElement("span", null, "You have not added any proposals yet..."),
                    React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () { return history.push('/quotes/new'); } }, "Add new"))),
            React.createElement(TitledContentPanel, { title: "Invoices" },
                React.createElement("div", { className: "flex-container" },
                    React.createElement("span", null, "You have not added any invoices yet..."),
                    React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () { return history.push('/invoices/new'); } }, "Add new"))))),
        !!(proposals === null || proposals === void 0 ? void 0 : proposals.length) && (React.createElement(React.Fragment, null,
            React.createElement(TitledContentPanel, { title: "Proposals" },
                React.createElement(DealListedJobsTable, { dealId: dealId, jobs: proposals, quickActionDefinitions: quickActionDefinitions })),
            invoiceableProposalId && !(invoices === null || invoices === void 0 ? void 0 : invoices.length) && (React.createElement(TitledContentPanel, { title: "Invoices" },
                React.createElement("div", { className: "flex-container" },
                    React.createElement("span", null, "You have not added any invoices yet..."),
                    React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () {
                            return !isRaisingInvoice && invoiceJob(invoiceableProposalId);
                        }, isProcessing: isRaisingInvoice }, "Add new")))))),
        !!(invoices === null || invoices === void 0 ? void 0 : invoices.length) && (React.createElement(React.Fragment, null,
            React.createElement(TitledContentPanel, { title: "Invoices" },
                React.createElement(DealListedJobsTable, { dealId: dealId, jobs: invoices, quickActionDefinitions: quickActionDefinitions })))),
        resendJobId && (React.createElement(ResendJobModal, { isOpen: !!resendJobId, onClose: function () { return setResendJobId(undefined); }, jobId: resendJobId })),
        recordPaymentJobId && (React.createElement(RecordPaymentModal, { job: jobs.find(function (x) { return x.id === recordPaymentJobId; }), jobPayments: jobPayments, customer: customer, isOpen: !!recordPaymentJobId, onClose: function () { return setRecordPaymentJobId(undefined); }, recordPaymentCallback: function () { return onDealUpdateSuccess(recordPaymentJobId); }, confirmPaymentCallback: function () { return onDealUpdateSuccess(recordPaymentJobId); } }))));
};
