import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { BasicField } from '@payaca/components/basicField';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import { actions as authActions } from '@bit/payaca-tech.payaca-core.store.auth';
import './DevPage.sass';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
// DEV only page - register new oauth clients
var DevPage = function () {
    var dispatch = useDispatch();
    var _a = useState(''), clientName = _a[0], setClientName = _a[1];
    var _b = useState(''), redirectUrl = _b[0], setRedirectUrl = _b[1];
    var _c = useState(''), clientId = _c[0], setClientId = _c[1];
    var _d = useState(''), clientSecret = _d[0], setClientSecret = _d[1];
    var generateClient = function () {
        var payload = {
            clientName: clientName,
            redirectUrl: redirectUrl,
        };
        dispatch(authActions.registerOAuthClient(payload, function (err, resp) {
            if (resp) {
                setClientId(resp.clientId);
                setClientSecret(resp.clientSecret);
            }
        }));
    };
    return (React.createElement(AuthenticatedPageWrapper, { className: "dev-page", title: 'OAuth' },
        React.createElement("section", null,
            React.createElement("h3", null, "Generate a client id and secret to use Payaca OAuth in another app."),
            React.createElement(BasicField, { styleVariant: InputStyleVariant.OUTSIZE, label: "Client name", value: clientName, name: "clientName", onChange: function (value) {
                    return setClientName(value.clientName);
                } }),
            React.createElement(BasicField, { styleVariant: InputStyleVariant.OUTSIZE, label: "Redirect url", value: redirectUrl, name: "redirectUrl", onChange: function (value) {
                    return setRedirectUrl(value.redirectUrl);
                } }),
            React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, onClick: function () { return generateClient(); }, isDisabled: !clientName }, "Generate client id and secret"),
            React.createElement("div", null,
                React.createElement("span", null, "Client ID: "),
                clientId),
            React.createElement("div", null,
                React.createElement("span", null, "Client Secret: "),
                clientSecret))));
};
export default DevPage;
