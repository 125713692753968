import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './IncludedFeatureTable.sass';
var IncludedFeatureTable = function (_a) {
    var data = _a.data;
    return (React.createElement("div", { className: "included-feature-table" },
        React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("td", { className: "included-feature-title" }),
                React.createElement("td", { className: "plan-title" },
                    React.createElement("span", null, "Starter")),
                React.createElement("td", { className: "plan-title" },
                    React.createElement("span", null, "Professional")),
                React.createElement("td", { className: "plan-title" },
                    React.createElement("span", null, "Premium")),
                React.createElement("td", { className: "plan-title" },
                    React.createElement("span", null, "Enterprise"))),
            React.createElement("tbody", null, data
                .map(function (section) {
                var rows = [];
                if (section.label) {
                    rows.push(React.createElement("tr", null,
                        React.createElement("td", { className: "plan-feature-section", colSpan: 6 }, section.label)));
                }
                section.data.forEach(function (functionality) {
                    rows.push(React.createElement("tr", null,
                        React.createElement("td", { className: "plan-feature-title" }, functionality.title),
                        React.createElement("td", { className: "plan-feature-included" }, functionality.plans['starter'] === true ? (React.createElement(Check, null)) : (functionality.plans['starter'])),
                        React.createElement("td", { className: "plan-feature-included" }, functionality.plans['professional'] === true ? (React.createElement(Check, null)) : (functionality.plans['professional'])),
                        React.createElement("td", { className: "plan-feature-included" }, functionality.plans['premium'] === true ? (React.createElement(Check, null)) : (functionality.plans['premium'])),
                        React.createElement("td", { className: "plan-feature-included" }, functionality.plans['enterprise'] === true ? (React.createElement(Check, null)) : functionality.plans['enterprise'] === 'Unlimited' ? (React.createElement(Unlimited, null)) : (functionality.plans['enterprise']))));
                });
                return rows;
            })
                .flat()))));
};
var Unlimited = function () {
    return (React.createElement("div", null,
        React.createElement("span", { className: "unlimited-symbol" }, "\u221E"),
        React.createElement("span", { className: "unlimited-word" }, "Unlimited")));
};
var Check = function () {
    return (React.createElement("div", { className: "check-icon-wrapper" },
        React.createElement(FontAwesomeIcon, { icon: faCheck })));
};
export default IncludedFeatureTable;
