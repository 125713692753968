import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import './NewEditCustomerPage.sass';
import CreateEditCustomerControl from '@/ui/components/createEditCustomerControl/CreateEditCustomerControl';
import { actions as customerActions } from '@bit/payaca-tech.payaca-core.store.customer';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
var NewEditCustomerPage = function () {
    var dispatch = useDispatch();
    var params = useParams();
    var history = useHistory();
    var customerId = useMemo(function () { return params.customerId; }, [params]);
    var customer = useSelector(function (state) { return state.customer.currentCustomer; });
    var isGettingCustomer = useSelector(function (state) { return state.customer.isGettingCustomer; });
    useEffect(function () {
        dispatch(customerActions.clearCurrentCustomer());
        if (customerId) {
            dispatch(customerActions.requestGetAndSetCurrentCustomer(customerId));
        }
        return function () {
            dispatch(customerActions.clearCurrentCustomer());
        };
    }, [customerId]);
    return (React.createElement(AuthenticatedPageWrapper, { className: "new-edit-customer-page", title: customerId ? 'Edit customer' : 'New customer' },
        React.createElement(CreateEditCustomerControl, { inputStyleVariant: InputStyleVariant.OUTSIZE, customer: customer, onPersistCustomer: function () { return history.push('/customers'); } })));
};
export default NewEditCustomerPage;
