import React from 'react';
import './PreviewJobControl.sass';
import JobOverview from '../jobOverview/JobOverviewRetrievalWrapper';
import { ContentPanel } from '@payaca/components/contentPanel';
import PreviewJobControlWrapper from '../previewJobControlWrapper/PreviewJobControlWrapper';
import PreviewJobHeader from '../previewJobHeader/PreviewJobHeader';
import PreviewJobActionButtons from '../previewJobActionButtons/PreviewJobActionButtons';
import { useSelector } from 'react-redux';
import { getJob } from '@/utils/stateAccessors';
var PreviewJobControl = function (_a) {
    var jobId = _a.jobId, onJobUpdateSuccess = _a.onJobUpdateSuccess, markAsSentCallback = _a.markAsSentCallback;
    var job = useSelector(function (state) {
        return getJob(state, jobId);
    });
    return (React.createElement(PreviewJobControlWrapper, { className: "preview-job-control", previewContent: React.createElement(ContentPanel, { hasBoxShadow: true },
            React.createElement(JobOverview, { jobId: jobId })), title: React.createElement(PreviewJobHeader, { jobId: jobId }), sidebarContent: React.createElement(React.Fragment, null,
            (job === null || job === void 0 ? void 0 : job.isSentOffline) && (React.createElement("small", { className: "marked-as-sent-explainer-microcopy" }, "This job has been marked as sent - it has not been sent to the customer using the Payaca system.")),
            React.createElement(PreviewJobActionButtons, { jobId: jobId, onJobUpdateSuccess: onJobUpdateSuccess, markAsSentCallback: markAsSentCallback })) }));
};
export default PreviewJobControl;
