import React from 'react';
import './FinanceProvisionOptionOverview.sass';
import { ProcessFlow } from '@/ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
var FinanceProvisionOptionOverview = function (_a) {
    var isActiveOption = _a.isActiveOption, title = _a.title, processFlowSteps = _a.processFlowSteps, requirements = _a.requirements, benefits = _a.benefits, costIndicatorElement = _a.costIndicatorElement, additionalCostInformationElement = _a.additionalCostInformationElement, callToAction = _a.callToAction;
    return (React.createElement("div", { className: "finance-provision-option-overview" + (isActiveOption ? ' active' : '') },
        React.createElement("h3", { className: "title" }, title),
        React.createElement("div", { className: "finance-provision-option-overview-details" },
            React.createElement("hr", null),
            React.createElement("div", { className: "process-overview-container" },
                React.createElement(ProcessFlow, { processFlowSteps: processFlowSteps })),
            React.createElement("hr", null),
            React.createElement("div", { className: "requirements-container" },
                React.createElement("h4", null, "Requirements"),
                requirements.map(function (requirement, index) { return (React.createElement("div", { key: index, className: "requirement flex-container detail" },
                    React.createElement("span", { className: "icon-container" },
                        React.createElement(FontAwesomeIcon, { icon: faChevronRight })),
                    React.createElement("span", null, requirement))); })),
            React.createElement("hr", null),
            React.createElement("div", { className: "benefits-container" },
                React.createElement("h4", null, "Benefits"),
                benefits.map(function (benefit, index) { return (React.createElement("div", { key: index, className: "benefit flex-container detail" },
                    React.createElement("span", { className: "icon-container" },
                        React.createElement(FontAwesomeIcon, { icon: faCheck })),
                    React.createElement("span", null, benefit))); })),
            React.createElement("hr", null),
            React.createElement("div", { className: "costs-container" },
                React.createElement("div", { className: "flex-container flex-middle" },
                    React.createElement("h4", { className: "flex-grow" }, "Cost:"),
                    costIndicatorElement),
                additionalCostInformationElement),
            callToAction && !isActiveOption && (React.createElement("div", { className: "call-to-action-container" }, callToAction)))));
};
export default FinanceProvisionOptionOverview;
