import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import './ListedDealsTable.sass';
import MiniLoader from '@bit/payaca-tech.payaca-core.component.mini-loader';
import { PaginationControl } from '@payaca/components/paginationControl';
import EmptyState from '../emptyState/EmptyState';
import { SortBy } from '@payaca/types/listedDealTypes';
import { SortableColumnHeaderTableCell } from '@payaca/components/sortableColumnHeaderTableCell';
import { currencyPrice } from '@/helpers/financeHelper';
import { getReadableJobStatusStyle } from '@payaca/helpers/jobHelper';
import { Badge } from '@payaca/components/badge';
import { ResponsiveViewWrapper } from '@payaca/components/responsiveViewWrapper';
import { getCustomerAddressAsString } from '@payaca/helpers/customerHelper';
import { Button } from '@payaca/components';
import moment from 'moment';
import { DATE_SHORT_FORMAT } from '@payaca/constants';
var ListedDealsTable = function (_a) {
    var _b;
    var sortBy = _a.sortBy, sortDirection = _a.sortDirection, _c = _a.readableStatuses, readableStatuses = _c === void 0 ? [] : _c, _d = _a.types, types = _d === void 0 ? [] : _d, _e = _a.userAssignments, userAssignments = _e === void 0 ? [] : _e, searchTerm = _a.searchTerm, _f = _a.selectedDealIds, selectedDealIds = _f === void 0 ? [] : _f, onClickRow = _a.onClickRow, onSelectDeals = _a.onSelectDeals, onUnselectDeals = _a.onUnselectDeals, onSelectPage = _a.onSelectPage, onChangeSortOrder = _a.onChangeSortOrder, onSelectPageSize = _a.onSelectPageSize, onChangeFilters = _a.onChangeFilters;
    var listedDealsPage = useSelector(function (state) {
        var _a;
        return (_a = state.deals) === null || _a === void 0 ? void 0 : _a.listedDealsPage;
    });
    var canSelectDeals = useMemo(function () {
        return !!(onSelectDeals && onUnselectDeals);
    }, [onUnselectDeals, onSelectDeals]);
    var isGettingListedDealsPage = useSelector(function (state) { return state.deals.isGettingListedDealsPage; });
    var paginationControl = useMemo(function () {
        if (!listedDealsPage)
            return null;
        return (React.createElement(PaginationControl, { pageNumber: listedDealsPage.pageNumber, pageSize: listedDealsPage.pageSize, totalItemCount: listedDealsPage.totalItemCount, onSelectPage: onSelectPage, allowPageSizeSelection: true, availablePageSizes: [5, 20, 30, 50], onSelectPageSize: onSelectPageSize }));
    }, [listedDealsPage, onSelectPage]);
    var allDealIds = useMemo(function () {
        if (!(listedDealsPage === null || listedDealsPage === void 0 ? void 0 : listedDealsPage.items))
            return [];
        return listedDealsPage.items.map(function (listedDeal) { return listedDeal.dealId; });
    }, [listedDealsPage]);
    var isAllDealsSelected = useMemo(function () {
        return allDealIds.sort().join(',') === selectedDealIds.sort().join(',');
    }, [allDealIds, selectedDealIds]);
    var renderCustomerTableCell = function (listedDeal, rowSpan) {
        var customerContactDetails = [
            listedDeal.contactEmail,
            listedDeal.contactPhoneNumber,
        ].filter(function (x) { return !!x; });
        return (React.createElement("td", { className: "customer-table-cell", rowSpan: rowSpan },
            React.createElement("strong", { className: "customer-contact-name" },
                listedDeal.customerName,
                listedDeal.contactName &&
                    listedDeal.contactName !== listedDeal.customerName
                    ? ": " + listedDeal.contactName
                    : ''),
            !!customerContactDetails.length && (React.createElement("span", null, customerContactDetails.join(' | ')))));
    };
    var renderTableHead = useMemo(function () {
        return (React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement(SortableColumnHeaderTableCell, { align: "center", columnName: "Deal ref", selectedSortDirection: sortBy === SortBy.DEAL_REFERENCE ? sortDirection : undefined, onChangeSortDirection: function (sortDirection) {
                        return onChangeSortOrder(SortBy.DEAL_REFERENCE, sortDirection);
                    } }),
                React.createElement(SortableColumnHeaderTableCell, { columnName: "Customer", selectedSortDirection: sortBy === SortBy.CUSTOMER_NAME ? sortDirection : undefined, onChangeSortDirection: function (sortDirection) {
                        return onChangeSortOrder(SortBy.CUSTOMER_NAME, sortDirection);
                    } }),
                React.createElement("th", { className: "align-center" }, "Customer address"),
                React.createElement(SortableColumnHeaderTableCell, { align: "center", columnName: "Updated", selectedSortDirection: sortBy === SortBy.LAST_UPDATED ? sortDirection : undefined, onChangeSortDirection: function (sortDirection) {
                        return onChangeSortOrder(SortBy.LAST_UPDATED, sortDirection);
                    } }),
                React.createElement("th", { className: "align-center" }, "Type"),
                React.createElement("th", { className: "align-center" }, "Ref"),
                React.createElement("th", null, "Value"),
                React.createElement("th", null, "Due"),
                React.createElement("th", null, "Date"),
                React.createElement("th", null, "Status"))));
    }, [
        canSelectDeals,
        isAllDealsSelected,
        allDealIds,
        onUnselectDeals,
        onSelectDeals,
    ]);
    var clearFilters = useCallback(function () {
        return onChangeFilters({
            readableStatuses: [],
            types: [],
            userAssignments: [],
        });
    }, []);
    var renderTableRow = useCallback(function (listedDeal, index) {
        var _a;
        var listedDealJobsCount = (_a = listedDeal.jobSummaries) === null || _a === void 0 ? void 0 : _a.length;
        // do we have no jobs in this deal yet?
        if (!listedDealJobsCount) {
            return (React.createElement("tbody", { key: index, className: 'listed-deal-table-row-container clickable' },
                React.createElement("tr", { key: "" + index, onClick: function () { return onClickRow(listedDeal.dealId); } },
                    React.createElement("td", { rowSpan: 1, className: "align-center" },
                        React.createElement("strong", null, listedDeal.reference)),
                    renderCustomerTableCell(listedDeal, 1),
                    React.createElement("td", { rowSpan: 1 }, getCustomerAddressAsString(listedDeal.customerAddress)),
                    React.createElement("td", { rowSpan: 1 }, moment(listedDeal.lastUpdated).format(DATE_SHORT_FORMAT)),
                    React.createElement("td", null),
                    React.createElement("td", null),
                    React.createElement("td", null),
                    React.createElement("td", null),
                    React.createElement("td", null),
                    React.createElement("td", null))));
        }
        // we do have jobs within this deal
        return (React.createElement(React.Fragment, null,
            React.createElement("tbody", { className: 'listed-deal-table-row-container clickable', onClick: function () { return onClickRow(listedDeal.dealId); } }, listedDeal.jobSummaries.map(function (_a, i) {
                var type = _a.type, ref = _a.ref, value = _a.value, due = _a.due, status = _a.status, assignedToUserId = _a.assignedToUserId, validUntil = _a.validUntil, expires = _a.expires;
                var isFirstRow = i === 0;
                var _b = getReadableJobStatusStyle(status), colour = _b.colour, backgroundColour = _b.backgroundColour;
                // does this item match any filtering we may have applied?
                var statusMatch = readableStatuses.length && !readableStatuses.includes(status);
                var typeMatch = types.length && !types.includes(type);
                var userAssignmentMatch = userAssignments.length &&
                    assignedToUserId &&
                    !userAssignments.includes(assignedToUserId);
                var cellStyles = '';
                switch (true) {
                    case statusMatch:
                    case typeMatch:
                    case userAssignmentMatch:
                        cellStyles = 'unmatched-item';
                }
                var nextActionDate = 'N/A';
                if (validUntil || expires) {
                    nextActionDate = (validUntil ? 'Exp:' : 'Due:') + " " + moment(validUntil || expires).format(DATE_SHORT_FORMAT);
                }
                return (React.createElement("tr", { key: type + "-" + index },
                    isFirstRow && (React.createElement(React.Fragment, null,
                        React.createElement("td", { rowSpan: listedDealJobsCount, className: "align-center" },
                            React.createElement("strong", null, listedDeal.reference)),
                        renderCustomerTableCell(listedDeal, listedDealJobsCount),
                        React.createElement("td", { rowSpan: listedDealJobsCount, className: "align-center" }, getCustomerAddressAsString(listedDeal.customerAddress)),
                        React.createElement("td", { rowSpan: listedDealJobsCount }, moment(listedDeal.lastUpdated).format(DATE_SHORT_FORMAT)))),
                    React.createElement("td", { className: "align-center " + cellStyles }, type),
                    React.createElement("td", { className: "align-center " + cellStyles },
                        React.createElement("strong", null, ref)),
                    React.createElement("td", { className: cellStyles }, currencyPrice(value)),
                    React.createElement("td", { className: cellStyles }, currencyPrice(due)),
                    React.createElement("td", { className: cellStyles }, nextActionDate),
                    React.createElement("td", { className: cellStyles },
                        React.createElement(Badge, { backgroundColour: backgroundColour, colour: colour }, status))));
            }))));
    }, [
        canSelectDeals,
        selectedDealIds,
        onSelectDeals,
        onUnselectDeals,
        readableStatuses,
        types,
        userAssignments,
    ]);
    return (React.createElement(ResponsiveViewWrapper, { className: "listed-deals-table", downBreakpointSm: 800 },
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: "scrollable-table-wrapper styled-table-wrapper" },
                React.createElement("table", { className: "styled-table" },
                    renderTableHead,
                    isGettingListedDealsPage && (React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", { colSpan: 10, className: "tr-exclude" },
                                React.createElement("div", { className: "loader-container" },
                                    React.createElement(MiniLoader, null)))))),
                    !isGettingListedDealsPage &&
                        !(listedDealsPage === null || listedDealsPage === void 0 ? void 0 : listedDealsPage.totalItemCount) &&
                        !(listedDealsPage === null || listedDealsPage === void 0 ? void 0 : listedDealsPage.totalUnfilteredItemCount) && (React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", { colSpan: 10, className: "tr-exclude" },
                                React.createElement(EmptyState, { configName: 'deals', arrowIndication: false }))))),
                    !isGettingListedDealsPage &&
                        !(listedDealsPage === null || listedDealsPage === void 0 ? void 0 : listedDealsPage.totalItemCount) &&
                        !!(listedDealsPage === null || listedDealsPage === void 0 ? void 0 : listedDealsPage.totalUnfilteredItemCount) && (React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", { colSpan: 10, className: "tr-exclude" },
                                React.createElement("div", { className: "no-results-container" }, searchTerm ? (React.createElement("span", null, "There are no deals that match your search.")) : (React.createElement(React.Fragment, null,
                                    React.createElement("span", null, "There are no deals that match the filters you have applied."),
                                    React.createElement(Button, { onClick: clearFilters }, "Clear filters")))))))),
                    !isGettingListedDealsPage && !!((_b = listedDealsPage === null || listedDealsPage === void 0 ? void 0 : listedDealsPage.items) === null || _b === void 0 ? void 0 : _b.length) && (React.createElement(React.Fragment, null, listedDealsPage.items.map(renderTableRow))))),
            paginationControl)));
};
export default ListedDealsTable;
