import React from 'react';

import Page, { Content } from '@/ui/components/Page';
import LoaderOverlay from '@bit/payaca-tech.payaca-core.component.loader-overlay';

const SplashPage = () => {
  return (
    <Page>
      <Content>
        <LoaderOverlay />
      </Content>
    </Page>
  );
};

export default SplashPage;
