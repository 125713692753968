var _a;
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { DropdownField } from '@payaca/components/dropdownField';
var VatTypes;
(function (VatTypes) {
    VatTypes["ZERO_PERCENT"] = "zero-percent";
    VatTypes["FIVE_PERCENT"] = "five-percent";
    VatTypes["TWENTY_PERCENT"] = "twenty-percent";
    VatTypes["FIVE_PERCENT_REVERSE_CHARGE"] = "five-percent-reverse-charge";
    VatTypes["TWENTY_PERCENT_REVERSE_CHARGE"] = "twenty-percent-reverse-charge";
    VatTypes["NONE"] = "none";
})(VatTypes || (VatTypes = {}));
var VAT_OPTIONS = [
    {
        label: 'VAT 20%',
        value: VatTypes.TWENTY_PERCENT,
    },
    {
        label: 'VAT 5%',
        value: VatTypes.FIVE_PERCENT,
    },
    {
        label: 'VAT 0%',
        value: VatTypes.ZERO_PERCENT,
    },
    {
        label: 'VAT 5% rc',
        value: VatTypes.FIVE_PERCENT_REVERSE_CHARGE,
    },
    {
        label: 'VAT 20% rc',
        value: VatTypes.TWENTY_PERCENT_REVERSE_CHARGE,
    },
    {
        label: 'No VAT',
        value: VatTypes.NONE,
    },
];
var VAT_SETTINGS = (_a = {},
    _a[VatTypes.TWENTY_PERCENT] = {
        vatAmount: 20,
        vatIncluded: true,
        isReverseChargeVat: false,
    },
    _a[VatTypes.FIVE_PERCENT] = {
        vatAmount: 5,
        vatIncluded: true,
        isReverseChargeVat: false,
    },
    _a[VatTypes.ZERO_PERCENT] = {
        vatAmount: 0,
        vatIncluded: true,
        isReverseChargeVat: false,
    },
    _a[VatTypes.NONE] = {
        vatAmount: null,
        vatIncluded: false,
        isReverseChargeVat: false,
    },
    _a[VatTypes.TWENTY_PERCENT_REVERSE_CHARGE] = {
        vatAmount: 20,
        vatIncluded: true,
        isReverseChargeVat: true,
    },
    _a[VatTypes.FIVE_PERCENT_REVERSE_CHARGE] = {
        vatAmount: 5,
        vatIncluded: true,
        isReverseChargeVat: true,
    },
    _a);
var VatSettingsField = function (_a) {
    var styleVariant = _a.styleVariant, vatAmount = _a.vatAmount, isReverseChargeVat = _a.isReverseChargeVat, isVatIncluded = _a.isVatIncluded, onChange = _a.onChange, onTouch = _a.onTouch;
    var _b = useState(), selectedVatType = _b[0], setSelectedVatType = _b[1];
    useEffect(function () {
        var vatSettings = {
            vatAmount: isVatIncluded ? vatAmount : null,
            vatIncluded: isVatIncluded,
            isReverseChargeVat: isReverseChargeVat,
        };
        var vatType = Object.keys(VAT_SETTINGS).find(function (key) {
            return _.isEqual(VAT_SETTINGS[key], vatSettings);
        });
        setSelectedVatType(vatType);
    }, [vatAmount, isReverseChargeVat, isVatIncluded]);
    return (React.createElement(DropdownField, { styleVariant: styleVariant, label: 'VAT settings', value: selectedVatType || null, name: "vatSettings", onChange: function (value) {
            onChange && onChange(VAT_SETTINGS[value.vatSettings]);
        }, onTouch: function () {
            onTouch && onTouch('vatAmount');
            onTouch && onTouch('isReverseChargeVat');
            onTouch && onTouch('vatIncluded');
        }, options: VAT_OPTIONS }));
};
export default VatSettingsField;
