import React, { useMemo, useState } from 'react';
import { LoginProvider } from '@/helpers/providerHelper';
import './InitialRegisterControl.sass';
import SocialLoginButton from '../socialLoginButton/SocialLoginButton';
import InitialRegisterForm from '../initialRegisterForm/InitialRegisterForm';
import { getFormServiceError } from '@/helpers/formHelper';
import { ErrorMessage } from '@payaca/components/feedbackMessage';
var socialLoginProviders = [
    LoginProvider.FACEBOOK,
    LoginProvider.GOOGLE,
    LoginProvider.XERO,
    LoginProvider.APPLE,
];
var InitialRegisterControl = function (_a) {
    var referralToken = _a.referralToken, onInitialRegisterFormSubmit = _a.onInitialRegisterFormSubmit, onRegisterCallback = _a.onRegisterCallback;
    var _b = useState(), registerErrorMessage = _b[0], setRegisterErrorMessage = _b[1];
    var socialRegisterButtons = useMemo(function () {
        return socialLoginProviders.map(function (socialLoginProvider, index) {
            return (React.createElement(SocialLoginButton, { key: "social-login-button-" + index, provider: socialLoginProvider, buttonPreText: 'Sign up with', referralToken: referralToken, loginServiceCallback: function (error) {
                    setRegisterErrorMessage(undefined);
                    if (error) {
                        setRegisterErrorMessage(getFormServiceError('register', error));
                    }
                    onRegisterCallback && onRegisterCallback(error);
                } }));
        });
    }, [referralToken, onRegisterCallback]);
    return (React.createElement("div", { className: "initial-register-control" },
        React.createElement("div", { className: "social-register-buttons-container" },
            socialRegisterButtons,
            registerErrorMessage && (React.createElement(ErrorMessage, { message: registerErrorMessage }))),
        React.createElement("div", { className: "flex-container flex-center register-with-email-prompt" },
            React.createElement("hr", null),
            React.createElement("span", null, "Or sign up with email"),
            React.createElement("hr", null)),
        React.createElement("div", null,
            React.createElement(InitialRegisterForm, { referralToken: referralToken, onSubmit: onInitialRegisterFormSubmit }))));
};
export default InitialRegisterControl;
