import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import './NewEditItemPage.css';
import CreateEditLineItemControl from '@/ui/components/createEditLineItemControl/CreateEditLineItemControl';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
var NewEditItemPage = function () {
    var params = useParams();
    var itemId = useMemo(function () { return params.itemId; }, [params]);
    return (React.createElement(AuthenticatedPageWrapper, { className: "new-edit-item", title: itemId ? 'Edit item' : 'New item' },
        React.createElement("div", { className: "create-edit-line-item-control-container" },
            React.createElement(CreateEditLineItemControl, { lineItemId: itemId }))));
};
export default NewEditItemPage;
