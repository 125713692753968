import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import './ListedDealsFilterControls.sass';
import { DropdownField } from '@payaca/components/dropdownField';
import { ReadableJobStatus } from '@bit/payaca-tech.payaca-core.types.job';
import ResponsiveViewWrapper from '@bit/payaca-tech.payaca-core.component.responsive-view-wrapper';
import { InputColourVariant, InputStyleVariant, } from '@payaca/components/inputWrapper';
import { ButtonColourVariant, IconButton } from '@payaca/components/button';
import { faTimes, faChevronDown } from '@fortawesome/free-solid-svg-icons';
var ListedDealsFilterControls = function (_a) {
    var getListedDealsRequestData = _a.getListedDealsRequestData, onChange = _a.onChange;
    var accountUsers = useSelector(function (state) {
        return state.users.accountUsers;
    });
    var isAdmin = useSelector(function (state) { return state.users.myProfile.isAdmin; });
    var clearFilters = useCallback(function () {
        return onChange({
            userAssignments: [],
            readableStatuses: [],
            types: [],
        });
    }, []);
    var _b = useMemo(function () { return getListedDealsRequestData || {}; }, [getListedDealsRequestData]), _c = _b.userAssignments, userAssignments = _c === void 0 ? [] : _c, _d = _b.readableStatuses, readableStatuses = _d === void 0 ? [] : _d, _e = _b.types, types = _e === void 0 ? [] : _e;
    var isZeroPercentAvailable = useSelector(function (state) {
        var _a, _b, _c, _d, _e, _f, _g;
        var isApproved = !!((_d = (_c = (_b = (_a = state.users.myProfile) === null || _a === void 0 ? void 0 : _a.accounts[0]) === null || _b === void 0 ? void 0 : _b.integrations) === null || _c === void 0 ? void 0 : _c.zeroPercentFinance) === null || _d === void 0 ? void 0 : _d.isApproved);
        var isFeatureAvailable = (_g = (_f = (_e = state.account) === null || _e === void 0 ? void 0 : _e.accountAccessInformation) === null || _f === void 0 ? void 0 : _f.features) === null || _g === void 0 ? void 0 : _g.hasZeroPercentFinance;
        return isApproved && isFeatureAvailable;
    });
    var _f = useMemo(function () {
        var readableJobStatuses = [
            { label: ReadableJobStatus.ACCEPTED, optionGroupId: 1 },
            { label: ReadableJobStatus.BOUNCED, optionGroupId: 1 },
            { label: ReadableJobStatus.DECLINED, optionGroupId: 1 },
            { label: ReadableJobStatus.DEPOSIT_DUE, optionGroupId: 1 },
            { label: ReadableJobStatus.DEPOSIT_PAID, optionGroupId: 1 },
            { label: ReadableJobStatus.DRAFT, optionGroupId: 1 },
            { label: ReadableJobStatus.VIEWED, optionGroupId: 1 },
            { label: ReadableJobStatus.SENT, optionGroupId: 1 },
            { label: ReadableJobStatus.PAYMENT_PENDING, optionGroupId: 1 },
            { label: ReadableJobStatus.PAID, optionGroupId: 1 },
            { label: ReadableJobStatus.OVERDUE, optionGroupId: 1 },
            { label: ReadableJobStatus.ARCHIVED, optionGroupId: 1 },
        ];
        var readableStatusOptionGroups = [{ id: 1 }];
        if (isZeroPercentAvailable) {
            readableJobStatuses = readableJobStatuses.concat([
                { label: ReadableJobStatus.FINANCE_APPROVED, optionGroupId: 2 },
                { label: ReadableJobStatus.FINANCE_COMPLETE, optionGroupId: 2 },
                { label: ReadableJobStatus.FINANCE_REJECTED, optionGroupId: 2 },
                { label: ReadableJobStatus.FINANCE_SIGNED, optionGroupId: 2 },
                { label: ReadableJobStatus.JOB_COMPLETE, optionGroupId: 2 },
                { label: ReadableJobStatus.JOB_UNSATISFACTORY, optionGroupId: 2 },
                { label: ReadableJobStatus.PAYOUT_APPROVED, optionGroupId: 2 },
            ]);
            readableStatusOptionGroups = readableStatusOptionGroups.concat([
                { id: 2, name: 'Finance' },
            ]);
        }
        var readableStatusOptions = readableJobStatuses.map(function (_a) {
            var label = _a.label, optionGroupId = _a.optionGroupId;
            return {
                label: label,
                value: label,
                optionGroupId: optionGroupId,
            };
        });
        return { readableStatusOptions: readableStatusOptions, readableStatusOptionGroups: readableStatusOptionGroups };
    }, [isZeroPercentAvailable]), readableStatusOptions = _f.readableStatusOptions, readableStatusOptionGroups = _f.readableStatusOptionGroups;
    var listedDealItemTypeOptions = useMemo(function () {
        var typeOptions = ['Estimate', 'Quote', 'Invoice'];
        return typeOptions.map(function (option) { return ({
            label: option,
            value: option,
        }); });
    }, []);
    var userAssignmentOptions = useMemo(function () {
        var options = accountUsers
            .filter(function (x) {
            return !x.deactivatedAt || x.inviteToken;
        })
            .map(function (accountUser) {
            return {
                label: accountUser.firstname + " " + accountUser.lastname,
                value: accountUser.id,
            };
        });
        options.unshift({
            label: 'Unassigned',
            value: 'unassigned',
        });
        return options;
    }, [accountUsers]);
    var userAssignmentsRenderValue = useCallback(function (value) {
        if (value && value.length > 1) {
            return 'Multiple selected';
        }
        return value && value.length
            ? value
                .map(function (userId) {
                if (userId === 'unassigned') {
                    return 'Unassigned';
                }
                else {
                    var accountUser = accountUsers.find(function (accountUser) { return accountUser.id === userId; });
                    if (!accountUser)
                        return null;
                    return accountUser.firstname + " " + accountUser.lastname;
                }
            })
                .filter(function (x) { return x != null; })
                .join(', ')
            : 'Assigned User';
    }, [accountUsers]);
    var renderedFilters = useMemo(function () {
        return (React.createElement(ResponsiveViewWrapper, { className: "flex-container filters-wrapper", downBreakpointSm: 1290 },
            React.createElement(React.Fragment, null,
                React.createElement("label", { className: "filter-by-label" }, "Filter by:"),
                isAdmin && (React.createElement("div", { className: "filter-container" },
                    React.createElement(DropdownField, { value: userAssignments, name: "userAssignments", options: userAssignmentOptions, onChange: onChange, multiple: true, additionalSelectProps: {
                            renderValue: userAssignmentsRenderValue,
                            displayEmpty: true,
                        }, styleVariant: InputStyleVariant.STANDARD, colourVariant: InputColourVariant.SECONDARY, hasBoxShadow: false, iconAfter: faChevronDown, displayCheckboxes: true }))),
                React.createElement("div", { className: "filter-container" },
                    React.createElement(DropdownField, { value: readableStatuses, name: "readableStatuses", optionGroups: readableStatusOptionGroups, options: readableStatusOptions, onChange: onChange, multiple: true, additionalSelectProps: {
                            renderValue: function (value, label) {
                                if (value && value.length > 1) {
                                    return 'Multiple selected';
                                }
                                return value && value.length ? value.join(', ') : 'Status';
                            },
                            displayEmpty: true,
                        }, styleVariant: InputStyleVariant.STANDARD, colourVariant: InputColourVariant.SECONDARY, hasBoxShadow: false, iconAfter: faChevronDown, displayCheckboxes: true })),
                React.createElement("div", { className: "filter-container" },
                    React.createElement(DropdownField, { value: types, name: "types", options: listedDealItemTypeOptions, onChange: onChange, multiple: true, additionalSelectProps: {
                            renderValue: function (value, label) {
                                if (value && value.length > 1) {
                                    return 'Multiple selected';
                                }
                                return value && value.length ? value.join(', ') : 'Type';
                            },
                            displayEmpty: true,
                        }, styleVariant: InputStyleVariant.STANDARD, colourVariant: InputColourVariant.SECONDARY, hasBoxShadow: false, iconAfter: faChevronDown, displayCheckboxes: true })),
                (userAssignments.length > 0 ||
                    readableStatuses.length > 0 ||
                    types.length > 0) && (React.createElement("div", { className: 'clear-filters', style: { position: 'relative', display: 'inline-block' } },
                    React.createElement(IconButton, { icon: faTimes, size: 'sm', colourVariant: ButtonColourVariant.GREY, onClick: clearFilters }),
                    React.createElement("div", { className: 'clear-filters-tooltip' }, "Clear filters"))))));
    }, [
        userAssignmentsRenderValue,
        onChange,
        userAssignmentOptions,
        getListedDealsRequestData,
        readableStatusOptions,
    ]);
    return (React.createElement(ResponsiveViewWrapper, { className: "listed-deals-filter-controls", downBreakpointSm: 850 },
        React.createElement("div", { className: "static-body-container flex-container flex-center" },
            React.createElement("div", { className: "filters-container" }, renderedFilters))));
};
export default ListedDealsFilterControls;
