import React from 'react';
import RadioButton from './RadioButton';
var RadioButtons = function (_a) {
    var items = _a.items, onChange = _a.onChange, value = _a.value;
    return items.map(function (item, index) {
        var isSelected = item.value === value || item.altSelected;
        var onClick = function () { return onChange(item.value); };
        return (React.createElement(RadioButton, { key: "item-" + index, isSelected: isSelected, onClick: onClick, label: item.label }));
    });
};
export default RadioButtons;
