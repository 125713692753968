import React, { useCallback } from 'react';
import './CheckboxField.css';
import Checkbox from '../checkbox/Checkbox';
import FieldLabel from '../fieldLabel/FieldLabel';
var CheckboxField = function (_a) {
    var name = _a.name, _b = _a.value, value = _b === void 0 ? false : _b, label = _a.label, description = _a.description, isDisabled = _a.isDisabled, isRequired = _a.isRequired, onChange = _a.onChange, onTouch = _a.onTouch;
    var onCheckboxChange = useCallback(function () {
        var _a;
        onChange && onChange((_a = {}, _a[name] = !value, _a));
        onTouch && onTouch(name);
    }, [onChange, onTouch, value, name]);
    return (React.createElement("div", { className: "checkbox-field" },
        React.createElement("div", { className: "checkbox-wrapper" },
            React.createElement(Checkbox, { isChecked: value, isDisabled: isDisabled, onChange: onCheckboxChange })),
        React.createElement(FieldLabel, { label: label, description: description, isRequired: isRequired })));
};
export default CheckboxField;
