import React, { useMemo } from 'react';
import './JobAssignmentDropdown.css';
import { useSelector } from 'react-redux';
import { DropdownField } from '@payaca/components/dropdownField';
var JobAssignmentDropdown = function (_a) {
    var _b = _a.currentAssignedUser, currentAssignedUser = _b === void 0 ? undefined : _b, onAssignedUserChange = _a.onAssignedUserChange;
    var accountUsers = useSelector(function (state) { return state.users.accountUsers; });
    var selectItems = useMemo(function () {
        var items = accountUsers
            .filter(function (x) {
            return !x.deactivatedAt || x.inviteToken;
        })
            .map(function (user) {
            return {
                label: user.name,
                value: user.id,
            };
        });
        items.push({ label: 'Unassigned', value: '' });
        return items;
    }, [accountUsers]);
    return accountUsers.length ? (React.createElement("div", { className: "job-assignment-control" }, accountUsers.length && (React.createElement(DropdownField
    // styleVariant={InputStyleVariant.OUTSIZE}
    , { 
        // styleVariant={InputStyleVariant.OUTSIZE}
        options: selectItems, label: 'Assigned to', name: "assignedUserId", onChange: function (value) {
            return onAssignedUserChange(value.assignedUserId);
        }, value: (currentAssignedUser === null || currentAssignedUser === void 0 ? void 0 : currentAssignedUser.id) ? currentAssignedUser.id
            : currentAssignedUser || '' })))) : null;
};
export default JobAssignmentDropdown;
