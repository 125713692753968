var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect, useState, useCallback, useMemo, useRef, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { faChevronRight, faPoundSign } from '@fortawesome/free-solid-svg-icons';
import { actions as subsidisedFinanceInformationActions } from '@payaca/store/subsidisedFinanceInformation';
import { getFieldValidatorsForFinancialInformation } from '@payaca/helpers/subsidisedFinanceInformationValidationHelper';
import { Checkbox } from '@payaca/components/checkbox';
import { MiniLoader, ResponsiveViewWrapper, ValidatedForm, } from '@payaca/components';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { TextareaField } from '@payaca/components/textareaField';
import { BasicField } from '@payaca/components/basicField';
import { TitledContentPanel } from '@payaca/components/titledContentPanel';
import ProductsFormSubsection from '../productsFormSubsection/ProductsFormSubsection';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import './FinancialInformationFormSection.sass';
function usePrevious(value) {
    var ref = useRef();
    useEffect(function () {
        ref.current = value;
    });
    return ref.current;
}
var FinancialInformationFormSection = function () {
    var dispatch = useDispatch();
    var persistedFinancialInformation = useSelector(function (state) {
        var _a;
        return (_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.financialInformation;
    });
    var isGettingOrPersisting = useSelector(function (state) {
        var _a, _b;
        return (((_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.isGettingFinancialInformation) || ((_b = state.subsidisedFinanceInformation) === null || _b === void 0 ? void 0 : _b.isPersistingFinancialInformation));
    });
    var financialInformation = useMemo(function () {
        var _a;
        var financialInformation = persistedFinancialInformation || {};
        if (!((_a = financialInformation === null || financialInformation === void 0 ? void 0 : financialInformation.products) === null || _a === void 0 ? void 0 : _a.length)) {
            financialInformation.products = [{}];
        }
        return financialInformation;
    }, [persistedFinancialInformation]);
    var previousFinancialInformation = usePrevious(financialInformation);
    var _a = useState(0), productsCount = _a[0], setProductsCount = _a[1];
    var _b = useState(), triggerValidationAt = _b[0], setTriggerValidationAt = _b[1];
    var fieldValidators = useMemo(function () {
        return getFieldValidatorsForFinancialInformation(productsCount);
    }, [productsCount]);
    var onProductAdd = useCallback(function (products, onFieldChange) {
        onFieldChange({
            products: __spreadArrays((products !== null && products !== void 0 ? products : []), [{}]),
        });
        setProductsCount(function (productsCount) { return productsCount + 1; });
    }, []);
    var onProductRemove = useCallback(function (productIndex, products, onFieldChange) {
        products.splice(productIndex, 1);
        onFieldChange({ products: products });
        setProductsCount(function (productsCount) { return productsCount - 1; });
    }, []);
    useEffect(function () {
        var _a;
        if (financialInformation &&
            !_.isEqual(financialInformation, previousFinancialInformation)) {
            setProductsCount(((_a = financialInformation === null || financialInformation === void 0 ? void 0 : financialInformation.products) === null || _a === void 0 ? void 0 : _a.length) || 0);
        }
    }, [financialInformation]);
    useEffect(function () {
        dispatch(subsidisedFinanceInformationActions.requestGetFinancialInformation());
    }, []);
    var onSaveProgress = useCallback(function (modifiedFinancialInformation) {
        dispatch(subsidisedFinanceInformationActions.requestPersistFinancialInformation(modifiedFinancialInformation));
    }, [dispatch]);
    var onSaveAndContinue = useCallback(function (modifiedFinancialInformation) {
        dispatch(subsidisedFinanceInformationActions.requestPersistCompleteFinancialInformation(modifiedFinancialInformation));
    }, [dispatch]);
    var renderActionButtons = useCallback(function (isValid, formState) {
        var modifiedFinancialInformation = formState;
        return (React.createElement("div", { className: "action-buttons-container" }, isGettingOrPersisting ? (React.createElement("div", { className: "loader-container flex-grow" },
            React.createElement(MiniLoader, null))) : (React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: function () { return onSaveProgress(modifiedFinancialInformation); }, styleVariant: ButtonStyleVariant.OUTSIZE, isOutlined: true }, 'Save progress'),
            React.createElement("div", { onClick: function () { return !isValid && setTriggerValidationAt(new Date()); } },
                React.createElement(Button, { isDisabled: !isValid, onClick: function () {
                        return isValid && onSaveAndContinue(modifiedFinancialInformation);
                    }, styleVariant: ButtonStyleVariant.OUTSIZE, iconAfter: faChevronRight }, 'Save and continue'))))));
    }, [onSaveProgress, onSaveAndContinue, isGettingOrPersisting]);
    var renderProductsFormSection = useCallback(function (formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        var _a, _b, _c;
        return (React.createElement("div", { className: "field-group" },
            React.createElement(TitledContentPanel, { title: "Please provide information about your top selling products/services (up to 3)", hasBoxShadow: true },
                React.createElement(React.Fragment, null,
                    React.createElement(ProductsFormSubsection, { products: formState.products, fieldNamePrefix: "products", validationState: validationState, touchedState: touchedState, onTouch: onFieldTouch, onChange: onFieldChange, onProductAdd: function () {
                            return onProductAdd(formState.products, onFieldChange);
                        }, onProductRemove: function (productIndex) {
                            return onProductRemove(productIndex, formState.products, onFieldChange);
                        } }),
                    !((_a = validationState === null || validationState === void 0 ? void 0 : validationState.products) === null || _a === void 0 ? void 0 : _a.isValid) && ((_c = (_b = validationState === null || validationState === void 0 ? void 0 : validationState.products) === null || _b === void 0 ? void 0 : _b.errors) === null || _c === void 0 ? void 0 : _c.map(function (error, index) {
                        return (React.createElement("p", { className: "validation-error", key: index }, error));
                    }))))));
    }, [onProductAdd, onProductRemove]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "field-group" },
                React.createElement("div", { className: "checkbox-container flex-container flex-center" },
                    React.createElement("div", null,
                        React.createElement(Checkbox, { isChecked: formState.hasCurrentCreditServiceProvider || false, onChange: function () {
                                onFieldChange({
                                    hasCurrentCreditServiceProvider: !formState.hasCurrentCreditServiceProvider,
                                });
                                onFieldTouch('hasCurrentCreditServiceProvider');
                            } })),
                    React.createElement("span", null, "Do you currently have a credit service provider?")),
                formState.hasCurrentCreditServiceProvider && (React.createElement(ValidatedFieldWrapper, { validationResult: validationState.creditServiceProviders, isTouched: touchedState.creditServiceProviders || false },
                    React.createElement(TextareaField, { label: "List any companies who currently or have previously provided your company with credit facilities for your customers", value: formState.creditServiceProviders || '', name: 'creditServiceProviders', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch })))),
            React.createElement("div", { className: "field-group" },
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.next12MonthsBusinessValueUsingCreditTerms, isTouched: touchedState.next12MonthsBusinessValueUsingCreditTerms || false },
                    React.createElement(BasicField, { label: "How much business do you expect to write using credit terms in the next 12 months?", value: formState.next12MonthsBusinessValueUsingCreditTerms || '', isRequired: true, name: 'next12MonthsBusinessValueUsingCreditTerms', type: "number", onChange: onFieldChange, onTouch: onFieldTouch, iconBefore: faPoundSign, styleVariant: InputStyleVariant.OUTSIZE })),
                React.createElement("div", { className: "flex-container" },
                    React.createElement(ValidatedFieldWrapper, { validationResult: validationState.numberOfBranches, isTouched: touchedState.numberOfBranches || false },
                        React.createElement(BasicField, { label: "Number of branches", value: formState.numberOfBranches || '', name: 'numberOfBranches', type: "number", isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, styleVariant: InputStyleVariant.OUTSIZE })),
                    React.createElement(ValidatedFieldWrapper, { validationResult: validationState.numberOfSalespeople, isTouched: touchedState.numberOfSalespeople || false },
                        React.createElement(BasicField, { label: "Number of salespeople", value: formState.numberOfSalespeople || '', name: 'numberOfSalespeople', type: "number", isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, styleVariant: InputStyleVariant.OUTSIZE })))),
            renderProductsFormSection(formState, validationState, touchedState, onFieldChange, onFieldTouch),
            renderActionButtons(isValid, formState)));
    }, [renderProductsFormSection, renderActionButtons]);
    return (React.createElement(ResponsiveViewWrapper, { className: "financial-information-form-section", downBreakpointSm: 600 },
        React.createElement("div", { style: { position: 'relative' } },
            React.createElement(ValidatedForm, { triggerValidationAt: triggerValidationAt, initialFormState: financialInformation, fieldValidators: fieldValidators, renderFormContents: renderFormContents }))));
};
export default FinancialInformationFormSection;
