import { action } from 'typesafe-actions';
import { AuthActionTypes, } from './authTypes';
export var authorise = function (payload, allow, callback) {
    return action(AuthActionTypes.AUTHORISE, { payload: payload, allow: allow, callback: callback });
};
export var logout = function () {
    return action(AuthActionTypes.LOGOUT);
};
export var clearAnalyticsUserId = function () {
    return action(AuthActionTypes.CLEAR_ANALYTICS_USER_ID);
};
export var registerOAuthClient = function (payload, callback) {
    return action(AuthActionTypes.REGISTER_O_AUTH_CLIENT, { payload: payload, callback: callback });
};
export var removeOAuthConnection = function (payload, callback) {
    return action(AuthActionTypes.REMOVE_O_AUTH_CONNECTION, {
        payload: payload,
        callback: callback,
    });
};
export var verifyOAuth = function (payload, callback) {
    return action(AuthActionTypes.VERIFY_O_AUTH, { payload: payload, callback: callback });
};
export var requestLogin = function (email, password, isPayacaAdminLogin) {
    if (isPayacaAdminLogin === void 0) { isPayacaAdminLogin = false; }
    return action(AuthActionTypes.REQUEST_LOGIN, {
        email: email,
        password: password,
        isPayacaAdminLogin: isPayacaAdminLogin,
    });
};
export var loginSuccess = function (token, refreshToken) {
    return action(AuthActionTypes.LOGIN_SUCCESS, {
        token: token,
        refreshToken: refreshToken,
    });
};
export var loginFailure = function (error) {
    return action(AuthActionTypes.LOGIN_FAILURE, null, null, error);
};
export var signUpSuccess = function (token, refreshToken) {
    return action(AuthActionTypes.SIGN_UP_SUCCESS, {
        token: token,
        refreshToken: refreshToken,
    });
};
export var signUpFailure = function (error) {
    return action(AuthActionTypes.SIGN_UP_FAILURE, null, null, error);
};
export var requestRefreshAuthToken = function () {
    return action(AuthActionTypes.REQUEST_REFRESH_AUTH_TOKEN);
};
export var refreshAuthTokenSuccess = function (token, refreshToken) {
    return action(AuthActionTypes.REFRESH_AUTH_TOKEN_SUCCESS, {
        token: token,
        refreshToken: refreshToken,
    });
};
export var refreshAuthTokenFailure = function (error) {
    return action(AuthActionTypes.REFRESH_AUTH_TOKEN_FAILURE, null, null, error);
};
export var storeTokens = function (token, refreshToken) {
    return action(AuthActionTypes.STORE_TOKENS, {
        token: token,
        refreshToken: refreshToken,
    });
};
export var storeTokensSuccess = function () {
    return action(AuthActionTypes.STORE_TOKENS_SUCCESS);
};
export var requestLoginWithApple = function (data) {
    return action(AuthActionTypes.REQUEST_LOGIN_WITH_APPLE, {
        data: data,
    });
};
export var requestLoginWithGoogle = function (data) {
    return action(AuthActionTypes.REQUEST_LOGIN_WITH_GOOGLE, {
        data: data,
    });
};
export var requestLoginWithFacebook = function (data) {
    return action(AuthActionTypes.REQUEST_LOGIN_WITH_FACEBOOK, {
        data: data,
    });
};
export var requestLoginWithXero = function (data) {
    return action(AuthActionTypes.REQUEST_LOGIN_WITH_XERO, {
        data: data,
    });
};
export var requestLoginWithToken = function (token) {
    return action(AuthActionTypes.REQUEST_LOGIN_WITH_TOKEN, {
        token: token,
    });
};
export var requestSignUpWithApple = function (data) {
    return action(AuthActionTypes.REQUEST_SIGN_UP_WITH_APPLE, {
        data: data,
    });
};
export var requestSignUpWithGoogle = function (data) {
    return action(AuthActionTypes.REQUEST_SIGN_UP_WITH_GOOGLE, {
        data: data,
    });
};
export var requestSignUpWithFacebook = function (data) {
    return action(AuthActionTypes.REQUEST_SIGN_UP_WITH_FACEBOOK, {
        data: data,
    });
};
export var requestSignUpWithXero = function (data) {
    return action(AuthActionTypes.REQUEST_SIGN_UP_WITH_XERO, {
        data: data,
    });
};
