import React from 'react';
import { Modal } from '@payaca/components/modal';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
var ConfirmDeleteUserModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onConfirmDeleteUser = _a.onConfirmDeleteUser;
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose, title: "Delete user", size: "xs", actions: React.createElement(React.Fragment, null,
            React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, onClick: onConfirmDeleteUser }, "Delete"),
            React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: onClose }, "Cancel")) },
        React.createElement("p", { style: { textAlign: 'center' } }, "Are you sure you want to delete this user?")));
};
export default ConfirmDeleteUserModal;
