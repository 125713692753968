import { faChevronLeft, faChevronRight, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import DropdownField from '../dropdownField/DropdownField';
import './PaginationControl.sass';
var PaginationControl = function (_a) {
    var pageNumber = _a.pageNumber, totalItemCount = _a.totalItemCount, pageSize = _a.pageSize, _b = _a.allowPageSizeSelection, allowPageSizeSelection = _b === void 0 ? false : _b, _c = _a.availablePageSizes, availablePageSizes = _c === void 0 ? [10, 20, 40] : _c, onSelectPage = _a.onSelectPage, onSelectPageSize = _a.onSelectPageSize;
    var shouldRenderPageSizeSelection = useMemo(function () {
        return allowPageSizeSelection && onSelectPageSize;
    }, [allowPageSizeSelection, onSelectPageSize]);
    var shouldRenderPageSelectionControl = useMemo(function () {
        return totalItemCount > pageSize;
    }, [totalItemCount, pageSize]);
    var lastPage = useMemo(function () {
        return Math.ceil(totalItemCount / pageSize);
    }, [pageSize, totalItemCount]);
    var shouldRenderPreviousArrow = useMemo(function () {
        return pageNumber > 1;
    }, [pageNumber]);
    var shouldRenderNextArrow = useMemo(function () {
        return pageNumber < lastPage;
    }, [pageNumber, lastPage]);
    var pageIndicators = useMemo(function () {
        var pageIndicators = [1, lastPage];
        pageIndicators.push(pageNumber);
        pageIndicators.push(pageNumber - 1);
        pageIndicators.push(pageNumber - 2);
        pageIndicators.push(pageNumber + 1);
        pageIndicators.push(pageNumber + 2);
        pageIndicators = pageIndicators.sort(function (a, b) {
            return a - b;
        });
        pageIndicators = pageIndicators.filter(function (pageIndicator, index) {
            return (pageIndicator > 0 &&
                pageIndicator <= lastPage &&
                pageIndicators.indexOf(pageIndicator) === index);
        });
        return pageIndicators;
    }, [lastPage, pageNumber]);
    return (React.createElement("div", { className: "pagination-control" },
        React.createElement("div", { className: "total-item-count" },
            totalItemCount,
            " results"),
        shouldRenderPageSelectionControl && (React.createElement("div", { className: "page-selection-control" },
            shouldRenderPreviousArrow && (React.createElement("span", { onClick: function () { return onSelectPage(pageNumber - 1); }, className: "arrow-control" },
                React.createElement(FontAwesomeIcon, { icon: faChevronLeft }))),
            pageIndicators.map(function (pageIndicator, index) {
                var prefixWithEllipsis = index !== 0 && pageIndicators[index - 1] < pageIndicator - 1;
                return (React.createElement(React.Fragment, { key: index },
                    prefixWithEllipsis && React.createElement("span", { className: "ellipsis" }, "..."),
                    React.createElement("span", { onClick: function () { return onSelectPage(pageIndicator); }, className: "" + (pageIndicator === pageNumber ? 'active' : '') }, pageIndicator)));
            }),
            shouldRenderNextArrow && (React.createElement("span", { onClick: function () { return onSelectPage(pageNumber + 1); }, className: "arrow-control" },
                React.createElement(FontAwesomeIcon, { icon: faChevronRight }))))),
        React.createElement("div", null, shouldRenderPageSizeSelection && (React.createElement("div", { className: "page-size-control" },
            React.createElement("span", null, "Items per page"),
            React.createElement(DropdownField, { name: "pageSize", value: pageSize, options: availablePageSizes.map(function (availablePageSize) {
                    return {
                        label: availablePageSize + " items",
                        value: availablePageSize,
                    };
                }), onChange: function (value) {
                    onSelectPageSize && onSelectPageSize(value.pageSize);
                } }))))));
};
export default PaginationControl;
