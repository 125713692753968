import { DuologiFinanceApplicationStatusTypes, } from "@payaca/types/jobFinanceApplicationTypes";
export var isCreditRequestValid = function (depositAmount, jobTotal, financePlan) {
    var financeAmount = jobTotal - depositAmount;
    // check requesting finance is valid i.e finance amount > min_amount and < max_amount
    var financeIsValid = financeAmount >= financePlan.minAmount &&
        financeAmount <= financePlan.maxAmount;
    // check deposit is valid
    var minDepositValue = (financePlan.minDeposit / 100) * jobTotal;
    var maxDepositValue = (financePlan.maxDeposit / 100) * jobTotal;
    var depositIsValid = depositAmount >= minDepositValue && depositAmount <= maxDepositValue;
    return financeIsValid && depositIsValid;
};
/**
 * hasSumittedFinanceApplication - if jobFinanceInformation exists, status exists and not PROPOSAL
 * @param jobFinanceInformation
 */
export var hasSubmittedFinanceApplication = function (jobFinanceInformation) {
    return !!jobFinanceInformation &&
        !!Object.keys(jobFinanceInformation).length &&
        !!jobFinanceInformation.status &&
        jobFinanceInformation.status !==
            DuologiFinanceApplicationStatusTypes.PROPOSAL;
};
export var hasAcceptedFinanceApplication = function (jobFinanceInformation) { return !!jobFinanceInformation && !!jobFinanceInformation.approvedAt; };
export var hasRejectedFinanceApplication = function (jobFinanceInformation) { return !!jobFinanceInformation && !!jobFinanceInformation.rejectedAt; };
export var hasCustomerAgreementSignedFinanceApplication = function (jobFinanceInformation) {
    return !!jobFinanceInformation && !!jobFinanceInformation.customerAgreementSignedAt;
};
export var requiresCustomerActionFinanceApplication = function (jobFinanceInformation) {
    return !!jobFinanceInformation &&
        jobFinanceInformation.status ===
            DuologiFinanceApplicationStatusTypes.ACTION_CUSTOMER;
};
/**
 * isPendingFinanceApplicationDecision - submitted application awaiting approval/rejection
 * @param jobFinanceInformation
 * @returns
 */
export var isPendingFinanceApplicationDecision = function (jobFinanceInformation) {
    return (hasSubmittedFinanceApplication(jobFinanceInformation) &&
        !hasAcceptedFinanceApplication(jobFinanceInformation) &&
        !hasRejectedFinanceApplication(jobFinanceInformation));
};
/**
 * Submitted finance and waiting for customer agreement signing
 * @param jobFinanceInformation
 */
export var isPendingCustomerAgreementSigned = function (jobFinanceInformation) {
    return hasSubmittedFinanceApplication(jobFinanceInformation) &&
        !!jobFinanceInformation &&
        !jobFinanceInformation.customerAgreementSignedAt;
};
