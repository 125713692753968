import React, { useEffect, useState } from 'react';
import TaxesTable from './TaxesTable';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { CheckboxField, DropdownField, Modal } from '@payaca/components';
import './AccountingSetupModal.sass';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
var taxes = [
    {
        payacaLabel: 'VAT 20%',
        payacaPercentage: 20,
        accountingLabel: '20% (VAT on Income)',
        accountingPercentage: 20,
    },
    {
        payacaLabel: 'VAT 20% rc',
        payacaPercentage: 0,
        accountingLabel: 'Domestic Reverse Charge @ 20% (VAT on Income)',
        accountingPercentage: 0,
    },
    {
        payacaLabel: 'VAT 5%',
        payacaPercentage: 5,
        accountingLabel: '5% (VAT on Income)',
        accountingPercentage: 5,
    },
    {
        payacaLabel: 'VAT 5% rc',
        payacaPercentage: 0,
        accountingLabel: 'Domestic Reverse Charge @ 5% (VAT on Income)',
        accountingPercentage: 0,
    },
    {
        payacaLabel: 'VAT 0%',
        payacaPercentage: 0,
        accountingLabel: 'Zero Rated Income',
        accountingPercentage: 0,
    },
    {
        payacaLabel: 'No VAT',
        payacaPercentage: 0,
        accountingLabel: 'No VAT',
        accountingPercentage: 0,
    },
];
var XeroSetupModal = function (_a) {
    var closeXeroSetupModal = _a.closeXeroSetupModal, currentXeroDefaultRevenueAccountCode = _a.currentXeroDefaultRevenueAccountCode, updateXeroSettings = _a.updateXeroSettings, xeroAccounts = _a.xeroAccounts;
    var _b = useState(null), selectedXeroDefaultRevenueAccountCode = _b[0], setSelectedXeroDefaultRevenueAccountCode = _b[1];
    var _c = useState(false), areTaxesCorrect = _c[0], setAreTaxesCorrect = _c[1];
    var _d = useState(null), updateXeroMessage = _d[0], setUpdateXeroMessage = _d[1];
    useEffect(function () {
        // set current revenue account code as selected on first load
        setSelectedXeroDefaultRevenueAccountCode(currentXeroDefaultRevenueAccountCode);
    }, []);
    return (React.createElement(Modal, { isOpen: true, title: "Complete Xero setup", size: "sm" },
        React.createElement("div", null,
            React.createElement(DropdownField, { label: "Setup default revenue account", options: xeroAccounts.map(function (xeroAccount) {
                    return {
                        label: xeroAccount.Code + " - " + xeroAccount.Name,
                        value: xeroAccount.Code,
                    };
                }), styleVariant: InputStyleVariant.UNSTYLED, value: selectedXeroDefaultRevenueAccountCode, name: "defaultRevAccount", onChange: function (value) {
                    return setSelectedXeroDefaultRevenueAccountCode(value.defaultRevAccount);
                } })),
        React.createElement(TaxesTable, { accountingName: "Xero", taxes: taxes }),
        React.createElement("p", null, "You will need to add Domestic Reverse Charge Tax Rates to Xero if you plan on using the VAT 20% REVERSE CHARGE tax option."),
        React.createElement("p", null,
            "If you haven't already, you can do this by visiting",
            ' ',
            React.createElement("strong", null, "Accounting"),
            " > ",
            React.createElement("strong", null, "Advanced"),
            " >",
            ' ',
            React.createElement("strong", null, "Tax rates"),
            " and clicking",
            ' ',
            React.createElement("strong", null, "Add Domestic Reverse Charge Tax Rates"),
            "."),
        React.createElement("div", null,
            React.createElement(CheckboxField, { label: "I confirm that my taxes are correct. Otherwise I will have to do manual reconciliation in Xero.", onChange: function () { return setAreTaxesCorrect(!areTaxesCorrect); }, name: "confirmTaxes", value: areTaxesCorrect })),
        React.createElement("div", { className: "error" }, updateXeroMessage),
        React.createElement("div", { className: "save-connection-button-container" },
            React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, isDisabled: !areTaxesCorrect, onClick: function () {
                    setUpdateXeroMessage(null);
                    if (!areTaxesCorrect) {
                        setUpdateXeroMessage('Please confirm your taxes are correct');
                    }
                    else if (!selectedXeroDefaultRevenueAccountCode ||
                        isNaN(selectedXeroDefaultRevenueAccountCode)) {
                        setUpdateXeroMessage('Please select a default revenue account');
                    }
                    else {
                        updateXeroSettings(selectedXeroDefaultRevenueAccountCode);
                        setUpdateXeroMessage(null);
                        closeXeroSetupModal();
                    }
                } }, "Save"))));
};
export default XeroSetupModal;
