import React, { useMemo, useState } from 'react';
import { ContentPanel, ResponsiveViewWrapper } from '@payaca/components';
import './CurrentSubscriptionControls.sass';
import { useSelector } from 'react-redux';
import SubscriptionUsageIndicator from '../manageSubscription/subscriptionUsageIndicator/SubscriptionUsageIndicator';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import PaymentMethodCard from '../paymentMethodCard/PaymentMethodCard';
import CollapsibleSubscriptionInformation from '../collapsibleSubscriptionInformation/CollapsibleSubscriptionInformation';
import UpdateSubscriptionAdditionalUserSeatsModal from '../manageSubscription/updateSubscriptionAdditionalUserSeatsModal/UpdateSubscriptionAdditionalUserSeatsModal';
import UpdatePaymentMethodControl from '../manageSubscription/updatePaymentMethodControl/UpdatePaymentMethodControl';
import { Modal } from '@payaca/components/modal';
import { PayacaPhoneLink } from '@payaca/components/payacaPhoneLink';
var TAX_RATE_PERCENTAGE = process.env
    .REACT_APP_SUBSCRIPTION_TAX_RATE_PERCENTAGE;
var CurrentSubscriptionControls = function (_a) {
    var onUpdateSubscription = _a.onUpdateSubscription;
    var _b = useState(false), showUpdateSubscriptionAdditionalUserSeatsModal = _b[0], setShowUpdateSubscriptionAdditionalUserSeatsModal = _b[1];
    var _c = useState(false), showUpdatePaymentMethodModal = _c[0], setShowUpdatePaymentMethodModal = _c[1];
    var accountSubscription = useSelector(function (state) { return state.subscription.accountSubscription; });
    var isZeroPercentFinanceApproved = useSelector(function (state) {
        var _a, _b;
        return !!((_b = (_a = state.users.myProfile.accounts[0].integrations) === null || _a === void 0 ? void 0 : _a.zeroPercentFinance) === null || _b === void 0 ? void 0 : _b.isApproved);
    });
    var hasZeroPercentFinanceFeature = useSelector(function (state) { var _a, _b; return (_b = (_a = state.account.accountAccessInformation) === null || _a === void 0 ? void 0 : _a.features) === null || _b === void 0 ? void 0 : _b.hasZeroPercentFinance; });
    var subscriptionEndsAt = useMemo(function () {
        return accountSubscription === null || accountSubscription === void 0 ? void 0 : accountSubscription.subscriptionInformation.subscriptionEndDate;
    }, [accountSubscription]);
    var paymentMethodCardElement = useMemo(function () {
        if (!(accountSubscription === null || accountSubscription === void 0 ? void 0 : accountSubscription.subscriptionInformation))
            return;
        return (React.createElement(PaymentMethodCard, { brand: accountSubscription.subscriptionInformation.stripePaymentMethodBrand, expiryMonth: +accountSubscription.subscriptionInformation
                .stripePaymentMethodExpiryMonth, expiryYear: +accountSubscription.subscriptionInformation
                .stripePaymentMethodExpiryYear, cardNumberLast4: accountSubscription.subscriptionInformation.stripePaymentMethodLast4 }));
    }, [accountSubscription === null || accountSubscription === void 0 ? void 0 : accountSubscription.subscriptionInformation]);
    var subscriptionUsageIndicatorElement = useMemo(function () {
        return (React.createElement(SubscriptionUsageIndicator, { updateAdditionalUserSeatsTrigger: (accountSubscription === null || accountSubscription === void 0 ? void 0 : accountSubscription.productPrice.canBuyAdditionalUserSeats) ? (React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () {
                    return setShowUpdateSubscriptionAdditionalUserSeatsModal(true);
                } }, "Add user seats")) : undefined }));
    }, [accountSubscription === null || accountSubscription === void 0 ? void 0 : accountSubscription.productPrice.canBuyAdditionalUserSeats]);
    return (React.createElement(ResponsiveViewWrapper, { className: "current-subscription-controls", downBreakpointSm: 800 },
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: "current-subscription-controls-inner flex-container" },
                React.createElement("div", { className: "flex-grow" },
                    React.createElement(ContentPanel, { className: "subscription-information-content-panel" },
                        subscriptionUsageIndicatorElement,
                        React.createElement("hr", null),
                        accountSubscription && (React.createElement(CollapsibleSubscriptionInformation, { accountSubscription: accountSubscription, taxRatePercentage: TAX_RATE_PERCENTAGE })))),
                React.createElement("div", { className: "rhs flex-shrink" },
                    paymentMethodCardElement,
                    !subscriptionEndsAt && (React.createElement("div", { className: "update-payment-method-trigger-container" },
                        React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () { return setShowUpdatePaymentMethodModal(true); } }, "Update payment method"))),
                    hasZeroPercentFinanceFeature && isZeroPercentFinanceApproved && (React.createElement("div", { className: "zero-percent-container" },
                        React.createElement("h4", null, "Your account has been approved to offer 0% finance on quotes"))))),
            !subscriptionEndsAt && (React.createElement("div", { className: "cancel-subscription-information" },
                "If you want to cancel your subscription please contact us on",
                ' ',
                React.createElement(PayacaPhoneLink, null))),
            React.createElement(UpdateSubscriptionAdditionalUserSeatsModal, { onUpdateSubscription: onUpdateSubscription, isOpen: showUpdateSubscriptionAdditionalUserSeatsModal, onClose: function () {
                    return setShowUpdateSubscriptionAdditionalUserSeatsModal(false);
                } }),
            React.createElement(Modal, { isOpen: showUpdatePaymentMethodModal, title: 'Update payment method', onClose: function () { return setShowUpdatePaymentMethodModal(false); } },
                React.createElement(UpdatePaymentMethodControl, { onUpdatePaymentMethod: function () { return setShowUpdatePaymentMethodModal(false); } })))));
};
export default CurrentSubscriptionControls;
