import React, { useCallback, useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@payaca/components/modal';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { actions as jobsStoreActions } from '@bit/payaca-tech.payaca-core.store.jobs';
import './MarkAsSentModal.css';
import { ErrorMessage } from '@payaca/components/feedbackMessage';
var MarkAsSentModal = function (_a) {
    var isOpen = _a.isOpen, jobId = _a.jobId, _b = _a.jobType, jobType = _b === void 0 ? 'Job' : _b, onClose = _a.onClose, markAsSentCallback = _a.markAsSentCallback;
    var dispatch = useDispatch();
    var _c = useState(false), hasMarkedAsSent = _c[0], setHasMarkedAsSent = _c[1];
    var isMarkingJobAsSent = useSelector(function (state) {
        return state.jobsStore.isMarkingJobAsSent;
    });
    var markJobAsSentError = useSelector(function (state) {
        return state.jobsStore.markJobAsSentErrorMessage;
    });
    var onMarkAsSent = useCallback(function () {
        dispatch(jobsStoreActions.requestMarkJobAsSent(jobId));
        setHasMarkedAsSent(true);
    }, [jobId, dispatch]);
    var onModalClose = function () {
        dispatch(jobsStoreActions.clearMarkJobAsSent());
        onClose();
    };
    useEffect(function () {
        if (hasMarkedAsSent && !isMarkingJobAsSent && !markJobAsSentError) {
            markAsSentCallback && markAsSentCallback();
            setHasMarkedAsSent(false);
            onModalClose();
        }
    }, [
        hasMarkedAsSent,
        isMarkingJobAsSent,
        markJobAsSentError,
        markAsSentCallback,
        onModalClose,
    ]);
    return (React.createElement(Modal, { isOpen: isOpen, title: "Mark job as sent", size: "xs", onClose: onModalClose, className: "mark-as-sent-modal", actions: React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: onMarkAsSent, iconAfter: faChevronRight, isProcessing: isMarkingJobAsSent, styleVariant: ButtonStyleVariant.OUTSIZE }, "Mark as sent without sending"),
            markJobAsSentError && React.createElement(ErrorMessage, { message: markJobAsSentError })) },
        React.createElement("p", null,
            "This option is intended to be used in situations where you have sent the",
            ' ',
            jobType.toLowerCase(),
            " offline. For example - where you have printed off the ",
            jobType.toLowerCase(),
            " and handed it to your customer on paper, in person."),
        React.createElement("p", null,
            "When a ",
            jobType.toLowerCase(),
            " has been marked as sent:"),
        React.createElement("ul", null,
            React.createElement("li", null, "No emails or other communication will go to the customer"),
            React.createElement("li", null,
                "You will not be able to retrospectively send this",
                ' ',
                jobType.toLowerCase(),
                " to the customer using the standard Payaca process"),
            React.createElement("li", null,
                "If your account is connected to any third party services (Zapier, Xero, Quickbooks), they will treat this ",
                jobType.toLowerCase(),
                " as they would any other sent ",
                jobType.toLowerCase()),
            jobType === 'Invoice' && React.createElement("li", null, "Invoice reminders will not be sent"))));
};
export default MarkAsSentModal;
