import { all } from 'redux-saga/effects';

export const initialState = {
  pendingAccount: {},
  pendingBusinessAttachments: [],
  pendingTerms: [],
  pendingEmailTemplates: {},
  pendingFinancePlans: [],
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'accountSettings.clearAccountPendingChanges': {
      return initialState;
    }
    case 'accountSettings.storeAccountPendingChanges': {
      return {
        ...state,
        pendingAccount: {
          ...state.pendingAccount,
          ...action.payload,
        },
      };
    }
    case 'accountSettings.storeAccountPendingBusinessAttachments': {
      return {
        ...state,
        pendingBusinessAttachments: action.payload,
      };
    }
    case 'accountSettings.storeAccountPendingTerms': {
      return {
        ...state,
        pendingTerms: action.payload,
      };
    }
    case 'accountSettings.storeAccountPendingEmailTemplates':
      return {
        ...state,
        pendingEmailTemplates: {
          ...state.pendingEmailTemplates,
          ...action.payload,
        },
      };
    case 'accountSettings.storeAccountPendingFinancePlans':
      return {
        ...state,
        pendingFinancePlans: action.payload,
      };

    default: {
      return state;
    }
  }
}

export const actions = {
  storeAccountPendingChanges: (payload) => ({
    type: 'accountSettings.storeAccountPendingChanges',
    payload,
  }),
  storeAccountPendingBusinessAttachments: (payload) => ({
    type: 'accountSettings.storeAccountPendingBusinessAttachments',
    payload,
  }),
  storeAccountPendingTerms: (payload) => ({
    type: 'accountSettings.storeAccountPendingTerms',
    payload,
  }),
  storeAccountPendingEmailTemplates: (payload) => ({
    type: 'accountSettings.storeAccountPendingEmailTemplates',
    payload,
  }),
  storeAccountPendingFinancePlans: (payload) => ({
    type: 'accountSettings.storeAccountPendingFinancePlans',
    payload,
  }),

  clearAccountPendingChanges: () => ({
    type: 'accountSettings.clearAccountPendingChanges',
  }),
};

export const saga = function* saga() {
  yield all([]);
};
