import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CompanySettingsCompanyInfo from './CompanySettingsCompanyInfo';
import CompanySettingsGettingPaid from './CompanySettingsGettingPaid';
import CompanySettingsTerms from './CompanySettingsTerms';
import CompanySettingsReminders from './CompanySettingsReminders';
import CompanySettingsBranding from './CompanySettingsBranding';
import CompanySettingsEmailCustomisation from './CompanySettingsEmailCustomisation';
import CompanySettingsFinance from './CompanySettingsFinance';
import { CollapsiblePanel, CollapsiblePanelStyleVariant, } from '@payaca/components/collapsiblePanel';
import { actions as userActions } from '@/api/users';
import { actions as accountActions } from '@bit/payaca-tech.payaca-core.store.account';
import { actions as accountSettingsActions } from '@/api/accountSettings';
import { parseAccount } from '@/helpers/userHelper';
import { getUrlSearchParam } from '@bit/payaca-tech.payaca-core.helpers.url';
import './CompanySettings.sass';
var CompanySection;
(function (CompanySection) {
    CompanySection["COMPANY_INFO"] = "company_info";
    CompanySection["BRANDING"] = "branding";
    CompanySection["GETTING_PAID"] = "getting_paid";
    CompanySection["TERMS"] = "terms";
    CompanySection["REMINDERS"] = "reminders";
    CompanySection["EMAIL_CUSTOMISATION"] = "email_customisation";
    CompanySection["FINANCE"] = "finance";
})(CompanySection || (CompanySection = {}));
var CompanySettings = function () {
    var dispatch = useDispatch();
    var location = useLocation();
    var isAdmin = useSelector(function (state) { return state.users.myProfile.isAdmin; });
    var financePlans = useSelector(function (state) { return state.users.financePlans; });
    var sections = useMemo(function () { return [
        {
            title: 'Company info',
            name: CompanySection.COMPANY_INFO,
            content: React.createElement(CompanySettingsCompanyInfo, { readOnly: !isAdmin }),
        },
        {
            title: 'Branding',
            name: CompanySection.BRANDING,
            content: React.createElement(CompanySettingsBranding, { readOnly: !isAdmin }),
        },
        {
            title: 'Getting paid',
            name: CompanySection.GETTING_PAID,
            content: React.createElement(CompanySettingsGettingPaid, { readOnly: !isAdmin }),
        },
        {
            title: 'Terms',
            name: CompanySection.TERMS,
            content: React.createElement(CompanySettingsTerms, { readOnly: !isAdmin }),
        },
        {
            title: 'Reminders',
            name: CompanySection.REMINDERS,
            content: React.createElement(CompanySettingsReminders, { readOnly: !isAdmin }),
        },
        {
            title: 'Email customisation',
            name: CompanySection.EMAIL_CUSTOMISATION,
            content: React.createElement(CompanySettingsEmailCustomisation, { readOnly: !isAdmin }),
        },
        {
            title: 'Finance',
            name: CompanySection.FINANCE,
            content: React.createElement(CompanySettingsFinance, { readOnly: !isAdmin }),
            isDisabled: !(financePlans === null || financePlans === void 0 ? void 0 : financePlans.length),
        },
    ]; }, [financePlans, isAdmin]);
    var _a = useState(null), openSection = _a[0], setOpenSection = _a[1];
    var defaultSectionOpen = useMemo(function () {
        var queryTabName = getUrlSearchParam(location.search, 'tab');
        if (!queryTabName) {
            return null;
        }
        var chosenSection = sections.find(function (section) { return section.name === (queryTabName === null || queryTabName === void 0 ? void 0 : queryTabName.toLowerCase()); });
        return chosenSection && !(chosenSection === null || chosenSection === void 0 ? void 0 : chosenSection.isDisabled)
            ? chosenSection.name
            : null;
    }, [location, sections]);
    useEffect(function () {
        dispatch(userActions.getProfile(function () {
            dispatch(userActions.getBusinessAttachments());
            dispatch(userActions.getAccountTerms(currentAccount.id));
            dispatch(accountActions.requestGetEmailTemplates(currentAccount.id, true));
            dispatch(userActions.requestGetFinancePlans(true));
        }));
        // set default open section
        setOpenSection(defaultSectionOpen);
        return function () {
            dispatch(accountSettingsActions.clearAccountPendingChanges());
            dispatch(userActions.clearFinancePlans());
        };
    }, []);
    var currentAccount = useSelector(function (state) { var _a, _b; return (_b = (_a = state.users) === null || _a === void 0 ? void 0 : _a.myProfile) === null || _b === void 0 ? void 0 : _b.accounts[0]; });
    var terms = useSelector(function (state) { return state.users.terms; });
    var emailTemplates = useSelector(function (state) { return state.account.emailTemplates; });
    var businessAttachments = useSelector(function (state) { return state.users.businessAttachments; });
    useEffect(function () {
        dispatch(accountSettingsActions.storeAccountPendingChanges(parseAccount(currentAccount)));
    }, [currentAccount]);
    useEffect(function () {
        dispatch(accountSettingsActions.storeAccountPendingBusinessAttachments(businessAttachments));
    }, [businessAttachments]);
    useEffect(function () {
        dispatch(accountSettingsActions.storeAccountPendingTerms(terms));
    }, [terms]);
    useEffect(function () {
        if (emailTemplates) {
            dispatch(accountSettingsActions.storeAccountPendingEmailTemplates({
                sendEstimate: emailTemplates.sendEstimate,
                sendQuote: emailTemplates.sendQuote,
                sendInvoice: emailTemplates.sendInvoice,
            }));
        }
    }, [emailTemplates]);
    useEffect(function () {
        dispatch(accountSettingsActions.storeAccountPendingFinancePlans(financePlans));
    }, [financePlans]);
    return (React.createElement("div", { className: "company-settings-container" },
        !isAdmin && (React.createElement("div", { className: "contact-admin-text" }, "Please contact an admin to amend these details")), sections === null || sections === void 0 ? void 0 :
        sections.map(function (section, i) {
            return (React.createElement(CollapsiblePanel, { isDisabled: section.isDisabled, isOpen: openSection === section.name, key: i, onOpen: function () { return setOpenSection(section.name); }, onClose: function () { return setOpenSection(null); }, title: section.title, styleVariant: CollapsiblePanelStyleVariant.OUTSIZE }, section.content));
        })));
};
export default CompanySettings;
