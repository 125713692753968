import React from 'react';
import { useSelector } from 'react-redux';
import { AnalyticsDashboardContent, AnalyticsDashboardUnsubscribedHoldingContent, } from '@/ui/components';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import './AnalyticsDashboardPage.css';
var AnalyticsDashboardPage = function () {
    var currentAccountHasSubscription = useSelector(function (state) { var _a, _b; return !!((_b = (_a = state === null || state === void 0 ? void 0 : state.account) === null || _a === void 0 ? void 0 : _a.accountAccessInformation) === null || _b === void 0 ? void 0 : _b.hasActiveSubscription); });
    return (React.createElement(AuthenticatedPageWrapper, { className: "analytics-dashboard-page", title: "Business Performance" },
        currentAccountHasSubscription && React.createElement(AnalyticsDashboardContent, null),
        !currentAccountHasSubscription && (React.createElement(AnalyticsDashboardUnsubscribedHoldingContent, null))));
};
export default AnalyticsDashboardPage;
