import { action } from 'typesafe-actions';
import { ActionType, } from './jobPaymentsTypes';
export var requestRecordJobPayment = function (recordJobPaymentRequestData) {
    return action(ActionType.REQUEST_RECORD_JOB_PAYMENT, {
        recordJobPaymentRequestData: recordJobPaymentRequestData,
    });
};
export var recordJobPaymentSuccess = function () {
    return action(ActionType.RECORD_JOB_PAYMENT_SUCCESS);
};
export var recordJobPaymentFailure = function (error) {
    return action(ActionType.RECORD_JOB_PAYMENT_FAILURE, null, null, error);
};
export var requestConfirmJobPayment = function (confirmJobPaymentRequestData) {
    return action(ActionType.REQUEST_CONFIRM_JOB_PAYMENT, {
        confirmJobPaymentRequestData: confirmJobPaymentRequestData,
    });
};
export var confirmJobPaymentSuccess = function () {
    return action(ActionType.CONFIRM_JOB_PAYMENT_SUCCESS);
};
export var confirmJobPaymentFailure = function (error) {
    return action(ActionType.CONFIRM_JOB_PAYMENT_FAILURE, null, null, error);
};
export var requestGetJobPaymentsForDeal = function (dealId) {
    return action(ActionType.REQUEST_GET_JOB_PAYMENTS_FOR_DEAL, {
        dealId: dealId,
    });
};
export var requestGetJobPayment = function (jobPaymentId) {
    return action(ActionType.REQUEST_GET_JOB_PAYMENT, {
        jobPaymentId: jobPaymentId,
    });
};
export var getJobPaymentSuccess = function (jobPaymentId, jobPayment) {
    return action(ActionType.GET_JOB_PAYMENT_SUCCESS, {
        jobPaymentId: jobPaymentId,
        jobPayment: jobPayment,
    });
};
export var getJobPaymentFailure = function (jobPaymentId, error) {
    return action(ActionType.GET_JOB_PAYMENT_FAILURE, {
        jobPaymentId: jobPaymentId,
    }, null, error);
};
export var clearJobPayment = function (jobPaymentId) {
    return action(ActionType.CLEAR_JOB_PAYMENT, {
        jobPaymentId: jobPaymentId,
    });
};
export var clearJobPayments = function () {
    return action(ActionType.CLEAR_JOB_PAYMENTS);
};
