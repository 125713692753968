export var JobStatus;
(function (JobStatus) {
    JobStatus["NEW_ESTIMATE"] = "new_estimate";
    JobStatus["ESTIMATED"] = "estimated";
    JobStatus["NEW_QUOTE"] = "new_quote";
    JobStatus["QUOTED"] = "quoted";
    JobStatus["ACCEPTED"] = "accepted";
    JobStatus["NEW_INVOICE"] = "new_invoice";
    JobStatus["INVOICED"] = "invoiced";
    JobStatus["PAID"] = "paid";
})(JobStatus || (JobStatus = {}));
export var ReadableJobStatus;
(function (ReadableJobStatus) {
    ReadableJobStatus["ARCHIVED"] = "Archived";
    ReadableJobStatus["INACTIVE"] = "Inactive";
    ReadableJobStatus["INVOICED"] = "Invoiced";
    ReadableJobStatus["PAID"] = "Paid";
    ReadableJobStatus["DEPOSIT_PAID"] = "Deposit paid";
    ReadableJobStatus["DEPOSIT_DUE"] = "Deposit due";
    ReadableJobStatus["PAYMENT_PENDING"] = "Payment pending";
    ReadableJobStatus["OVERDUE"] = "Overdue";
    ReadableJobStatus["ACCEPTED"] = "Accepted";
    ReadableJobStatus["DECLINED"] = "Declined";
    ReadableJobStatus["BOUNCED"] = "Bounced";
    ReadableJobStatus["VIEWED"] = "Viewed";
    ReadableJobStatus["SENT"] = "Sent";
    ReadableJobStatus["DRAFT"] = "Draft";
    ReadableJobStatus["FINANCE_APPROVED"] = "Finance approved";
    ReadableJobStatus["FINANCE_REJECTED"] = "Finance rejected";
    ReadableJobStatus["FINANCE_SIGNED"] = "Finance signed";
    ReadableJobStatus["FINANCE_COMPLETE"] = "Finance complete";
    ReadableJobStatus["JOB_COMPLETE"] = "Job complete";
    ReadableJobStatus["JOB_UNSATISFACTORY"] = "Job unsatisfactory";
    ReadableJobStatus["PAYOUT_APPROVED"] = "Payout approved";
})(ReadableJobStatus || (ReadableJobStatus = {}));
