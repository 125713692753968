import get from 'lodash/get';
import includes from 'lodash/includes';
import { EMPTY_STRING_REGEX, VALID_EMAIL_REGEX, VALID_PHONE_NUMBER_REGEX, } from '../constants/regexConstants';
import moment from 'moment';
var hasValue = function (value) {
    if (value === null || value === undefined || value === '') {
        return false;
    }
    if (EMPTY_STRING_REGEX.test(value)) {
        return false;
    }
    return true;
};
export var getAllowEmptyValidator = function (fieldValidator) {
    return function (fieldName, formState) {
        var value = get(formState, fieldName);
        if (!hasValue(value)) {
            return {
                isValid: true,
            };
        }
        return fieldValidator(fieldName, formState);
    };
};
export var getIsRequiredFieldValidator = function (options) {
    var errorMessage = (options === null || options === void 0 ? void 0 : options.customErrorMessage) ||
        ((options === null || options === void 0 ? void 0 : options.readableName) ? options === null || options === void 0 ? void 0 : options.readableName : 'This') + " is a required field";
    return function (fieldName, formState) {
        var value = get(formState, fieldName);
        if (!hasValue(value)) {
            return {
                isValid: false,
                errors: [errorMessage],
            };
        }
        return {
            isValid: true,
        };
    };
};
export var getLengthFieldValidator = function (_a) {
    var _b = _a.min, min = _b === void 0 ? 0 : _b, _c = _a.max, max = _c === void 0 ? 10000 : _c;
    return function (fieldName, formState) {
        var value = get(formState, fieldName) || '';
        if (typeof value !== 'string') {
            return {
                isValid: false,
                errors: ['Please enter a valid value for this field'],
            };
        }
        if (value.length < min || value.length > max) {
            return {
                isValid: false,
                errors: [
                    value.length < min
                        ? "This must be longer than " + min + " characters"
                        : "This must not be longer than " + max + " characters",
                ],
            };
        }
        return {
            isValid: true,
        };
    };
};
export var getRegexMatchFieldValidator = function (regex, options) {
    var errorMessage = (options === null || options === void 0 ? void 0 : options.customErrorMessage) ||
        ((options === null || options === void 0 ? void 0 : options.readableName) ? options === null || options === void 0 ? void 0 : options.readableName : 'This field') + " must contain valid input";
    return function (fieldName, formState) {
        var value = get(formState, fieldName);
        var regexMatch = regex.test(value);
        if (!regexMatch) {
            return {
                isValid: false,
                errors: [errorMessage],
            };
        }
        return {
            isValid: true,
        };
    };
};
export var getEmailFieldValidator = function (options) {
    var regex = VALID_EMAIL_REGEX;
    return getRegexMatchFieldValidator(regex, options);
};
export var getPhoneNumberFieldValidator = function (options) {
    var regex = VALID_PHONE_NUMBER_REGEX;
    return getRegexMatchFieldValidator(regex, options);
};
export var getArrayMustNotBeEmptyFieldValidator = function (options) {
    var errorMessage = (options === null || options === void 0 ? void 0 : options.customErrorMessage) ||
        "You must provide at least one " + ((options === null || options === void 0 ? void 0 : options.readableName) ? options === null || options === void 0 ? void 0 : options.readableName : 'item');
    return function (fieldName, formState) {
        var value = get(formState, fieldName);
        if (!(value instanceof Array) || value.length <= 0) {
            return {
                isValid: false,
                errors: [errorMessage],
            };
        }
        return {
            isValid: true,
        };
    };
};
export var getIsRequiredIfTrueConditionValidator = function (executeCondition, options) {
    var isRequiredFieldValidator = getIsRequiredFieldValidator(options);
    return function (fieldName, formState) {
        var conditionResult = executeCondition(formState);
        if (conditionResult) {
            return isRequiredFieldValidator(fieldName, formState);
        }
        return {
            isValid: true,
        };
    };
};
export var getConditionalValidator = function (executeCondition, fieldValidator) {
    return function (fieldName, formState) {
        var conditionResult = executeCondition(formState);
        if (conditionResult) {
            return fieldValidator(fieldName, formState);
        }
        return {
            isValid: true,
        };
    };
};
export var getMustEqualValueValidator = function (mustEqualValue, options) {
    var errorMessage = (options === null || options === void 0 ? void 0 : options.customErrorMessage) ||
        "The value of " + ((options === null || options === void 0 ? void 0 : options.readableName) ? options === null || options === void 0 ? void 0 : options.readableName : 'this field') + " must be " + ((mustEqualValue === null || mustEqualValue === void 0 ? void 0 : mustEqualValue.toString) ? mustEqualValue.toString() : mustEqualValue);
    return function (fieldName, formState) {
        var value = get(formState, fieldName);
        if (value !== mustEqualValue) {
            return {
                isValid: false,
                errors: [errorMessage],
            };
        }
        return {
            isValid: true,
        };
    };
};
export var getNumericalRangeFieldValidator = function (minValue, maxValue, readableName, minValueExceededCustomErrorMessage, maxValueExceededCustomErrorMessage) {
    return function (fieldName, formState) {
        var value = get(formState, fieldName);
        if (minValue !== undefined && value <= minValue) {
            return {
                isValid: false,
                errors: [
                    minValueExceededCustomErrorMessage ||
                        (readableName ? readableName : 'This field') + " must be greater than " + minValue,
                ],
            };
        }
        else if (maxValue !== undefined && value >= maxValue) {
            return {
                isValid: false,
                errors: [
                    maxValueExceededCustomErrorMessage ||
                        (readableName ? readableName : 'This field') + " must be less than " + maxValue,
                ],
            };
        }
        return {
            isValid: true,
        };
    };
};
export var getDateRangeFieldValidator = function (minValue, maxValue, readableName, errorMessageMomentFormat, minValueExceededCustomErrorMessage, maxValueExceededCustomErrorMessage) {
    if (errorMessageMomentFormat === void 0) { errorMessageMomentFormat = 'DD MMMM yyyy'; }
    return function (fieldName, formState) {
        var value = get(formState, fieldName);
        if (minValue !== undefined && value <= minValue) {
            return {
                isValid: false,
                errors: [
                    minValueExceededCustomErrorMessage ||
                        (readableName ? readableName : 'This field') + " must be greater than " + moment(minValue).format(errorMessageMomentFormat),
                ],
            };
        }
        else if (maxValue !== undefined && value >= maxValue) {
            return {
                isValid: false,
                errors: [
                    maxValueExceededCustomErrorMessage ||
                        (readableName ? readableName : 'This field') + " must be less than " + moment(maxValue).format(errorMessageMomentFormat),
                ],
            };
        }
        return {
            isValid: true,
        };
    };
};
export var getValueMustNotExistInArrayFieldValidator = function (disallowedValueArray, options) {
    var errorMessage = (options === null || options === void 0 ? void 0 : options.customErrorMessage) ||
        "The provided value of " + ((options === null || options === void 0 ? void 0 : options.readableName) ? options === null || options === void 0 ? void 0 : options.readableName : 'this field') + " is not allowed";
    return function (fieldName, formState) {
        var value = get(formState, fieldName);
        if (includes(disallowedValueArray, value)) {
            return {
                isValid: false,
                errors: [errorMessage],
            };
        }
        return {
            isValid: true,
        };
    };
};
export var getValueMustExistInArrayFieldValidator = function (valueArray, options) {
    var errorMessage = (options === null || options === void 0 ? void 0 : options.customErrorMessage) ||
        "The provided value of " + ((options === null || options === void 0 ? void 0 : options.readableName) ? options === null || options === void 0 ? void 0 : options.readableName : 'this field') + " is not allowed";
    return function (fieldName, formState) {
        var value = get(formState, fieldName);
        if (!includes(valueArray, value)) {
            return {
                isValid: false,
                errors: [errorMessage],
            };
        }
        return {
            isValid: true,
        };
    };
};
export var getFieldValuesMustMatchValidator = function (fieldNameToMatch, options) {
    var errorMessage = (options === null || options === void 0 ? void 0 : options.customErrorMessage) ||
        ((options === null || options === void 0 ? void 0 : options.readableName) ? options === null || options === void 0 ? void 0 : options.readableName : 'This') + " must match the value of " + fieldNameToMatch;
    return function (fieldName, formState) {
        var value = get(formState, fieldName);
        var valueToMatch = get(formState, fieldNameToMatch);
        if (value !== valueToMatch) {
            return {
                isValid: false,
                errors: [errorMessage],
            };
        }
        return {
            isValid: true,
        };
    };
};
