var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo, useState } from 'react';
import { actions as jobsActions } from '@bit/payaca-tech.payaca-core.store.jobs';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatedForm } from '@payaca/components';
import { useCallback } from 'react';
import { isQuote, isQuoteOrEstimate, } from '@bit/payaca-tech.payaca-core.helpers.job-status';
import PaymentOptions from './PaymentOptions';
import FinanceOptions from './FinanceOptions';
import moment from 'moment';
import { TextareaField } from '@payaca/components/textareaField';
import { getInitialUpdateJobRequestDataFromBaseJob } from '@bit/payaca-tech.payaca-core.helpers.job-v2';
import { BasicField } from '@payaca/components/basicField';
import JobTotalsAndValueModifiersControl from './JobTotalsAndValueModifiersControl';
import './JobSidePanel.sass';
import _ from 'lodash';
import JobStatusControl from '../jobStatusControl/JobStatusControl';
import JobReferenceControl from '../jobReferenceControl/JobReferenceControl';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
var JobSidePanel = function (_a) {
    var jobId = _a.jobId;
    var dispatch = useDispatch();
    var job = useSelector(function (state) {
        var _a;
        return state.jobsStore.jobs && ((_a = state.jobsStore.jobs[jobId]) === null || _a === void 0 ? void 0 : _a.entity);
    });
    var _b = useState(false), isSaveRequired = _b[0], setIsSaveRequired = _b[1];
    var isUpdatingJob = useSelector(function (state) {
        return state.jobsStore.isUpdatingJob;
    });
    var isFetchingJob = useSelector(function (state) {
        var _a;
        return state.jobsStore.jobs && ((_a = state.jobsStore.jobs[jobId]) === null || _a === void 0 ? void 0 : _a.isFetching);
    });
    var accountHasStripeConnection = useSelector(function (state) {
        return state.users.myProfile.accounts[0].connectedToStripe;
    });
    var jobIsProposition = useMemo(function () {
        return isQuoteOrEstimate(job.status);
    }, [job.status]);
    var onJobUpdateSuccess = useCallback(function () {
        dispatch(jobsActions.requestGetJob(jobId));
        dispatch(jobsActions.requestGetJobLineItemsForJob(jobId));
    }, [dispatch, jobId]);
    var initialFormState = useMemo(function () {
        return getInitialUpdateJobRequestDataFromBaseJob(job);
    }, [
        job === null || job === void 0 ? void 0 : job.id,
        job === null || job === void 0 ? void 0 : job.markupAmount,
        job === null || job === void 0 ? void 0 : job.markupPercentage,
        job === null || job === void 0 ? void 0 : job.minimumDepositAmount,
        job === null || job === void 0 ? void 0 : job.minimumDepositPercentage,
        job === null || job === void 0 ? void 0 : job.depositAmount,
        job === null || job === void 0 ? void 0 : job.depositPercentage,
        job === null || job === void 0 ? void 0 : job.discountAmount,
        job === null || job === void 0 ? void 0 : job.discountPercentage,
        job === null || job === void 0 ? void 0 : job.status,
        job === null || job === void 0 ? void 0 : job.showBespokeFinancePlans,
        job === null || job === void 0 ? void 0 : job.showSelinaFinance,
    ]);
    var requiresUpdateJob = useCallback(function (formState) {
        if (isFetchingJob || isUpdatingJob)
            return true;
        return !_.isEqual(formState, initialFormState);
    }, [isFetchingJob, isUpdatingJob, initialFormState]);
    var updateJob = useCallback(function (formState) {
        var updateJobRequestData = formState;
        if (!updateJobRequestData.jobId)
            return;
        if (requiresUpdateJob(formState)) {
            dispatch(jobsActions.requestUpdateJob(updateJobRequestData, onJobUpdateSuccess));
        }
    }, [dispatch, onJobUpdateSuccess, requiresUpdateJob]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        var _a;
        if (isSaveRequired) {
            updateJob(formState);
            setIsSaveRequired(false);
        }
        return (React.createElement("div", { className: "job-side-panel-form-contents" },
            jobIsProposition && (React.createElement(React.Fragment, null,
                React.createElement(JobStatusControl, { jobStatus: formState.status, onFieldChange: function (value) {
                        var updateObject = {
                            status: value.status,
                            depositAmount: undefined,
                            minimumdepositAmount: undefined,
                            depositPercentage: undefined,
                            minimumDepositPercentage: undefined,
                            showZeroPercentFinance: false,
                        };
                        onFieldChange(updateObject);
                        updateJob(__assign(__assign({}, formState), updateObject));
                    } }),
                React.createElement("hr", null))),
            React.createElement(JobReferenceControl, { customReference: formState.customReference, onFieldChange: function (value) {
                    onFieldChange(value);
                }, reference: (_a = job === null || job === void 0 ? void 0 : job.reference) === null || _a === void 0 ? void 0 : _a.toString(), onFieldBlur: function () {
                    setIsSaveRequired(true);
                } }),
            React.createElement("hr", null),
            React.createElement(JobTotalsAndValueModifiersControl, { jobId: job.id, updateJobFormState: formState, onChange: function (value) {
                    onFieldChange(value);
                    updateJob(__assign(__assign({}, formState), value));
                }, showZeroPercentFinance: formState.showBespokeFinancePlans }),
            React.createElement(PaymentOptions, { onChange: function (value) {
                    var updateObject = {};
                    if ('showBACSPaymentOption' in value) {
                        updateObject.showBacsPaymentOption =
                            value.showBACSPaymentOption;
                    }
                    if ('showStripePaymentOption' in value) {
                        updateObject.showStripePaymentOption =
                            value.showStripePaymentOption;
                    }
                    onFieldChange(updateObject);
                    updateJob(__assign(__assign({}, formState), updateObject));
                }, showBACSPaymentOption: formState.showBacsPaymentOption, showStripePaymentOption: formState.showStripePaymentOption }),
            React.createElement(FinanceOptions, { hasZeroPercentFinanceOption: isQuote(formState.status), onChange: function (value) {
                    var updateObject = {};
                    if ('showFinanceOptions' in value) {
                        updateObject.showFinanceOptions = value.showFinanceOptions;
                    }
                    if ('showZeroPercentFinance' in value) {
                        updateObject.showBespokeFinancePlans =
                            value.showZeroPercentFinance;
                        updateObject.depositAmount = null;
                        updateObject.depositPercentage = null;
                        updateObject.minimumDepositAmount = null;
                        updateObject.minimumDepositPercentage = null;
                    }
                    if ('showSelinaFinance' in value) {
                        updateObject.showSelinaFinance = value.showSelinaFinance;
                    }
                    onFieldChange(updateObject);
                    updateJob(__assign(__assign({}, formState), updateObject));
                }, showFinanceOptions: formState.showFinanceOptions, showZeroPercentFinance: formState.showBespokeFinancePlans, showSelinaFinance: formState.showSelinaFinance }),
            React.createElement(TextareaField, { name: "jobDescription", label: "Job description", value: formState.jobDescription, onChange: onFieldChange, changeTimeoutMs: 2000, onBlur: function () { return setIsSaveRequired(true); }, onChangeTimeout: function () { return setIsSaveRequired(true); } }),
            React.createElement(TextareaField, { name: "jobNotes", label: "Notes for customer", value: formState.jobNotes, onChange: onFieldChange, changeTimeoutMs: 2000, onBlur: function () { return setIsSaveRequired(true); }, onChangeTimeout: function () { return setIsSaveRequired(true); } }),
            jobIsProposition && (React.createElement(BasicField, { styleVariant: InputStyleVariant.OUTSIZE, label: "Valid for (days)", name: "validForDays", value: formState.validUntil
                    ? moment(formState.validUntil).diff(moment(), 'days')
                    : null, type: "number", onChange: function (value) {
                    onFieldChange({
                        validUntil: value.validForDays
                            ? moment()
                                .add(value.validForDays, 'days')
                                .endOf('day')
                                .toDate()
                            : null,
                    });
                }, onBlur: function () { return setIsSaveRequired(true); }, onChangeTimeout: function () { return setIsSaveRequired(true); } })),
            !jobIsProposition && (React.createElement(BasicField, { styleVariant: InputStyleVariant.OUTSIZE, label: "Invoice due in (days)", name: "dueInDays", value: formState.dueAt
                    ? moment(formState.dueAt).diff(moment(), 'days')
                    : null, type: "number", onChange: function (value) {
                    onFieldChange({
                        dueAt: value.dueInDays
                            ? moment()
                                .add(value.dueInDays, 'days')
                                .endOf('day')
                                .toDate()
                            : null,
                    });
                }, onBlur: function () { return setIsSaveRequired(true); }, onChangeTimeout: function () { return setIsSaveRequired(true); } }))));
    }, [job, updateJob, isUpdatingJob, accountHasStripeConnection, isSaveRequired]);
    return (React.createElement("div", { className: "job-side-panel" },
        React.createElement(ValidatedForm, { initialFormState: initialFormState, renderFormContents: renderFormContents })));
};
export default JobSidePanel;
