import { action } from 'typesafe-actions';
import { AccountActionTypes } from './accountTypes';
// Account access information
export var requestGetAccountAccessInformation = function () {
    return action(AccountActionTypes.REQUEST_GET_ACCOUNT_ACCESS_INFORMATION);
};
export var clearAccountAccessInformation = function () {
    return action(AccountActionTypes.CLEAR_ACCOUNT_ACCESS_INFORMATION);
};
export var getAccountAccessInformationSuccess = function (accountAccessInformation) {
    return action(AccountActionTypes.GET_ACCOUNT_ACCESS_INFORMATION_SUCCESS, {
        accountAccessInformation: accountAccessInformation,
    });
};
export var getAccountAccessInformationFailure = function (error) {
    return action(AccountActionTypes.GET_ACCOUNT_ACCESS_INFORMATION_FAILURE, null, null, error);
};
// Update business settings prompt
export var setRequiresUpdateBusinessSettingsPrompt = function (requiresUpdateBusinessSettingsPrompt) {
    return action(AccountActionTypes.SET_REQUIRES_UPDATE_BUSINESS_SETTINGS_PROMPT, {
        requiresUpdateBusinessSettingsPrompt: requiresUpdateBusinessSettingsPrompt,
    });
};
// Book demo
export var requestBookDemo = function (bookDemoFor, isImmediateCallback) {
    return action(AccountActionTypes.REQUEST_BOOK_DEMO, {
        bookDemoFor: bookDemoFor,
        isImmediateCallback: isImmediateCallback,
    });
};
export var bookDemoSuccess = function () {
    return action(AccountActionTypes.BOOK_DEMO_SUCCESS);
};
export var bookDemoFailure = function (error) {
    return action(AccountActionTypes.BOOK_DEMO_FAILURE, null, null, error);
};
// Email templates
export var requestUpdateEmailTemplates = function (accountId, emailTemplates, callback) {
    return action(AccountActionTypes.REQUEST_UPDATE_EMAIL_TEMPLATES, {
        accountId: accountId,
        emailTemplates: emailTemplates,
        callback: callback,
    });
};
export var requestGetEmailTemplates = function (accountId, includeDefaults) {
    if (includeDefaults === void 0) { includeDefaults = false; }
    return action(AccountActionTypes.REQUEST_GET_EMAIL_TEMPLATES, {
        accountId: accountId,
        includeDefaults: includeDefaults,
    });
};
export var getEmailTemplatesSuccess = function (emailTemplates) {
    return action(AccountActionTypes.GET_EMAIL_TEMPLATES_SUCCESS, {
        emailTemplates: emailTemplates,
    });
};
export var getEmailTemplatesFailure = function (error, errorMessage) {
    return action(AccountActionTypes.GET_EMAIL_TEMPLATES_FAILURE, { errorMessage: errorMessage }, null, error);
};
export var storeEmailTemplates = function (emailTemplates) {
    return action(AccountActionTypes.STORE_EMAIL_TEMPLATES, { emailTemplates: emailTemplates });
};
export var clearEmailTemplates = function () {
    return action(AccountActionTypes.CLEAR_EMAIL_TEMPLATES);
};
export var requestSendDemoQuote = function (sendToEmailAddress, name, phone, businessName, industry, employees, notes, token, ownerUserId) {
    return action(AccountActionTypes.REQUEST_SEND_DEMO_QUOTE, {
        sendToEmailAddress: sendToEmailAddress,
        name: name,
        phone: phone,
        businessName: businessName,
        industry: industry,
        employees: employees,
        notes: notes,
        token: token,
        ownerUserId: ownerUserId,
    });
};
export var sendDemoQuoteSuccess = function () {
    return action(AccountActionTypes.SEND_DEMO_QUOTE_SUCCESS);
};
export var sendDemoQuoteFailure = function (error) {
    return action(AccountActionTypes.SEND_DEMO_QUOTE_FAILURE, null, null, error);
};
