import React, { useMemo } from 'react';
import './KeyIndicatorData.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@bit/payaca-tech.payaca-core.component.tooltip';
var KeyIndicatorData = function (_a) {
    var title = _a.title, titleMicrocopy = _a.titleMicrocopy, explainerText = _a.explainerText, currentPeriodValue = _a.currentPeriodValue, previousPeriodValue = _a.previousPeriodValue, percentageChange = _a.percentageChange, formatValue = _a.formatValue;
    var percentageChangeElement = useMemo(function () {
        var isIncrease = percentageChange >= 0;
        return (React.createElement("span", { className: "percentage-change-element " + (isIncrease ? 'increase' : 'decrease') },
            React.createElement(FontAwesomeIcon, { icon: isIncrease ? faArrowUp : faArrowDown }),
            !!previousPeriodValue && (React.createElement("span", null,
                (Math.abs(percentageChange) * 100).toFixed(2),
                "%"))));
    }, [percentageChange, previousPeriodValue]);
    return (React.createElement("div", { className: "key-indicator-data" },
        React.createElement("div", { className: "title-container" },
            React.createElement("h3", null,
                React.createElement("span", null, title),
                ' ',
                explainerText && React.createElement(Tooltip, { text: explainerText })),
            titleMicrocopy && React.createElement("span", { className: "microcopy" }, titleMicrocopy)),
        React.createElement("span", { className: "current-period-value" }, formatValue ? formatValue(currentPeriodValue) : currentPeriodValue),
        React.createElement("div", { className: "previous-period-value-container" },
            percentageChangeElement,
            React.createElement("span", { className: "previous-period-value" },
                "prev:",
                ' ',
                formatValue ? formatValue(previousPeriodValue) : previousPeriodValue))));
};
export default KeyIndicatorData;
