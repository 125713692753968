var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionType } from './dealsTypes';
export var initialState = {
    deals: {},
    isSettingDealCustomer: false,
    isDealCustomerSetSuccessfully: undefined,
    isArchivingDeals: false,
    areDealsArchivedSuccessfully: false,
    isGettingListedDealsPage: false,
    listedDealsPage: null
};
var dealsReducer = function (state, action) {
    var _a, _b, _c;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionType.REQUEST_GET_DEAL:
        case ActionType.REQUEST_UPDATE_DEAL: {
            var fetchedEntity = state.deals && state.deals[action.payload.dealId];
            return __assign(__assign({}, state), { deals: __assign(__assign({}, state.deals), (_a = {}, _a[action.payload.dealId] = __assign(__assign({}, fetchedEntity), { fetchAttemptedAttemptedAt: new Date(), fetchSucceededAt: undefined, fetchFailedAt: undefined, isFetching: true, fetchError: undefined }), _a)) });
        }
        case ActionType.GET_DEAL_SUCCESS:
        case ActionType.UPDATE_DEAL_SUCCESS: {
            var fetchedEntity = state.deals && state.deals[action.payload.dealId];
            return __assign(__assign({}, state), { deals: __assign(__assign({}, state.deals), (_b = {}, _b[action.payload.dealId] = __assign(__assign({}, fetchedEntity), { entity: action.payload.deal, fetchSucceededAt: new Date(), isFetching: false }), _b)) });
        }
        case ActionType.GET_DEAL_FAILURE:
        case ActionType.UPDATE_DEAL_FAILURE: {
            var fetchedEntity = state.deals && state.deals[action.payload.dealId];
            return __assign(__assign({}, state), { deals: __assign(__assign({}, state.deals), (_c = {}, _c[action.payload.dealId] = __assign(__assign({}, fetchedEntity), { fetchFailedAt: new Date(), isFetching: false, fetchError: action.error }), _c)) });
        }
        case ActionType.CLEAR_DEAL: {
            var deals = state.deals || {};
            delete deals[action.payload.dealId];
            return __assign(__assign({}, state), { deals: deals });
        }
        case ActionType.CLEAR_DEALS: {
            return __assign(__assign({}, state), { deals: {} });
        }
        case ActionType.REQUEST_SET_DEAL_CUSTOMER:
        case ActionType.REQUEST_UNSET_DEAL_CUSTOMER:
            return __assign(__assign({}, state), { isSettingDealCustomer: true, isDealCustomerSetSuccessfully: undefined });
        case ActionType.SET_DEAL_CUSTOMER_SUCCESS:
        case ActionType.UNSET_DEAL_CUSTOMER_SUCCESS:
            return __assign(__assign({}, state), { isSettingDealCustomer: false, isDealCustomerSetSuccessfully: true });
        case ActionType.SET_DEAL_CUSTOMER_FAILURE:
        case ActionType.UNSET_DEAL_CUSTOMER_FAILURE:
            return __assign(__assign({}, state), { isSettingDealCustomer: false, isDealCustomerSetSuccessfully: false });
        case ActionType.REQUEST_GET_LISTED_DEALS_PAGE:
            return __assign(__assign({}, state), { isGettingListedDealsPage: true });
        case ActionType.GET_LISTED_DEALS_PAGE_FAILURE:
            return __assign(__assign({}, state), { listedDealsPage: null, isGettingListedDealsPage: false });
        case ActionType.GET_LISTED_DEALS_PAGE_SUCCESS:
            return __assign(__assign({}, state), { listedDealsPage: action.payload.listedDealsPage, isGettingListedDealsPage: false });
        case ActionType.CLEAR_LISTED_DEALS_PAGE:
            return __assign(__assign({}, state), { listedDealsPage: null });
        case ActionType.REQUEST_ARCHIVE_DEALS:
            return __assign(__assign({}, state), { isArchivingDeals: true, areDealsArchivedSuccessfully: undefined });
        case ActionType.ARCHIVE_DEALS_SUCCESS:
            return __assign(__assign({}, state), { isArchivingDeals: false, areDealsArchivedSuccessfully: true });
        case ActionType.ARCHIVE_DEALS_FAILURE:
            return __assign(__assign({}, state), { isArchivingDeals: false, areDealsArchivedSuccessfully: false });
        default:
            return state;
    }
};
export default dealsReducer;
