import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IntercomAPI } from 'react-intercom';
import { BasicField } from '@payaca/components';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import LoqateAdvisoryEmailInputField from '../loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';
import LoqateAdvisoryPhoneInputField from '../loqateAdvisoryPhoneInputField/LoqateAdvisoryPhoneInputField';
import { actions as accountSettingsActions } from '@/api/accountSettings';
var BusinessSettingsCompanyDetails = function (_a) {
    var readOnly = _a.readOnly;
    var dispatch = useDispatch();
    var pendingAccount = useSelector(function (state) {
        return state.accountSettings.pendingAccount;
    });
    var isConnectedToQuickbooks = useSelector(function (state) { var _a; return !!((_a = state.users.myProfile.accounts[0].integrations) === null || _a === void 0 ? void 0 : _a.quickbooks); });
    var isConnectedToXero = useSelector(function (state) { var _a; return !!((_a = state.users.myProfile.accounts[0].integrations) === null || _a === void 0 ? void 0 : _a.xero); });
    var isCisSubcontractor = useSelector(function (state) {
        return state.users.myProfile.accounts[0].isCisSubcontractor;
    });
    var cisDeductionRate = useSelector(function (state) {
        return state.users.myProfile.accounts[0].cisDeductionRate;
    });
    // const fieldData = useMemo(() => {
    //   return {
    //     sections: [
    //       {
    //         fields: [
    //           {
    //             label: 'Trading name',
    //             name: 'businessName',
    //           },
    //           {
    //             label: 'Business email',
    //             name: 'email',
    //           },
    //           {
    //             label: 'Business phone',
    //             name: 'contactNumber',
    //             type: 'tel',
    //           },
    //         ],
    //       },
    //       {
    //         label: 'Address',
    //         fields: [
    //           {
    //             label: 'Address line 1',
    //             name: 'firstLineAddress',
    //           },
    //           {
    //             label: 'Address line 2',
    //             name: 'secondLineAddress',
    //           },
    //           {
    //             label: 'Town',
    //             name: 'city',
    //           },
    //           {
    //             label: 'Postcode',
    //             name: 'postcode',
    //           },
    //         ],
    //       },
    //       {
    //         label: 'Line items',
    //         sectionInformation:
    //           'Default behaviour for hiding item prices on your quotes, estimates and invoices. This can be changed on a per job basis.',
    //         fields: [
    //           {
    //             render: () => (
    //               <div className="field validated-field">
    //                 <CheckboxField
    //                   name="hideItemPrices"
    //                   isDisabled={readOnly}
    //                   value={pendingAccount.hideItemPrices}
    //                   label="Hide item prices"
    //                   onChange={(value) =>
    //                     dispatch(
    //                       accountSettingsActions.storeAccountPendingChanges(value)
    //                     )
    //                   }
    //                 />
    //               </div>
    //             ),
    //           },
    //         ],
    //       },
    //       {
    //         label: 'Construction industry scheme',
    //         fields: [
    //           {
    //             render: () => (
    //               <div className="field validated-field">
    //                 <CheckboxField
    //                   name="isCisSubcontractor"
    //                   isDisabled={readOnly}
    //                   value={pendingAccount.isCisSubcontractor}
    //                   label={'I am a CIS subcontractor'}
    //                   onChange={(value) => {
    //                     dispatch(
    //                       accountSettingsActions.storeAccountPendingChanges({
    //                         ...value,
    //                         cisDeductionRate:
    //                           value.isCisSubcontractor === true ? 20 : null,
    //                       })
    //                     );
    //                   }}
    //                 />
    //               </div>
    //             ),
    //           },
    //           {
    //             render: () =>
    //               pendingAccount.isCisSubcontractor && (
    //                 <div className="field validated-field">
    //                   <DropdownField
    //                     name="cisDeductionRate"
    //                     isDisabled={readOnly}
    //                     value={pendingAccount.cisDeductionRate}
    //                     label={'CIS deduction rate'}
    //                     onChange={(value) => {
    //                       dispatch(
    //                         accountSettingsActions.storeAccountPendingChanges(
    //                           value
    //                         )
    //                       );
    //                     }}
    //                     options={[
    //                       {
    //                         label: '0%',
    //                         value: 0,
    //                       },
    //                       {
    //                         label: '20%',
    //                         value: 20,
    //                       },
    //                       {
    //                         label: '30%',
    //                         value: 30,
    //                       },
    //                     ]}
    //                   />
    //                 </div>
    //               ),
    //           },
    //           {
    //             render: () =>
    //               isCisSubcontractor &&
    //               !pendingAccount.isCisSubcontractor && (
    //                 <div style={{ marginBottom: 10 }}>
    //                   <CalloutPanel type={CalloutPanelType.ALERT}>
    //                     <div style={{ fontSize: '0.9rem' }}>
    //                       If you mark this company as no longer being a CIS
    //                       subcontractor, CIS deduction rates will be removed from
    //                       all line items, and from any quotes and invoices which
    //                       have not been sent. CIS deduction rates will remain on
    //                       any sent quotes and invoices.
    //                     </div>
    //                   </CalloutPanel>
    //                 </div>
    //               ),
    //           },
    //           {
    //             render: () =>
    //               isCisSubcontractor &&
    //               pendingAccount.isCisSubcontractor &&
    //               pendingAccount.cisDeductionRate != cisDeductionRate && (
    //                 <div style={{ marginBottom: 10 }}>
    //                   <CalloutPanel type={CalloutPanelType.ALERT}>
    //                     <div style={{ fontSize: '0.9rem' }}>
    //                       If you change the CIS deduction rate for this company,
    //                       CIS deduction rates will be updated against all
    //                       applicable line items and against any quotes and
    //                       invoices which have not been sent. The old CIS deduction
    //                       rate will remain on any sent quotes and invoices.
    //                     </div>
    //                   </CalloutPanel>
    //                 </div>
    //               ),
    //           },
    //           {
    //             render: () =>
    //               isConnectedToQuickbooks &&
    //               pendingAccount.isCisSubcontractor && (
    //                 <div style={{ marginBottom: 10 }}>
    //                   <CalloutPanel type={CalloutPanelType.ERROR}>
    //                     <div style={{ fontSize: '0.9rem' }}>
    //                       Your account is connected to Quickbooks, but our
    //                       Quickbooks integration does not currently support CIS
    //                       behaviours.
    //                     </div>
    //                   </CalloutPanel>
    //                 </div>
    //               ),
    //           },
    //           {
    //             render: () =>
    //               isConnectedToXero &&
    //               pendingAccount.isCisSubcontractor && (
    //                 <div style={{ marginBottom: 10 }}>
    //                   <CalloutPanel type={CalloutPanelType.ALERT}>
    //                     <div style={{ fontSize: '0.9rem' }}>
    //                       <p>
    //                         Your account is connected to Xero. You must have CIS
    //                         behaviours enabled in Xero for the integration to
    //                         function. Please{' '}
    //                         <strong>
    //                           <a
    //                             href="https://central.xero.com/s/article/Enable-CIS-in-your-organisation"
    //                             target="_blank"
    //                             rel="noopener noreferrer"
    //                           >
    //                             follow these instructions
    //                           </a>
    //                         </strong>{' '}
    //                         to enable CIS for your organisation in Xero if you
    //                         haven&#39;t already.
    //                       </p>
    //                       <p>
    //                         Make sure your CIS subcontractor deduction rates in
    //                         Xero and Payaca match.
    //                       </p>
    //                     </div>
    //                   </CalloutPanel>
    //                 </div>
    //               ),
    //           },
    //         ],
    //       },
    //     ],
    //     onChange: (change: any) =>
    //       dispatch(accountSettingsActions.storeAccountPendingChanges(change)),
    //     validations: FormValidations.businessSettings,
    //     values: pendingAccount || {},
    //   };
    // }, [
    //   pendingAccount,
    //   isConnectedToQuickbooks,
    //   isCisSubcontractor,
    //   cisDeductionRate,
    //   isConnectedToXero,
    // ]);
    var onFieldChange = useCallback(function (change) {
        dispatch(accountSettingsActions.storeAccountPendingChanges(change));
    }, [dispatch]);
    return (React.createElement("div", { className: "company-settings-company-info" },
        React.createElement("div", null,
            React.createElement("p", null,
                "Your registered business name is stored as",
                ' ',
                React.createElement("strong", null, pendingAccount.legalBusinessName),
                ". If this is incorrect or you wish to change it,",
                ' ',
                React.createElement("span", { onClick: function () { return IntercomAPI('show'); }, className: "clickable" }, "please contact us directly"),
                ".")),
        React.createElement("div", { className: "settings-sub-section" },
            React.createElement(BasicField, { name: "businessName", label: "Trading name", value: pendingAccount.businessName, isRequired: true, styleVariant: InputStyleVariant.OUTSIZE, onChange: onFieldChange }),
            React.createElement(LoqateAdvisoryEmailInputField, { name: "email", label: 'Email address', value: pendingAccount.email, styleVariant: InputStyleVariant.OUTSIZE, onChange: onFieldChange }),
            React.createElement(LoqateAdvisoryPhoneInputField, { name: "contactNumber", label: 'Telephone number', value: pendingAccount.contactNumber, styleVariant: InputStyleVariant.OUTSIZE, onChange: onFieldChange }),
            React.createElement(BasicField, { name: "firstLineAddress", label: "Address line 1", value: pendingAccount.firstLineAddress, styleVariant: InputStyleVariant.OUTSIZE, onChange: onFieldChange }),
            React.createElement(BasicField, { name: "secondLineAddress", label: "Address line 2", value: pendingAccount.secondLineAddress, styleVariant: InputStyleVariant.OUTSIZE, onChange: onFieldChange }),
            React.createElement(BasicField, { name: "city", label: "Town", value: pendingAccount.city, styleVariant: InputStyleVariant.OUTSIZE, onChange: onFieldChange }),
            React.createElement(BasicField, { name: "postcode", label: "Postcode", value: pendingAccount.postcode, styleVariant: InputStyleVariant.OUTSIZE, onChange: onFieldChange }))));
};
export default BusinessSettingsCompanyDetails;
