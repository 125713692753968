import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import './ListedCustomersTable.sass';
import MiniLoader from '@bit/payaca-tech.payaca-core.component.mini-loader';
import { Checkbox } from '@payaca/components/checkbox';
import { RowSelectionTableCell } from '@payaca/components/rowSelectionTableCell';
import EmptyState from '../emptyState/EmptyState';
import XeroLogo from '../../../assets/images/xero.svg';
import QuickbooksLogo from '../../../assets/images/quickbooks.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { PaginationControl } from '@payaca/components/paginationControl';
var ListedCustomersTable = function (_a) {
    var _b;
    var _c = _a.selectedCustomerIds, selectedCustomerIds = _c === void 0 ? [] : _c, onClickRow = _a.onClickRow, onSelectCustomers = _a.onSelectCustomers, onUnselectCustomers = _a.onUnselectCustomers, onSelectPage = _a.onSelectPage;
    var listedCustomersPage = useSelector(function (state) {
        var _a;
        return (_a = state.customer) === null || _a === void 0 ? void 0 : _a.listedCustomersPage;
    });
    var canSelectCustomers = useMemo(function () {
        return !!(onSelectCustomers && onUnselectCustomers);
    }, [onUnselectCustomers, onSelectCustomers]);
    var isGettingListedCustomersPage = useSelector(function (state) { return state.customer.isGettingListedCustomersPage; });
    var accountHasAnyIntegration = useSelector(function (state) {
        var integrations = state.users.myProfile.accounts[0].integrations;
        return !!(integrations.xero || integrations.quickbooks);
    });
    var columnCount = useMemo(function () {
        var count = 5;
        if (accountHasAnyIntegration)
            count++;
        if (canSelectCustomers)
            count++;
        return count;
    }, [accountHasAnyIntegration, canSelectCustomers]);
    var allCustomerIds = useMemo(function () {
        if (!(listedCustomersPage === null || listedCustomersPage === void 0 ? void 0 : listedCustomersPage.items))
            return [];
        return listedCustomersPage.items.map(function (listedCustomer) { return listedCustomer.customerId; });
    }, [listedCustomersPage]);
    var isAllCustomersSelected = useMemo(function () {
        return (allCustomerIds.sort().join(',') === selectedCustomerIds.sort().join(','));
    }, [allCustomerIds, selectedCustomerIds]);
    var renderTableHead = useMemo(function () {
        return (React.createElement("thead", null,
            React.createElement("tr", null,
                canSelectCustomers && (React.createElement("th", { className: "row-selection-table-cell" },
                    React.createElement(Checkbox, { isChecked: isAllCustomersSelected, onChange: function () {
                            isAllCustomersSelected
                                ? onUnselectCustomers && onUnselectCustomers(allCustomerIds)
                                : onSelectCustomers && onSelectCustomers(allCustomerIds);
                        } }))),
                React.createElement("th", null, "Name"),
                React.createElement("th", null, "Email"),
                React.createElement("th", null, "Phone"),
                React.createElement("th", null, "Address"),
                React.createElement("th", { className: "align-center" }, "Multiple contacts?"),
                accountHasAnyIntegration && React.createElement("th", { className: "align-center" }, "Import"))));
    }, [
        canSelectCustomers,
        isAllCustomersSelected,
        allCustomerIds,
        accountHasAnyIntegration,
        onUnselectCustomers,
        onSelectCustomers,
    ]);
    var renderTableRow = useCallback(function (listedCustomer, index) {
        var customerIsSelected = selectedCustomerIds.includes(listedCustomer.customerId);
        return (React.createElement("tr", { key: index, className: (customerIsSelected ? 'selected' : '') + " " + (onClickRow ? 'clickable' : ''), onClick: function () { return onClickRow && onClickRow(listedCustomer.customerId); } },
            canSelectCustomers && (React.createElement(RowSelectionTableCell, { isSelected: customerIsSelected, onChange: function () {
                    customerIsSelected
                        ? onUnselectCustomers &&
                            onUnselectCustomers([listedCustomer.customerId])
                        : onSelectCustomers &&
                            onSelectCustomers([listedCustomer.customerId]);
                } })),
            React.createElement("td", { className: "tr-start" },
                React.createElement("strong", null, listedCustomer.customerName)),
            React.createElement("td", null, listedCustomer.primaryContactEmailAddress),
            React.createElement("td", null, listedCustomer.primaryContactTelephoneNumber),
            React.createElement("td", null, listedCustomer.address),
            React.createElement("td", { className: "has-multiple-contacts-table-cell " + (accountHasAnyIntegration ? '' : 'tr-end') }, listedCustomer.contactsCount > 1 && (React.createElement(FontAwesomeIcon, { icon: faCheck }))),
            accountHasAnyIntegration && (React.createElement("td", { className: "synced-with-integrations-table-cell tr-end" },
                React.createElement("div", { className: "synced-with-integrations-container" },
                    listedCustomer.syncedWithIntegrations.includes('Xero') && (React.createElement("img", { alt: "Xero", src: XeroLogo })),
                    listedCustomer.syncedWithIntegrations.includes('Quickbooks') && React.createElement("img", { alt: "Quickbooks", src: QuickbooksLogo }))))));
    }, [
        onClickRow,
        canSelectCustomers,
        selectedCustomerIds,
        onSelectCustomers,
        onUnselectCustomers,
    ]);
    var paginationControl = useMemo(function () {
        if (!listedCustomersPage)
            return;
        return (React.createElement(PaginationControl, { pageNumber: listedCustomersPage.pageNumber, pageSize: listedCustomersPage.pageSize, totalItemCount: listedCustomersPage.totalItemCount, onSelectPage: onSelectPage }));
    }, [listedCustomersPage, onSelectPage]);
    return (React.createElement("div", { className: "listed-customers-table" },
        React.createElement("div", { className: "scrollable-table-wrapper styled-table-wrapper" },
            React.createElement("table", { className: "styled-table" },
                renderTableHead,
                React.createElement("tbody", null,
                    isGettingListedCustomersPage && (React.createElement("tr", null,
                        React.createElement("td", { colSpan: columnCount, className: "tr-exclude" },
                            React.createElement("div", { className: "loader-container" },
                                React.createElement(MiniLoader, null))))),
                    !isGettingListedCustomersPage &&
                        !(listedCustomersPage === null || listedCustomersPage === void 0 ? void 0 : listedCustomersPage.totalItemCount) &&
                        !(listedCustomersPage === null || listedCustomersPage === void 0 ? void 0 : listedCustomersPage.totalUnfilteredItemCount) && (React.createElement("tr", null,
                        React.createElement("td", { colSpan: columnCount, className: "tr-exclude" },
                            React.createElement(EmptyState, { configName: 'customers', arrowIndication: false })))),
                    !isGettingListedCustomersPage &&
                        !(listedCustomersPage === null || listedCustomersPage === void 0 ? void 0 : listedCustomersPage.totalItemCount) &&
                        !!(listedCustomersPage === null || listedCustomersPage === void 0 ? void 0 : listedCustomersPage.totalUnfilteredItemCount) && (React.createElement("tr", null,
                        React.createElement("td", { colSpan: columnCount, className: "tr-exclude" },
                            React.createElement("div", { className: "no-results-container" },
                                React.createElement("span", null, "No results found"))))),
                    !isGettingListedCustomersPage &&
                        !!((_b = listedCustomersPage === null || listedCustomersPage === void 0 ? void 0 : listedCustomersPage.items) === null || _b === void 0 ? void 0 : _b.length) && (React.createElement(React.Fragment, null, listedCustomersPage.items.map(renderTableRow)))))),
        paginationControl));
};
export default ListedCustomersTable;
