import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import { actions as authActions } from '@bit/payaca-tech.payaca-core.store.auth';
import { useHistory } from 'react-router';
import BasicPageWrapper from '../pageWrappers/basicPageWrapper/BasicPageWrapper';
var XeroCallbackPage = function () {
    var dispatch = useDispatch();
    var history = useHistory();
    var _a = useState(false), isSubmitted = _a[0], setIsSubmitted = _a[1];
    var isLoggingIn = useSelector(function (state) { return state.auth.isLoggingIn; });
    var isLoggedInSuccessfully = useSelector(function (state) { return state.auth.isLoggedInSuccessfully; });
    useEffect(function () {
        dispatch(authActions.logout());
    }, []);
    useEffect(function () {
        if (isLoggedInSuccessfully && isSubmitted) {
            history.push('/');
        }
        else if (isSubmitted && !isLoggedInSuccessfully && !isLoggingIn) {
            history.push('/?error=An error occurred logging into Xero, please try again or use an alternative method.');
        }
    }, [isLoggedInSuccessfully, isSubmitted, history, isLoggingIn]);
    useEffect(function () {
        var query = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });
        // TODO: generate state query before login, passed in params to xero and returned to check no modifications
        if (query.state &&
            typeof query.state === 'string' &&
            query.state.endsWith('_nativeRegister')) {
            // If state includes native - redirect to mobile and login there with returned code
            //@ts-ignore
            window.location = "Payaca://register/xero=" + query.code;
        }
        else if (query.state &&
            typeof query.state === 'string' &&
            (query.state.endsWith('_nativeLogin') || query.state.endsWith('_native'))) {
            //@ts-ignore
            window.location = "Payaca://login/xero=" + query.code;
        }
        else {
            if (query.code) {
                setIsSubmitted(true);
                dispatch(authActions.requestLoginWithXero({ code: query.code }));
            }
        }
    }, []);
    return (
    //@ts-ignore
    React.createElement(BasicPageWrapper, null,
        React.createElement("div", null)));
};
export default XeroCallbackPage;
