export var JobActionType;
(function (JobActionType) {
    JobActionType["DUPLICATE"] = "duplicate";
    JobActionType["SEND"] = "send";
    JobActionType["RESEND"] = "resend";
    JobActionType["DELETE"] = "delete";
    JobActionType["ASSIGN"] = "assign";
    JobActionType["UNASSIGN"] = "unassign";
    JobActionType["INVOICE"] = "invoice";
    JobActionType["REMOVE_ACCEPTED"] = "remove-accepted";
    JobActionType["MARK_AS_COMPLETE"] = "mark-as-complete";
    JobActionType["MARK_AS_ACCEPTED"] = "mark-as-accepted";
    JobActionType["MARK_AS_DECLINED"] = "mark-as-declined";
    JobActionType["MARK_AS_SENT"] = "mark-as-sent";
    JobActionType["CONFIRM_PENDING_PAYMENT"] = "confirm-pending-payment";
    JobActionType["RECORD_PAYMENT"] = "record-payment";
    JobActionType["RECORD_DEPOSIT_PAYMENT"] = "record-deposit-payment";
    JobActionType["INACTIVATE_AND_EDIT"] = "inactivate-and-edit";
    JobActionType["EDIT"] = "edit";
})(JobActionType || (JobActionType = {}));
