import React, { useCallback, useMemo, useState, } from 'react';
import './PreviewJobActionButtons.sass';
import { useDispatch, useSelector } from 'react-redux';
import { getJobType } from '@payaca/helpers/jobHelperV2';
import { actions as jobActions } from '@/api/jobs';
import { actions as appActions } from '@/api/app';
import { IconButton, Button, ButtonStyleVariant, } from '@payaca/components/button';
import MiniLoader from '@bit/payaca-tech.payaca-core.component.mini-loader';
import { getActiveJobFinanceInformationByJobId, getJob, getJobPaymentsByJobId, getRelatedJobByDealId, } from '@/utils/stateAccessors';
import RecordPaymentModal from '../recordPaymentModal/RecordPaymentModal';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import CreateInvoiceFromQuote from '../createInvoiceFromQuote/CreateInvoiceFromQuote';
import { isEstimate, isInvoice, isQuote, isQuoteOrEstimate, } from '@bit/payaca-tech.payaca-core.helpers.job-status';
import { useHistory } from 'react-router';
import { actions as fileDownloadActions } from '@payaca/store/fileDownload';
import { FileDownloadTypes } from '@payaca/types/fileDownloadTypes';
import { getModal } from '@/helpers/modalHelper';
import ResendJobModal from '../resendJobModal/ResendJobModal';
import EditJobModal from '../editJobModal/EditJobModal';
import MarkAsCompleteModal from '../markAsCompleteModal/MarkAsCompleteModal';
import MarkAsSentModal from '../markAsSentModal/MarkAsSentModal';
import { getAvailableActionsForJob } from '@payaca/helpers/jobActionsHelper';
import { JobActionType } from '@payaca/types/jobActionsTypes';
var PreviewJobActionButtons = function (_a) {
    var jobId = _a.jobId, dealContextId = _a.dealContextId, onJobUpdateSuccess = _a.onJobUpdateSuccess, markAsSentCallback = _a.markAsSentCallback;
    var dispatch = useDispatch();
    var history = useHistory();
    var _b = useState(false), isProcessing = _b[0], setIsProcessing = _b[1];
    var _c = useState(false), showRecordPaymentModal = _c[0], setShowRecordPaymentModal = _c[1];
    var _d = useState(false), showResendJobModal = _d[0], setShowResendJobModal = _d[1];
    var _e = useState(false), showEditWarningModal = _e[0], setShowEditWarningModal = _e[1];
    var _f = useState(false), showMarkAsCompleteModal = _f[0], setShowMarkAsCompleteModal = _f[1];
    var _g = useState(false), showMarkAsSentModal = _g[0], setShowMarkAsSentModal = _g[1];
    var customer = useSelector(function (state) {
        return state.customer.currentCustomer;
    });
    var job = useSelector(function (state) {
        return getJob(state, jobId);
    });
    var relatedJob = useSelector(function (state) {
        return !!job && getRelatedJobByDealId(state, job.dealId, jobId);
    });
    var jobPayments = useSelector(function (state) {
        return getJobPaymentsByJobId(state, jobId);
    });
    var activeJobFinanceInformation = useSelector(function (state) {
        return getActiveJobFinanceInformationByJobId(state, jobId);
    });
    var availableActions = useMemo(function () {
        if (!job || !customer)
            return [];
        return getAvailableActionsForJob(job, customer, jobPayments || [], relatedJob ? [relatedJob] : [], activeJobFinanceInformation);
    }, [job, customer, jobPayments, relatedJob, activeJobFinanceInformation]);
    var jobIsArchivedOrInactivated = useMemo(function () {
        if (!job)
            return false;
        return job.archivedAt || job.inactivatedAt;
    }, [job]);
    var jobIsQuoteOrEstimate = useMemo(function () {
        if (!job)
            return false;
        return isQuoteOrEstimate(job.status);
    }, [job]);
    var acceptProposition = useCallback(function () {
        setIsProcessing(true);
        dispatch(jobActions.acceptQuote(jobId, function () {
            onJobUpdateSuccess && onJobUpdateSuccess();
            setIsProcessing(false);
        }));
    }, [dispatch, jobId, onJobUpdateSuccess]);
    var declineProposition = useCallback(function () {
        setIsProcessing(true);
        dispatch(jobActions.declineJob(jobId, function () {
            onJobUpdateSuccess && onJobUpdateSuccess();
            setIsProcessing(false);
        }));
    }, [dispatch, jobId, onJobUpdateSuccess]);
    var duplicateJob = useCallback(function () {
        setIsProcessing(true);
        dispatch(jobActions.duplicateJob(jobId, function (error, response) {
            if (!error) {
                history.push('/'); // this is needed so the page actually reloads - otherwise it sees that the route hasn't changed and doesn't do anything
                if (jobIsQuoteOrEstimate) {
                    history.push("/quotes/" + response.newJobId);
                }
                else {
                    history.push("/invoices/" + response.newJobId);
                }
            }
            setIsProcessing(false);
        }));
    }, [jobIsQuoteOrEstimate, jobId, history]);
    var deleteJob = useCallback(function () {
        dispatch(appActions.showModal(getModal('DELETE_JOB', {
            primaryAction: function () {
                setIsProcessing(true);
                dispatch(jobActions.archiveJob(jobId, function () {
                    dispatch(appActions.hideModal());
                    setIsProcessing(false);
                    if (dealContextId) {
                        return onJobUpdateSuccess && onJobUpdateSuccess();
                    }
                    history.push("/deals");
                }));
            },
            secondaryAction: function () { return dispatch(appActions.hideModal()); },
            onClose: function () { return dispatch(appActions.hideModal()); },
        })));
    }, [dispatch, job, history, dealContextId]);
    var unacceptJob = useCallback(function () {
        setIsProcessing(true);
        dispatch(jobActions.unacceptQuote(jobId, function () {
            onJobUpdateSuccess && onJobUpdateSuccess();
            setIsProcessing(false);
        }));
    }, [dispatch, jobId, onJobUpdateSuccess]);
    var pdfFileName = useMemo(function () {
        if (!job)
            return '';
        if (isInvoice(job.status)) {
            return "invoice_" + job.reference + ".pdf";
        }
        else if (isQuote(job.status)) {
            return "quote_" + job.reference + ".pdf";
        }
        else if (isEstimate(job.status)) {
            return "estimate_" + job.reference + ".pdf";
        }
        else {
            return "job_" + job.reference + ".pdf";
        }
    }, [job]);
    var triggerFileDownload = useCallback(function (fileContent) {
        var link = document.createElement('a');
        link.href = "data:application/pdf;base64," + fileContent;
        link.download = pdfFileName;
        link.dispatchEvent(new MouseEvent('click'));
    }, [pdfFileName]);
    var downloadAsPdfIconButton = useMemo(function () {
        if (!job)
            return;
        return (React.createElement(IconButton, { onClick: function () {
                dispatch(fileDownloadActions.requestDownloadFile(jobId, isInvoice(job.status)
                    ? FileDownloadTypes.INVOICE
                    : FileDownloadTypes.QUOTE, triggerFileDownload));
            }, label: "Download as pdf", icon: "Download" }));
    }, [dispatch, job, jobId, triggerFileDownload]);
    if (!job)
        return null;
    if (jobIsArchivedOrInactivated)
        return null;
    if (isProcessing)
        return (React.createElement("div", { className: "preview-job-action-buttons loader-container" },
            React.createElement(MiniLoader, null)));
    return (React.createElement("div", { className: "preview-job-action-buttons" },
        React.createElement("div", { className: "preview-job-action-buttons-inner" },
            availableActions.includes(JobActionType.MARK_AS_COMPLETE) && (React.createElement(Button, { onClick: function () { return setShowMarkAsCompleteModal(true); }, styleVariant: ButtonStyleVariant.OUTSIZE }, "Send a satisfaction note")),
            availableActions.includes(JobActionType.MARK_AS_ACCEPTED) && (React.createElement(IconButton, { onClick: acceptProposition, label: "Mark as accepted", icon: "TickSquare" })),
            availableActions.includes(JobActionType.MARK_AS_DECLINED) && (React.createElement(IconButton, { onClick: declineProposition, label: "Mark as declined", icon: "CloseSquare" })),
            availableActions.includes(JobActionType.RECORD_DEPOSIT_PAYMENT) && (React.createElement(IconButton, { onClick: function () { return setShowRecordPaymentModal(true); }, label: "Record deposit payment", icon: React.createElement("img", { style: { color: 'white', width: 25 }, src: require('./money.svg').default }) })),
            availableActions.includes(JobActionType.INVOICE) && (
            //   TODO why do we need the CreateInvoiceFromQuote element?
            React.createElement(CreateInvoiceFromQuote, { accountId: job.accountId, job: job, jobPayments: jobPayments, onIsProcessing: setIsProcessing, onRecordPayment: setShowRecordPaymentModal },
                React.createElement(IconButton, { label: "Invoice", icon: "Paper" }))),
            availableActions.includes(JobActionType.RECORD_PAYMENT) && (React.createElement(IconButton, { onClick: function () { return setShowRecordPaymentModal(true); }, label: "Record payment", icon: React.createElement("img", { style: { color: 'white', width: 25 }, src: require('./money.svg').default }) })),
            availableActions.includes(JobActionType.DUPLICATE) && (React.createElement(IconButton, { onClick: duplicateJob, label: "Duplicate", icon: React.createElement("img", { style: { color: 'white', width: 25 }, src: require('./duplicate.svg').default }) })),
            downloadAsPdfIconButton,
            availableActions.includes(JobActionType.DELETE) && (React.createElement(IconButton, { onClick: deleteJob, label: "Delete", icon: "Delete" })),
            availableActions.includes(JobActionType.REMOVE_ACCEPTED) && (React.createElement(IconButton, { onClick: unacceptJob, label: "Remove accepted", icon: faUndo })),
            availableActions.includes(JobActionType.RESEND) && (React.createElement(IconButton, { onClick: function () { return setShowResendJobModal(true); }, label: "Resend", icon: "Send" })),
            availableActions.includes(JobActionType.INACTIVATE_AND_EDIT) && (React.createElement(IconButton, { onClick: function () { return setShowEditWarningModal(true); }, label: "Edit", icon: "Edit" })),
            availableActions.includes(JobActionType.MARK_AS_SENT) && (React.createElement(IconButton, { onClick: function () { return setShowMarkAsSentModal(true); }, label: "Mark as sent", icon: "Message" }))),
        React.createElement(RecordPaymentModal, { job: job, jobPayments: jobPayments, customer: customer, isOpen: showRecordPaymentModal, onClose: function () { return setShowRecordPaymentModal(false); }, recordPaymentCallback: onJobUpdateSuccess, confirmPaymentCallback: onJobUpdateSuccess }),
        React.createElement(ResendJobModal, { isOpen: showResendJobModal, onClose: function () { return setShowResendJobModal(false); }, jobId: jobId }),
        React.createElement(EditJobModal, { isOpen: showEditWarningModal, onClose: function () { return setShowEditWarningModal(false); }, jobId: jobId }),
        React.createElement(MarkAsCompleteModal, { jobId: jobId, isOpen: showMarkAsCompleteModal, onClose: function () { return setShowMarkAsCompleteModal(false); }, markAsCompleteCallback: onJobUpdateSuccess }),
        React.createElement(MarkAsSentModal, { jobId: job.id, isOpen: showMarkAsSentModal, jobType: getJobType(job), onClose: function () { return setShowMarkAsSentModal(false); }, markAsSentCallback: markAsSentCallback })));
};
export default PreviewJobActionButtons;
