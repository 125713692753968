import React from 'react';
import Tooltip from '@bit/payaca-tech.payaca-core.component.tooltip';
import EditIcon from '@material-ui/icons/Edit';
var TotalsEditableValue = function (_a) {
    var disabled = _a.disabled, editValueTitle = _a.editValueTitle, emptyValueTitle = _a.emptyValueTitle, infoIconText = _a.infoIconText, onClick = _a.onClick, value = _a.value, valuePrefix = _a.valuePrefix;
    return (React.createElement("div", { className: "detail-container" },
        React.createElement("div", { className: "detail-title editable-value-title-wrapper " + (disabled ? 'disabled' : ''), onClick: function () { return (disabled ? {} : onClick && onClick()); } },
            React.createElement("div", { className: value ? '' : 'editable-value-title' },
                React.createElement("strong", null, value ? editValueTitle : emptyValueTitle)),
            infoIconText && React.createElement(Tooltip, { text: infoIconText }),
            value && React.createElement(EditIcon, { className: "detail-icon" })),
        React.createElement("div", { className: "detail-value" }, value ? "" + (valuePrefix || '') + value : null)));
};
export default TotalsEditableValue;
