import { canConvertJobToInvoice, canEditSentJob, canMarkJobAsAccepted, canMarkJobAsComplete, canMarkJobAsDeclined, canRecordDepositPayment, canRecordInvoicePayment, canResendJob, canUnacceptJob, } from './jobHelperV2';
import { isInvoice, jobHasSent } from './jobStatusHelper';
import { JobActionType } from '@payaca/types/jobActionsTypes';
export var getAvailableActionsForJob = function (job, customer, jobPayments, relatedJobs, activeJobFinanceInformation) {
    var jobActionTypes = [];
    if (job.archivedAt || job.inactivatedAt)
        return jobActionTypes;
    var jobHasSentSuccessfully = !job.bouncedAt && jobHasSent(job.status);
    var jobHasRelatedInvoice = relatedJobs.some(function (x) { return isInvoice(x.status); });
    if (jobHasSentSuccessfully) {
        if (canMarkJobAsComplete(job, jobPayments, activeJobFinanceInformation))
            jobActionTypes.push(JobActionType.MARK_AS_COMPLETE);
        if (canMarkJobAsAccepted(job))
            jobActionTypes.push(JobActionType.MARK_AS_ACCEPTED);
        if (canMarkJobAsDeclined(job))
            jobActionTypes.push(JobActionType.MARK_AS_DECLINED);
        if (canRecordDepositPayment(job, jobPayments, activeJobFinanceInformation))
            jobActionTypes.push(JobActionType.RECORD_DEPOSIT_PAYMENT);
        if (canConvertJobToInvoice(job, jobHasRelatedInvoice))
            jobActionTypes.push(JobActionType.INVOICE);
        if (canRecordInvoicePayment(job))
            jobActionTypes.push(JobActionType.RECORD_PAYMENT);
        jobActionTypes.push(JobActionType.DUPLICATE);
        jobActionTypes.push(JobActionType.DELETE);
        if (canUnacceptJob(job, jobPayments, activeJobFinanceInformation, !!relatedJobs.length))
            jobActionTypes.push(JobActionType.REMOVE_ACCEPTED);
        if (canResendJob(job, customer, !!relatedJobs.length))
            jobActionTypes.push(JobActionType.RESEND);
        if (canEditSentJob(job, jobPayments, !!relatedJobs.length, activeJobFinanceInformation))
            jobActionTypes.push(JobActionType.INACTIVATE_AND_EDIT);
    }
    else if (!jobHasSentSuccessfully && !job.bouncedAt) {
        jobActionTypes.push(JobActionType.SEND);
        if (!job.showBespokeFinancePlans) {
            jobActionTypes.push(JobActionType.MARK_AS_SENT);
        }
        jobActionTypes.push(JobActionType.DELETE);
    }
    return jobActionTypes;
};
