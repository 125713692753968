export var SortBy;
(function (SortBy) {
    SortBy["DEAL_REFERENCE"] = "reference";
    SortBy["CUSTOMER_NAME"] = "customerName";
    SortBy["LAST_UPDATED"] = "lastUpdated";
})(SortBy || (SortBy = {}));
export var ColumnType;
(function (ColumnType) {
    ColumnType["DEAL_REFERENCE"] = "Deal Reference";
    ColumnType["CUSTOMER_NAME"] = "Customer Name";
    ColumnType["TYPE"] = "Type";
    ColumnType["REFERENCE"] = "Ref";
    ColumnType["VALUE"] = "Value";
    ColumnType["DUE"] = "Due";
    ColumnType["STATUS"] = "Status";
    ColumnType["LAST_UPDATED"] = "Last Updated";
})(ColumnType || (ColumnType = {}));
