var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ValidatedForm } from '@payaca/components';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import React, { useCallback, useMemo, useState, } from 'react';
import './ChangePasswordForm.sass';
import { useDispatch, useSelector } from 'react-redux';
import { actions as userActions } from '@/api/users';
import { getIsRequiredFieldValidator, getFieldValuesMustMatchValidator, } from '@payaca/helpers/fieldValidationHelper';
import { PrivateField } from '@payaca/components/privateField';
import { minimumLengthFieldValidator, mustContainLetterFieldValidator, mustContainNumberFieldValidator, } from '@payaca/helpers/passwordValidationHelper';
import { ErrorMessage } from '@payaca/components/feedbackMessage';
import { CollapsiblePanel, CollapsiblePanelStyleVariant, } from '@payaca/components/collapsiblePanel';
import PasswordValidationFeedback from '../passwordValidationFeedback/PasswordValidationFeedback';
var ChangePasswordForm = function (_a) {
    var onSuccessCallback = _a.onSuccessCallback;
    var dispatch = useDispatch();
    var _b = useState(false), isProcessing = _b[0], setIsProcessing = _b[1];
    var _c = useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = useState(false), showPasswordValidationFeedback = _d[0], setShowPasswordValidationFeedback = _d[1];
    var myProfile = useSelector(function (state) {
        return state.users.myProfile;
    });
    var fieldValidators = useMemo(function () {
        var isRequiredFieldValidator = getIsRequiredFieldValidator();
        return {
            currentPassword: [isRequiredFieldValidator],
            newPassword: [
                isRequiredFieldValidator,
                minimumLengthFieldValidator,
                mustContainLetterFieldValidator,
                mustContainNumberFieldValidator,
            ],
            confirmNewPassword: [
                isRequiredFieldValidator,
                getFieldValuesMustMatchValidator('newPassword', {
                    customErrorMessage: 'The passwords entered do not match',
                }),
            ],
        };
    }, []);
    var onSubmit = useCallback(function (formState) {
        setIsProcessing(true);
        setErrorMessage(undefined);
        var payload = {
            email: myProfile.email,
            currentPassword: formState.currentPassword,
            newPassword: formState.newPassword,
        };
        dispatch(userActions.updatePassword(payload, function (error) {
            setIsProcessing(false);
            error
                ? setErrorMessage('Sorry, there was an error updating your password. Please try again.')
                : onSuccessCallback && onSuccessCallback();
        }));
    }, [dispatch, onSuccessCallback, myProfile === null || myProfile === void 0 ? void 0 : myProfile.email]);
    var initialFormState = useMemo(function () {
        return {
            newPassword: '',
        };
    }, []);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ValidatedFieldWrapper, { validationResult: __assign(__assign({}, validationState['currentPassword']), { errors: [] }), isTouched: touchedState['currentPassword'] || false },
                React.createElement(PrivateField, { styleVariant: InputStyleVariant.OUTSIZE, name: "currentPassword", value: formState.currentPassword, label: 'Current password', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch })),
            React.createElement("div", { className: "new-password-input-container" },
                React.createElement("div", null,
                    React.createElement(ValidatedFieldWrapper, { validationResult: __assign(__assign({}, validationState['newPassword']), { errors: [] }), isTouched: touchedState['newPassword'] || false },
                        React.createElement(PrivateField, { styleVariant: InputStyleVariant.OUTSIZE, name: "newPassword", value: formState.newPassword, label: 'New password', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, additionalInputProps: {
                                onFocus: function () { return setShowPasswordValidationFeedback(true); },
                            } })),
                    React.createElement(CollapsiblePanel, { isOpen: showPasswordValidationFeedback, styleVariant: CollapsiblePanelStyleVariant.UNSTYLED, shouldRenderHeader: false },
                        React.createElement(PasswordValidationFeedback, { password: formState.newPassword }))),
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState['confirmNewPassword'], isTouched: touchedState['confirmNewPassword'] || false },
                    React.createElement(PrivateField, { styleVariant: InputStyleVariant.OUTSIZE, name: "confirmNewPassword", value: formState.confirmNewPassword, label: 'Confirm password', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch }))),
            React.createElement("div", { className: "actions-container flex-center flex-container" },
                React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, isDisabled: !isValid, isProcessing: isProcessing, onClick: function () { return !isProcessing && onSubmit(formState); } }, "Save"),
                errorMessage && React.createElement(ErrorMessage, { message: errorMessage }))));
    }, [onSubmit, isProcessing, errorMessage, showPasswordValidationFeedback]);
    return (React.createElement("div", { className: "change-password-form-container" },
        React.createElement(ValidatedForm, { initialFormState: initialFormState, renderFormContents: renderFormContents, fieldValidators: fieldValidators })));
};
export default ChangePasswordForm;
