import { delay, take, put, all, race, call } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import qs from 'qs';

import { actions as authActions } from '@payaca/store/auth';
import { IntercomAPI } from 'react-intercom';

import * as api from '../services/api';

const TOKEN_KEY = 'payacav1';
const IGNORE_PATHS = ['/login', '/reset'];

const initialState = {
  loaded: false,
  currentTab: null,
  banner: null,
  dismissMobileMode: localStorage.getItem('payacaDismissMobileMode') || false,
  modal: null,
  showNavbar: false,
  maintenanceSchedule: {},
  hideMaintenanceBanner: true,
  lastReadFutureMaintenaceMessageDownTimeAt: null,
  navigationSidebarIsHidden: false,
  navigationSidebarIsCollapsed: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'app.showNavigationSidebar':
      return { ...state, navigationSidebarIsHidden: false };
    case 'app.hideNavigationSidebar':
      return { ...state, navigationSidebarIsHidden: true };

    case 'app.expandNavigationSidebar':
      return { ...state, navigationSidebarIsCollapsed: false };
    case 'app.collapseNavigationSidebar':
      return { ...state, navigationSidebarIsCollapsed: true };

    case 'app.storePreviousPathname': {
      return { ...state, previousPathname: action.pathname };
    }

    case 'app.storePathname': {
      return { ...state, pathname: action.pathname };
    }

    case 'app.toggleShowNavbar': {
      return { ...state, showNavbar: !state.showNavbar };
    }

    case 'app.hideNavbar': {
      return { ...state, showNavbar: false };
    }

    case 'app.loaded': {
      return { ...state, loaded: true };
    }

    case 'app.showModal': {
      return {
        ...state,
        modal: action.payload,
      };
    }

    case 'app.hideModal': {
      return {
        ...state,
        modal: initialState.modal,
      };
    }

    case 'app.showBanner': {
      return {
        ...state,
        banner: action.payload,
      };
    }

    case 'app.hideBanner': {
      return {
        ...state,
        banner: initialState.banner,
      };
    }

    case 'app.dismissMobileMode': {
      localStorage.setItem('payacaDismissMobileMode', 'true');
      return {
        ...state,
        dismissMobileMode: true,
      };
    }

    case 'app.hideMaintenanceBanner': {
      return {
        ...state,
        hideMaintenanceBanner: action.hideMaintenanceBanner,
      };
    }

    case 'app.requestGetMaintenance': {
      return {
        ...state,
        isGettingMaintenance: true,
      };
    }

    case 'app.getMaintenanceSuccess': {
      return {
        ...state,
        isGettingMaintenance: false,
        maintenanceSchedule: action.payload,
      };
    }
    case 'app.getMaintenanceFailure': {
      return {
        ...state,
        isGettingMaintenance: false,
        maintenanceError: action.maintenanceError,
      };
    }
    case 'app.setLastReadFutureMaintenaceMessageDownTimeAt': {
      return {
        ...state,
        lastReadFutureMaintenaceMessageDownTimeAt: action.downTimeAt,
      };
    }

    default: {
      return state;
    }
  }
}

export const actions = {
  start: (callback) => ({ type: 'app.start', callback }),
  storePathname: (pathname) => ({ type: 'app.storePathname', pathname }),
  storePreviousPathname: (pathname) => ({
    type: 'app.storePreviousPathname',
    pathname,
  }),
  loaded: () => ({ type: 'app.loaded' }),
  toggleShowNavbar: () => ({ type: 'app.toggleShowNavbar' }),
  hideNavbar: () => ({ type: 'app.hideNavbar' }),
  signUp: (payload, callback) => ({
    type: 'app.signUp',
    payload,
    callback,
  }),
  sendPasswordResetEmail: (emailAddress, callback) => ({
    type: 'app.sendPasswordResetEmail',
    emailAddress,
    callback,
  }),
  validResetToken: (token, callback) => ({
    type: 'app.validResetToken',
    token,
    callback,
  }),
  resetPassword: (token, password, callback) => ({
    type: 'app.resetPassword',
    token,
    password,
    callback,
  }),
  showBanner: (payload) => ({ type: 'app.showBanner', payload }),
  hideBanner: () => ({ type: 'app.hideBanner' }),
  showModal: (payload) => ({ type: 'app.showModal', payload }),
  hideModal: () => ({ type: 'app.hideModal' }),
  dismissMobileMode: () => ({ type: 'app.dismissMobileMode' }),
  verifyAccountToken: (token, callback) => ({
    type: 'app.verifyAccountToken',
    token,
    callback,
  }),
  validUserInviteToken: (token, callback) => ({
    type: 'app.validUserInviteToken',
    token,
    callback,
  }),
  registerInvitedUser: (token, payload, callback) => ({
    type: 'app.registerInvitedUser',
    token,
    payload,
    callback,
  }),
  requestGetMaintenance: () => ({
    type: 'app.requestGetMaintenance',
  }),
  getMaintenanceSuccess: (payload) => ({
    type: 'app.getMaintenanceSuccess',
    payload,
  }),
  getMaintenanceFailure: (maintenanceError) => ({
    type: 'app.getMaintenanceFailure',
    maintenanceError,
  }),
  hideMaintenanceBanner: (hideMaintenanceBanner) => ({
    type: 'app.hideMaintenanceBanner',
    hideMaintenanceBanner,
  }),
  setLastReadFutureMaintenaceMessageDownTimeAt: (downTimeAt) => ({
    type: 'app.setLastReadFutureMaintenaceMessageDownTimeAt',
    downTimeAt,
  }),
  showNavigationSidebar: () => ({
    type: 'app.showNavigationSidebar',
  }),
  hideNavigationSidebar: () => ({
    type: 'app.hideNavigationSidebar',
  }),
  expandNavigationSidebar: () => ({
    type: 'app.expandNavigationSidebar',
  }),
  collapseNavigationSidebar: () => ({
    type: 'app.collapseNavigationSidebar',
  }),
};

export const selectors = {
  isLoaded: (state) => state.loaded === true,
  isLoggedIn: (state) => !!localStorage.getItem(TOKEN_KEY),
  getAuthToken: (state) => localStorage.getItem(TOKEN_KEY),
};

const watchAppStart = function* watchAppStart() {
  while (true) {
    const { callback } = yield take('app.start');

    try {
      const token = localStorage.getItem(TOKEN_KEY);

      const query = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });

      let oneTimeLoginToken =
        query.oneTimeLoginToken ||
        (window.location.pathname === '/enableCustomerPayments' && query.state);

      if (oneTimeLoginToken) {
        yield put(authActions.requestLoginWithToken(oneTimeLoginToken));
        yield take('auth.storeTokensSuccess');
      } else if (!token) {
        const ignorePath = IGNORE_PATHS.find((path) =>
          window.location.pathname.includes(path)
        );
        if (!token && !ignorePath) {
          yield put(actions.storePathname(window.location.pathname));
        }
      }
    } catch (e) {
      console.log('Remove token', e);
      localStorage.removeItem(TOKEN_KEY);
    } finally {
      yield put(actions.loaded());
    }
  }
};

const watchSignUp = function* watchSignUp() {
  while (true) {
    const { payload, callback } = yield take('app.signUp');

    payload.email = payload.email.toLowerCase();

    yield put(startSubmit('signUp'));
    try {
      const { response, timeout } = yield race({
        response: call(api.signUp, payload),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Sign up request timed out.');
      }
      yield put(stopSubmit('signUp'));
      yield put(authActions.storeTokens(response.token, response.refreshToken));
      yield take('auth.storeTokensSuccess');
      callback && callback();
    } catch (e) {
      console.log(e);
      yield put(stopSubmit('signUp'));

      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors);
        }
      }
    }
  }
};

const watchSendPasswordResetEmail = function* watchSendPasswordResetEmail() {
  while (true) {
    const { emailAddress, callback } = yield take('app.sendPasswordResetEmail');

    try {
      const { response, timeout } = yield race({
        response: call(api.sendPasswordResetEmail, emailAddress),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Send password reset email request timed out.'
        );
      }
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);

      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors || e.message);
        }
      }
    }
  }
};

const watchValidResetToken = function* watchValidResetToken() {
  while (true) {
    const { token, callback } = yield take('app.validResetToken');

    try {
      const { response, timeout } = yield race({
        response: call(api.validResetToken, token),
        timeout: delay(5000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Check valid reset password token timed out.'
        );
      }
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message);
        } else {
          callback(e.message);
        }
      }
    }
  }
};

const watchLoginSuccess = function* () {
  while (true) {
    yield take('auth.loginSuccess');
    yield put(actions.storePathname());
    yield put(actions.setLastReadFutureMaintenaceMessageDownTimeAt());
  }
};

const watchResetPassword = function* watchResetPassword() {
  while (true) {
    const { token, password, callback } = yield take('app.resetPassword');

    try {
      const { response, timeout } = yield race({
        response: call(api.resetPassword, token, password),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Reset password timed out.');
      }
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message);
        } else {
          callback(e.message);
        }
      }
    }
  }
};

const watchVerifyAccountToken = function* watchVerifyAccountToken() {
  while (true) {
    const { token, callback } = yield take('app.verifyAccountToken');

    try {
      const { response, timeout } = yield race({
        response: call(api.verifyAccountToken, token),
        timeout: delay(5000),
      });
      if (timeout) {
        throw new api.TimeoutException('Verify account token timed out.');
      }
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message);
        } else {
          callback(e.message);
        }
      }
    }
  }
};

const watchValidUserInviteToken = function* watchValidUserInviteToken() {
  while (true) {
    const { token, callback } = yield take('app.validUserInviteToken');

    try {
      const { response, timeout } = yield race({
        response: call(api.validUserInviteToken, token),
        timeout: delay(5000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Check valid user invite token timed out.'
        );
      }
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message);
        } else {
          callback(e.message);
        }
      }
    }
  }
};

const watchRegisterInvitedUser = function* watchRegisterInvitedUser() {
  while (true) {
    const { token, payload, callback } = yield take('app.registerInvitedUser');

    try {
      const { response, timeout } = yield race({
        response: call(api.registerInvitedUser, token, payload),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Register invited user timed out.');
      }
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message);
        } else {
          callback(e.message);
        }
      }
    }
  }
};

const watchRequestGetMaintenance = function* watchRequestGetMaintenance() {
  while (true) {
    const {} = yield take('app.requestGetMaintenance');

    try {
      const { response, timeout } = yield race({
        response: call(api.requestGetMaintenance),
        timeout: delay(15000),
      });
      if (timeout) {
        yield put(
          actions.getMaintenanceFailure(new Error('Get maintenance timed out'))
        );
      } else {
        yield put(actions.getMaintenanceSuccess(response));
      }
    } catch (error) {
      yield put(actions.getMaintenanceFailure(error));
    }
  }
};

const watchLogout = function* watchLogout() {
  while (true) {
    const { callback } = yield take('auth.logout');
    IntercomAPI('shutdown');
  }
};

export const saga = function* saga() {
  yield all([
    watchAppStart(),
    watchSignUp(),
    watchSendPasswordResetEmail(),
    watchLoginSuccess(),
    watchValidResetToken(),
    watchResetPassword(),
    watchVerifyAccountToken(),
    watchValidUserInviteToken(),
    watchRegisterInvitedUser(),
    watchRequestGetMaintenance(),
    watchLogout(),
  ]);
};
