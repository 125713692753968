import React, { Fragment, useCallback, useMemo, useState, } from 'react';
import { useDispatch } from 'react-redux';
import { faCheck, faExclamationTriangle, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Danger } from 'react-iconly';
import { actions as usersActions } from '@/api/users';
import { ContentPanel } from '@payaca/components/contentPanel';
import { Button, ButtonStyleVariant, ButtonColourVariant, } from '@payaca/components/button';
import './ConnectionControl.sass';
var Connection = function (_a) {
    var allowConnectDisconnect = _a.allowConnectDisconnect, logo = _a.logo, children = _a.children, connectDisconnectButton = _a.connectDisconnectButton, connectionName = _a.connectionName, disconnectionError = _a.disconnectionError, _b = _a.hasConnectionExpired, hasConnectionExpired = _b === void 0 ? false : _b, isConnected = _a.isConnected, onAddConnection = _a.onAddConnection, onRemoveConnection = _a.onRemoveConnection;
    var dispatch = useDispatch();
    var _c = useState(false), removingConnection = _c[0], setRemovingConnection = _c[1];
    var _d = useState(false), hasDisconnectionError = _d[0], setHasDisconnectionError = _d[1];
    var connectButtonComponent = useMemo(function () {
        return connectDisconnectButton ? (React.createElement(Fragment, null,
            hasConnectionExpired && (React.createElement(FontAwesomeIcon, { icon: faExclamationTriangle, className: "alert-icon", color: "#ffbf00" })),
            React.createElement("div", { onClick: function () { return onAddConnection(); } }, connectDisconnectButton))) : (React.createElement(Button, { onClick: onAddConnection, colourVariant: ButtonColourVariant.WHITE, styleVariant: ButtonStyleVariant.OUTSIZE, isOutlined: true, hasBoxShadow: true },
            logo && React.createElement("img", { src: logo, className: "logo" }),
            "Connect to ",
            connectionName));
    }, [connectDisconnectButton]);
    var disconnectionErrorMessage = useMemo(function () {
        return disconnectionError
            ? disconnectionError
            : "Sorry, there was an error disconnecting from " + connectionName + ". Please try again.";
    }, [disconnectionError]);
    var removeConnection = useCallback(function () {
        setRemovingConnection(true);
        setHasDisconnectionError(false);
        onRemoveConnection(function (err, resp) {
            if (err) {
                setHasDisconnectionError(true);
            }
            dispatch(usersActions.getProfile());
            setRemovingConnection(false);
        });
    }, [dispatch, onRemoveConnection]);
    var disconnectButtonComponent = useMemo(function () {
        return connectDisconnectButton ? (React.createElement("div", { onClick: function () { return removeConnection(); } }, connectDisconnectButton)) : (React.createElement("div", null,
            hasDisconnectionError && (React.createElement(ConnectionContolAlert, null, disconnectionErrorMessage)),
            React.createElement(Button, { onClick: function () { return removeConnection(); }, isProcessing: removingConnection, colourVariant: ButtonColourVariant.WHITE, styleVariant: ButtonStyleVariant.OUTSIZE, isOutlined: true, hasBoxShadow: true, iconBefore: faCheck, className: "disconnect" },
                "Disconnect from ",
                connectionName)));
    }, [
        connectionName,
        connectDisconnectButton,
        disconnectionErrorMessage,
        dispatch,
        hasDisconnectionError,
        removeConnection,
        removingConnection,
    ]);
    return (React.createElement(ContentPanel, { className: "connection-control " + connectionName.toLowerCase() + (hasConnectionExpired ? ' expired' : '') },
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: "title-bar" },
                logo && React.createElement("img", { src: logo, className: "logo" }),
                React.createElement("h2", null, connectionName)),
            React.createElement("div", { className: "body-content" }, children),
            allowConnectDisconnect && (React.createElement("div", { className: "connect-disconnect-button-wrapper" }, !isConnected ? connectButtonComponent : disconnectButtonComponent)))));
};
export var ConnectionContolAlert = function (_a) {
    var children = _a.children;
    return (React.createElement("div", { className: "connection-control-alert" },
        React.createElement(Danger, { size: "small" }),
        React.createElement("div", null, children)));
};
export default Connection;
