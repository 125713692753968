// ======================================================================================================
// Routes and redirects
// - Creates multiple routes and redirects
// ======================================================================================================

import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import values from 'lodash.values';

const RoutesAndRedirects = ({
  availableRedirects,
  availableRoutes,
  matchPath,
}) => {
  const fullPath = (path) => `${matchPath}${path}`;
  return (
    <Switch>
      {/** Routes */}
      {values(availableRoutes)
        .filter((route) => route)
        .sort((a, b) => {
          // Sort to ensure order of routes puts fixed address before dynamic params as <Switch/> will render first match
          const aParams = a.path.split('/').length;
          const bParams = b.path.split('/').length;
          if (aParams > bParams) {
            return 1;
          } else if (aParams < bParams) {
            return -1;
          } else {
            return a.path.split('/:').length > b.path.split('/:').length
              ? 1
              : -1;
          }
        })
        .map((route) => {
          const key = `route-${route.path}`;
          return route.component ? (
            <Route
              key={key}
              exact
              path={fullPath(route.path)}
              component={route.component}
            />
          ) : (
            <Route
              key={key}
              exact
              path={fullPath(route.path)}
              render={route.renderComponent}
            />
          );
        })}
      {/** Redirects */}
      {values(availableRedirects).map((redirect) => {
        return redirect.fromPaths.map((path) => {
          const key = `redirect-${path}`;
          const toPath = fullPath(redirect.toPath);
          const fromPath = fullPath(path);
          return redirect.component ? (
            <Route
              key={key}
              from={fromPath}
              to={toPath}
              component={redirect.component}
            />
          ) : (
            <Route exact path={fromPath} key={key}>
              <Redirect to={toPath} />
            </Route>
          );
        });
      })}
    </Switch>
  );
};

export default RoutesAndRedirects;
