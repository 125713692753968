import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '@payaca/components/modal';
import { IntercomAPI } from 'react-intercom';
import AddUserForm from '../addUserForm/AddUserForm';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { useHistory } from 'react-router-dom';
import './AddUserModal.css';
var AddUserModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onAddUserSuccess = _a.onAddUserSuccess;
    var history = useHistory();
    var numberOfUserSeats = useSelector(function (state) {
        var _a, _b, _c;
        return (_c = (_b = (_a = state.account) === null || _a === void 0 ? void 0 : _a.accountAccessInformation) === null || _b === void 0 ? void 0 : _b.features) === null || _c === void 0 ? void 0 : _c.numberOfUserSeats;
    });
    var numberOfActiveUsers = useSelector(function (state) {
        return state.users.accountUsers.filter(function (accountUser) {
            return !accountUser.deactivatedAt || accountUser.inviteToken;
        }).length;
    });
    var canAddNewUser = useMemo(function () {
        return numberOfUserSeats > numberOfActiveUsers;
    }, [numberOfUserSeats, numberOfActiveUsers]);
    var userSeatsFilledComponent = useMemo(function () {
        return (React.createElement("div", { className: "user-seats-filled" },
            React.createElement("p", null, "All the user seats associated with your account have been filled."),
            React.createElement("p", null,
                "If you wish to add additional users, you can upgrade to a higher subscription plan or",
                ' ',
                React.createElement("strong", { className: "contact-us-directly-trigger", onClick: function () { return IntercomAPI('show'); }, role: "button" }, "contact us directly"),
                "."),
            React.createElement("div", { className: "actions-container" },
                React.createElement(Button, { onClick: function () {
                        history.push('/upgradeAccount');
                    }, styleVariant: ButtonStyleVariant.OUTSIZE }, "Manage subscription"))));
    }, [history]);
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose, title: "Add user" },
        React.createElement("div", { className: "add-user-modal" },
            React.createElement("p", null,
                "Currently using",
                ' ',
                React.createElement("strong", null,
                    numberOfActiveUsers,
                    " out of ",
                    numberOfUserSeats),
                ' ',
                "available user seats."),
            canAddNewUser && React.createElement(AddUserForm, { onAddUserSuccess: onAddUserSuccess }),
            !canAddNewUser && userSeatsFilledComponent)));
};
export default AddUserModal;
