import React, { useMemo, useCallback } from 'react';
import './FcaComplaintsContactFormSubsection.css';
import { TitledContentPanel } from '@payaca/components/titledContentPanel';
import { DropdownField } from '@payaca/components/dropdownField';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { BasicField } from '@payaca/components/basicField';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
var FcaComplaintsContactFormSubsection = function (_a) {
    var existingCompanyContacts = _a.existingCompanyContacts, fcaComplaintsContactEmailAddress = _a.fcaComplaintsContactEmailAddress, fcaComplaintsContactName = _a.fcaComplaintsContactName, touchedState = _a.touchedState, validationState = _a.validationState, onChange = _a.onChange, onTouch = _a.onTouch;
    var existingCompanyContactsOptions = useMemo(function () {
        if (!existingCompanyContacts || !existingCompanyContacts.length)
            return [];
        return existingCompanyContacts.map(function (existingCompanyContact) {
            return {
                value: existingCompanyContact.id,
                label: existingCompanyContact.name + " (" + existingCompanyContact.emailAddress + ")",
            };
        });
    }, [existingCompanyContacts]);
    var selectedCompanyContactId = useMemo(function () {
        var companyContact = existingCompanyContacts === null || existingCompanyContacts === void 0 ? void 0 : existingCompanyContacts.find(function (existingCompanyContact) {
            return existingCompanyContact.name === fcaComplaintsContactName &&
                existingCompanyContact.emailAddress === fcaComplaintsContactEmailAddress;
        });
        return companyContact === null || companyContact === void 0 ? void 0 : companyContact.id;
    }, [
        existingCompanyContacts,
        fcaComplaintsContactEmailAddress,
        fcaComplaintsContactName,
    ]);
    var onExistingCompanyContactsDropdownChange = useCallback(function (value) {
        var companyContactId = value.companyContactId;
        var companyContact = existingCompanyContacts === null || existingCompanyContacts === void 0 ? void 0 : existingCompanyContacts.find(function (existingCompanyContact) {
            return existingCompanyContact.id === companyContactId;
        });
        if (companyContact) {
            onChange({
                fcaComplaintsContactName: companyContact.name,
                fcaComplaintsContactEmailAddress: companyContact.emailAddress,
            });
        }
    }, [existingCompanyContacts, onChange]);
    return (React.createElement("div", { className: "fca-complaints-contact-form-subsection" },
        React.createElement(TitledContentPanel, { title: "Who is your fca complaints contact?", hasBoxShadow: true },
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: "flex-container" },
                    React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState.fcaComplaintsContactName) || false, validationResult: validationState && validationState.fcaComplaintsContactName },
                        React.createElement(BasicField, { label: "Name", value: fcaComplaintsContactName || '', name: "fcaComplaintsContactName", onChange: onChange, onTouch: onTouch, isRequired: true, styleVariant: InputStyleVariant.OUTSIZE })),
                    React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState &&
                            touchedState.fcaComplaintsContactEmailAddress) ||
                            false, validationResult: validationState &&
                            validationState.fcaComplaintsContactEmailAddress },
                        React.createElement(BasicField, { label: "Email address", value: fcaComplaintsContactEmailAddress || '', name: "fcaComplaintsContactEmailAddress", onChange: onChange, onTouch: onTouch, isRequired: true, styleVariant: InputStyleVariant.OUTSIZE }))),
                existingCompanyContacts && !!existingCompanyContacts.length && (React.createElement(React.Fragment, null,
                    React.createElement(DropdownField, { label: "Or choose an existing contact", value: selectedCompanyContactId || '', options: existingCompanyContactsOptions, name: "companyContactId", onChange: onExistingCompanyContactsDropdownChange, styleVariant: InputStyleVariant.OUTSIZE })))))));
};
export default FcaComplaintsContactFormSubsection;
