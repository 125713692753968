import React, { useMemo, } from 'react';
import './CollapsibleSidebar.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, } from '@fortawesome/free-solid-svg-icons';
export var CollapseDirection;
(function (CollapseDirection) {
    CollapseDirection["LEFT"] = "left";
    CollapseDirection["RIGHT"] = "right";
})(CollapseDirection || (CollapseDirection = {}));
var CollapsibleSidebar = function (_a) {
    var isCollapsed = _a.isCollapsed, isHidden = _a.isHidden, _b = _a.config, config = _b === void 0 ? {
        collapsedWidthPx: 100,
        expandedWidthPx: 250,
    } : _b, children = _a.children, _c = _a.collapseDirection, collapseDirection = _c === void 0 ? CollapseDirection.LEFT : _c, onToggleCollapse = _a.onToggleCollapse;
    var sidebarInnerWidth = useMemo(function () {
        return isCollapsed ? config.collapsedWidthPx : config.expandedWidthPx;
    }, [config, isCollapsed]);
    return (React.createElement("div", { className: "collapsible-sidebar " + (isCollapsed ? 'collapsed' : '') + " " + (isHidden ? 'hidden' : '') + " collapse-direction-" + collapseDirection, style: {
            width: isHidden ? 0 : sidebarInnerWidth,
        } },
        React.createElement("div", { className: "collapsible-sidebar-wrapper" },
            React.createElement("div", { className: "collapsible-sidebar-inner", style: {
                    width: sidebarInnerWidth,
                } }, children)),
        React.createElement("div", { className: "expand-collapse-control", onClick: onToggleCollapse },
            React.createElement("div", { className: "expand-collapse-control-inner" },
                React.createElement("div", { className: "expand-control" },
                    React.createElement(FontAwesomeIcon, { icon: collapseDirection === CollapseDirection.LEFT
                            ? faChevronRight
                            : faChevronLeft })),
                React.createElement("div", { className: "collapse-control" },
                    React.createElement(FontAwesomeIcon, { icon: collapseDirection === CollapseDirection.LEFT
                            ? faChevronLeft
                            : faChevronRight }))))));
};
export default CollapsibleSidebar;
