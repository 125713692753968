import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ModalWrapper, Table } from '@/ui/components';
var styles = function () { return ({
    modalWrapper: {
        '&.content': {
            height: 500,
        },
        '& .search-bar': {
            width: '100%',
        },
        '& .table-element.description': {
            'max-width': '170px',
        },
    },
}); };
var SearchTableModal = function (_a) {
    var open = _a.open, title = _a.title, onClose = _a.onClose, data = _a.data, configName = _a.configName, _b = _a.onDisabledRowClick, onDisabledRowClick = _b === void 0 ? null : _b, onRowClick = _a.onRowClick, bulkActions = _a.bulkActions, classes = _a.classes, disableData = _a.disableData;
    return (React.createElement(ModalWrapper, { open: open, title: title, onClose: function () { return onClose(); }, classes: classes.modalWrapper, size: "md" },
        React.createElement(Table, { data: data, configName: configName, onRowClick: function (row) { return onRowClick(row); }, onDisabledRowClick: onDisabledRowClick, bulkActions: bulkActions, hideEmptyArrowIndication: true, disableData: disableData, addButton: null, singleActions: null })));
};
export default withStyles(styles)(SearchTableModal);
