import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmailTemplateModal from './EmailTemplateModal';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { actions as accountSettingsActions } from '@/api/accountSettings';
var EmailTemplate;
(function (EmailTemplate) {
    EmailTemplate["ESTIMATE"] = "estimate";
    EmailTemplate["QUOTE"] = "quote";
    EmailTemplate["INVOICE"] = "invoice";
})(EmailTemplate || (EmailTemplate = {}));
var EmailTemplateSetting = function (_a) {
    var title = _a.title, description = _a.description, onClick = _a.onClick;
    return (React.createElement("div", { className: "settings-sub-section" },
        React.createElement("div", { className: "email-customisation-setting" },
            React.createElement("h4", null, title),
            React.createElement("p", null, description)),
        React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: onClick }, "View/Edit")));
};
var CompanySettingsEmailCustomisation = function (_a) {
    var readOnly = _a.readOnly;
    var dispatch = useDispatch();
    var _b = useState(null), showEmailTemplateModal = _b[0], setShowEmailTemplateModal = _b[1];
    var pendingEmailTemplates = useSelector(function (state) { return state.accountSettings.pendingEmailTemplates; });
    var emailTemplatesDefaults = useSelector(function (state) { var _a; return (_a = state.account.emailTemplates) === null || _a === void 0 ? void 0 : _a.defaults; });
    var onFieldChange = useCallback(function (change) {
        dispatch(accountSettingsActions.storeAccountPendingEmailTemplates(change));
    }, [dispatch]);
    var emailTemplateModalProps = useMemo(function () {
        if (showEmailTemplateModal === EmailTemplate.ESTIMATE) {
            return {
                title: 'Estimate email template',
                name: 'sendEstimate',
                defaultValue: emailTemplatesDefaults === null || emailTemplatesDefaults === void 0 ? void 0 : emailTemplatesDefaults.sendEstimate,
                value: (pendingEmailTemplates === null || pendingEmailTemplates === void 0 ? void 0 : pendingEmailTemplates.sendEstimate) || '',
            };
        }
        else if (showEmailTemplateModal === EmailTemplate.QUOTE) {
            return {
                title: 'Quote email template',
                name: 'sendQuote',
                defaultValue: emailTemplatesDefaults === null || emailTemplatesDefaults === void 0 ? void 0 : emailTemplatesDefaults.sendQuote,
                value: (pendingEmailTemplates === null || pendingEmailTemplates === void 0 ? void 0 : pendingEmailTemplates.sendQuote) || '',
            };
        }
        else if (showEmailTemplateModal === EmailTemplate.INVOICE) {
            return {
                title: 'Invoice email template',
                name: 'sendInvoice',
                defaultValue: emailTemplatesDefaults === null || emailTemplatesDefaults === void 0 ? void 0 : emailTemplatesDefaults.sendInvoice,
                value: (pendingEmailTemplates === null || pendingEmailTemplates === void 0 ? void 0 : pendingEmailTemplates.sendInvoice) || '',
            };
        }
    }, [emailTemplatesDefaults, pendingEmailTemplates, showEmailTemplateModal]);
    return (React.createElement("div", { className: "company-settings-email-customisation" },
        React.createElement(EmailTemplateSetting, { title: "Estimate email template", description: "Template for the email sent with estimates", onClick: function () { return setShowEmailTemplateModal(EmailTemplate.ESTIMATE); } }),
        React.createElement(EmailTemplateSetting, { title: "Quote email template", description: "Template for the email sent with quotes", onClick: function () { return setShowEmailTemplateModal(EmailTemplate.QUOTE); } }),
        React.createElement(EmailTemplateSetting, { title: "Invoice email template", description: "Template for the email sent with invoices", onClick: function () { return setShowEmailTemplateModal(EmailTemplate.INVOICE); } }),
        React.createElement(EmailTemplateModal, { isOpen: !!showEmailTemplateModal, onClose: function () { return setShowEmailTemplateModal(null); }, onChange: onFieldChange, readOnly: readOnly, title: (emailTemplateModalProps === null || emailTemplateModalProps === void 0 ? void 0 : emailTemplateModalProps.title) || '', name: (emailTemplateModalProps === null || emailTemplateModalProps === void 0 ? void 0 : emailTemplateModalProps.name) || '', defaultValue: emailTemplateModalProps === null || emailTemplateModalProps === void 0 ? void 0 : emailTemplateModalProps.defaultValue, value: emailTemplateModalProps === null || emailTemplateModalProps === void 0 ? void 0 : emailTemplateModalProps.value, buttonText: showEmailTemplateModal && "View " + showEmailTemplateModal })));
};
export default CompanySettingsEmailCustomisation;
