import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ModalWrapper, ValidatedForm } from '@/ui/components';

import { actions as appActions } from '@/api/app';
import { actions as jobActions } from '@/api/jobs';
import { actions as usersActions } from '@/api/users';

import { FormValidations } from '@/helpers/formHelper';

class AddBACSDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountName: '',
      accountNumber: '',
      sortCode: '',
      agreeToPrivacy: false,
      updatingAccount: false,
      subscribeToNewsletter: false,
      updatingAccountError: '',
    };
  }

  save() {
    this.setState({
      updatingAccount: true,
      updatingAccountError: '',
    });
    const onError = () => {
      this.setState({
        updatingAccount: false,
        updatingAccountError: 'An error occurred updating your account.',
      });
    };

    this.props.users.updateBusinessAccount(
      this.props.currentBusiness[0].id,
      {
        accountName: this.state.accountName,
        accountNumber: this.state.accountNumber,
        sortCode: this.state.sortCode,
      },
      (err) => {
        if (!err) {
          this.props.users.getProfile(() => {
            this.props.onClose();
          });
        } else {
          onError();
        }
      }
    );
  }

  onFieldChange(change) {
    this.setState({
      ...change,
    });
  }

  render() {
    const fieldData = {
      sections: [
        {
          fields: [
            {
              label: 'Account name',
              name: 'accountName',
            },
            {
              label: 'Sort code',
              name: 'sortCode',
              type: 'formattedNumberTemplate',
            },
            {
              label: 'Account number',
              name: 'accountNumber',
              type: 'formattedNumberTemplate',
            },
          ],
        },
      ],
      onChange: (change) => this.onFieldChange(change),
      validations: FormValidations.bacsDetails,
      values: this.state,
    };

    return (
      <ModalWrapper
        open={this.props.open}
        title="Add BACS Details"
        onClose={() => this.props.onClose()}
        classes="business-account-modal"
      >
        <ValidatedForm
          buttonAction={() => this.save()}
          submitting={this.state.updatingAccount}
          fieldData={fieldData}
          formError={this.state.updatingAccountError}
        />
      </ModalWrapper>
    );
  }
}

export default connect(
  (state) => ({
    currentBusiness: state.users.myProfile.accounts,
  }),
  (dispatch) => ({
    app: bindActionCreators(appActions, dispatch),
    jobs: bindActionCreators(jobActions, dispatch),
    users: bindActionCreators(usersActions, dispatch),
  })
)(AddBACSDetailsModal);
