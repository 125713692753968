import React from 'react';
import { useDispatch } from 'react-redux';
import { actions as jobActions } from '@/api/jobs';
import { actions as appActions } from '@/api/app';
import EditJobPage from '../editJobPage/EditJobPage';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import LoaderOverlay from '@bit/payaca-tech.payaca-core.component.loader-overlay';
import { useLocation } from 'react-router-dom';
import { getUrlSearchParam } from '@bit/payaca-tech.payaca-core.helpers.url';
var NewEditInvoicePage = function (_a) {
    var _b;
    var match = _a.match, _c = _a.isNew, isNew = _c === void 0 ? false : _c;
    var dispatch = useDispatch();
    var history = useHistory();
    var location = useLocation();
    var invoiceId = useMemo(function () { var _a, _b; return (_b = (_a = match === null || match === void 0 ? void 0 : match.params) === null || _a === void 0 ? void 0 : _a.invoiceId) === null || _b === void 0 ? void 0 : _b.split('?')[0]; }, [match]);
    var dealId = useMemo(function () {
        return getUrlSearchParam(location.search, 'dealId');
    }, [location]);
    var _d = useState(!invoiceId), isInitialising = _d[0], setIsInitialising = _d[1];
    useEffect(function () {
        if (!invoiceId) {
            setIsInitialising(true);
            dispatch(jobActions.createJob(false, function (err, job) {
                setIsInitialising(false);
                if (job) {
                    // history replace so navigating back will go to dashboard not /new
                    history.replace({
                        pathname: "/invoices/" + job.id + "/create/new",
                        search: dealId ? "?dealId=" + dealId : '',
                    });
                }
                else {
                    dispatch(appActions.showBanner({ type: 'error' }));
                }
            }, dealId));
        }
    }, [invoiceId, history]);
    if (isInitialising) {
        return React.createElement(LoaderOverlay, null);
    }
    return (React.createElement(EditJobPage, { jobId: invoiceId, viewType: (_b = match === null || match === void 0 ? void 0 : match.params) === null || _b === void 0 ? void 0 : _b.view, sendJobToCustomer: function (id, payload, cb) { return dispatch(jobActions.sendInvoiceToCustomer(id, payload, cb)); } }));
};
export default NewEditInvoicePage;
