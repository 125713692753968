var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef, useCallback, useMemo, useState, } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import FileSaver from 'file-saver';
import { getFileType, getFileTypeIcon, getFileTypeIconDownload, } from '@payaca/helpers/fileHelper';
var imageFileExtensions = ['.jpeg', '.jpg', '.png'];
var JobAttachment = function (_a) {
    var file = _a.file, isUpload = _a.isUpload, readOnly = _a.readOnly, onFileChange = _a.onFileChange, isDownloadDisabled = _a.isDownloadDisabled;
    var _b = useState(null), editingFile = _b[0], setEditingFile = _b[1];
    var nodeRef = useRef(null);
    var fileTypeIcon = useMemo(function () {
        return isUpload || isDownloadDisabled
            ? getFileTypeIcon(file)
            : getFileTypeIconDownload(file);
    }, [file, isDownloadDisabled, isUpload]);
    var fileExtension = useMemo(function () { return getFileType(file.attachmentUrl || file.termUrl); }, [file]);
    var isImage = useMemo(function () { return imageFileExtensions.find(function (t) { return t === fileExtension; }); }, [fileExtension]);
    var exitEditingMode = function () {
        document.removeEventListener('mousedown', handleEditModeClick, false);
        setEditingFile(null);
    };
    var enterEditingMode = function (file) {
        document.addEventListener('mousedown', handleEditModeClick, false);
        setEditingFile(file);
    };
    var handleEditModeClick = function (e) {
        if (nodeRef.current && nodeRef.current.contains(e.target)) {
            return;
        }
        exitEditingMode();
    };
    var onClickEdit = function (file) {
        if (editingFile === file) {
            exitEditingMode();
        }
        else {
            enterEditingMode(file);
        }
    };
    var onFileNameChange = function (e, file) {
        if (onFileChange) {
            var newFileName = e.target.value;
            onFileChange(__assign(__assign({}, file), { fileName: newFileName }), false);
        }
    };
    var keyPressed = function (e) {
        if (e.key === 'Enter') {
            exitEditingMode();
        }
    };
    var isEditable = useMemo(function () { return !!file.file; }, [file]); // locally uploaded file
    // locally uploaded
    var editMode = useMemo(function () { return editingFile === file.file; }, [editingFile, file]);
    // downloading and not readonly and not editmode
    var showDeleteIcon = useMemo(function () { return !editMode && !!isUpload && !readOnly; }, [editMode, isUpload, readOnly]);
    // downlaoding, not readony and editable (i.e. not uploaded yet)
    var showEditIcon = useMemo(function () { return !!isUpload && !readOnly && !!isEditable && !editMode; }, [isUpload, readOnly, isEditable, editMode]);
    // editable and in editmode
    var showInputEditInput = useMemo(function () { return !!isEditable && !!editMode; }, [isEditable, editMode]);
    var renderFileName = useCallback(function () {
        var fullFileName = isEditable
            ? file.fileName
            : "" + file.fileName + (fileExtension || '');
        return (React.createElement("div", { className: "file-name-wrapper" + (showEditIcon ? ' editable' : '') },
            showInputEditInput ? (React.createElement("input", { ref: nodeRef, className: "file-label edit", type: "text", value: file.fileName, onChange: function (e) { return onFileNameChange(e, file); }, onKeyPress: function (e) { return keyPressed(e); } })) : (React.createElement("span", null, fullFileName)),
            showEditIcon && (React.createElement(FontAwesomeIcon, { icon: faPen, className: "edit-icon", onClick: function () {
                    onClickEdit(file.file);
                } }))));
    }, [
        file,
        fileExtension,
        isEditable,
        keyPressed,
        onClickEdit,
        onFileNameChange,
        showEditIcon,
        showInputEditInput,
    ]);
    var renderFile = useCallback(function () {
        return (React.createElement("div", { className: "file-wrapper" },
            React.createElement("div", { className: "image-icon-container" }, isImage ? (React.createElement("img", { src: file.attachmentUrl, alt: "attachment " + file.fileName })) : (React.createElement(FontAwesomeIcon, { icon: fileTypeIcon, className: "file-type-icon" }))),
            renderFileName()));
    }, [file, fileTypeIcon, isImage, renderFileName]);
    if (isUpload) {
        // uploading files and local editable
        return (React.createElement("div", { className: "job-attachment" },
            showDeleteIcon && !readOnly && (React.createElement(FontAwesomeIcon, { icon: faTimes, className: "delete-icon", onClick: function () {
                    onFileChange && onFileChange(file, true);
                } })),
            renderFile()));
    }
    else {
        // viewing and downloading files
        return (React.createElement("div", { onClick: function () {
                return !isDownloadDisabled ? FileSaver.saveAs(file.attachmentUrl) : null;
            }, className: "job-attachment download" },
            React.createElement("div", { className: "background-colour" }),
            renderFile()));
    }
};
export default JobAttachment;
