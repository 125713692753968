import React from 'react';
import { ModalWrapper } from '@/ui/components';
import ListedItemGroupsControl from '../listedItemGroupsControl/ListedItemGroupsControl';
var SelectItemGroupModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onSelectItemGroup = _a.onSelectItemGroup;
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalWrapper, { open: isOpen, title: 'Select an item group', onClose: onClose, classes: 'select-item-group-modal', size: "md", showCloseIcon: true },
            React.createElement(ListedItemGroupsControl, { onClick: function (lineItemGroup) {
                    onSelectItemGroup(lineItemGroup);
                } }))));
};
export default SelectItemGroupModal;
