var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ListedCustomersTable } from '@/ui/components';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { actions as appActions } from '@/api/app';
import { actions as customerActions } from '@bit/payaca-tech.payaca-core.store.customer';
import './ListedCustomersPage.sass';
import { Button, CreateButton, ButtonStyleVariant, } from '@payaca/components/button';
var ListedCustomersPage = function () {
    var dispatch = useDispatch();
    var history = useHistory();
    var _a = useState([]), selectedCustomerIds = _a[0], setSelectedCustomerIds = _a[1];
    var _b = useState(false), isInBulkActionsMode = _b[0], setIsInBulkActionsMode = _b[1];
    var _c = useState({
        pageSize: 20,
        pageNumber: 1,
        searchTerm: '',
    }), getListedCustomersRequestData = _c[0], setGetListedCustomersRequestData = _c[1];
    useEffect(function () {
        requestGetListedCustomersPage();
    }, [getListedCustomersRequestData]);
    var requestGetListedCustomersPage = useCallback(function () {
        dispatch(customerActions.requestGetListedCustomersPage(getListedCustomersRequestData));
    }, [getListedCustomersRequestData, dispatch]);
    var isArchivingCustomers = useSelector(function (state) { return state.customer.isArchivingCustomers; });
    var customerActionCallback = useCallback(function (error) {
        if (error) {
            dispatch(appActions.showBanner({ type: 'error' }));
        }
        setSelectedCustomerIds([]);
        requestGetListedCustomersPage();
    }, [requestGetListedCustomersPage, dispatch]);
    var onSelectCustomers = useCallback(function (customerIds) {
        setSelectedCustomerIds(function (selectedCustomerIds) {
            return selectedCustomerIds
                .concat(customerIds)
                .filter(function (customerId, index, array) {
                return array.indexOf(customerId) === index; // get unique
            });
        });
    }, []);
    var onUnselectCustomers = useCallback(function (customerIds) {
        setSelectedCustomerIds(function (selectedCustomerIds) {
            return selectedCustomerIds.filter(function (customerId) {
                return !customerIds.includes(customerId);
            });
        });
    }, []);
    var navigateToCustomerPage = useCallback(function (customerId) {
        history.push("/customers/" + customerId);
    }, [history]);
    var onClickRow = useCallback(function (customerId) {
        navigateToCustomerPage(customerId);
    }, [navigateToCustomerPage]);
    var onBulkDeleteCustomers = useCallback(function (customerIds) {
        dispatch(customerActions.requestArchiveCustomers(customerIds, customerActionCallback));
    }, [dispatch, customerActionCallback]);
    var onSelectPage = useCallback(function (pageNumber) {
        setGetListedCustomersRequestData(function (getListedCustomersRequestData) {
            return __assign(__assign({}, getListedCustomersRequestData), { pageNumber: pageNumber });
        });
    }, []);
    var renderListedCustomersTable = useMemo(function () {
        return (React.createElement(ListedCustomersTable, { selectedCustomerIds: selectedCustomerIds, onClickRow: isInBulkActionsMode ? undefined : onClickRow, onSelectCustomers: onSelectCustomers, onUnselectCustomers: onUnselectCustomers, onSelectPage: onSelectPage }));
    }, [
        selectedCustomerIds,
        onClickRow,
        onSelectCustomers,
        onUnselectCustomers,
        isInBulkActionsMode,
        onSelectPage,
    ]);
    var searchFieldConfig = useMemo(function () {
        return {
            placeholder: 'Search',
            onSearchTermChange: function (searchTerm) {
                setGetListedCustomersRequestData(function (getListedCustomersRequestData) {
                    return __assign(__assign({}, getListedCustomersRequestData), { searchTerm: searchTerm, pageNumber: 1 });
                });
            },
        };
    }, []);
    var listedCustomersTitleBar = useMemo(function () {
        return (React.createElement("div", { className: "flex-container flex-center" },
            React.createElement("div", null,
                React.createElement("h1", null, "Customers")),
            React.createElement(CreateButton, { onClick: function () { return history.push('/customers/new'); } }, "Create new"),
            React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () { return setIsInBulkActionsMode(true); } }, "Edit customers")));
    }, [history]);
    var listedCustomersBulkActionsBar = useMemo(function () {
        var isAnyCustomersSelected = !!selectedCustomerIds.length;
        return (React.createElement("div", { className: "flex-container flex-center flex-grow" },
            React.createElement("div", { className: "flex-container" },
                React.createElement("div", null,
                    React.createElement("h1", null, "Customers")),
                React.createElement("span", null, isAnyCustomersSelected
                    ? "You have " + selectedCustomerIds.length + " " + (selectedCustomerIds.length === 1 ? 'row' : 'rows') + " selected"
                    : 'Check the rows you wish to select')),
            React.createElement("div", { className: "flex-grow" }),
            React.createElement(Button, { isDisabled: !isAnyCustomersSelected, isProcessing: isArchivingCustomers, onClick: function () {
                    return !isArchivingCustomers && onBulkDeleteCustomers(selectedCustomerIds);
                } }, "Delete"),
            React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () {
                    setIsInBulkActionsMode(false);
                    setSelectedCustomerIds([]);
                } }, "Cancel")));
    }, [selectedCustomerIds, onBulkDeleteCustomers, isArchivingCustomers]);
    return (React.createElement(AuthenticatedPageWrapper, { className: "listed-customers-page", title: isInBulkActionsMode
            ? listedCustomersBulkActionsBar
            : listedCustomersTitleBar, searchFieldConfig: searchFieldConfig },
        React.createElement("div", { className: "listed-customers-table-container " + (isInBulkActionsMode ? 'bulk-actions-mode' : '') }, renderListedCustomersTable)));
};
export default ListedCustomersPage;
