import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '@payaca/components/modal';
import UpdateSubscriptionAdditionalUserSeatsControl from '../updateSubscriptionAdditionalUserSeatsControl/UpdateSubscriptionAdditonalUserSeatsControl';
import './UpdateSubscriptionAdditionalUserSeatsModal.sass';
var UpdateSubscriptionAdditionalUserSeatsModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onUpdateSubscription = _a.onUpdateSubscription;
    var _b = useState(false), subscriptionIsUpdated = _b[0], setSubscriptionIsUpdated = _b[1];
    useEffect(function () {
        if (isOpen) {
            setSubscriptionIsUpdated(false);
        }
    }, [isOpen]);
    return (React.createElement(Modal, { isOpen: isOpen, size: "sm", onClose: onClose, className: "update-subscription-additional-user-seats-modal" },
        !subscriptionIsUpdated && (React.createElement(UpdateSubscriptionAdditionalUserSeatsControl, { onUpdateSubscription: function () {
                setSubscriptionIsUpdated(true);
                onUpdateSubscription && onUpdateSubscription();
            } })),
        subscriptionIsUpdated && (React.createElement("div", { className: "subscription-update-success-message" },
            React.createElement(FontAwesomeIcon, { icon: faCheck }),
            React.createElement("p", null, "Success!")))));
};
export default UpdateSubscriptionAdditionalUserSeatsModal;
