import PhoneInput from 'react-phone-input-2';
import React, { useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldLabel from '../fieldLabel/FieldLabel';
import InputWrapper from '../inputWrapper/InputWrapper';
import 'react-phone-input-2/lib/bootstrap.css';
import './PhoneNumberField.css';
var formatValue = function (value, dialCode) {
    var formattedValue = value;
    // remove any leading zeros after country prefix
    if (value[2] === '0') {
        formattedValue = value.slice(0, 2) + value.slice(3, value.length);
    }
    // if phone value is dialcode clear input value
    if (value === dialCode) {
        formattedValue = '';
    }
    return formattedValue;
};
var BasicField = function (_a) {
    var styleVariant = _a.styleVariant, name = _a.name, value = _a.value, label = _a.label, description = _a.description, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, _c = _a.isRequired, isRequired = _c === void 0 ? false : _c, iconBefore = _a.iconBefore, iconAfter = _a.iconAfter, onChange = _a.onChange, onTouch = _a.onTouch;
    var onInputChange = useCallback(function (value, country) {
        var _a;
        var formattedValue = formatValue(value, country.dialCode);
        onChange &&
            onChange((_a = {},
                _a[name] = formattedValue,
                _a));
    }, [onChange, name]);
    var onInputTouch = useCallback(function (event) {
        onTouch && onTouch(name);
    }, [onTouch, name]);
    useEffect(function () {
        var _a;
        if (value && onChange) {
            var strippedValue = value.replace(' ', '');
            if (value !== strippedValue) {
                onChange((_a = {},
                    _a[name] = strippedValue,
                    _a));
            }
        }
    }, [value, onChange]);
    return (React.createElement("div", { className: "phone-number-field" },
        React.createElement(FieldLabel, { label: label, isRequired: isRequired, description: description }),
        React.createElement(InputWrapper, { isDisabled: isDisabled, styleVariant: styleVariant },
            iconBefore && React.createElement(FontAwesomeIcon, { icon: iconBefore }),
            React.createElement(PhoneInput, { country: "gb", value: value || '44', onChange: onInputChange, countryCodeEditable: false, onBlur: onInputTouch, enableLongNumbers: 12 }),
            iconAfter && React.createElement(FontAwesomeIcon, { icon: iconAfter }))));
};
export default BasicField;
