import React, { useEffect, useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SearchField from '../searchField/SearchField';
import { Search } from 'react-iconly';
import './HeaderSearchControl.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
var HeaderSearchControl = function (_a) {
    var _b = _a.isSmallView, isSmallView = _b === void 0 ? false : _b, placeholder = _a.placeholder, onSearchTermChange = _a.onSearchTermChange, onToggleSearchBarCollapse = _a.onToggleSearchBarCollapse;
    var _c = useState(true), isCollapsed = _c[0], setIsCollapsed = _c[1];
    useEffect(function () {
        onToggleSearchBarCollapse && onToggleSearchBarCollapse(isCollapsed);
        if (isCollapsed)
            onSearchTermChange();
    }, [isCollapsed]);
    useEffect(function () {
        if (isSmallView)
            setIsCollapsed(true);
    }, [isSmallView]);
    return (React.createElement("div", { className: "header-search-control " + (isSmallView ? 'sm-view' : '') + " " + (isCollapsed ? 'collapsed' : '') },
        React.createElement("div", { className: "header-search-control-inner" },
            React.createElement("div", { className: "search-icon-container", onClick: function () { return setIsCollapsed(false); } },
                React.createElement(Search, { set: "light" })),
            (!isCollapsed || !isSmallView) && (React.createElement(React.Fragment, null,
                React.createElement(SearchField, { styleVariant: InputStyleVariant.UNSTYLED, placeholder: placeholder, onSearchTermChange: onSearchTermChange, showSearchIcon: false }),
                isSmallView && (React.createElement("div", { className: "close-icon-container", onClick: function () { return setIsCollapsed(true); } },
                    React.createElement(FontAwesomeIcon, { icon: faTimes }))))))));
};
export default HeaderSearchControl;
