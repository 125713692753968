var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { Modal } from '@payaca/components/modal';
import SendJob from '../sendJob/SendJob';
import { actions as jobsStoreActions } from '@bit/payaca-tech.payaca-core.store.jobs';
import { actions as jobPaymentsActions } from '@bit/payaca-tech.payaca-core.store.job-payments';
import { actions as dealsActions } from '@bit/payaca-tech.payaca-core.store.deals';
import { actions as customerActions } from '@bit/payaca-tech.payaca-core.store.customer';
import './ResendJobModal.css';
var ResendJobModal = function (_a) {
    var isOpen = _a.isOpen, jobId = _a.jobId, onClose = _a.onClose;
    var dispatch = useDispatch();
    var isResendingJob = useSelector(function (state) {
        return state.jobsStore.isResendingJob;
    });
    var resendJobErrorMessage = useSelector(function (state) {
        return state.jobsStore.resendJobErrorMessage;
    });
    var isResentJobSuccessfully = useSelector(function (state) {
        return state.jobsStore.isResentJobSuccessfully;
    });
    var _b = useState({
        preButtonEmailText: '',
        postButtonEmailText: '',
        sendMeACopy: true,
        isSendJobDisabled: true,
    }), resendEmailState = _b[0], setResendEmailState = _b[1];
    var onResendJob = useCallback(function () {
        dispatch(jobsStoreActions.requestResendJob(jobId, {
            preButtonEmailText: resendEmailState.preButtonEmailText,
            postButtonEmailText: resendEmailState.postButtonEmailText,
            sendMeACopy: resendEmailState.sendMeACopy,
        }));
    }, [dispatch, jobId, onClose, resendEmailState]);
    useEffect(function () {
        // resent successfully and
        if (!isResendingJob && isResentJobSuccessfully) {
            dispatch(jobsStoreActions.clearResendJob());
            onClose();
        }
    }, [dispatch, isResendingJob, isResentJobSuccessfully, onClose]);
    useEffect(function () {
        dispatch(jobsStoreActions.requestGetJob(jobId));
    }, [jobId]);
    var job = useSelector(function (state) {
        var _a;
        return state.jobsStore.jobs && ((_a = state.jobsStore.jobs[jobId]) === null || _a === void 0 ? void 0 : _a.entity);
    });
    var deal = useSelector(function (state) {
        var _a;
        if (!job.dealId)
            return;
        return state.deals.deals && ((_a = state.deals.deals[job.dealId]) === null || _a === void 0 ? void 0 : _a.entity);
    });
    useEffect(function () {
        if (job) {
            dispatch(jobPaymentsActions.requestGetJobPaymentsForDeal(job === null || job === void 0 ? void 0 : job.dealId));
            dispatch(dealsActions.requestGetDeal(job.dealId));
        }
    }, [job === null || job === void 0 ? void 0 : job.dealId]);
    useEffect(function () {
        if (deal === null || deal === void 0 ? void 0 : deal.customerId) {
            dispatch(customerActions.requestGetAndSetCurrentCustomer(deal.customerId));
        }
    }, [deal === null || deal === void 0 ? void 0 : deal.customerId]);
    var jobPayments = useSelector(function (state) {
        var _a;
        if (!(job === null || job === void 0 ? void 0 : job.dealId))
            return [];
        var deal = state.deals.deals && ((_a = state.deals.deals[job === null || job === void 0 ? void 0 : job.dealId]) === null || _a === void 0 ? void 0 : _a.entity);
        var jobPaymentIds = (deal === null || deal === void 0 ? void 0 : deal.jobPaymentIds) || [];
        var payments = jobPaymentIds.map(function (jobPaymentId) {
            var _a;
            return (_a = state.jobPayments.jobPayments[jobPaymentId]) === null || _a === void 0 ? void 0 : _a.entity;
        });
        return payments.filter(function (x) { return !!x; });
    });
    var customer = useSelector(function (state) { return state.customer.currentCustomer; });
    return (React.createElement(Modal, { isOpen: isOpen, title: "Resend job", size: "md", onClose: onClose, className: "resend-job-modal", actions: React.createElement(Button, { onClick: function () { return !isResendingJob && onResendJob(); }, isDisabled: resendEmailState.isSendJobDisabled, iconAfter: faChevronRight, isProcessing: isResendingJob, styleVariant: ButtonStyleVariant.OUTSIZE }, "Send") },
        React.createElement(SendJob, { job: job, jobPayments: jobPayments, customer: customer, preButtonEmailText: resendEmailState.preButtonEmailText || '', postButtonEmailText: resendEmailState.postButtonEmailText || '', sendMeACopy: !!resendEmailState.sendMeACopy, onUpdate: function (change) {
                setResendEmailState(function (resendEmailState) { return (__assign(__assign({}, resendEmailState), change)); });
            }, onSetIsSendJobDisabled: function (isSendJobDisabled) {
                setResendEmailState(function (resendEmailState) {
                    return __assign(__assign({}, resendEmailState), { isSendJobDisabled: isSendJobDisabled });
                });
            } }),
        resendJobErrorMessage && (React.createElement("div", { className: "error" }, "Sorry, there was an error resending your job. Please try again."))));
};
export default ResendJobModal;
