import React, { useCallback, useMemo, useState, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as subscriptionActions } from '@bit/payaca-tech.payaca-core.store.subscription';
import { actions as analyticsActions } from '@bit/payaca-tech.payaca-core.store.analytics';
import SelectSubscriptionControl from '../selectSubscriptionControl/SelectSubscriptionControl';
import SubscriptionComparisonModal from '@/ui/components/subscriptionComparisonModal/SubscriptionComparisonModal';
import './ManageSubscriptionPageContent.sass';
import { Button, ButtonStyleVariant, ButtonColourVariant, } from '@payaca/components/button';
import CurrentSubscriptionControls from '../../currentSubscriptionControls/CurrentSubscriptionControls';
var ContentViewType = {
    DEFAULT: 'default',
    UPDATE_PAYMENT_METHOD: 'updatePaymentMethod',
};
var ManageSubscriptionPageContent = function () {
    var dispatch = useDispatch();
    var _a = useState(ContentViewType.DEFAULT), contentView = _a[0], setContentView = _a[1];
    var accountSubscription = useSelector(function (state) { return state.subscription.accountSubscription; });
    var _b = useState(false), showUpdateSubscriptionAdditionalUserSeatsModal = _b[0], setShowUpdateSubscriptionAdditionalUserSeatsModal = _b[1];
    var _c = useState(false), showSubscriptionComparisonModal = _c[0], setShowSubscriptionComparisonModal = _c[1];
    var isRestoringSubscription = useSelector(function (state) { return state.subscription.isRestoringSubscription; });
    var _d = useState(false), restoreSubscriptionAttemptMade = _d[0], setRestoreSubscriptionAttemptMade = _d[1];
    var subscriptionEndsAt = useMemo(function () {
        return accountSubscription === null || accountSubscription === void 0 ? void 0 : accountSubscription.subscriptionInformation.subscriptionEndDate;
    }, [accountSubscription]);
    var handleRestoreSubscriptionAsyncBehaviour = useCallback(function () {
        setRestoreSubscriptionAttemptMade(true);
        setTimeout(function () { return dispatch(subscriptionActions.requestGetAccountSubscription()); }, 5000);
    }, [dispatch]);
    var onUpdateSubscription = useCallback(function () {
        setContentView(ContentViewType.DEFAULT);
        dispatch(analyticsActions.requestGetSubscriptionUsageData());
    }, [dispatch]);
    return (React.createElement("div", { className: "manage-subscription-page-content" },
        React.createElement(CurrentSubscriptionControls, { onUpdateSubscription: onUpdateSubscription }),
        !subscriptionEndsAt && (React.createElement(React.Fragment, null,
            React.createElement(SelectSubscriptionControl, { onUpdateSubscription: onUpdateSubscription }),
            React.createElement("div", { className: "view-comparison-chart-trigger-container" },
                React.createElement(Button, { onClick: function () { return setShowSubscriptionComparisonModal(true); }, styleVariant: ButtonStyleVariant.OUTSIZE, colourVariant: ButtonColourVariant.SECONDARY }, "View comparison chart")))),
        React.createElement(SubscriptionComparisonModal, { isOpen: showSubscriptionComparisonModal, onClose: function () { return setShowSubscriptionComparisonModal(false); } })));
};
export default ManageSubscriptionPageContent;
