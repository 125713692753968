var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { Fragment, useCallback, useEffect, useMemo, useState, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import isEqual from 'lodash.isequal';
import { Prompt } from 'react-router-dom';
import { ValidatedForm } from '@payaca/components';
import { BasicField } from '@payaca/components/basicField';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { TextareaField } from '@payaca/components/textareaField';
import SelectItemModal from '@/ui/components/selectItemModal/SelectItemModal';
import { ConfirmModal } from '@/ui/components';
import { ContentPanel } from '@payaca/components/contentPanel';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import { currencyPrice, } from '@bit/payaca-tech.payaca-core.helpers.finance';
import { getModal } from '@/helpers/modalHelper';
import { getIsRequiredFieldValidator, getLengthFieldValidator, } from '@payaca/helpers/fieldValidationHelper';
import { usePrevious } from '@/utils/customHooks';
import { actions as lineItemGroupActions } from '@bit/payaca-tech.payaca-core.store.line-item-groups';
import { actions as lineItemV2Actions } from '@payaca/store/lineItemsV2';
import './CreateEditLineItemGroupControl.sass';
import BodyWithSidePanelContentWrapper from '../bodyWithSidePanelContentWrapper/BodyWithSidePanelContentWrapper';
import { LabelValuePair } from '@payaca/components/labelValuePair';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { actions as lineItemActions } from '@payaca/store/lineItemsV2';
import { getLineItemGroup, getLineItemsByLineItemGroupId, } from '@/utils/stateAccessors';
import { calculateLineItemGroupTotals } from '@payaca/helpers/lineItemGroupTotalsHelper';
import LineItemGroupLineItemControl from '../lineItemGroupLineItemControl/LineItemGroupLineItemControl';
import { ErrorMessage } from '@payaca/components/feedbackMessage';
import { ResponsiveViewWrapper } from '@payaca/components/responsiveViewWrapper';
var CreateEditLineItemControl = function (_a) {
    var lineItemGroupId = _a.lineItemGroupId;
    var dispatch = useDispatch();
    var history = useHistory();
    var _b = useState({}), formState = _b[0], setFormState = _b[1];
    var _c = useState(false), isSaveDisabled = _c[0], setIsSaveDisabled = _c[1];
    var _d = useState(false), requiresSelectedMultiSelectItemIfNone = _d[0], setRequiresSelectedMultiSelectItemIfNone = _d[1];
    var _e = useState(false), showItemSearch = _e[0], setShowItemSearch = _e[1];
    var lineItemGroup = useSelector(function (state) {
        return getLineItemGroup(state, lineItemGroupId);
    });
    var currentLineItemGroup = useSelector(function (state) { return state.lineItemGroups.lineItemGroups[lineItemGroupId]; });
    var isUpdatingLineItemGroup = useSelector(function (state) { return state.lineItemGroups.isUpdatingLineItemGroup; });
    var isArchivingLineItemGroup = useSelector(function (state) { return state.lineItemGroups.isArchivingLineItemGroup; });
    var archiveLineItemGroupErrorMessage = useSelector(function (state) { return state.lineItemGroups.archiveLineItemGroupErrorMessage; });
    var isArchiveLineItemGroupSuccessful = useSelector(function (state) { return state.lineItemGroups.isArchiveLineItemGroupSuccessful; });
    var isUpdateLineItemGroupSuccessful = useSelector(function (state) { return state.lineItemGroups.isUpdateLineItemGroupSuccessful; });
    var updateLineItemGroupErrorMessage = useSelector(function (state) { return state.lineItemGroups.updateLineItemGroupErrorMessage; });
    var lineItems = useSelector(function (state) {
        return getLineItemsByLineItemGroupId(state, lineItemGroupId);
    });
    var currentLineItemGroupEntity = useMemo(function () { return (currentLineItemGroup === null || currentLineItemGroup === void 0 ? void 0 : currentLineItemGroup.entity) || null; }, [currentLineItemGroup]);
    var previousIsUpdatingLineItemGroup = usePrevious(isUpdatingLineItemGroup);
    useEffect(function () {
        if (!updateLineItemGroupErrorMessage &&
            previousIsUpdatingLineItemGroup &&
            !isUpdatingLineItemGroup &&
            isUpdateLineItemGroupSuccessful) {
            // item group has successfully saved
            history.push('/itemGroups');
        }
    }, [
        previousIsUpdatingLineItemGroup,
        isUpdatingLineItemGroup,
        updateLineItemGroupErrorMessage,
        isUpdateLineItemGroupSuccessful,
    ]);
    var _f = useState(false), showDeleteGroupModal = _f[0], setShowDeleteGroupModal = _f[1];
    var isFetchingLineItemGroup = useMemo(function () {
        return lineItemGroupId && currentLineItemGroup
            ? currentLineItemGroup.isFetching
            : true;
    }, [currentLineItemGroup]);
    var getLineItemFromLineItemId = useCallback(function (lineItemId) { return lineItems[lineItemId]; }, [lineItems]);
    useEffect(function () {
        if (lineItemGroupId) {
            dispatch(lineItemGroupActions.requestGetLineItemGroup(lineItemGroupId));
            dispatch(lineItemActions.requestGetLineItemsForLineItemGroup(lineItemGroupId));
        }
        return function () {
            dispatch(lineItemGroupActions.clearUpdateLineItemGroup());
            dispatch(lineItemGroupActions.clearArchiveLineItemGroup());
        };
    }, [lineItemGroupId]);
    var onSubmit = useCallback(function (formState) {
        if (!lineItemGroupId) {
            return;
        }
        dispatch(lineItemGroupActions.requestUpdateLineItemGroup(lineItemGroupId, {
            id: lineItemGroupId,
            description: formState.description,
            name: formState.name,
            lineItemRelations: formState.lineItemRelations,
        }));
    }, [dispatch, lineItemGroupId]);
    var initialFormState = useMemo(function () {
        return {
            id: currentLineItemGroupEntity === null || currentLineItemGroupEntity === void 0 ? void 0 : currentLineItemGroupEntity.id,
            name: currentLineItemGroupEntity === null || currentLineItemGroupEntity === void 0 ? void 0 : currentLineItemGroupEntity.name,
            description: currentLineItemGroupEntity === null || currentLineItemGroupEntity === void 0 ? void 0 : currentLineItemGroupEntity.description,
            lineItemRelations: (currentLineItemGroupEntity === null || currentLineItemGroupEntity === void 0 ? void 0 : currentLineItemGroupEntity.lineItemRelations) || [],
        };
    }, [currentLineItemGroupEntity]);
    var fieldValidators = useMemo(function () {
        return {
            name: [
                getIsRequiredFieldValidator(),
                getLengthFieldValidator({ min: 0, max: 255 }),
            ],
        };
    }, []);
    var handleMultiChoiceItemDeselect = function (item, formState, index) {
        // number of current multi choices - must always be 1
        var selectedMultiChoiceItemCount = formState.lineItemRelations.filter(function (i) { return i.isMultipleChoice && i.isSelected; }).length;
        if (formState.lineItemRelations[index].isMultipleChoice &&
            formState.lineItemRelations[index].isSelected &&
            !item.isMultipleChoice &&
            selectedMultiChoiceItemCount === 1) {
            // Removing current item which is selected and multi choice from being multi choice - select another multi choice
            var nextMultiChoiceIndex = formState.lineItemRelations.findIndex(function (i, itemIndex) {
                return itemIndex !== index && i.isMultipleChoice;
            });
            if (nextMultiChoiceIndex !== -1) {
                formState.lineItemRelations[nextMultiChoiceIndex] = __assign(__assign({}, formState.lineItemRelations[nextMultiChoiceIndex]), { isSelected: true });
            }
        }
        else if (item.isMultipleChoice &&
            item.isSelected &&
            selectedMultiChoiceItemCount === 1) {
            // Setting current item to selected multi choice - unselect previously selected item
            var currentSelectedMultiChoiceIndex = formState.lineItemRelations.findIndex(function (i, itemIndex) {
                return itemIndex !== index && i.isMultipleChoice && i.isSelected;
            });
            if (currentSelectedMultiChoiceIndex !== -1) {
                formState.lineItemRelations[currentSelectedMultiChoiceIndex] = __assign(__assign({}, formState.lineItemRelations[currentSelectedMultiChoiceIndex]), { isSelected: false });
            }
        }
        return formState;
    };
    var onUpdateItem = useCallback(function (updateItem, index, formState, onFieldChange) {
        // update any multi choice items in group
        var updatedFormState = handleMultiChoiceItemDeselect(updateItem, formState, index);
        var updatedItemToInsert = __assign(__assign({}, updatedFormState.lineItemRelations[index]), { quantity: updateItem.quantity });
        if (!(updateItem.isMultipleChoice && !updateItem.isSelected)) {
            // update multi/optional as long as not trying to unselect last multiple choice item
            updatedItemToInsert = __assign(__assign({}, updatedItemToInsert), { isMultipleChoice: updateItem.isMultipleChoice, isSelected: updateItem.isSelected, isOptional: updateItem.isOptional });
        }
        var lineItemRelations = updatedFormState.lineItemRelations
            .slice(0, index)
            .concat([updatedItemToInsert])
            .concat(updatedFormState.lineItemRelations.slice(index + 1));
        onFieldChange({
            lineItemRelations: lineItemRelations,
        });
    }, [handleMultiChoiceItemDeselect]);
    var archiveLineItemGroup = useCallback(function () {
        dispatch(lineItemGroupActions.requestArchiveLineItemGroup(lineItemGroupId));
    }, [dispatch]);
    useEffect(function () {
        if (isArchiveLineItemGroupSuccessful && !archiveLineItemGroupErrorMessage) {
            // successfully archive line item group - return to line item groups page
            history.push('/itemGroups');
        }
    }, [isArchiveLineItemGroupSuccessful, archiveLineItemGroupErrorMessage]);
    var deselectMultiSelectItemsOnItemSelect = useCallback(function (formState, updateValue, lineItemRelation, index) {
        var newUpdateValue = updateValue;
        if ((lineItemRelation.isMultipleChoice ||
            newUpdateValue["lineItemRelations[" + index + "].isMultipleChoice"]) &&
            newUpdateValue["lineItemRelations[" + index + "].isSelected"]) {
            formState.lineItemRelations.forEach(function (lineItemRelation, liIndex) {
                if (liIndex !== index && lineItemRelation.isMultipleChoice) {
                    newUpdateValue["lineItemRelations[" + liIndex + "].isSelected"] =
                        false;
                }
            });
        }
        return newUpdateValue;
    }, []);
    var setSelectedMultiSelectItemIfNone = useCallback(function (formState) {
        var _a;
        if (!(formState === null || formState === void 0 ? void 0 : formState.lineItemRelations))
            return;
        var multiSelectItems = formState.lineItemRelations.filter(function (x) { return x.isMultipleChoice; });
        if (!multiSelectItems.length)
            return;
        var hasSelectedMultiSelectItem = multiSelectItems.find(function (x) { return x.isMultipleChoice && x.isSelected; });
        if (hasSelectedMultiSelectItem)
            return;
        var itemToSelect = multiSelectItems.at(-1);
        var itemToSelectIndex = formState.lineItemRelations.findIndex(function (x) { return x.id === itemToSelect.id; });
        var updateValue = (_a = {},
            _a["lineItemRelations[" + itemToSelectIndex + "].isSelected"] = true,
            _a);
        return updateValue;
    }, []);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        var _a;
        setFormState(formState);
        var hasChangesMade = !isEqual(formState, initialFormState);
        setIsSaveDisabled(!isValid || isFetchingLineItemGroup || !lineItems.length);
        if (requiresSelectedMultiSelectItemIfNone) {
            var updateValue = setSelectedMultiSelectItemIfNone(formState);
            if (updateValue)
                onFieldChange(updateValue);
            setRequiresSelectedMultiSelectItemIfNone(false);
        }
        return (React.createElement(Fragment, null,
            React.createElement("div", { className: "scrollable-content" },
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.name, isTouched: touchedState.name || false },
                    React.createElement(BasicField, { label: "Group name", styleVariant: InputStyleVariant.OUTSIZE, description: "Your internal group name. This will not be seen by your customers.", value: formState.name || '', name: "name", onChange: onFieldChange, onTouch: onFieldTouch })),
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.description, isTouched: touchedState.description || false },
                    React.createElement(TextareaField, { label: "Description", styleVariant: InputStyleVariant.OUTSIZE, description: "Description for your customers. This will be shown on your estimates, quotes and invoices.", value: formState.description || '', name: "description", onChange: onFieldChange, onTouch: onFieldTouch, rows: 3 })),
                !!((_a = formState.lineItemRelations) === null || _a === void 0 ? void 0 : _a.length) && (React.createElement("div", { className: "line-item-controls-container" }, formState.lineItemRelations.map(function (lineItemRelation, index) {
                    return (React.createElement(LineItemGroupLineItemControl, { key: "line-item-group-line-item-control-" + index, lineItemGroupLineItem: lineItemRelation, fieldNamePrefix: "lineItemRelations[" + index + "]", onTouch: onFieldTouch, onChange: function (value) {
                            var updateObject = deselectMultiSelectItemsOnItemSelect(formState, value, lineItemRelation, index);
                            onFieldChange(updateObject);
                        }, onDelete: function () {
                            formState.lineItemRelations.splice(index, 1);
                            onFieldChange({
                                lineItemRelations: formState.lineItemRelations,
                            });
                            setRequiresSelectedMultiSelectItemIfNone(true);
                        } }));
                })))),
            React.createElement("div", { className: "add-item-button-container" },
                React.createElement(Button, { isOutlined: true, onClick: function () {
                        setShowItemSearch(true);
                    }, iconBefore: faPlus }, "Add item to group")),
            React.createElement(SelectItemModal, { isOpen: showItemSearch, onClose: function () { return setShowItemSearch(false); }, onSelectItem: function (itemId) {
                    onFieldChange({
                        lineItemRelations: __spreadArrays(formState.lineItemRelations, [
                            {
                                lineItemId: itemId,
                                isMultipleChoice: false,
                                isSelected: false,
                                isOptional: false,
                                quantity: 1,
                            },
                        ]),
                    });
                    // requestGetLineItem
                    dispatch(lineItemV2Actions.requestGetLineItem(itemId));
                    // close modal
                    setShowItemSearch(false);
                }, disabledItemIds: [] }),
            React.createElement(Prompt
            // only show prompt when there are changes made and hasnt finished updating or archiving
            , { 
                // only show prompt when there are changes made and hasnt finished updating or archiving
                when: hasChangesMade &&
                    !isUpdateLineItemGroupSuccessful &&
                    !isArchiveLineItemGroupSuccessful, message: "There are unsaved changes on the page, are you sure you want to leave?" })));
    }, [
        requiresSelectedMultiSelectItemIfNone,
        archiveLineItemGroupErrorMessage,
        currentLineItemGroupEntity,
        getLineItemFromLineItemId,
        handleMultiChoiceItemDeselect,
        initialFormState,
        isArchivingLineItemGroup,
        isArchiveLineItemGroupSuccessful,
        isFetchingLineItemGroup,
        isUpdateLineItemGroupSuccessful,
        isUpdatingLineItemGroup,
        onSubmit,
        showItemSearch,
        updateLineItemGroupErrorMessage,
    ]);
    var lineItemGroupTotals = useMemo(function () {
        if (!lineItemGroup)
            return;
        return calculateLineItemGroupTotals(lineItemGroup, lineItems);
    }, [lineItemGroup, lineItems]);
    var sidebarContent = useMemo(function () {
        var _a;
        return (React.createElement("div", { className: "sidebar-content" },
            React.createElement(LabelValuePair, { label: "Items added", value: ((_a = formState === null || formState === void 0 ? void 0 : formState.lineItemRelations) === null || _a === void 0 ? void 0 : _a.length) || 0, suffixLabelWith: '' }),
            lineItemGroupTotals && (React.createElement(React.Fragment, null,
                React.createElement("hr", null),
                React.createElement(LabelValuePair, { label: "Total (inc vat)", value: currencyPrice(lineItemGroupTotals.total), suffixLabelWith: '' }),
                React.createElement(LabelValuePair, { label: "Minimum total (inc vat)", value: currencyPrice(lineItemGroupTotals.minTotal), suffixLabelWith: '' }),
                React.createElement(LabelValuePair, { label: "Maximum total (inc vat)", value: currencyPrice(lineItemGroupTotals.maxTotal), suffixLabelWith: '' }))),
            React.createElement("hr", null),
            React.createElement("div", { className: "buttons-container" },
                (archiveLineItemGroupErrorMessage ||
                    updateLineItemGroupErrorMessage) && (React.createElement(ErrorMessage, { message: "\n            Sorry, there was a problem\n            " + (archiveLineItemGroupErrorMessage ? 'deleting' : 'saving') + " your item\n            group. Please try again." })),
                React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, isDisabled: isSaveDisabled, onClick: function () { return !isUpdatingLineItemGroup && onSubmit(formState); }, isProcessing: isUpdatingLineItemGroup }, "Save"),
                React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, isDisabled: isFetchingLineItemGroup, onClick: function () { return setShowDeleteGroupModal(true); }, isProcessing: isArchivingLineItemGroup }, "Delete"))));
    }, [
        formState,
        lineItemGroupTotals,
        isSaveDisabled,
        isUpdatingLineItemGroup,
        isFetchingLineItemGroup,
        isArchivingLineItemGroup,
        archiveLineItemGroupErrorMessage,
        updateLineItemGroupErrorMessage,
    ]);
    return (React.createElement(ResponsiveViewWrapper, { className: "create-edit-line-item-group-control", downBreakpointSm: 850 },
        React.createElement(BodyWithSidePanelContentWrapper, { title: lineItemGroupId ? 'Edit item group' : 'Create new item group', sidebarContent: sidebarContent },
            React.createElement(ContentPanel, null,
                React.createElement(ValidatedForm, { fieldValidators: fieldValidators, initialFormState: initialFormState, renderFormContents: renderFormContents }))),
        React.createElement(ConfirmModal, __assign({}, getModal('DELETE_ITEM_GROUP'), { onClose: function () {
                setShowDeleteGroupModal(false);
            }, secondaryAction: function () {
                setShowDeleteGroupModal(false);
            }, primaryAction: function () {
                archiveLineItemGroup();
                setShowDeleteGroupModal(false);
            }, open: showDeleteGroupModal }))));
};
export default CreateEditLineItemControl;
