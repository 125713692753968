import React, { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@payaca/components';
import { StringUtil } from '@/utils';
import { actions as usersActions } from '@/api/users';
import './SubsidisedFinanceInformationOverviewPage.css';
import { ContentPanel } from '@payaca/components/contentPanel';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
var applyNowGuidance = (React.createElement("div", null,
    React.createElement("h3", null, "Apply to offer 0% finance over 12 months"),
    React.createElement("p", null,
        React.createElement("strong", null, "Before starting the application, please confirm you have:")),
    React.createElement("ul", null,
        React.createElement("li", null, "1 year of accounts filed at companies house or 1 year tax returns for self employed."),
        React.createElement("li", null, "No CCJ\u2019s, Bankruptcies, IVA\u2019s personal or on the business"),
        React.createElement("li", null, "A business based in the UK (excluding N.Ireland)"),
        React.createElement("li", null,
            "Registered with the Information Commissioner's Office (ICO) - it's quick to do here:",
            ' ',
            React.createElement("a", { href: "https://ico.org.uk/registration/new", target: "_blank", rel: "noopener noreferrer" }, "https://ico.org.uk/registration/new"))),
    React.createElement("p", null, "You will need a Professional or Premium subscription to activate this but you can upgrade on approval if required."),
    React.createElement("h3", null, "The process"),
    React.createElement("ol", null,
        React.createElement("li", null, "Complete the application form."),
        React.createElement("li", null, "We'll notify you once approved."),
        React.createElement("li", null, "If approved and once you have a Professional or Premium subscription we will turn on the feature and you can start offering 0% finance on quotes."))));
var SubsidisedFinanceInformationOverviewPage = function () {
    var history = useHistory();
    var dispatch = useDispatch();
    useEffect(function () {
        dispatch(usersActions.getProfile());
    }, []);
    var zeroPercentFinanceIntegration = useSelector(function (state) { var _a; return (_a = state.users.myProfile.accounts[0].integrations) === null || _a === void 0 ? void 0 : _a.zeroPercentFinance; });
    var hasApplication = useMemo(function () { return zeroPercentFinanceIntegration.hasApplication; }, [zeroPercentFinanceIntegration]);
    var applicationSubmitted = useMemo(function () { return !!zeroPercentFinanceIntegration.submittedAt; }, [zeroPercentFinanceIntegration]);
    var applicationInProgress = useMemo(function () { return hasApplication && !applicationSubmitted; }, [applicationSubmitted, hasApplication]);
    var renderGoToFormButton = useMemo(function () {
        return (React.createElement(Button, { onClick: function () { return history.push('/subsidisedFinanceInformation/form'); } }, applicationInProgress ? "Continue application" : "Start application"));
    }, [applicationInProgress]);
    var renderApplicationStatus = useMemo(function () {
        var status = '';
        if (!!zeroPercentFinanceIntegration.isApproved) {
            status = "You have been approved to provide 0% finance";
        }
        else if (!!zeroPercentFinanceIntegration.deniedAt) {
            status = "Unfortunately your application to provide 0% finance was denied on the " + StringUtil.readableDate(zeroPercentFinanceIntegration.deniedAt, false);
        }
        else if (applicationSubmitted) {
            status = "You have submitted your application to provide 0% finance";
        }
        return (React.createElement("div", { className: "application-status-container" },
            React.createElement("h4", { className: "application-status" }, status)));
    }, [applicationSubmitted, zeroPercentFinanceIntegration]);
    return (React.createElement(AuthenticatedPageWrapper, { className: "subsidised-finance-information-overview-page", title: "0% Finance Application" },
        React.createElement("div", { className: "subsidised-finance-information-overview-page-content" },
            React.createElement(ContentPanel, null,
                React.createElement(React.Fragment, null,
                    applicationSubmitted && renderApplicationStatus,
                    applicationInProgress && (React.createElement("div", { className: "button-container" }, renderGoToFormButton)),
                    applyNowGuidance,
                    !hasApplication && (React.createElement("div", { className: "button-container" }, renderGoToFormButton)))))));
};
export default SubsidisedFinanceInformationOverviewPage;
