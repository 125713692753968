import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from '@payaca/components/modal';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { isQuoteOrEstimate } from '@bit/payaca-tech.payaca-core.helpers.job-status';
import { actions as jobsStoreActions } from '@bit/payaca-tech.payaca-core.store.jobs';
import './EditJobModal.css';
var EditJobModal = function (_a) {
    var isOpen = _a.isOpen, jobId = _a.jobId, dealId = _a.dealId, onClose = _a.onClose;
    var dispatch = useDispatch();
    var history = useHistory();
    var isEditingJob = useSelector(function (state) { return state.jobsStore.isEditingJob; });
    var editJobSuccess = useSelector(function (state) { return state.jobsStore.editJobSuccess; });
    var editJobErrorMessage = useSelector(function (state) { return state.jobsStore.editJobErrorMessage; });
    var editJob = useCallback(function () {
        dispatch(jobsStoreActions.requestEditJob(jobId));
    }, [dispatch, jobId]);
    useEffect(function () {
        // new job id returned from request - navigate to new job page
        if (editJobSuccess) {
            onClose();
            var jobTypePath = isQuoteOrEstimate(editJobSuccess.status)
                ? 'quotes'
                : 'invoices';
            history.replace({
                pathname: "/" + jobTypePath + "/" + editJobSuccess.id,
                search: dealId ? "?dealId=" + dealId : '',
            });
        }
    }, [history, editJobSuccess]);
    useEffect(function () {
        return function () {
            dispatch(jobsStoreActions.clearEditJob());
        };
    }, []);
    return (React.createElement(Modal, { isOpen: isOpen, title: "Edit job", size: "sm", onClose: onClose, className: "edit-job-modal", actions: React.createElement(React.Fragment, null,
            React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, onClick: editJob, isProcessing: isEditingJob }, "Yes, edit job"),
            React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: onClose }, "No, cancel")) },
        React.createElement("p", null, "If you edit this job, your customer will no longer be able to access it using the link that has been sent to them. When you send the job again, the customer will receive a new access link. Are you sure you want to edit this job?"),
        editJobErrorMessage && (React.createElement("div", { className: "error" }, "Sorry, there was an error editing your job. Please try again."))));
};
export default EditJobModal;
