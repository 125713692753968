import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ProductFormSubsection from '../productFormSubsection/ProductFormSubsection';
import './ProductsFormSubsection.css';
var ProductsFormSubSection = function (_a) {
    var products = _a.products, fieldNamePrefix = _a.fieldNamePrefix, touchedState = _a.touchedState, validationState = _a.validationState, onChange = _a.onChange, onTouch = _a.onTouch, onProductAdd = _a.onProductAdd, onProductRemove = _a.onProductRemove;
    var renderProductFormSubsection = useCallback(function (product, index) {
        return (React.createElement(ProductFormSubsection, { key: index, title: "Product " + (index + 1), fieldNamePrefix: fieldNamePrefix + "[" + index + "]", product: product, onChange: onChange, onTouch: onTouch, touchedState: touchedState, validationState: validationState, onRemove: products.length <= 1 ? undefined : function () { return onProductRemove(index); } }));
    }, [
        fieldNamePrefix,
        touchedState,
        validationState,
        onChange,
        onTouch,
        onProductRemove,
    ]);
    return (React.createElement("div", { className: "products-form-subsection" },
        products && products.map(renderProductFormSubsection),
        (products === null || products === void 0 ? void 0 : products.length) < 3 && (React.createElement("div", { className: "add-button", onClick: onProductAdd },
            React.createElement(FontAwesomeIcon, { icon: faPlus }),
            "Add product"))));
};
export default ProductsFormSubSection;
