import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { BasicField } from '@payaca/components/basicField';
import { useDispatch } from 'react-redux';
import './LoqateAdvisoryEmailInputField.sass';
import { actions as usersActions } from '@/api/users';
import { faCheck, faCircleNotch, faTimes, } from '@fortawesome/free-solid-svg-icons';
var LoqateAdvisoryEmailInputField = function (_a) {
    var styleVariant = _a.styleVariant, name = _a.name, value = _a.value, label = _a.label, description = _a.description, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, _c = _a.isRequired, isRequired = _c === void 0 ? false : _c, iconBefore = _a.iconBefore, _d = _a.additionalInputProps, additionalInputProps = _d === void 0 ? {} : _d, onChange = _a.onChange, onTouch = _a.onTouch, _e = _a.disableValidation, disableValidation = _e === void 0 ? false : _e;
    var dispatch = useDispatch();
    var _f = useState(), loqateValidationTimeout = _f[0], setLoqateValidationTimeout = _f[1];
    var _g = useState(false), isValidating = _g[0], setIsValidating = _g[1];
    var _h = useState(), isValid = _h[0], setIsValid = _h[1];
    useEffect(function () {
        return function () {
            setLoqateValidationTimeout(function (loqateValidationTimeout) {
                loqateValidationTimeout && clearTimeout(loqateValidationTimeout);
                return undefined;
            });
        };
    }, []);
    var runLoqateValidation = useCallback(function (value) {
        if (!value || value === '') {
            setIsValid(undefined);
            return;
        }
        setIsValidating(true);
        dispatch(usersActions.validateEmail(value, function (error, response) {
            setIsValidating(false);
            if (error) {
                setIsValid(undefined);
            }
            else {
                setIsValid(response);
            }
        }));
    }, [dispatch]);
    var iconAfter = useMemo(function () {
        if (isValidating)
            return faCircleNotch;
        if (isValid === true)
            return faCheck;
        if (isValid === false)
            return faTimes;
    }, [isValidating, isValid]);
    var validationResult = useMemo(function () {
        if (isValidating || isValid === undefined)
            return;
        return {
            isValid: isValid,
            errors: isValid
                ? undefined
                : [
                    'This email looks invalid to us, please double check before continuing',
                ],
        };
    }, [isValidating, isValid]);
    useEffect(function () {
        if (!disableValidation) {
            setLoqateValidationTimeout(function (loqateValidationTimeout) {
                loqateValidationTimeout && clearTimeout(loqateValidationTimeout);
                return setTimeout(function () {
                    runLoqateValidation(value);
                }, 500);
            });
        }
    }, [value, runLoqateValidation, disableValidation]);
    useEffect(function () {
        if (disableValidation) {
            setIsValidating(false);
            setIsValid(undefined);
            setLoqateValidationTimeout(function (loqateValidationTimeout) {
                loqateValidationTimeout && clearTimeout(loqateValidationTimeout);
                return undefined;
            });
        }
    }, [disableValidation]);
    return (React.createElement("div", { className: "loqate-advisory-email-input-field" },
        React.createElement(ValidatedFieldWrapper, { isTouched: value && value !== '', validationResult: validationResult },
            React.createElement(BasicField, { styleVariant: styleVariant, name: name, value: value, label: label, description: description, isDisabled: isDisabled, isRequired: isRequired, iconBefore: iconBefore, additionalInputProps: additionalInputProps, onChange: onChange, onTouch: onTouch, iconAfter: iconAfter }))));
};
export default LoqateAdvisoryEmailInputField;
