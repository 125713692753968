import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash.get';
import { Button } from '@payaca/components';
import SearchBar from './SearchBar';
import { StringUtil } from '@/utils';
import { QuickActions } from '@/helpers/tableHelper';
import { getModal } from '@/helpers/modalHelper';
import { actions as appActions } from '@/api/app';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
var TableHeader = function (_a) {
    var addButton = _a.addButton, bulkActions = _a.bulkActions, inputValue = _a.inputValue, onSearch = _a.onSearch, selected = _a.selected, tableConfig = _a.tableConfig, myProfile = _a.myProfile, setSelected = _a.setSelected;
    var dispatch = useDispatch();
    var renderSearch = useCallback(function () {
        var placeholder = get(tableConfig, ['search', 'searchPlaceholder']);
        return (React.createElement(SearchBar, { inputValue: inputValue, onSearch: function (value) { return onSearch(value); }, placeholder: placeholder }));
    }, [inputValue, tableConfig]);
    var renderAddButton = useCallback(function () {
        return (React.createElement(Link, { to: addButton.navTo },
            React.createElement(Button, { onClick: addButton.onClick, iconAfter: faPlus }, addButton.title)));
    }, [addButton]);
    var renderMultiSelectActions = useCallback(function () {
        var _a;
        var ModalActionRequired = (_a = {},
            _a[QuickActions.DELETE] = {
                modalName: 'TABLE_DELETE',
                modalTitle: function (_a) {
                    var s = _a[0], p = _a[1];
                    return (selected.length === 1 ? s : p);
                },
                modalText: function (_a) {
                    var s = _a[0], p = _a[1];
                    return StringUtil.singularPlural(selected.length, s, p, 'this');
                },
            },
            _a);
        var bulkActionsArr = [];
        // get actions allowed by role
        var allowedActions = tableConfig.bulkActions
            ? tableConfig.bulkActions(myProfile)
            : {};
        bulkActionsArr = Object.entries(allowedActions).reduce(function (acc, _a) {
            var key = _a[0], title = _a[1];
            //filter out any actions without a relating function
            var func = bulkActions && bulkActions[key];
            if (!func) {
                return acc;
            }
            var performAction = function () {
                func(selected.map(function (s) { return s.id; })); // pass selected jobIds to action
                setSelected([]); // clear selected jobs
            };
            return acc.concat({
                // @ts-ignore
                title: title,
                onClick: function () {
                    var modalAction = ModalActionRequired[key];
                    // if modal action required to perform bulk action
                    if (modalAction) {
                        dispatch(appActions.showModal(getModal(modalAction.modalName, {
                            title: [modalAction.modalTitle(tableConfig.modalReference)],
                            text: [modalAction.modalText(tableConfig.modalReference)],
                            primaryAction: function () {
                                performAction();
                                dispatch(appActions.hideModal());
                            },
                            secondaryAction: function () { return dispatch(appActions.hideModal()); },
                            onClose: function () { return dispatch(appActions.hideModal()); },
                        })));
                    }
                    else {
                        performAction();
                    }
                },
            });
        }, []);
        return (bulkActionsArr &&
            bulkActionsArr.map(function (action, i) {
                return (React.createElement(Button, { key: i, onClick: action.onClick, isDisabled: !selected.length }, action.title));
            }));
    }, [bulkActions, myProfile, selected, setSelected, tableConfig]);
    return !selected.length ? (React.createElement("div", { className: "table-management-header" },
        tableConfig.search && renderSearch(),
        addButton && renderAddButton())) : (React.createElement("div", { className: "table-management-header bulk-actions" }, renderMultiSelectActions()));
};
export default TableHeader;
