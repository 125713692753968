import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './Modal.sass';
var Modal = function (_a) {
    var children = _a.children, className = _a.className, isOpen = _a.isOpen, _b = _a.showCloseControl, showCloseControl = _b === void 0 ? true : _b, actions = _a.actions, size = _a.size, title = _a.title, onClose = _a.onClose;
    return (React.createElement(Dialog, { open: isOpen, onClose: onClose, fullWidth: true, maxWidth: size || 'sm', classes: {
            root: "modal " + (className ? className : ''),
        } },
        showCloseControl && (React.createElement("div", { className: "close-control", onClick: onClose },
            React.createElement(FontAwesomeIcon, { icon: faTimes }))),
        React.createElement(DialogContent, null,
            title && (React.createElement("div", { className: "title-container" }, typeof title === 'string' ? React.createElement("h3", null, title) : { title: title })),
            React.createElement("div", { className: "body-content" }, children),
            actions && React.createElement("div", { className: "actions-container" }, actions))));
};
export default Modal;
