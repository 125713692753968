import { action } from 'typesafe-actions';
import { ActionType } from './fileDownloadTypes';
export var requestDownloadFile = function (entityId, type, callback) {
    return action(ActionType.REQUEST_FILE_DOWNLOAD, {
        entityId: entityId,
        type: type,
        callback: callback
    });
};
export var fileDownloadSuccess = function (entityId, type, value) {
    return action(ActionType.FILE_DOWNLOAD_SUCCESS, {
        entityId: entityId,
        type: type,
        value: value
    });
};
export var fileDownloadFailure = function (entityId, type, error) {
    return action(ActionType.FILE_DOWNLOAD_FAILURE, { entityId: entityId, type: type }, null, error);
};
export var clearFileDownloads = function () {
    return action(ActionType.CLEAR_FILE_DOWNLOADS);
};
