var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FileDownloadTypes } from '@payaca/types/fileDownloadTypes';
import { Button } from '@payaca/components/button';
import { CollapsiblePanel, JobAttachments, JobOverview as JobOverviewBody, JobOverviewBusinessDetails, JobOverviewFooter, JobOverviewHeader, } from '@payaca/components';
import { isQuoteOrEstimate } from '@payaca/helpers/jobStatusHelper';
import { getMinimumMonthlyCost } from '@payaca/helpers/jobFinanceHelper';
import { shouldShowFinanceOptionsToCustomer } from '@payaca/helpers/jobHelperV2';
import { hasSubmittedFinanceApplication } from '@payaca/helpers/jobFinanceApplicationHelper';
import { getJob, getJobAttachmentsByJobId, getJobDownloadByJobId, getJobLineItemAttachmentsByJobId, getJobLineItemGroupsByJobId, getJobLineItemsByJobId, getJobPaymentsByJobId, getActiveJobFinanceInformationByJobId, } from '@/utils/stateAccessors';
import './JobOverview.sass';
var JobOverview = function (_a) {
    var jobId = _a.jobId;
    var businessAttachments = useSelector(function (state) { return state.users.businessAttachments; });
    var job = useSelector(function (state) {
        return getJob(state, jobId);
    });
    var account = useSelector(function (state) { var _a, _b; return (_b = (_a = state.users) === null || _a === void 0 ? void 0 : _a.myProfile) === null || _b === void 0 ? void 0 : _b.accounts[0]; });
    var customer = useSelector(function (state) {
        return state.customer.currentCustomer;
    });
    var jobAttachments = useSelector(function (state) {
        return getJobAttachmentsByJobId(state, jobId);
    });
    var jobLineItemGroups = useSelector(function (state) {
        return getJobLineItemGroupsByJobId(state, jobId);
    });
    var jobLineItems = useSelector(function (state) {
        return getJobLineItemsByJobId(state, jobId);
    });
    var jobLineItemAttachments = useSelector(function (state) {
        return getJobLineItemAttachmentsByJobId(state, jobId);
    });
    var jobPayments = useSelector(function (state) {
        return getJobPaymentsByJobId(state, jobId);
    });
    var accountAccessInformation = useSelector(function (state) {
        return state.account.accountAccessInformation;
    });
    var activeJobFinanceInformation = useSelector(function (state) {
        return getActiveJobFinanceInformationByJobId(state, jobId);
    });
    var financePlans = useSelector(function (state) { return state.users.financePlans; });
    var jobIsQuoteOrEstimate = useMemo(function () { return job && isQuoteOrEstimate(job.status); }, [job]);
    var acceptedSignatureImage = useSelector(function (state) {
        return getJobDownloadByJobId(state, jobId, FileDownloadTypes.QUOTE_SIGNATURE_IMAGE);
    });
    var satisfactionNoteSignatureImage = useSelector(function (state) {
        return getJobDownloadByJobId(state, jobId, FileDownloadTypes.SATISFACTION_NOTE_SIGNATURE);
    });
    var zeroPercentFinanceEnabled = useMemo(function () {
        var _a, _b, _c;
        if (!job)
            return false;
        // enabled on job, account is approved and subscription allows
        var jobHasZeroPercentEnabled = job.showBespokeFinancePlans;
        var accountIsZeroPercentApproved = !!(account && ((_b = (_a = account.integrations) === null || _a === void 0 ? void 0 : _a.zeroPercentFinance) === null || _b === void 0 ? void 0 : _b.isApproved));
        var accountHasSubscriptionFeature = !!((_c = accountAccessInformation === null || accountAccessInformation === void 0 ? void 0 : accountAccessInformation.features) === null || _c === void 0 ? void 0 : _c.hasZeroPercentFinance);
        return (jobIsQuoteOrEstimate &&
            jobHasZeroPercentEnabled &&
            accountIsZeroPercentApproved &&
            accountHasSubscriptionFeature);
    }, [job, account, accountAccessInformation, jobIsQuoteOrEstimate]);
    var minimumMonthlyPayment = useMemo(function () {
        if (job && (financePlans === null || financePlans === void 0 ? void 0 : financePlans.length)) {
            var minimumMonthlyCosts = financePlans
                .map(function (financePlanConfig) {
                return getMinimumMonthlyCost(job.total, financePlanConfig.maxDeposit, financePlanConfig.minAmount, financePlanConfig.interestRate, financePlanConfig.agreementDuration);
            })
                .sort(function (a, b) { return a - b; });
            // lowest monthly cost from finance plans
            return "\u00A3" + Math.ceil(minimumMonthlyCosts[0] / 100);
        }
        return null;
    }, [financePlans, job]);
    var financePaymentTitle = useMemo(function () {
        if (!financePlans) {
            return '';
        }
        return "" + (financePlans.length > 1 ? 'Finance' : '0% finance') + (minimumMonthlyPayment ? " from " + minimumMonthlyPayment + " per month" : '');
    }, [financePlans, minimumMonthlyPayment]);
    var standardAndFinancePaymentMethodsElement = useMemo(function () {
        return (React.createElement("div", { className: "standard-and-finance-panels-wrapper" },
            React.createElement(CollapsiblePanel, { title: "Standard payment", isOpen: false, onOpen: function () { return null; }, onClose: function () { return null; } }),
            React.createElement(CollapsiblePanel, { title: financePaymentTitle, isOpen: false, onOpen: function () { return null; }, onClose: function () { return null; } })));
    }, [financePaymentTitle]);
    var showFinanceOptions = useMemo(function () {
        if (!job)
            return false;
        return shouldShowFinanceOptionsToCustomer(job, jobPayments || [], activeJobFinanceInformation);
    }, [job, jobPayments, activeJobFinanceInformation]);
    var financeOptionsOverlayTriggerElement = useMemo(function () {
        if (!showFinanceOptions)
            return null;
        return (React.createElement("div", null,
            React.createElement(Button, null, "Finance available")));
    }, [showFinanceOptions]);
    var callToAction = useMemo(function () {
        var cta = undefined;
        if (zeroPercentFinanceEnabled &&
            !hasSubmittedFinanceApplication(activeJobFinanceInformation)) {
            cta = standardAndFinancePaymentMethodsElement;
            // before send, after send
        }
        else if (showFinanceOptions) {
            cta = financeOptionsOverlayTriggerElement;
        }
        else {
            return;
        }
        return React.createElement("div", { className: "cta-container" }, cta);
    }, [
        standardAndFinancePaymentMethodsElement,
        activeJobFinanceInformation,
        zeroPercentFinanceEnabled,
        showFinanceOptions,
        financeOptionsOverlayTriggerElement,
    ]);
    var jobAttachmentsElement = useMemo(function () {
        var attachments = [];
        if (jobAttachments && jobAttachments.length) {
            attachments = __spreadArrays(jobAttachments);
        }
        if (businessAttachments && businessAttachments.length) {
            attachments = __spreadArrays(attachments, businessAttachments);
        }
        if (attachments.length) {
            return React.createElement(JobAttachments, { jobAttachments: attachments });
        }
    }, [businessAttachments, jobAttachments]);
    var jobOverviewBusinessDetails = useMemo(function () {
        if (!account || !job)
            return;
        return React.createElement(JobOverviewBusinessDetails, { account: account, job: job });
    }, [account, job]);
    var jobOverviewFooter = useMemo(function () {
        if (!account)
            return;
        return React.createElement(JobOverviewFooter, { account: account });
    }, [account]);
    var jobOverviewHeader = useMemo(function () {
        if (!job || !account || !customer)
            return;
        return (React.createElement(JobOverviewHeader, { account: account, job: job, customer: customer, jobFinanceInformation: activeJobFinanceInformation }));
    }, [account, job, customer, activeJobFinanceInformation]);
    var jobOverviewBody = useMemo(function () {
        if (!job || !account || !customer)
            return;
        return (React.createElement(JobOverviewBody, { job: job, account: account, customer: customer, jobLineItems: jobLineItems || [], jobLineItemGroups: jobLineItemGroups || [], jobLineItemAttachments: jobLineItemAttachments || [], jobPayments: jobPayments || [], onUpdate: function () { return null; }, isEditable: false, acceptedSignatureImageData: acceptedSignatureImage, satisfactionNoteSignatureImageData: satisfactionNoteSignatureImage, jobFinanceInformation: activeJobFinanceInformation, callToAction: callToAction }));
    }, [
        job,
        account,
        customer,
        jobLineItems,
        jobLineItemAttachments,
        jobLineItemGroups,
        jobPayments,
        acceptedSignatureImage,
        satisfactionNoteSignatureImage,
        activeJobFinanceInformation,
        callToAction,
    ]);
    return (React.createElement("div", { className: "job-overview-wrapper" },
        jobOverviewHeader,
        jobOverviewBody,
        jobOverviewBusinessDetails,
        jobAttachmentsElement,
        jobOverviewFooter));
};
export default JobOverview;
