import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import qs from 'qs';
import { Login, StatusError } from '@/ui/components';
import { actions as appActions } from '@/api/app';
import { actions as authActions } from '@bit/payaca-tech.payaca-core.store.auth';
import BasicPageWrapper from '../pageWrappers/basicPageWrapper/BasicPageWrapper';
import MiniLoader from '@bit/payaca-tech.payaca-core.component.mini-loader';
import './LoginOAuthPage.sass';
var PageState;
(function (PageState) {
    PageState["LOADING"] = "loading";
    PageState["ERROR"] = "error";
    PageState["LOGIN"] = "login";
})(PageState || (PageState = {}));
var LoginOAuthPage = function (_a) {
    var location = _a.location, history = _a.history, isAuthed = _a.isAuthed;
    var dispatch = useDispatch();
    var _b = useState(false), verifiedOAuth = _b[0], setVerifiedOAuth = _b[1];
    var _c = useState(null), verifyError = _c[0], setVerifyError = _c[1];
    useEffect(function () {
        var query = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });
        // store pathname for reference in grant access page if logging out
        dispatch(appActions.storePreviousPathname("" + location.pathname + location.search));
        var payload = {
            //@ts-ignore
            redirectUri: decodeURIComponent(query.redirect_uri),
            clientId: query.client_id,
            responseType: query.response_type,
            state: query.state,
            scope: query.scope,
        };
        // verify oauth query before proceeding to grantAccess page
        // or error if verification fails
        dispatch(authActions.verifyOAuth(payload, function (err, resp) {
            if (resp) {
                var grantAccessPath = "grantAccess?" + resp.grantAccessPath;
                setVerifiedOAuth(true);
                if (isAuthed) {
                    history.push(grantAccessPath);
                }
                else {
                    dispatch(appActions.storePathname(grantAccessPath));
                }
            }
            else {
                setVerifyError(err);
            }
        }));
    }, []);
    var pageState = useMemo(function () {
        if (!verifyError && (!verifiedOAuth || (verifiedOAuth && isAuthed))) {
            return PageState.LOADING;
        }
        else if (verifyError) {
            return PageState.ERROR;
        }
        else {
            return PageState.LOGIN;
        }
    }, [verifyError, verifiedOAuth, isAuthed]);
    return (React.createElement(BasicPageWrapper, { className: "login-oauth-page" },
        pageState === PageState.LOADING && React.createElement(MiniLoader, null),
        pageState === PageState.ERROR && (React.createElement(StatusError, { title: "Authorisation Error", status: (verifyError === null || verifyError === void 0 ? void 0 : verifyError.status) || undefined, errorName: (verifyError === null || verifyError === void 0 ? void 0 : verifyError.error) || '', errorDescriptionMessage: (verifyError === null || verifyError === void 0 ? void 0 : verifyError.error_description) || '' })),
        pageState === PageState.LOGIN && React.createElement(Login, null)));
};
export default LoginOAuthPage;
