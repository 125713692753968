import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export default class WalkthroughStep extends Component {
  renderDescription() {
    return this.props.description.map((descriptionItem, i) => {
      return (
        <div className="walkthrough-description-item" key={i}>
          <FontAwesomeIcon icon={faCheck} className="check-icon" />
          <label>{descriptionItem}</label>
        </div>
      );
    });
  }
  render() {
    const Container = ({ children }) => {
      const wrapperClass = `walkthrough-step${
        this.props.reversed ? ' reverse' : ''
      }`;
      if (this.props.link) {
        return (
          <a
            className={wrapperClass}
            href={this.props.link}
            target="_blank"
            rel="noreferrer"
          >
            {children}
          </a>
        );
      }
      return <div className={wrapperClass}>{children}</div>;
    };

    return (
      <Container>
        <div className="walkthrough-text-wrapper">
          <label className="walkthrough-title">{this.props.title}</label>
          <div className="walkthrough-description">
            {this.renderDescription()}
          </div>
        </div>
        <img
          className="walkthrough-image"
          src={this.props.img}
          alt={this.props.imgAlt || this.props.title}
        />
      </Container>
    );
  }
}
