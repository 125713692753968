import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import './JobPreviewUpdateReminders.sass';
import { getUpdateReminders } from '@/helpers/jobHelper';
import { Button } from '@payaca/components/button';
import { BusinessSettingsModal } from '..';
var JobPreviewUpdateReminders = function () {
    var _a = useState(false), showBusinessSettingsModal = _a[0], setShowBusinessSettingsModal = _a[1];
    var account = useSelector(function (state) { return state.users.myProfile.accounts[0]; });
    var termsOfBusiness = useSelector(function (state) { return state.users.terms; });
    var reminders = useMemo(function () { return getUpdateReminders(account, termsOfBusiness); }, [account, termsOfBusiness]);
    return reminders.length ? (React.createElement("div", { className: "job-preview-update-reminder" },
        React.createElement("p", null, "Before sending this you can add:"),
        React.createElement("ul", null, reminders.map(function (reminder, i) { return (React.createElement("li", { key: i }, reminder)); })),
        React.createElement(Button, { onClick: function () { return setShowBusinessSettingsModal(true); } }, "Update now"),
        React.createElement(BusinessSettingsModal, { open: showBusinessSettingsModal, onClose: function () { return setShowBusinessSettingsModal(false); } }))) : null;
};
export default JobPreviewUpdateReminders;
