import React from 'react';
import JobLineItemAttachmentsControl from '../jobLineItemAttachmentsControl/JobLineItemAttachmentsControl';
import { Modal } from '@payaca/components/modal';
var JobLineItemAttachmentsModal = function (_a) {
    var _b = _a.updateLineItemAttachment, updateLineItemAttachment = _b === void 0 ? false : _b, isOpen = _a.isOpen, jobLineItemId = _a.jobLineItemId, onClose = _a.onClose;
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { isOpen: isOpen, title: 'Attached image', onClose: onClose, className: 'job-line-item-attachments-modal', size: "xs" },
            React.createElement(JobLineItemAttachmentsControl, { jobLineItemId: jobLineItemId, updateLineItemAttachment: updateLineItemAttachment }))));
};
export default JobLineItemAttachmentsModal;
