import React from 'react';
import './UserSeatsExceededWarning.css';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
var UserSeatsExceededWarning = function (_a) {
    var selectedUserSeats = _a.selectedUserSeats, bonusAdditionalUserSeats = _a.bonusAdditionalUserSeats, activeUsersCount = _a.activeUsersCount;
    var history = useHistory();
    return (React.createElement("div", { className: "user-seats-exceeded-warning" },
        React.createElement("p", null,
            "You are trying to switch to a subscription plan with",
            ' ',
            React.createElement("strong", null,
                selectedUserSeats,
                " user seats",
                ' ',
                bonusAdditionalUserSeats
                    ? "(plus " + bonusAdditionalUserSeats + " bonus extra seats)"
                    : ''),
            ' ',
            "but there are currently ",
            React.createElement("strong", null,
                activeUsersCount,
                " users"),
            ' ',
            "associated with your account."),
        React.createElement("p", null, "To resolve this, please either:"),
        React.createElement("ul", null,
            React.createElement("li", null, "Add additional user seats to your plan to accommodate all current users"),
            React.createElement("li", null,
                "Delete some of the users associated with your account:",
                ' ',
                React.createElement("a", { onClick: function () {
                        history.push('/users');
                    } },
                    "click here ",
                    React.createElement(FontAwesomeIcon, { icon: faAngleRight }))))));
};
export default UserSeatsExceededWarning;
