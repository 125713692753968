export var getPrimaryContactFromCustomer = function (customer) {
    var _a;
    if (!((_a = customer === null || customer === void 0 ? void 0 : customer.contacts) === null || _a === void 0 ? void 0 : _a.length))
        return null;
    var primaryContact = customer.contacts.find(function (x) { return x.isPrimaryContact; });
    if (!primaryContact) {
        primaryContact = customer.contacts[0];
    }
    return primaryContact;
};
/**
 * Get contact associated with job, if no jobContactId primary contact is used
 * @param jobContactId
 * @param customer
 * @returns
 */
export var getJobContactFromCustomer = function (customer, jobContactId) {
    var _a;
    if (!((_a = customer === null || customer === void 0 ? void 0 : customer.contacts) === null || _a === void 0 ? void 0 : _a.length)) {
        return null;
    }
    var jobCustomer = null;
    if (jobContactId) {
        jobCustomer = customer.contacts.find(function (x) { return x.id === jobContactId; });
    }
    if (!jobCustomer) {
        jobCustomer = getPrimaryContactFromCustomer(customer);
    }
    return jobCustomer;
};
export var getPrimaryAddressFromCustomer = function (customer) {
    var _a;
    if (!((_a = customer === null || customer === void 0 ? void 0 : customer.addresses) === null || _a === void 0 ? void 0 : _a.length))
        return null;
    return customer.addresses[0];
};
export var getCustomerAddressAsString = function (address) {
    if (!address)
        return;
    var addressElements = [];
    addressElements.push(address.line1);
    addressElements.push(address.line2);
    addressElements.push(address.city);
    addressElements.push(address.postcode);
    return addressElements.filter(function (x) { return !!x; }).join(', ');
};
