import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions as userActions } from '@/api/users';
function useBlockedEmails() {
    var dispatch = useDispatch();
    var _a = useState([]), blockedEmails = _a[0], setBlockedEmails = _a[1];
    useEffect(function () {
        dispatch(userActions.getBlockedEmails(function (err, blockedEmails) {
            if (blockedEmails) {
                setBlockedEmails(blockedEmails);
            }
        }));
    }, []);
    return blockedEmails;
}
function usePrevious(value) {
    var ref = useRef();
    useEffect(function () {
        ref.current = value;
    });
    return ref.current;
}
export { useBlockedEmails, usePrevious };
