var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useState, } from 'react';
import { useDispatch } from 'react-redux';
import { actions as listedItemsActions } from '@bit/payaca-tech.payaca-core.store.listed-items';
import { SortBy } from '@bit/payaca-tech.payaca-core.types.listed-item';
import { SortDirection } from '@bit/payaca-tech.payaca-core.types.list-view';
import './SelectItemControl.sass';
import { BasicField } from '@payaca/components/basicField';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ListedItemsTable from '../listedItemsTable/ListedItemsTable';
import { ContentPanel } from '@payaca/components/contentPanel';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
var SelectItemControl = function (_a) {
    var onSelectItem = _a.onSelectItem, onSelectDisabledItem = _a.onSelectDisabledItem, disabledItemIds = _a.disabledItemIds;
    var dispatch = useDispatch();
    var _b = useState({
        pageSize: 100,
        pageNumber: 1,
        searchTerm: '',
        sortDirection: SortDirection.ASCENDING,
        sortBy: SortBy.NAME,
    }), getListedItemsRequestData = _b[0], setGetListedItemsRequestData = _b[1];
    var requestGetListedItemsPage = useCallback(function () {
        dispatch(listedItemsActions.requestGetListedItemsPage(getListedItemsRequestData));
    }, [getListedItemsRequestData, dispatch]);
    useEffect(function () {
        requestGetListedItemsPage();
    }, [getListedItemsRequestData]);
    var onSelectPage = useCallback(function (pageNumber) {
        setGetListedItemsRequestData(function (getListedItemsRequestData) {
            return __assign(__assign({}, getListedItemsRequestData), { pageNumber: pageNumber });
        });
    }, []);
    var _c = useState(), searchTermTimeout = _c[0], setSearchTermTimeout = _c[1];
    var onSearchTermChange = useCallback(function (searchTerm) {
        setGetListedItemsRequestData(function (getListedItemsRequestData) {
            return __assign(__assign({}, getListedItemsRequestData), { pageNumber: 1, searchTerm: searchTerm });
        });
    }, []);
    var onSearchTermType = useCallback(function (value) {
        searchTermTimeout && clearTimeout(searchTermTimeout);
        setSearchTermTimeout(setTimeout(function () {
            onSearchTermChange(value.searchTerm);
        }, 500));
    }, [searchTermTimeout, onSearchTermChange]);
    var onChangeSortOrder = useCallback(function (sortBy, sortDirection) {
        setGetListedItemsRequestData(function (getListedItemsRequestData) {
            return __assign(__assign({}, getListedItemsRequestData), { pageNumber: 1, sortBy: sortBy, sortDirection: sortDirection });
        });
    }, []);
    return (React.createElement("div", { className: "select-item-control" },
        React.createElement("div", { className: "search-term-field-container" },
            React.createElement(BasicField, { name: "searchTerm", onChange: onSearchTermType, iconAfter: faSearch, additionalInputProps: {
                    placeholder: 'Search',
                }, styleVariant: InputStyleVariant.OUTSIZE })),
        React.createElement(ContentPanel, null,
            React.createElement(ListedItemsTable, { includeCisColumn: false, includeSupplierPriceColumn: false, includeImportColumn: false, sortBy: getListedItemsRequestData.sortBy, sortDirection: getListedItemsRequestData.sortDirection, disabledItemIds: disabledItemIds, onClickRow: function (itemId) {
                    return disabledItemIds.includes(itemId)
                        ? onSelectDisabledItem(itemId)
                        : onSelectItem(itemId);
                }, onSelectPage: onSelectPage, onChangeSortOrder: onChangeSortOrder }))));
};
export default SelectItemControl;
