var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionType } from './jobsTypes';
export var initialState = {
    isResendingJob: false,
    isResentJobSuccessfully: undefined,
    resendJobErrorMessage: undefined,
    isMarkingJobAsSent: false,
    isJobMarkedAsSentSuccessfully: undefined,
    markJobAsSentErrorMessage: undefined,
    isEditingJob: false,
    editJobSuccess: undefined,
    editJobErrorMessage: undefined,
    isUpdatingJob: false,
    isUpdatingJobAttachment: false,
    isGettingJobValidationResult: false,
    isCreatingJobLineItemGroup: false,
    isUpdatingJobLineItemGroup: false,
    isDeletingJobLineItemGroup: false,
    isAddingLineItemGroupToJob: false,
    isRemovingAttachmentFromJobLineItem: false,
    isAddingAttachmentToJobLineItem: false,
    isCreatingJobLineItem: false,
    isUpdatingJobLineItem: false,
    isDeletingJobLineItem: false,
    jobs: {},
    jobAttachments: {},
    jobLineItemAttachments: {},
    jobLineItemGroups: {},
    jobLineItems: {},
    jobUpdateResults: {},
    jobLineItemGroupUpdateResults: {},
    jobLineItemUpdateResults: {},
};
var jobsReducer = function (state, action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionType.REQUEST_GET_JOB_VALIDATION_RESULT:
            return __assign(__assign({}, state), { isGettingJobValidationResult: true });
        case ActionType.GET_JOB_VALIDATION_RESULT_FAILURE:
        case ActionType.GET_JOB_VALIDATION_RESULT_SUCCESS:
            return __assign(__assign({}, state), { isGettingJobValidationResult: false });
        // RESEND JOB
        case ActionType.REQUEST_RESEND_JOB:
            return __assign(__assign({}, state), { isResendingJob: true, isResentJobSuccessfully: undefined, resendJobErrorMessage: undefined });
        case ActionType.RESEND_JOB_SUCCESS:
            return __assign(__assign({}, state), { isResendingJob: false, isResentJobSuccessfully: true, resendJobErrorMessage: undefined });
        case ActionType.RESEND_JOB_FAILURE:
            return __assign(__assign({}, state), { isResendingJob: false, isResentJobSuccessfully: false, resendJobErrorMessage: action.payload.errorMessage });
        case ActionType.CLEAR_RESEND_JOB:
            return __assign(__assign({}, state), { isResentJobSuccessfully: undefined, resendJobErrorMessage: undefined });
        // EDIT JOB
        case ActionType.REQUEST_EDIT_JOB:
            return __assign(__assign({}, state), { isEditingJob: true, editJobSuccess: undefined, editJobErrorMessage: undefined });
        case ActionType.EDIT_JOB_SUCCESS:
            return __assign(__assign({}, state), { isEditingJob: false, editJobSuccess: action.payload.newJob, editJobErrorMessage: undefined });
        case ActionType.EDIT_JOB_FAILURE:
            return __assign(__assign({}, state), { isEditingJob: false, editJobSuccess: undefined, editJobErrorMessage: action.payload.errorMessage });
        case ActionType.CLEAR_EDIT_JOB:
            return __assign(__assign({}, state), { isEditingJob: false, editJobSuccess: undefined, editJobErrorMessage: undefined });
        // MARK JOB AS SENT
        case ActionType.REQUEST_MARK_JOB_AS_SENT:
            return __assign(__assign({}, state), { isMarkingJobAsSent: true, isJobMarkedAsSentSuccessfully: undefined, markJobAsSentErrorMessage: undefined });
        case ActionType.MARK_JOB_AS_SENT_SUCCESS:
            return __assign(__assign({}, state), { isMarkingJobAsSent: false, isJobMarkedAsSentSuccessfully: true, markJobAsSentErrorMessage: undefined });
        case ActionType.MARK_JOB_AS_SENT_FAILURE:
            return __assign(__assign({}, state), { isMarkingJobAsSent: false, isJobMarkedAsSentSuccessfully: false, markJobAsSentErrorMessage: action.payload.errorMessage });
        case ActionType.CLEAR_MARK_JOB_AS_SENT:
            return __assign(__assign({}, state), { isJobMarkedAsSentSuccessfully: undefined, markJobAsSentErrorMessage: undefined });
        case ActionType.REQUEST_GET_JOB:
        case ActionType.REQUEST_GET_JOB_WITH_JOB_GROUPS_AND_JOB_LINE_ITEMS: {
            var fetchedEntity = state.jobs && state.jobs[action.payload.jobId];
            return __assign(__assign({}, state), { jobs: __assign(__assign({}, state.jobs), (_a = {}, _a[action.payload.jobId] = __assign(__assign({}, fetchedEntity), { fetchAttemptedAttemptedAt: new Date(), fetchSucceededAt: undefined, fetchFailedAt: undefined, isFetching: true, fetchError: undefined }), _a)) });
        }
        case ActionType.GET_JOB_SUCCESS: {
            var fetchedEntity = state.jobs && state.jobs[action.payload.jobId];
            return __assign(__assign({}, state), { jobs: __assign(__assign({}, state.jobs), (_b = {}, _b[action.payload.jobId] = __assign(__assign({}, fetchedEntity), { entity: action.payload.job, fetchSucceededAt: new Date(), isFetching: false }), _b)) });
        }
        case ActionType.GET_JOB_FAILURE: {
            var fetchedEntity = state.jobs && state.jobs[action.payload.jobId];
            return __assign(__assign({}, state), { jobs: __assign(__assign({}, state.jobs), (_c = {}, _c[action.payload.jobId] = __assign(__assign({}, fetchedEntity), { fetchFailedAtAt: new Date(), isFetching: false, fetchError: action.error }), _c)) });
        }
        case ActionType.CLEAR_JOB: {
            var jobs = state.jobs || {};
            delete jobs[action.payload.jobId];
            return __assign(__assign({}, state), { jobs: jobs });
        }
        case ActionType.CLEAR_JOBS: {
            return __assign(__assign({}, state), { jobs: {} });
        }
        // UPDATE JOB
        case ActionType.REQUEST_UPDATE_JOB:
            return __assign(__assign({}, state), { isUpdatingJob: true, jobUpdateResults: __assign(__assign({}, state.jobUpdateResults), (_d = {}, _d[action.payload.updateJobRequestData.jobId] = {
                    isUpdatedSuccessfully: undefined,
                    updateError: undefined,
                }, _d)) });
        case ActionType.UPDATE_JOB_SUCCESS:
            return __assign(__assign({}, state), { isUpdatingJob: false, jobUpdateResults: __assign(__assign({}, state.jobUpdateResults), (_e = {}, _e[action.payload.jobId] = {
                    isUpdatedSuccessfully: true,
                    updateError: undefined,
                }, _e)) });
        case ActionType.UPDATE_JOB_FAILURE:
            return __assign(__assign({}, state), { isUpdatingJob: false, jobUpdateResults: __assign(__assign({}, state.jobUpdateResults), (_f = {}, _f[action.payload.jobId] = {
                    isUpdatedSuccessfully: false,
                    updateError: action.error,
                }, _f)) });
        case ActionType.GET_JOB_ATTACHMENT_SUCCESS: {
            var fetchedEntity = state.jobAttachments &&
                state.jobAttachments[action.payload.jobAttachmentId];
            return __assign(__assign({}, state), { jobAttachments: __assign(__assign({}, state.jobAttachments), (_g = {}, _g[action.payload.jobAttachmentId] = __assign(__assign({}, fetchedEntity), { entity: action.payload.jobAttachment, fetchSucceededAt: new Date(), isFetching: false }), _g)) });
        }
        case ActionType.GET_JOB_ATTACHMENT_FAILURE: {
            var fetchedEntity = state.jobAttachments && state.jobAttachments[action.payload.jobId];
            return __assign(__assign({}, state), { jobAttachments: __assign(__assign({}, state.jobAttachments), (_h = {}, _h[action.payload.jobAttachmentId] = __assign(__assign({}, fetchedEntity), { fetchFailedAtAt: new Date(), isFetching: false, fetchError: action.error }), _h)) });
        }
        case ActionType.CLEAR_JOB_ATTACHMENT: {
            var jobAttachments = state.jobAttachments || {};
            delete jobAttachments[action.payload.jobAttachmentId];
            return __assign(__assign({}, state), { jobAttachments: jobAttachments });
        }
        case ActionType.CLEAR_JOB_ATTACHMENTS: {
            return __assign(__assign({}, state), { jobAttachments: {} });
        }
        // UPDATE JOB
        case ActionType.REQUEST_UPDATE_JOB_ATTACHMENT:
            return __assign(__assign({}, state), { isUpdatingJobAttachment: true });
        case ActionType.UPDATE_JOB_ATTACHMENT_SUCCESS:
        case ActionType.UPDATE_JOB_ATTACHMENT_FAILURE:
            return __assign(__assign({}, state), { isUpdatingJobAttachment: false });
        case ActionType.GET_JOB_LINE_ITEM_ATTACHMENT_SUCCESS: {
            var fetchedEntity = state.jobLineItemAttachments &&
                state.jobLineItemAttachments[action.payload.jobLineItemAttachmentId];
            return __assign(__assign({}, state), { jobLineItemAttachments: __assign(__assign({}, state.jobLineItemAttachments), (_j = {}, _j[action.payload.jobLineItemAttachmentId] = __assign(__assign({}, fetchedEntity), { entity: action.payload.jobLineItemAttachment, fetchSucceededAt: new Date(), isFetching: false }), _j)) });
        }
        case ActionType.GET_JOB_LINE_ITEM_ATTACHMENT_FAILURE: {
            var fetchedEntity = state.jobLineItemAttachments &&
                state.jobLineItemAttachments[action.payload.jobId];
            return __assign(__assign({}, state), { jobLineItemAttachments: __assign(__assign({}, state.jobLineItemAttachments), (_k = {}, _k[action.payload.jobLineItemAttachmentId] = __assign(__assign({}, fetchedEntity), { fetchFailedAtAt: new Date(), isFetching: false, fetchError: action.error }), _k)) });
        }
        case ActionType.CLEAR_JOB_LINE_ITEM_ATTACHMENT: {
            var jobLineItemAttachments = state.jobLineItemAttachments || {};
            delete jobLineItemAttachments[action.payload.jobLineItemAttachmentId];
            return __assign(__assign({}, state), { jobLineItemAttachments: jobLineItemAttachments });
        }
        case ActionType.CLEAR_JOB_LINE_ITEM_ATTACHMENTS: {
            return __assign(__assign({}, state), { jobLineItemAttachments: {} });
        }
        case ActionType.GET_JOB_LINE_ITEM_GROUP_SUCCESS: {
            var fetchedEntity = state.jobLineItemGroups &&
                state.jobLineItemGroups[action.payload.jobLineItemGroupId];
            return __assign(__assign({}, state), { jobLineItemGroups: __assign(__assign({}, state.jobLineItemGroups), (_l = {}, _l[action.payload.jobLineItemGroupId] = __assign(__assign({}, fetchedEntity), { entity: action.payload.jobLineItemGroup, fetchSucceededAt: new Date(), isFetching: false }), _l)) });
        }
        case ActionType.GET_JOB_LINE_ITEM_GROUP_FAILURE: {
            var fetchedEntity = state.jobLineItemGroups &&
                state.jobLineItemGroups[action.payload.jobId];
            return __assign(__assign({}, state), { jobLineItemGroups: __assign(__assign({}, state.jobLineItemGroups), (_m = {}, _m[action.payload.jobLineItemGroupId] = __assign(__assign({}, fetchedEntity), { fetchFailedAtAt: new Date(), isFetching: false, fetchError: action.error }), _m)) });
        }
        case ActionType.CLEAR_JOB_LINE_ITEM_GROUP: {
            var jobLineItemGroups = state.jobLineItemGroups || {};
            delete jobLineItemGroups[action.payload.jobLineItemGroupId];
            return __assign(__assign({}, state), { jobLineItemGroups: jobLineItemGroups });
        }
        case ActionType.CLEAR_JOB_LINE_ITEM_GROUPS: {
            return __assign(__assign({}, state), { jobLineItemGroups: {} });
        }
        case ActionType.GET_JOB_LINE_ITEM_SUCCESS: {
            var fetchedEntity = state.jobLineItems && state.jobLineItems[action.payload.jobLineItemId];
            return __assign(__assign({}, state), { jobLineItems: __assign(__assign({}, state.jobLineItems), (_o = {}, _o[action.payload.jobLineItemId] = __assign(__assign({}, fetchedEntity), { entity: action.payload.jobLineItem, fetchSucceededAt: new Date(), isFetching: false }), _o)) });
        }
        case ActionType.GET_JOB_LINE_ITEM_FAILURE: {
            var fetchedEntity = state.jobLineItems && state.jobLineItems[action.payload.jobId];
            return __assign(__assign({}, state), { jobLineItems: __assign(__assign({}, state.jobLineItems), (_p = {}, _p[action.payload.jobLineItemId] = __assign(__assign({}, fetchedEntity), { fetchFailedAtAt: new Date(), isFetching: false, fetchError: action.error }), _p)) });
        }
        case ActionType.CLEAR_JOB_LINE_ITEM: {
            var jobLineItems = state.jobLineItems || {};
            delete jobLineItems[action.payload.jobLineItemId];
            return __assign(__assign({}, state), { jobLineItems: jobLineItems });
        }
        case ActionType.CLEAR_JOB_LINE_ITEMS: {
            return __assign(__assign({}, state), { jobLineItems: {} });
        }
        case ActionType.REQUEST_CREATE_JOB_LINE_ITEM_GROUP:
            return __assign(__assign({}, state), { isCreatingJobLineItemGroup: true });
        case ActionType.CREATE_JOB_LINE_ITEM_GROUP_SUCCESS:
        case ActionType.CREATE_JOB_LINE_ITEM_GROUP_FAILURE:
            return __assign(__assign({}, state), { isCreatingJobLineItemGroup: false });
        case ActionType.REQUEST_UPDATE_JOB_LINE_ITEM_GROUP:
            return __assign(__assign({}, state), { isUpdatingJobLineItemGroup: true, jobLineItemGroupUpdateResults: __assign(__assign({}, state.jobLineItemGroupUpdateResults), (_q = {}, _q[action.payload.updateJobLineItemGroupRequestData.jobLineItemGroupId] = {
                    isUpdatedSuccessfully: undefined,
                    updateError: undefined,
                }, _q)) });
        case ActionType.UPDATE_JOB_LINE_ITEM_GROUP_SUCCESS:
            return __assign(__assign({}, state), { isUpdatingJobLineItemGroup: false, jobLineItemGroupUpdateResults: __assign(__assign({}, state.jobLineItemGroupUpdateResults), (_r = {}, _r[action.payload.jobLineItemGroupId] = {
                    isUpdatedSuccessfully: true,
                    updateError: undefined,
                }, _r)) });
        case ActionType.UPDATE_JOB_LINE_ITEM_GROUP_FAILURE:
            return __assign(__assign({}, state), { isUpdatingJobLineItemGroup: false, jobLineItemGroupUpdateResults: __assign(__assign({}, state.jobLineItemGroupUpdateResults), (_s = {}, _s[action.payload.jobLineItemGroupId] = {
                    isUpdatedSuccessfully: false,
                    updateError: action.error,
                }, _s)) });
        case ActionType.REQUEST_DELETE_JOB_LINE_ITEM_GROUP:
            return __assign(__assign({}, state), { isDeletingJobLineItemGroup: true });
        case ActionType.DELETE_JOB_LINE_ITEM_GROUP_SUCCESS:
        case ActionType.DELETE_JOB_LINE_ITEM_GROUP_FAILURE:
            return __assign(__assign({}, state), { isDeletingJobLineItemGroup: false });
        case ActionType.REQUEST_ADD_LINE_ITEM_GROUP_TO_JOB:
            return __assign(__assign({}, state), { isAddingLineItemGroupToJob: true });
        case ActionType.ADD_LINE_ITEM_GROUP_TO_JOB_FAILURE:
        case ActionType.ADD_LINE_ITEM_GROUP_TO_JOB_SUCCESS:
            return __assign(__assign({}, state), { isAddingLineItemGroupToJob: false });
        case ActionType.REQUEST_CREATE_JOB_LINE_ITEM:
            return __assign(__assign({}, state), { isCreatingJobLineItem: true });
        case ActionType.CREATE_JOB_LINE_ITEM_SUCCESS:
        case ActionType.CREATE_JOB_LINE_ITEM_FAILURE:
            return __assign(__assign({}, state), { isCreatingJobLineItem: false });
        case ActionType.REQUEST_UPDATE_JOB_LINE_ITEM:
            return __assign(__assign({}, state), { isUpdatingJobLineItem: true, jobLineItemUpdateResults: __assign(__assign({}, state.jobLineItemUpdateResults), (_t = {}, _t[action.payload.updateJobLineItemRequestData.jobLineItemId] = {
                    isUpdatedSuccessfully: undefined,
                    updateError: undefined,
                }, _t)) });
        case ActionType.REQUEST_UPDATE_JOB_LINE_ITEM_FROM_LINE_ITEM:
            return __assign(__assign({}, state), { isUpdatingJobLineItem: true, jobLineItemUpdateResults: __assign(__assign({}, state.jobLineItemUpdateResults), (_u = {}, _u[action.payload.jobLineItemId] = {
                    isUpdatedSuccessfully: undefined,
                    updateError: undefined,
                }, _u)) });
        case ActionType.UPDATE_JOB_LINE_ITEM_SUCCESS:
            return __assign(__assign({}, state), { isUpdatingJobLineItem: false, jobLineItemUpdateResults: __assign(__assign({}, state.jobLineItemUpdateResults), (_v = {}, _v[action.payload.jobLineItemId] = {
                    isUpdatedSuccessfully: true,
                    updateError: undefined,
                }, _v)) });
        case ActionType.UPDATE_JOB_LINE_ITEM_FAILURE:
            return __assign(__assign({}, state), { isUpdatingJobLineItem: false, jobLineItemUpdateResults: __assign(__assign({}, state.jobLineItemUpdateResults), (_w = {}, _w[action.payload.jobLineItemId] = {
                    isUpdatedSuccessfully: false,
                    updateError: action.error,
                }, _w)) });
        case ActionType.REQUEST_DELETE_JOB_LINE_ITEM:
            return __assign(__assign({}, state), { isDeletingJobLineItem: true });
        case ActionType.DELETE_JOB_LINE_ITEM_SUCCESS:
        case ActionType.DELETE_JOB_LINE_ITEM_FAILURE:
            return __assign(__assign({}, state), { isDeletingJobLineItem: false });
        case ActionType.REQUEST_ADD_ATTACHMENT_TO_JOB_LINE_ITEM:
            return __assign(__assign({}, state), { isAddingAttachmentToJobLineItem: true });
        case ActionType.ADD_ATTACHMENT_TO_JOB_LINE_ITEM_SUCCESS:
        case ActionType.ADD_ATTACHMENT_TO_JOB_LINE_ITEM_FAILURE:
            return __assign(__assign({}, state), { isAddingAttachmentToJobLineItem: false });
        case ActionType.REQUEST_REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM:
            return __assign(__assign({}, state), { isRemovingAttachmentFromJobLineItem: true });
        case ActionType.REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM_FAILURE:
        case ActionType.REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM_SUCCESS:
            return __assign(__assign({}, state), { isRemovingAttachmentFromJobLineItem: false });
        case ActionType.CLEAR_JOB_LINE_ITEM_UPDATE_RESULTS:
            return __assign(__assign({}, state), { jobLineItemUpdateResults: {} });
        case ActionType.CLEAR_JOB_LINE_ITEM_GROUP_UPDATE_RESULTS:
            return __assign(__assign({}, state), { jobLineItemGroupUpdateResults: {} });
        case ActionType.CLEAR_JOB_UPDATE_RESULTS:
            return __assign(__assign({}, state), { jobUpdateResults: {} });
        default:
            return state;
    }
};
export default jobsReducer;
