import React, { useState, useCallback, useMemo, } from 'react';
import { useDispatch } from 'react-redux';
import { actions as appActions } from '@/api/app';
import { ValidatedForm } from '@payaca/components';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import LoqateAdvisoryEmailInputField from '@/ui/components/loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';
import { ErrorMessage } from '@payaca/components/feedbackMessage';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import { getEmailFieldValidator, getIsRequiredFieldValidator, } from '@payaca/helpers/fieldValidationHelper';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { Modal } from '@payaca/components/modal';
import './ForgotPasswordForm.sass';
var ForgotPasswordForm = function () {
    var dispatch = useDispatch();
    var _a = useState(false), isResettingPassword = _a[0], setIsResettingPassword = _a[1];
    var _b = useState(), resetPasswordErrorMessage = _b[0], setResetPasswordErrorMessage = _b[1];
    var _c = useState(false), showFeedbackModal = _c[0], setShowFeedbackModal = _c[1];
    var passwordResetFeedbackModal = useMemo(function () {
        return (React.createElement(Modal, { isOpen: showFeedbackModal, size: "xs", title: "Reset password", onClose: function () { return setShowFeedbackModal(false); }, actions: React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, onClick: function () { return setShowFeedbackModal(false); } }, "Ok") },
            React.createElement("p", { style: { textAlign: 'center', whiteSpace: 'pre' } }, 'Please check your emails for instructions to reset\n your password.')));
    }, [showFeedbackModal]);
    var onSubmit = useCallback(function (emailAddress) {
        setIsResettingPassword(true);
        setResetPasswordErrorMessage(undefined);
        dispatch(appActions.sendPasswordResetEmail(emailAddress, function (error) {
            setIsResettingPassword(false);
            if (error) {
                setResetPasswordErrorMessage('Something went wrong. Please try again');
            }
            else {
                setShowFeedbackModal(true);
            }
        }));
    }, [dispatch]);
    var fieldValidators = useMemo(function () {
        return {
            emailAddress: [getEmailFieldValidator(), getIsRequiredFieldValidator()],
        };
    }, []);
    return (React.createElement("div", { className: "forgot-password-form-container" },
        React.createElement(ValidatedForm, { fieldValidators: fieldValidators, renderFormContents: function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
                return (React.createElement(React.Fragment, null,
                    React.createElement(ValidatedFieldWrapper, { validationResult: validationState['emailAddress'], isTouched: touchedState['emailAddress'] || false },
                        React.createElement(LoqateAdvisoryEmailInputField, { styleVariant: InputStyleVariant.OUTSIZE, label: 'Email', isRequired: true, value: formState.emailAddress, name: 'emailAddress', onChange: onFieldChange, onTouch: onFieldTouch, disableValidation: validationState['emailAddress']
                                ? !validationState['emailAddress'].isValid
                                : false })),
                    React.createElement("div", { className: "button-container" },
                        React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, isDisabled: !isValid, isProcessing: isResettingPassword, onClick: function () {
                                return !isResettingPassword && onSubmit(formState.emailAddress);
                            } }, "Submit"))));
            } }),
        resetPasswordErrorMessage && (React.createElement(ErrorMessage, { message: resetPasswordErrorMessage })),
        passwordResetFeedbackModal));
};
export default ForgotPasswordForm;
