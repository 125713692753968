import React, { useMemo } from 'react';
import tinycolor from 'tinycolor2';
import { Danger } from 'react-iconly';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CalloutPanel.sass';
export var CalloutPanelType;
(function (CalloutPanelType) {
    CalloutPanelType["ALERT"] = "alert";
    CalloutPanelType["ERROR"] = "error";
})(CalloutPanelType || (CalloutPanelType = {}));
var CalloutPanel = function (_a) {
    var className = _a.className, _b = _a.hasBoxShadow, hasBoxShadow = _b === void 0 ? false : _b, colour = _a.colour, children = _a.children, type = _a.type, icon = _a.icon;
    var borderColour = useMemo(function () {
        if (colour)
            return colour;
        if (type === CalloutPanelType.ALERT)
            return '#fabb00';
        if (type === CalloutPanelType.ERROR)
            return '#ff0000';
        return '#7eb0f1';
    }, [colour, type]);
    var backgroundColour = useMemo(function () {
        var c = tinycolor(borderColour);
        c.setAlpha(0.15);
        return c.toRgbString();
    }, [borderColour]);
    var calloutIcon = useMemo(function () {
        if (icon) {
            return React.createElement(FontAwesomeIcon, { icon: icon, style: { color: borderColour } });
        }
        if (type === CalloutPanelType.ALERT || type === CalloutPanelType.ERROR) {
            return React.createElement(Danger, { set: "bold", primaryColor: borderColour });
        }
    }, [borderColour, icon, type]);
    return (React.createElement("div", { className: "callout-panel " + className + "  " + (hasBoxShadow ? 'box-shadow' : ''), style: { borderColor: borderColour, backgroundColor: backgroundColour } },
        React.createElement("div", { className: "flex-container flex-center" },
            calloutIcon && React.createElement("div", { className: "icon-container" }, calloutIcon),
            React.createElement("div", { className: "flex-grow" }, children))));
};
export default CalloutPanel;
