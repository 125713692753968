import { useCallback, useMemo, cloneElement } from 'react';
import { actions as appActions } from '@/api/app';
import { getModal } from '@/helpers/modalHelper';
import { useDispatch } from 'react-redux';
import { actions as jobActions } from '@/api/jobs';
import { actions as userActions } from '@/api/users';
import { isInvoice } from '@bit/payaca-tech.payaca-core.helpers.job-status';
import { getCompletedDepositPaymentValueFromJobPayments } from '@bit/payaca-tech.payaca-core.helpers.job-payment';
import { useHistory } from 'react-router';
var CreateInvoiceFromQuote = function (_a) {
    var accountId = _a.accountId, job = _a.job, jobPayments = _a.jobPayments, children = _a.children, onIsProcessing = _a.onIsProcessing, onRecordPayment = _a.onRecordPayment, dealId = _a.dealId;
    var dispatch = useDispatch();
    var history = useHistory();
    var jobHasOutstandingDepositPayment = useMemo(function () {
        if (isInvoice(job.status)) {
            return false;
        }
        else {
            var value = (job.depositAmount || 0) -
                getCompletedDepositPaymentValueFromJobPayments(jobPayments);
            return value > 0;
        }
    }, [job, jobPayments]);
    var invoiceJob = useCallback(function () {
        onIsProcessing(true);
        dispatch(jobActions.convertJobToInvoice(job.id, function (err, resp) {
            if (!err) {
                dispatch(userActions.getAccountTerms(accountId, function () {
                    history.push({
                        pathname: "/invoices/" + resp.invoiceId,
                        search: dealId ? "?dealId=" + dealId : '',
                    });
                    onIsProcessing(false);
                }));
            }
            else {
                onIsProcessing(false);
            }
        }));
    }, [dispatch, accountId, job, history]);
    var promptRecordDepositPaymentBeforeInvoice = useCallback(function () {
        dispatch(appActions.showModal(getModal('OUTSTANDING_DEPOSIT_PAYMENT_PROMPT', {
            primaryAction: function () {
                onRecordPayment(true);
                dispatch(appActions.hideModal());
            },
            secondaryAction: function () {
                invoiceJob();
                dispatch(appActions.hideModal());
            },
            onClose: function () { return dispatch(appActions.hideModal()); },
        })));
    }, [invoiceJob, dispatch]);
    var handleInvoiceJob = useCallback(function () {
        if (jobHasOutstandingDepositPayment) {
            promptRecordDepositPaymentBeforeInvoice();
        }
        else {
            invoiceJob();
        }
    }, [
        jobHasOutstandingDepositPayment,
        promptRecordDepositPaymentBeforeInvoice,
        invoiceJob,
    ]);
    return cloneElement(children, { onClick: handleInvoiceJob });
};
export default CreateInvoiceFromQuote;
