import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ContentPanel } from '@payaca/components/contentPanel';
import { ClientInformationFlyerContentPanel, AdvertisingMaterialContentPanel, } from '@/ui/components';
import './ResourcesPageContent.css';
import TrainingVideosContentPanel from '../trainingVideosContentPanel/TrainingVideosContentPanel';
import { Button } from '@payaca/components';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
var ResourcesPageContent = function () {
    var accountAccessInformation = useSelector(function (state) {
        return state.account.accountAccessInformation;
    });
    var hasActiveSubscription = useMemo(function () {
        return !!(accountAccessInformation === null || accountAccessInformation === void 0 ? void 0 : accountAccessInformation.hasActiveSubscription);
    }, [accountAccessInformation]);
    return (React.createElement("div", { className: "resources-page-content" },
        !hasActiveSubscription && (React.createElement(ContentPanel, { className: "no-active-subscription-content-panel" }, "Resources are only available to subscribers.")),
        hasActiveSubscription && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "updates-button-container" },
                React.createElement("a", { href: "https://headwayapp.co/payaca-updates", target: "_blank", rel: "noopener noreferrer" },
                    React.createElement(Button, { iconAfter: faChevronRight }, "View Payaca updates"))),
            React.createElement(TrainingVideosContentPanel, null),
            React.createElement(AdvertisingMaterialContentPanel, null),
            React.createElement(ClientInformationFlyerContentPanel, null)))));
};
export default ResourcesPageContent;
