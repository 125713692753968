import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditableElementControl } from '@payaca/components/editableElementControl';
import ValidatedFieldWrapper from '@bit/payaca-tech.payaca-core.component.validated-field-wrapper';
import { BasicField } from '@payaca/components/basicField';
import { TextareaField } from '@payaca/components/textareaField';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import { ValidatedForm } from '@payaca/components';
import { getLengthFieldValidator } from '@bit/payaca-tech.payaca-core.helpers.field-validation';
import { actions as dealsActions } from '@bit/payaca-tech.payaca-core.store.deals';
import './DealInformationControl.sass';
import { getDeal } from '@/utils/stateAccessors';
var DealInformationControl = function (_a) {
    var dealId = _a.dealId;
    var dispatch = useDispatch();
    var _b = useState(false), isEditingDealReference = _b[0], setIsEditingDealReference = _b[1];
    var _c = useState({}), formState = _c[0], setFormState = _c[1];
    var _d = useState(false), isEditingDealDescription = _d[0], setIsEditingDealDescription = _d[1];
    var deal = useSelector(function (state) {
        return getDeal(state, dealId);
    });
    var initialFormState = useMemo(function () {
        if (!deal) {
            return {
                customReference: '',
                description: '',
            };
        }
        return {
            customReference: deal.customReference || "Deal " + deal.reference,
            description: deal.description || '',
        };
    }, [deal]);
    var onDealUpdate = useCallback(function (formState) {
        if (!deal) {
            return;
        }
        dispatch(dealsActions.requestUpdateDeal(deal.id, formState));
    }, [dispatch, deal, formState]);
    var fieldValidators = useMemo(function () {
        return {
            customReference: [getLengthFieldValidator({ min: 0, max: 30 })],
            description: [getLengthFieldValidator({ min: 0, max: 200 })],
        };
    }, []);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        if (!deal) {
            return React.createElement(React.Fragment, null, "Loading...");
        }
        return (React.createElement("div", { className: 'deal-information' },
            React.createElement("div", { className: 'deal-reference' },
                React.createElement(EditableElementControl, { customIsEditingState: isEditingDealReference, onEdit: function () {
                        setIsEditingDealDescription(false);
                        setIsEditingDealReference(true);
                    }, onSave: function () {
                        if (!validationState.customReference.isValid) {
                            return;
                        }
                        setIsEditingDealReference(false);
                        onDealUpdate(formState);
                    }, renderTemplate: React.createElement("h3", null, deal.customReference || "Deal " + deal.reference), editTemplate: React.createElement(ValidatedFieldWrapper, { validationResult: validationState.customReference },
                        React.createElement(BasicField, { name: 'customReference', styleVariant: InputStyleVariant.OUTSIZE, value: formState.customReference, onChange: onFieldChange, onTouch: onFieldTouch })) })),
            React.createElement("div", { className: 'deal-description' },
                React.createElement(EditableElementControl, { customIsEditingState: isEditingDealDescription, onEdit: function () {
                        setIsEditingDealReference(false);
                        setIsEditingDealDescription(true);
                    }, onSave: function () {
                        if (!validationState.description.isValid) {
                            return;
                        }
                        setIsEditingDealDescription(false);
                        onDealUpdate(formState);
                    }, renderTemplate: React.createElement("div", null, deal.description ||
                        'A description for the deal can be added here'), editTemplate: React.createElement(ValidatedFieldWrapper, { validationResult: validationState.description },
                        React.createElement(TextareaField, { name: 'description', styleVariant: InputStyleVariant.OUTSIZE, value: formState.description, onChange: onFieldChange, onTouch: onFieldTouch, rows: 1, autoSize: true })) }))));
    }, [deal, isEditingDealReference, isEditingDealDescription, onDealUpdate]);
    return (React.createElement("div", { className: 'deal-information-control' }, deal && (React.createElement(ValidatedForm, { renderFormContents: renderFormContents, initialFormState: initialFormState, fieldValidators: fieldValidators }))));
};
export default DealInformationControl;
