var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import './DateField.css';
import FieldLabel from '../fieldLabel/FieldLabel';
import InputWrapper from '../inputWrapper/InputWrapper';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
var DateField = function (_a) {
    var styleVariant = _a.styleVariant, name = _a.name, value = _a.value, label = _a.label, description = _a.description, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, _c = _a.isRequired, isRequired = _c === void 0 ? false : _c, _d = _a.iconBefore, iconBefore = _d === void 0 ? faCalendarAlt : _d, iconAfter = _a.iconAfter, onChange = _a.onChange, onTouch = _a.onTouch, _e = _a.additionalPickerProps, additionalPickerProps = _e === void 0 ? {} : _e;
    return (React.createElement("div", { className: "date-field" },
        React.createElement(FieldLabel, { label: label, isRequired: isRequired, description: description }),
        React.createElement(InputWrapper, { isDisabled: isDisabled, styleVariant: styleVariant },
            iconBefore && React.createElement(FontAwesomeIcon, { icon: iconBefore }),
            React.createElement(MuiPickersUtilsProvider, { utils: MomentUtils },
                React.createElement(DatePicker, __assign({ disabled: isDisabled, value: value !== null && value !== void 0 ? value : null, onChange: function (date) {
                        var _a;
                        onChange && onChange((_a = {}, _a[name] = new Date(date), _a));
                        onTouch && onTouch(name);
                    }, format: "DD MMMM YYYY", fullWidth: true, InputProps: { disableUnderline: true } }, additionalPickerProps))),
            iconAfter && React.createElement(FontAwesomeIcon, { icon: iconAfter }))));
};
export default DateField;
