import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './SubscriptionExceededBanner.css';
var SubscriptionExceededBanner = function () {
    var currentUserFirstName = useSelector(function (state) {
        var _a;
        return (_a = state.users.myProfile) === null || _a === void 0 ? void 0 : _a.firstname;
    });
    var currentUserIsAdmin = useSelector(function (state) {
        var _a;
        return !!((_a = state.users.myProfile) === null || _a === void 0 ? void 0 : _a.isAdmin);
    });
    return (React.createElement("div", { className: "subscription-exceeded-banner" },
        React.createElement("span", null,
            "Hi ",
            currentUserFirstName,
            ", all the jobs included in your subscription have been used up this month.",
            !currentUserIsAdmin &&
                ' Contact your account administrator to upgrade and get more jobs.'),
        currentUserIsAdmin && (React.createElement("a", { href: "/upgradeAccount?view=changeSubscriptionPlan" },
            "Upgrade and get more jobs",
            React.createElement(FontAwesomeIcon, { icon: faChevronRight })))));
};
export default SubscriptionExceededBanner;
