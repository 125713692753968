var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { takeEvery, call, put, race, delay, takeLatest, } from 'redux-saga/effects';
import { ActionType } from './dealsTypes';
import { activateDealViewFailure, activateDealViewSuccess, archiveDealsFailure, archiveDealsSuccess, clearDeals, createDealFailure, createDealSuccess, getDealFailure, getDealSuccess, getListedDealsPageFailure, getListedDealsPageSuccess, requestGetDeal, setDealCustomerFailure, setDealCustomerSuccess, unsetDealCustomerFailure, unsetDealCustomerSuccess, updateDealFailure, updateDealSuccess, } from './dealsActions';
import { refreshAuthToken } from '../auth/authSaga';
var dealsSagaCreator = function (_a) {
    var apiBaseurl = _a.apiBaseurl, getAuthHeader = _a.getAuthHeader, isNativeApp = _a.isNativeApp;
    function handleGetDeal(action) {
        var _a, response, timeout, errorMessage, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(getDeal, action.payload.dealId),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Get deal timed out.';
                    return [4 /*yield*/, put(getDealFailure(action.payload.dealId, new Error(errorMessage)))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(getDealSuccess(action.payload.dealId, response))];
                case 6:
                    _b.sent();
                    _b.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_1 = _b.sent();
                    return [4 /*yield*/, put(getDealFailure(action.payload.dealId, error_1))];
                case 9:
                    _b.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleUpdateDeal(action) {
        var _a, response, timeout, errorMessage, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(updateDeal, action.payload.dealId, action.payload.dealUpdates),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Update deal timed out.';
                    return [4 /*yield*/, put(updateDealFailure(action.payload.dealId, new Error(errorMessage)))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(updateDealSuccess(action.payload.dealId, response))];
                case 6:
                    _b.sent();
                    _b.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_2 = _b.sent();
                    return [4 /*yield*/, put(updateDealFailure(action.payload.dealId, error_2))];
                case 9:
                    _b.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleActivateDealView(action) {
        var timeout, errorMessage, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(activateDealView, action.payload.accountId),
                            timeout: delay(15000),
                        })];
                case 3:
                    timeout = (_a.sent()).timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Activate deal view timed out.';
                    return [4 /*yield*/, put(activateDealViewFailure(new Error(errorMessage)))];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(activateDealViewSuccess())];
                case 6:
                    _a.sent();
                    action.payload.onActivated && action.payload.onActivated();
                    _a.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_3 = _a.sent();
                    return [4 /*yield*/, put(activateDealViewFailure(error_3))];
                case 9:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleCreateDeal(action) {
        var _a, response, timeout, errorMessage, dealId, error_4;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(createDeal),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Create deal timed out.';
                    return [4 /*yield*/, put(createDealFailure(new Error(errorMessage)))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 7];
                case 5:
                    dealId = response.dealId;
                    return [4 /*yield*/, put(createDealSuccess(dealId))];
                case 6:
                    _b.sent();
                    action.payload.onDealCreated && action.payload.onDealCreated(dealId);
                    _b.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_4 = _b.sent();
                    return [4 /*yield*/, put(createDealFailure(error_4))];
                case 9:
                    _b.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleSetDealCustomer(action) {
        var _a, response, timeout, errorMessage, error_5;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(setDealCustomer, action.payload.dealId, action.payload.customerId),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Set deal customer timed out.';
                    return [4 /*yield*/, put(setDealCustomerFailure(new Error(errorMessage)))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(setDealCustomerSuccess(action.payload.dealId))];
                case 6:
                    _b.sent();
                    _b.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_5 = _b.sent();
                    return [4 /*yield*/, put(setDealCustomerFailure(error_5))];
                case 9:
                    _b.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleUnsetDealCustomer(action) {
        var _a, response, timeout, errorMessage, error_6;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(unsetDealCustomer, action.payload.dealId),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Unset deal customer timed out.';
                    return [4 /*yield*/, put(unsetDealCustomerFailure(new Error(errorMessage)))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(unsetDealCustomerSuccess(action.payload.dealId))];
                case 6:
                    _b.sent();
                    _b.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_6 = _b.sent();
                    return [4 /*yield*/, put(unsetDealCustomerFailure(error_6))];
                case 9:
                    _b.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    var activateDealView = function (accountId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/accounts/" + accountId + "/activate_deal_view", {
                            method: 'PATCH',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/, response.json()];
                                }
                                else {
                                    throw new Error("activateDealView failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var getDeal = function (dealId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/deals/" + dealId, {
                            method: 'GET',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/, response.json()];
                                }
                                else {
                                    throw new Error("getDeal failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var createDeal = function () { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/deals", {
                            method: 'POST',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Job': 'true',
                            },
                        }).then(function (response) {
                            if (response.ok) {
                                return response.json();
                            }
                            else {
                                throw new Error("createDeal failed: " + response.status + " " + response.statusText);
                            }
                        })];
            }
        });
    }); };
    var updateDeal = function (dealId, dealUpdates) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/deals/" + dealId, {
                            method: 'PUT',
                            body: JSON.stringify(dealUpdates),
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) {
                            if (response.ok) {
                                return response.json();
                            }
                            else {
                                throw new Error("updateDeal failed: " + response.status + " " + response.statusText);
                            }
                        })];
            }
        });
    }); };
    var setDealCustomer = function (dealId, customerId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/deals/" + dealId + "/customer", {
                            method: 'POST',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                            body: JSON.stringify({ customerId: customerId }),
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/];
                                }
                                else {
                                    throw new Error("setDealCustomer failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var unsetDealCustomer = function (dealId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/deals/" + dealId + "/customer", {
                            method: 'DELETE',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/];
                                }
                                else {
                                    throw new Error("unsetDealCustomer failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    function handleGetListedDealsPage(action) {
        var _a, response, timeout, error_7;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(getListedDealsPage, action.payload.getListedDealsRequestData),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    return [4 /*yield*/, put(getListedDealsPageFailure(new Error('Get listed deals request timed out.')))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(getListedDealsPageSuccess(response))];
                case 6:
                    _b.sent();
                    _b.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_7 = _b.sent();
                    return [4 /*yield*/, put(getListedDealsPageFailure(error_7))];
                case 9:
                    _b.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    var getListedDealsPage = function (getListedDealsRequestData) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = fetch;
                    _b = [apiBaseurl + "/listed_deals?pageNumber=" + getListedDealsRequestData.pageNumber + "&pageSize=" + getListedDealsRequestData.pageSize + "&searchTerm=" + (getListedDealsRequestData.searchTerm || '') + "&sortBy=" + (getListedDealsRequestData.sortBy || '') + "&sortDirection=" + (getListedDealsRequestData.sortDirection || '') + "&types=" + (getListedDealsRequestData.types
                            ? getListedDealsRequestData.types.join(',')
                            : '') + "&readableStatuses=" + (getListedDealsRequestData.readableStatuses
                            ? getListedDealsRequestData.readableStatuses.join(',')
                            : '') + "&userAssignments=" + (getListedDealsRequestData.userAssignments
                            ? getListedDealsRequestData.userAssignments.join(',')
                            : '')];
                    _c = {
                        method: 'GET'
                    };
                    _d = {};
                    return [4 /*yield*/, getAuthHeader()];
                case 1: return [2 /*return*/, _a.apply(void 0, _b.concat([(_c.headers = (_d.Authorization = _e.sent(),
                            _d['Content-Type'] = 'application/json',
                            _d['X-Simple-Job'] = 'true',
                            _d),
                            _c)])).then(function (response) {
                        if (response.ok) {
                            return response.json();
                        }
                        else {
                            throw new Error("GetListedDealsPage failed: " + response.status + " " + response.statusText);
                        }
                    })];
            }
        });
    }); };
    var archiveDeal = function (dealId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/deal/" + dealId + "/archive", {
                            method: 'PUT',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Job': 'true',
                            },
                        }).then(function (response) {
                            if (response.ok) {
                                return;
                            }
                            else {
                                throw new Error("Archive deal failed: " + response.status + " " + response.statusText);
                            }
                        })];
            }
        });
    }); };
    var archiveDeals = function (dealIds) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/deals/bulk_archive", {
                            method: 'PUT',
                            body: JSON.stringify({
                                dealIds: dealIds,
                            }),
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Job': 'true',
                            },
                        }).then(function (response) {
                            if (response.ok) {
                                return;
                            }
                            else {
                                throw new Error("Archive deals failed: " + response.status + " " + response.statusText);
                            }
                        })];
            }
        });
    }); };
    function handleArchiveDeals(action) {
        var isSingleDeal, _a, response, timeout, error_8;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _c.sent();
                    isSingleDeal = ((_b = action.payload.dealIds) === null || _b === void 0 ? void 0 : _b.length) === 1;
                    _c.label = 2;
                case 2:
                    _c.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: isSingleDeal
                                ? call(archiveDeal, action.payload.dealIds[0])
                                : call(archiveDeals, action.payload.dealIds),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _c.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    return [4 /*yield*/, put(archiveDealsFailure(new Error('Archive deals timed out.')))];
                case 4:
                    _c.sent();
                    return [3 /*break*/, 7];
                case 5:
                    action.payload.onArchiveSuccess && action.payload.onArchiveSuccess();
                    return [4 /*yield*/, put(archiveDealsSuccess())];
                case 6:
                    _c.sent();
                    _c.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_8 = _c.sent();
                    return [4 /*yield*/, put(archiveDealsFailure(error_8))];
                case 9:
                    _c.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleSetCustomerSuccess(action) {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, put(requestGetDeal(action.payload.dealId))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    function handleUnsetCustomerSuccess(action) {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, put(requestGetDeal(action.payload.dealId))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    function handleAppLogout() {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, put(clearDeals())];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    return function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, takeLatest(ActionType.REQUEST_ACTIVATE_DEAL_VIEW, handleActivateDealView)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.REQUEST_GET_DEAL, handleGetDeal)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.REQUEST_UPDATE_DEAL, handleUpdateDeal)];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.REQUEST_CREATE_DEAL, handleCreateDeal)];
                case 4:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.REQUEST_SET_DEAL_CUSTOMER, handleSetDealCustomer)];
                case 5:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.SET_DEAL_CUSTOMER_SUCCESS, handleSetCustomerSuccess)];
                case 6:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.REQUEST_UNSET_DEAL_CUSTOMER, handleUnsetDealCustomer)];
                case 7:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.UNSET_DEAL_CUSTOMER_SUCCESS, handleUnsetCustomerSuccess)];
                case 8:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.REQUEST_GET_LISTED_DEALS_PAGE, handleGetListedDealsPage)];
                case 9:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(ActionType.REQUEST_ARCHIVE_DEALS, handleArchiveDeals)];
                case 10:
                    _a.sent();
                    return [4 /*yield*/, takeEvery('auth.logout', handleAppLogout)];
                case 11:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    };
};
export default dealsSagaCreator;
