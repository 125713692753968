import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { actions as jobsActions } from '@bit/payaca-tech.payaca-core.store.jobs';
import { useState } from 'react';
import JobLineItemGroupControl from '../jobLineItemGroupControl/JobLineItemGroupControl';
import { ContentPanel } from '@payaca/components/contentPanel';
import './JobLineItemGroupsControl.sass';
import SelectItemGroupModal from '../selectItemGroupModal/SelectItemGroupModal';
import { getJob as getJobFromState } from '@/utils/stateAccessors';
import { ClickableArea, CreateArea } from '@payaca/components/clickableArea';
var JobLineItemGroupsControl = function (_a) {
    var _b;
    var jobId = _a.jobId, jobIsInvoice = _a.jobIsInvoice;
    var dispatch = useDispatch();
    var job = useSelector(function (state) {
        return getJobFromState(state, jobId);
    });
    var isCreatingJobLineItemGroup = useSelector(function (state) {
        return state.jobsStore.isCreatingJobLineItemGroup;
    });
    var isAddingLineItemGroupToJob = useSelector(function (state) {
        return state.jobsStore.isAddingLineItemGroupToJob;
    });
    var _c = useState(false), showLineItemGroupModal = _c[0], setShowLineItemGroupModal = _c[1];
    var _d = useState(null), lineItemGroupToAdd = _d[0], setLineItemGroupToAdd = _d[1];
    var getJobLineItems = useCallback(function () {
        dispatch(jobsActions.requestGetJobLineItemsForJob(jobId));
    }, [dispatch, jobId]);
    var getJobLineItemGroups = useCallback(function () {
        dispatch(jobsActions.requestGetJobLineItemGroupsForJob(jobId));
    }, [dispatch, jobId]);
    var getJob = useCallback(function () {
        dispatch(jobsActions.requestGetJob(jobId));
    }, [dispatch, jobId]);
    var createJobLineItemGroup = useCallback(function () {
        dispatch(jobsActions.requestCreateJobLineItemGroup({ jobId: jobId }, function () {
            getJobLineItemGroups();
            getJob();
        }));
    }, [dispatch, getJob, getJobLineItemGroups, jobId]);
    var addLineItemGroupToJob = useCallback(function (lineItemGroupId) {
        dispatch(jobsActions.requestAddLineItemGroupToJob(jobId, lineItemGroupId, function () {
            dispatch(jobsActions.requestGetJobWithJobGroupsAndJobLineItems(jobId));
        }));
    }, [jobId, getJobLineItems]);
    var jobHasMultipleJobLineItemGroups = useMemo(function () {
        if (!(job === null || job === void 0 ? void 0 : job.jobLineItemGroupIds))
            return false;
        return job.jobLineItemGroupIds.length > 1;
    }, [job]);
    return (React.createElement("div", { className: "job-line-item-groups-control" },
        React.createElement("div", { className: "job-line-item-group-controls-container" }, (_b = job === null || job === void 0 ? void 0 : job.jobLineItemGroupIds) === null || _b === void 0 ? void 0 : _b.map(function (jobLineItemGroupId, index) { return (React.createElement(ContentPanel, { key: "job-line-item-group-control-" + index, hasBoxShadow: false },
            React.createElement(JobLineItemGroupControl, { canDeleteItemGroup: jobHasMultipleJobLineItemGroups, jobIsInvoice: jobIsInvoice, jobLineItemGroupId: jobLineItemGroupId }))); })),
        React.createElement("div", { className: "create-or-add-existing-container line-item-group" },
            React.createElement(ClickableArea, { isProcessing: isAddingLineItemGroupToJob, onClick: function () { return setShowLineItemGroupModal(true); } }, "Search existing groups"),
            React.createElement("span", { className: "or" }, "or"),
            React.createElement(CreateArea, { isProcessing: isCreatingJobLineItemGroup, onClick: createJobLineItemGroup }, "Create new group")),
        React.createElement(SelectItemGroupModal, { isOpen: showLineItemGroupModal, onClose: function () { return setShowLineItemGroupModal(false); }, onSelectItemGroup: function (lineItemGroup) {
                if (lineItemGroupToAdd) {
                    // don't allow selecting a group if already adding one
                    return;
                }
                addLineItemGroupToJob(lineItemGroup.id);
                setShowLineItemGroupModal(false);
            } })));
};
export default JobLineItemGroupsControl;
