import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import './ListedItemsTable.sass';
import { SortBy, } from '@bit/payaca-tech.payaca-core.types.listed-item';
import MiniLoader from '@bit/payaca-tech.payaca-core.component.mini-loader';
import { Checkbox } from '@payaca/components/checkbox';
import { RowSelectionTableCell } from '@payaca/components/rowSelectionTableCell';
import { PaginationControl } from '@payaca/components/paginationControl';
import EmptyState from '../emptyState/EmptyState';
import XeroLogo from '../../../assets/images/xero.svg';
import QuickbooksLogo from '../../../assets/images/quickbooks.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { SortableColumnHeaderTableCell } from '@payaca/components/sortableColumnHeaderTableCell';
var renderPriceIncludingVatTableCell = function (listedItem) {
    var _a;
    return (React.createElement("td", { className: "align-right price-including-vat-table-cell" },
        React.createElement("span", { className: "price" }, listedItem.priceIncludingVat),
        React.createElement("small", { className: "vat" }, (_a = listedItem.vat) === null || _a === void 0 ? void 0 : _a.replace('REVERSE', 'REV.'))));
};
var renderSupplierPriceTableCell = function (listedItem) {
    return (React.createElement("td", { className: "align-right supplier-price-table-cell" },
        React.createElement("span", { className: "price" }, listedItem.supplierPrice),
        React.createElement("small", { className: "name" }, listedItem.supplierName)));
};
var renderCisTableCell = function (listedItem) {
    return (React.createElement("td", { className: "align-center cis-table-cell" },
        listedItem.cisApplies && React.createElement(FontAwesomeIcon, { icon: faCheck }),
        listedItem.cisDeductionRate && (React.createElement("span", { className: "cis-deduction-rate" },
            listedItem.cisDeductionRate,
            "% deduction rate"))));
};
var ListedItemsTable = function (_a) {
    var _b;
    var _c = _a.disabledItemIds, disabledItemIds = _c === void 0 ? [] : _c, _d = _a.selectedItemIds, selectedItemIds = _d === void 0 ? [] : _d, sortBy = _a.sortBy, sortDirection = _a.sortDirection, onClickRow = _a.onClickRow, onSelectItems = _a.onSelectItems, onUnselectItems = _a.onUnselectItems, onSelectPage = _a.onSelectPage, onChangeSortOrder = _a.onChangeSortOrder, _e = _a.includeSupplierPriceColumn, includeSupplierPriceColumn = _e === void 0 ? true : _e, _f = _a.includeCisColumn, includeCisColumn = _f === void 0 ? true : _f, _g = _a.includeImportColumn, includeImportColumn = _g === void 0 ? true : _g;
    var listedItemsPage = useSelector(function (state) {
        var _a;
        return (_a = state.listedItems) === null || _a === void 0 ? void 0 : _a.listedItemsPage;
    });
    var canSelectItems = useMemo(function () {
        return !!(onSelectItems && onUnselectItems);
    }, [onUnselectItems, onSelectItems]);
    var isCisSubcontractor = useSelector(function (state) { var _a; return (_a = state.users.myProfile.accounts[0]) === null || _a === void 0 ? void 0 : _a.isCisSubcontractor; });
    var isGettingListedItemsPage = useSelector(function (state) { return state.listedItems.isGettingListedItemsPage; });
    var paginationControl = useMemo(function () {
        if (!listedItemsPage)
            return null;
        return (React.createElement(PaginationControl, { pageNumber: listedItemsPage.pageNumber, pageSize: listedItemsPage.pageSize, totalItemCount: listedItemsPage.totalItemCount, onSelectPage: onSelectPage }));
    }, [listedItemsPage, onSelectPage]);
    var accountHasAnyIntegration = useSelector(function (state) {
        var integrations = state.users.myProfile.accounts[0].integrations;
        return !!(integrations.xero || integrations.quickbooks);
    });
    var columnCount = useMemo(function () {
        var count = 4;
        if (accountHasAnyIntegration && includeImportColumn)
            count++;
        if (canSelectItems)
            count++;
        if (isCisSubcontractor && includeCisColumn)
            count++;
        if (includeSupplierPriceColumn)
            count++;
        return count;
    }, [
        accountHasAnyIntegration,
        canSelectItems,
        isCisSubcontractor,
        includeCisColumn,
        includeSupplierPriceColumn,
        includeImportColumn,
    ]);
    var allItemIds = useMemo(function () {
        if (!(listedItemsPage === null || listedItemsPage === void 0 ? void 0 : listedItemsPage.items))
            return [];
        return listedItemsPage.items.map(function (listedItem) { return listedItem.itemId; });
    }, [listedItemsPage]);
    var isAllItemsSelected = useMemo(function () {
        return allItemIds.sort().join(',') === selectedItemIds.sort().join(',');
    }, [allItemIds, selectedItemIds]);
    var renderTableHead = useMemo(function () {
        return (React.createElement("thead", null,
            React.createElement("tr", null,
                canSelectItems && (React.createElement("th", { className: "row-selection-table-cell" },
                    React.createElement(Checkbox, { isChecked: isAllItemsSelected, onChange: function () {
                            isAllItemsSelected
                                ? onUnselectItems && onUnselectItems(allItemIds)
                                : onSelectItems && onSelectItems(allItemIds);
                        } }))),
                React.createElement(SortableColumnHeaderTableCell, { columnName: "Reference", selectedSortDirection: sortBy === SortBy.NAME ? sortDirection : undefined, onChangeSortDirection: function (sortDirection) {
                        return onChangeSortOrder(SortBy.NAME, sortDirection);
                    } }),
                React.createElement(SortableColumnHeaderTableCell, { columnName: "Description", selectedSortDirection: sortBy === SortBy.DESCRIPTION ? sortDirection : undefined, onChangeSortDirection: function (sortDirection) {
                        return onChangeSortOrder(SortBy.DESCRIPTION, sortDirection);
                    } }),
                React.createElement(SortableColumnHeaderTableCell, { align: "center", columnName: "Price (ex vat)", selectedSortDirection: sortBy === SortBy.PRICE ? sortDirection : undefined, onChangeSortDirection: function (sortDirection) {
                        return onChangeSortOrder(SortBy.PRICE, sortDirection);
                    } }),
                React.createElement(SortableColumnHeaderTableCell, { align: "center", columnName: "Total (inc vat)", selectedSortDirection: sortBy === SortBy.PRICE_INCLUDING_VAT ? sortDirection : undefined, onChangeSortDirection: function (sortDirection) {
                        return onChangeSortOrder(SortBy.PRICE_INCLUDING_VAT, sortDirection);
                    } }),
                includeSupplierPriceColumn && (React.createElement(SortableColumnHeaderTableCell, { align: "center", columnName: "Cost price", selectedSortDirection: sortBy === SortBy.SUPPLIER_PRICE ? sortDirection : undefined, onChangeSortDirection: function (sortDirection) {
                        return onChangeSortOrder(SortBy.SUPPLIER_PRICE, sortDirection);
                    } })),
                isCisSubcontractor && includeCisColumn && (React.createElement("th", { className: "align-center" }, "CIS")),
                accountHasAnyIntegration && includeImportColumn && (React.createElement("th", { className: "align-center" }, "Import")))));
    }, [
        canSelectItems,
        isAllItemsSelected,
        allItemIds,
        accountHasAnyIntegration,
        isCisSubcontractor,
        onUnselectItems,
        onSelectItems,
        includeCisColumn,
        includeSupplierPriceColumn,
        includeImportColumn,
    ]);
    var renderTableRow = useCallback(function (listedItem, index) {
        var itemIsSelected = selectedItemIds.includes(listedItem.itemId);
        var itemIsDisabled = disabledItemIds.includes(listedItem.itemId);
        return (React.createElement("tr", { key: index, className: (itemIsDisabled ? 'disabled' : onClickRow ? 'clickable' : '') + " " + (itemIsSelected ? 'selected' : ''), onClick: function () { return onClickRow && onClickRow(listedItem.itemId); } },
            canSelectItems && (React.createElement(RowSelectionTableCell, { isSelected: itemIsSelected, onChange: function () {
                    itemIsSelected
                        ? onUnselectItems && onUnselectItems([listedItem.itemId])
                        : onSelectItems && onSelectItems([listedItem.itemId]);
                } })),
            React.createElement("td", { className: "tr-start" },
                React.createElement("strong", null, listedItem.name)),
            React.createElement("td", { className: "description-table-cell" }, listedItem.description),
            React.createElement("td", { className: "align-right" }, listedItem.price),
            renderPriceIncludingVatTableCell(listedItem),
            includeSupplierPriceColumn &&
                renderSupplierPriceTableCell(listedItem),
            isCisSubcontractor &&
                includeCisColumn &&
                renderCisTableCell(listedItem),
            accountHasAnyIntegration && includeImportColumn && (React.createElement("td", { className: "synced-with-integrations-table-cell" },
                React.createElement("div", { className: "synced-with-integrations-container" },
                    listedItem.syncedWithIntegrations.includes('Xero') && (React.createElement("img", { alt: "Xero", src: XeroLogo })),
                    listedItem.syncedWithIntegrations.includes('Quickbooks') && (React.createElement("img", { alt: "Quickbooks", src: QuickbooksLogo })))))));
    }, [
        accountHasAnyIntegration,
        disabledItemIds,
        canSelectItems,
        selectedItemIds,
        isCisSubcontractor,
        onSelectItems,
        onUnselectItems,
        onClickRow,
        includeCisColumn,
        includeSupplierPriceColumn,
        includeImportColumn,
    ]);
    return (React.createElement("div", { className: "listed-items-table" },
        React.createElement("div", { className: "scrollable-table-wrapper styled-table-wrapper" },
            React.createElement("table", { className: "styled-table" },
                renderTableHead,
                React.createElement("tbody", null,
                    isGettingListedItemsPage && (React.createElement("tr", null,
                        React.createElement("td", { colSpan: columnCount, className: "tr-exclude" },
                            React.createElement("div", { className: "loader-container" },
                                React.createElement(MiniLoader, null))))),
                    !isGettingListedItemsPage &&
                        !(listedItemsPage === null || listedItemsPage === void 0 ? void 0 : listedItemsPage.totalItemCount) &&
                        !(listedItemsPage === null || listedItemsPage === void 0 ? void 0 : listedItemsPage.totalUnfilteredItemCount) && (React.createElement("tr", null,
                        React.createElement("td", { colSpan: columnCount, className: "tr-exclude" },
                            React.createElement(EmptyState, { configName: 'items', arrowIndication: false })))),
                    !isGettingListedItemsPage &&
                        !(listedItemsPage === null || listedItemsPage === void 0 ? void 0 : listedItemsPage.totalItemCount) &&
                        !!(listedItemsPage === null || listedItemsPage === void 0 ? void 0 : listedItemsPage.totalUnfilteredItemCount) && (React.createElement("tr", null,
                        React.createElement("td", { colSpan: columnCount, className: "tr-exclude" },
                            React.createElement("div", { className: "no-results-container" },
                                React.createElement("span", null, "No results found"))))),
                    !isGettingListedItemsPage && !!((_b = listedItemsPage === null || listedItemsPage === void 0 ? void 0 : listedItemsPage.items) === null || _b === void 0 ? void 0 : _b.length) && (React.createElement(React.Fragment, null, listedItemsPage.items.map(renderTableRow)))))),
        paginationControl));
};
export default ListedItemsTable;
