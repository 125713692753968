// ======================================================================================================
// Form commponent
//  - sections
//     - fields - [{ name, label, type, value}]
//     - render
//  - onChange - (change) => onChange(change)
//  - validation - validation object
//  - values - get field values from same level of object
//  - invalid - additional external variable to define if a button can be enabled
// ======================================================================================================

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash.get';
import union from 'lodash.union';
import isEqual from 'lodash.isequal';
import values from 'lodash.values';

import { Form, FormSection, ValidatedField } from '@/ui/components';

import {
  ExternalFormValidationErrors,
  getExternalFieldError,
  getFieldErrors,
  isFieldRequired,
} from '@/helpers/formHelper';

import { actions as userActions } from '@/api/users';

import './Form.css';

class ValidatedForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      allowedValidations: [],
      blockedEmails: [],
      isValidating: false,
    };
  }

  componentDidMount() {
    // validate all fields straight away
    this.validateForm();
    // get list of postmark blocked emails to validate email against
    this.props.users.getBlockedEmails((err, blockedEmails) => {
      if (blockedEmails) {
        this.setState({ blockedEmails });
        this.validateForm();
      }
    });
  }

  componentDidUpdate(oldProps) {
    // validate form anytime field data value changes
    if (!isEqual(oldProps.fieldData.values, this.props.fieldData.values)) {
      this.validateForm();
    }
  }

  getAllFields() {
    // filter out any pre-rendered sections, return array of fields
    const fieldDataValidSections = this.props.fieldData.sections.filter(
      (section) => section.fields && !section.fields.find((s) => s.render)
    );
    return fieldDataValidSections.reduce(
      (acc, curr) => acc.concat(curr.fields),
      []
    );
  }

  validateField(name, isValid, error) {
    // current field errors
    const currentFieldErrors = this.state.errors[name];
    let newFieldErrors = currentFieldErrors;
    // check if error already exists
    const errorIndex = newFieldErrors.findIndex((n) => n === error);
    if (errorIndex < 0 && !isValid) {
      // error doesn't exist and not valid
      newFieldErrors.push(error);
    } else if (errorIndex > -1 && isValid) {
      // error exists and is valid
      newFieldErrors.splice(errorIndex, 1);
    }
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: newFieldErrors,
      },
    });
  }

  validateForm() {
    let newStateErrors = { ...this.state.errors };
    // validate each field, add error to state errors object
    this.getAllFields().forEach(({ name }) => {
      const fieldValue = this.props.fieldData.values[name];
      const localErrorsForField = this.getErrorsForField(name, fieldValue);
      // find if external error currently exists on field
      const existingExternalError =
        this.state.errors[name] &&
        this.state.errors[name].reduce((acc, curr) => {
          if (curr === ExternalFormValidationErrors[name]) {
            acc = curr;
          }
          return acc;
        }, '');
      if (localErrorsForField) {
        newStateErrors[name] = localErrorsForField;
        if (existingExternalError) {
          // add external error back in
          newStateErrors[name].push(existingExternalError);
        }
      } else {
        // ignore any external errors existing
        delete newStateErrors[name];
        if (existingExternalError) {
          // add external error back in
          newStateErrors[name] = [existingExternalError];
        }
      }
    });
    this.setState({ errors: newStateErrors });
  }

  getErrorsForField(name, value) {
    const fieldValidations = get(this.props, [
      'fieldData',
      'validations',
      name,
    ]);
    return getFieldErrors(value, fieldValidations, {
      blockedEmails: this.state.blockedEmails,
    });
  }

  onExternalValidation(name, isValid) {
    // get external validation
    const error = getExternalFieldError(name);
    // current field errors
    const currentFieldErrors = this.state.errors[name];
    let newFieldErrors = currentFieldErrors;
    // check if error already exists
    const errorIndex =
      newFieldErrors && newFieldErrors.findIndex((n) => n === error);
    if (errorIndex < 0 && !isValid) {
      // error doesn't exist and not valid
      newFieldErrors.push(error);
    } else if (errorIndex > -1 && isValid) {
      // error exists and is valid
      newFieldErrors.splice(errorIndex, 1);
    }
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: newFieldErrors,
      },
    });
  }

  onFieldChange(name, value) {
    // add field to allowed validations
    this.setState({
      allowedValidations: union(this.state.allowedValidations, [name]),
    });
    this.props.fieldData.onChange({ [name]: value });
  }

  getFieldError(name) {
    const fieldErrors = get(this.state.errors, [name]);
    if (!fieldErrors) {
      return null;
    }
    // prioritise local validations over external
    const localError = fieldErrors.find((e) => !e.allowInvalid);
    return localError || fieldErrors[0];
  }

  forceValidate() {
    // allow validation on all fields to show all present errors
    this.setState({
      allowedValidations: this.getAllFields().map((field) => field.name),
    });
  }

  isValidForm() {
    // check for errors on every field
    const allErrors = values(this.state.errors).reduce((acc, curr) => {
      curr.forEach((c) => acc.push(c));
      return acc;
    }, []);
    // ignore errors which are allowed to be invalid
    const invalidErrors = allErrors.filter((a) => !a.allowInvalid);
    return !invalidErrors.length;
  }

  renderFields(fields) {
    return fields.map(
      (
        {
          label,
          name,
          type,
          preInputText,
          fieldType,
          tooltip,
          formatPreChange,
          value,
          render,
          disableExternalValidation,
          rowsMin,
        },
        i
      ) => {
        if (render) {
          return render();
        }
        // field error (shown under input)
        const error = this.getFieldError(name);
        // field value
        const fieldValue = value || this.props.fieldData.values[name];
        // determines if field can be shown (i.e. after typing, but not on initial load of field)
        const allowFieldValidation = this.state.allowedValidations.find(
          (v) => v === name
        );
        // field requires external validation
        const requiresExternalValidation =
          Object.keys(ExternalFormValidationErrors).find((er) => er === name) &&
          !disableExternalValidation;

        return (
          <ValidatedField
            label={label}
            value={fieldValue}
            name={name}
            type={type}
            onChange={(name, value) => {
              this.onFieldChange(name, value, requiresExternalValidation);
            }}
            key={i}
            error={error && error.error}
            preInputText={preInputText}
            allowValidation={allowFieldValidation}
            fieldType={fieldType}
            tooltip={tooltip}
            formatPreChange={formatPreChange}
            readOnly={this.props.readOnly}
            onExternalValidation={(name, isValid) =>
              this.onExternalValidation(name, isValid)
            }
            isExternallyValidating={(isValidating) =>
              this.setState({ isValidating: isValidating })
            }
            requiresExternalValidation={requiresExternalValidation}
            rowsMin={rowsMin}
            isRequired={isFieldRequired(
              get(this.props, ['fieldData', 'validations', name])
            )}
          />
        );
      }
    );
  }

  renderFormSections() {
    return this.props.fieldData.sections.map(
      ({ label, fields, render, sectionClass, sectionInformation }, i) => {
        return (
          <FormSection title={label} key={i} classes={sectionClass}>
            {sectionInformation && (
              <div className="section-information">{sectionInformation}</div>
            )}
            {render ? render() : this.renderFields(fields)}
          </FormSection>
        );
      }
    );
  }

  render() {
    const {
      buttonTitle,
      children,
      buttonAction,
      submitting,
      invalid,
      formError,
    } = this.props;
    const validForm = this.isValidForm() && !invalid;

    return (
      <Form
        buttonTitle={buttonTitle || 'Save'}
        buttonAction={buttonAction}
        isValid={validForm && !submitting && !this.state.isValidating}
        submitting={submitting}
        buttonDisabledAction={() => this.forceValidate()}
        hideButton={this.props.readOnly || this.props.hideButton}
      >
        {this.renderFormSections()}
        {children}
        {formError && <div className="form-error">{formError}</div>}
      </Form>
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => ({
    users: bindActionCreators(userActions, dispatch),
  })
)(ValidatedForm);
