import React, { useMemo, useState } from 'react';
import { actions as appActions } from '@/api/app';
import './AuthenticatedPageHeader.sass';
import { useDispatch } from 'react-redux';
import { faBars, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import HeaderNavigationControl from '../headerNavigationControl/HeaderNavigationControl';
import HeaderSearchControl from '../headerSearchControl/HeaderSearchControl';
import HeaderInformationResourcesControl from '../headerInformationResourcesControl/HeaderInformationResourcesControl';
var AuthenticatedPageHeader = function (_a) {
    var _b = _a.enableNavigationSidebarToggle, enableNavigationSidebarToggle = _b === void 0 ? false : _b, isSmallView = _a.isSmallView, searchFieldConfig = _a.searchFieldConfig;
    var dispatch = useDispatch();
    var _c = useState(true), isSearchBarCollapsed = _c[0], setIsSearchBarCollapsed = _c[1];
    var headerSearchControl = useMemo(function () {
        if (!searchFieldConfig)
            return null;
        return (React.createElement(HeaderSearchControl, { isSmallView: isSmallView, onSearchTermChange: searchFieldConfig.onSearchTermChange, placeholder: searchFieldConfig.placeholder, onToggleSearchBarCollapse: setIsSearchBarCollapsed }));
    }, [searchFieldConfig, isSmallView]);
    return (React.createElement("div", { className: "authenticated-page-header " + (isSmallView ? 'sm-view' : '') },
        enableNavigationSidebarToggle && isSearchBarCollapsed && (React.createElement("div", { className: "show-navigation-sidebar-toggle", onClick: function () { return dispatch(appActions.showNavigationSidebar()); } },
            React.createElement(FontAwesomeIcon, { icon: faBars }))),
        !isSmallView && headerSearchControl,
        !isSmallView && (React.createElement("div", { className: "date-container" },
            React.createElement(FontAwesomeIcon, { icon: faCalendarAlt }),
            React.createElement("span", null, moment().format('ddd DD MMM YYYY')))),
        React.createElement("div", { className: "info-actions-container" },
            isSmallView && (React.createElement("div", { className: "header-search-control-container" }, headerSearchControl)),
            (!isSmallView || isSearchBarCollapsed) && (React.createElement(React.Fragment, null,
                React.createElement(HeaderInformationResourcesControl, { isSmallView: isSmallView }),
                React.createElement(HeaderNavigationControl, { isSmallView: isSmallView }))))));
};
export default AuthenticatedPageHeader;
