export var ActionType;
(function (ActionType) {
    ActionType["CLEAR_JOBS"] = "jobs.clearJobsData";
    ActionType["CLEAR_JOB"] = "jobs.clearJobData";
    ActionType["UPDATE_JOB"] = "jobs.updateJob";
    ActionType["CREATE_JOB"] = "jobs.createJob";
    ActionType["GET_JOB_PREVIEW"] = "jobs.getJobPreview";
    ActionType["SELECT_JOB"] = "jobs.selectJob";
    ActionType["GET_JOB"] = "jobs.getJob";
    ActionType["GET_JOB_PAYMENTS"] = "jobs.getJobPayments";
    ActionType["STORE_JOB_LINE_ITEM_GROUPS"] = "jobs.storeJobLineItemGroups";
    ActionType["EDIT_CURRENT_JOB"] = "jobs.editCurrentJob";
    ActionType["SEND_QUOTE_TO_CUSTOMER"] = "jobs.sendQuoteToCustomer";
    ActionType["ARCHIVE_JOB"] = "jobs.archiveJob";
    ActionType["ARCHIVE_JOBS"] = "jobs.archiveJobs";
    ActionType["UNARCHIVE_JOB"] = "jobs.unarchiveJob";
    ActionType["UNARCHIVE_JOBS"] = "jobs.unarchiveJobs";
    ActionType["ACCEPT_QUOTE"] = "jobs.acceptQuote";
    ActionType["UNACCEPT_QUOTE"] = "jobs.unacceptQuote";
    ActionType["DECLINE_JOB"] = "jobs.declineJob";
    ActionType["CONVERT_JOB_TO_INVOICE"] = "jobs.convertJobToInvoice";
    ActionType["DELETE_JOB"] = "jobs.deleteJob";
    ActionType["DELETE_JOBS"] = "jobs.deleteJobs";
    ActionType["SEND_INVOICE_TO_CUSTOMER"] = "jobs.sendInvoiceToCustomer";
    ActionType["STORE_SEND_INVOICE_TO_CUSTOMER_ERROR"] = "jobs.storeSendInvoiceToCustomerError";
    ActionType["DUPLICATE_JOB"] = "jobs.duplicateJob";
    ActionType["STORE_CURRENT_LINE_ITEM"] = "jobs.storeCurrentLineItem";
    ActionType["UPDATE_CURRENT_LINE_ITEM"] = "jobs.updateCurrentLineItem";
    ActionType["CLEAR_CURRENT_LINE_ITEM"] = "jobs.clearCurrentLineItem";
    ActionType["ADD_LINE_ITEM_TO_NEW_JOB"] = "jobs.addLineItemToNewJob";
    ActionType["ADD_LINE_ITEM_TO_PENDING_JOB"] = "jobs.addLineItemToPendingJob";
    ActionType["UPDATE_ITEM_GROUP"] = "jobs.updateItemGroup";
    ActionType["REMOVE_ITEM_GROUP"] = "jobs.removeItemGroup";
    ActionType["UPDATE_ITEM"] = "jobs.updateItem";
    ActionType["ADD_ITEM"] = "jobs.addItem";
    ActionType["REMOVE_ITEM"] = "jobs.removeItem";
    ActionType["CREATE_UPDATE_ITEM_GROUPS_ON_JOB"] = "jobs.createUpdateItemGroupsOnJob";
    ActionType["ADD_ATTACHMENT_TO_JOB"] = "jobs.addAttachmentToJob";
    ActionType["REMOVE_ATTACHMENT_FROM_JOB"] = "jobs.removeAttachmentFromJob";
    ActionType["ADD_ATTACHMENT_TO_CURRENT_JOB"] = "jobs.addAttachmentToCurrentJob";
    ActionType["REMOVE_ATTACHMENT_FROM_CURRENT_JOB"] = "jobs.removeAttachmentFromCurrentJob";
    ActionType["ASSIGN_JOB"] = "jobs.assignJob";
    ActionType["ASSIGN_JOBS"] = "jobs.assignJobs";
    ActionType["UNASSIGN_JOB"] = "jobs.unassignJob";
    ActionType["UNASSIGN_JOBS"] = "jobs.unassignJobs";
    ActionType["MARK_AS_COMPLETE"] = "jobs.markAsComplete";
})(ActionType || (ActionType = {}));
