// ======================================================================================================
// Setup account form
// - Set up business details
// ======================================================================================================
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as usersActions } from '@/api/users';
import { ValidatedForm } from '@payaca/components';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { BasicField } from '@payaca/components/basicField';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import LoqateAdvisoryPhoneInputField from '../loqateAdvisoryPhoneInputField/LoqateAdvisoryPhoneInputField';
import LoqateAdvisoryEmailInputField from '../loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';
import { getAllowEmptyValidator, getIsRequiredFieldValidator, getEmailFieldValidator, getPhoneNumberFieldValidator, } from '@payaca/helpers/fieldValidationHelper';
import './SetupAccountCompanyForm.sass';
var SetupAccountCompanyForm = function (_a) {
    var onSetupAccountCallback = _a.onSetupAccountCallback;
    var dispatch = useDispatch();
    var _b = useState(), triggerValidationAt = _b[0], setTriggerValidationAt = _b[1];
    var _c = useState(false), isProcessing = _c[0], setIsProcessing = _c[1];
    var _d = useState(null), setupBusinessErrorMessage = _d[0], setSetupBusinessErrorMessage = _d[1];
    var myProfile = useSelector(function (state) { return state.users.myProfile; });
    var initialFormState = useMemo(function () {
        if (!myProfile) {
            return {};
        }
        return {
            email: myProfile.email,
            contactNumber: myProfile.contactNumber,
        };
    }, [myProfile]);
    var fieldValidators = useMemo(function () {
        var isRequiredFieldValidator = getIsRequiredFieldValidator();
        return {
            legalBusinessName: [isRequiredFieldValidator],
            email: [getAllowEmptyValidator(getEmailFieldValidator())],
            contactNumber: [getAllowEmptyValidator(getPhoneNumberFieldValidator())],
        };
    }, []);
    var onSetupBusinessError = useCallback(function () {
        setIsProcessing(false);
        setSetupBusinessErrorMessage('Sorry, there was an error setting up your account, please try again.');
    }, []);
    var onSubmitCallback = useCallback(function (accountId, error) {
        setIsProcessing(false);
        if (!error) {
            onSetupAccountCallback && onSetupAccountCallback(accountId);
        }
        else {
            onSetupBusinessError();
        }
    }, [dispatch, onSetupAccountCallback, onSetupBusinessError]);
    var onSubmit = useCallback(function (formState) {
        setSetupBusinessErrorMessage(null);
        setIsProcessing(true);
        // create business
        dispatch(usersActions.createBusinessAccount({
            name: formState.legalBusinessName,
            legalBusinessName: formState.legalBusinessName,
            contactNumber: formState.contactNumber,
            email: formState.email,
        }, function (createAccountError, newAccount) {
            console.log('>>>>>>> ~ file: SetupAccountCompanyForm.tsx ~ line 101 ~ newAccount', newAccount);
            onSubmitCallback(newAccount.id, createAccountError);
        }));
    }, [dispatch, onSubmitCallback]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.legalBusinessName, isTouched: touchedState.legalBusinessName || false },
                    React.createElement(BasicField, { name: "legalBusinessName", isRequired: true, label: "Registered business name", onChange: onFieldChange, onTouch: onFieldTouch, styleVariant: InputStyleVariant.OUTSIZE, value: formState.legalBusinessName || '' })),
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.email, isTouched: touchedState.email || false },
                    React.createElement(LoqateAdvisoryEmailInputField, { disableValidation: validationState.email ? !validationState.email.isValid : false, label: "Business email", name: "email", onChange: onFieldChange, onTouch: onFieldTouch, styleVariant: InputStyleVariant.OUTSIZE, value: formState.email })),
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.contactNumber, isTouched: touchedState.contactNumber || false },
                    React.createElement(LoqateAdvisoryPhoneInputField, { disableValidation: validationState.contactNumber
                            ? !validationState.contactNumber.isValid
                            : false, label: "Business phone", name: "contactNumber", onChange: onFieldChange, onTouch: onFieldTouch, styleVariant: InputStyleVariant.OUTSIZE, value: formState.contactNumber || '' }))),
            setupBusinessErrorMessage && (React.createElement("div", { className: "error" }, setupBusinessErrorMessage)),
            React.createElement("div", { className: "button-container" },
                React.createElement(Button, { isDisabled: !isValid, onClickDisabled: function () { return setTriggerValidationAt(new Date()); }, onClick: function () { return !isProcessing && onSubmit(formState); }, isProcessing: isProcessing, styleVariant: ButtonStyleVariant.OUTSIZE }, "Continue"))));
    }, [isProcessing, onSubmit, setupBusinessErrorMessage]);
    return (React.createElement("div", { className: "setup-account-company-form" },
        React.createElement(ValidatedForm, { triggerValidationAt: triggerValidationAt, fieldValidators: fieldValidators, renderFormContents: renderFormContents, initialFormState: initialFormState })));
};
export default SetupAccountCompanyForm;
