import React, { useMemo, useRef, useState } from 'react';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopupMenu from '../popupMenu/PopupMenu';
import './QuickActionsTableCell.css';
var QuickActionsTableCell = function (_a) {
    var recordId = _a.recordId, quickActions = _a.quickActions, _b = _a.isTrExclude, isTrExclude = _b === void 0 ? true : _b;
    var ref = useRef(null);
    var _c = useState(false), isOpen = _c[0], setIsOpen = _c[1];
    var popupMenuOptions = useMemo(function () {
        return quickActions.map(function (quickAction) {
            return {
                name: quickAction.actionName,
                onClick: function () { return quickAction.actionBehaviour(recordId); },
                isProcessing: quickAction.isActionProcessing || false,
            };
        });
    }, [quickActions, recordId]);
    return (React.createElement("td", { className: "quick-actions-table-cell " + (isTrExclude ? 'tr-exclude' : '') + " " + (!!quickActions.length ? 'clickable' : ''), ref: ref, onClick: function (e) {
            setIsOpen(true);
            e.stopPropagation();
        } }, !!quickActions.length && (React.createElement(React.Fragment, null,
        React.createElement(FontAwesomeIcon, { icon: faEllipsisV }),
        React.createElement(PopupMenu, { options: popupMenuOptions, isOpen: isOpen, onClose: function () {
                setIsOpen(false);
            }, anchorElement: ref.current })))));
};
export default QuickActionsTableCell;
