import React, { useMemo } from 'react';
import { currencyPrice } from '@bit/payaca-tech.payaca-core.helpers.finance';
import moment from 'moment';
import './DealFinanceItem.sass';
import { useSelector } from 'react-redux';
import { TitledContentPanel } from '@payaca/components/titledContentPanel';
import { DATE_TIMESTAMP_FORMAT } from '@payaca/constants';
export var DealFinanceItem = function (_a) {
    var jobFinanceInformationId = _a.jobFinanceInformationId;
    var _b = useSelector(function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.jobFinanceInformation) === null || _a === void 0 ? void 0 : _a.jobFinanceInformation[jobFinanceInformationId]) === null || _b === void 0 ? void 0 : _b.entity; }), productName = _b.productName, type = _b.type, financeAmount = _b.financeAmount, applicationStartedAt = _b.applicationStartedAt, approvedAt = _b.approvedAt, rejectedAt = _b.rejectedAt;
    var status = useMemo(function () {
        if (approvedAt) {
            return 'approved';
        }
        if (rejectedAt) {
            return 'failed';
        }
        return 'pending';
    }, [approvedAt, rejectedAt]);
    return (React.createElement(TitledContentPanel, { hasBoxShadow: false, className: "deal-finance-item " + status, title: React.createElement(React.Fragment, null,
            React.createElement("span", { className: 'flex-grow' },
                "Finance Application:",
                ' ',
                React.createElement("strong", null,
                    productName,
                    " (",
                    type,
                    ")")),
            React.createElement("strong", null, currencyPrice(financeAmount))) },
        React.createElement("div", { className: 'deal-finance-body flex-container' },
            React.createElement("div", { className: 'deal-finance-body-info' },
                React.createElement("div", null,
                    "Application started at:",
                    ' ',
                    moment(applicationStartedAt).format(DATE_TIMESTAMP_FORMAT)),
                approvedAt && (React.createElement("div", null,
                    "Application approved at:",
                    ' ',
                    moment(approvedAt).format(DATE_TIMESTAMP_FORMAT))),
                rejectedAt && (React.createElement("div", null,
                    "Application rejected at:",
                    ' ',
                    moment(rejectedAt).format(DATE_TIMESTAMP_FORMAT)))),
            React.createElement("div", { className: 'deal-finance-body-contact' }))));
};
