import React, { useEffect, useState, useCallback, } from 'react';
import { useDispatch } from 'react-redux';
import { actions as appActions } from '@/api/app';
import { actions as authActions } from '@bit/payaca-tech.payaca-core.store.auth';
import UnauthenticatedPageWrapper from '../pageWrappers/unauthenticatedPageWrapper/UnauthenticatedPageWrapper';
import './UserInvitePage.sass';
import UserInviteForm from '@/ui/components/userInviteForm/UserInviteForm';
var PageState;
(function (PageState) {
    PageState["USER_INVITE_FORM"] = "user-invite-form";
    PageState["INVALID_TOKEN"] = "invalid-token";
})(PageState || (PageState = {}));
var UserInvitePage = function (_a) {
    var match = _a.match, location = _a.location, history = _a.history, isAuthed = _a.isAuthed;
    var dispatch = useDispatch();
    var _b = useState(PageState.USER_INVITE_FORM), pageState = _b[0], setPageState = _b[1];
    useEffect(function () {
        if (isAuthed) {
            dispatch(authActions.logout());
        }
        else {
            validateToken();
        }
    }, []);
    var validateToken = useCallback(function () {
        var token = match.params.token;
        // check valid invite token
        dispatch(appActions.validUserInviteToken(token, function (error) {
            if (error) {
                setPageState(PageState.INVALID_TOKEN);
            }
        }));
    }, [dispatch, location.search, match.params.token]);
    return (React.createElement(UnauthenticatedPageWrapper, { className: "user-invite-page" },
        pageState === PageState.USER_INVITE_FORM && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "title-introduction-container" },
                React.createElement("h2", null, "Join Payaca")),
            React.createElement(UserInviteForm, { inviteToken: match.params.token }))),
        pageState === PageState.INVALID_TOKEN && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "title-introduction-container" },
                React.createElement("h2", null, "You have used an invalid invitation link."),
                React.createElement("p", null, "Please contact your account owner to send a new invitation."))))));
};
export default UserInvitePage;
