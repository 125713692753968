import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp, } from '@fortawesome/free-solid-svg-icons';
import { SortableColumnHeaderTableCell } from '@payaca/components/sortableColumnHeaderTableCell';
import { SortDirection } from '@bit/payaca-tech.payaca-core.types.list-view';
import { Checkbox } from '@payaca/components/checkbox';
var HeaderRow = function (_a) {
    var onColumnHeaderClick = _a.onColumnHeaderClick, onSelectAll = _a.onSelectAll, selectableData = _a.selectableData, selectedRows = _a.selectedRows, showBulkActionsColumn = _a.showBulkActionsColumn, showQuickActionsColumn = _a.showQuickActionsColumn, shownColumns = _a.shownColumns, sortData = _a.sortData;
    var renderSelectAllCell = function () {
        var allRowsSelected = selectableData.length === selectedRows.length;
        return (React.createElement("th", { className: "row-selection-table-cell" },
            React.createElement(Checkbox, { isChecked: !!(selectedRows.length && allRowsSelected), onChange: function () {
                    onSelectAll(allRowsSelected ? [] : selectableData);
                } })));
    };
    var getSortIcon = function (sortBy) {
        var sortIcon = faSort;
        if (sortData.value === sortBy) {
            sortIcon = sortData.reversed ? faSortDown : faSortUp;
        }
        return React.createElement(FontAwesomeIcon, { className: "column-sort-icon", icon: sortIcon });
    };
    return (React.createElement("thead", null,
        React.createElement("tr", null,
            showBulkActionsColumn && renderSelectAllCell(),
            shownColumns.map(function (config) {
                var columnClass = 'column-name' + (config.classes ? " " + config.classes : '');
                if (!config.disableSort) {
                    var selectedSortDirection = sortData.value === config.name
                        ? sortData.reversed
                            ? SortDirection.DESCENDING
                            : SortDirection.ASCENDING
                        : undefined;
                    return (React.createElement(SortableColumnHeaderTableCell, { className: columnClass, key: config.name + "-column", columnName: config.name, onChangeSortDirection: function () { return onColumnHeaderClick(config.name); }, selectedSortDirection: selectedSortDirection }));
                }
                return (React.createElement("th", { className: columnClass, key: config.name + "-column", onClick: function () { return onColumnHeaderClick(config.name); } }, config.name));
            }),
            showQuickActionsColumn && React.createElement("th", null))));
};
export default HeaderRow;
