import React, { useMemo } from 'react';
import './JobOverviewFooter.sass';
var JobOverviewFooter = function (_a) {
    var account = _a.account;
    var address = useMemo(function () {
        var fullAddress = '';
        if (account.address) {
            fullAddress += account.address;
        }
        if (account.city) {
            fullAddress += fullAddress ? ", " + account.city : account.city;
        }
        if (account.postcode) {
            fullAddress += fullAddress ? ", " + account.postcode : account.postcode;
        }
        return fullAddress;
    }, [account]);
    var showCompanyTradingName = useMemo(function () { return account.legalBusinessName !== account.companyName; }, [account.companyName, account.legalBusinessName]);
    var showAddress = useMemo(function () { return !!address; }, [address]);
    var showVatRegistration = useMemo(function () { return !!account.vatNumber; }, [account.vatNumber]);
    if (!showCompanyTradingName && !showAddress && !showVatRegistration) {
        return null;
    }
    return (React.createElement("footer", { className: "job-overview-footer" },
        showCompanyTradingName && (React.createElement("div", null,
            React.createElement("strong", null, account.legalBusinessName),
            " trading as",
            ' ',
            React.createElement("strong", null, account.companyName))),
        showAddress && React.createElement("div", null, address),
        showVatRegistration && (React.createElement("div", null,
            "VAT registration number: ",
            account.vatNumber))));
};
export default JobOverviewFooter;
