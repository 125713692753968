import React, { useMemo, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { ValidatedForm } from '@payaca/components';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { BasicField } from '@payaca/components/basicField';
import { ErrorMessage } from '@payaca/components/feedbackMessage';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import { getEmailFieldValidator, getIsRequiredFieldValidator, } from '@payaca/helpers/fieldValidationHelper';
import { actions as usersActions } from '@/api/users';
import { getFormServiceError } from '@/helpers/formHelper';
import './AddUserForm.css';
var AddUserForm = function (_a) {
    var onAddUserSuccess = _a.onAddUserSuccess;
    var dispatch = useDispatch();
    var _b = useState(false), isProcessing = _b[0], setIsProcessing = _b[1];
    var _c = useState(null), errorMessage = _c[0], setErrorMessage = _c[1];
    var accountUsers = useSelector(function (state) { return state.users.accountUsers; });
    var existingUserValidator = useCallback(function (fieldName, formState) {
        var value = get(formState, fieldName);
        var existingUser = accountUsers.find(function (accountUser) { return accountUser.email === value; });
        var errorMessage = null;
        if (existingUser) {
            if (existingUser.deactivatedAt) {
                if (existingUser.inviteToken) {
                    errorMessage = 'This user has already been invited.';
                }
                else {
                    errorMessage = 'This user has previously been removed.';
                }
            }
            else {
                errorMessage = 'This user is already active.';
            }
        }
        if (errorMessage) {
            return {
                isValid: false,
                errors: [errorMessage],
            };
        }
        return {
            isValid: true,
        };
    }, [accountUsers]);
    var getUserHasBeenRemoved = useCallback(function (emailAddress) {
        var existingUser = accountUsers.find(function (accountUser) { return accountUser.email === emailAddress; });
        if (existingUser) {
            if (existingUser.deactivatedAt) {
                if (!existingUser.inviteToken) {
                    return true;
                }
            }
        }
        return false;
    }, [accountUsers]);
    var fieldValidators = useMemo(function () {
        var isRequiredFieldValidator = getIsRequiredFieldValidator();
        var emailFieldValidator = getEmailFieldValidator({
            customErrorMessage: 'This field must contain a valid email address',
        });
        return {
            firstName: [isRequiredFieldValidator],
            lastName: [isRequiredFieldValidator],
            email: [
                isRequiredFieldValidator,
                emailFieldValidator,
                existingUserValidator,
            ],
        };
    }, [existingUserValidator]);
    var onReactivateUserSubmit = useCallback(function (formState) {
        setIsProcessing(true);
        setErrorMessage(null);
        var existingUser = accountUsers.find(function (accountUser) { return accountUser.email === formState.email; });
        dispatch(usersActions.reactivateUser(existingUser.id, function (error) {
            setIsProcessing(false);
            if (!error) {
                onAddUserSuccess && onAddUserSuccess();
            }
            else {
                setErrorMessage('Something went wrong reinstating user');
            }
        }));
    }, [onAddUserSuccess, dispatch, accountUsers]);
    var onInviteUserSubmit = useCallback(function (formState) {
        setIsProcessing(true);
        setErrorMessage(null);
        var payload = {
            email: formState.email,
            firstName: formState.firstName,
            lastName: formState.lastName,
        };
        dispatch(usersActions.inviteUserToAccount(payload, function (error) {
            setIsProcessing(false);
            if (!error) {
                onAddUserSuccess && onAddUserSuccess();
            }
            else {
                setErrorMessage(getFormServiceError('addUser', error));
            }
        }));
    }, [onAddUserSuccess, dispatch]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        var userHasBeenRemoved = getUserHasBeenRemoved(formState.email);
        return (React.createElement(React.Fragment, null,
            React.createElement(ValidatedFieldWrapper, { validationResult: validationState.firstName, isTouched: touchedState.firstName || false },
                React.createElement(BasicField, { name: "firstName", value: formState.firstName || '', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, label: "First name", styleVariant: InputStyleVariant.OUTSIZE })),
            React.createElement(ValidatedFieldWrapper, { validationResult: validationState.lastName, isTouched: touchedState.lastName || false },
                React.createElement(BasicField, { name: "lastName", value: formState.lastName || '', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, label: "Last name", styleVariant: InputStyleVariant.OUTSIZE })),
            React.createElement(ValidatedFieldWrapper, { validationResult: userHasBeenRemoved ? undefined : validationState.email, isTouched: touchedState.email || false },
                React.createElement(BasicField, { name: "email", value: formState.email || '', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, label: "Email address", styleVariant: InputStyleVariant.OUTSIZE })),
            React.createElement("div", { className: "actions-container flex-container flex-center" },
                errorMessage && React.createElement(ErrorMessage, { message: errorMessage }),
                !errorMessage && userHasBeenRemoved && (React.createElement(ErrorMessage, { message: "A user with the email address " + formState.email + " used to exist but has been removed." })),
                userHasBeenRemoved ? (React.createElement(Button, { onClick: function () {
                        return !isProcessing && onReactivateUserSubmit(formState);
                    }, isProcessing: isProcessing, styleVariant: ButtonStyleVariant.OUTSIZE },
                    React.createElement("span", { style: { whiteSpace: 'nowrap' } }, "Reinstate user"))) : (React.createElement(Button, { onClick: function () { return !isProcessing && onInviteUserSubmit(formState); }, isDisabled: !isValid, isProcessing: isProcessing, styleVariant: ButtonStyleVariant.OUTSIZE },
                    React.createElement("span", { style: { whiteSpace: 'nowrap' } }, "Send invite"))))));
    }, [
        onInviteUserSubmit,
        onReactivateUserSubmit,
        isProcessing,
        errorMessage,
        getUserHasBeenRemoved,
    ]);
    return (React.createElement("div", { className: "add-user-form" },
        React.createElement(ValidatedForm, { renderFormContents: renderFormContents, fieldValidators: fieldValidators })));
};
export default AddUserForm;
