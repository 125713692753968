var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ValidatedForm } from '@payaca/components';
import React, { useEffect, useCallback, useMemo, useState, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SubscriptionPaymentMethodControl from '../subscriptionPaymentMethodControl/SubscriptionPaymentMethodControl';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import './UpdatePaymentMethodControl.css';
import { getSubscriptionCostIncludingVat } from '@bit/payaca-tech.payaca-core.helpers.subscription';
import { actions as subscriptionActions } from '@bit/payaca-tech.payaca-core.store.subscription';
import { ErrorMessage } from '@payaca/components/feedbackMessage';
var TAX_RATE_PERCENTAGE = process.env
    .REACT_APP_SUBSCRIPTION_TAX_RATE_PERCENTAGE;
var UpdatePaymentMethodControl = function (_a) {
    var onUpdatePaymentMethod = _a.onUpdatePaymentMethod;
    var history = useHistory();
    var dispatch = useDispatch();
    var _b = useState(false), isSubmitted = _b[0], setIsSubmitted = _b[1];
    var _c = useState(false), updatePaymentMethod = _c[0], setUpdatePaymentMethod = _c[1];
    var _d = useState(false), isCreatingPaymentMethod = _d[0], setIsCreatingPaymentMethod = _d[1];
    var isUpdatingPaymentMethod = useSelector(function (state) { return state.subscription.isUpdatingPaymentMethod; });
    var accountSubscription = useSelector(function (state) { return state.subscription.accountSubscription; });
    var isPaymentMethodUpdatedSuccessfully = useSelector(function (state) { return state.subscription.isPaymentMethodUpdatedSuccessfully; });
    var isProcessing = useMemo(function () {
        return isUpdatingPaymentMethod || isCreatingPaymentMethod;
    }, [isUpdatingPaymentMethod, isCreatingPaymentMethod]);
    var initialFormState = useMemo(function () {
        if (!accountSubscription)
            return {};
        return {
            subscriptionId: accountSubscription.subscriptionInformation.stripeSubscriptionId,
            paymentMethodId: accountSubscription.subscriptionInformation.stripePaymentMethodId,
        };
    }, [accountSubscription]);
    var onSubmit = useCallback(function (formState) {
        var updatePaymentMethodRequestData = {
            stripePaymentMethodId: formState.paymentMethodId,
            stripeSubscriptionId: formState.subscriptionId,
        };
        dispatch(subscriptionActions.requestUpdatePaymentMethod(updatePaymentMethodRequestData));
        setIsSubmitted(true);
    }, [dispatch]);
    useEffect(function () {
        if (isSubmitted &&
            !isUpdatingPaymentMethod &&
            isPaymentMethodUpdatedSuccessfully) {
            onUpdatePaymentMethod && onUpdatePaymentMethod();
        }
    }, [
        isSubmitted,
        isUpdatingPaymentMethod,
        isPaymentMethodUpdatedSuccessfully,
        onUpdatePaymentMethod,
    ]);
    var subscriptionCostIncludingVat = useMemo(function () {
        if (!accountSubscription)
            return;
        return getSubscriptionCostIncludingVat(accountSubscription.subscriptionProduct, accountSubscription.recurringInterval, accountSubscription.subscriptionInformation.additionalUserSeats, TAX_RATE_PERCENTAGE);
    }, [accountSubscription]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "form-section" },
                React.createElement("div", { className: "update-payment-method-container" },
                    React.createElement(SubscriptionPaymentMethodControl, { paymentAmount: subscriptionCostIncludingVat, onPaymentMethodChange: function (paymentMethod) {
                            setIsCreatingPaymentMethod(false);
                            if (paymentMethod) {
                                onSubmit(__assign(__assign({}, formState), { paymentMethodId: paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.id }));
                            }
                        }, renderActions: function (handleCreatePaymentMethod, applePayButton) {
                            return (React.createElement(React.Fragment, null,
                                applePayButton,
                                React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, onClick: !isProcessing
                                        ? function () {
                                            setIsCreatingPaymentMethod(true);
                                            handleCreatePaymentMethod();
                                        }
                                        : undefined, iconAfter: faAngleRight, isProcessing: isProcessing }, "Update payment method")));
                        } })),
                isPaymentMethodUpdatedSuccessfully === false && isSubmitted && (React.createElement(ErrorMessage, { message: "Failed to update your payment method." })))));
    }, [
        history,
        isSubmitted,
        isProcessing,
        updatePaymentMethod,
        isPaymentMethodUpdatedSuccessfully,
        onSubmit,
        subscriptionCostIncludingVat,
    ]);
    return (React.createElement("div", { className: "update-payment-method-control" },
        React.createElement(ValidatedForm, { renderFormContents: renderFormContents, initialFormState: initialFormState })));
};
export default UpdatePaymentMethodControl;
