var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useMemo, useState } from 'react';
import get from 'lodash.get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { faChevronRight, faPen } from '@fortawesome/free-solid-svg-icons';
import { BasicField } from '@payaca/components/basicField';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { ValidatedForm } from '@payaca/components';
import ResponsiveViewWrapper from '@bit/payaca-tech.payaca-core.component.responsive-view-wrapper';
import { getEmailFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import { getJobContactFromCustomer } from '@bit/payaca-tech.payaca-core.helpers.customer';
import { actions as customerActions } from '@bit/payaca-tech.payaca-core.store.customer';
import './CustomerEmailControl.css';
export var getEmailMustNotBeBlockedValidator = function (blockedEmails, options) {
    var errorMessage = (options === null || options === void 0 ? void 0 : options.customErrorMessage) ||
        ((options === null || options === void 0 ? void 0 : options.readableName) ? options === null || options === void 0 ? void 0 : options.readableName : 'This') + " must be a valid email address";
    return function (fieldName, formState) {
        var value = get(formState, fieldName);
        if (blockedEmails && blockedEmails.includes(value)) {
            return {
                isValid: false,
                errors: [errorMessage],
            };
        }
        return {
            isValid: true,
        };
    };
};
var CustomerEmailControl = function (_a) {
    var blockedEmails = _a.blockedEmails, customer = _a.customer, jobContactId = _a.jobContactId, onPersistCustomer = _a.onPersistCustomer;
    var _b = useState(false), isInEditMode = _b[0], setIsInEditMode = _b[1];
    var dispatch = useDispatch();
    var isPersistingCustomer = useSelector(function (state) { return state.customer.isPersistingCustomer; });
    var jobContact = useMemo(function () { return getJobContactFromCustomer(customer, jobContactId); }, [customer, jobContactId]);
    var onSubmit = useCallback(function (formState) {
        var contacts = customer.contacts.map(function (customerContact, index) {
            if (customerContact.id === (jobContact === null || jobContact === void 0 ? void 0 : jobContact.id)) {
                // update email on job contact
                return __assign(__assign({}, customerContact), { emailAddress: formState.emailAddress });
            }
            else {
                return customerContact;
            }
        });
        var customerToPersist = __assign(__assign({}, customer), { contacts: contacts });
        dispatch(customerActions.requestPersistCustomer(customerToPersist, function (customerId) {
            setIsInEditMode(false);
            onPersistCustomer && onPersistCustomer(customerId);
        }));
    }, [customer, onPersistCustomer]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        if (!isInEditMode)
            return (React.createElement("div", null,
                React.createElement("div", { className: "flex-container" },
                    React.createElement("div", null, formState.emailAddress),
                    React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, isProcessing: isPersistingCustomer, onClick: function () { return setIsInEditMode(true); } },
                        React.createElement(FontAwesomeIcon, { icon: faPen })))));
        return (React.createElement("div", { className: "flex-container form-contents" },
            React.createElement("div", { className: "flex-grow field-container" },
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.emailAddress, isTouched: true },
                    React.createElement(BasicField, { name: "emailAddress", value: formState.emailAddress || '', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch }))),
            React.createElement("div", { className: "button-container" },
                React.createElement(Button, { isDisabled: !isValid, isProcessing: isPersistingCustomer, onClick: function () { return !isPersistingCustomer && onSubmit(formState); }, iconAfter: faChevronRight }, "Update email"))));
    }, [isPersistingCustomer, onSubmit, isInEditMode]);
    var fieldValidators = useMemo(function () {
        return {
            emailAddress: [
                getEmailFieldValidator(),
                getEmailMustNotBeBlockedValidator(blockedEmails || []),
            ],
        };
    }, [blockedEmails]);
    var initialFormState = useMemo(function () {
        return {
            emailAddress: jobContact === null || jobContact === void 0 ? void 0 : jobContact.emailAddress,
        };
    }, [jobContact]);
    return (React.createElement(ResponsiveViewWrapper, { className: "customer-email-control", downBreakpointSm: 550 },
        React.createElement(ValidatedForm, { renderFormContents: renderFormContents, initialFormState: initialFormState, fieldValidators: fieldValidators })));
};
export default CustomerEmailControl;
