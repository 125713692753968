import React, { useState } from 'react';
import { ContentPanel, ResponsiveViewWrapper } from '@payaca/components';
import './CreateSubscriptionGuidancePrompts.sass';
import { Button, ButtonStyleVariant, ButtonColourVariant, } from '@payaca/components/button';
import BookDemoModal from '../bookDemoModal/BookDemoModal';
import WatchDemoModal from '../watchDemoModal/WatchDemoModal';
var CreateSubscriptionGuidancePrompts = function (_a) {
    var onGoToSubscriptionPlans = _a.onGoToSubscriptionPlans;
    var _b = useState(false), showBookDemoModal = _b[0], setShowBookDemoModal = _b[1];
    var _c = useState(false), showWatchDemoModal = _c[0], setShowWatchDemoModal = _c[1];
    return (React.createElement(ResponsiveViewWrapper, { className: "create-subscription-guidance-prompts-container", downBreakpointSm: 900 },
        React.createElement(ContentPanel, { className: "create-subscription-guidance-prompt" },
            React.createElement("div", { className: "content-wrapper" },
                React.createElement("h2", null, "Book a demo"),
                React.createElement("div", { className: "prompt-intro-copy" }, "Choose a convenient slot for a free 20 minute demo. Our friendly team will help you discover if Payaca is right for you and your business."),
                React.createElement(Button, { onClick: function () { return setShowBookDemoModal(true); } }, "Book a free demo")),
            React.createElement("small", { className: "watch-demo-prompt flex-container flex-center" },
                React.createElement("span", null, "Don't want to speak to anyone?"),
                React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () { return setShowWatchDemoModal(true); } }, "Watch a demo here"))),
        React.createElement(ContentPanel, { className: "create-subscription-guidance-prompt" },
            React.createElement("div", { className: "content-wrapper" },
                React.createElement("h2", null, "Join Payaca"),
                React.createElement("div", { className: "prompt-intro-copy" }, "Ready to go? Choose the best plan for your business. You'll get a dedicated customer success manager to help you get started."),
                React.createElement(Button, { colourVariant: ButtonColourVariant.PRIMARY, onClick: onGoToSubscriptionPlans }, "Choose a plan"))),
        React.createElement(BookDemoModal, { isOpen: showBookDemoModal, onClose: function () { return setShowBookDemoModal(false); } }),
        React.createElement(WatchDemoModal, { isOpen: showWatchDemoModal, onClose: function () { return setShowWatchDemoModal(false); } })));
};
export default CreateSubscriptionGuidancePrompts;
