import React from 'react';
import { faCheck, faExclamationTriangle, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './FeedbackMessage.sass';
export var FeedbackLevel;
(function (FeedbackLevel) {
    FeedbackLevel["ERROR"] = "error";
    FeedbackLevel["SUCCESS"] = "success";
})(FeedbackLevel || (FeedbackLevel = {}));
var FeedbackMessage = function (_a) {
    var message = _a.message, feedbackLevel = _a.feedbackLevel, iconBefore = _a.iconBefore;
    return (React.createElement("p", { className: "flex-container flex-center feedback-message " + (feedbackLevel ? "feedback-level-" + feedbackLevel : '') },
        iconBefore && (React.createElement("span", { className: "icon-container" },
            React.createElement(FontAwesomeIcon, { icon: iconBefore }))),
        React.createElement("span", null, message)));
};
export var ErrorMessage = function (_a) {
    var message = _a.message;
    return (React.createElement(FeedbackMessage, { message: message, feedbackLevel: FeedbackLevel.ERROR, iconBefore: faExclamationTriangle }));
};
export var SuccessMessage = function (_a) {
    var message = _a.message;
    return (React.createElement(FeedbackMessage, { message: message, feedbackLevel: FeedbackLevel.SUCCESS, iconBefore: faCheck }));
};
export default FeedbackMessage;
