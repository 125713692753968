var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState, useCallback, useMemo, } from 'react';
import './AggregateValueBarChart.css';
import * as d3 from 'd3';
var DEFAULT_CHART_PADDING = {
    top: 0,
    bottom: 40,
    left: 40,
    right: 0,
};
var ANIMATION_DURATION_MS = 1000;
var LABEL_MARGIN_PX = 4;
var MAX_BAND_WIDTH_PX = 40;
var BarLabelPosition;
(function (BarLabelPosition) {
    BarLabelPosition["INTERNAL"] = "internal";
    BarLabelPosition["EXTERNAL"] = "external";
    BarLabelPosition["NO_DISPLAY"] = "no_display";
})(BarLabelPosition || (BarLabelPosition = {}));
var AggregateValueBarChart = function (_a) {
    var data = _a.data, xAxisLabel = _a.xAxisLabel, yAxisLabel = _a.yAxisLabel, overrideChartPadding = _a.overrideChartPadding, formatValue = _a.formatValue, formatXAxisTickValue = _a.formatXAxisTickValue;
    var _b = useState(null), chartContainer = _b[0], setChartContainer = _b[1];
    var _c = useState(true), requiresUpdateVisualisationData = _c[0], setRequiresUpdateVisualisationData = _c[1];
    var _d = useState(false), showTooltip = _d[0], setShowTooltip = _d[1];
    var _e = useState({
        x: 0,
        y: 0,
    }), tooltipCoordinates = _e[0], setTooltipCoordinates = _e[1];
    var _f = useState(), tooltipContent = _f[0], setTooltipContent = _f[1];
    var chartContainerRef = useCallback(function (node) {
        if (node !== null) {
            setChartContainer(node);
        }
    }, []);
    var chartPadding = useMemo(function () {
        return __assign(__assign({}, DEFAULT_CHART_PADDING), overrideChartPadding);
    }, [overrideChartPadding]);
    var svgSelection = useMemo(function () {
        if (chartContainer) {
            return d3.select(chartContainer).append('svg');
        }
    }, [chartContainer]);
    var xAxisLabelSelection = useMemo(function () {
        if (svgSelection) {
            return svgSelection
                .append('text')
                .text(xAxisLabel || '')
                .attr('class', 'axis-label');
        }
    }, [svgSelection, xAxisLabel]);
    var yAxisLabelSelection = useMemo(function () {
        if (svgSelection) {
            return svgSelection
                .append('text')
                .text(yAxisLabel || '')
                .attr('class', 'axis-label');
        }
    }, [svgSelection, yAxisLabel]);
    var xScale = useMemo(function () {
        return d3.scaleLinear().domain([0, 0]).range([0, 0]);
    }, []);
    var yScale = useMemo(function () {
        return d3.scaleBand().rangeRound([0, 0]).padding(0.1);
    }, []);
    var yAxis = useMemo(function () {
        return d3.axisLeft(yScale);
    }, [yScale]);
    var xAxis = useMemo(function () {
        var _xAxis = d3.axisBottom(xScale);
        if (formatXAxisTickValue) {
            _xAxis = _xAxis.tickFormat(function (value) {
                return formatXAxisTickValue(value.valueOf());
            });
        }
        return _xAxis;
    }, [xScale, formatValue]);
    var chartDataGroupSelection = useMemo(function () {
        if (svgSelection) {
            return svgSelection
                .append('g')
                .attr('transform', "translate(" + chartPadding.left + ", " + chartPadding.top + ")");
        }
    }, [svgSelection]);
    var _g = useState(), aggregateBarGroups = _g[0], setAggregateBarGroups = _g[1];
    var _h = useState(), barGroups = _h[0], setBarGroups = _h[1];
    var _j = useState(), bars = _j[0], setBars = _j[1];
    var _k = useState(), barLabels = _k[0], setBarLabels = _k[1];
    var yAxisSelection = useMemo(function () {
        if (svgSelection) {
            return svgSelection
                .append('g')
                .attr('transform', "translate(" + chartPadding.left + "," + chartPadding.top + ")")
                .call(yAxis);
        }
    }, [svgSelection, yAxis]);
    var xAxisSelection = useMemo(function () {
        if (svgSelection) {
            return svgSelection
                .append('g')
                .attr('transform', "translate(" + chartPadding.left + "," + (chartPadding.top + yScale.range()[1]) + ")")
                .call(xAxis);
        }
    }, [svgSelection, xAxis, yScale]);
    var handleBarMouseenter = useCallback(function (event, datapoint) {
        setTooltipContent(React.createElement("div", null,
            React.createElement("p", null, data === null || data === void 0 ? void 0 :
                data.aggregateCategories[datapoint.categoryLabel],
                ": "),
            React.createElement("p", null, formatValue ? formatValue(datapoint.value) : datapoint.value)));
        setShowTooltip(true);
    }, [formatValue, data === null || data === void 0 ? void 0 : data.categories]);
    var handleBarMousemove = useCallback(function (event) {
        var chartBounds = chartContainer === null || chartContainer === void 0 ? void 0 : chartContainer.getBoundingClientRect();
        setTooltipCoordinates({
            x: event.clientX - ((chartBounds === null || chartBounds === void 0 ? void 0 : chartBounds.x) || 0) + 5,
            y: event.clientY - ((chartBounds === null || chartBounds === void 0 ? void 0 : chartBounds.y) || 0) + 5,
        });
    }, [chartContainer]);
    var handleBarMouseleave = useCallback(function () {
        setShowTooltip(false);
    }, []);
    var getBarLabelPosition = useCallback(function (datapoint, index, elements) {
        var currentElement = elements[index];
        var bbox = currentElement.getBBox();
        var width = bbox.width;
        var height = bbox.height;
        var barWidth = xScale(datapoint.value) || 0;
        var barHeight = yScale.bandwidth();
        if (height >= barHeight) {
            return BarLabelPosition.NO_DISPLAY;
        }
        else if (width + LABEL_MARGIN_PX * 2 < barWidth) {
            return BarLabelPosition.INTERNAL;
        }
        else if (index + 1 === elements.length) {
            var barXEnd = (xScale(datapoint === null || datapoint === void 0 ? void 0 : datapoint.aggregateValue) || 0) + barWidth;
            if (barXEnd + LABEL_MARGIN_PX + width >
                xScale.range()[1] + chartPadding.right) {
                return BarLabelPosition.NO_DISPLAY;
            }
            else {
                return BarLabelPosition.EXTERNAL;
            }
        }
        else {
            return BarLabelPosition.NO_DISPLAY;
        }
    }, [xScale, yScale]);
    var updateVisualisationDimensions = useCallback(function () {
        var height = (chartContainer === null || chartContainer === void 0 ? void 0 : chartContainer.clientHeight) || 0;
        var width = (chartContainer === null || chartContainer === void 0 ? void 0 : chartContainer.clientWidth) || 0;
        var scaleWidth = width - chartPadding.right - chartPadding.left;
        var scaleHeight = height - chartPadding.bottom - chartPadding.top;
        var bandWidth = Math.min(MAX_BAND_WIDTH_PX, yScale.bandwidth());
        xScale.range([0, scaleWidth]);
        yScale.range([0, scaleHeight]);
        xAxisLabelSelection &&
            xAxisLabelSelection
                .attr('dy', height - 3)
                .attr('dx', chartPadding.left + scaleWidth / 2)
                .attr('text-anchor', 'middle');
        yAxisLabelSelection &&
            yAxisLabelSelection
                .attr('dy', '1em')
                .attr('dx', -(chartPadding.top + scaleHeight / 2))
                .attr('transform', 'rotate(-90)')
                .attr('text-anchor', 'middle');
        yAxisSelection && yAxisSelection.call(yAxis);
        xAxisSelection &&
            xAxisSelection
                .attr('transform', "translate(" + chartPadding.left + "," + (chartPadding.top + yScale.range()[1]) + ")")
                .call(xAxis);
        aggregateBarGroups &&
            setAggregateBarGroups(aggregateBarGroups.attr('transform', function (d) {
                var barOffset = (yScale.bandwidth() - bandWidth) / 2;
                return "translate(0, " + ((yScale(d.categoryLabel) || 0) + barOffset) + ")";
            }));
        barGroups && setBarGroups(barGroups);
        barGroups &&
            barGroups
                .transition()
                .duration(ANIMATION_DURATION_MS)
                .attr('transform', function (d) {
                return "translate(" + (xScale(d.aggregateValue) || 0) + ", 0)";
            });
        bars && setBars(bars.attr('height', bandWidth));
        bars &&
            bars
                .transition()
                .duration(ANIMATION_DURATION_MS)
                .attr('width', function (d) { return xScale(d.value) || 0; });
        barLabels && setBarLabels(barLabels.attr('y', bandWidth / 2));
        barLabels &&
            barLabels
                .transition()
                .duration(ANIMATION_DURATION_MS)
                .attr('x', function (datapoint, i, elements) {
                var barWidth = xScale(datapoint.value) || 0;
                var barLabelPosition = getBarLabelPosition(datapoint, i, elements);
                if (barLabelPosition === BarLabelPosition.INTERNAL) {
                    return barWidth - LABEL_MARGIN_PX;
                }
                else {
                    return barWidth + LABEL_MARGIN_PX;
                }
            })
                .attr('class', function (datapoint, i, elements) {
                var barLabelPosition = getBarLabelPosition(datapoint, i, elements);
                if (barLabelPosition === BarLabelPosition.INTERNAL) {
                    return "bar-label " + datapoint.categoryLabel + "-bar-label internal-bar-label";
                }
                else if (barLabelPosition === BarLabelPosition.EXTERNAL) {
                    return "bar-label " + datapoint.categoryLabel + "-bar-label external-bar-label";
                }
                else {
                    return "bar-label " + datapoint.categoryLabel + "-bar-label no-display-bar-label";
                }
            });
    }, [
        xScale,
        yScale,
        xAxisLabelSelection,
        yAxisLabelSelection,
        xAxisSelection,
        yAxisSelection,
        xAxis,
        yAxis,
        chartContainer,
        aggregateBarGroups,
        barGroups,
        bars,
        barLabels,
    ]);
    var updateAxisLabels = useCallback(function () {
        xAxisLabelSelection && xAxisLabelSelection.text(xAxisLabel || '');
        yAxisLabelSelection && yAxisLabelSelection.text(yAxisLabel || '');
    }, [xAxisLabelSelection, yAxisLabelSelection, xAxisLabel, yAxisLabel]);
    var updateVisualisationData = useCallback(function () {
        if (!data)
            return;
        xScale.domain([0, data.maxValue]);
        var labels = Object.keys(data.categories);
        yScale.domain(labels);
        yAxis.tickFormat(function (categoryLabel) { return data.categories[categoryLabel]; });
        if (chartDataGroupSelection) {
            var _aggregateBarGroups = chartDataGroupSelection
                .selectAll('g.aggregate-bar')
                .data(data.datapoints)
                .enter()
                .append('g')
                .attr('class', 'aggregate-bar');
            setAggregateBarGroups(_aggregateBarGroups);
            var _barGroups = _aggregateBarGroups
                .selectAll('g.bar')
                .data(function (datapoint) {
                return datapoint.aggregateDatapoints;
            })
                .enter()
                .append('g')
                .attr('class', 'bar');
            _barGroups.sort(function (a, b) {
                return b.aggregateValue - a.aggregateValue;
            });
            setBarGroups(_barGroups);
            var _bars = _barGroups
                .append('rect')
                .attr('class', function (d) { return d.categoryLabel; });
            _bars
                .on('mouseenter', handleBarMouseenter)
                .on('mousemove', handleBarMousemove)
                .on('mouseleave', handleBarMouseleave);
            setBars(_bars);
            var _barLabels = _barGroups
                .append('text')
                .attr('class', function (d) {
                return "bar-label " + d.categoryLabel + "-bar-label";
            })
                .text(function (d) {
                return formatValue ? formatValue(d.value) : d.value;
            });
            setBarLabels(_barLabels);
        }
    }, [
        data,
        xScale,
        yScale,
        yAxis,
        chartDataGroupSelection,
        handleBarMouseenter,
        handleBarMousemove,
        handleBarMouseleave,
    ]);
    useEffect(function () {
        window.addEventListener('resize', updateVisualisationDimensions);
        return function () {
            window.removeEventListener('resize', updateVisualisationDimensions);
        };
    }, [updateVisualisationDimensions]);
    useEffect(function () {
        updateAxisLabels();
    }, [xAxisLabel, yAxisLabel, updateAxisLabels]);
    useEffect(function () {
        if (svgSelection)
            updateVisualisationDimensions();
    }, [svgSelection, updateVisualisationDimensions]);
    useEffect(function () {
        if (data && requiresUpdateVisualisationData) {
            updateVisualisationData();
            updateVisualisationDimensions();
            setRequiresUpdateVisualisationData(false);
        }
    }, [
        data,
        requiresUpdateVisualisationData,
        updateVisualisationDimensions,
        updateVisualisationData,
    ]);
    useEffect(function () {
        if (data) {
            setRequiresUpdateVisualisationData(true);
        }
    }, [data]);
    return (React.createElement("div", { ref: chartContainerRef, className: "aggregate-value-bar-chart-container" },
        React.createElement("div", { className: "chart-tooltip", style: {
                top: tooltipCoordinates.y,
                left: tooltipCoordinates.x,
                opacity: showTooltip ? 1 : 0,
            } }, tooltipContent)));
};
export default AggregateValueBarChart;
