import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import { useHistory } from 'react-router';
import { actions as authActions } from '@bit/payaca-tech.payaca-core.store.auth';
import BasicPageWrapper from '../pageWrappers/basicPageWrapper/BasicPageWrapper';
var AppleCallbackPage = function () {
    var dispatch = useDispatch();
    var history = useHistory();
    var _a = useState(false), isSubmitted = _a[0], setIsSubmitted = _a[1];
    var isLoggingIn = useSelector(function (state) { return state.auth.isLoggingIn; });
    var isLoggedInSuccessfully = useSelector(function (state) { return state.auth.isLoggedInSuccessfully; });
    useEffect(function () {
        dispatch(authActions.logout());
    }, []);
    useEffect(function () {
        if (isLoggedInSuccessfully && isSubmitted) {
            history.push('/');
        }
        else if (isSubmitted && !isLoggedInSuccessfully && !isLoggingIn) {
            history.push('/?error=An error occurred logging into Apple, please try again or use an alternative method.');
        }
    }, [isLoggedInSuccessfully, isSubmitted, history, isLoggingIn]);
    useEffect(function () {
        var query = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });
        if (query.code) {
            setIsSubmitted(true);
            dispatch(authActions.requestLoginWithApple({ code: query.code }));
        }
    }, []);
    return (
    //@ts-ignore
    React.createElement(BasicPageWrapper, null,
        React.createElement("div", null)));
};
export default AppleCallbackPage;
