import React from 'react';
import './FieldLabel.sass';
var FieldLabel = function (_a) {
    var label = _a.label, _b = _a.isRequired, isRequired = _b === void 0 ? false : _b, description = _a.description;
    return (React.createElement("div", { className: "field-label" },
        label && (React.createElement("label", null,
            label,
            isRequired && React.createElement("span", { className: "required-indicator" }, "*"))),
        description && React.createElement("span", { className: "description" }, description)));
};
export default FieldLabel;
