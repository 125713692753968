import React, { useCallback, useState, useRef, useEffect, useMemo, } from "react";
import "./SteppedProgressTracker.css";
import Step from "./Step";
import StepConnector from "./StepConnector";
var MIN_STEP_INDICATOR_SIZE = 40;
var MAX_STEP_INDICATOR_SIZE = 100;
var SteppedProgressTracker = function (_a) {
    var steps = _a.steps, currentStepLabel = _a.currentStepLabel, onClickStep = _a.onClickStep;
    var _b = useState(80), stepIndicatorSize = _b[0], setStepIndicatorSize = _b[1];
    var _c = useState(false), isVerticalView = _c[0], setIsVerticalView = _c[1];
    var steppedProgressTrackerRef = useRef(null);
    var currentStepIndex = useMemo(function () {
        return steps.findIndex(function (step) {
            return step.label === currentStepLabel;
        });
    }, [steps, currentStepLabel]);
    var renderStepAndConnector = useCallback(function (step, index) {
        var isCompleted = currentStepIndex > index;
        var isCurrent = currentStepIndex === index;
        return (React.createElement(React.Fragment, { key: index },
            index > 0 && (React.createElement(StepConnector, { key: index + "-step-connector", isCompleted: isCompleted, isCurrent: isCurrent, stepIndicatorSize: stepIndicatorSize })),
            React.createElement(Step, { key: index + "-step", name: step.name, isCompleted: isCompleted, isCurrent: isCurrent, index: index, stepIndicatorSize: stepIndicatorSize, onClick: onClickStep ? function () { return onClickStep(step.label); } : undefined })));
    }, [stepIndicatorSize, currentStepIndex, onClickStep]);
    var handleResize = useCallback(function () {
        var _a;
        if (!steppedProgressTrackerRef.current)
            return;
        var width = (_a = steppedProgressTrackerRef === null || steppedProgressTrackerRef === void 0 ? void 0 : steppedProgressTrackerRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth;
        var stepCount = steps.length;
        var stepIndicatorSizeCalc = width / (stepCount + (stepCount - 1) / 2);
        stepIndicatorSizeCalc = Math.min(stepIndicatorSizeCalc, MAX_STEP_INDICATOR_SIZE);
        if (stepIndicatorSizeCalc < MIN_STEP_INDICATOR_SIZE) {
            setIsVerticalView(true);
        }
        else {
            setIsVerticalView(false);
        }
        stepIndicatorSizeCalc = Math.max(stepIndicatorSizeCalc, MIN_STEP_INDICATOR_SIZE);
        setStepIndicatorSize(stepIndicatorSizeCalc);
    }, [steps, steppedProgressTrackerRef.current]);
    useEffect(function () {
        handleResize();
        window.addEventListener("resize", handleResize);
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);
    return (React.createElement("div", { ref: steppedProgressTrackerRef, className: "stepped-progress-tracker" + (isVerticalView ? " vertical-view" : ""), style: {
            paddingLeft: stepIndicatorSize / 2,
            paddingRight: stepIndicatorSize / 2,
        } },
        React.createElement("div", { className: "stepped-progress-tracker-inner" }, steps.map(function (step, index) {
            return renderStepAndConnector(step, index);
        }))));
};
export default SteppedProgressTracker;
