import React, { useMemo } from 'react';
import JobOverviewItem from './JobOverviewItem';
var JobOverviewItemsSection = function (_a) {
    var helperTitle = _a.helperTitle, hideItemPrices = _a.hideItemPrices, hideVat = _a.hideVat, isEditable = _a.isEditable, _b = _a.isProposition, isProposition = _b === void 0 ? false : _b, jobLineItems = _a.jobLineItems, jobLineItemAttachments = _a.jobLineItemAttachments, onItemClick = _a.onItemClick;
    var selectedMultipleChoiceItem = useMemo(function () {
        if (!!jobLineItems.find(function (i) { return i.isMultipleChoice; })) {
            return jobLineItems.find(function (i) { return i.isSelected; });
        }
        return null;
    }, [jobLineItems]);
    return (React.createElement("div", { className: "job-overview-items-section " + (helperTitle ? 'with-title' : '') },
        helperTitle && (React.createElement("h3", { className: "job-overview-item-group-section-helper-title" }, helperTitle)),
        helperTitle && (React.createElement("div", { className: "job-overview-item-group-section-helper-border" })),
        jobLineItems.map(function (jobLineItem) { return (React.createElement(JobOverviewItem, { hideItemPrices: hideItemPrices, item: jobLineItem, attachments: jobLineItemAttachments.filter(function (attachment) {
                return jobLineItem.jobLineItemAttachmentIds.includes(attachment.id);
            }), key: jobLineItem.id, isEditable: isEditable, isProposition: isProposition, onItemClick: onItemClick, selectedMultipleChoiceItem: selectedMultipleChoiceItem, hideVat: hideVat })); })));
};
export default JobOverviewItemsSection;
