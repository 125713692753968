import React, { useMemo } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { LabelValuePair } from '@payaca/components';
import './CompaniesHouseCompanyPreview.css';
var CompaniesHouseCompanyPreview = function (_a) {
    var companiesHouseCompanyPreview = _a.companiesHouseCompanyPreview;
    var registeredAddressString = useMemo(function () {
        var registeredAddressElements = [
            companiesHouseCompanyPreview.registeredAddress.line1,
            companiesHouseCompanyPreview.registeredAddress.line2,
            companiesHouseCompanyPreview.registeredAddress.city,
            companiesHouseCompanyPreview.registeredAddress.postcode,
            companiesHouseCompanyPreview.registeredAddress.country,
        ].filter(function (x) { return !!x; });
        return registeredAddressElements.join(', ');
    }, [companiesHouseCompanyPreview]);
    return (React.createElement("div", { className: "companies-house-company-preview" },
        React.createElement("div", { className: "flex-container flex-center" },
            React.createElement("span", null,
                React.createElement(FontAwesomeIcon, { icon: faBuilding })),
            React.createElement("div", null,
                React.createElement("small", null, companiesHouseCompanyPreview.companyNumber),
                React.createElement("h3", null, companiesHouseCompanyPreview.companyName))),
        React.createElement(LabelValuePair, { label: "Created on", value: moment(companiesHouseCompanyPreview.createdAtDate).format('DD MMM YYYY') }),
        React.createElement("div", { className: "multiline-label-value-pair" },
            React.createElement(LabelValuePair, { label: "Registered address", value: registeredAddressString })),
        React.createElement("div", { className: "multiline-label-value-pair" },
            React.createElement(LabelValuePair, { label: "Directors", value: companiesHouseCompanyPreview.directorsNames.join(' | ') }))));
};
export default CompaniesHouseCompanyPreview;
