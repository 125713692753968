import React from 'react';
import ContentPanel from '../contentPanel/ContentPanel';
import './TitledContentPanel.sass';
var TitledContentPanel = function (_a) {
    var title = _a.title, children = _a.children, className = _a.className, _b = _a.hasBoxShadow, hasBoxShadow = _b === void 0 ? true : _b;
    return (React.createElement(ContentPanel, { className: "titled-content-panel " + (className ? className : ''), hasBoxShadow: hasBoxShadow },
        React.createElement("div", { className: "title" }, typeof title === 'string' ? React.createElement("h4", null, title) : title),
        React.createElement("hr", null),
        React.createElement("div", { className: "content" }, children)));
};
export default TitledContentPanel;
