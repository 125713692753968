import React, { useMemo } from 'react';
import LabelValuePair from '../labelValuePair/LabelValuePair';
import JobOverviewPaidStamp from './JobOverviewPaidStamp';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { hasSubmittedFinanceApplication } from '@payaca/helpers/jobFinanceApplicationHelper';
import { getCompletedDepositPaymentValueFromJobPayments, getTotalCompletedPaymentValueFromJobPayments, } from '@payaca/helpers/jobPaymentHelper';
import { isInvoiceConfirmedPaid } from '@payaca/helpers/jobHelperV2';
var JobOverviewTotals = function (_a) {
    var _b = _a.hasAnyReverseChargeVat, hasAnyReverseChargeVat = _b === void 0 ? false : _b, isQuote = _a.isQuote, job = _a.job, jobPayments = _a.jobPayments, jobFinanceInformation = _a.jobFinanceInformation;
    var isJobFinanceSubmitted = useMemo(function () { return hasSubmittedFinanceApplication(jobFinanceInformation); }, [jobFinanceInformation]);
    var completedDepositPaymentValue = useMemo(function () {
        return getCompletedDepositPaymentValueFromJobPayments(jobPayments);
    }, [jobPayments]);
    var totalCompletedPaymentValue = useMemo(function () {
        return getTotalCompletedPaymentValueFromJobPayments(jobPayments);
    }, [jobPayments]);
    var minimumDepositRequired = useMemo(function () {
        return (isQuote &&
            !!job.calculatedMinimumDepositAmount &&
            job.showBespokeFinancePlans &&
            !isJobFinanceSubmitted);
    }, [isQuote, job, isJobFinanceSubmitted]);
    var depositRequired = useMemo(function () {
        return !!(isQuote &&
            job.calculatedDepositAmount &&
            completedDepositPaymentValue < job.calculatedDepositAmount &&
            (job.showBespokeFinancePlans ? isJobFinanceSubmitted : true));
    }, [job, isJobFinanceSubmitted, isQuote, completedDepositPaymentValue]);
    var amountDue = useMemo(function () {
        return job.total - totalCompletedPaymentValue;
    }, [job, totalCompletedPaymentValue]);
    var shouldRenderJobOverviewPaidStamp = useMemo(function () { return !jobFinanceInformation && isInvoiceConfirmedPaid(job, jobPayments); }, [job, jobPayments, jobFinanceInformation]);
    var depositPercentage = useMemo(function () {
        // submitted zero percent job with deposit - show deposit percentage
        if (job.showBespokeFinancePlans &&
            isJobFinanceSubmitted &&
            job.depositAmount) {
            var depositPercentageFromAmount = Math.round(((job.calculatedDepositAmount || 0) / job.total) * 1000) /
                10;
            return " (" + (job.depositPercentage
                ? job.depositPercentage
                : depositPercentageFromAmount) + "%)";
        }
        else {
            return job.depositPercentage ? " (" + job.depositPercentage + "%)" : '';
        }
    }, [isJobFinanceSubmitted, job]);
    return (React.createElement("div", { className: "job-overview-totals" },
        shouldRenderJobOverviewPaidStamp && React.createElement(JobOverviewPaidStamp, null),
        hasAnyReverseChargeVat && (React.createElement("div", { className: "reverse-charge-instructions" }, "Customer to account to HMRC for the reverse charge output tax on the VAT exclusive price of items marked \u2018reverse charge\u2019 at the relevant VAT rate as shown above.")),
        React.createElement("div", { className: "totals-container" },
            React.createElement(LabelValuePair, { label: "Subtotal", value: currencyPrice(job.subtotalIncMarkup), suffixLabelWith: "" }),
            !!job.calculatedDiscountAmount && (React.createElement(LabelValuePair, { label: "Discount" + (job.discountPercentage ? " (" + job.discountPercentage + "%)" : ''), value: currencyPrice(job.calculatedDiscountAmount), suffixLabelWith: "" })),
            job.isAnyVatIncluded && (React.createElement(LabelValuePair, { label: "VAT", value: currencyPrice(job.vatTotal), suffixLabelWith: "" })),
            job.hasAnyCisDeduction && (React.createElement(LabelValuePair, { label: "CIS suffered", value: currencyPrice(-job.cisTotal), suffixLabelWith: "" })),
            React.createElement(LabelValuePair, { label: "Total", value: currencyPrice(job.total), suffixLabelWith: "" }),
            minimumDepositRequired && (React.createElement("div", { className: "separator" },
                React.createElement(LabelValuePair, { label: "Minimum deposit" + (job.minimumDepositPercentage
                        ? " (" + job.minimumDepositPercentage + "%)"
                        : ''), value: job.calculatedMinimumDepositAmount
                        ? currencyPrice(job.calculatedMinimumDepositAmount)
                        : '', suffixLabelWith: "" }))),
            depositRequired && !completedDepositPaymentValue && (React.createElement("div", { className: "separator" },
                React.createElement(LabelValuePair, { label: "Deposit" + depositPercentage, value: job.calculatedDepositAmount
                        ? currencyPrice(job.calculatedDepositAmount)
                        : '', suffixLabelWith: "" }))),
            !isQuote && !!totalCompletedPaymentValue && (React.createElement("div", { className: "separator" },
                React.createElement(LabelValuePair, { label: "Amount paid", value: currencyPrice(totalCompletedPaymentValue), suffixLabelWith: "" }))),
            isQuote && !!completedDepositPaymentValue && (React.createElement("div", { className: "separator" },
                React.createElement(LabelValuePair, { label: "Deposit paid", value: currencyPrice(completedDepositPaymentValue), suffixLabelWith: "" }))),
            depositRequired && !!completedDepositPaymentValue && (React.createElement(LabelValuePair, { label: "Deposit due", value: currencyPrice((job.calculatedDepositAmount || 0) - completedDepositPaymentValue), suffixLabelWith: "" })),
            !isQuote && !!amountDue && (React.createElement(LabelValuePair, { label: "Amount due", value: currencyPrice(amountDue), suffixLabelWith: "" })))));
};
export default JobOverviewTotals;
