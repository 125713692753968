import React from 'react';
import ProfilePageWrapper from '../pageWrappers/profilePageWrapper/ProfilePageWrapper';
import { CompanySettings, CompanySettingsSaveButton } from '@/ui/components';
import './CompanySettingsPage.sass';
var CompanySettingsPage = function () {
    return (React.createElement(ProfilePageWrapper, { title: "My Company", className: "company-settings-page" },
        React.createElement(CompanySettings, null),
        React.createElement("div", { className: "button-container" },
            React.createElement(CompanySettingsSaveButton, null))));
};
export default CompanySettingsPage;
