import { action } from 'typesafe-actions';
import { SubscriptionActionTypes, } from './subscriptionTypes';
export var requestGetProducts = function () {
    return action(SubscriptionActionTypes.REQUEST_GET_PRODUCTS);
};
export var clearProducts = function () {
    return action(SubscriptionActionTypes.CLEAR_PRODUCTS);
};
export var getProductsSuccess = function (products) {
    return action(SubscriptionActionTypes.GET_PRODUCTS_SUCCESS, {
        products: products,
    });
};
export var getProductsFailure = function (error) {
    return action(SubscriptionActionTypes.GET_PRODUCTS_FAILURE, null, null, error);
};
export var requestGetAccountSubscription = function () {
    return action(SubscriptionActionTypes.REQUEST_GET_ACCOUNT_SUBSCRIPTION);
};
export var clearAccountSubscription = function () {
    return action(SubscriptionActionTypes.CLEAR_ACCOUNT_SUBSCRIPTION);
};
export var getAccountSubscriptionSuccess = function (accountSubscription) {
    return action(SubscriptionActionTypes.GET_ACCOUNT_SUBSCRIPTION_SUCCESS, {
        accountSubscription: accountSubscription,
    });
};
export var getAccountSubscriptionFailure = function (error) {
    return action(SubscriptionActionTypes.GET_ACCOUNT_SUBSCRIPTION_FAILURE, null, null, error);
};
export var requestCreateSubscription = function (createSubscriptionRequestData) {
    return action(SubscriptionActionTypes.REQUEST_CREATE_SUBSCRIPTION, {
        createSubscriptionRequestData: createSubscriptionRequestData,
    });
};
export var createSubscriptionSuccess = function () {
    return action(SubscriptionActionTypes.CREATE_SUBSCRIPTION_SUCCESS);
};
export var createSubscriptionFailure = function (error) {
    return action(SubscriptionActionTypes.CREATE_SUBSCRIPTION_FAILURE, null, null, error);
};
export var requestUpdateSubscription = function (updateSubscriptionRequestData) {
    return action(SubscriptionActionTypes.REQUEST_UPDATE_SUBSCRIPTION, {
        updateSubscriptionRequestData: updateSubscriptionRequestData,
    });
};
export var updateSubscriptionSuccess = function () {
    return action(SubscriptionActionTypes.UPDATE_SUBSCRIPTION_SUCCESS);
};
export var updateSubscriptionFailure = function (error) {
    return action(SubscriptionActionTypes.UPDATE_SUBSCRIPTION_FAILURE, null, null, error);
};
export var requestRestoreSubscription = function () {
    return action(SubscriptionActionTypes.REQUEST_RESTORE_SUBSCRIPTION);
};
export var restoreSubscriptionSuccess = function () {
    return action(SubscriptionActionTypes.RESTORE_SUBSCRIPTION_SUCCESS);
};
export var restoreSubscriptionFailure = function (error) {
    return action(SubscriptionActionTypes.RESTORE_SUBSCRIPTION_FAILURE, null, null, error);
};
export var requestUpdatePaymentMethod = function (updatePaymentMethodRequestData) {
    return action(SubscriptionActionTypes.REQUEST_UPDATE_PAYMENT_METHOD, {
        updatePaymentMethodRequestData: updatePaymentMethodRequestData,
    });
};
export var updatePaymentMethodSuccess = function () {
    return action(SubscriptionActionTypes.UPDATE_PAYMENT_METHOD_SUCCESS);
};
export var updatePaymentMethodFailure = function (error) {
    return action(SubscriptionActionTypes.UPDATE_PAYMENT_METHOD_FAILURE, null, null, error);
};
export var requestGetSubscriptionPaymentPreview = function (getSubscriptionPaymentPreviewData) {
    return action(SubscriptionActionTypes.REQUEST_GET_SUBSCRIPTION_PAYMENT_PREVIEW, {
        getSubscriptionPaymentPreviewData: getSubscriptionPaymentPreviewData,
    });
};
export var getSubscriptionPaymentPreviewSuccess = function (subscriptionPaymentPreview) {
    return action(SubscriptionActionTypes.GET_SUBSCRIPTION_PAYMENT_PREVIEW_SUCCESS, {
        subscriptionPaymentPreview: subscriptionPaymentPreview,
    });
};
export var getSubscriptionPaymentPreviewFailure = function (error) {
    return action(SubscriptionActionTypes.GET_SUBSCRIPTION_PAYMENT_PREVIEW_FAILURE, null, null, error);
};
export var clearSubscriptionPaymentPreview = function () {
    return action(SubscriptionActionTypes.CLEAR_SUBSCRIPTION_PAYMENT_PREVIEW);
};
