var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useMemo, useState } from 'react';
import { SelectValueModal } from '@/ui/components';
import TotalsEditableValue from './TotalsEditableValue';
import TotalsFixedValue from './TotalsFixedValue';
import { ValueModal } from '@/helpers/modalHelper';
import { currencyPrice } from '@/helpers/financeHelper';
import { calculateNetProfitFromJobLineItems } from '@bit/payaca-tech.payaca-core.helpers.job-totals';
import './JobTotalsAndValueModifiersControl.css';
import { useSelector } from 'react-redux';
import { isInvoice } from '@/helpers/jobHelper';
import { getCompletedDepositPaymentValueFromJobPayments } from '@bit/payaca-tech.payaca-core.helpers.job-payment';
var JobTotalsAndValueModifiersControl = function (_a) {
    var jobId = _a.jobId, onChange = _a.onChange, updateJobFormState = _a.updateJobFormState, showZeroPercentFinance = _a.showZeroPercentFinance;
    var _b = useState(false), showSelectValueModal = _b[0], setShowSelectValueModal = _b[1];
    var _c = useState({}), modalOptions = _c[0], setModalOptions = _c[1];
    var job = useSelector(function (state) {
        var _a;
        return state.jobsStore.jobs && ((_a = state.jobsStore.jobs[jobId]) === null || _a === void 0 ? void 0 : _a.entity);
    });
    var financePlans = useSelector(function (state) {
        return state.users.financePlans;
    });
    var dealId = useMemo(function () {
        return job === null || job === void 0 ? void 0 : job.dealId;
    }, [job]);
    var jobPayments = useSelector(function (state) {
        var _a;
        var deal = state.deals.deals && ((_a = state.deals.deals[dealId]) === null || _a === void 0 ? void 0 : _a.entity);
        var jobPaymentIds = (deal === null || deal === void 0 ? void 0 : deal.jobPaymentIds) || [];
        var payments = jobPaymentIds.map(function (jobPaymentId) {
            var _a;
            return (_a = state.jobPayments.jobPayments[jobPaymentId]) === null || _a === void 0 ? void 0 : _a.entity;
        });
        return payments.filter(function (x) { return !!x; });
    });
    var jobLineItems = useSelector(function (state) {
        var jobLineItemIds = (job === null || job === void 0 ? void 0 : job.jobLineItemIds) || [];
        var jobLineItems = jobLineItemIds.map(function (jobLineItemId) {
            var _a;
            return (state.jobsStore.jobLineItems && ((_a = state.jobsStore.jobLineItems[jobLineItemId]) === null || _a === void 0 ? void 0 : _a.entity));
        });
        return jobLineItems.filter(function (x) { return !!x; });
    });
    var isProposition = useMemo(function () {
        if (!(job === null || job === void 0 ? void 0 : job.status))
            return false;
        return !isInvoice(job.status);
    }, [job === null || job === void 0 ? void 0 : job.status]);
    var completedDepositPaymentValue = useMemo(function () {
        return getCompletedDepositPaymentValueFromJobPayments(jobPayments);
    }, [jobPayments]);
    var hasAnyRequiredItems = useMemo(function () {
        return jobLineItems.find(function (jobLineItem) { return !jobLineItem.isMultipleChoice && !jobLineItem.isOptional; });
    }, [jobLineItems]);
    // disable markup when job has no required items
    var disableMarkupAmount = useMemo(function () {
        return !hasAnyRequiredItems && isProposition;
    }, [hasAnyRequiredItems, isProposition]);
    var showMarkupModalInfoText = useMemo(function () {
        return (jobLineItems.some(function (jobLineItem) { return jobLineItem.isMultipleChoice || jobLineItem.isOptional; }) && !disableMarkupAmount);
    }, [jobLineItems, disableMarkupAmount]);
    useEffect(function () {
        if (disableMarkupAmount && updateJobFormState.markupAmount) {
            onChange({
                markupAmount: null,
            });
        }
    }, [onChange, disableMarkupAmount, updateJobFormState.markupAmount]);
    // calculate job net profit and net profit margin
    var jobNetProfit = useMemo(function () {
        var netProfit = jobLineItems && calculateNetProfitFromJobLineItems(jobLineItems);
        return netProfit;
    }, [jobLineItems]);
    var jobLineItemsLength = useMemo(function () { return jobLineItems === null || jobLineItems === void 0 ? void 0 : jobLineItems.length; }, [jobLineItems === null || jobLineItems === void 0 ? void 0 : jobLineItems.length]);
    var jobSubtotal = useMemo(function () { return job === null || job === void 0 ? void 0 : job.subtotal; }, [job === null || job === void 0 ? void 0 : job.subtotal]);
    useEffect(function () {
        // clear markup if jobsubtatotal changes to £0 and there are items on job
        if (!!jobLineItemsLength && !jobSubtotal) {
            onChange({
                markupAmount: null,
                markupPercentage: null,
            });
        }
    }, [jobLineItemsLength, jobSubtotal]);
    var minimumDepositMaximumValuePercentage = useMemo(function () {
        return (financePlans || []).reduce(function (acc, curr) {
            return curr.maxDeposit > acc ? curr.maxDeposit : acc;
        }, 0);
    }, [financePlans]);
    var valueLines = useMemo(function () {
        if (!(jobLineItems === null || jobLineItems === void 0 ? void 0 : jobLineItems.length))
            return [];
        return [
            {
                hide: false,
                separator: false,
                title: 'Subtotal',
                value: currencyPrice(job.subtotal),
            },
            {
                separator: false,
                hide: !!jobLineItems.length && !job.subtotal && isProposition,
                editValueTitle: 'Markup',
                emptyValueTitle: 'Add markup',
                onClick: function () {
                    setShowSelectValueModal(true);
                    setModalOptions(__assign(__assign({}, ValueModal.MARKUP), { 
                        // disallow markup amount if there are no mandatory items on job #hardmath
                        disallowAmount: disableMarkupAmount, infoText: showMarkupModalInfoText && ValueModal.MARKUP.infoText }));
                },
                value: job.calculatedMarkupAmount != undefined
                    ? currencyPrice(job.calculatedMarkupAmount)
                    : null,
                infoIconText: ValueModal.MARKUP.informationText,
                valuePrefix: '+',
            },
            {
                editValueTitle: 'Discount',
                emptyValueTitle: 'Add discount',
                onClick: function () {
                    setShowSelectValueModal(true);
                    setModalOptions(ValueModal.DISCOUNT);
                },
                value: job.calculatedDiscountAmount != undefined
                    ? currencyPrice(job.calculatedDiscountAmount)
                    : null,
                valuePrefix: '-',
            },
            {
                hide: !job.isAnyVatIncluded,
                title: 'VAT',
                value: job.vatTotal != undefined ? currencyPrice(job.vatTotal) : null,
                valuePrefix: '+',
            },
            {
                hide: !job.hasAnyCisDeduction,
                title: 'CIS suffered',
                value: job.cisTotal != undefined ? currencyPrice(job.cisTotal) : null,
                valuePrefix: '-',
            },
            {
                separator: true,
            },
            {
                title: 'Total',
                value: job.total != undefined ? currencyPrice(job.total) : null,
                detailClass: 'total',
            },
            {
                separator: completedDepositPaymentValue,
            },
            {
                hide: !completedDepositPaymentValue,
                title: 'Amount paid',
                value: currencyPrice(completedDepositPaymentValue),
                detailClass: 'total',
            },
            {
                separator: isProposition,
            },
            {
                hide: !isProposition || showZeroPercentFinance,
                editValueTitle: 'Deposit',
                emptyValueTitle: 'Request deposit',
                onClick: function () {
                    setShowSelectValueModal(true);
                    setModalOptions(ValueModal.DEPOSIT);
                },
                value: job.calculatedDepositAmount != undefined
                    ? currencyPrice(job.calculatedDepositAmount)
                    : null,
            },
            {
                hide: !isProposition || !showZeroPercentFinance,
                editValueTitle: 'Minimum deposit',
                emptyValueTitle: 'Request minimum deposit',
                onClick: function () {
                    setShowSelectValueModal(true);
                    setModalOptions(__assign(__assign({}, ValueModal.MINIMUM_DEPOSIT), { maximumValuePercentage: minimumDepositMaximumValuePercentage }));
                },
                value: job.calculatedMinimumDepositAmount != undefined
                    ? currencyPrice(job.calculatedMinimumDepositAmount)
                    : null,
            },
            {
                separator: (jobNetProfit === null || jobNetProfit === void 0 ? void 0 : jobNetProfit.netProfit) || (jobNetProfit === null || jobNetProfit === void 0 ? void 0 : jobNetProfit.netProfitMargin),
            },
            {
                title: 'Net profit',
                value: (jobNetProfit === null || jobNetProfit === void 0 ? void 0 : jobNetProfit.netProfit) && currencyPrice(jobNetProfit === null || jobNetProfit === void 0 ? void 0 : jobNetProfit.netProfit),
                hide: !(jobNetProfit === null || jobNetProfit === void 0 ? void 0 : jobNetProfit.netProfit),
            },
            {
                title: 'Net profit margin',
                value: (jobNetProfit === null || jobNetProfit === void 0 ? void 0 : jobNetProfit.netProfitMargin) &&
                    parseFloat(jobNetProfit === null || jobNetProfit === void 0 ? void 0 : jobNetProfit.netProfitMargin.toFixed(2)) + "%",
                hide: !(jobNetProfit === null || jobNetProfit === void 0 ? void 0 : jobNetProfit.netProfitMargin),
            },
        ];
    }, [
        disableMarkupAmount,
        job,
        jobLineItems,
        isProposition,
        completedDepositPaymentValue,
        showZeroPercentFinance,
        jobNetProfit,
        minimumDepositMaximumValuePercentage,
    ]);
    var selectValueModal = useMemo(function () {
        return (React.createElement(SelectValueModal, { open: true, onClose: function () { return setShowSelectValueModal(false); }, valueAmount: updateJobFormState[modalOptions.valueAmountName], valuePercentage: updateJobFormState[modalOptions.valuePercentageName], onChange: onChange, modalOptions: modalOptions }));
    }, [updateJobFormState, modalOptions, onChange]);
    return (React.createElement("div", { className: "job-totals-and-value-modifiers-control" },
        valueLines.map(function (line, i) {
            if (line.separator) {
                return React.createElement("hr", { key: i });
            }
            else if (line.hide) {
                return null;
            }
            else if (line.title && line.value) {
                return React.createElement(TotalsFixedValue, __assign({}, line, { key: i }));
            }
            else {
                return React.createElement(TotalsEditableValue, __assign({}, line, { key: i }));
            }
        }),
        showSelectValueModal && selectValueModal));
};
export default JobTotalsAndValueModifiersControl;
