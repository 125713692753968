import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import RequiredSettingsDropdown, { getRequiredSettingsType, RequiredSettingsType, } from '../requiredSettingsDropdown/RequiredSettingsDropdown';
import './LineItemGroupLineItemControl.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Checkbox } from '@payaca/components/checkbox';
import { RadioButton } from '@payaca/components/radioButton';
import QuantityControl from '../manageSubscription/quantityControl/QuantityControl';
import { currencyPrice } from '@/helpers/financeHelper';
import { getVatLabelFromVatSettings } from '@payaca/helpers/vatHelper';
var LineItemGroupLineItemControl = function (_a) {
    var lineItemGroupLineItem = _a.lineItemGroupLineItem, fieldNamePrefix = _a.fieldNamePrefix, onChange = _a.onChange, onTouch = _a.onTouch, onDelete = _a.onDelete;
    var lineItem = useSelector(function (state) {
        var _a;
        return (_a = state.lineItemsV2.lineItems[lineItemGroupLineItem.lineItemId]) === null || _a === void 0 ? void 0 : _a.entity;
    });
    var isSelectedContainer = useMemo(function () {
        var requiredSettingsType = getRequiredSettingsType(lineItemGroupLineItem);
        return (requiredSettingsType !== RequiredSettingsType.REQUIRED && (React.createElement("div", { className: "is-selected-container" },
            requiredSettingsType === RequiredSettingsType.OPTIONAL && (React.createElement(Checkbox, { isChecked: lineItemGroupLineItem.isSelected, onChange: function () {
                    var _a;
                    var updateItem = (_a = {},
                        _a[fieldNamePrefix + ".isSelected"] = !lineItemGroupLineItem.isSelected,
                        _a);
                    onChange(updateItem);
                } })),
            requiredSettingsType === RequiredSettingsType.MULTIPLE_CHOICE && (React.createElement(RadioButton, { isSelected: lineItemGroupLineItem.isSelected, onClick: function () {
                    var _a;
                    if (lineItemGroupLineItem.isSelected)
                        return;
                    var updateItem = (_a = {},
                        _a[fieldNamePrefix + ".isSelected"] = !lineItemGroupLineItem.isSelected,
                        _a);
                    onChange(updateItem);
                } })))));
    }, [onChange, lineItemGroupLineItem, lineItemGroupLineItem.isSelected]);
    return (React.createElement("div", { className: "line-item-group-line-item-control" },
        isSelectedContainer,
        React.createElement("div", { className: "line-item-group-line-item-control-inner" },
            React.createElement("div", { className: "actions-container" },
                React.createElement("div", { className: "delete-container" },
                    React.createElement(FontAwesomeIcon, { icon: faTimes, onClick: onDelete }))),
            React.createElement("div", { className: "name-description-container" },
                React.createElement("p", null,
                    React.createElement("strong", null, lineItem === null || lineItem === void 0 ? void 0 : lineItem.name)),
                React.createElement("p", null, lineItem === null || lineItem === void 0 ? void 0 : lineItem.description)),
            React.createElement("hr", null),
            React.createElement("div", { className: "price-quantity-required-container flex-container flex-center" },
                React.createElement("div", null,
                    React.createElement(RequiredSettingsDropdown, { fieldNamePrefix: fieldNamePrefix, value: {
                            isMultipleChoice: lineItemGroupLineItem.isMultipleChoice,
                            isOptional: lineItemGroupLineItem.isOptional,
                            isSelected: lineItemGroupLineItem.isSelected,
                        }, onChange: function (value) {
                            onChange(value);
                        } })),
                React.createElement("div", { className: "flex-grow" }),
                React.createElement("div", { className: "quantity-control-container flex-container flex-center" },
                    React.createElement("label", null, "Qty"),
                    React.createElement(QuantityControl, { value: lineItemGroupLineItem.quantity, minimumValue: 1, onChange: function (value) {
                            var _a;
                            return onChange((_a = {}, _a[fieldNamePrefix + ".quantity"] = value, _a));
                        }, showInputField: true })),
                React.createElement("div", { className: "price-and-vat-container" },
                    React.createElement("div", null,
                        React.createElement("strong", null, currencyPrice(lineItem.price))),
                    React.createElement("div", null,
                        React.createElement("small", null, getVatLabelFromVatSettings(lineItem.vatAmount, !!lineItem.isReverseChargeVat, !!lineItem.isVatIncluded))))))));
};
export default LineItemGroupLineItemControl;
