import { getAttemptedDepositPaymentValueFromJobPayments, getBacsPendingDepositPaymentsFromJobPayments, getBacsPendingPaymentsExcludingDepositFromJobPayments, getCompletedDepositPaymentValueFromJobPayments, getTotalAttemptedPaymentValueFromJobPayments, getTotalCompletedPaymentValueFromJobPayments, } from './jobPaymentHelper';
import { isEstimate, isInvoice, isInvoiceOverdue, isQuote, isQuoteOrEstimate, isSentQuoteEstimate, jobHasSent, } from './jobStatusHelper';
import { JobStatus, ReadableJobStatus } from '../types/jobTypes';
import { hasRejectedFinanceApplication, hasSubmittedFinanceApplication, } from './jobFinanceApplicationHelper';
import { getJobContactFromCustomer } from './customerHelper';
export var getInitialUpdateJobRequestDataFromBaseJob = function (baseJob) {
    return {
        jobId: baseJob.id,
        status: baseJob.status,
        customReference: baseJob.customReference,
        depositPercentage: baseJob.depositPercentage,
        depositAmount: baseJob.depositAmount,
        minimumDepositPercentage: baseJob.minimumDepositPercentage,
        minimumDepositAmount: baseJob.minimumDepositAmount,
        discountPercentage: baseJob.discountPercentage,
        discountAmount: baseJob.discountAmount,
        markupPercentage: baseJob.markupPercentage,
        markupAmount: baseJob.markupAmount,
        showFinanceOptions: baseJob.showFinanceOptions,
        showSelinaFinance: baseJob.showSelinaFinance,
        showBespokeFinancePlans: baseJob.showBespokeFinancePlans,
        showStripePaymentOption: baseJob.showStripePaymentOption,
        showBacsPaymentOption: baseJob.showBacsPaymentOption,
        jobNotes: baseJob.jobNotes,
        jobDescription: baseJob.jobDescription,
        hideItemPrices: baseJob.hideItemPrices,
        validUntil: baseJob.validUntil,
        dueAt: baseJob.dueAt,
        assignedToUserId: baseJob.assignedToUserId,
        contactId: baseJob.contactId,
    };
};
export var isDepositPaymentRequired = function (job) {
    return (isQuoteOrEstimate(job.status) &&
        (!!job.depositAmount || !!job.depositPercentage));
};
export var isInvoicePaymentRequired = function (job) {
    return isInvoice(job.status) && job.total > 0;
};
export var isSatisfactionNoteSignatureRequired = function (job, jobFinanceInformation) {
    if (!job.showBespokeFinancePlans) {
        return false;
    }
    return (!!jobFinanceInformation &&
        !!jobFinanceInformation.jobCompletedAt &&
        !jobFinanceInformation.satisfactionNoteSignedAt);
};
export var isDepositAttemptedPaid = function (jobTotals, jobPayments) {
    var attemptedDepositPaymentValue = getAttemptedDepositPaymentValueFromJobPayments(jobPayments);
    var remainingDeposit = (jobTotals.calculatedDepositAmount || 0) - attemptedDepositPaymentValue;
    return remainingDeposit <= 0;
};
export var isDepositConfirmedPaid = function (jobTotals, jobPayments) {
    var completedDepositPaymentValue = getCompletedDepositPaymentValueFromJobPayments(jobPayments);
    var remainingDeposit = (jobTotals.calculatedDepositAmount || 0) - completedDepositPaymentValue;
    return remainingDeposit <= 0;
};
export var isInvoiceAttemptedPaid = function (jobTotals, jobPayments) {
    var attemptedPaymentValue = getTotalAttemptedPaymentValueFromJobPayments(jobPayments);
    var remainingTotal = jobTotals.total - attemptedPaymentValue;
    return remainingTotal <= 0;
};
export var isInvoiceConfirmedPaid = function (jobTotals, jobPayments) {
    var attemptedPaymentValue = getTotalCompletedPaymentValueFromJobPayments(jobPayments);
    var remainingTotal = jobTotals.total - attemptedPaymentValue;
    return remainingTotal <= 0;
};
export var hasBacsPendingPayments = function (job, jobPayments) {
    var jobIsQuoteOrEstimate = isQuoteOrEstimate(job.status);
    if (jobIsQuoteOrEstimate) {
        return !!getBacsPendingDepositPaymentsFromJobPayments(jobPayments).length;
    }
    return !!getBacsPendingPaymentsExcludingDepositFromJobPayments(jobPayments)
        .length;
};
export var isJobAccepted = function (job) {
    return isQuoteOrEstimate(job.status) && !!job.acceptedAt;
};
export var isJobDeclined = function (job) {
    return isQuoteOrEstimate(job.status) && !!job.declinedAt;
};
export var getReadableJobStatus = function (job, jobPayments, jobFinanceInformation, isRelatedInvoiceSent) {
    var isArchived = job.archivedAt;
    var isInactivated = job.inactivatedAt;
    var isZeroPercentJob = job.showBespokeFinancePlans;
    var isJobSentQuoteEstimate = isSentQuoteEstimate(job.status);
    var isPaymentPending = hasBacsPendingPayments(job, jobPayments);
    // Return statuses relating to invoices and invoiced quotes
    if (isArchived) {
        return ReadableJobStatus.ARCHIVED;
    }
    if (isInactivated) {
        return ReadableJobStatus.INACTIVE;
    }
    if (isRelatedInvoiceSent) {
        return ReadableJobStatus.INVOICED;
    }
    if (job.status === JobStatus.INVOICED) {
        if (isZeroPercentJob) {
            return ReadableJobStatus.PAYOUT_APPROVED;
        }
        if (isInvoiceConfirmedPaid(job, jobPayments)) {
            return ReadableJobStatus.PAID;
        }
        if (isPaymentPending) {
            return ReadableJobStatus.PAYMENT_PENDING;
        }
        if (isInvoiceOverdue(job.dueAt || null, job.status) && !job.bouncedAt) {
            return ReadableJobStatus.OVERDUE;
        }
    }
    if (job.status === JobStatus.PAID) {
        if (isZeroPercentJob) {
            return ReadableJobStatus.PAYOUT_APPROVED;
        }
        return ReadableJobStatus.PAID;
    }
    // return statuses relating to standard quotes
    if ((isJobSentQuoteEstimate || job.status === JobStatus.ACCEPTED) &&
        !isZeroPercentJob) {
        if (!!job.depositAmount && isDepositConfirmedPaid(job, jobPayments)) {
            return ReadableJobStatus.DEPOSIT_PAID;
        }
        if (isPaymentPending) {
            return ReadableJobStatus.PAYMENT_PENDING;
        }
        if (isJobAccepted(job) &&
            !!job.calculatedDepositAmount &&
            !isDepositConfirmedPaid(job, jobPayments)) {
        }
        if (isJobAccepted(job)) {
            if (!!job.calculatedDepositAmount &&
                !isDepositConfirmedPaid(job, jobPayments)) {
                return ReadableJobStatus.DEPOSIT_DUE;
            }
            else {
                return ReadableJobStatus.ACCEPTED;
            }
        }
        if (isJobDeclined(job)) {
            return ReadableJobStatus.DECLINED;
        }
    }
    // return statuses relating to zero% quotes
    if ((isJobSentQuoteEstimate || job.status === JobStatus.ACCEPTED) &&
        isZeroPercentJob &&
        jobFinanceInformation) {
        var isJobDepositPaymentRequired = isDepositPaymentRequired(job);
        if (jobFinanceInformation.satisfactionNoteRejectedAt) {
            return ReadableJobStatus.JOB_UNSATISFACTORY;
        }
        if (jobFinanceInformation.jobCompletedAt) {
            return ReadableJobStatus.JOB_COMPLETE;
        }
        if (!!job.depositAmount && isDepositConfirmedPaid(job, jobPayments)) {
            return ReadableJobStatus.DEPOSIT_PAID;
        }
        if (isPaymentPending) {
            return ReadableJobStatus.PAYMENT_PENDING;
        }
        if (jobFinanceInformation.customerAgreementSignedAt) {
            if (isJobDepositPaymentRequired) {
                return ReadableJobStatus.FINANCE_SIGNED;
            }
            return ReadableJobStatus.FINANCE_COMPLETE;
        }
        if (jobFinanceInformation.rejectedAt) {
            return ReadableJobStatus.FINANCE_REJECTED;
        }
        if (jobFinanceInformation.approvedAt) {
            return ReadableJobStatus.FINANCE_APPROVED;
        }
    }
    // return standard statuses
    if (job.bouncedAt) {
        return ReadableJobStatus.BOUNCED;
    }
    if (job.viewedAt) {
        return ReadableJobStatus.VIEWED;
    }
    switch (job.status) {
        case JobStatus.QUOTED:
        case JobStatus.ESTIMATED:
        case JobStatus.INVOICED:
            return ReadableJobStatus.SENT;
        default:
            return ReadableJobStatus.DRAFT;
    }
};
export var canMarkJobAsAccepted = function (job) {
    if (job.showBespokeFinancePlans)
        return false;
    return (isSentQuoteEstimate(job.status) &&
        !isJobAccepted(job) &&
        !isJobDeclined(job));
};
export var canMarkJobAsDeclined = function (job) {
    if (job.showBespokeFinancePlans)
        return false;
    return (isSentQuoteEstimate(job.status) &&
        !isJobDeclined(job) &&
        !isJobAccepted(job));
};
export var canConvertJobToInvoice = function (job, hasRelatedInvoice) {
    if (job.showBespokeFinancePlans)
        return false;
    return isQuoteOrEstimate(job.status) && !hasRelatedInvoice;
};
export var canMarkJobAsComplete = function (job, jobPayments, jobFinanceInformation) {
    if (!job.showBespokeFinancePlans)
        return false;
    if (isDepositPaymentRequired(job) &&
        !isDepositConfirmedPaid(job, jobPayments))
        return false;
    return (!!jobFinanceInformation &&
        !!jobFinanceInformation.approvedAt &&
        !!jobFinanceInformation.customerAgreementSignedAt &&
        !jobFinanceInformation.jobCompletedAt);
};
export var getReadableJobStatusStyle = function (status) {
    switch (status) {
        case ReadableJobStatus.ARCHIVED:
        case ReadableJobStatus.INACTIVE:
            return {
                colour: '#ffffff',
                backgroundColour: '#919191',
            };
        case ReadableJobStatus.PAID:
        case ReadableJobStatus.DEPOSIT_PAID:
        case ReadableJobStatus.ACCEPTED:
        case ReadableJobStatus.FINANCE_COMPLETE:
        case ReadableJobStatus.PAYOUT_APPROVED:
            return {
                colour: '#263E59',
                backgroundColour: '#75E582',
            };
        case ReadableJobStatus.DEPOSIT_DUE:
        case ReadableJobStatus.PAYMENT_PENDING:
        case ReadableJobStatus.VIEWED:
            return {
                backgroundColour: '#FABB00',
                colour: '#263E59',
            };
        case ReadableJobStatus.OVERDUE:
        case ReadableJobStatus.FINANCE_REJECTED:
        case ReadableJobStatus.JOB_UNSATISFACTORY:
        case ReadableJobStatus.DECLINED:
            return {
                colour: '#ffffff',
                backgroundColour: '#D93A3A',
            };
        case ReadableJobStatus.BOUNCED:
            return {
                backgroundColour: '#7E27BE',
                colour: '#ffffff',
            };
        case ReadableJobStatus.INVOICED:
        case ReadableJobStatus.SENT:
        case ReadableJobStatus.FINANCE_APPROVED:
        case ReadableJobStatus.FINANCE_SIGNED:
        case ReadableJobStatus.JOB_COMPLETE:
            return {
                backgroundColour: '#DBE5F0',
                colour: '#263E59',
            };
        case ReadableJobStatus.DRAFT:
        default:
            return {
                backgroundColour: '#607387',
                colour: '#ffffff',
            };
    }
};
export var canRecordDepositPayment = function (job, jobPayments, jobFinanceInformation) {
    var isJobSentQuoteEstimate = isSentQuoteEstimate(job.status);
    if (!isJobSentQuoteEstimate) {
        return false;
    }
    if (job.showBespokeFinancePlans) {
        if (isDepositPaymentRequired(job) &&
            !isDepositConfirmedPaid(job, jobPayments) &&
            !!(jobFinanceInformation === null || jobFinanceInformation === void 0 ? void 0 : jobFinanceInformation.approvedAt) &&
            !!(jobFinanceInformation === null || jobFinanceInformation === void 0 ? void 0 : jobFinanceInformation.customerAgreementSignedAt))
            return true;
        return false;
    }
    return !isJobDeclined(job);
};
export var canRecordInvoicePayment = function (job) {
    var jobIsQuoteOrEstimate = isQuoteOrEstimate(job.status);
    if (jobIsQuoteOrEstimate)
        return false;
    if (job.showBespokeFinancePlans)
        return false;
    var isSentJob = jobHasSent(job.status);
    return isSentJob;
};
export var shouldShowFinanceOptionsToCustomer = function (job, jobPayments, jobFinanceInformation) {
    // hide finance if job declined
    if (!!job.declinedAt) {
        return false;
    }
    var attemptedPaymentValue = getTotalAttemptedPaymentValueFromJobPayments(jobPayments);
    var remainingTotal = job.total - attemptedPaymentValue;
    var isJobAttemptedFullyPaid = remainingTotal <= 0;
    // hide finance if job is attempted fully paid
    if (isJobAttemptedFullyPaid) {
        return false;
    }
    // hide finance button if zero percent and application not submitted or submitted and not been rejected
    if (job.showBespokeFinancePlans &&
        (!hasSubmittedFinanceApplication(jobFinanceInformation) ||
            (hasSubmittedFinanceApplication(jobFinanceInformation) &&
                !hasRejectedFinanceApplication(jobFinanceInformation)))) {
        return false;
    }
    return job.showFinanceOptions || job.showSelinaFinance;
};
export var getJobType = function (job) {
    if (isInvoice(job.status)) {
        return 'Invoice';
    }
    else if (isQuote(job.status)) {
        return 'Quote';
    }
    else if (isEstimate(job.status)) {
        return 'Estimate';
    }
    else {
        return 'Job';
    }
};
export var canResendJob = function (job, customer, hasRelatedJobInformation) {
    var primaryContact = getJobContactFromCustomer(customer, job.contactId);
    return (jobHasSent(job.status) &&
        !job.archivedAt &&
        (isQuote(job.status) ? !hasRelatedJobInformation : true) &&
        !!(primaryContact === null || primaryContact === void 0 ? void 0 : primaryContact.emailAddress));
};
export var canEditSentJob = function (job, jobPayments, hasRelatedJobInformation, jobFinanceInformation) {
    if ((jobPayments === null || jobPayments === void 0 ? void 0 : jobPayments.length) || jobFinanceInformation) {
        return false;
    }
    if (isSentQuoteEstimate(job.status)) {
        return !hasRelatedJobInformation && !job.acceptedAt;
    }
    else if (job.status === JobStatus.INVOICED) {
        return true;
    }
    return false;
};
export var canUnacceptJob = function (job, jobPayments, jobFinanceInformation, hasRelatedJob) {
    return (isSentQuoteEstimate(job.status) &&
        !!job.acceptedAt &&
        !(jobPayments === null || jobPayments === void 0 ? void 0 : jobPayments.length) &&
        !jobFinanceInformation &&
        !hasRelatedJob);
};
export var getOutstandingPaymentValue = function (job, jobPayments) {
    var value = null;
    var jobIsInvoice = isInvoice(job.status);
    if (jobIsInvoice && !!job.sentAt) {
        if (job.showBespokeFinancePlans) {
            value =
                (job.calculatedDepositAmount || 0) -
                    getCompletedDepositPaymentValueFromJobPayments(jobPayments);
        }
        else {
            value =
                job.total - getTotalCompletedPaymentValueFromJobPayments(jobPayments);
        }
    }
    else if (!jobIsInvoice && !!job.acceptedAt) {
        value =
            (job.calculatedDepositAmount || 0) -
                getCompletedDepositPaymentValueFromJobPayments(jobPayments);
    }
    if (value === null)
        return value;
    return value < 0 ? 0 : Math.ceil(value);
};
