import React from 'react';
import { Button, ContentPanel } from '@payaca/components';
import './ClientInformationFlyerContentPanel.css';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import FileSaver from 'file-saver';
var ClientInformationFlyerContentPanel = function () {
    var flyerUri = 'https://storage.googleapis.com/payaca-prod-assets/Payaca_customer_information.pdf';
    var flyerImageUri = 'https://storage.googleapis.com/payaca-prod-assets/Payaca_customer_information.png';
    return (React.createElement(ContentPanel, { className: "client-information-flyer-content-panel" },
        React.createElement("div", { className: "flex-container flex-center" },
            React.createElement("h4", { className: "flex-grow" }, "Client information flyer"),
            React.createElement(Button, { iconAfter: faDownload, onClick: function () {
                    return FileSaver.saveAs(flyerUri, 'Payaca_customer_information.pdf');
                } }, "Download flyer")),
        React.createElement("div", { className: "client-information-flyer-image-container" },
            React.createElement("img", { className: "client-information-flyer-image", src: flyerImageUri, alt: "How it works" }))));
};
export default ClientInformationFlyerContentPanel;
