var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useMemo, useState } from 'react';
import './UserInviteForm.sass';
import { ValidatedForm } from '@payaca/components';
import { getIsRequiredFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { CollapsiblePanel, CollapsiblePanelStyleVariant, } from '@payaca/components/collapsiblePanel';
import { PrivateField } from '@payaca/components/privateField';
import PasswordValidationFeedback from '../passwordValidationFeedback/PasswordValidationFeedback';
import { minimumLengthFieldValidator, mustContainLetterFieldValidator, mustContainNumberFieldValidator, } from '@bit/payaca-tech.payaca-core.helpers.password-validation';
import { useDispatch } from 'react-redux';
import { getFormServiceError } from '@/helpers/formHelper';
import { actions as appActions } from '@/api/app';
import { actions as authActions } from '@bit/payaca-tech.payaca-core.store.auth';
import { useHistory } from 'react-router';
import { ErrorMessage } from '@payaca/components/feedbackMessage';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
var UserInviteForm = function (_a) {
    var inviteToken = _a.inviteToken;
    var dispatch = useDispatch();
    var history = useHistory();
    var _b = useState(), userInviteErrorMessage = _b[0], setUserInviteErrorMessage = _b[1];
    var _c = useState(false), isProcessing = _c[0], setIsProcessing = _c[1];
    var _d = useState(false), showPasswordValidationFeedback = _d[0], setShowPasswordValidationFeedback = _d[1];
    var fieldValidators = useMemo(function () {
        var isRequiredFieldValidator = getIsRequiredFieldValidator();
        return {
            password: [
                isRequiredFieldValidator,
                minimumLengthFieldValidator,
                mustContainLetterFieldValidator,
                mustContainNumberFieldValidator,
            ],
        };
    }, []);
    var login = useCallback(function (emailAddress, password) {
        setIsProcessing(true);
        dispatch(authActions.requestLogin(emailAddress, password));
        history.push('/deals');
    }, [dispatch, history]);
    var onSubmit = useCallback(function (formState) {
        setIsProcessing(true);
        setUserInviteErrorMessage(undefined);
        var payload = {
            password: formState.password,
            agreeToPrivacy: true,
            subscribeToNewsletter: false,
        };
        dispatch(appActions.registerInvitedUser(inviteToken, payload, function (error, response) {
            if (!error && response) {
                login(response.email, formState.password);
            }
            else {
                setIsProcessing(false);
                setUserInviteErrorMessage(getFormServiceError('userInvite', error));
            }
        }));
    }, [dispatch, login]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ValidatedFieldWrapper, { validationResult: __assign(__assign({}, validationState['password']), { errors: [] }), isTouched: touchedState['password'] || false },
                React.createElement(PrivateField, { styleVariant: InputStyleVariant.OUTSIZE, name: "password", value: formState.password, label: 'Create password', isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, additionalInputProps: {
                        onFocus: function () { return setShowPasswordValidationFeedback(true); },
                        onBlur: function () {
                            var _a;
                            onFieldTouch('password');
                            ((_a = validationState['password']) === null || _a === void 0 ? void 0 : _a.isValid) &&
                                setShowPasswordValidationFeedback(false);
                        },
                    } }),
                React.createElement(CollapsiblePanel, { isOpen: showPasswordValidationFeedback, styleVariant: CollapsiblePanelStyleVariant.UNSTYLED, shouldRenderHeader: false },
                    React.createElement(PasswordValidationFeedback, { password: formState.password }))),
            React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement(Button, { isProcessing: isProcessing, onClick: function () { return !isProcessing && onSubmit(formState); }, styleVariant: ButtonStyleVariant.OUTSIZE, isDisabled: !isValid }, "Create account"),
                    userInviteErrorMessage && (React.createElement(ErrorMessage, { message: userInviteErrorMessage }))),
                React.createElement("div", { style: { textAlign: 'center' } },
                    React.createElement("small", null,
                        "* by continuing you are agreeing to Payaca\u2019s",
                        ' ',
                        React.createElement("a", { href: process.env.REACT_APP_MARKETING_SITE_URL + "/terms-and-conditions", target: "_blank", rel: "noreferrer" }, 'terms & conditions'),
                        ' ',
                        "and",
                        ' ',
                        React.createElement("a", { href: process.env.REACT_APP_MARKETING_SITE_URL + "/privacy-cookie-policy", target: "_blank", rel: "noreferrer" }, "privacy policy"))))));
    }, [
        showPasswordValidationFeedback,
        isProcessing,
        userInviteErrorMessage,
        onSubmit,
    ]);
    var initialFormState = useMemo(function () {
        return {
            password: '',
            inviteToken: inviteToken,
        };
    }, [inviteToken]);
    return (React.createElement("div", { className: "user-invite-form-container" },
        React.createElement(ValidatedForm, { renderFormContents: renderFormContents, fieldValidators: fieldValidators, initialFormState: initialFormState })));
};
export default UserInviteForm;
