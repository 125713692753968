import React, { useMemo, useState, useEffect } from 'react';
import { Modal } from '@payaca/components/modal';
import RecordPaymentControl from '../recordPaymentControl/RecordPaymentControl';
import { getJobContactFromCustomer } from '@bit/payaca-tech.payaca-core.helpers.customer';
import { isInvoice } from '@bit/payaca-tech.payaca-core.helpers.job-status';
import ConfirmPaymentControl from '../confirmPaymentControl/ConfirmPaymentControl';
import { getBacsPendingDepositPaymentsFromJobPayments, getBacsPendingPaymentsExcludingDepositFromJobPayments, } from '@bit/payaca-tech.payaca-core.helpers.job-payment';
import { usePrevious } from '@/utils/customHooks';
import { getOutstandingPaymentValue } from '@payaca/helpers/jobHelperV2';
var RecordPaymentModal = function (_a) {
    var job = _a.job, jobPayments = _a.jobPayments, customer = _a.customer, isOpen = _a.isOpen, onClose = _a.onClose, recordPaymentCallback = _a.recordPaymentCallback, confirmPaymentCallback = _a.confirmPaymentCallback;
    var jobIsInvoice = useMemo(function () {
        return isInvoice(job.status);
    }, [job]);
    var prevIsOpen = usePrevious(isOpen);
    var _b = useState(false), isInConfirmPendingPaymentMode = _b[0], setIsInConfirmPendingPaymentMode = _b[1];
    var outstandingPaymentValue = useMemo(function () {
        return getOutstandingPaymentValue(job, jobPayments);
    }, [job, jobPayments]);
    var pendingPayment = useMemo(function () {
        var pendingPayments = [];
        if (jobIsInvoice) {
            pendingPayments =
                getBacsPendingPaymentsExcludingDepositFromJobPayments(jobPayments);
        }
        else {
            pendingPayments =
                getBacsPendingDepositPaymentsFromJobPayments(jobPayments);
        }
        return pendingPayments.shift();
    }, [jobPayments, jobIsInvoice]);
    var jobContact = useMemo(function () { return getJobContactFromCustomer(customer, job.contactId); }, [job]);
    var canSendReceipt = useMemo(function () {
        if (!job)
            return false;
        return !!(jobContact === null || jobContact === void 0 ? void 0 : jobContact.emailAddress);
    }, [job, jobContact]);
    useEffect(function () {
        if (isOpen && !prevIsOpen && !pendingPayment) {
            setIsInConfirmPendingPaymentMode(false);
        }
    }, [prevIsOpen, isOpen, pendingPayment]);
    useEffect(function () {
        if (pendingPayment) {
            setIsInConfirmPendingPaymentMode(true);
        }
    }, [pendingPayment]);
    return (React.createElement(Modal, { isOpen: isOpen, title: isInConfirmPendingPaymentMode ? 'Confirm a payment' : 'Record a payment', size: "sm", onClose: onClose, className: "record-payment-modal" },
        !isInConfirmPendingPaymentMode && (React.createElement(RecordPaymentControl, { outstandingPaymentValue: outstandingPaymentValue || 0, canRecordAnyPaymentValue: !job.showBespokeFinancePlans, isDepositPayment: !jobIsInvoice, customerName: (jobContact === null || jobContact === void 0 ? void 0 : jobContact.name) || '', jobReference: job.customReference || String(job.reference), jobId: job.id, recordPaymentCallback: recordPaymentCallback, canSendReceipt: canSendReceipt })),
        isInConfirmPendingPaymentMode && (React.createElement(ConfirmPaymentControl, { jobPayment: pendingPayment || {}, customerName: (jobContact === null || jobContact === void 0 ? void 0 : jobContact.name) || '', jobReference: job.customReference || String(job.reference), goToRecordPayment: job.showBespokeFinancePlans
                ? undefined
                : function () { return setIsInConfirmPendingPaymentMode(false); }, confirmPaymentCallback: confirmPaymentCallback, canSendReceipt: canSendReceipt }))));
};
export default RecordPaymentModal;
