import React, { useCallback } from 'react';
import './DirectorsFormSubsection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import DirectorFormSubsection from '../directorFormSubsection/DirectorFormSubsection';
var DirectorsFormSubSection = function (_a) {
    var directors = _a.directors, fieldNamePrefix = _a.fieldNamePrefix, touchedState = _a.touchedState, validationState = _a.validationState, onChange = _a.onChange, onTouch = _a.onTouch, onDirectorAdd = _a.onDirectorAdd, onDirectorRemove = _a.onDirectorRemove;
    var renderDirectorFormSubsection = useCallback(function (director, index) {
        return (React.createElement(DirectorFormSubsection, { key: index, title: "Director " + (index + 1), fieldNamePrefix: fieldNamePrefix + "[" + index + "]", director: director, onChange: onChange, onTouch: onTouch, touchedState: touchedState, validationState: validationState, onRemove: function () { return onDirectorRemove(index); } }));
    }, [
        fieldNamePrefix,
        touchedState,
        validationState,
        onChange,
        onTouch,
        onDirectorRemove,
    ]);
    return (React.createElement("div", { className: "directors-form-subsection" },
        directors && directors.map(renderDirectorFormSubsection),
        React.createElement("div", { className: "add-button", onClick: onDirectorAdd },
            React.createElement(FontAwesomeIcon, { icon: faPlus }),
            "Add director")));
};
export default DirectorsFormSubSection;
