// ======================================================================================================
// Private routes config
// - Define private routes, only accessible to authenticated users
// ======================================================================================================

import React from 'react';

import { Routes } from './RoutesConfig';

import VerifyUser from '@/ui/pages/verifyUserPage/VerifyUserPage';
import ResetPassword from '@/ui/pages/resetPasswordPage/ResetPasswordPage';
import UserInvite from '@/ui/pages/userInvitePage/UserInvitePage';
import AppleCallback from '@/ui/pages/appleCallbackPage/AppleCallbackPage';
import XeroCallback from '@/ui/pages/xeroCallbackPage/XeroCallbackPage';

import Customers from '@/ui/pages/listedCustomersPage/ListedCustomersPage';
import Items from '@/ui/pages/listedItemsPage/ListedItemsPage';
import ItemGroups from '@/ui/pages/listedItemGroupsPage/ListedItemGroupsPage';
import Users from '@/ui/pages/listedUsersPage/ListedUsersPage';

import NewEditItem from '@/ui/pages/newEditItemPage/NewEditItemPage';
import NewEditItemGroup from '@/ui/pages/newEditItemGroupPage/NewEditItemGroupPage';
import NewEditCustomer from '@/ui/pages/newEditCustomerPage/NewEditCustomerPage';
import NewEditQuote from '@/ui/pages/newEditQuotePage/NewEditQuotePage';
import NewEditInvoice from '@/ui/pages/newEditInvoicePage/NewEditInvoicePage';

import AnalyticsDashboardPage from '@/ui/pages/analyticsDashboardPage/AnalyticsDashboardPage';
import PersonalSettings from '@/ui/pages/personalSettingsPage/PersonalSettingsPage';
import CompanySettingsPage from '@/ui/pages/companySettingsPage/CompanySettingsPage';
import ReferAFriend from '@/ui/pages/referAFriendPage/ReferAFriendPage';
import Connections from '@/ui/pages/connectionsPage/ConnectionsPage';
import FinanceOptions from '@/ui/pages/financeOptionsPage/FinanceOptionsPage';
import ManageSubscription from '../ui/pages/manageSubscriptionPage/ManageSubscriptionPage';
import EnableCustomerPayments from '@/ui/pages/enableCustomerPaymentsPage/EnableCustomerPaymentsPage';
import VerifyUserHoldingPage from '@/ui/pages/verifyUserHoldingPage/VerifyUserHoldingPage';
import TrialPeriodExpiryHoldingPage from '@/ui/pages/trialPeriodExpiryHoldingPage/TrialPeriodExpiryHoldingPage';
import ResourcesPage from '@/ui/pages/resourcesPage/ResourcesPage';
import DevPage from '@/ui/pages/devPage/DevPage';
import GrantAccess from '@/ui/pages/grantAccessPage/GrantAccessPage';
import LoginOAuth from '@/ui/pages/loginOAuthPage/LoginOAuthPage';
import SubsidisedFinanceInformationOverviewPage from '@/ui/pages/subsidisedFinanceInformationOverviewPage/SubsidisedFinanceInformationOverviewPage';
import SubsidisedFinanceInformationFormPage from '@/ui/pages/subsidisedFinanceInformationFormPage/SubsidisedFinanceInformationFormPage';

import DownloadApp from '@/ui/pages/downloadAppPage/DownloadAppPage';

import FourOFour from '@/ui/pages/404/404';
import SetupAccountPage from '@/ui/pages/setupAccountPage/SetupAccountPage';
import SetupUserPage from '@/ui/pages/setupUserPage/SetupUserPage';
import DealPage from '@/ui/pages/dealPage/DealPage';
import DealsPage from '@/ui/pages/listedDealsPage/ListedDealsPage';

const PrivateRoutingConfig = {
  Routes: {
    [Routes.APPLE_CALLBACK]: {
      path: 'apple/callback',
      component: AppleCallback,
    },
    [Routes.XERO_CALLBACK]: {
      path: 'xero/callback',
      component: XeroCallback,
    },
    [Routes.VERIFY_USER]: {
      path: 'verify/:token',
      renderComponent: (props) => <VerifyUser {...props} isAuthed={true} />,
    },
    [Routes.CUSTOMERS]: {
      path: 'customers',
      component: Customers,
    },
    [Routes.ITEMS]: {
      path: 'items',
      component: Items,
    },
    [Routes.USERS]: {
      path: 'users',
      component: Users,
    },
    [Routes.ITEM_GROUPS]: {
      path: 'itemGroups',
      component: ItemGroups,
    },
    [Routes.NEW_QUOTE]: {
      path: 'quotes/new',
      component: NewEditQuote,
    },
    [Routes.EDIT_QUOTE]: {
      path: 'quotes/:quoteId/:view?',
      component: NewEditQuote,
    },
    [Routes.EDIT_NEW_QUOTE]: {
      path: 'quotes/:quoteId/:view?/new',
      renderComponent: (props) => <NewEditQuote {...props} isNew={true} />,
    },
    [Routes.NEW_INVOICE]: {
      path: 'invoices/new',
      component: NewEditInvoice,
    },
    [Routes.EDIT_INVOICE]: {
      path: 'invoices/:invoiceId/:view?',
      component: NewEditInvoice,
    },
    [Routes.EDIT_NEW_INVOICE]: {
      path: 'invoices/:invoiceId/:view?/new',
      renderComponent: (props) => <NewEditInvoice {...props} isNew={true} />,
    },
    [Routes.NEW_ITEM]: {
      path: 'items/new',
      component: NewEditItem,
    },
    [Routes.EDIT_ITEM]: {
      path: 'items/:itemId',
      component: NewEditItem,
    },
    [Routes.EDIT_ITEM_GROUP]: {
      path: 'itemGroups/:lineItemGroupId',
      component: NewEditItemGroup,
    },
    [Routes.NEW_CUSTOMER]: {
      path: 'customers/new',
      component: NewEditCustomer,
    },
    [Routes.EDIT_CUSTOMER]: {
      path: 'customers/:customerId',
      component: NewEditCustomer,
    },
    [Routes.PERSONAL_SETTINGS]: {
      path: 'personalSettings',
      component: PersonalSettings,
    },
    [Routes.COMPANY_SETTINGS]: {
      path: 'companySettings',
      component: CompanySettingsPage,
    },
    [Routes.FINANCE_OPTIONS]: {
      path: 'financeOptions',
      component: FinanceOptions,
    },
    [Routes.REFER_A_FRIEND]: {
      path: 'referAFriend',
      component: ReferAFriend,
    },
    [Routes.SUBSIDISED_FINANCE_INFORMATION_OVERVIEW]: {
      path: 'subsidisedFinanceInformation/overview',
      component: SubsidisedFinanceInformationOverviewPage,
    },
    [Routes.CONNECTIONS]: {
      path: 'connections/:service?',
      component: Connections,
    },
    [Routes.UPGRADE_ACCOUNT]: {
      path: 'upgradeAccount',
      component: ManageSubscription,
    },
    [Routes.ANALYTICS_DASHBOARD]: {
      path: 'analytics',
      component: AnalyticsDashboardPage,
    },
    [Routes.SUBSIDISED_FINANCE_INFORMATION_FORM]: {
      path: 'subsidisedFinanceInformation/form',
      component: SubsidisedFinanceInformationFormPage,
    },
    [Routes.ENABLE_CUSTOMER_PAYMENTS]: {
      path: 'enableCustomerPayments',
      component: EnableCustomerPayments,
    },
    [Routes.RESET]: {
      path: 'reset/:token',
      renderComponent: (props) => <ResetPassword {...props} isAuthed={true} />,
    },
    [Routes.USER_INVITE]: {
      path: 'invite/:token',
      renderComponent: (props) => <UserInvite {...props} isAuthed={true} />,
    },
    [Routes.RESOURCES]: {
      path: 'resources',
      component: ResourcesPage,
    },
    [Routes.LOGIN_OAUTH]: {
      path: 'oauth',
      renderComponent: (props) => <LoginOAuth {...props} isAuthed={true} />,
    },
    [Routes.GRANT_ACCESS]: {
      path: 'grantAccess',
      component: GrantAccess,
    },
    [Routes.DEALS]: {
      path: 'deals',
      component: DealsPage,
    },
    [Routes.DEAL_PAGE]: {
      path: 'deals/:dealId',
      component: DealPage,
    },
  },
  DevRoutes: {
    [Routes.DEV]: {
      path: 'dev',
      component: DevPage,
    },
  },
  RequiresSetupAccountRoutes: {
    [Routes.SETUP_ACCOUNT_PAGE]: {
      path: 'setupBusiness',
      component: SetupAccountPage,
    },
    [Routes.VERIFY_USER]: {
      path: 'verify/:token',
      renderComponent: (props) => <VerifyUser {...props} isAuthed={true} />,
    },
  },
  RequiresSetupUserRoutes: {
    [Routes.SETUP_USER]: {
      path: 'setupUser',
      component: SetupUserPage,
    },
  },
  NotOnboardedRoutes: {
    [Routes.UPGRADE_ACCOUNT]: {
      path: 'upgradeAccount',
      component: ManageSubscription,
    },
  },
  UserUnverifiedRoutes: {
    [Routes.VERIFY_USER_HOLDING_PAGE]: {
      path: 'verifyHolding',
      component: VerifyUserHoldingPage,
    },
    [Routes.VERIFY_USER]: {
      path: 'verify/:token',
      renderComponent: (props) => <VerifyUser {...props} isAuthed={true} />,
    },
  },
  AdminTrialPeriodExpiredRoutes: {
    [Routes.UPGRADE_ACCOUNT]: {
      path: 'upgradeAccount',
      component: ManageSubscription,
    },
    [Routes.FINANCE_OPTIONS]: {
      path: 'financeOptions',
      component: FinanceOptions,
    },
  },
  TrialPeriodExpiredRoutes: {
    [Routes.UPGRADE_ACCOUNT]: {
      path: 'trialExpired',
      component: TrialPeriodExpiryHoldingPage,
    },
    [Routes.FINANCE_OPTIONS]: {
      path: 'financeOptions',
      component: FinanceOptions,
    },
  },
  AdminUserSeatsExceededRoutes: {
    [Routes.UPGRADE_ACCOUNT]: {
      path: 'upgradeAccount',
      component: ManageSubscription,
    },
    [Routes.FINANCE_OPTIONS]: {
      path: 'financeOptions',
      component: FinanceOptions,
    },
    [Routes.USERS]: {
      path: 'users',
      component: Users,
    },
    [Routes.RESOURCES]: {
      path: 'resources',
      component: ResourcesPage,
    },
    [Routes.PERSONAL_SETTINGS]: {
      path: 'personalSettings',
      component: PersonalSettings,
    },
  },
  UserSeatsExceededRoutes: {
    [Routes.RESOURCES]: {
      path: 'resources',
      component: ResourcesPage,
    },
    [Routes.FINANCE_OPTIONS]: {
      path: 'financeOptions',
      component: FinanceOptions,
    },
    [Routes.PERSONAL_SETTINGS]: {
      path: 'personalSettings',
      component: PersonalSettings,
    },
  },
  MobileRoutes: {
    [Routes.UPGRADE_ACCOUNT]: {
      path: 'upgradeAccount',
      component: ManageSubscription,
    },
  },
  MobileRedirects: {
    TO_DOWNLOAD_APP: {
      fromPaths: ['*'],
      toPath: '/',
      component: DownloadApp,
    },
  },
  Redirects: {
    TO_DEALS: {
      fromPaths: ['', 'login', 'register', 'verifyHolding'],
      toPath: 'deals',
    },
    TO_404: {
      fromPaths: ['*'],
      toPath: '404',
      component: FourOFour,
    },
  },
  NoDealViewRedirects: {
    TO_QUOTES: {
      fromPaths: [
        '',
        'login',
        'register',
        'verifyHolding',
        'quotes',
        'invoices',
      ],
      toPath: 'deals',
    },
    TO_404: {
      fromPaths: ['*'],
      toPath: '404',
      component: FourOFour,
    },
  },
  NotOnboardedRedirects: {
    TO_UPGRADE_ACCOUNT_PAGE: {
      fromPaths: ['*'],
      toPath: 'upgradeAccount',
    },
  },
  UserUnverifiedRedirects: {
    TO_VERIFY_USER_HOLDING_PAGE: {
      fromPaths: ['*'],
      toPath: 'verifyHolding',
    },
  },
  RequiresSetupAccountRedirects: {
    TO_SETUP_ACCOUNT_PAGE: {
      fromPaths: ['*'],
      toPath: 'setupBusiness',
    },
  },
  RequiresSetupUserRedirects: {
    TO_SETUP_USER: {
      fromPaths: ['*'],
      toPath: 'setupUser',
    },
  },
  AdminTrialPeriodExpiredRedirects: {
    TO_UPGRADE_ACCOUNT: {
      fromPaths: ['*'],
      toPath: 'upgradeAccount',
    },
  },
  TrialPeriodExpiredRedirects: {
    TO_TRIAL_EXPIRED: {
      fromPaths: ['*'],
      toPath: 'trialExpired',
    },
  },
  AdminUserSeatsExceededRedirects: {
    TO_UPGRADE_ACCOUNT: {
      fromPaths: ['*'],
      toPath: 'upgradeAccount',
    },
  },
  UserSeatsExceededRedirects: {
    TO_TRIAL_EXPIRED: {
      fromPaths: ['*'],
      toPath: 'resources',
    },
  },
};

export { PrivateRoutingConfig };
