import React from 'react';
import { useDispatch } from 'react-redux';
import { actions as appActions } from '@/api/app';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import './DownloadAppPage.sass';
var DownloadAppPage = function (_a) {
    var history = _a.history, pathname = _a.pathname;
    var dispatch = useDispatch();
    return (React.createElement("div", { className: "download-app-page" },
        React.createElement("div", { className: "download-app-page-inner" },
            React.createElement("div", { className: "download-app-title" }, "For the best experience please download our mobile app or visit this website from a computer or tablet."),
            React.createElement("div", { className: "download-app-button-container" },
                React.createElement(Button, { onClick: function () {
                        dispatch(appActions.dismissMobileMode());
                        if (pathname) {
                            history.push(pathname);
                        }
                    }, styleVariant: ButtonStyleVariant.OUTSIZE }, "Proceed anyway")),
            React.createElement("div", { className: "download-apps" },
                React.createElement("a", { href: "https://apps.apple.com/us/app/payaca/id1455123031?ls=1" },
                    React.createElement("img", { alt: "Download iPhone App", src: require('../../../assets/images/apple.svg').default })),
                React.createElement("a", { href: "https://play.google.com/store/apps/details?id=com.payaca.mobileapp" },
                    React.createElement("img", { alt: "Download Android App", src: require('../../../assets/images/google-play.svg').default }))),
            React.createElement("img", { className: "phone-image", alt: "Download app", src: require('../../../assets/images/iphone-image.png').default }))));
};
export default DownloadAppPage;
