import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tile } from '@payaca/components/tile';
import { actions as lineItemActions } from '@payaca/store/lineItemsV2';
import './ListedLineItemGroupTile.sass';
import { getLineItemsByLineItemGroupId } from '@/utils/stateAccessors';
import { calculateLineItemGroupTotals } from '@payaca/helpers/lineItemGroupTotalsHelper';
import { currencyPrice } from '@/helpers/financeHelper';
import { LabelValuePair } from '@payaca/components/labelValuePair';
var ListedLineItemGroupTile = function (_a) {
    var lineItemGroupId = _a.lineItemGroupId, onClick = _a.onClick;
    var dispatch = useDispatch();
    var lineItemGroup = useSelector(function (state) { var _a, _b; return (_b = (_a = state.lineItemGroups) === null || _a === void 0 ? void 0 : _a.lineItemGroups[lineItemGroupId]) === null || _b === void 0 ? void 0 : _b.entity; });
    var lineItems = useSelector(function (state) {
        return getLineItemsByLineItemGroupId(state, lineItemGroupId);
    });
    useEffect(function () {
        lineItemGroupId &&
            dispatch(lineItemActions.requestGetLineItemsForLineItemGroup(lineItemGroupId));
    }, [lineItemGroupId]);
    var groupHasAnyNonRequiredItems = useMemo(function () {
        if (!lineItemGroup)
            return false;
        return lineItemGroup.lineItemRelations.some(function (x) { return x.isMultipleChoice || x.isOptional; });
    }, [lineItemGroup]);
    var groupTotals = useMemo(function () {
        if (!lineItemGroup)
            return;
        return calculateLineItemGroupTotals(lineItemGroup, lineItems);
    }, [lineItemGroup, lineItems]);
    var totalElement = useMemo(function () {
        if (!groupTotals)
            return;
        var totalValue = '';
        if (groupHasAnyNonRequiredItems) {
            totalValue = currencyPrice(groupTotals.minTotal) + " - " + currencyPrice(groupTotals.maxTotal);
        }
        else {
            totalValue = currencyPrice(groupTotals.total);
        }
        return React.createElement(LabelValuePair, { label: "Total", value: totalValue });
    }, [groupTotals, groupHasAnyNonRequiredItems]);
    return (React.createElement(Tile, { onClick: onClick, className: "listed-line-item-group-tile " + (onClick ? 'clickable' : ''), title: lineItemGroup === null || lineItemGroup === void 0 ? void 0 : lineItemGroup.name, footer: React.createElement("div", { className: "flex-container flex-center" },
            React.createElement("span", { className: "flex-grow" }, totalElement)) },
        React.createElement("p", { className: "description" }, lineItemGroup === null || lineItemGroup === void 0 ? void 0 : lineItemGroup.description),
        !!lineItems.length && (React.createElement("ul", null, lineItems.map(function (lineItem, index) {
            return (React.createElement("li", { key: "line-item-relation-" + index },
                React.createElement("div", { className: "line-item-description" }, lineItem.description)));
        })))));
};
export default ListedLineItemGroupTile;
