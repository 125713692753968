import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckboxField } from '@payaca/components/checkboxField';
import Tooltip from '@bit/payaca-tech.payaca-core.component.tooltip';
import { AddBACSDetailsModal } from '@/ui/components';
import JobOptions from './JobOptions';
import { actions as appActions } from '@/api/app';
import { actions as userActions } from '@/api/users';
import { getModal } from '@/helpers/modalHelper';
import { useRef } from 'react';
import { useEffect } from 'react';
import get from 'lodash.get';
var PaymentOptions = function (_a) {
    var onChange = _a.onChange, showBACSPaymentOption = _a.showBACSPaymentOption, showStripePaymentOption = _a.showStripePaymentOption;
    var dispatch = useDispatch();
    var _b = useState(false), showAddBacsDetails = _b[0], setShowAddBacsDetails = _b[1];
    var isAdmin = useSelector(function (state) { return state.users.myProfile.isAdmin; });
    var account = useSelector(function (state) { return state.users.myProfile.accounts[0]; });
    var isConnectedToStripe = useMemo(function () { return account && account.connectedToStripe; }, [account]);
    var hasAddedBankInformation = useMemo(function () {
        return account &&
            account.accountNameRaw &&
            account.accountNumberRaw &&
            account.sortCodeRaw;
    }, [account]);
    var interval = useRef(null);
    useEffect(function () {
        return function () { return clearInterval(interval.current); };
    }, []);
    var setCheckStripeStatusInterval = function () {
        clearInterval(interval.current);
        interval.current = setInterval(function () { return checkStripeStatus(); }, 3000);
    };
    var checkStripeStatus = function () {
        dispatch(userActions.getProfile(function (err, response) {
            var connectedToStripe = get(response, [
                'accounts',
                0,
                'connectedToStripe',
            ]);
            if (connectedToStripe) {
                onChange({
                    showStripePaymentOption: true,
                });
                clearInterval(interval.current);
            }
        }));
    };
    var checkBacsDetails = useCallback(function () {
        if (hasAddedBankInformation) {
            onChange({
                showBACSPaymentOption: true,
            });
        }
    }, [hasAddedBankInformation]);
    var stripeLabel = useMemo(function () {
        return (React.createElement("div", null,
            "Stripe",
            ' ',
            React.createElement("img", { className: "icon", alt: "Visa", src: require('./visa.svg').default }),
            React.createElement("img", { className: "icon", alt: "Mastercard", src: require('./mastercard.svg').default }),
            React.createElement("img", { className: "icon", alt: "American Express", src: require('./americanexpress.svg').default }),
            React.createElement("img", { className: "icon", alt: "Apple Pay", src: require('./applepay.svg').default }),
            React.createElement("img", { className: "icon", alt: "Android Pay", src: require('./androidpay.svg').default }),
            React.createElement(Tooltip, { text: "Connect to Stripe and accept card payments. Fees of 1.4% +20p apply for Visa, Mastercard and other European cards. Volume discounts may be available." })));
    }, []);
    var onBankDetailsChange = useCallback(function () {
        if (hasAddedBankInformation) {
            onChange({
                showBACSPaymentOption: !showBACSPaymentOption,
            });
        }
        else {
            setShowAddBacsDetails(true);
        }
    }, [hasAddedBankInformation, onChange, showBACSPaymentOption]);
    var onStripeChange = useCallback(function () {
        if (isConnectedToStripe) {
            onChange({
                showStripePaymentOption: !showStripePaymentOption,
            });
        }
        else {
            setCheckStripeStatusInterval();
            dispatch(appActions.showModal(getModal('ACCEPT_PAYMENTS_WITH_STRIPE', {
                onClose: function () { return dispatch(appActions.hideModal()); },
                tertiaryAction: function () { return dispatch(appActions.hideModal()); },
            })));
        }
    }, [isConnectedToStripe, showStripePaymentOption]);
    return (React.createElement(JobOptions, { title: "Payment options" },
        React.createElement("div", null,
            React.createElement(CheckboxField, { name: "stripe", label: stripeLabel, onChange: function () { return onStripeChange(); }, value: !!showStripePaymentOption, isDisabled: !isConnectedToStripe && !isAdmin }),
            React.createElement("div", null,
                React.createElement(CheckboxField, { name: "bankDetails", label: React.createElement("div", null,
                        "Show company bank details",
                        React.createElement(Tooltip, { text: "Displays your bank details to enable a client to make a BACS payment." })), onChange: function () { return onBankDetailsChange(); }, value: !!showBACSPaymentOption, isDisabled: !hasAddedBankInformation && !isAdmin }))),
        React.createElement(AddBACSDetailsModal, { open: showAddBacsDetails, onClose: function () {
                setShowAddBacsDetails(false);
                checkBacsDetails();
            } })));
};
export default PaymentOptions;
