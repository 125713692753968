import React, { useState, useCallback, useEffect, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './ConfirmPaymentControl.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronRight, faTimes, } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { ValidatedForm } from '@payaca/components';
import { Checkbox } from '@payaca/components/checkbox';
import { actions as jobPaymentsActions } from '@bit/payaca-tech.payaca-core.store.job-payments';
import MiniLoader from '@bit/payaca-tech.payaca-core.component.mini-loader';
import { currencyPrice } from '@bit/payaca-tech.payaca-core.helpers.finance';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { BasicField } from '@payaca/components/basicField';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
var ConfirmPaymentControl = function (_a) {
    var jobPayment = _a.jobPayment, customerName = _a.customerName, jobReference = _a.jobReference, _b = _a.canSendReceipt, canSendReceipt = _b === void 0 ? true : _b, goToRecordPayment = _a.goToRecordPayment, confirmPaymentCallback = _a.confirmPaymentCallback;
    var dispatch = useDispatch();
    var _c = useState(false), hasCalledCallback = _c[0], setHasCalledCallback = _c[1];
    var _d = useState(false), isSubmitted = _d[0], setIsSubmitted = _d[1];
    var isConfirmingJobPayment = useSelector(function (state) {
        return state.jobPayments.isConfirmingJobPayment;
    });
    var isJobPaymentConfirmedSuccessfully = useSelector(function (state) {
        return state.jobPayments.isJobPaymentConfirmedSuccessfully;
    });
    var onSubmit = useCallback(function (jobPaymentId, sendReceipt, note, isPaymentReceived) {
        if (isPaymentReceived === void 0) { isPaymentReceived = true; }
        dispatch(jobPaymentsActions.requestConfirmJobPayment({
            jobPaymentId: jobPaymentId,
            isPaymentReceived: isPaymentReceived,
            sendReceipt: sendReceipt,
            note: note,
        }));
        setIsSubmitted(true);
    }, [dispatch]);
    useEffect(function () {
        if (isSubmitted &&
            !isConfirmingJobPayment &&
            isJobPaymentConfirmedSuccessfully &&
            confirmPaymentCallback &&
            !hasCalledCallback) {
            setHasCalledCallback(true);
            confirmPaymentCallback();
        }
    }, [
        isSubmitted,
        isConfirmingJobPayment,
        isJobPaymentConfirmedSuccessfully,
        hasCalledCallback,
        confirmPaymentCallback,
    ]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        return (React.createElement(React.Fragment, null,
            React.createElement("p", null,
                customerName,
                " has made a",
                ' ',
                jobPayment.isDepositPayment ? 'deposit payment' : 'payment',
                " of",
                ' ',
                currencyPrice(jobPayment.paymentValue),
                " via BACS transfer towards job #",
                jobReference,
                "."),
            canSendReceipt && (React.createElement("div", null,
                React.createElement("div", { className: "checkbox-container flex-container flex-center" },
                    React.createElement("div", null,
                        React.createElement(Checkbox, { isChecked: formState.sendReceipt === undefined
                                ? true
                                : formState.sendReceipt, onChange: function () {
                                onFieldChange({
                                    sendReceipt: !formState.sendReceipt,
                                });
                                onFieldTouch && onFieldTouch('sendReceipt');
                            } })),
                    React.createElement("span", null, "I would like to send a receipt to the customer for this amount")))),
            React.createElement(ValidatedFieldWrapper, { validationResult: validationState.note, isTouched: touchedState.note || false },
                React.createElement(BasicField, { additionalInputProps: {
                        maxLength: 150,
                    }, name: "note", value: formState.note, isRequired: false, onChange: onFieldChange, onTouch: onFieldTouch, label: "Internal note", styleVariant: InputStyleVariant.OUTSIZE })),
            React.createElement("div", { className: "actions-container" },
                React.createElement(Button, { onClick: function () {
                        return onSubmit(formState.jobPaymentId, formState.sendReceipt, formState.note, true);
                    }, isDisabled: !isValid, iconAfter: faChevronRight, styleVariant: ButtonStyleVariant.OUTSIZE },
                    currencyPrice(jobPayment.paymentValue),
                    " received"),
                React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, iconAfter: faTimes, onClick: function () {
                        return onSubmit(formState.jobPaymentId, formState.sendReceipt, formState.note, false);
                    } }, "Payment not received"),
                goToRecordPayment && (React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, iconAfter: faChevronRight, onClick: function () {
                        // fail the original payment made by customer
                        onSubmit(formState.jobPaymentId, false, undefined, false);
                        goToRecordPayment();
                    } }, "I have received another amount")))));
    }, [jobPayment, customerName, jobReference, canSendReceipt, goToRecordPayment]);
    return (React.createElement("div", { className: "confirm-payment-control" },
        !isSubmitted && (React.createElement(ValidatedForm, { renderFormContents: renderFormContents, initialFormState: {
                jobPaymentId: jobPayment.id,
                sendReceipt: canSendReceipt,
            } })),
        isSubmitted && isConfirmingJobPayment && (React.createElement("div", { className: "loader-container" },
            React.createElement(MiniLoader, null))),
        isSubmitted && !isConfirmingJobPayment && (React.createElement("div", { className: "feedback-container" }, isJobPaymentConfirmedSuccessfully ? (React.createElement(React.Fragment, null,
            React.createElement(FontAwesomeIcon, { icon: faCheck }),
            React.createElement("p", null, "Success!"))) : (React.createElement("p", null, "Something went wrong updating this payment record"))))));
};
export default ConfirmPaymentControl;
