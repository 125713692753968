import React, { useEffect } from 'react';
import './SentQuoteValueChart.css';
import { AggregateValueBarChart, CategoriesKey } from '@/ui/components';
import { useDispatch, useSelector } from 'react-redux';
import { actions as analyticsActions } from '@bit/payaca-tech.payaca-core.store.analytics';
import * as d3 from 'd3';
var formatValue = function (value) {
    return "\u00A3" + d3.format(',.2f')(value / 100);
};
var formatXAxisTickValue = function (value) {
    var poundsValue = value / 100;
    return poundsValue < 1 ? '' : d3.format('~s')(poundsValue);
};
var SentQuoteValueChart = function () {
    var dispatch = useDispatch();
    var sentQuoteValueAggregateChartData = useSelector(function (state) {
        return state.analytics.sentQuoteValueAggregateChartData;
    });
    useEffect(function () {
        dispatch(analyticsActions.requestGetSentQuoteValueAggregateChartData());
        return function () {
            dispatch(analyticsActions.clearSentQuoteValueAggregateChartData());
        };
    }, []);
    return (React.createElement("div", { className: "sent-quote-value-chart" },
        React.createElement(CategoriesKey, { categories: sentQuoteValueAggregateChartData === null || sentQuoteValueAggregateChartData === void 0 ? void 0 : sentQuoteValueAggregateChartData.aggregateCategories }),
        React.createElement(AggregateValueBarChart, { data: sentQuoteValueAggregateChartData, xAxisLabel: "Quotes value (\u00A3)", yAxisLabel: "Month sent", overrideChartPadding: { left: 75, bottom: 35, top: 0, right: 10 }, formatValue: formatValue, formatXAxisTickValue: formatXAxisTickValue })));
};
export default SentQuoteValueChart;
