import React from 'react';
import './InputWrapper.sass';
export var InputStyleVariant;
(function (InputStyleVariant) {
    InputStyleVariant["STANDARD"] = "standard";
    InputStyleVariant["OUTSIZE"] = "outsize";
    InputStyleVariant["UNSTYLED"] = "unstyled";
})(InputStyleVariant || (InputStyleVariant = {}));
export var InputColourVariant;
(function (InputColourVariant) {
    InputColourVariant["PRIMARY"] = "primary";
    InputColourVariant["SECONDARY"] = "secondary";
    InputColourVariant["WHITE"] = "white";
    InputColourVariant["GREY"] = "grey";
})(InputColourVariant || (InputColourVariant = {}));
var InputWrapper = function (_a) {
    var children = _a.children, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, _c = _a.styleVariant, styleVariant = _c === void 0 ? InputStyleVariant.STANDARD : _c, _d = _a.colourVariant, colourVariant = _d === void 0 ? InputColourVariant.WHITE : _d, _e = _a.hasBoxShadow, hasBoxShadow = _e === void 0 ? true : _e;
    return (React.createElement("div", { className: "input-wrapper v2 " + (isDisabled ? 'disabled' : '') + " input-wrapper-" + styleVariant + " input-col-" + colourVariant + " " + (hasBoxShadow ? '' : 'input-no-shadow') }, children));
};
export default InputWrapper;
