import React, { useMemo } from 'react';
import './ProfilePageWrapper.sass';
import AuthenticatedPageWrapper from '../authenticatedPageWrapper/AuthenticatedPageWrapper';
import ProfileNavigation from '@/ui/components/profileNavigation/ProfileNavigation';
import { ContentPanel } from '@payaca/components/contentPanel';
var ProfilePageWrapper = function (_a) {
    var children = _a.children, className = _a.className, pageBanners = _a.pageBanners, title = _a.title, searchFieldConfig = _a.searchFieldConfig;
    // TODO: add real progress
    var profileCompletePercentage = 80;
    var profileCompleteMessage = useMemo(function () {
        if (profileCompletePercentage > 60) {
            return "Nearly there! Your profile is " + profileCompletePercentage + "% complete, please enter your details.";
        }
        else {
            return "Don't miss out! Your profile is " + profileCompletePercentage + "% complete, please enter your details.";
        }
    }, [profileCompletePercentage]);
    return (React.createElement(AuthenticatedPageWrapper, { pageBanners: pageBanners, className: "profile-page-wrapper " + (className ? className : ''), searchFieldConfig: searchFieldConfig },
        React.createElement("div", { className: "lhs" },
            React.createElement("div", { className: "title-bar" },
                React.createElement("h1", null, "Profile")),
            React.createElement("div", { className: "lhs-content" },
                React.createElement("div", { style: { marginBottom: '2rem' } },
                    React.createElement(ContentPanel, { hasBoxShadow: true, className: "profile-progress" },
                        React.createElement("div", { className: "profile-progress-inner" },
                            React.createElement("div", { className: "profile-progress-circle-wrapper" },
                                React.createElement("div", { className: "profile-progress-circle" },
                                    React.createElement("span", null,
                                        profileCompletePercentage,
                                        "%"))),
                            React.createElement("div", null,
                                React.createElement("h4", null, "Profile progress"),
                                React.createElement("p", null, profileCompleteMessage))),
                        React.createElement("p", null, "Please add your company information in to complete your profile"))),
                React.createElement(ProfileNavigation, null))),
        React.createElement("div", { className: "divider" }),
        React.createElement("div", { className: "rhs" },
            React.createElement("div", { className: "title-bar" },
                typeof title === 'string' ? React.createElement("h2", null, title) : title,
                React.createElement("hr", null)),
            React.createElement("div", { className: "rhs-content" }, children))));
};
export default ProfilePageWrapper;
