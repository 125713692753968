export var StepLabel;
(function (StepLabel) {
    StepLabel["GET_STARTED"] = "get_started";
    StepLabel["BUSINESS_INFORMATION"] = "business_information";
    StepLabel["FINANCIAL_INFORMATION"] = "financial_information";
    StepLabel["REGULATORY_REQUIREMENTS"] = "regulatory_requirements";
    StepLabel["ADDITIONAL_INFORMATION"] = "additional_information";
    StepLabel["AUTHORITY_TO_SEARCH"] = "authority_to_search";
    StepLabel["SUPPORTING_DOCUMENTS"] = "supporting_documents";
    StepLabel["REVIEW"] = "review";
})(StepLabel || (StepLabel = {}));
export var CompanyType;
(function (CompanyType) {
    CompanyType["PLC"] = "Private Limited Company";
    CompanyType["SOLE_TRADER"] = "Individual / Sole trader";
    CompanyType["PARTNERSHIP_LLP"] = "Partnership / LLP";
})(CompanyType || (CompanyType = {}));
export var SupportingDocumentType;
(function (SupportingDocumentType) {
    SupportingDocumentType["GENERIC"] = "GENERIC";
    SupportingDocumentType["DIRECTOR_ID"] = "DIRECTOR_ID";
    SupportingDocumentType["DIRECTOR_ADDRESS"] = "DIRECTOR_ADDRESS";
    SupportingDocumentType["BANK_STATEMENT"] = "BANK_STATEMENT";
})(SupportingDocumentType || (SupportingDocumentType = {}));
