import React, { useCallback, useMemo } from 'react';
import ResponsiveViewWrapper from '@bit/payaca-tech.payaca-core.component.responsive-view-wrapper';
import { getCustomerAddressAsString } from '@payaca/helpers/customerHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt, faPhone, } from '@fortawesome/free-solid-svg-icons';
import { getPrimaryContactFromCustomer } from '@bit/payaca-tech.payaca-core.helpers.customer';
import './CustomerInformation.css';
var CustomerInformation = function (_a) {
    var customer = _a.customer, renderCustomerContact = _a.renderCustomerContact, _b = _a.showPrimaryContactOnly, showPrimaryContactOnly = _b === void 0 ? false : _b;
    var hasAnyContacts = useMemo(function () {
        var _a, _b, _c, _d;
        if (((_a = customer.contacts) === null || _a === void 0 ? void 0 : _a.length) === 1) {
            var contact = customer.contacts[0];
            var hasEmailAddress = !!((_b = contact.emailAddress) === null || _b === void 0 ? void 0 : _b.length);
            var hasPhoneNumber = !!((_c = contact.telephoneNumber) === null || _c === void 0 ? void 0 : _c.length);
            if (!hasEmailAddress &&
                !hasPhoneNumber &&
                contact.name === customer.name) {
                return false;
            }
            return true;
        }
        return !!((_d = customer.contacts) === null || _d === void 0 ? void 0 : _d.length);
    }, [customer]);
    var hasSingleContact = useMemo(function () {
        var _a;
        return ((_a = customer.contacts) === null || _a === void 0 ? void 0 : _a.length) === 1;
    }, [customer]);
    var primaryContact = useMemo(function () {
        return getPrimaryContactFromCustomer(customer);
    }, [customer]);
    var addresses = useMemo(function () {
        var _a;
        return (_a = customer.addresses) === null || _a === void 0 ? void 0 : _a.filter(function (address) {
            var addressAsString = getCustomerAddressAsString(address.address);
            return !!addressAsString && addressAsString !== '';
        });
    }, [customer.addresses]);
    var hasAnyAddresses = useMemo(function () {
        return !!(addresses === null || addresses === void 0 ? void 0 : addresses.length);
    }, [addresses]);
    var renderCustomerContactDefault = useCallback(function (contact, index) {
        return (React.createElement("div", { className: "customer-contact", key: "customer-contact-" + index },
            React.createElement("p", { className: "contact-name" }, contact.name),
            contact.emailAddress && (React.createElement("p", null,
                React.createElement(FontAwesomeIcon, { icon: faEnvelope }),
                contact.emailAddress)),
            contact.telephoneNumber && (React.createElement("p", null,
                React.createElement(FontAwesomeIcon, { icon: faPhone }),
                contact.telephoneNumber))));
    }, []);
    return (React.createElement(ResponsiveViewWrapper, { className: "customer-information", downBreakpointSm: 500 },
        React.createElement(React.Fragment, null,
            React.createElement("p", { className: "customer-name" }, customer.name),
            hasAnyContacts && (React.createElement(React.Fragment, null,
                React.createElement("hr", null),
                React.createElement("div", { className: "customer-contacts" },
                    !showPrimaryContactOnly && !hasSingleContact && (React.createElement("div", { className: "customer-contacts-inner" }, customer.contacts.map(function (contact, index) {
                        if (renderCustomerContact) {
                            return renderCustomerContact(contact, index, renderCustomerContactDefault(contact, index));
                        }
                        else {
                            return renderCustomerContactDefault(contact, index);
                        }
                    }))),
                    !showPrimaryContactOnly && hasSingleContact && (React.createElement(React.Fragment, null,
                        customer.contacts[0].emailAddress && (React.createElement("p", null,
                            React.createElement(FontAwesomeIcon, { icon: faEnvelope }),
                            customer.contacts[0].emailAddress)),
                        customer.contacts[0].telephoneNumber && (React.createElement("p", null,
                            React.createElement(FontAwesomeIcon, { icon: faPhone }),
                            customer.contacts[0].telephoneNumber)))),
                    showPrimaryContactOnly && primaryContact && (React.createElement(React.Fragment, null,
                        primaryContact.name !== customer.name && (React.createElement("p", { className: "contact-name" }, primaryContact.name)),
                        primaryContact.emailAddress && (React.createElement("p", null,
                            React.createElement(FontAwesomeIcon, { icon: faEnvelope }),
                            primaryContact.emailAddress)),
                        primaryContact.telephoneNumber && (React.createElement("p", null,
                            React.createElement(FontAwesomeIcon, { icon: faPhone }),
                            primaryContact.telephoneNumber))))))),
            hasAnyAddresses && (React.createElement(React.Fragment, null,
                React.createElement("hr", null),
                React.createElement("div", { className: "customer-addresses" }, addresses.map(function (address, index) {
                    var addressAsString = getCustomerAddressAsString(address.address);
                    if (!addressAsString || addressAsString === '')
                        return;
                    return (React.createElement("div", { className: "customer-address", key: "customer-address-" + index },
                        React.createElement("p", null,
                            React.createElement(FontAwesomeIcon, { icon: faMapMarkerAlt }),
                            address.description && (React.createElement("span", { className: "address-description" },
                                address.description,
                                ' | ')),
                            getCustomerAddressAsString(address.address))));
                })))))));
};
export default CustomerInformation;
