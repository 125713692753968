import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import './ListedItemGroupsPage.sass';
import ListViewPageWrapper from '../pageWrappers/listViewPageWrapper/ListViewPageWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { actions as lineItemGroupActions } from '@bit/payaca-tech.payaca-core.store.line-item-groups';
import { CreateButton } from '@payaca/components/button';
import ListedLineItemGroups from '@/ui/components/listedLineItemGroups/ListedLineItemGroups';
var ListedItemGroupsPage = function () {
    var dispatch = useDispatch();
    var history = useHistory();
    var lineItemGroups = useSelector(function (state) { return state.lineItemGroups.lineItemGroups; });
    var createdLineItemGroupId = useSelector(function (state) { return state.lineItemGroups.createLineItemGroupSuccessId; });
    var listedLineItemGroups = useMemo(function () { return Object.values(lineItemGroups); }, [lineItemGroups]);
    useEffect(function () {
        dispatch(lineItemGroupActions.requestGetLineItemGroups());
        return function () {
            dispatch(lineItemGroupActions.clearCreateLineItemGroup());
        };
    }, []);
    useEffect(function () {
        if (createdLineItemGroupId) {
            history.push("/itemGroups/" + createdLineItemGroupId);
        }
    }, [createdLineItemGroupId]);
    return (React.createElement(ListViewPageWrapper, { className: "listed-item-groups-page", title: React.createElement("div", { className: "flex-container flex-center" },
            React.createElement("div", null,
                React.createElement("h1", null, "Item Groups")),
            React.createElement(CreateButton, { onClick: function () {
                    return dispatch(lineItemGroupActions.requestCreateLineItemGroup());
                } }, "Create new")), paginationConfig: {
            pageNumber: 1,
            totalItemCount: listedLineItemGroups.length,
            pageSize: listedLineItemGroups.length,
            onSelectPage: function () { return null; },
        } },
        React.createElement(ListedLineItemGroups, { onLineItemGroupClick: function (lineItemGroupId) {
                return history.push("/itemGroups/" + lineItemGroupId);
            } })));
};
export default ListedItemGroupsPage;
