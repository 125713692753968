import React from 'react';
import LabelValuePair from '../labelValuePair/LabelValuePair';
var AdditionalInformationOverview = function (_a) {
    var additionalInformation = _a.additionalInformation;
    return (React.createElement("div", { className: "additional-information-overview" },
        React.createElement("div", null,
            React.createElement(LabelValuePair, { label: "Are there any criminal offences for which any directors have been convicted (except convictions spent under the Rehabilitation of Offenders Act)?", value: (additionalInformation === null || additionalInformation === void 0 ? void 0 : additionalInformation.hasCriminalConvictions) ? 'YES' : 'NO' }),
            (additionalInformation === null || additionalInformation === void 0 ? void 0 : additionalInformation.hasCriminalConvictions) && (React.createElement("div", { className: "multiline-label-value" },
                React.createElement(LabelValuePair, { label: "Please provide more information", value: (additionalInformation === null || additionalInformation === void 0 ? void 0 : additionalInformation.criminalConvictionsDetails) || '' })))),
        React.createElement("div", null,
            React.createElement(LabelValuePair, { label: "Are there any bankruptcy claims or voluntary arrangements any directors have made with creditors within the last 5 years?", value: (additionalInformation === null || additionalInformation === void 0 ? void 0 : additionalInformation.hasBankruptcyClaims) ? 'YES' : 'NO' }),
            (additionalInformation === null || additionalInformation === void 0 ? void 0 : additionalInformation.hasBankruptcyClaims) && (React.createElement("div", { className: "multiline-label-value" },
                React.createElement(LabelValuePair, { label: "Please provide more information", value: (additionalInformation === null || additionalInformation === void 0 ? void 0 : additionalInformation.bankruptcyClaimsDetails) || '' })))),
        React.createElement("div", null,
            React.createElement(LabelValuePair, { label: "Have you ever had a lender facility terminated?", value: (additionalInformation === null || additionalInformation === void 0 ? void 0 : additionalInformation.hasLenderFacilityTermination) ? 'YES' : 'NO' }),
            (additionalInformation === null || additionalInformation === void 0 ? void 0 : additionalInformation.hasLenderFacilityTermination) && (React.createElement("div", { className: "multiline-label-value" },
                React.createElement(LabelValuePair, { label: "Please provide more information", value: (additionalInformation === null || additionalInformation === void 0 ? void 0 : additionalInformation.lenderFacilityTerminationDetails) || '' })))),
        React.createElement("div", null,
            React.createElement(LabelValuePair, { label: "Does the company or its directors have any outstanding CCJs or adverse financial information registered?", value: (additionalInformation === null || additionalInformation === void 0 ? void 0 : additionalInformation.hasOutstandingCcjs) ? 'YES' : 'NO' }),
            (additionalInformation === null || additionalInformation === void 0 ? void 0 : additionalInformation.hasOutstandingCcjs) && (React.createElement("div", { className: "multiline-label-value" },
                React.createElement(LabelValuePair, { label: "Please provide more information", value: (additionalInformation === null || additionalInformation === void 0 ? void 0 : additionalInformation.outstandingCcjsDetails) || '' })))),
        React.createElement("div", null,
            React.createElement(LabelValuePair, { label: "Has the company had any litigation, disputes or claims within the last 12 months?", value: (additionalInformation === null || additionalInformation === void 0 ? void 0 : additionalInformation.hasRecentDisputesLitigationClaims) ? 'YES'
                    : 'NO' }),
            (additionalInformation === null || additionalInformation === void 0 ? void 0 : additionalInformation.hasRecentDisputesLitigationClaims) && (React.createElement("div", { className: "multiline-label-value" },
                React.createElement(LabelValuePair, { label: "Please provide more information", value: (additionalInformation === null || additionalInformation === void 0 ? void 0 : additionalInformation.recentDisputesLitigationClaimsDetails) ||
                        '' }))))));
};
export default AdditionalInformationOverview;
