export var ActionType;
(function (ActionType) {
    ActionType["REQUEST_GET_JOBS_FOR_DEAL"] = "jobs.requestGetJobsForDeal";
    ActionType["REQUEST_GET_JOB"] = "jobs.requestGetJob";
    ActionType["REQUEST_GET_JOB_WITH_JOB_GROUPS_AND_JOB_LINE_ITEMS"] = "jobs.requestGetJobWithJobGroupsAndJobLineItems";
    ActionType["GET_JOB_SUCCESS"] = "jobs.getJobSuccess";
    ActionType["GET_JOB_FAILURE"] = "jobs.getJobFailure";
    ActionType["CLEAR_JOB"] = "jobs.clearJob";
    ActionType["CLEAR_JOBS"] = "jobs.clearJobs";
    ActionType["REQUEST_UPDATE_JOB"] = "jobs.requestUpdateJob";
    ActionType["UPDATE_JOB_SUCCESS"] = "jobs.updateJobSuccess";
    ActionType["UPDATE_JOB_FAILURE"] = "jobs.updateJobFailure";
    ActionType["REQUEST_GET_JOB_VALIDATION_RESULT"] = "jobs.requestGetJobValidationResult";
    ActionType["GET_JOB_VALIDATION_RESULT_SUCCESS"] = "jobs.getJobValidationResultSuccess";
    ActionType["GET_JOB_VALIDATION_RESULT_FAILURE"] = "jobs.getJobValidationResultFailure";
    ActionType["REQUEST_MARK_JOB_AS_SENT"] = "jobs.requestMarkJobAsSent";
    ActionType["MARK_JOB_AS_SENT_SUCCESS"] = "jobs.markJobAsSentSuccess";
    ActionType["MARK_JOB_AS_SENT_FAILURE"] = "jobs.markJobAsSentFailure";
    ActionType["CLEAR_MARK_JOB_AS_SENT"] = "jobs.clearMarkJobAsSent";
    ActionType["REQUEST_RESEND_JOB"] = "jobs.requestResendJob";
    ActionType["RESEND_JOB_SUCCESS"] = "jobs.resentJobSuccess";
    ActionType["RESEND_JOB_FAILURE"] = "jobs.resentJobFailure";
    ActionType["CLEAR_RESEND_JOB"] = "jobs.clearResendJob";
    ActionType["REQUEST_EDIT_JOB"] = "jobs.requestEditJob";
    ActionType["EDIT_JOB_SUCCESS"] = "jobs.editJobSuccess";
    ActionType["EDIT_JOB_FAILURE"] = "jobs.editJobFailure";
    ActionType["CLEAR_EDIT_JOB"] = "jobs.clearEditJob";
    ActionType["CLEAR_JOB_UPDATE_RESULTS"] = "jobs.clearJobUpdateResults";
    // JOB ATTACHMENTS
    ActionType["REQUEST_GET_JOB_ATTACHMENTS_FOR_JOB"] = "jobs.requestGetJobAttachmentsForJob";
    ActionType["GET_JOB_ATTACHMENT_SUCCESS"] = "jobs.getJobAttachmentSuccess";
    ActionType["GET_JOB_ATTACHMENT_FAILURE"] = "jobs.getJobAttachmentFailure";
    ActionType["CLEAR_JOB_ATTACHMENT"] = "jobs.clearJobAttachment";
    ActionType["CLEAR_JOB_ATTACHMENTS"] = "jobs.clearJobAttachments";
    ActionType["REQUEST_UPDATE_JOB_ATTACHMENT"] = "jobs.requestUpdateJobAttachment";
    ActionType["UPDATE_JOB_ATTACHMENT_SUCCESS"] = "jobs.updateJobAttachmentSuccess";
    ActionType["UPDATE_JOB_ATTACHMENT_FAILURE"] = "jobs.updateJobAttachmentFailure";
    // JOB LINE ITEM ATTACHMENTS
    ActionType["REQUEST_GET_JOB_LINE_ITEM_ATTACHMENTS_FOR_JOB_LINE_ITEM"] = "jobs.requestGetJobLineItemAttachmentsForJobLineItem";
    ActionType["GET_JOB_LINE_ITEM_ATTACHMENT_SUCCESS"] = "jobs.getJobLineItemAttachmentSuccess";
    ActionType["GET_JOB_LINE_ITEM_ATTACHMENT_FAILURE"] = "jobs.getJobLineItemAttachmentFailure";
    ActionType["CLEAR_JOB_LINE_ITEM_ATTACHMENT"] = "jobs.clearJobLineItemAttachment";
    ActionType["CLEAR_JOB_LINE_ITEM_ATTACHMENTS"] = "jobs.clearJobLineItemAttachments";
    ActionType["REQUEST_ADD_ATTACHMENT_TO_JOB_LINE_ITEM"] = "jobs.requestAddAttachmentToJobLineItem";
    ActionType["ADD_ATTACHMENT_TO_JOB_LINE_ITEM_SUCCESS"] = "jobs.addAttachmentToJobLineItemSuccess";
    ActionType["ADD_ATTACHMENT_TO_JOB_LINE_ITEM_FAILURE"] = "jobs.addAttachmentToJobLineItemFailure";
    ActionType["REQUEST_REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM"] = "jobs.requestRemoveAttachmentFromJobLineItem";
    ActionType["REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM_SUCCESS"] = "jobs.removeAttachmentFromJobLineItemSuccess";
    ActionType["REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM_FAILURE"] = "jobs.removeAttachmentFromJobLineItemFailure";
    // JOB LINE ITEM GROUPS
    ActionType["REQUEST_GET_JOB_LINE_ITEM_GROUPS_FOR_JOB"] = "jobs.requestGetJobLineItemGroupsForJob";
    ActionType["GET_JOB_LINE_ITEM_GROUP_SUCCESS"] = "jobs.getJobLineItemGroupSuccess";
    ActionType["GET_JOB_LINE_ITEM_GROUP_FAILURE"] = "jobs.getJobLineItemGroupFailure";
    ActionType["CLEAR_JOB_LINE_ITEM_GROUP"] = "jobs.clearJobLineItemGroup";
    ActionType["CLEAR_JOB_LINE_ITEM_GROUPS"] = "jobs.clearJobLineItemGroups";
    ActionType["REQUEST_ADD_LINE_ITEM_GROUP_TO_JOB"] = "jobs.requestAddLineItemGroupToJob";
    ActionType["ADD_LINE_ITEM_GROUP_TO_JOB_SUCCESS"] = "jobs.addLineItemGroupToJobSuccess";
    ActionType["ADD_LINE_ITEM_GROUP_TO_JOB_FAILURE"] = "jobs.addLineItemGroupToJobFailure";
    ActionType["REQUEST_CREATE_JOB_LINE_ITEM_GROUP"] = "jobs.requestCreateJobLineItemGroup";
    ActionType["CREATE_JOB_LINE_ITEM_GROUP_SUCCESS"] = "jobs.createJobLineItemGroupSuccess";
    ActionType["CREATE_JOB_LINE_ITEM_GROUP_FAILURE"] = "jobs.createJobLineItemGroupFailure";
    ActionType["REQUEST_UPDATE_JOB_LINE_ITEM_GROUP"] = "jobs.requestUpdateJobLineItemGroup";
    ActionType["UPDATE_JOB_LINE_ITEM_GROUP_SUCCESS"] = "jobs.updateJobLineItemGroupSuccess";
    ActionType["UPDATE_JOB_LINE_ITEM_GROUP_FAILURE"] = "jobs.updateJobLineItemGroupFailure";
    ActionType["REQUEST_DELETE_JOB_LINE_ITEM_GROUP"] = "jobs.requestDeleteJobLineItemGroup";
    ActionType["DELETE_JOB_LINE_ITEM_GROUP_SUCCESS"] = "jobs.deleteJobLineItemGroupSuccess";
    ActionType["DELETE_JOB_LINE_ITEM_GROUP_FAILURE"] = "jobs.deleteJobLineItemGroupFailure";
    ActionType["CLEAR_JOB_LINE_ITEM_GROUP_UPDATE_RESULTS"] = "jobs.clearJobLineItemGroupUpdateResults";
    // JOB LINE ITEMS
    ActionType["REQUEST_GET_JOB_LINE_ITEMS_FOR_JOB"] = "jobs.requestGetJobLineItemsForJob";
    ActionType["GET_JOB_LINE_ITEM_SUCCESS"] = "jobs.getJobLineItemSuccess";
    ActionType["GET_JOB_LINE_ITEM_FAILURE"] = "jobs.getJobLineItemFailure";
    ActionType["CLEAR_JOB_LINE_ITEM"] = "jobs.clearJobLineItem";
    ActionType["CLEAR_JOB_LINE_ITEMS"] = "jobs.clearJobLineItems";
    ActionType["REQUEST_CREATE_JOB_LINE_ITEM"] = "jobs.requestCreateJobLineItem";
    ActionType["CREATE_JOB_LINE_ITEM_SUCCESS"] = "jobs.createJobLineItemSuccess";
    ActionType["CREATE_JOB_LINE_ITEM_FAILURE"] = "jobs.createJobLineItemFailure";
    ActionType["REQUEST_UPDATE_JOB_LINE_ITEM"] = "jobs.requestUpdateJobLineItem";
    ActionType["UPDATE_JOB_LINE_ITEM_SUCCESS"] = "jobs.updateJobLineItemSuccess";
    ActionType["UPDATE_JOB_LINE_ITEM_FAILURE"] = "jobs.updateJobLineItemFailure";
    ActionType["REQUEST_DELETE_JOB_LINE_ITEM"] = "jobs.requestDeleteJobLineItem";
    ActionType["DELETE_JOB_LINE_ITEM_SUCCESS"] = "jobs.deleteJobLineItemSuccess";
    ActionType["DELETE_JOB_LINE_ITEM_FAILURE"] = "jobs.deleteJobLineItemFailure";
    ActionType["REQUEST_UPDATE_JOB_LINE_ITEM_FROM_LINE_ITEM"] = "jobs.requestUpdateJobLineItemFromLineItem";
    ActionType["CLEAR_JOB_LINE_ITEM_UPDATE_RESULTS"] = "jobs.clearJobLineItemUpdateResults";
})(ActionType || (ActionType = {}));
