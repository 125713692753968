import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SectionRadioButtons, } from '@/ui/components';
import { getFormServiceError } from '@bit/payaca-tech.payaca-core.helpers.error';
import './SelectValueModal.css';
import { faPercent, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BasicField } from '@payaca/components/basicField';
import { CurrencyField } from '@payaca/components/currencyField';
import { ValidatedForm } from '@payaca/components';
import { getIsRequiredIfTrueConditionValidator, getNumericalRangeFieldValidator, } from '@payaca/helpers/fieldValidationHelper';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { Modal } from '@payaca/components/modal';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { ErrorMessage } from '@payaca/components/feedbackMessage';
var ValueType = {
    AMOUNT: 'amount',
    FULL_AMOUNT: 'fullAmount',
    PERCENTAGE: 'percentage',
};
var SelectValueModal = function (_a) {
    var onChange = _a.onChange, onClose = _a.onClose, modalOptions = _a.modalOptions, valueAmount = _a.valueAmount, valuePercentage = _a.valuePercentage, open = _a.open;
    var _b = useState([]), items = _b[0], setItems = _b[1];
    var _c = useState(''), amountName = _c[0], setAmountName = _c[1];
    var _d = useState(''), percentageName = _d[0], setPercentageName = _d[1];
    var _e = useState(null), modalError = _e[0], setModalError = _e[1];
    useEffect(function () {
        var _a = modalOptions || {}, disallowAmount = _a.disallowAmount, disallowFullAmount = _a.disallowFullAmount, valueAmountName = _a.valueAmountName, valuePercentageName = _a.valuePercentageName;
        var items = [{ label: '%', value: ValueType.PERCENTAGE }];
        if (!disallowAmount) {
            items.splice(0, 0, { label: '£', value: ValueType.AMOUNT });
        }
        if (!disallowFullAmount) {
            items.push({ label: 'Full', value: ValueType.FULL_AMOUNT });
        }
        setItems(items);
        setAmountName(valueAmountName);
        setPercentageName(valuePercentageName);
    }, [modalOptions]);
    var saveValues = useCallback(function (formState, remove) {
        var _a;
        setModalError(null);
        var percentage = formState.percentage;
        if ((modalOptions === null || modalOptions === void 0 ? void 0 : modalOptions.maximumValuePercentage) &&
            percentage > modalOptions.maximumValuePercentage) {
            // TODO: currently only specific to 0% finance
            setModalError(getFormServiceError('job', 'validateMinimumDepositPercentageAboveDepositMaximum', {
                maxDeposit: modalOptions.maximumValuePercentage,
            }));
            return;
        }
        if (!isNaN(modalOptions === null || modalOptions === void 0 ? void 0 : modalOptions.minimumValuePercentage) &&
            percentage < modalOptions.minimumValuePercentage) {
            // TODO: currently only specific to 0% finance
            setModalError(getFormServiceError('job', 'validateMinimumDepositPercentageAboveDepositMinimum', {
                minDeposit: modalOptions.minimumValuePercentage,
            }));
            return;
        }
        onChange((_a = {},
            _a[percentageName] = remove ? null : formState.percentage,
            _a[amountName] = remove ? null : formState.amount,
            _a));
        onClose();
    }, [onClose, onChange, modalOptions, amountName, percentageName]);
    var initialFormState = useMemo(function () {
        var valueType = ValueType.AMOUNT;
        if (!!valueAmount && (valuePercentage === null || valuePercentage === 0)) {
            valueType = ValueType.AMOUNT;
        }
        else if (valuePercentage && valuePercentage === 100) {
            valueType = ValueType.FULL_AMOUNT;
        }
        else {
            valueType = ValueType.PERCENTAGE;
        }
        return {
            amount: valueType === ValueType.AMOUNT ? valueAmount || 0 : null,
            percentage: valueType !== ValueType.AMOUNT ? valuePercentage || 0 : null,
            valueType: valueType,
        };
    }, [valueAmount, valuePercentage]);
    var fieldValidators = useMemo(function () {
        return {
            percentage: [
                getIsRequiredIfTrueConditionValidator(function (formState) {
                    return formState.valueType === ValueType.PERCENTAGE ||
                        formState.valueType === ValueType.FULL_AMOUNT;
                }),
                getNumericalRangeFieldValidator(-0.1, undefined, undefined, 'Percentage must be greater than 0'),
            ],
            amount: [
                getIsRequiredIfTrueConditionValidator(function (formState) { return formState.valueType === ValueType.AMOUNT; }),
                getNumericalRangeFieldValidator(-0.1, undefined, undefined, 'Amount must not be less than £0'),
            ],
        };
    }, []);
    return (React.createElement(Modal, { isOpen: open, title: modalOptions.title, onClose: function () { return onClose(); }, size: "xs", className: "select-value-modal" },
        modalOptions.infoText && (React.createElement("div", { className: "info-text" }, modalOptions.infoText)),
        React.createElement(ValidatedForm, { initialFormState: initialFormState, fieldValidators: fieldValidators, renderFormContents: function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
                return (React.createElement("div", null,
                    React.createElement("div", { className: "form-contents" },
                        React.createElement("div", { className: "input-field-container" },
                            formState.valueType === ValueType.AMOUNT && (React.createElement(ValidatedFieldWrapper, { isTouched: true, validationResult: validationState.amount },
                                React.createElement(CurrencyField, { styleVariant: InputStyleVariant.OUTSIZE, value: formState.amount, name: 'amount', onChange: onFieldChange }))),
                            formState.valueType === ValueType.PERCENTAGE && (React.createElement(ValidatedFieldWrapper, { isTouched: true, validationResult: validationState.percentage },
                                React.createElement(BasicField, { styleVariant: InputStyleVariant.OUTSIZE, value: formState.percentage, name: 'percentage', onChange: function (value) {
                                        onFieldChange({
                                            percentage: value.percentage
                                                ? Math.round(value.percentage * 100) / 100
                                                : value.percentage,
                                        });
                                    }, type: "number", additionalInputProps: {
                                        pattern: '[0-9]*',
                                        min: 0,
                                        max: 100,
                                        step: 1,
                                    }, iconAfter: faPercent })))),
                        React.createElement("div", null, items.length > 1 && (React.createElement(SectionRadioButtons, { value: formState.valueType, items: items, onChange: function (value) {
                                var percentage = null;
                                var amount = null;
                                if (value === ValueType.AMOUNT) {
                                    amount = 0;
                                }
                                else if (value === ValueType.PERCENTAGE) {
                                    percentage = 0;
                                }
                                else if (value === ValueType.FULL_AMOUNT) {
                                    percentage = 100;
                                }
                                onFieldChange({
                                    percentage: percentage,
                                    amount: amount,
                                    valueType: value,
                                });
                            } }))),
                        modalError && React.createElement(ErrorMessage, { message: modalError })),
                    React.createElement("div", { className: "actions-container" },
                        React.createElement(Button, { isDisabled: !isValid, onClick: function () { return saveValues(formState); }, styleVariant: ButtonStyleVariant.OUTSIZE }, "Save"),
                        React.createElement(Button, { onClick: function () { return saveValues(formState, true); }, styleVariant: ButtonStyleVariant.ANCHOR, iconAfter: faTimes }, modalOptions.removeButtonTitle))));
            } })));
};
export default SelectValueModal;
