import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import './polyfills';

import '@payaca/styles';
import App from './App';
import { store, persistor } from './api';
import * as api from './services/api';

import { PersistGate } from 'redux-persist/integration/react';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_CLIENT_ID,
    environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
  });
}
api.setStore(store);

if (process.env.REACT_APP_ENV !== 'staging') {
  console.log = () => null;
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
