import React, { useEffect, useCallback, } from 'react';
import './KeyIndicatorsData.css';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { actions as analyticsActions } from '@bit/payaca-tech.payaca-core.store.analytics';
import { KeyIndicatorType, } from '@bit/payaca-tech.payaca-core.types.analytics';
import KeyIndicatorData from '../keyIndicatorData/KeyIndicatorData';
import { currencyPrice } from '@bit/payaca-tech.payaca-core.helpers.finance';
import { DATE_SHORT_FORMAT } from '@payaca/constants';
var KeyIndicatorsData = function () {
    var dispatch = useDispatch();
    var keyIndicatorsData = useSelector(function (state) {
        return state.analytics.keyIndicatorsData;
    });
    useEffect(function () {
        dispatch(analyticsActions.requestGetKeyIndicatorsData());
        return function () {
            dispatch(analyticsActions.clearKeyIndicatorsData());
        };
    }, []);
    var renderKeyIndicatorData = useCallback(function (keyIndicatorData, index) {
        var title;
        var formatValue;
        var explainerText;
        switch (keyIndicatorData.type) {
            case KeyIndicatorType.REVENUE_SECURED:
                title = 'Revenue secured';
                formatValue = currencyPrice;
                explainerText =
                    'This represents the total value (excluding VAT) of revenue due from quotes which have been accepted in this period';
                break;
            case KeyIndicatorType.REVENUE_RECEIVED:
                title = 'Revenue received';
                formatValue = currencyPrice;
                explainerText =
                    'This represents the total value of payments received in this period. Not including payouts or deposit payments from 0% quotes at this time.';
                break;
            case KeyIndicatorType.CONVERSION_RATE:
                title = 'Quote conversion rate';
                formatValue = function (value) { return (value * 100).toFixed(2) + "%"; };
                explainerText =
                    'This represents the percentage of sent quotes which have been accepted.';
                break;
            default:
                title = keyIndicatorData.type;
        }
        return (React.createElement(KeyIndicatorData, { title: title, titleMicrocopy: 'Past 30 days', explainerText: explainerText, currentPeriodValue: keyIndicatorData.currentPeriodValue, previousPeriodValue: keyIndicatorData.previousPeriodValue, percentageChange: keyIndicatorData.percentageChange, formatValue: formatValue }));
    }, []);
    if (!keyIndicatorsData)
        return null;
    return (React.createElement("div", { className: "key-indicators-data" },
        React.createElement("div", null,
            React.createElement("div", { className: "key-indicator-data-container flex-container" }, keyIndicatorsData.keyIndicatorData.map(renderKeyIndicatorData))),
        React.createElement("div", { className: "microcopy-container" },
            React.createElement("p", { className: "microcopy" },
                "Current data represented relates to a",
                ' ',
                keyIndicatorsData.periodLengthInDays,
                " day period starting on the",
                ' ',
                moment(keyIndicatorsData.currentPeriod.fromDate).format(DATE_SHORT_FORMAT),
                ' ',
                "and finishing on the",
                ' ',
                moment(keyIndicatorsData.currentPeriod.toDate).format(DATE_SHORT_FORMAT),
                "."),
            React.createElement("p", { className: "microcopy" },
                "Previous data represented relates to a",
                ' ',
                keyIndicatorsData.periodLengthInDays,
                " day period starting on the",
                ' ',
                moment(keyIndicatorsData.previousPeriod.fromDate).format(DATE_SHORT_FORMAT),
                ' ',
                "and finishing on the",
                ' ',
                moment(keyIndicatorsData.previousPeriod.toDate).format(DATE_SHORT_FORMAT),
                "."))));
};
export default KeyIndicatorsData;
