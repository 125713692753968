var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Fragment, useCallback, useEffect, useMemo, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import isEqual from 'lodash.isequal';
import get from 'lodash.get';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { actions as userActions } from '@/api/users';
import { actions as appActions } from '@/api/app';
import { actions as accountActions } from '@payaca/store/account';
import { actions as accountSettingsActions } from '@/api/accountSettings';
import { parseAccount } from '@/helpers/userHelper';
import { getModal } from '@/helpers/modalHelper';
import { getManagedAttachments } from '@/helpers/jobHelper';
import { FormValidations } from '@/helpers/formHelper';
import { getFieldErrors } from '@/helpers/formHelper';
import StringUtil from '@/utils/stringUtil';
var CompanySettingsSaveButton = function (_a) {
    var buttonTitle = _a.buttonTitle, _b = _a.isModal, isModal = _b === void 0 ? false : _b, onSaveSuccess = _a.onSaveSuccess;
    var _c = useState([]), blockedEmails = _c[0], setBlockedEmails = _c[1];
    var _d = useState(false), isSaving = _d[0], setIsSaving = _d[1];
    var dispatch = useDispatch();
    useEffect(function () {
        dispatch(userActions.getBlockedEmails(function (err, blockedEmails) {
            if (blockedEmails) {
                setBlockedEmails(blockedEmails);
            }
        }));
    }, []);
    var currentAccount = useSelector(function (state) { var _a, _b; return (_b = (_a = state.users) === null || _a === void 0 ? void 0 : _a.myProfile) === null || _b === void 0 ? void 0 : _b.accounts[0]; });
    var pendingAccount = useSelector(function (state) { return state.accountSettings.pendingAccount; });
    var currentBusinessAttachments = useSelector(function (state) { return state.users.businessAttachments; });
    var currentTerms = useSelector(function (state) { return state.users.terms; });
    var pendingTerms = useSelector(function (state) { return state.accountSettings.pendingTerms; });
    var pendingBusinessAttachments = useSelector(function (state) { return state.accountSettings.pendingBusinessAttachments; });
    var currentEmailTemplates = useSelector(function (state) { return state.account.emailTemplates; });
    var pendingEmailTemplates = useSelector(function (state) { return state.accountSettings.pendingEmailTemplates; });
    var currentFinancePlans = useSelector(function (state) { return state.users.financePlans; });
    var pendingFinancePlans = useSelector(function (state) { return state.accountSettings.pendingFinancePlans; });
    var getDifferences = function (pendingData, existingData) {
        if (pendingData && existingData) {
            return Object.entries(pendingData).reduce(function (acc, val) {
                if (!isEqual(val[1], existingData[val[0]])) {
                    acc[val[0]] = val[1];
                }
                return acc;
            }, {});
        }
        return {};
    };
    var emailTemplatesChanges = useMemo(function () { return getDifferences(pendingEmailTemplates, currentEmailTemplates); }, [currentEmailTemplates, getDifferences, pendingEmailTemplates]);
    var changesMade = useMemo(function () {
        var existingAccount = parseAccount(currentAccount);
        var detailDifferences = getDifferences(pendingAccount, existingAccount);
        var financeDifferences = getDifferences(pendingFinancePlans, currentFinancePlans);
        // if updating one address line, add changes to other line to ensure whole address is saved together
        if (detailDifferences.firstLineAddress !== undefined) {
            detailDifferences.secondLineAddress = pendingAccount.secondLineAddress;
        }
        else if (detailDifferences.secondLineAddress !== undefined) {
            detailDifferences.firstLineAddress = pendingAccount.firstLineAddress;
        }
        var differences = {};
        if (Object.keys(detailDifferences).length) {
            differences.details = detailDifferences;
        }
        if (pendingTerms !== currentTerms) {
            differences.terms = pendingTerms;
        }
        if (pendingBusinessAttachments !== currentBusinessAttachments) {
            differences.businessAttachments = pendingBusinessAttachments;
        }
        if (Object.keys(emailTemplatesChanges).length) {
            differences.emailTemplates = emailTemplatesChanges;
        }
        if (Object.keys(financeDifferences).length) {
            differences.financePlans = pendingFinancePlans;
        }
        return differences;
    }, [
        currentAccount,
        currentBusinessAttachments,
        currentFinancePlans,
        currentTerms,
        emailTemplatesChanges,
        getDifferences,
        pendingAccount,
        pendingBusinessAttachments,
        pendingFinancePlans,
        pendingTerms,
    ]);
    var onSavingComplete = function (errors) {
        if (errors === void 0) { errors = []; }
        if (errors.length) {
            setIsSaving(false);
            var errorList = StringUtil.list(errors.filter(function (f) { return f; }));
            var closeModal = function () { return dispatch(appActions.hideModal()); };
            if (errorList) {
                dispatch(appActions.showModal(getModal('UPDATE_ACCOUNT_ERROR_LIST', {
                    primaryAction: closeModal,
                    onClose: closeModal,
                    text: [errorList],
                })));
            }
            else {
                dispatch(appActions.showModal(getModal('UPDATE_ACCOUNT_ERROR_GENERAL', {
                    primaryAction: closeModal,
                    onClose: closeModal,
                })));
            }
        }
        else {
            dispatch(userActions.getProfile(function () {
                setIsSaving(false);
                onSaveSuccess && onSaveSuccess();
            }));
        }
    };
    var onSave = function () {
        setIsSaving(true);
        saveFields();
    };
    var saveBusinessAttachments = useCallback(function (errors) {
        if (errors === void 0) { errors = []; }
        if (changesMade.businessAttachments) {
            var managedAttachments = getManagedAttachments(currentBusinessAttachments, changesMade.businessAttachments);
            dispatch(userActions.manageBusinessAttachments(managedAttachments.toAdd, managedAttachments.toRemove, function (err) {
                if (!err) {
                    // fetch updated business attachments
                    dispatch(userActions.getBusinessAttachments());
                }
                err && errors.push('business attachments');
                onSavingComplete(errors);
            }));
        }
        else {
            onSavingComplete(errors);
        }
    }, [changesMade, currentBusinessAttachments, dispatch, onSavingComplete]);
    var saveTerms = useCallback(function (errors) {
        if (errors === void 0) { errors = []; }
        if (changesMade.terms) {
            var managedAttachments = getManagedAttachments(currentTerms, changesMade.terms);
            dispatch(userActions.manageTermsDocuments(currentAccount.id, managedAttachments.toAdd, managedAttachments.toRemove, function (err) {
                if (!err) {
                    // fetch updated terms
                    dispatch(userActions.getAccountTerms(currentAccount.id));
                }
                err && errors.push('business terms');
                saveBusinessAttachments(errors);
            }));
        }
        else {
            saveBusinessAttachments(errors);
        }
    }, [
        changesMade,
        currentAccount,
        currentTerms,
        dispatch,
        saveBusinessAttachments,
    ]);
    var saveLogo = useCallback(function (errors) {
        var _a, _b;
        if (errors === void 0) { errors = []; }
        if ((_b = (_a = changesMade === null || changesMade === void 0 ? void 0 : changesMade.details) === null || _a === void 0 ? void 0 : _a.logoUrl) === null || _b === void 0 ? void 0 : _b.uri) {
            dispatch(userActions.uploadAccountLogo(currentAccount.id, changesMade.details.logoUrl, function (err, resp) {
                err && errors.push('logo');
                saveTerms(errors);
            }));
        }
        else {
            saveTerms(errors);
        }
    }, [changesMade, currentAccount, dispatch, saveTerms]);
    var saveFinancePlans = useCallback(function (errors) {
        if (errors === void 0) { errors = []; }
        if (changesMade.financePlans) {
            dispatch(userActions.requestUpdateFinancePlans(changesMade.financePlans, function (err) {
                err && errors.push('finance plans');
                saveLogo(errors);
            }));
        }
        else {
            saveLogo(errors);
        }
    }, [changesMade, dispatch, saveLogo]);
    var saveEmailTemplates = useCallback(function (errors) {
        if (errors === void 0) { errors = []; }
        if (changesMade.emailTemplates) {
            dispatch(accountActions.requestUpdateEmailTemplates(currentAccount.id, changesMade.emailTemplates, function (err) {
                err && errors.push('email templates');
                saveFinancePlans(errors);
            }));
        }
        else {
            saveFinancePlans(errors);
        }
    }, [changesMade, currentAccount, dispatch, saveFinancePlans]);
    var saveFields = useCallback(function () {
        var _a;
        if (changesMade.details) {
            var changesToSave = changesMade.details;
            if ((_a = changesMade === null || changesMade === void 0 ? void 0 : changesMade.details) === null || _a === void 0 ? void 0 : _a.logoUrl) {
                // save logoUrl as null if new logo/removed logo, this gets updated on account when uploading logo
                changesToSave = __assign(__assign({}, changesMade.details), { logoUrl: null });
            }
            dispatch(userActions.updateBusinessAccount(currentAccount.id, changesToSave, function (err) {
                saveEmailTemplates(err
                    ? getReadableFieldErrors(Object.keys(changesMade.details))
                    : []);
            }));
        }
        else {
            saveEmailTemplates();
        }
    }, [changesMade, currentAccount, dispatch, saveEmailTemplates]);
    var getReadableFieldErrors = function (fields) {
        var fieldsToReadable = {
            businessName: 'trading name',
            contactNumber: 'contact number',
            businessNotes: 'business notes',
            firstLineAddress: 'address',
            city: 'city',
            email: 'email',
            postcode: 'postcode',
            sendInvoiceReminderAfterDue3Days: '3 days after invoice reminder',
            sendInvoiceReminderAfterDue7Days: '7 days before invoice reminder',
            sendInvoiceReminderBeforeDue3Days: '3 days before invoice reminder',
            sendInvoiceReminderWhenDue: 'invoice due reminder',
            vatNumber: 'vat number',
            paymentTerms: 'payment terms',
            accountName: 'account name',
            accountNumber: 'account number',
            sortCode: 'sort code',
            hideItemPrices: 'hide item prices',
        };
        return fields.map(function (f) { return fieldsToReadable[f]; });
    };
    var isInvalid = useMemo(function () {
        if (changesMade.details) {
            var isInvalid_1 = Object.entries(changesMade.details).reduce(function (acc, change) {
                var fieldValidation = get(FormValidations.businessSettings, [
                    change[0],
                ]);
                var error = getFieldErrors(change[1], fieldValidation, {
                    blockedEmails: blockedEmails,
                });
                if (error.length) {
                    acc = true;
                }
                return acc;
            }, false);
            return isInvalid_1;
        }
        return false;
    }, [changesMade]);
    var discardChanges = useCallback(function () {
        dispatch(accountSettingsActions.storeAccountPendingChanges(parseAccount(currentAccount)));
        dispatch(accountSettingsActions.storeAccountPendingBusinessAttachments(currentBusinessAttachments));
        dispatch(accountSettingsActions.storeAccountPendingTerms(currentTerms));
        dispatch(accountSettingsActions.storeAccountPendingFinancePlans(currentFinancePlans));
        dispatch(accountSettingsActions.storeAccountPendingEmailTemplates({
            sendEstimate: currentEmailTemplates.sendEstimate,
            sendQuote: currentEmailTemplates.sendQuote,
            sendInvoice: currentEmailTemplates.sendInvoice,
        }));
    }, [
        currentAccount,
        currentBusinessAttachments,
        currentTerms,
        currentFinancePlans,
        currentEmailTemplates,
        dispatch,
    ]);
    return (React.createElement(Fragment, null,
        React.createElement(Button, { isProcessing: isSaving, isDisabled: !Object.keys(changesMade).length || isInvalid, onClick: function () { return onSave(); }, styleVariant: ButtonStyleVariant.OUTSIZE }, buttonTitle || 'Save changes'),
        React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: discardChanges, isDisabled: !Object.keys(changesMade).length }, "Discard changes"),
        React.createElement(Prompt, { when: !isModal && !!Object.keys(changesMade).length && !isSaving, message: "There are unsaved changes on the page, are you sure you want to leave?" })));
};
export default CompanySettingsSaveButton;
