import React, { useCallback, useMemo, useState } from 'react';
import { IntercomAPI } from 'react-intercom';
import { useDispatch } from 'react-redux';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { actions as usersActions } from '@/api/users';
import { getUrlSearchParam } from '@payaca/helpers/urlHelper';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import UnauthenticatedPageWrapper from '../pageWrappers/unauthenticatedPageWrapper/UnauthenticatedPageWrapper';
import InitialRegisterControl from '@/ui/components/initialRegisterControl/InitialRegisterControl';
import RegisterForm from '@/ui/components/registerForm/RegisterForm';
import './RegisterPage.sass';
var PageState;
(function (PageState) {
    PageState["INITIAL"] = "initial";
    PageState["REGISTER_FORM"] = "register-form";
})(PageState || (PageState = {}));
var RegisterPage = function (_a) {
    var location = _a.location, history = _a.history;
    var dispatch = useDispatch();
    var _b = useState(PageState.INITIAL), pageState = _b[0], setPageState = _b[1];
    var _c = useState(), initialRegisterFormState = _c[0], setInitialregisterFormState = _c[1];
    var goToLoginPage = useCallback(function () {
        history.push('/login');
    }, [history]);
    var referralToken = useMemo(function () {
        var token = null;
        token = getUrlSearchParam(location.search, 'referralToken');
        if (!token) {
            token = getUrlSearchParam(document.referrer, 'referralToken');
        }
        return token;
    }, [location]);
    var onRegisterCallback = useCallback(function (error) {
        if (!error) {
            dispatch(usersActions.getProfile(function (err, myProfile) {
                IntercomAPI('registerIdentifiedUser', {
                    userId: myProfile.name,
                });
                var userDetails = {
                    email: myProfile.email,
                    user_id: myProfile.name,
                    name: myProfile.name,
                    phone: myProfile.contactNumber,
                    using_tradesperson_web: true,
                };
                IntercomAPI('update', userDetails);
            }));
        }
    }, [dispatch]);
    return (React.createElement(UnauthenticatedPageWrapper, { headerContent: React.createElement("div", { className: "flex-container" },
            pageState === PageState.REGISTER_FORM && (React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () { return setPageState(PageState.INITIAL); }, iconBefore: faChevronLeft }, "Back")),
            React.createElement("div", { className: "flex-grow" }),
            React.createElement("span", { className: "login-prompt" },
                "Got an account?",
                ' ',
                React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: goToLoginPage }, "Log in"))), className: "register-page", titleContent: pageState === PageState.INITIAL ? (React.createElement("h1", null, "Sign up")) : pageState === PageState.REGISTER_FORM ? (React.createElement("h2", null, "Sign up with email")) : null },
        pageState === PageState.INITIAL && (React.createElement(React.Fragment, null,
            React.createElement(InitialRegisterControl, { onRegisterCallback: onRegisterCallback, referralToken: referralToken || undefined, onInitialRegisterFormSubmit: function (formState) {
                    setInitialregisterFormState(formState);
                    setPageState(PageState.REGISTER_FORM);
                } }))),
        pageState === PageState.REGISTER_FORM && (React.createElement(React.Fragment, null,
            React.createElement(RegisterForm, { initialFormState: initialRegisterFormState })))));
};
export default RegisterPage;
