import React, { useCallback, useMemo, useState } from 'react';
import { getProductPriceCostExcludingVat } from '@payaca/helpers/subscriptionHelper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { LabelValuePair } from '@payaca/components/labelValuePair';
import './CollapsibleSubscriptionInformation.sass';
import { CollapsiblePanel, CollapsiblePanelStyleVariant, } from '@payaca/components/collapsiblePanel';
import { Button, ButtonColourVariant, ButtonStyleVariant, } from '@payaca/components/button';
import { ErrorMessage } from '@payaca/components/feedbackMessage';
import moment from 'moment';
import { actions as appActions } from '@/api/app';
import { actions as subscriptionActions } from '@bit/payaca-tech.payaca-core.store.subscription';
import { getModal } from '@/helpers/modalHelper';
import { useDispatch, useSelector } from 'react-redux';
var CollapsibleSubscriptionInformation = function (_a) {
    var accountSubscription = _a.accountSubscription, taxRatePercentage = _a.taxRatePercentage;
    var dispatch = useDispatch();
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var subscriptionEndsAt = useMemo(function () {
        return accountSubscription.subscriptionInformation.subscriptionEndDate;
    }, [accountSubscription]);
    var additionalUserSeats = useMemo(function () { return accountSubscription.subscriptionInformation.additionalUserSeats || 0; }, [accountSubscription]);
    var costExcludingVat = useMemo(function () {
        if (!accountSubscription.subscriptionProduct)
            return null;
        return (getProductPriceCostExcludingVat(accountSubscription.productPrice, additionalUserSeats) || null);
    }, [accountSubscription, additionalUserSeats]);
    var vat = useMemo(function () {
        if (!costExcludingVat)
            return 0;
        return costExcludingVat * (taxRatePercentage / 100);
    }, [costExcludingVat, taxRatePercentage]);
    var costIncludingVat = useMemo(function () {
        if (!costExcludingVat)
            return null;
        return costExcludingVat + vat;
    }, [costExcludingVat, vat]);
    var paymentMethodDescription = useMemo(function () {
        if (!accountSubscription)
            return null;
        var subscriptionInformation = accountSubscription.subscriptionInformation;
        return subscriptionInformation.stripePaymentMethodBrand + "   \u2022\u2022\u2022\u2022 " + subscriptionInformation.stripePaymentMethodLast4 + "   " + subscriptionInformation.stripePaymentMethodExpiryMonth + "/" + subscriptionInformation.stripePaymentMethodExpiryYear;
    }, [accountSubscription]);
    var hasAnySubscriptionBonuses = useMemo(function () {
        if (!accountSubscription)
            return null;
        var subscriptionInformation = accountSubscription.subscriptionInformation;
        return (!!subscriptionInformation.bonusAdditionalUserSeats ||
            !!subscriptionInformation.bonusAdditionalJobsPerMonth);
    }, [accountSubscription]);
    var header = useMemo(function () {
        if (!accountSubscription)
            return React.createElement(React.Fragment, null);
        return (React.createElement("div", { className: "product-name-container flex-container" },
            React.createElement("div", { className: "flex-grow" },
                React.createElement("small", null, "Current plan"),
                React.createElement("h3", null, accountSubscription.subscriptionProduct.name)),
            React.createElement("div", null,
                !isOpen && (React.createElement(Button, { onClick: function () {
                        setIsOpen(true);
                    } }, "View current plan")),
                isOpen && (React.createElement(Button, { onClick: function () {
                        setIsOpen(false);
                    }, isOutlined: true }, "Hide current plan")))));
    }, [accountSubscription, subscriptionEndsAt, isOpen]);
    var body = useMemo(function () {
        if (!accountSubscription)
            return React.createElement(React.Fragment, null);
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "subscription-information-container" },
                React.createElement("div", { className: "features-container" },
                    React.createElement("p", null,
                        accountSubscription.subscriptionProduct.numberOfJobsPerMonth,
                        ' ',
                        "jobs per month"),
                    React.createElement("p", null, additionalUserSeats
                        ? additionalUserSeats +
                            (accountSubscription.subscriptionProduct
                                .numberOfUserSeats || 0) + " user seats (" + accountSubscription.subscriptionProduct.numberOfUserSeats + " + " + additionalUserSeats + " additional)"
                        : accountSubscription.subscriptionProduct.numberOfUserSeats + " " + (accountSubscription.subscriptionProduct
                            .numberOfUserSeats === 1
                            ? 'user seat'
                            : 'user seats'))),
                (accountSubscription === null || accountSubscription === void 0 ? void 0 : accountSubscription.productPrice) && (React.createElement(React.Fragment, null,
                    React.createElement("hr", null),
                    React.createElement("div", { className: "costs-container" },
                        React.createElement(LabelValuePair, { label: "Subscription cost", value: currencyPrice(accountSubscription.productPrice.basicCost) }),
                        !!accountSubscription.subscriptionInformation
                            .additionalUserSeats && (React.createElement(LabelValuePair, { label: "Additional user seats (" + additionalUserSeats + " x " + currencyPrice(accountSubscription.productPrice.additionalUserSeatCost ||
                                0) + ")", value: currencyPrice(additionalUserSeats *
                                (accountSubscription.productPrice
                                    .additionalUserSeatCost || 0)) })),
                        React.createElement(LabelValuePair, { label: "VAT (" + taxRatePercentage + "%)", value: currencyPrice(vat) }),
                        costIncludingVat && (React.createElement(LabelValuePair, { label: "Total cost recurring " + (accountSubscription.recurringInterval === 'month'
                                ? 'monthly'
                                : 'annually'), value: currencyPrice(costIncludingVat || 0) })))))),
            hasAnySubscriptionBonuses && (React.createElement("div", { className: "subscription-bonuses-container" },
                React.createElement("p", null,
                    React.createElement("strong", null, "Bonuses:")),
                React.createElement("ul", null,
                    !!accountSubscription.subscriptionInformation
                        .bonusAdditionalUserSeats && (React.createElement("li", null,
                        accountSubscription.subscriptionInformation
                            .bonusAdditionalUserSeats,
                        ' ',
                        "extra user",
                        ' ',
                        accountSubscription.subscriptionInformation
                            .bonusAdditionalUserSeats === 1
                            ? 'seat'
                            : 'seats')),
                    !!accountSubscription.subscriptionInformation
                        .bonusAdditionalJobsPerMonth && (React.createElement("li", null,
                        accountSubscription.subscriptionInformation
                            .bonusAdditionalJobsPerMonth,
                        ' ',
                        "extra",
                        ' ',
                        accountSubscription.subscriptionInformation
                            .bonusAdditionalJobsPerMonth === 1
                            ? 'job'
                            : 'jobs',
                        ' ',
                        "per month")))))));
    }, [
        accountSubscription,
        additionalUserSeats,
        hasAnySubscriptionBonuses,
        costIncludingVat,
        paymentMethodDescription,
        taxRatePercentage,
        vat,
    ]);
    var handleRestoreSubscriptionAsyncBehaviour = useCallback(function () {
        setRestoreSubscriptionAttemptMade(true);
        setTimeout(function () { return dispatch(subscriptionActions.requestGetAccountSubscription()); }, 5000);
    }, [dispatch]);
    var isRestoringSubscription = useSelector(function (state) { return state.subscription.isRestoringSubscription; });
    var _c = useState(false), restoreSubscriptionAttemptMade = _c[0], setRestoreSubscriptionAttemptMade = _c[1];
    var restoreSubscription = useCallback(function () {
        dispatch(appActions.showModal(getModal('RESTORE_SUBSCRIPTION', {
            primaryAction: function () {
                dispatch(subscriptionActions.requestRestoreSubscription());
                handleRestoreSubscriptionAsyncBehaviour();
                dispatch(appActions.hideModal());
            },
            secondaryAction: function () { return dispatch(appActions.hideModal()); },
            onClose: function () { return dispatch(appActions.hideModal()); },
        })));
    }, [dispatch, handleRestoreSubscriptionAsyncBehaviour]);
    if (!accountSubscription)
        return null;
    return (React.createElement("div", { className: "collapsible-subscription-information" },
        React.createElement(CollapsiblePanel, { isOpen: isOpen, showControlIcons: false, title: header, styleVariant: CollapsiblePanelStyleVariant.UNSTYLED }, body),
        subscriptionEndsAt && (React.createElement("div", { className: "subscription-ends-container" },
            React.createElement(ErrorMessage, { message: "subscription ends at " + moment(subscriptionEndsAt).format('Do MMMM YYYY') }),
            React.createElement(Button, { colourVariant: ButtonColourVariant.PRIMARY, styleVariant: ButtonStyleVariant.OUTSIZE, onClick: restoreSubscription, isProcessing: isRestoringSubscription }, "Restore subscription")))));
};
export default CollapsibleSubscriptionInformation;
