import React, { useCallback, useMemo, useState, } from 'react';
import './AdvancedCreateEditCustomerControl.sass';
import { ValidatedForm } from '@payaca/components';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { BasicField } from '@payaca/components/basicField';
import CustomerContactsControl from '../customerContactsControl/CustomerContactsControl';
import { getFieldValidatorsForCustomer } from '@bit/payaca-tech.payaca-core.helpers.customer-validation';
import CustomerAddressInputControl from '../customerAddressInputControl/CustomerAddressInputControl';
var AdvancedCreateEditCustomerControl = function (_a) {
    var inputStyleVariant = _a.inputStyleVariant, initialFormState = _a.initialFormState, blockedEmails = _a.blockedEmails, renderActions = _a.renderActions, onFormStateChange = _a.onFormStateChange;
    var _b = useState(0), contactsCount = _b[0], setContactsCount = _b[1];
    var fieldValidators = useMemo(function () {
        return getFieldValidatorsForCustomer(contactsCount, blockedEmails);
    }, [contactsCount, blockedEmails === null || blockedEmails === void 0 ? void 0 : blockedEmails.length]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        var _a;
        onFormStateChange && onFormStateChange(formState);
        setContactsCount(formState.contacts ? formState.contacts.length : 0);
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "form-section" },
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.name, isTouched: touchedState.name || false },
                    React.createElement(BasicField, { styleVariant: inputStyleVariant, isRequired: true, label: 'Name', value: formState.name || '', name: "name", onChange: onFieldChange, onTouch: onFieldTouch }))),
            React.createElement("div", { className: "form-section" },
                React.createElement("h4", null, "Contacts"),
                React.createElement(CustomerContactsControl, { inputStyleVariant: inputStyleVariant, customerContacts: formState.contacts, onChange: onFieldChange, onTouch: onFieldTouch, validationState: validationState, touchedState: touchedState, fieldNamePrefix: "contacts" })),
            React.createElement("hr", null),
            React.createElement("div", { className: "form-section" },
                React.createElement("h4", null, "Address"),
                React.createElement(CustomerAddressInputControl, { inputStyleVariant: inputStyleVariant, customerAddress: ((_a = formState.contacts) === null || _a === void 0 ? void 0 : _a.length) ? formState.addresses[0] : {}, fieldNamePrefix: 'addresses[0]', onChange: onFieldChange, onTouch: onFieldTouch, validationState: validationState, touchedState: touchedState, showDescriptionField: false })),
            renderActions && (React.createElement("div", { className: "actions-container" }, renderActions(isValid, formState)))));
    }, [renderActions]);
    return (React.createElement("div", { className: "advanced-create-edit-customer-control" },
        React.createElement(ValidatedForm, { fieldValidators: fieldValidators, initialFormState: initialFormState, renderFormContents: renderFormContents })));
};
export default AdvancedCreateEditCustomerControl;
