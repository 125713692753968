export var DuologiFinanceApplicationStatusTypes;
(function (DuologiFinanceApplicationStatusTypes) {
    DuologiFinanceApplicationStatusTypes["PROPOSAL"] = "PROPOSAL";
    DuologiFinanceApplicationStatusTypes["ACCEPTED"] = "ACCEPTED";
    DuologiFinanceApplicationStatusTypes["DECLINED"] = "DECLINED";
    DuologiFinanceApplicationStatusTypes["REFERRED"] = "REFERRED";
    DuologiFinanceApplicationStatusTypes["INFO"] = "INFO";
    DuologiFinanceApplicationStatusTypes["ACTION_CUSTOMER"] = "ACTION-CUSTOMER";
    DuologiFinanceApplicationStatusTypes["ACTION_RETAILER"] = "ACTION-RETAILER";
    DuologiFinanceApplicationStatusTypes["ACTION_LENDER"] = "ACTION-LENDER";
    DuologiFinanceApplicationStatusTypes["DEPOSIT_PAID"] = "DEPOSIT-PAID";
    DuologiFinanceApplicationStatusTypes["SIGNED"] = "SIGNED";
    DuologiFinanceApplicationStatusTypes["READY"] = "READY";
    DuologiFinanceApplicationStatusTypes["AWAITING_ACTIVATION"] = "AWAITING-ACTIVATION";
    DuologiFinanceApplicationStatusTypes["AWAITING_CANCELLATION"] = "AWAITING-CANCELLATION";
    DuologiFinanceApplicationStatusTypes["PARTIALLY_ACTIVATED"] = "PARTIALLY-ACTIVATED";
    DuologiFinanceApplicationStatusTypes["ACTIVATED"] = "ACTIVATED";
    DuologiFinanceApplicationStatusTypes["CANCELLED"] = "CANCELLED";
    DuologiFinanceApplicationStatusTypes["REFUNDED"] = "REFUNDED";
    DuologiFinanceApplicationStatusTypes["COMPLETED"] = "COMPLETED";
})(DuologiFinanceApplicationStatusTypes || (DuologiFinanceApplicationStatusTypes = {}));
export var DuologiFinanceApplicationEventTypes;
(function (DuologiFinanceApplicationEventTypes) {
    DuologiFinanceApplicationEventTypes["PROPOSAL_CREATED"] = "proposal-created";
    DuologiFinanceApplicationEventTypes["APPLICATION_STATUS_UPDATE"] = "application-status-update";
})(DuologiFinanceApplicationEventTypes || (DuologiFinanceApplicationEventTypes = {}));
export var FinanceApplicationTypeTypes;
(function (FinanceApplicationTypeTypes) {
    FinanceApplicationTypeTypes["DUOLOGI"] = "DUOLOGI";
    FinanceApplicationTypeTypes["SHERMIN_DUOLOGI"] = "SHERMIN-DUOLOGI";
})(FinanceApplicationTypeTypes || (FinanceApplicationTypeTypes = {}));
