import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import CreateEditLineItemGroupControl from '@/ui/components/createEditLineItemGroupControl/CreateEditLineItemGroupControl';
import './NewEditItemGroupPage.sass';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
var NewEditItemGroupPage = function () {
    var params = useParams();
    var lineItemGroupId = useMemo(function () { return params.lineItemGroupId; }, [params]);
    return (React.createElement(AuthenticatedPageWrapper, { className: "edit-item-group-page" },
        React.createElement(CreateEditLineItemGroupControl, { lineItemGroupId: lineItemGroupId })));
};
export default NewEditItemGroupPage;
