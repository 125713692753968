export var ActionType;
(function (ActionType) {
    ActionType["REQUEST_RECORD_JOB_PAYMENT"] = "jobPayments.requestRecordJobPayment";
    ActionType["RECORD_JOB_PAYMENT_SUCCESS"] = "jobPayments.recordJobPaymentSuccess";
    ActionType["RECORD_JOB_PAYMENT_FAILURE"] = "jobPayments.recordJobPaymentFailure";
    ActionType["REQUEST_CONFIRM_JOB_PAYMENT"] = "jobPayments.requestConfirmJobPayment";
    ActionType["CONFIRM_JOB_PAYMENT_SUCCESS"] = "jobPayments.confirmJobPaymentSuccess";
    ActionType["CONFIRM_JOB_PAYMENT_FAILURE"] = "jobPayments.confirmJobPaymentFailure";
    ActionType["REQUEST_GET_JOB_PAYMENTS_FOR_DEAL"] = "jobPayments.requestGetJobPaymentsForDeal";
    ActionType["REQUEST_GET_JOB_PAYMENT"] = "jobPayments.requestGetJobPayment";
    ActionType["GET_JOB_PAYMENT_SUCCESS"] = "jobPayments.getJobPaymentSuccess";
    ActionType["GET_JOB_PAYMENT_FAILURE"] = "jobPayments.getJobPaymentFailure";
    ActionType["CLEAR_JOB_PAYMENT"] = "jobPayments.clearJobPayment";
    ActionType["CLEAR_JOB_PAYMENTS"] = "jobPayments.clearJobPayments";
})(ActionType || (ActionType = {}));
