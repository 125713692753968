import React, { useMemo } from 'react';
import LabelValuePair from '../labelValuePair/LabelValuePair';
var SupportingDocumentsOverview = function (_a) {
    var _b;
    var supportingDocuments = _a.supportingDocuments, directors = _a.directors, _c = _a.isSoleTrader, isSoleTrader = _c === void 0 ? false : _c;
    var genericSupportingDocumentsAsString = useMemo(function () {
        var genericSupportingDocumentsFileNames = supportingDocuments === null || supportingDocuments === void 0 ? void 0 : supportingDocuments.genericSupportingDocuments.map(function (supportingDocument) {
            return supportingDocument.fileName;
        });
        return genericSupportingDocumentsFileNames
            ? genericSupportingDocumentsFileNames.join(', ')
            : '';
    }, [supportingDocuments]);
    return (React.createElement("div", { className: "supporting-documents-overview" },
        React.createElement("div", { className: "multiline-label-value" },
            React.createElement(LabelValuePair, { label: isSoleTrader
                    ? 'Please upload your most recent tax return'
                    : 'Please upload your most recent accounts filed with Companies House. If these are older than 9 months please also attach updated management accounts', value: genericSupportingDocumentsAsString })),
        React.createElement("div", { className: "multiline-label-value" },
            React.createElement(LabelValuePair, { label: "Please upload a copy of a bank statement dated within the last 3 months", value: (_b = supportingDocuments === null || supportingDocuments === void 0 ? void 0 : supportingDocuments.bankStatementDocument) === null || _b === void 0 ? void 0 : _b.fileName })),
        React.createElement("div", null,
            React.createElement("span", null,
                React.createElement("strong", null, "Please also upload a scan or image of a passport or driving licence as photo ID for the following people:")),
            directors &&
                directors.map(function (director) {
                    var _a, _b;
                    var directorIdDocument = (_b = (_a = supportingDocuments === null || supportingDocuments === void 0 ? void 0 : supportingDocuments.directorIdDocuments) === null || _a === void 0 ? void 0 : _a.find(function (directorIdDocument) {
                        return directorIdDocument.directorId === director.id;
                    })) === null || _b === void 0 ? void 0 : _b.document;
                    return (React.createElement(LabelValuePair, { label: director.name || '', value: (directorIdDocument === null || directorIdDocument === void 0 ? void 0 : directorIdDocument.fileName) || '' }));
                })),
        React.createElement("div", null,
            React.createElement("span", null,
                React.createElement("strong", null, "Please upload a scan or image of a proof of address document for the following people:")),
            directors &&
                directors.map(function (director) {
                    var _a, _b;
                    var directorAddressDocument = (_b = (_a = supportingDocuments === null || supportingDocuments === void 0 ? void 0 : supportingDocuments.directorAddressDocuments) === null || _a === void 0 ? void 0 : _a.find(function (directorAddressDocument) {
                        return directorAddressDocument.directorId === director.id;
                    })) === null || _b === void 0 ? void 0 : _b.document;
                    return (React.createElement(LabelValuePair, { label: director.name || '', value: (directorAddressDocument === null || directorAddressDocument === void 0 ? void 0 : directorAddressDocument.fileName) || '' }));
                }))));
};
export default SupportingDocumentsOverview;
