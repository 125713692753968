import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { currencyPrice } from '@bit/payaca-tech.payaca-core.helpers.finance';
import { Badge } from '@payaca/components/badge';
import moment from 'moment';
import { Button, } from '@payaca/components/button';
import './DealPaymentItem.sass';
import { DATE_TIMESTAMP_FORMAT } from '@payaca/constants';
export var DealPaymentItem = function (_a) {
    var jobPaymentId = _a.jobPaymentId, setShowRecordPaymentModal = _a.setShowRecordPaymentModal;
    var _b = useSelector(function (state) {
        var _a, _b;
        return ((_b = (_a = state === null || state === void 0 ? void 0 : state.jobPayments) === null || _a === void 0 ? void 0 : _a.jobPayments[jobPaymentId]) === null || _b === void 0 ? void 0 : _b.entity) || {};
    }), isDepositPayment = _b.isDepositPayment, paymentValue = _b.paymentValue, paymentMethod = _b.paymentMethod, paymentCompletedConfirmationAt = _b.paymentCompletedConfirmationAt, paymentFailedConfirmationAt = _b.paymentFailedConfirmationAt, stripePaymentAttemptedAt = _b.stripePaymentAttemptedAt, bacsPaymentMadeAt = _b.bacsPaymentMadeAt;
    var customer = useSelector(function (state) {
        return state.customer.currentCustomer;
    });
    var paymentStatus = useMemo(function () {
        if (paymentFailedConfirmationAt) {
            return 'Failed';
        }
        if (paymentCompletedConfirmationAt) {
            return 'Complete';
        }
        return 'Pending';
    }, [paymentFailedConfirmationAt, paymentCompletedConfirmationAt]);
    return (React.createElement("div", { className: "deal-payment-item " + paymentStatus.toLowerCase() },
        React.createElement("div", { className: "deal-payment-item-inner flex-container" },
            React.createElement("div", { className: "deal-payment-amount" },
                React.createElement("h3", null, currencyPrice(paymentValue))),
            React.createElement("div", { className: 'deal-payment-data' },
                React.createElement("div", { className: "flex-container flex-center payment-label" },
                    React.createElement("h4", null, isDepositPayment ? "Deposit Payment" : 'Payment'),
                    React.createElement(Badge, null, paymentStatus)),
                React.createElement("div", null,
                    "Payment method: ",
                    paymentMethod),
                React.createElement("div", null,
                    React.createElement("small", null,
                        "Recorded by ",
                        customer.name,
                        " on",
                        ' ',
                        moment(bacsPaymentMadeAt ||
                            stripePaymentAttemptedAt ||
                            paymentCompletedConfirmationAt).format(DATE_TIMESTAMP_FORMAT)))),
            bacsPaymentMadeAt &&
                !paymentFailedConfirmationAt &&
                !paymentCompletedConfirmationAt && (React.createElement("div", { className: 'deal-payment-action' },
                React.createElement(Button, { onClick: function () { return setShowRecordPaymentModal(true); } }, "Confirm Payment"))))));
};
