import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ContactUsCallout } from '@/ui/components';
import { actions as userActions } from '@/api/users';
import './TrialPeriodExpiryCallout.css';
var TrialPeriodExpiryCallout = function () {
    var dispatch = useDispatch();
    var accountAccessInformation = useSelector(function (state) {
        return state.account.accountAccessInformation;
    });
    var adminUserEmails = useSelector(function (state) {
        var _a;
        if (!((_a = state === null || state === void 0 ? void 0 : state.users) === null || _a === void 0 ? void 0 : _a.accountUsers))
            return [];
        return state.users.accountUsers
            .filter(function (user) { return user.isAdmin && !!user.verifiedAt && !user.deactivatedAt; })
            .map(function (user) { return user.email; });
    });
    var companyName = useSelector(function (state) {
        var _a, _b, _c;
        if (!((_b = (_a = state === null || state === void 0 ? void 0 : state.users) === null || _a === void 0 ? void 0 : _a.myProfile) === null || _b === void 0 ? void 0 : _b.accounts))
            return '';
        return (_c = state.users.myProfile.accounts[0]) === null || _c === void 0 ? void 0 : _c.companyName;
    });
    var isCurrentUserAdmin = useSelector(function (state) { return state.users.myProfile.isAdmin; });
    useEffect(function () {
        dispatch(userActions.getAccountUsers());
    }, []);
    var nonAdminContent = useMemo(function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("span", null,
                "Contact one of the administrators of the ",
                companyName,
                " account to continue using Payaca:"),
            React.createElement("ul", null, adminUserEmails.map(function (adminUserEmail, index) { return (React.createElement("li", { key: index }, adminUserEmail)); }))));
    }, [adminUserEmails, companyName]);
    return (React.createElement(ContactUsCallout, { title: (accountAccessInformation === null || accountAccessInformation === void 0 ? void 0 : accountAccessInformation.hasInactiveSubscription) ? 'Your account does not have an active subscription'
            : 'Your demo has ended!' }, isCurrentUserAdmin
        ? 'Please choose a subscription plan to continue using Payaca.'
        : nonAdminContent));
};
export default TrialPeriodExpiryCallout;
