import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Bookmark, Document } from 'react-iconly';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import SetupAccountCompanyForm from '@/ui/components/setupAccountCompanyForm/SetupAccountCompanyForm';
import SetupAccountBrandingForm from '@/ui/components/setupAccountBrandingForm/SetupAccountBrandingForm';
import UnauthenticatedPageWrapper from '../pageWrappers/unauthenticatedPageWrapper/UnauthenticatedPageWrapper';
import { actions as authActions } from '@bit/payaca-tech.payaca-core.store.auth';
import './SetupAccountPage.sass';
var PageState;
(function (PageState) {
    PageState["COMPANY"] = "company";
    PageState["BRANDING"] = "branding";
})(PageState || (PageState = {}));
var SetupAccountCompanyPage = function () {
    var history = useHistory();
    var dispatch = useDispatch();
    var _a = useState(PageState.COMPANY), pageState = _a[0], setPageState = _a[1];
    var _b = useState(), accountId = _b[0], setAccountId = _b[1];
    var pageIcon = useMemo(function () {
        if (pageState === PageState.COMPANY) {
            return React.createElement(Document, { set: "light", size: "xlarge" });
        }
        else if (pageState === PageState.BRANDING) {
            return React.createElement(Bookmark, { set: "light", size: "xlarge" });
        }
    }, [pageState]);
    var pageTitleText = useMemo(function () {
        if (pageState === PageState.COMPANY) {
            return 'Add your company information';
        }
        else if (pageState === PageState.BRANDING) {
            return 'Add your branding information';
        }
    }, [pageState]);
    var pageInstructionsText = useMemo(function () {
        if (pageState === PageState.COMPANY) {
            return 'Please enter your company information below.';
        }
        else if (pageState === PageState.BRANDING) {
            return 'Please upload your logo and brand colours below.';
        }
    }, [pageState]);
    return (React.createElement(UnauthenticatedPageWrapper, { className: "setup-account-page", headerContent: React.createElement("div", { className: "flex-container" },
            React.createElement("div", { className: "flex-grow" }),
            React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () { return dispatch(authActions.logout()); } }, "Log out")), titleContent: pageTitleText && (React.createElement(React.Fragment, null,
            pageIcon,
            React.createElement("h3", { className: "page-title" }, pageTitleText))) },
        pageInstructionsText && (React.createElement("p", { className: "page-instructions" }, pageInstructionsText)),
        pageState === PageState.COMPANY && (React.createElement(SetupAccountCompanyForm, { onSetupAccountCallback: function (accountId) {
                setPageState(PageState.BRANDING);
                setAccountId(accountId);
            } })),
        pageState === PageState.BRANDING && (React.createElement(SetupAccountBrandingForm, { onSetupAccountCallback: function () { return history.push('/upgradeAccount'); }, accountId: accountId }))));
};
export default SetupAccountCompanyPage;
