import React from 'react';
import { BasicField } from '@payaca/components/basicField';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
var SearchBar = function (_a) {
    var inputValue = _a.inputValue, onSearch = _a.onSearch, placeholder = _a.placeholder;
    return (React.createElement("div", { className: "search-bar" },
        React.createElement(BasicField, { name: "searchTerm", onChange: function (value) {
                onSearch(value.searchTerm);
            }, value: inputValue, iconBefore: faSearch, additionalInputProps: {
                placeholder: placeholder,
            } })));
};
export default SearchBar;
