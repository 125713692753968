import React from 'react';
import { Modal } from '@payaca/components/modal';
import './ValidationResultModal.css';
var ValidationResultModal = function (_a) {
    var isOpen = _a.isOpen, title = _a.title, validationResult = _a.validationResult, onClose = _a.onClose;
    return (React.createElement(Modal, { isOpen: isOpen, title: title || '', onClose: function () { return onClose(); }, className: "validation-result-modal", size: "sm" },
        React.createElement("p", null, "Resolve the following validation errors and try again"),
        validationResult.errors.length && (React.createElement("ul", { className: "validation-errors" }, validationResult.errors.map(function (error, index) { return (React.createElement("li", { key: "validation-result-error-" + index }, error)); })))));
};
export default ValidationResultModal;
