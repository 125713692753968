export var AuthActionTypes;
(function (AuthActionTypes) {
    AuthActionTypes["LOGOUT"] = "auth.logout";
    AuthActionTypes["CLEAR_ANALYTICS_USER_ID"] = "auth.clearAnalyticsUserId";
    AuthActionTypes["REQUEST_LOGIN"] = "auth.requestLogin";
    AuthActionTypes["REQUEST_LOGIN_WITH_FACEBOOK"] = "auth.requestLoginWithFacebook";
    AuthActionTypes["REQUEST_LOGIN_WITH_XERO"] = "auth.requestLoginWithXero";
    AuthActionTypes["REQUEST_LOGIN_WITH_GOOGLE"] = "auth.requestLoginWithGoogle";
    AuthActionTypes["REQUEST_LOGIN_WITH_APPLE"] = "auth.requestLoginWithApple";
    AuthActionTypes["REQUEST_LOGIN_WITH_TOKEN"] = "auth.requestLoginWithToken";
    AuthActionTypes["LOGIN_SUCCESS"] = "auth.loginSuccess";
    AuthActionTypes["LOGIN_FAILURE"] = "auth.loginFailure";
    AuthActionTypes["REQUEST_SIGN_UP_WITH_FACEBOOK"] = "auth.requestSignUpWithFacebook";
    AuthActionTypes["REQUEST_SIGN_UP_WITH_XERO"] = "auth.requestSignUpWithXero";
    AuthActionTypes["REQUEST_SIGN_UP_WITH_GOOGLE"] = "auth.requestSignUpWithGoogle";
    AuthActionTypes["REQUEST_SIGN_UP_WITH_APPLE"] = "auth.requestSignUpWithApple";
    AuthActionTypes["SIGN_UP_FAILURE"] = "auth.signUpFailure";
    AuthActionTypes["SIGN_UP_SUCCESS"] = "auth.signUpSuccess";
    AuthActionTypes["REQUEST_REFRESH_AUTH_TOKEN"] = "auth.requestRefreshAuthToken";
    AuthActionTypes["REFRESH_AUTH_TOKEN_SUCCESS"] = "auth.refreshAuthTokenSuccess";
    AuthActionTypes["REFRESH_AUTH_TOKEN_FAILURE"] = "auth.refreshAuthTokenFailure";
    AuthActionTypes["STORE_TOKENS"] = "auth.storeTokens";
    AuthActionTypes["STORE_TOKENS_SUCCESS"] = "auth.storeTokensSuccess";
    AuthActionTypes["AUTHORISE"] = "auth.authorise";
    AuthActionTypes["REGISTER_O_AUTH_CLIENT"] = "auth.registerOAuthClient";
    AuthActionTypes["REMOVE_O_AUTH_CONNECTION"] = "auth.removeOAuthConnection";
    AuthActionTypes["VERIFY_O_AUTH"] = "auth.verifyOAuth";
})(AuthActionTypes || (AuthActionTypes = {}));
