import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, matchPath, useLocation } from 'react-router';
import { Calendar, Chart, People, Work, Bag2, Category, } from 'react-iconly';
import { actions as appActions } from '@/api/app';
import { NavigationSidebar as NavigationSidebarBase, NavigationMenuItem, } from '@payaca/components/navigationSidebar';
var NavigationSidebar = function (_a) {
    var _b = _a.isSmallView, isSmallView = _b === void 0 ? false : _b;
    var dispatch = useDispatch();
    var history = useHistory();
    var location = useLocation();
    var isHidden = useSelector(function (state) { return state.app.navigationSidebarIsHidden; });
    var isCollapsed = useSelector(function (state) { return state.app.navigationSidebarIsCollapsed; });
    var accountAccessInformation = useSelector(function (state) { return state.account.accountAccessInformation; });
    var myProfile = useSelector(function (state) { return state.users.myProfile; });
    var isAdmin = useMemo(function () { return myProfile === null || myProfile === void 0 ? void 0 : myProfile.isAdmin; }, [myProfile]);
    var trialPeriodIsExpired = useMemo(function () {
        return accountAccessInformation
            ? !accountAccessInformation.hasActiveSubscription &&
                accountAccessInformation.isTrialPeriodExpired
            : false;
    }, [accountAccessInformation]);
    var isNotOnboarded = useMemo(function () {
        return accountAccessInformation
            ? !accountAccessInformation.hasActiveSubscription &&
                !accountAccessInformation.hasInactiveSubscription &&
                !accountAccessInformation.isOnboarded
            : true;
    }, [accountAccessInformation]);
    var hasExceededUserSeats = useMemo(function () {
        if (!accountAccessInformation)
            return false;
        return (accountAccessInformation.hasActiveSubscription &&
            accountAccessInformation.hasExceededActiveSubscriptionUserSeats);
    }, [accountAccessInformation]);
    var renderStandardNavigationMenuItem = useCallback(function (title, navigateToLocation, isDisabled, IconComponent, isComingSoon) {
        if (isComingSoon === void 0) { isComingSoon = false; }
        var isSelected = !!matchPath(location.pathname, navigateToLocation);
        return (React.createElement(NavigationMenuItem, { title: title, isComingSoon: isComingSoon, isDisabled: isDisabled, isSelected: isSelected, onClick: function () { return history.push(navigateToLocation); }, iconElement: React.createElement(IconComponent, { size: "xlarge", set: isSelected ? 'bold' : 'light' }) }));
    }, [location, history]);
    var customersNavigationMenuItem = useMemo(function () {
        var navigateToLocation = '/customers';
        var isSelected = !!matchPath(location.pathname, navigateToLocation);
        return (React.createElement(NavigationMenuItem, { title: 'Customers', isDisabled: trialPeriodIsExpired || isNotOnboarded || hasExceededUserSeats, isSelected: isSelected, onClick: function () { return history.push(navigateToLocation); }, iconElement: React.createElement("img", { src: require(isSelected
                    ? './customers-icon-bold.svg'
                    : './customers-icon.svg').default }) }));
    }, [
        location,
        history,
        trialPeriodIsExpired,
        isNotOnboarded,
        hasExceededUserSeats,
    ]);
    useEffect(function () {
        if (isSmallView) {
            dispatch(appActions.hideNavigationSidebar());
            dispatch(appActions.expandNavigationSidebar());
        }
        else {
            dispatch(appActions.showNavigationSidebar());
        }
    }, [isSmallView]);
    return (React.createElement(NavigationSidebarBase, { currentAccount: myProfile.accounts[0], onClickCurrentAccount: function () { return history.push('/companySettings'); }, isCollapsed: isCollapsed, isHidden: isHidden, isSmallView: isSmallView, onToggleIsCollapsed: function (setIsCollapsed) {
            return !setIsCollapsed
                ? dispatch(appActions.expandNavigationSidebar())
                : dispatch(appActions.collapseNavigationSidebar());
        }, onToggleIsHidden: function (setIsHidden) {
            return !setIsHidden
                ? dispatch(appActions.showNavigationSidebar())
                : dispatch(appActions.hideNavigationSidebar());
        } },
        renderStandardNavigationMenuItem('Deals', '/deals', trialPeriodIsExpired || isNotOnboarded || hasExceededUserSeats, Work),
        customersNavigationMenuItem,
        renderStandardNavigationMenuItem('Items', '/items', trialPeriodIsExpired || isNotOnboarded || hasExceededUserSeats, Bag2),
        React.createElement("hr", null),
        renderStandardNavigationMenuItem('Item Groups', '/itemGroups', trialPeriodIsExpired || isNotOnboarded || hasExceededUserSeats, Category),
        React.createElement("hr", null),
        isAdmin &&
            renderStandardNavigationMenuItem('Business Performance', '/analytics', trialPeriodIsExpired || isNotOnboarded || hasExceededUserSeats, Chart),
        isAdmin &&
            renderStandardNavigationMenuItem('Users', '/users', trialPeriodIsExpired || isNotOnboarded, People),
        renderStandardNavigationMenuItem('Calendar', '/calendar', true, // TODO: build the calendar!
        Calendar, true)));
};
export default NavigationSidebar;
