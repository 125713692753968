import React, { useMemo, useState } from 'react';
import './DealPayments.sass';
import DealPaymentSummary from '@/ui/components/dealPaymentSummary/DealPaymentSummary';
import { DealFinanceItem } from '@/ui/components/dealFinanceItem/DealFinanceItem';
import { DealPaymentItem } from '@/ui/components/dealPaymentItem/DealPaymentItem';
import { getActiveJobFinanceInformationByDealId, getJobFinanceInformationByDealId, getJobPaymentsByDealId, } from '@/utils/stateAccessors';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ConfirmPaymentModal from '../confirmPaymentModal/ConfirmPaymentModal';
import { DATE_TIMESTAMP_FORMAT } from '@payaca/constants';
var getLatestDateFromJobPayment = function (jobPayment) {
    var array = [
        jobPayment.bacsPaymentMadeAt,
        jobPayment.stripePaymentAttemptedAt,
        jobPayment.paymentCompletedConfirmationAt,
        jobPayment.paymentFailedConfirmationAt,
    ]
        .filter(function (x) { return !!x; })
        .map(function (x) { return moment(x); });
    return moment.max(array).toDate();
};
// change to deal payment
var DealPayments = function (_a) {
    var dealId = _a.dealId, onDealUpdateSuccess = _a.onDealUpdateSuccess;
    var _b = useState(), confirmPaymentId = _b[0], setConfirmPaymentId = _b[1];
    var jobFinanceInformations = useSelector(function (state) {
        return getJobFinanceInformationByDealId(state, dealId);
    });
    var activeJobFinanceInformation = useSelector(function (state) {
        return getActiveJobFinanceInformationByDealId(state, dealId);
    });
    var jobPayments = useSelector(function (state) {
        return getJobPaymentsByDealId(state, dealId);
    });
    var sortedInactiveJobFinanceInformations = useMemo(function () {
        if (!jobFinanceInformations)
            return [];
        return jobFinanceInformations
            .filter(function (x) { return x.id !== (activeJobFinanceInformation === null || activeJobFinanceInformation === void 0 ? void 0 : activeJobFinanceInformation.id) && !!x; })
            .sort(function (j1, j2) {
            return (j1.applicationStartedAt || new Date(0)) <
                (j2.applicationStartedAt || new Date(0))
                ? 1
                : -1;
        });
    }, [jobFinanceInformations, activeJobFinanceInformation]);
    var sortedJobPayments = useMemo(function () {
        if (!jobPayments)
            return [];
        return jobPayments.sort(function (p1, p2) {
            var p1Date = getLatestDateFromJobPayment(p1);
            var p2Date = getLatestDateFromJobPayment(p2);
            return p1Date < p2Date ? 1 : -1;
        });
    }, [jobFinanceInformations, activeJobFinanceInformation]);
    var pendingJobPayments = useMemo(function () {
        return sortedJobPayments.filter(function (x) { return !x.paymentFailedConfirmationAt && !x.paymentCompletedConfirmationAt; });
    }, [sortedJobPayments]);
    var nonPendingJobPayments = useMemo(function () {
        return sortedJobPayments.filter(function (x) {
            return !!x.paymentFailedConfirmationAt || !!x.paymentCompletedConfirmationAt;
        });
    }, [sortedJobPayments]);
    var customer = useSelector(function (state) {
        return state.customer.currentCustomer;
    });
    return (React.createElement("div", { className: "deal-payments" },
        React.createElement("div", { className: "deal-payment-summary-container" },
            React.createElement(DealPaymentSummary, { dealId: dealId })),
        !!(activeJobFinanceInformation === null || activeJobFinanceInformation === void 0 ? void 0 : activeJobFinanceInformation.satisfactionNoteSignedAt) && (React.createElement("div", { className: "satisfaction-note-signed-block deal-payment-item complete" },
            React.createElement("div", { className: "deal-payment-data" },
                React.createElement("h4", null, "Satisfaction note signed"),
                React.createElement("div", null, "Finance payouts should be expected within 3 working days of the satisfaction note signature date"),
                React.createElement("small", null,
                    "Signed by ",
                    customer.name,
                    " on",
                    ' ',
                    moment(activeJobFinanceInformation.satisfactionNoteSignedAt).format(DATE_TIMESTAMP_FORMAT))))),
        !(sortedJobPayments === null || sortedJobPayments === void 0 ? void 0 : sortedJobPayments.length) && !(jobFinanceInformations === null || jobFinanceInformations === void 0 ? void 0 : jobFinanceInformations.length) && (React.createElement("h4", { style: { textAlign: 'center' } }, "Looks like you haven't had any payments yet")),
        !!(sortedJobPayments === null || sortedJobPayments === void 0 ? void 0 : sortedJobPayments.length) && (React.createElement("div", { className: "job-payments-container" },
            pendingJobPayments.map(function (jobPayment, index) {
                return (React.createElement(DealPaymentItem, { key: "pending-deal-payment-item-" + index, jobPaymentId: jobPayment.id, setShowRecordPaymentModal: function () {
                        return setConfirmPaymentId(jobPayment.id);
                    } }));
            }),
            nonPendingJobPayments.map(function (jobPayment, index) {
                return (React.createElement(DealPaymentItem, { key: "deal-payment-item-" + index, jobPaymentId: jobPayment.id, setShowRecordPaymentModal: function () {
                        return setConfirmPaymentId(jobPayment.id);
                    } }));
            }))),
        !!(jobFinanceInformations === null || jobFinanceInformations === void 0 ? void 0 : jobFinanceInformations.length) && (React.createElement("div", { className: "job-finance-information-container" },
            activeJobFinanceInformation && (React.createElement(DealFinanceItem, { jobFinanceInformationId: activeJobFinanceInformation.id })),
            sortedInactiveJobFinanceInformations.map(function (jobFinanceInformation, index) {
                return (React.createElement(DealFinanceItem, { key: "deal-finance-item-" + index, jobFinanceInformationId: jobFinanceInformation.id }));
            }))),
        !!confirmPaymentId && (React.createElement(ConfirmPaymentModal, { jobPaymentId: confirmPaymentId, isOpen: !!confirmPaymentId, onClose: function () {
                setConfirmPaymentId(undefined);
            }, confirmPaymentCallback: function () {
                setConfirmPaymentId(undefined);
                onDealUpdateSuccess();
            } }))));
};
export default DealPayments;
