import React, { useMemo, useCallback, } from 'react';
import { useSelector } from 'react-redux';
import { NavigationMenuItem } from '@payaca/components/navigationSidebar';
import { Bookmark, Edit, Graph, TwoUsers, User, } from 'react-iconly';
import './ProfileNavigation.sass';
import { matchPath, useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
var ProfileNavigation = function () {
    var history = useHistory();
    //   const dispatch = useDispatch();
    var location = useLocation();
    var myProfile = useSelector(function (state) { return state.users.myProfile; });
    var isAdmin = useMemo(function () { return myProfile === null || myProfile === void 0 ? void 0 : myProfile.isAdmin; }, [myProfile]);
    var accountAccessInformation = useSelector(function (state) { return state.account.accountAccessInformation; });
    var trialPeriodIsExpired = useMemo(function () {
        return accountAccessInformation
            ? !accountAccessInformation.hasActiveSubscription &&
                accountAccessInformation.isTrialPeriodExpired
            : false;
    }, [accountAccessInformation]);
    var isNotOnboarded = useMemo(function () {
        return accountAccessInformation
            ? !accountAccessInformation.hasActiveSubscription &&
                !accountAccessInformation.hasInactiveSubscription &&
                !accountAccessInformation.isOnboarded
            : true;
    }, [accountAccessInformation]);
    var hasExceededUserSeats = useMemo(function () {
        if (!accountAccessInformation)
            return false;
        return (accountAccessInformation.hasActiveSubscription &&
            accountAccessInformation.hasExceededActiveSubscriptionUserSeats);
    }, [accountAccessInformation]);
    var renderStandardNavigationMenuItem = useCallback(function (title, navigateToLocation, isDisabled, IconComponent) {
        var isSelected = !!matchPath(location.pathname, navigateToLocation);
        return (React.createElement("div", { className: "navigation-menu-item-wrapper" },
            React.createElement(NavigationMenuItem, { title: React.createElement("span", { className: "flex-container flex-center" },
                    React.createElement("span", { className: "flex-grow" }, title),
                    React.createElement(FontAwesomeIcon, { icon: faChevronRight })), isDisabled: isDisabled, isSelected: isSelected, onClick: function () { return history.push(navigateToLocation); }, iconElement: React.createElement(IconComponent, { size: "xlarge", set: true ? 'bold' : 'light' }) })));
    }, [history]);
    return (React.createElement("nav", { className: "profile-navigation", "aria-label": "secondary-profile" },
        renderStandardNavigationMenuItem('Personal Information', '/personalSettings', false, User),
        isAdmin &&
            renderStandardNavigationMenuItem('My company', '/companySettings', trialPeriodIsExpired || isNotOnboarded || hasExceededUserSeats, Bookmark),
        isAdmin &&
            renderStandardNavigationMenuItem('Manage subscription', '/upgradeAccount', false, Edit),
        renderStandardNavigationMenuItem('Finance options', '/financeOptions', isNotOnboarded, Graph),
        renderStandardNavigationMenuItem('Refer a friend', '/referAFriend', trialPeriodIsExpired || isNotOnboarded, TwoUsers)));
};
export default ProfileNavigation;
