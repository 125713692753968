import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ModalWrapper } from '@/ui/components';

import { actions as appActions } from '@/api/app';
import { actions as usersActions } from '@/api/users';
import { actions as accountActions } from '@bit/payaca-tech.payaca-core.store.account';

import { FormValidations } from '@/helpers/formHelper';
import SetupAccountCompanyForm from '../setupAccountCompanyForm/SetupAccountCompanyForm';

class CreateBusinessAccount extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ModalWrapper
        open={this.props.open}
        title="Create business account"
        onClose={() => {
          return;
        }}
        classes="business-account-modal"
      >
        <SetupAccountCompanyForm
          onSetupAccountCallback={() => this.props.onClose()}
        />
      </ModalWrapper>
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => ({
    app: bindActionCreators(appActions, dispatch),
    users: bindActionCreators(usersActions, dispatch),
    account: bindActionCreators(accountActions, dispatch),
  })
)(CreateBusinessAccount);
