import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import './CustomerAddressInputControl.sass';
import { BasicField } from '@payaca/components/basicField';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { AddressInputControl } from '@payaca/components/addressInputControl';
import LabelValuePair from '@bit/payaca-tech.payaca-core.component.label-value-pair';
import { faChevronRight, faSearch, faTimes, } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { actions as usersActions } from '@/api/users';
import ResponsiveViewWrapper from '@bit/payaca-tech.payaca-core.component.responsive-view-wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonStyleVariant, } from '@payaca/components/button';
var CustomerAddressInputControl = function (_a) {
    var _b, _c, _d, _e, _f;
    var inputStyleVariant = _a.inputStyleVariant, customerAddress = _a.customerAddress, fieldNamePrefix = _a.fieldNamePrefix, touchedState = _a.touchedState, validationState = _a.validationState, _g = _a.showDescriptionField, showDescriptionField = _g === void 0 ? true : _g, onTouch = _a.onTouch, onChange = _a.onChange;
    var dispatch = useDispatch();
    var _h = useState(false), isEnteringAddress = _h[0], setIsEnteringAddress = _h[1];
    var _j = useState(false), isEnteringAddressManually = _j[0], setIsEnteringAddressManually = _j[1];
    var _k = useState(null), addressLookupPostcode = _k[0], setAddressLookupPostcode = _k[1];
    var _l = useState(false), isLookingUpAddresses = _l[0], setIsLookingUpAddresses = _l[1];
    var _m = useState(), addressLookupResults = _m[0], setAddressLookupResults = _m[1];
    var descriptionFieldName = useMemo(function () {
        return fieldNamePrefix ? fieldNamePrefix + ".description" : 'description';
    }, [fieldNamePrefix]);
    var addressFieldName = useMemo(function () {
        return fieldNamePrefix ? fieldNamePrefix + ".address" : 'address';
    }, [fieldNamePrefix]);
    var addressAsString = useMemo(function () {
        var address = customerAddress === null || customerAddress === void 0 ? void 0 : customerAddress.address;
        if (!address)
            return;
        var addressElements = [];
        addressElements.push(address.line1);
        addressElements.push(address.line2);
        addressElements.push(address.city);
        addressElements.push(address.postcode);
        return addressElements.filter(function (x) { return !!x; }).join(', ');
    }, [
        (_b = customerAddress === null || customerAddress === void 0 ? void 0 : customerAddress.address) === null || _b === void 0 ? void 0 : _b.postcode,
        (_c = customerAddress === null || customerAddress === void 0 ? void 0 : customerAddress.address) === null || _c === void 0 ? void 0 : _c.city,
        (_d = customerAddress === null || customerAddress === void 0 ? void 0 : customerAddress.address) === null || _d === void 0 ? void 0 : _d.line1,
        (_e = customerAddress === null || customerAddress === void 0 ? void 0 : customerAddress.address) === null || _e === void 0 ? void 0 : _e.line2,
    ]);
    useEffect(function () {
        var _a;
        setAddressLookupPostcode((_a = customerAddress === null || customerAddress === void 0 ? void 0 : customerAddress.address) === null || _a === void 0 ? void 0 : _a.postcode);
    }, [(_f = customerAddress === null || customerAddress === void 0 ? void 0 : customerAddress.address) === null || _f === void 0 ? void 0 : _f.postcode]);
    useEffect(function () {
        setAddressLookupResults(undefined);
    }, [addressLookupPostcode]);
    var lookupAddressesFromPostcode = useCallback(function () {
        setIsLookingUpAddresses(true);
        dispatch(usersActions.lookupPostcode(addressLookupPostcode, function (error, response) {
            setAddressLookupResults(response);
            setIsLookingUpAddresses(false);
        }));
    }, [addressLookupPostcode]);
    var selectAddressFromLookup = useCallback(function (address) {
        dispatch(usersActions.lookupAddress(address.id, function (error, completeAddress) {
            var _a;
            onChange((_a = {},
                _a[addressFieldName + '.line1'] = [
                    completeAddress.flatNumber,
                    completeAddress.houseNumber,
                    completeAddress.houseName,
                ]
                    .filter(function (x) { return !!x; })
                    .join(', '),
                _a[addressFieldName + '.line2'] = [
                    completeAddress.street1,
                    completeAddress.street2,
                ]
                    .filter(function (x) { return !!x; })
                    .join(', '),
                _a[addressFieldName + '.city'] = completeAddress.postTown,
                _a[addressFieldName + '.postcode'] = completeAddress.postCode,
                _a));
            setAddressLookupResults(undefined);
            setIsEnteringAddress(false);
        }));
    }, [onChange, addressFieldName]);
    return (React.createElement(ResponsiveViewWrapper, { downBreakpointSm: 400, className: "customer-address-input-control" },
        React.createElement(React.Fragment, null,
            showDescriptionField && (React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[descriptionFieldName]) || false, validationResult: validationState && validationState[descriptionFieldName] },
                React.createElement(BasicField, { label: "Description", value: customerAddress === null || customerAddress === void 0 ? void 0 : customerAddress.description, name: descriptionFieldName, onChange: onChange, onTouch: onTouch }))),
            addressAsString &&
                !!addressAsString.length &&
                !isEnteringAddressManually && (React.createElement("div", { className: "existing-address-container" },
                React.createElement(LabelValuePair, { label: "Address", value: addressAsString }))),
            !isEnteringAddress && !isEnteringAddressManually && (React.createElement("div", { className: "address-controls-container" },
                React.createElement(Button, { iconAfter: faChevronRight, styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () { return setIsEnteringAddress(true); } }, addressAsString && !!addressAsString.length
                    ? 'Change address'
                    : 'Add address'),
                addressAsString && !!addressAsString.length && (React.createElement(FontAwesomeIcon, { icon: faTimes, onClick: function () {
                        var _a;
                        onChange((_a = {},
                            _a[addressFieldName + '.line1'] = undefined,
                            _a[addressFieldName + '.line2'] = undefined,
                            _a[addressFieldName + '.city'] = undefined,
                            _a[addressFieldName + '.postcode'] = undefined,
                            _a));
                    } })))),
            isEnteringAddress && !isEnteringAddressManually && (React.createElement("div", { className: "address-lookup-container" },
                React.createElement("div", { className: "address-lookup-control-container" },
                    React.createElement("label", null, "Postcode address lookup"),
                    React.createElement("div", { className: "flex-container flex-center" },
                        React.createElement("div", null,
                            React.createElement(BasicField, { value: addressLookupPostcode, styleVariant: inputStyleVariant, name: 'postcode', onChange: function (value) {
                                    setAddressLookupPostcode(value.postcode);
                                } })),
                        React.createElement(Button, { iconAfter: faSearch, onClick: lookupAddressesFromPostcode, isProcessing: isLookingUpAddresses, isDisabled: !addressLookupPostcode || addressLookupPostcode.length < 6 }, "Search"))),
                addressLookupResults && !addressLookupResults.length && (React.createElement("p", { className: "error-message" }, "We couldn't find any addresses matching this postcode.")),
                addressLookupResults && !!addressLookupResults.length && (React.createElement(React.Fragment, null,
                    React.createElement("ul", { className: "address-lookup-results-container" }, addressLookupResults.map(function (address, index) { return (React.createElement("li", { key: "address-lookup-result-" + index, className: "address-lookup-result", onClick: function () { return selectAddressFromLookup(address); } }, address.label)); })))),
                React.createElement("div", { className: "enter-address-manually-trigger-container" },
                    React.createElement(Button, { iconAfter: faChevronRight, styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () { return setIsEnteringAddressManually(true); } }, "Enter address manually")))),
            isEnteringAddressManually && (React.createElement(AddressInputControl, { styleVariant: inputStyleVariant, address: customerAddress === null || customerAddress === void 0 ? void 0 : customerAddress.address, fieldNamePrefix: addressFieldName, onChange: onChange, onTouch: onTouch, validationState: validationState, touchedState: touchedState })))));
};
export default CustomerAddressInputControl;
