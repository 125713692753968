import React, { useCallback, } from 'react';
import './NavigationSidebar.sass';
import CollapsibleSidebar from '../collapsibleSidebar/CollapsibleSidebar';
import NavigationMenuItem from '../navigationMenuItem/NavigationMenuItem';
import { User } from 'react-iconly';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export var CollapseDirection;
(function (CollapseDirection) {
    CollapseDirection["LEFT"] = "left";
    CollapseDirection["RIGHT"] = "right";
})(CollapseDirection || (CollapseDirection = {}));
export { NavigationMenuItem };
var NavigationSidebar = function (_a) {
    var currentAccount = _a.currentAccount, isCollapsed = _a.isCollapsed, isHidden = _a.isHidden, _b = _a.isSmallView, isSmallView = _b === void 0 ? false : _b, children = _a.children, onToggleIsCollapsed = _a.onToggleIsCollapsed, onToggleIsHidden = _a.onToggleIsHidden, onClickCurrentAccount = _a.onClickCurrentAccount;
    var onToggleCollapse = useCallback(function () {
        if (isSmallView) {
            onToggleIsCollapsed(false);
            onToggleIsHidden(!isHidden);
        }
        else {
            onToggleIsHidden(false);
            onToggleIsCollapsed(!isCollapsed);
        }
    }, [
        isCollapsed,
        isHidden,
        onToggleIsCollapsed,
        onToggleIsHidden,
        isSmallView,
    ]);
    return (React.createElement("div", { className: "navigation-sidebar" },
        React.createElement(CollapsibleSidebar, { isCollapsed: isCollapsed, isHidden: isHidden, onToggleCollapse: onToggleCollapse, config: {
                collapsedWidthPx: 88,
                expandedWidthPx: 255,
            } },
            React.createElement("nav", { "aria-label": "main-sidebar" },
                currentAccount && (React.createElement("div", { className: "current-account-container " + (onClickCurrentAccount ? 'clickable' : '') },
                    React.createElement(NavigationMenuItem, { onClick: onClickCurrentAccount, iconElement: currentAccount.logoUrl ? (React.createElement("img", { src: currentAccount.logoUrl, className: "account-logo" })) : (React.createElement(User, { size: "xlarge", set: "light" })), title: React.createElement("div", { className: "flex-container flex-center" },
                            React.createElement("span", { className: "flex-grow" }, currentAccount.companyName + " " + currentAccount.companyName),
                            onClickCurrentAccount && (React.createElement(FontAwesomeIcon, { icon: faChevronRight }))) }))),
                React.createElement("div", { className: "navigation-menu-items-container" }, children)))));
};
export default NavigationSidebar;
