var _a;
import { faFileInvoice, faUserFriends, faUserCircle, } from '@fortawesome/free-solid-svg-icons';
import ZapierLogo from '@/assets/images/oAuth/zapier.png';
var Scopes;
(function (Scopes) {
    Scopes["JOBS_READ"] = "jobs:read";
    Scopes["JOBS_WRITE"] = "jobs:write";
    Scopes["CUSTOMERS_READ"] = "customers:read";
    Scopes["CUSTOMERS_WRITE"] = "customers:write";
    Scopes["USERS_READ"] = "users:read";
})(Scopes || (Scopes = {}));
// Add scope details if oauth scopes expand
var ScopeInfo = (_a = {},
    _a[Scopes.JOBS_READ] = {
        description: 'View relevant job information on your account',
        icon: faFileInvoice,
    },
    _a[Scopes.JOBS_WRITE] = {
        description: 'Edit relevant job information on your account',
        icon: faFileInvoice,
    },
    _a[Scopes.CUSTOMERS_READ] = {
        description: 'View relevant customer information on your account',
        icon: faUserFriends,
    },
    _a[Scopes.CUSTOMERS_WRITE] = {
        description: 'Edit relevant customer information on your account',
        icon: faUserFriends,
    },
    _a[Scopes.USERS_READ] = {
        description: 'View basic profile information on your account',
        icon: faUserCircle,
    },
    _a);
var OAuthImage = {
    zapier: ZapierLogo,
    test: ZapierLogo,
};
var getScopeList = function (scopeString) {
    // split scopes from query
    var splitScopes = scopeString ? scopeString.split(' ') : [];
    // get description and icon for scope
    var scopes = splitScopes.reduce(function (acc, scope) {
        var scopeInfo = ScopeInfo[scope];
        if (scopeInfo && scopeInfo.description && scopeInfo.icon) {
            acc.push({
                scope: scope,
                description: scopeInfo.description,
                icon: scopeInfo.icon,
            });
        }
        return acc;
    }, []);
    return scopes;
};
var getAppLogo = function (appName) { return OAuthImage[appName.toLowerCase()]; };
export { getAppLogo, getScopeList };
