var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState, useCallback, } from 'react';
import { faPoundSign } from '@fortawesome/free-solid-svg-icons';
import BasicField from '../basicField/BasicField';
import './CurrencyField.sass';
var CurrencyField = function (_a) {
    var styleVariant = _a.styleVariant, name = _a.name, value = _a.value, label = _a.label, description = _a.description, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, _c = _a.isRequired, isRequired = _c === void 0 ? false : _c, _d = _a.iconBefore, iconBefore = _d === void 0 ? faPoundSign : _d, iconAfter = _a.iconAfter, _e = _a.allowPennies, allowPennies = _e === void 0 ? true : _e, _f = _a.additionalInputProps, additionalInputProps = _f === void 0 ? {} : _f, _g = _a.changeTimeoutMs, changeTimeoutMs = _g === void 0 ? 1000 : _g, onChange = _a.onChange, onTouch = _a.onTouch, onBlur = _a.onBlur, onChangeTimeout = _a.onChangeTimeout;
    var _h = useState(), formattedValue = _h[0], setFormattedValue = _h[1];
    var _j = useState(false), isFocused = _j[0], setIsFocused = _j[1];
    var setFixedFormattedValue = useCallback(function () {
        var _a;
        var fixedFormattedValue = value === null || value === undefined
            ? value
            : (value / 100).toFixed(allowPennies ? 2 : 0);
        var adjustedValue = fixedFormattedValue
            ? Math.trunc(Math.round(parseFloat(fixedFormattedValue.toString()) * 100))
            : null;
        setFormattedValue(fixedFormattedValue);
        onChange &&
            adjustedValue &&
            adjustedValue !== value &&
            onChange((_a = {},
                _a[name] = adjustedValue,
                _a));
    }, [value, name, onChange, allowPennies]);
    useEffect(function () {
        if (isFocused) {
            setFormattedValue(value === null || value === undefined ? value : value / 100);
        }
        else {
            setFixedFormattedValue();
        }
    }, [value, isFocused, setFixedFormattedValue]);
    return (React.createElement(BasicField, { className: "currency-field", styleVariant: styleVariant, name: name, value: formattedValue, label: label, description: description, isDisabled: isDisabled, isRequired: isRequired, iconBefore: iconBefore, iconAfter: iconAfter, type: "number", additionalInputProps: __assign({ pattern: '[0-9]*', min: 0, step: allowPennies ? 0.01 : 1, onFocus: function () {
                setIsFocused(true);
            } }, additionalInputProps), onChange: function (value) {
            var _a;
            onChange &&
                onChange((_a = {},
                    _a[name] = Math.round(value[name] * 100),
                    _a));
        }, onTouch: function (fieldName) {
            setIsFocused(false);
            onTouch && onTouch(fieldName);
        }, onBlur: onBlur, onChangeTimeout: onChangeTimeout, changeTimeoutMs: changeTimeoutMs }));
};
export default CurrencyField;
