var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { takeEvery, call, put, race, delay, takeLatest, } from 'redux-saga/effects';
import moment from 'moment';
import { ActionType, } from './jobsTypes';
import { editJobFailure, editJobSuccess, markJobAsSentSuccess, markJobAsSentFailure, resendJobFailure, resendJobSuccess, clearJobs, getJobFailure, getJobSuccess, updateJobFailure, updateJobSuccess, getJobAttachmentSuccess, clearJobAttachments, clearJobLineItemGroups, clearJobLineItems, getJobLineItemGroupSuccess, getJobLineItemSuccess, getJobValidationResultFailure, getJobValidationResultSuccess, createJobLineItemGroupFailure, createJobLineItemGroupSuccess, updateJobLineItemGroupFailure, updateJobLineItemGroupSuccess, deleteJobLineItemGroupFailure, deleteJobLineItemGroupSuccess, addLineItemGroupToJobFailure, addLineItemGroupToJobSuccess, createJobLineItemFailure, createJobLineItemSuccess, updateJobLineItemFailure, updateJobLineItemSuccess, deleteJobLineItemFailure, deleteJobLineItemSuccess, getJobLineItemAttachmentSuccess, clearJobLineItemAttachments, addAttachmentToJobLineItemFailure, addAttachmentToJobLineItemSuccess, removeAttachmentFromJobLineItemFailure, removeAttachmentFromJobLineItemSuccess, updateJobAttachmentFailure, updateJobAttachmentSuccess, } from './jobsActions';
import { Req } from '@payaca/helpers/storeHelper';
import { refreshAuthToken } from '../auth/authSaga';
var jobsSagaCreator = function (_a) {
    var apiBaseurl = _a.apiBaseurl, getAuthHeader = _a.getAuthHeader, isNativeApp = _a.isNativeApp;
    var req = Req(apiBaseurl, getAuthHeader, isNativeApp);
    function handleResendJob(action) {
        var timeout, errorMessage, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(resendJob, action.payload.jobId, action.payload.params),
                            timeout: delay(15000),
                        })];
                case 3:
                    timeout = (_a.sent()).timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Resend job timed out.';
                    return [4 /*yield*/, put(resendJobFailure(new Error(errorMessage), errorMessage))];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(resendJobSuccess())];
                case 6:
                    _a.sent();
                    _a.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_1 = _a.sent();
                    return [4 /*yield*/, put(resendJobFailure(error_1, error_1.message))];
                case 9:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleMarkJobAsSent(action) {
        var timeout, errorMessage, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(markJobAsSent, action.payload.jobId),
                            timeout: delay(15000),
                        })];
                case 3:
                    timeout = (_a.sent()).timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Mark job as sent timed out.';
                    return [4 /*yield*/, put(markJobAsSentFailure(new Error(errorMessage), errorMessage))];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(markJobAsSentSuccess())];
                case 6:
                    _a.sent();
                    _a.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_2 = _a.sent();
                    return [4 /*yield*/, put(markJobAsSentFailure(error_2, error_2.message))];
                case 9:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleEditJob(action) {
        var _a, response, timeout, errorMessage, error_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(editJob, action.payload.jobId),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Edit job timed out.';
                    return [4 /*yield*/, put(editJobFailure(new Error(errorMessage), errorMessage))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(editJobSuccess(response))];
                case 6:
                    _b.sent();
                    _b.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_3 = _b.sent();
                    return [4 /*yield*/, put(editJobFailure(error_3, error_3.message))];
                case 9:
                    _b.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleGetJob(action) {
        var _a, response, timeout, errorMessage, error_4;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(getJob, action.payload.jobId),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Get job timed out.';
                    return [4 /*yield*/, put(getJobFailure(action.payload.jobId, new Error(errorMessage)))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(getJobSuccess(action.payload.jobId, response))];
                case 6:
                    _b.sent();
                    _b.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_4 = _b.sent();
                    return [4 /*yield*/, put(getJobFailure(action.payload.jobId, error_4))];
                case 9:
                    _b.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleGetJobWithJobGroupsAndJobLineItems(action) {
        var _a, response, timeout, errorMessage, _i, _b, jobLineItemGroup, _c, _d, jobLineItem, error_5;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _e.sent();
                    _e.label = 2;
                case 2:
                    _e.trys.push([2, 15, , 17]);
                    return [4 /*yield*/, race({
                            response: call(getJobWithJobGroupsAndJobLineItems, action.payload.jobId),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _e.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Get job timed out.';
                    return [4 /*yield*/, put(getJobFailure(action.payload.jobId, new Error(errorMessage)))];
                case 4:
                    _e.sent();
                    return [3 /*break*/, 14];
                case 5: return [4 /*yield*/, put(getJobSuccess(action.payload.jobId, response.job))];
                case 6:
                    _e.sent();
                    _i = 0, _b = response.jobLineItemGroups;
                    _e.label = 7;
                case 7:
                    if (!(_i < _b.length)) return [3 /*break*/, 10];
                    jobLineItemGroup = _b[_i];
                    return [4 /*yield*/, put(getJobLineItemGroupSuccess(jobLineItemGroup.id, jobLineItemGroup))];
                case 8:
                    _e.sent();
                    _e.label = 9;
                case 9:
                    _i++;
                    return [3 /*break*/, 7];
                case 10:
                    _c = 0, _d = response.jobLineItems;
                    _e.label = 11;
                case 11:
                    if (!(_c < _d.length)) return [3 /*break*/, 14];
                    jobLineItem = _d[_c];
                    return [4 /*yield*/, put(getJobLineItemSuccess(jobLineItem.id, jobLineItem))];
                case 12:
                    _e.sent();
                    _e.label = 13;
                case 13:
                    _c++;
                    return [3 /*break*/, 11];
                case 14: return [3 /*break*/, 17];
                case 15:
                    error_5 = _e.sent();
                    return [4 /*yield*/, put(getJobFailure(action.payload.jobId, error_5))];
                case 16:
                    _e.sent();
                    return [3 /*break*/, 17];
                case 17: return [2 /*return*/];
            }
        });
    }
    function handleUpdateJob(action) {
        var validForDays, dueInDays, timeout, errorMessage, error_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _a.sent();
                    if (action.payload.updateJobRequestData.validUntil) {
                        validForDays = moment(action.payload.updateJobRequestData.validUntil).diff(moment(), 'days');
                        localStorage.setItem('payaca-quoteValidFor', validForDays.toString());
                    }
                    if (action.payload.updateJobRequestData.dueAt) {
                        dueInDays = moment(action.payload.updateJobRequestData.dueAt).diff(moment(), 'days');
                        localStorage.setItem('payaca-invoiceDueIn', dueInDays.toString());
                    }
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(updateJob, action.payload.updateJobRequestData),
                            timeout: delay(15000),
                        })];
                case 3:
                    timeout = (_a.sent()).timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Update job timed out.';
                    return [4 /*yield*/, put(updateJobFailure(action.payload.updateJobRequestData.jobId, new Error(errorMessage)))];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(updateJobSuccess(action.payload.updateJobRequestData.jobId))];
                case 6:
                    _a.sent();
                    action.payload.callback && action.payload.callback();
                    _a.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_6 = _a.sent();
                    return [4 /*yield*/, put(updateJobFailure(action.payload.updateJobRequestData.jobId, error_6))];
                case 9:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleGetJobsForDeal(action) {
        var _a, response, timeout, _i, response_1, job, error_7;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 9]);
                    return [4 /*yield*/, race({
                            response: call(getJobsForDeal, action.payload.dealId),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!!timeout) return [3 /*break*/, 7];
                    _i = 0, response_1 = response;
                    _b.label = 4;
                case 4:
                    if (!(_i < response_1.length)) return [3 /*break*/, 7];
                    job = response_1[_i];
                    return [4 /*yield*/, put(getJobSuccess(job.id, job))];
                case 5:
                    _b.sent();
                    _b.label = 6;
                case 6:
                    _i++;
                    return [3 /*break*/, 4];
                case 7: return [3 /*break*/, 9];
                case 8:
                    error_7 = _b.sent();
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }
    function handleGetJobAttachmentsForJob(action) {
        var _a, response, timeout, _i, response_2, jobAttachment, error_8;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 9]);
                    return [4 /*yield*/, race({
                            response: call(getJobAttachmentsForJob, action.payload.jobId),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!!timeout) return [3 /*break*/, 7];
                    _i = 0, response_2 = response;
                    _b.label = 4;
                case 4:
                    if (!(_i < response_2.length)) return [3 /*break*/, 7];
                    jobAttachment = response_2[_i];
                    return [4 /*yield*/, put(getJobAttachmentSuccess(jobAttachment.id, jobAttachment))];
                case 5:
                    _b.sent();
                    _b.label = 6;
                case 6:
                    _i++;
                    return [3 /*break*/, 4];
                case 7: return [3 /*break*/, 9];
                case 8:
                    error_8 = _b.sent();
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }
    function handleGetJobLineItemAttachmentsForJobLineItem(action) {
        var _a, response, timeout, _i, response_3, jobLineItemAttachment, error_9;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 9]);
                    return [4 /*yield*/, race({
                            response: call(getJobLineItemAttachmentsForJobLineItem, action.payload.jobLineItemId),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!!timeout) return [3 /*break*/, 7];
                    _i = 0, response_3 = response;
                    _b.label = 4;
                case 4:
                    if (!(_i < response_3.length)) return [3 /*break*/, 7];
                    jobLineItemAttachment = response_3[_i];
                    return [4 /*yield*/, put(getJobLineItemAttachmentSuccess(jobLineItemAttachment.id, jobLineItemAttachment))];
                case 5:
                    _b.sent();
                    _b.label = 6;
                case 6:
                    _i++;
                    return [3 /*break*/, 4];
                case 7: return [3 /*break*/, 9];
                case 8:
                    error_9 = _b.sent();
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }
    function handleGetJobLineItemGroupsForJob(action) {
        var _a, response, timeout, _i, response_4, jobLineItemGroup, error_10;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 9]);
                    return [4 /*yield*/, race({
                            response: call(getJobLineItemGroupsForJob, action.payload.jobId),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!!timeout) return [3 /*break*/, 7];
                    _i = 0, response_4 = response;
                    _b.label = 4;
                case 4:
                    if (!(_i < response_4.length)) return [3 /*break*/, 7];
                    jobLineItemGroup = response_4[_i];
                    return [4 /*yield*/, put(getJobLineItemGroupSuccess(jobLineItemGroup.id, jobLineItemGroup))];
                case 5:
                    _b.sent();
                    _b.label = 6;
                case 6:
                    _i++;
                    return [3 /*break*/, 4];
                case 7: return [3 /*break*/, 9];
                case 8:
                    error_10 = _b.sent();
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }
    function handleCreateJobLineItemGroup(action) {
        var timeout, errorMessage, error_11;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(createJobLineItemGroup, action.payload.createJobLineItemGroupRequestData),
                            timeout: delay(15000),
                        })];
                case 3:
                    timeout = (_a.sent()).timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Create job line item group timed out.';
                    return [4 /*yield*/, put(createJobLineItemGroupFailure(new Error(errorMessage)))];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(createJobLineItemGroupSuccess())];
                case 6:
                    _a.sent();
                    action.payload.callback && action.payload.callback();
                    _a.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_11 = _a.sent();
                    return [4 /*yield*/, put(createJobLineItemGroupFailure(error_11))];
                case 9:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleUpdateJobLineItemGroup(action) {
        var timeout, errorMessage, error_12;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(updateJobLineItemGroup, action.payload.updateJobLineItemGroupRequestData),
                            timeout: delay(15000),
                        })];
                case 3:
                    timeout = (_a.sent()).timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Update job line item group timed out.';
                    return [4 /*yield*/, put(updateJobLineItemGroupFailure(action.payload.updateJobLineItemGroupRequestData.jobLineItemGroupId, new Error(errorMessage)))];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(updateJobLineItemGroupSuccess(action.payload.updateJobLineItemGroupRequestData.jobLineItemGroupId))];
                case 6:
                    _a.sent();
                    action.payload.callback && action.payload.callback();
                    _a.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_12 = _a.sent();
                    return [4 /*yield*/, put(updateJobLineItemGroupFailure(action.payload.updateJobLineItemGroupRequestData.jobLineItemGroupId, error_12))];
                case 9:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleUpdateJobAttachment(action) {
        var timeout, errorMessage, error_13;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(updateJobAttachment, action.payload.updateJobAttachmentRequestData),
                            timeout: delay(15000),
                        })];
                case 3:
                    timeout = (_a.sent()).timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Update job attachment timed out.';
                    return [4 /*yield*/, put(updateJobAttachmentFailure(action.payload.updateJobAttachmentRequestData.jobAttachmentId, new Error(errorMessage)))];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(updateJobAttachmentSuccess(action.payload.updateJobAttachmentRequestData.jobAttachmentId))];
                case 6:
                    _a.sent();
                    action.payload.callback && action.payload.callback();
                    _a.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_13 = _a.sent();
                    return [4 /*yield*/, put(updateJobAttachmentFailure(action.payload.updateJobAttachmentRequestData.jobAttachmentId, error_13))];
                case 9:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleDeleteJobLineItemGroup(action) {
        var timeout, errorMessage, error_14;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(deleteJobLineItemGroup, action.payload.jobLineItemGroupId),
                            timeout: delay(15000),
                        })];
                case 3:
                    timeout = (_a.sent()).timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Delete job line item group timed out.';
                    return [4 /*yield*/, put(deleteJobLineItemGroupFailure(new Error(errorMessage)))];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(deleteJobLineItemGroupSuccess())];
                case 6:
                    _a.sent();
                    action.payload.callback && action.payload.callback();
                    _a.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_14 = _a.sent();
                    return [4 /*yield*/, put(deleteJobLineItemGroupFailure(error_14))];
                case 9:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleAddLineItemGroupToJob(action) {
        var timeout, errorMessage, error_15;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(addLineItemGroupToJob, action.payload.jobId, action.payload.lineItemGroupId),
                            timeout: delay(15000),
                        })];
                case 3:
                    timeout = (_a.sent()).timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Add line item group to job timed out.';
                    return [4 /*yield*/, put(addLineItemGroupToJobFailure(new Error(errorMessage)))];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(addLineItemGroupToJobSuccess())];
                case 6:
                    _a.sent();
                    action.payload.callback && action.payload.callback();
                    _a.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_15 = _a.sent();
                    return [4 /*yield*/, put(addLineItemGroupToJobFailure(error_15))];
                case 9:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleGetJobLineItemsForJob(action) {
        var _a, response, timeout, _i, response_5, jobLineItem, error_16;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 9]);
                    return [4 /*yield*/, race({
                            response: call(getJobLineItemsForJob, action.payload.jobId),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!!timeout) return [3 /*break*/, 7];
                    _i = 0, response_5 = response;
                    _b.label = 4;
                case 4:
                    if (!(_i < response_5.length)) return [3 /*break*/, 7];
                    jobLineItem = response_5[_i];
                    return [4 /*yield*/, put(getJobLineItemSuccess(jobLineItem.id, jobLineItem))];
                case 5:
                    _b.sent();
                    _b.label = 6;
                case 6:
                    _i++;
                    return [3 /*break*/, 4];
                case 7: return [3 /*break*/, 9];
                case 8:
                    error_16 = _b.sent();
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }
    function handleGetJobValidationResult(action) {
        var _a, response, timeout, errorMessage, error_17;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(getJobValidationResult, action.payload.jobId),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Get job validation result timed out.';
                    return [4 /*yield*/, put(getJobValidationResultFailure(new Error(errorMessage)))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(getJobValidationResultSuccess(response))];
                case 6:
                    _b.sent();
                    action.payload.callback && action.payload.callback(response);
                    _b.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_17 = _b.sent();
                    return [4 /*yield*/, put(getJobValidationResultFailure(error_17))];
                case 9:
                    _b.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleCreateJobLineItem(action) {
        var timeout, errorMessage, error_18;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(createJobLineItem, action.payload.createJobLineItemRequestData),
                            timeout: delay(15000),
                        })];
                case 3:
                    timeout = (_a.sent()).timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Create job line item timed out.';
                    return [4 /*yield*/, put(createJobLineItemFailure(new Error(errorMessage)))];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(createJobLineItemSuccess())];
                case 6:
                    _a.sent();
                    action.payload.callback && action.payload.callback();
                    _a.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_18 = _a.sent();
                    return [4 /*yield*/, put(createJobLineItemFailure(error_18))];
                case 9:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleUpdateJobLineItem(action) {
        var timeout, errorMessage, error_19;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _a.sent();
                    localStorage.setItem('payaca-vatAmount', (action.payload.updateJobLineItemRequestData.vatAmount || 0).toString());
                    localStorage.setItem('payaca-vatIncluded', action.payload.updateJobLineItemRequestData.vatIncluded.toString());
                    localStorage.setItem('payaca-isReverseChargeVat', action.payload.updateJobLineItemRequestData.isReverseChargeVat.toString());
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(updateJobLineItem, action.payload.updateJobLineItemRequestData),
                            timeout: delay(15000),
                        })];
                case 3:
                    timeout = (_a.sent()).timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Update job line item  timed out.';
                    return [4 /*yield*/, put(updateJobLineItemFailure(action.payload.updateJobLineItemRequestData.jobLineItemId, new Error(errorMessage)))];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(updateJobLineItemSuccess(action.payload.updateJobLineItemRequestData.jobLineItemId))];
                case 6:
                    _a.sent();
                    action.payload.callback && action.payload.callback();
                    _a.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_19 = _a.sent();
                    return [4 /*yield*/, put(updateJobLineItemFailure(action.payload.updateJobLineItemRequestData.jobLineItemId, error_19))];
                case 9:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleUpdateJobLineItemFromLineItem(action) {
        var timeout, errorMessage, error_20;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(updateJobLineItemFromLineItem, action.payload.jobLineItemId, action.payload.lineItemId),
                            timeout: delay(15000),
                        })];
                case 3:
                    timeout = (_a.sent()).timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Update job line item from line item timed out.';
                    return [4 /*yield*/, put(updateJobLineItemFailure(action.payload.jobLineItemId, new Error(errorMessage)))];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(updateJobLineItemSuccess(action.payload.jobLineItemId))];
                case 6:
                    _a.sent();
                    action.payload.callback && action.payload.callback();
                    _a.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_20 = _a.sent();
                    return [4 /*yield*/, put(updateJobLineItemFailure(action.payload.jobLineItemId, error_20))];
                case 9:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleDeleteJobLineItem(action) {
        var timeout, errorMessage, error_21;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(deleteJobLineItem, action.payload.jobLineItemId),
                            timeout: delay(15000),
                        })];
                case 3:
                    timeout = (_a.sent()).timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Delete job line item timed out.';
                    return [4 /*yield*/, put(deleteJobLineItemFailure(new Error(errorMessage)))];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(deleteJobLineItemSuccess())];
                case 6:
                    _a.sent();
                    action.payload.callback && action.payload.callback();
                    _a.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_21 = _a.sent();
                    return [4 /*yield*/, put(deleteJobLineItemFailure(error_21))];
                case 9:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleAddAttachmentToJobLineItem(action) {
        var timeout, errorMessage, error_22;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(addAttachmentToJobLineItem, action.payload.jobLineItemId, action.payload.attachment, action.payload.updateLineItemAttachment),
                            timeout: delay(15000),
                        })];
                case 3:
                    timeout = (_a.sent()).timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Add attachment to job line item.';
                    return [4 /*yield*/, put(addAttachmentToJobLineItemFailure(new Error(errorMessage)))];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(addAttachmentToJobLineItemSuccess())];
                case 6:
                    _a.sent();
                    action.payload.callback && action.payload.callback();
                    _a.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_22 = _a.sent();
                    return [4 /*yield*/, put(addAttachmentToJobLineItemFailure(error_22))];
                case 9:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    function handleRemoveAttachmentFromJobLineItem(action) {
        var timeout, errorMessage, error_23;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(removeAttachmentFromJobLineItem, action.payload.jobLineItemId, action.payload.attachmentId, action.payload.updateLineItemAttachment),
                            timeout: delay(15000),
                        })];
                case 3:
                    timeout = (_a.sent()).timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Add attachment to job line item.';
                    return [4 /*yield*/, put(removeAttachmentFromJobLineItemFailure(new Error(errorMessage)))];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(removeAttachmentFromJobLineItemSuccess())];
                case 6:
                    _a.sent();
                    action.payload.callback && action.payload.callback();
                    _a.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_23 = _a.sent();
                    return [4 /*yield*/, put(removeAttachmentFromJobLineItemFailure(error_23))];
                case 9:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    var addAttachmentToJobLineItem = function (jobLineItemId, attachment, updateLineItemAttachment) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, req.postFile("/job_line_items/" + jobLineItemId + "/job_line_item_attachment?updateLineItemAttachment=" + updateLineItemAttachment, attachment)];
                case 1:
                    response = _a.sent();
                    if (response.ok) {
                        return [2 /*return*/];
                    }
                    else {
                        throw new Error("addAttachmentToJobLineItem failed: " + response.status + " " + response.statusText);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var removeAttachmentFromJobLineItem = function (jobLineItemId, attachmentId, updateLineItemAttachment) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/job_line_items/" + jobLineItemId + "/job_line_item_attachment/" + attachmentId + "?updateLineItemAttachment=" + updateLineItemAttachment, {
                            method: 'DELETE',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/];
                                }
                                else {
                                    throw new Error("removeAttachmentFromJobLineItem failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var createJobLineItem = function (createJobLineItemRequestData) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/job_line_items/create/" + createJobLineItemRequestData.jobId + "/" + createJobLineItemRequestData.jobLineItemGroupId, {
                            method: 'POST',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                            body: JSON.stringify(__assign({ vatAmount: localStorage.getItem('payaca-vatAmount') &&
                                    Number(localStorage.getItem('payaca-vatAmount')), vatIncluded: localStorage.getItem('payaca-vatIncluded') === 'true', isReverseChargeVat: localStorage.getItem('payaca-isReverseChargeVat') === 'true' }, createJobLineItemRequestData)),
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/];
                                }
                                else {
                                    throw new Error("createJobLineItem failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var updateJobAttachment = function (updateJobAttachmentRequestData) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/jobs/" + updateJobAttachmentRequestData.jobId + "/job_attachments/" + updateJobAttachmentRequestData.jobAttachmentId, {
                            method: 'PUT',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                            body: JSON.stringify(updateJobAttachmentRequestData),
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/];
                                }
                                else {
                                    throw new Error("updateJobAttachment failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var updateJobLineItem = function (updateJobLineItemRequestData) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/job_line_items/" + updateJobLineItemRequestData.jobLineItemId, {
                            method: 'PUT',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                            body: JSON.stringify(updateJobLineItemRequestData),
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/];
                                }
                                else {
                                    throw new Error("updateJobLineItem failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var updateJobLineItemFromLineItem = function (jobLineItemId, lineItemId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/job_line_items/" + jobLineItemId + "/line_item/" + lineItemId, {
                            method: 'PUT',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/];
                                }
                                else {
                                    throw new Error("updateJobLineItemFromLineItem failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var deleteJobLineItem = function (jobLineItemId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/job_line_items/" + jobLineItemId, {
                            method: 'DELETE',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/];
                                }
                                else {
                                    throw new Error("deleteJobLineItem failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var createJobLineItemGroup = function (createJobLineItemGroupRequestData) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/job_line_item_groups/create/" + createJobLineItemGroupRequestData.jobId, {
                            method: 'POST',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/];
                                }
                                else {
                                    throw new Error("createJobLineItemGroup failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var updateJobLineItemGroup = function (updateJobLineItemGroupRequestData) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/job_line_item_groups/" + updateJobLineItemGroupRequestData.jobLineItemGroupId, {
                            method: 'PUT',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                            body: JSON.stringify(updateJobLineItemGroupRequestData),
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/];
                                }
                                else {
                                    throw new Error("updateJobLineItemGroup failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var deleteJobLineItemGroup = function (jobLineItemGroupId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/job_line_item_groups/" + jobLineItemGroupId, {
                            method: 'DELETE',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/];
                                }
                                else {
                                    throw new Error("deleteJobLineItemGroup failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var addLineItemGroupToJob = function (jobId, lineItemGroupId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/job_line_item_groups/create/" + jobId + "/" + lineItemGroupId, {
                            method: 'POST',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/];
                                }
                                else {
                                    throw new Error("addLineItemGroupToJob failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var getJobValidationResult = function (jobId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/jobs/" + jobId + "/validation_result", {
                            method: 'GET',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/, response.json()];
                                }
                                else {
                                    throw new Error("getJobValidationResult failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var getJobLineItemsForJob = function (jobId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/jobs/" + jobId + "/job_line_items", {
                            method: 'GET',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/, response.json()];
                                }
                                else {
                                    throw new Error("getJobLineItemsForJob failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var getJobLineItemGroupsForJob = function (jobId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/jobs/" + jobId + "/job_line_item_groups", {
                            method: 'GET',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/, response.json()];
                                }
                                else {
                                    throw new Error("getJobLineItemGroupsForJob failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var getJobAttachmentsForJob = function (jobId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/jobs/" + jobId + "/job_attachments", {
                            method: 'GET',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/, response.json()];
                                }
                                else {
                                    throw new Error("getJobAttachmentsForJob failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var getJobLineItemAttachmentsForJobLineItem = function (jobLineItemId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/job_line_items/" + jobLineItemId + "/job_line_item_attachments", {
                            method: 'GET',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/, response.json()];
                                }
                                else {
                                    throw new Error("getJobLineItemAttachmentsForJob failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var getJobsForDeal = function (dealId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/jobs/deal/" + dealId, {
                            method: 'GET',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Deal': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/, response.json()];
                                }
                                else {
                                    throw new Error("getJobsForDeal failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var resendJob = function (jobId, params) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, req.post("/jobs/" + jobId + "/resend", params)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response];
                case 2: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    var markJobAsSent = function (jobId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/jobs/" + jobId + "/mark_as_sent", {
                            method: 'POST',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Job': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            var payload;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!response.ok) return [3 /*break*/, 1];
                                        return [2 /*return*/];
                                    case 1: return [4 /*yield*/, response.json()];
                                    case 2:
                                        payload = _a.sent();
                                        throw new Error(payload.errors);
                                }
                            });
                        }); })];
            }
        });
    }); };
    var updateJob = function (updateJobRequestData) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/jobs/" + updateJobRequestData.jobId + "/v2", {
                            method: 'POST',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Job': 'true',
                            },
                            body: JSON.stringify(updateJobRequestData),
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/];
                                }
                                else {
                                    throw new Error("updateJob failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var getJob = function (jobId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/jobs/" + jobId + "/v2", {
                            method: 'GET',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Job': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/, response.json()];
                                }
                                else {
                                    throw new Error("getJob failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var getJobWithJobGroupsAndJobLineItems = function (jobId) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [2 /*return*/, fetch(apiBaseurl + "/jobs/" + jobId + "/with_related_entities", {
                            method: 'GET',
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Job': 'true',
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.ok) {
                                    return [2 /*return*/, response.json()];
                                }
                                else {
                                    throw new Error("getJob failed: " + response.status + " " + response.statusText);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    }); };
    var editJob = function (jobId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, req.put("/jobs/" + jobId + "/edit")];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    function handleAppLogout() {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, put(clearJobs())];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, put(clearJobAttachments())];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, put(clearJobLineItemAttachments())];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, put(clearJobLineItemGroups())];
                case 4:
                    _a.sent();
                    return [4 /*yield*/, put(clearJobLineItems())];
                case 5:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    return function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, takeEvery(ActionType.REQUEST_MARK_JOB_AS_SENT, handleMarkJobAsSent)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(ActionType.REQUEST_RESEND_JOB, handleResendJob)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(ActionType.REQUEST_EDIT_JOB, handleEditJob)];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(ActionType.REQUEST_GET_JOB, handleGetJob)];
                case 4:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.REQUEST_GET_JOB_WITH_JOB_GROUPS_AND_JOB_LINE_ITEMS, handleGetJobWithJobGroupsAndJobLineItems)];
                case 5:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.REQUEST_UPDATE_JOB, handleUpdateJob)];
                case 6:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.REQUEST_GET_JOBS_FOR_DEAL, handleGetJobsForDeal)];
                case 7:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.REQUEST_GET_JOB_ATTACHMENTS_FOR_JOB, handleGetJobAttachmentsForJob)];
                case 8:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(ActionType.REQUEST_GET_JOB_LINE_ITEM_ATTACHMENTS_FOR_JOB_LINE_ITEM, handleGetJobLineItemAttachmentsForJobLineItem)];
                case 9:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.REQUEST_GET_JOB_LINE_ITEM_GROUPS_FOR_JOB, handleGetJobLineItemGroupsForJob)];
                case 10:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.REQUEST_GET_JOB_LINE_ITEMS_FOR_JOB, handleGetJobLineItemsForJob)];
                case 11:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(ActionType.REQUEST_GET_JOB_VALIDATION_RESULT, handleGetJobValidationResult)];
                case 12:
                    _a.sent();
                    return [4 /*yield*/, takeEvery('auth.logout', handleAppLogout)];
                case 13:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(ActionType.REQUEST_CREATE_JOB_LINE_ITEM_GROUP, handleCreateJobLineItemGroup)];
                case 14:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(ActionType.REQUEST_DELETE_JOB_LINE_ITEM_GROUP, handleDeleteJobLineItemGroup)];
                case 15:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.REQUEST_UPDATE_JOB_LINE_ITEM_GROUP, handleUpdateJobLineItemGroup)];
                case 16:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.REQUEST_ADD_LINE_ITEM_GROUP_TO_JOB, handleAddLineItemGroupToJob)];
                case 17:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(ActionType.REQUEST_CREATE_JOB_LINE_ITEM, handleCreateJobLineItem)];
                case 18:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(ActionType.REQUEST_DELETE_JOB_LINE_ITEM, handleDeleteJobLineItem)];
                case 19:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.REQUEST_UPDATE_JOB_LINE_ITEM, handleUpdateJobLineItem)];
                case 20:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(ActionType.REQUEST_UPDATE_JOB_ATTACHMENT, handleUpdateJobAttachment)];
                case 21:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(ActionType.REQUEST_UPDATE_JOB_LINE_ITEM_FROM_LINE_ITEM, handleUpdateJobLineItemFromLineItem)];
                case 22:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(ActionType.REQUEST_REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM, handleRemoveAttachmentFromJobLineItem)];
                case 23:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(ActionType.REQUEST_ADD_ATTACHMENT_TO_JOB_LINE_ITEM, handleAddAttachmentToJobLineItem)];
                case 24:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    };
};
export default jobsSagaCreator;
