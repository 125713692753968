import React, { useEffect, useState, useCallback, useMemo, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { actions as subsidisedFinanceInformationActions } from '@payaca/store/subsidisedFinanceInformation';
import { getFieldValidatorsForAuthorityToSearch } from '@payaca/helpers/subsidisedFinanceInformationValidationHelper';
import { Checkbox } from '@payaca/components/checkbox';
import { MiniLoader, ResponsiveViewWrapper, SubsidisedFinanceDataProtectionDeclaration, ValidatedForm, } from '@payaca/components';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { BasicField } from '@payaca/components/basicField';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import './AuthorityToSearchFormSection.css';
var AuthorityToSearchFormSection = function () {
    var dispatch = useDispatch();
    var authorityToSearch = useSelector(function (state) {
        var _a;
        return (_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.authorityToSearch;
    });
    var isGettingOrPersisting = useSelector(function (state) {
        var _a, _b;
        return (((_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.isGettingAuthorityToSearch) || ((_b = state.subsidisedFinanceInformation) === null || _b === void 0 ? void 0 : _b.isPersistingAuthorityToSearch));
    });
    var _a = useState(), triggerValidationAt = _a[0], setTriggerValidationAt = _a[1];
    var fieldValidators = useMemo(function () {
        return getFieldValidatorsForAuthorityToSearch();
    }, []);
    useEffect(function () {
        dispatch(subsidisedFinanceInformationActions.requestGetAuthorityToSearch());
    }, []);
    var onSaveProgress = useCallback(function (modifiedAuthorityToSearch) {
        dispatch(subsidisedFinanceInformationActions.requestPersistAuthorityToSearch(modifiedAuthorityToSearch));
    }, [dispatch]);
    var onSaveAndContinue = useCallback(function (modifiedAuthorityToSearch) {
        dispatch(subsidisedFinanceInformationActions.requestPersistCompleteAuthorityToSearch(modifiedAuthorityToSearch));
    }, [dispatch]);
    var renderActionButtons = useCallback(function (isValid, formState) {
        var modifiedAuthorityToSearch = formState;
        return (React.createElement("div", { className: "action-buttons-container" }, isGettingOrPersisting ? (React.createElement("div", { className: "loader-container flex-grow" },
            React.createElement(MiniLoader, null))) : (React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: function () { return onSaveProgress(modifiedAuthorityToSearch); }, styleVariant: ButtonStyleVariant.OUTSIZE, isOutlined: true }, 'Save progress'),
            React.createElement("div", { onClick: function () { return !isValid && setTriggerValidationAt(new Date()); } },
                React.createElement(Button, { isDisabled: !isValid, onClick: function () {
                        return isValid && onSaveAndContinue(modifiedAuthorityToSearch);
                    }, styleVariant: ButtonStyleVariant.OUTSIZE, iconAfter: faChevronRight }, 'Save and continue'))))));
    }, [onSaveProgress, onSaveAndContinue, isGettingOrPersisting]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        var _a, _b, _c;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "field-group" },
                React.createElement("p", null, "Please enter details for the business bank account you would like any paid out funds to go to. Payaca Ltd will require proof of bank details should your application be successful."),
                React.createElement("div", { className: "flex-container" },
                    React.createElement(ValidatedFieldWrapper, { validationResult: validationState.bankName, isTouched: touchedState.bankName || false },
                        React.createElement(BasicField, { name: "bankName", value: formState.bankName || '', onChange: onFieldChange, onTouch: onFieldTouch, isRequired: true, label: "Bank name", styleVariant: InputStyleVariant.OUTSIZE })),
                    React.createElement(ValidatedFieldWrapper, { validationResult: validationState.sortCode, isTouched: touchedState.sortCode || false },
                        React.createElement(BasicField, { name: "sortCode", value: formState.sortCode || '', onChange: onFieldChange, onTouch: onFieldTouch, isRequired: true, label: "Sort code", styleVariant: InputStyleVariant.OUTSIZE })),
                    React.createElement(ValidatedFieldWrapper, { validationResult: validationState.accountNumber, isTouched: touchedState.accountNumber || false },
                        React.createElement(BasicField, { name: "accountNumber", value: formState.accountNumber || '', onChange: onFieldChange, onTouch: onFieldTouch, isRequired: true, label: "Account number", styleVariant: InputStyleVariant.OUTSIZE })),
                    React.createElement(ValidatedFieldWrapper, { validationResult: validationState.accountName, isTouched: touchedState.accountName || false },
                        React.createElement(BasicField, { name: "accountName", value: formState.accountName || '', onChange: onFieldChange, onTouch: onFieldTouch, isRequired: true, label: "Name on account", styleVariant: InputStyleVariant.OUTSIZE })))),
            React.createElement("div", { className: "field-group" },
                React.createElement(SubsidisedFinanceDataProtectionDeclaration, null),
                React.createElement("div", { className: "checkbox-container flex-container flex-center" },
                    React.createElement("div", null,
                        React.createElement(Checkbox, { isChecked: formState.hasReadAndAcceptedAuthorityToSearch || false, onChange: function () {
                                onFieldChange({
                                    hasReadAndAcceptedAuthorityToSearch: !formState.hasReadAndAcceptedAuthorityToSearch,
                                });
                                onFieldTouch('hasReadAndAcceptedAuthorityToSearch');
                            } })),
                    React.createElement("span", null, "I have read and agreed to the above")),
                (touchedState === null || touchedState === void 0 ? void 0 : touchedState.hasReadAndAcceptedAuthorityToSearch) &&
                    !((_a = validationState === null || validationState === void 0 ? void 0 : validationState.hasReadAndAcceptedAuthorityToSearch) === null || _a === void 0 ? void 0 : _a.isValid) && ((_c = (_b = validationState === null || validationState === void 0 ? void 0 : validationState.hasReadAndAcceptedAuthorityToSearch) === null || _b === void 0 ? void 0 : _b.errors) === null || _c === void 0 ? void 0 : _c.map(function (error, index) {
                    return (React.createElement("p", { className: "validation-error", key: index }, error));
                }))),
            renderActionButtons(isValid, formState)));
    }, [renderActionButtons]);
    return (React.createElement(ResponsiveViewWrapper, { className: "authority-to-search-form-section", downBreakpointSm: 600 },
        React.createElement("div", { style: { position: 'relative' } },
            React.createElement(ValidatedForm, { triggerValidationAt: triggerValidationAt, initialFormState: authorityToSearch, fieldValidators: fieldValidators, renderFormContents: renderFormContents }))));
};
export default AuthorityToSearchFormSection;
