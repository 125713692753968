import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { BasicField } from '@payaca/components/basicField';
import { TextareaField } from '@payaca/components/textareaField';
import { TitledContentPanel } from '@payaca/components/titledContentPanel';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import './AssociatedBusinessFormSubsection.css';
var AssociatedBusinessFormSubsection = function (_a) {
    var title = _a.title, associatedBusiness = _a.associatedBusiness, fieldNamePrefix = _a.fieldNamePrefix, touchedState = _a.touchedState, validationState = _a.validationState, onChange = _a.onChange, onTouch = _a.onTouch, onRemove = _a.onRemove;
    var companyNameFieldName = useMemo(function () {
        return fieldNamePrefix ? fieldNamePrefix + ".companyName" : 'companyName';
    }, [fieldNamePrefix]);
    var registrationDetailsFieldName = useMemo(function () {
        return fieldNamePrefix
            ? fieldNamePrefix + ".registrationDetails"
            : 'registrationDetails';
    }, [fieldNamePrefix]);
    var natureOfRelationshipFieldName = useMemo(function () {
        return fieldNamePrefix
            ? fieldNamePrefix + ".natureOfRelationship"
            : 'natureOfRelationship';
    }, [fieldNamePrefix]);
    var titleElement = useMemo(function () {
        return (React.createElement("div", { className: "flex-container flex-center" },
            React.createElement("span", { className: "flex-grow" },
                title,
                associatedBusiness.companyName &&
                    " - " + associatedBusiness.companyName),
            React.createElement(FontAwesomeIcon, { icon: faTimes, onClick: onRemove })));
    }, [title, associatedBusiness, onRemove]);
    return (React.createElement(TitledContentPanel, { className: "associated-business-form-subsection", title: titleElement, hasBoxShadow: true },
        React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[companyNameFieldName]) || false, validationResult: validationState && validationState[companyNameFieldName] },
            React.createElement(BasicField, { label: "Full company name", value: associatedBusiness.companyName || '', name: companyNameFieldName, onChange: onChange, onTouch: onTouch, styleVariant: InputStyleVariant.OUTSIZE })),
        React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[registrationDetailsFieldName]) || false, validationResult: validationState && validationState[registrationDetailsFieldName] },
            React.createElement(TextareaField, { label: "Registration details", description: "(Including registration number)", value: associatedBusiness.registrationDetails || '', name: registrationDetailsFieldName, onChange: onChange, onTouch: onTouch, styleVariant: InputStyleVariant.OUTSIZE })),
        React.createElement(ValidatedFieldWrapper, { isTouched: (touchedState && touchedState[natureOfRelationshipFieldName]) || false, validationResult: validationState && validationState[natureOfRelationshipFieldName] },
            React.createElement(TextareaField, { label: "What is the relationship to this business?", value: associatedBusiness.natureOfRelationship || '', name: natureOfRelationshipFieldName, onChange: onChange, onTouch: onTouch, styleVariant: InputStyleVariant.OUTSIZE }))));
};
export default AssociatedBusinessFormSubsection;
