var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileUploadPersistRemoveControl, TextareaField, } from '@payaca/components';
import { actions as accountSettingsActions } from '@/api/accountSettings';
var CompanySettingsTerms = function (_a) {
    var readOnly = _a.readOnly;
    var dispatch = useDispatch();
    var pendingAccount = useSelector(function (state) { return state.accountSettings.pendingAccount; });
    var pendingTerms = useSelector(function (state) { return state.accountSettings.pendingTerms; });
    var persistedFiles = useMemo(function () {
        return (pendingTerms === null || pendingTerms === void 0 ? void 0 : pendingTerms.length) ? pendingTerms.map(function (pendingTerm) { return ({
            fileName: pendingTerm.fileName,
            identifier: pendingTerm.id,
        }); })
            : [];
    }, [pendingTerms]);
    var onPersistTerm = useCallback(function (file) {
        return new Promise(function (resolve, reject) {
            dispatch(accountSettingsActions.storeAccountPendingTerms(__spreadArrays(pendingTerms, [
                __assign(__assign({}, file), { fileName: file.name, identifier: file.lastModified }),
            ])));
            resolve();
        });
    }, [dispatch, pendingTerms]);
    var onRemoveTerm = useCallback(function (fileIdentifier) {
        var updatedTerms = __spreadArrays(pendingTerms);
        // find file to remove in persisted files
        var fileToRemoveIndex = pendingTerms.findIndex(function (f) { return f.identifier === fileIdentifier || f.id === fileIdentifier; });
        updatedTerms.splice(fileToRemoveIndex, 1);
        return new Promise(function (resolve, reject) {
            dispatch(accountSettingsActions.storeAccountPendingTerms(updatedTerms));
            resolve();
        });
    }, [dispatch, pendingTerms]);
    var onChange = useCallback(function (change) {
        dispatch(accountSettingsActions.storeAccountPendingChanges(change));
    }, [dispatch]);
    return (React.createElement("div", { className: "company-settings-terms" },
        React.createElement("div", { className: "settings-sub-section" },
            React.createElement(TextareaField, { autoSize: true, label: "Terms of business", value: pendingAccount.businessNotes, name: "businessNotes", onChange: onChange, rows: 5, isDisabled: readOnly, placeholder: "Text here will be added to the bottom of every quote & invoice" }),
            React.createElement("span", null, "Attach a file"),
            React.createElement(FileUploadPersistRemoveControl, { acceptFileTypes: ['.png, .jpeg'], allowMultipleUploads: true, showAsDragAndDrop: true, persistFile: onPersistTerm, removePersistedFile: onRemoveTerm, persistedFiles: persistedFiles }))));
};
export default CompanySettingsTerms;
