/**
 *  Finance helper for zero percent specific
 */
/**
 * getMinimumDepositPercentage - min deposit percentage value possible
 * @param jobTotal - total job cost
 * @param maxFinanceAmount - maximum finance allowed to take out for job
 * @param minFinancePercentageValue - minimum deposit percentage allowed
 */
export var getMinimumDepositPercentage = function (jobTotal, maxFinanceAmount, minDepositPercentageValue) {
    var minDepositPercentageAllowedDefinedByMaxFinance = Math.round(((jobTotal - maxFinanceAmount) / jobTotal) * 10000) / 100;
    var minDepositPercentageAllowedDefinedByMinDepositPercentage = minDepositPercentageValue;
    // return larger deposit percenatage value
    return minDepositPercentageAllowedDefinedByMaxFinance >
        minDepositPercentageAllowedDefinedByMinDepositPercentage
        ? minDepositPercentageAllowedDefinedByMaxFinance
        : minDepositPercentageAllowedDefinedByMinDepositPercentage;
};
/**
 * getMaximumDepositPercentage - max deposit percentage value possible
 * @param jobTotal - total job cost
 * @param minFinanceAmount - minimum finance allowed to take out for job
 * @param maxDepositPercentageValue - maximum deposit percentage allowed
 */
export var getMaximumDepositPercentage = function (jobTotal, minFinanceAmount, maxDepositPercentageValue) {
    var maxDepositPercentageAllowedDefinedByMinFinance = Math.floor(((jobTotal - minFinanceAmount) / jobTotal) * 10000) / 100;
    var maxDepositPercentageAllowedDefinedByMaxDepositPercentage = maxDepositPercentageValue;
    // return smaller deposit percentage value
    return maxDepositPercentageAllowedDefinedByMinFinance <
        maxDepositPercentageAllowedDefinedByMaxDepositPercentage
        ? maxDepositPercentageAllowedDefinedByMinFinance
        : maxDepositPercentageAllowedDefinedByMaxDepositPercentage;
};
export var getMaximumDepositAmountFromMaxDepositPercentage = function (jobTotal, maxDepositPercentage) { return Math.round((maxDepositPercentage / 100) * jobTotal); };
export var getMinimumDepositAmountFromMinDepositPercentage = function (jobTotal, minDepositPercentage) { return Math.round((minDepositPercentage / 100) * jobTotal); };
/**
 * getMaxJobTotalAllowedByMaxFinanceAndMaxDeposit
 * @param maxFinanceAmount - Maximum finance allowed to apply for (defined by finance plan)
 *   - Decimal free format i.e. 500 for 5.00
 * @param maxDepositPercentage - Maximum deposit allowed to apply for (defined by finance plan)
 *   - i.e. 75 for 75%
 */
export var getMaxJobTotalAllowedByMaxFinanceAndMaxDeposit = function (maxFinanceAmount, maxDepositPercentage) { return (maxFinanceAmount / (100 - maxDepositPercentage)) * 100; };
/**
 * getMinJobTotalAllowedByMinFinanceAndMinDeposit
 * @param minFinanceAmount - Minimum finance allowed to apply for (defined by finance plan)
 *   - Decimal free format i.e. 500 for 5.00
 * @param minDepositPercentage - Minimum deposit allowed to apply for (defined by finance plan)
 *   - i.e. 10 for 10%
 */
export var getMinJobTotalAllowedByMinFinanceAndMinDeposit = function (minFinanceAmount, minDepositPercentage) { return (minFinanceAmount / (100 - minDepositPercentage)) * 100; };
/**
 * Checks if job total is under the minimum job total limit to apply for finace
 *    - i.e. min job total < min finance with 0% deposit
 * @param minJobTotalValue
 * @param financePlanMinAmount
 * @param financePlanMinDepositPercentage
 */
export var isJobUnderMinimumLimitJobTotal = function (minJobTotalValue, financePlanMinAmount, financePlanMinDepositPercentage) {
    var minJobTotalLimitDefinedByFinancePlan = getMinJobTotalAllowedByMinFinanceAndMinDeposit(financePlanMinAmount, financePlanMinDepositPercentage);
    return minJobTotalValue < minJobTotalLimitDefinedByFinancePlan;
};
/**
 * Checks if job total is above the maximum job total limit to apply for finance
 *    - i.e. max job total > max finance with 25% deposit
 * @param maxJobTotalValue
 * @param financePlanMaxAmount
 * @param financePlanMaxDepositPercentage
 */
export var isJobOverMaximumLimitJobTotal = function (maxJobTotalValue, financePlanMaxAmount, financePlanMaxDepositPercentage) {
    var maxJobTotalLimitDefinedByFinancePlan = getMaxJobTotalAllowedByMaxFinanceAndMaxDeposit(financePlanMaxAmount, financePlanMaxDepositPercentage);
    return maxJobTotalValue > maxJobTotalLimitDefinedByFinancePlan;
};
/**
 * Checks if job with user defined minimum deposit percentage is above maximum finance defined deposit percentage
 *    - i.e. User defined deposit > finance plan max deposit
 * @param jobMinimumDepositPercentage
 * @param financePlanMaxDepositPercentage
 */
export var isJobMinimumDepositPercentageOverMaximumLimitDeposit = function (jobMinimumDepositPercentage, financePlanMaxDepositPercentage) {
    return jobMinimumDepositPercentage > financePlanMaxDepositPercentage;
};
/**
 * Checks if job total with user defined minimum deposit percentage is under the minimum job total limit to apply for finance
 *    - i.e. Job with user defined deposit < min job required to meet finance minimum
 * @param minJobTotalValue
 * @param financePlanMinAmount
 * @param jobMinimumDepositPercentage
 */
export var isJobWithMinimumDepositPercentageUnderMinimumFinanceLimit = function (minJobTotalValue, financePlanMinAmount, jobMinimumDepositPercentage) {
    var minJobTotalLimitDefinedByUserMinDeposit = getMinJobTotalAllowedByMinFinanceAndMinDeposit(financePlanMinAmount, jobMinimumDepositPercentage);
    return minJobTotalValue < minJobTotalLimitDefinedByUserMinDeposit;
};
/**
 * Check if job with user defined minimum deposit amount is above the finance defined maximum deposit percentage
 * @param jobMinDepositPercentageFromMaxJobTotal
 * @param jobMinDepositPercentageFromMinJobTotal
 * @param financePlanMaxDepositPercentage
 */
export var isJobWithMinimumDepositAmountOverMaximumLimitDeposit = function (jobMinDepositPercentageFromMaxJobTotal, jobMinDepositPercentageFromMinJobTotal, financePlanMaxDepositPercentage) {
    return (jobMinDepositPercentageFromMaxJobTotal > financePlanMaxDepositPercentage ||
        jobMinDepositPercentageFromMinJobTotal > financePlanMaxDepositPercentage);
};
/**
 * Check if job with user defined minimum deposit amount is under the minimum job total limits that allow for finance (checks the min and max job totals - optional/multiple choice job)
 * @param minJobTotalValue
 * @param maxJobTotalValue
 * @param jobMinDepositPercentageFromMaxJobTotal
 * @param jobMinDepositPercentageFromMinJobTotal
 * @param financePlanMinAmount
 */
export var isJobWithMinimumDepositAmountUnderMinimumLimitJobTotals = function (minJobTotalValue, maxJobTotalValue, jobMinDepositPercentageFromMaxJobTotal, jobMinDepositPercentageFromMinJobTotal, financePlanMinAmount) {
    var minJobTotalLimitDefinedByUserMinDepositFromMaxJobTotal = getMinJobTotalAllowedByMinFinanceAndMinDeposit(financePlanMinAmount, jobMinDepositPercentageFromMaxJobTotal);
    var minJobTotalLimitDefinedByUserMinDepositFromMinJobTotal = getMinJobTotalAllowedByMinFinanceAndMinDeposit(financePlanMinAmount, jobMinDepositPercentageFromMinJobTotal);
    return (maxJobTotalValue < minJobTotalLimitDefinedByUserMinDepositFromMaxJobTotal ||
        minJobTotalValue < minJobTotalLimitDefinedByUserMinDepositFromMinJobTotal);
};
export var getMaximumMinimumDepositPercentage = function (minJobTotalValue, financePlanMinAmount) {
    var maximumMinimumDepositPercentageAllowedDefinedByMinFinance = Math.round(((minJobTotalValue - financePlanMinAmount) / minJobTotalValue) * 10000) / 100;
    return maximumMinimumDepositPercentageAllowedDefinedByMinFinance;
};
export var getMaximumMinimumDepositAmount = function (minJobTotalValue, financePlanMinAmount) {
    var maximumMinimumDepositPercentageAllowedDefinedByMinFinance = getMaximumMinimumDepositPercentage(minJobTotalValue, financePlanMinAmount);
    return ((maximumMinimumDepositPercentageAllowedDefinedByMinFinance *
        minJobTotalValue) /
        100);
};
/**
 * Check user defined deposit is between finance defined deposit limits
 * @param userMinimumDepositPercentage
 * @param financeMinimumDepositPercentage
 * @param financeMaximumDepositPercentage
 */
export var isUserMinimumDepositPercentageBetweenDepositLimits = function (userMinimumDepositPercentage, financeMinimumDepositPercentage, financeMaximumDepositPercentage) {
    return (userMinimumDepositPercentage >= financeMinimumDepositPercentage &&
        userMinimumDepositPercentage <= financeMaximumDepositPercentage);
};
/**
 * Get the minimum deposit percentage from user defined minimum deposit and finance plan
 * @param userMinimumDepositAmount
 * @param userMinimumDepositPercentage
 * @param financeMinimumDepositPercentage
 */
export var getMinimumDepositPercentageFromJobAndFinancePlan = function (userMinimumDepositAmount, userMinimumDepositPercentage, financeMinimumDepositPercentage, jobTotal) {
    if (!userMinimumDepositPercentage && !userMinimumDepositAmount) {
        // no user defined deposit - finance plan min deposit
        return financeMinimumDepositPercentage;
    }
    else {
        // get user defined minimum deposit percentage
        var userMinimumDeposit = void 0;
        if (userMinimumDepositPercentage) {
            userMinimumDeposit = userMinimumDepositPercentage;
        }
        else if (userMinimumDepositAmount) {
            userMinimumDeposit = (userMinimumDepositAmount / jobTotal) * 100;
        }
        return userMinimumDeposit &&
            userMinimumDeposit > financeMinimumDepositPercentage
            ? userMinimumDeposit
            : financeMinimumDepositPercentage;
    }
};
/**
 * Get lowest monthly payments using the maximum deposit percentage
 * @param jobTotal -
 * @param maxDepositPercentage - defined by finance plan
 * @param minFinanceAmount - defined by finance plan
 * @param agreementDuration
 */
export var getMinimumMonthlyCost = function (jobTotal, maxDepositPercentage, minFinanceAmount, interestRate, agreementDuration) {
    // get max deposit taking into account finance plan defined max deposit and min finance allowed
    var maxDepositPercentageAllowed = getMaximumDepositPercentage(jobTotal, minFinanceAmount, maxDepositPercentage);
    var maxDepositAmount = Math.round((maxDepositPercentageAllowed / 100) * jobTotal);
    var monthlyCost = getTotalCreditAndMonthlyCost(jobTotal, maxDepositAmount, interestRate, agreementDuration).monthlyCost;
    return monthlyCost;
};
/**
 * Get total amount of credit and monthly cost
 * @param jobTotal
 * @param depositAmount
 * @param interestRate
 * @param financeMonths
 * @returns
 */
export var getTotalCreditAndMonthlyCost = function (jobTotal, depositAmount, interestRate, financeMonths) {
    var financeAmount = jobTotal - depositAmount;
    var totalCredit = Math.round(financeAmount + (financeAmount * Number(interestRate)) / 100);
    var monthlyCost = Math.round(totalCredit / financeMonths);
    return {
        totalCredit: totalCredit,
        monthlyCost: monthlyCost,
    };
};
