import React, { useState, useCallback, useEffect, useMemo, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './RecordPaymentControl.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { PaymentMethodType } from '@bit/payaca-tech.payaca-core.types.job-payment';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { ValidatedForm } from '@payaca/components';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { CurrencyField } from '@payaca/components/currencyField';
import { DropdownField } from '@payaca/components/dropdownField';
import { Checkbox } from '@payaca/components/checkbox';
import { getIsRequiredFieldValidator, getNumericalRangeFieldValidator, } from '@payaca/helpers/fieldValidationHelper';
import { actions as jobPaymentsActions } from '@bit/payaca-tech.payaca-core.store.job-payments';
import MiniLoader from '@bit/payaca-tech.payaca-core.component.mini-loader';
import LabelValuePair from '@bit/payaca-tech.payaca-core.component.label-value-pair';
import { currencyPrice } from '@bit/payaca-tech.payaca-core.helpers.finance';
import { BasicField } from '@payaca/components/basicField';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import { ErrorMessage } from '@payaca/components/feedbackMessage';
var paymentMethodOptions = [
    {
        label: 'BACS transfer',
        value: PaymentMethodType.BACS,
    },
    {
        label: 'Cash',
        value: PaymentMethodType.CASH,
    },
    {
        label: 'Cheque',
        value: PaymentMethodType.CHEQUE,
    },
    {
        label: 'Card',
        value: PaymentMethodType.CARD,
    },
    {
        label: 'Other',
        value: PaymentMethodType.OTHER,
    },
];
var fieldValidators = {
    paymentValue: [
        getIsRequiredFieldValidator({ readableName: 'payment amount' }),
        getNumericalRangeFieldValidator(0, 10000000, 'payment amount', 'payment amount must be greater than zero', 'payment amount must not exceed £100,000'),
    ],
    paymentMethod: [
        getIsRequiredFieldValidator({ readableName: 'payment method' }),
    ],
};
var RecordPaymentControl = function (_a) {
    var outstandingPaymentValue = _a.outstandingPaymentValue, isDepositPayment = _a.isDepositPayment, customerName = _a.customerName, jobReference = _a.jobReference, jobId = _a.jobId, _b = _a.canSendReceipt, canSendReceipt = _b === void 0 ? true : _b, canRecordAnyPaymentValue = _a.canRecordAnyPaymentValue, recordPaymentCallback = _a.recordPaymentCallback;
    var dispatch = useDispatch();
    var _c = useState(false), isSubmitted = _c[0], setIsSubmitted = _c[1];
    var _d = useState(false), hasCalledCallback = _d[0], setHasCalledCallback = _d[1];
    var isRecordingJobPayment = useSelector(function (state) {
        return state.jobPayments.isRecordingJobPayment;
    });
    var isJobPaymentRecordedSuccessfully = useSelector(function (state) {
        console.log(state.jobPayments);
        return state.jobPayments.isJobPaymentRecordedSuccessfully;
    });
    var isOutstandingPaymentDue = useMemo(function () {
        return !!outstandingPaymentValue;
    }, [outstandingPaymentValue]);
    useEffect(function () {
        if (isSubmitted &&
            !isRecordingJobPayment &&
            isJobPaymentRecordedSuccessfully &&
            recordPaymentCallback &&
            !hasCalledCallback) {
            setHasCalledCallback(true);
            recordPaymentCallback();
        }
    }, [
        isSubmitted,
        isRecordingJobPayment,
        isJobPaymentRecordedSuccessfully,
        hasCalledCallback,
        recordPaymentCallback,
    ]);
    var onSubmit = useCallback(function (recordJobPaymentRequestData) {
        dispatch(jobPaymentsActions.requestRecordJobPayment(recordJobPaymentRequestData));
        setIsSubmitted(true);
    }, [dispatch]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        var paymentValueExceedsOutstanding = formState.paymentValue > outstandingPaymentValue;
        return (React.createElement(React.Fragment, null,
            !canRecordAnyPaymentValue && (React.createElement(LabelValuePair, { label: "Payment amount", value: currencyPrice(formState.paymentValue) })),
            React.createElement("div", { className: "fields-container" },
                React.createElement("div", { className: "flex-container" },
                    canRecordAnyPaymentValue && (React.createElement("div", { className: "flex-grow" },
                        React.createElement(ValidatedFieldWrapper, { validationResult: validationState.paymentValue, isTouched: touchedState.paymentValue || false },
                            React.createElement(CurrencyField, { styleVariant: InputStyleVariant.OUTSIZE, name: "paymentValue", value: formState.paymentValue || 0, isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, label: "Payment amount" })))),
                    React.createElement("div", { className: "flex-grow" },
                        React.createElement(ValidatedFieldWrapper, { validationResult: validationState.paymentMethod, isTouched: touchedState.paymentMethod || false },
                            React.createElement(DropdownField, { styleVariant: InputStyleVariant.OUTSIZE, options: paymentMethodOptions, name: "paymentMethod", value: formState.paymentMethod || PaymentMethodType.BACS, isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, label: "Payment method" })))),
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.note, isTouched: touchedState.note || false },
                    React.createElement(BasicField, { additionalInputProps: {
                            maxLength: 150,
                        }, styleVariant: InputStyleVariant.OUTSIZE, name: "note", value: formState.note, isRequired: false, onChange: onFieldChange, onTouch: onFieldTouch, label: "Internal note" }))),
            canSendReceipt && (React.createElement("div", null,
                React.createElement("div", { className: "checkbox-container flex-container flex-center" },
                    React.createElement("div", null,
                        React.createElement(Checkbox, { isChecked: formState.sendReceipt === undefined
                                ? true
                                : formState.sendReceipt, onChange: function () {
                                onFieldChange({
                                    sendReceipt: !formState.sendReceipt,
                                });
                                onFieldTouch && onFieldTouch('sendReceipt');
                            } })),
                    React.createElement("span", null, "I would like to send a receipt to the customer for this amount")))),
            React.createElement("p", null,
                "By continuing I confirm that I have recieved this",
                ' ',
                isDepositPayment ? 'deposit payment' : 'payment',
                " from",
                ' ',
                customerName,
                " towards job #",
                jobReference,
                "."),
            React.createElement("div", { className: "actions-container" },
                paymentValueExceedsOutstanding && isOutstandingPaymentDue && (React.createElement(ErrorMessage, { message: "A payment of " + currencyPrice(formState.paymentValue) + " exceeds the\n              outstanding amount of " + currencyPrice(outstandingPaymentValue) + ".\n              Are you sure you want to record this payment?" })),
                !isOutstandingPaymentDue && (React.createElement(ErrorMessage, { message: "There is no outstanding payment required against this job. Are\n              you sure you want to record a payment?" })),
                React.createElement(Button, { onClick: function () { return onSubmit(formState); }, isDisabled: !isValid, iconAfter: faChevronRight, styleVariant: ButtonStyleVariant.OUTSIZE }, "Continue"))));
    }, [
        onSubmit,
        customerName,
        jobReference,
        isDepositPayment,
        outstandingPaymentValue,
        canSendReceipt,
        isOutstandingPaymentDue,
    ]);
    return (React.createElement("div", { className: "record-payment-control" },
        !isSubmitted && (React.createElement(ValidatedForm, { renderFormContents: renderFormContents, initialFormState: {
                paymentValue: outstandingPaymentValue,
                paymentMethod: PaymentMethodType.BACS,
                isDepositPayment: isDepositPayment,
                sendReceipt: canSendReceipt,
                jobId: jobId,
            }, fieldValidators: fieldValidators })),
        isSubmitted && isRecordingJobPayment && (React.createElement("div", { className: "loader-container" },
            React.createElement(MiniLoader, null))),
        isSubmitted && !isRecordingJobPayment && (React.createElement("div", { className: "feedback-container" }, isJobPaymentRecordedSuccessfully ? (React.createElement(React.Fragment, null,
            React.createElement(FontAwesomeIcon, { icon: faCheck }),
            React.createElement("p", null, "Payment has been successfully recorded!"))) : (React.createElement("p", null, "Something went wrong recording this payment"))))));
};
export default RecordPaymentControl;
