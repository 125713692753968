import React, { useEffect, useState, useCallback, } from 'react';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { ShieldDone } from 'react-iconly';
import { actions as appActions } from '@/api/app';
import { actions as authActions } from '@bit/payaca-tech.payaca-core.store.auth';
import './ResetPasswordPage.sass';
import UnauthenticatedPageWrapper from '../pageWrappers/unauthenticatedPageWrapper/UnauthenticatedPageWrapper';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import ResetPasswordForm from '@/ui/components/resetPasswordForm/ResetPasswordForm';
import LoginForm from '@/ui/components/loginForm/LoginForm';
var PageState;
(function (PageState) {
    PageState["RESET_PASSWORD"] = "reset-password";
    PageState["RESET_PASSWORD_SUCCESS"] = "reset-password-success";
    PageState["INVALID_TOKEN"] = "invalid-token";
})(PageState || (PageState = {}));
var ResetPasswordPage = function (_a) {
    var match = _a.match, location = _a.location, history = _a.history, isAuthed = _a.isAuthed;
    var dispatch = useDispatch();
    var _b = useState(PageState.RESET_PASSWORD), pageState = _b[0], setPageState = _b[1];
    var _c = useState(false), linkToNativeApp = _c[0], setLinkToNativeApp = _c[1];
    var goToRegisterPage = useCallback(function () {
        history.push('/register');
    }, [history]);
    useEffect(function () {
        if (isAuthed) {
            dispatch(authActions.logout());
        }
        else {
            validateToken();
        }
    }, []);
    var validateToken = useCallback(function () {
        var token = match.params.token;
        var query = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });
        setLinkToNativeApp(query.linkToNativeApp === 'true');
        dispatch(appActions.validResetToken(token, function (err) {
            if (err) {
                setPageState(PageState.INVALID_TOKEN);
            }
        }));
    }, [dispatch, location.search, match.params.token]);
    return (React.createElement(UnauthenticatedPageWrapper, { headerContent: React.createElement("div", { className: "flex-container" },
            React.createElement("div", { className: "flex-grow" }),
            React.createElement("span", { className: "sign-up-prompt" },
                "No account?",
                ' ',
                React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: goToRegisterPage }, "Sign up"))), className: "reset-password-page" },
        pageState === PageState.RESET_PASSWORD && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "title-introduction-container" },
                React.createElement("h2", null, "Reset your password"),
                React.createElement("p", null, "Please enter and confirm your new password below")),
            React.createElement(ResetPasswordForm, { resetToken: match.params.token, onSuccessCallback: function () {
                    return setPageState(PageState.RESET_PASSWORD_SUCCESS);
                } }))),
        pageState === PageState.INVALID_TOKEN && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "title-introduction-container" },
                React.createElement("p", null, "You have used an invalid password reset link.")),
            React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, hasBoxShadow: true, onClick: function () { return history.push('/forgotPassword'); } }, "Request a new one"))),
        pageState === PageState.RESET_PASSWORD_SUCCESS && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "title-introduction-container" },
                React.createElement(ShieldDone, { size: "xlarge", set: "light" }),
                React.createElement("h2", null, "Your password has been reset"),
                React.createElement("p", null, "You can now log in with your new password.")),
            linkToNativeApp && (React.createElement(React.Fragment, null,
                React.createElement("a", { href: 'Payaca://', className: "mobile-app-link" },
                    React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, hasBoxShadow: true }, "Return to login page")))),
            !linkToNativeApp && React.createElement(LoginForm, null)))));
};
export default ResetPasswordPage;
