import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import SubscriptionProductSelectionControl from '../subscriptionProductSelectionControl/SubscriptionProductSelectionControl';
import './SubscriptionProductsSelectionControl.sass';
import { Button, ButtonStyleVariant, ButtonColourVariant, } from '@payaca/components/button';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { Badge } from '@payaca/components/badge';
var SubscriptionProductsSelectionControl = function (_a) {
    var recurringInterval = _a.recurringInterval, _b = _a.enableEmbeddedUserSeatControl, enableEmbeddedUserSeatControl = _b === void 0 ? false : _b, selectedProductId = _a.selectedProductId, additionalUserSeats = _a.additionalUserSeats, onChange = _a.onChange;
    var subscriptionProducts = useSelector(function (state) {
        return state.subscription.products;
    });
    var selectedSubscriptionProduct = useMemo(function () {
        return subscriptionProducts === null || subscriptionProducts === void 0 ? void 0 : subscriptionProducts.find(function (product) { return product.stripeId === selectedProductId; });
    }, [subscriptionProducts, selectedProductId]);
    var renderSubscriptionProduct = useCallback(function (productName, badge) {
        if (!subscriptionProducts)
            return null;
        var subscriptionProduct = subscriptionProducts.find(function (product) { return product.name === productName; });
        if (!subscriptionProduct)
            return null;
        var isSelected = selectedProductId === subscriptionProduct.stripeId;
        var isDowngrade = !isSelected &&
            selectedSubscriptionProduct &&
            ((selectedSubscriptionProduct === null || selectedSubscriptionProduct === void 0 ? void 0 : selectedSubscriptionProduct.numberOfJobsPerMonth) || 0) >
                ((subscriptionProduct === null || subscriptionProduct === void 0 ? void 0 : subscriptionProduct.numberOfJobsPerMonth) || 0);
        var isUpgrade = !isSelected &&
            selectedSubscriptionProduct &&
            ((selectedSubscriptionProduct === null || selectedSubscriptionProduct === void 0 ? void 0 : selectedSubscriptionProduct.numberOfJobsPerMonth) || 0) <
                ((subscriptionProduct === null || subscriptionProduct === void 0 ? void 0 : subscriptionProduct.numberOfJobsPerMonth) || 0);
        var buttonTitle = 'Select plan';
        if (isDowngrade) {
            buttonTitle = 'Downgrade';
        }
        else if (isUpgrade) {
            buttonTitle = 'Upgrade';
        }
        else if (isSelected) {
            buttonTitle = 'Selected';
        }
        return (React.createElement(SubscriptionProductSelectionControl, { subscriptionProduct: subscriptionProduct, recurringInterval: recurringInterval, additionalUserSeats: additionalUserSeats, badge: badge, enableEmbeddedUserSeatControl: enableEmbeddedUserSeatControl, onSelect: function () {
                var _a, _b;
                return onChange({
                    productId: subscriptionProduct.stripeId,
                    priceId: recurringInterval === 'month'
                        ? (_a = subscriptionProduct.monthlyPrice) === null || _a === void 0 ? void 0 : _a.stripeId : (_b = subscriptionProduct.annualPrice) === null || _b === void 0 ? void 0 : _b.stripeId,
                    additionalUserSeats: 0,
                });
            }, onChangeAdditionalUserSeats: function (additionalUserSeats) {
                var _a, _b;
                return onChange({
                    productId: subscriptionProduct.stripeId,
                    priceId: recurringInterval === 'month'
                        ? (_a = subscriptionProduct.monthlyPrice) === null || _a === void 0 ? void 0 : _a.stripeId : (_b = subscriptionProduct.annualPrice) === null || _b === void 0 ? void 0 : _b.stripeId,
                    additionalUserSeats: additionalUserSeats,
                });
            }, isSelected: selectedProductId === subscriptionProduct.stripeId, renderCta: function (isSelected, onSelect) {
                return (React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, colourVariant: isDowngrade
                        ? ButtonColourVariant.GREY
                        : isSelected
                            ? ButtonColourVariant.SECONDARY
                            : ButtonColourVariant.PRIMARY, isDisabled: isDowngrade || isSelected, onClick: onSelect, onClickDisabled: function () { return (isSelected ? null : onSelect()); } }, buttonTitle));
            } }));
    }, [
        subscriptionProducts,
        selectedSubscriptionProduct,
        onChange,
        recurringInterval,
        additionalUserSeats,
        selectedProductId,
        enableEmbeddedUserSeatControl,
    ]);
    var enterpriseProductSelectionControl = useMemo(function () {
        return (React.createElement("div", { className: "subscription-product-selection-control-outer enterprise-product-selection-control" },
            React.createElement("div", { className: "subscription-product-selection-control" },
                React.createElement("div", { className: "subscription-product-selection-control-inner" },
                    React.createElement("div", { className: "product-name-container" },
                        React.createElement("h2", { className: "product-name" }, "Enterprise")),
                    React.createElement("hr", null),
                    React.createElement("p", null, "Need a custom plan? Contact us to discuss.")),
                React.createElement("div", { className: "button-container" },
                    React.createElement("a", { href: "tel:+443330509475", title: "0333 050 9475" },
                        React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, colourVariant: ButtonColourVariant.PRIMARY, iconBefore: faPhone }, "0333 050 9475"))))));
    }, []);
    return (React.createElement("div", { className: "subscription-products-selection-control" },
        React.createElement("div", { className: "products-container" },
            renderSubscriptionProduct('Starter'),
            renderSubscriptionProduct('Professional', React.createElement(Badge, { colour: "#ffffff", backgroundColour: "#0872ED" }, "Most popular")),
            renderSubscriptionProduct('Premium'),
            enterpriseProductSelectionControl)));
};
export default SubscriptionProductsSelectionControl;
