var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
import './Button.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { ButtonStyleVariant } from './Button';
//@ts-ignore 'react-iconly has no exported member "Iconly"' - but it does
import { Iconly } from 'react-iconly';
var IconButton = function (props) {
    var renderedIcon = useMemo(function () {
        if (typeof props.icon === 'string') {
            return (React.createElement(Iconly, { className: "react-iconly", name: props.icon, set: "light", size: "xl" }));
        }
        else if (React.isValidElement(props.icon)) {
            return props.icon;
        }
        else {
            //@ts-ignore
            return React.createElement(FontAwesomeIcon, { icon: props.icon, size: props.size });
        }
    }, [props.icon]);
    return (React.createElement("div", { className: "icon-button-container" },
        React.createElement(Button, __assign({}, props, { children: renderedIcon, className: "icon-button " + (props.size ? "icon-" + props.size : '') + " " + (props.className ? props.className : ''), styleVariant: ButtonStyleVariant.CIRCULAR })),
        props.label && React.createElement("span", null, props.label)));
};
export default IconButton;
