var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actions as appActions } from '@/api/app';
import { actions as lineItemActions } from '@bit/payaca-tech.payaca-core.store.line-items';
import { SortBy } from '@bit/payaca-tech.payaca-core.types.listed-item';
import { SortDirection } from '@bit/payaca-tech.payaca-core.types.list-view';
import ListedItemsTable from '@/ui/components/listedItemsTable/ListedItemsTable';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { actions as itemActions } from '@bit/payaca-tech.payaca-core.store.listed-items';
import { Button, CreateButton, ButtonStyleVariant, } from '@payaca/components/button';
import './ListedItemsPage.sass';
import BulkUploadModal from '@/ui/components/bulkUploadModal/BulkUploadModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
var ListedItemsPage = function () {
    var dispatch = useDispatch();
    var history = useHistory();
    var _a = useState(false), isInBulkActionsMode = _a[0], setIsInBulkActionsMode = _a[1];
    var _b = useState(false), showBulkUploadModal = _b[0], setShowBulkUploadModal = _b[1];
    var _c = useState([]), selectedItemIds = _c[0], setSelectedItemIds = _c[1];
    var _d = useState(false), hasSubmittedBulkDelete = _d[0], setHasSubmittedBulkDelete = _d[1];
    var _e = useState({
        pageSize: 100,
        pageNumber: 1,
        searchTerm: '',
        sortDirection: SortDirection.ASCENDING,
        sortBy: SortBy.NAME,
    }), getListedItemsRequestData = _e[0], setGetListedItemsRequestData = _e[1];
    var isDeletingLineItems = useSelector(function (state) {
        return state.lineItems.isDeletingLineItems;
    });
    useEffect(function () {
        requestGetListedItemsPage();
    }, [getListedItemsRequestData]);
    var requestGetListedItemsPage = useCallback(function () {
        dispatch(itemActions.requestGetListedItemsPage(getListedItemsRequestData));
    }, [getListedItemsRequestData, dispatch]);
    var itemActionCallback = useCallback(function (error) {
        if (error) {
            dispatch(appActions.showBanner({ type: 'error' }));
        }
        setHasSubmittedBulkDelete(false);
        setSelectedItemIds([]);
        requestGetListedItemsPage();
    }, [requestGetListedItemsPage, dispatch]);
    useEffect(function () {
        if (hasSubmittedBulkDelete && !isDeletingLineItems) {
            itemActionCallback();
        }
    }, [hasSubmittedBulkDelete, isDeletingLineItems, itemActionCallback]);
    var onSelectItems = useCallback(function (itemIds) {
        setSelectedItemIds(function (selectedItemIds) {
            return selectedItemIds
                .concat(itemIds)
                .filter(function (itemId, index, array) {
                return array.indexOf(itemId) === index; // get unique
            });
        });
    }, []);
    var onUnselectItems = useCallback(function (itemIds) {
        setSelectedItemIds(function (selectedItemIds) {
            return selectedItemIds.filter(function (itemId) {
                return !itemIds.includes(itemId);
            });
        });
    }, []);
    var navigateToItemPage = useCallback(function (itemId) {
        history.push("/items/" + itemId);
    }, [history]);
    var onClickRow = useCallback(function (itemId) {
        navigateToItemPage(itemId);
    }, [navigateToItemPage]);
    var onBulkDeleteItems = useCallback(function (itemIds) {
        dispatch(lineItemActions.requestDeleteLineItems(itemIds));
        setHasSubmittedBulkDelete(true);
    }, [dispatch, itemActionCallback]);
    var onSelectPage = useCallback(function (pageNumber) {
        setGetListedItemsRequestData(function (getListedItemsRequestData) {
            return __assign(__assign({}, getListedItemsRequestData), { pageNumber: pageNumber });
        });
    }, []);
    var onChangeSortOrder = useCallback(function (sortBy, sortDirection) {
        setGetListedItemsRequestData(function (getListedItemsRequestData) {
            return __assign(__assign({}, getListedItemsRequestData), { pageNumber: 1, sortBy: sortBy, sortDirection: sortDirection });
        });
    }, []);
    var renderListedItemsTable = useMemo(function () {
        return (React.createElement(ListedItemsTable, { sortBy: getListedItemsRequestData.sortBy, sortDirection: getListedItemsRequestData.sortDirection, selectedItemIds: selectedItemIds, onClickRow: isInBulkActionsMode ? undefined : onClickRow, onSelectItems: onSelectItems, onUnselectItems: onUnselectItems, onSelectPage: onSelectPage, onChangeSortOrder: onChangeSortOrder }));
    }, [
        getListedItemsRequestData,
        selectedItemIds,
        onClickRow,
        onSelectItems,
        onUnselectItems,
        onSelectPage,
        onChangeSortOrder,
        isInBulkActionsMode,
    ]);
    var listedItemsTitleBar = useMemo(function () {
        return (React.createElement("div", { className: "flex-container flex-center flex-grow" },
            React.createElement("div", null,
                React.createElement("h1", null, "Items")),
            React.createElement(CreateButton, { onClick: function () { return history.push('/items/new'); } }, "Create new"),
            React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () { return setIsInBulkActionsMode(true); } }, "Edit items"),
            React.createElement("div", { className: "flex-grow" }),
            React.createElement(Button, { onClick: function () { return setShowBulkUploadModal(true); }, className: "upload-button", hasBoxShadow: false },
                React.createElement(FontAwesomeIcon, { icon: faUpload }))));
    }, [history]);
    var listedItemsBulkActionsBar = useMemo(function () {
        var isAnyItemsSelected = !!selectedItemIds.length;
        return (React.createElement("div", { className: "flex-container flex-center flex-grow" },
            React.createElement("div", { className: "flex-container" },
                React.createElement("div", null,
                    React.createElement("h1", null, "Users")),
                React.createElement("span", null, isAnyItemsSelected
                    ? "You have " + selectedItemIds.length + " " + (selectedItemIds.length === 1 ? 'row' : 'rows') + " selected"
                    : 'Check the rows you wish to select')),
            React.createElement("div", { className: "flex-grow" }),
            React.createElement(Button, { isDisabled: !isAnyItemsSelected, isProcessing: isDeletingLineItems, onClick: function () {
                    return !isDeletingLineItems && onBulkDeleteItems(selectedItemIds);
                } }, "Delete"),
            React.createElement(Button, { styleVariant: ButtonStyleVariant.ANCHOR, onClick: function () {
                    setIsInBulkActionsMode(false);
                    setSelectedItemIds([]);
                } }, "Cancel")));
    }, [selectedItemIds, isDeletingLineItems, onBulkDeleteItems]);
    return (React.createElement(AuthenticatedPageWrapper, { className: "listed-items-page", title: isInBulkActionsMode ? listedItemsBulkActionsBar : listedItemsTitleBar, searchFieldConfig: {
            placeholder: 'Search',
            onSearchTermChange: function (searchTerm) {
                setGetListedItemsRequestData(function (getListedItemsRequestData) {
                    return __assign(__assign({}, getListedItemsRequestData), { searchTerm: searchTerm, pageNumber: 1 });
                });
            },
        } },
        React.createElement("div", { className: "listed-items-table-container " + (isInBulkActionsMode ? 'bulk-actions-mode' : '') }, renderListedItemsTable),
        React.createElement(BulkUploadModal, { isOpen: showBulkUploadModal, onClose: function () {
                setShowBulkUploadModal(false);
            }, onUploadSuccess: requestGetListedItemsPage, title: "Upload items" })));
};
export default ListedItemsPage;
