import React, { useCallback, useState } from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDealByJobId, getJob } from '@/utils/stateAccessors';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { actions as jobsActions } from '@bit/payaca-tech.payaca-core.store.jobs';
import ValidationResultModal from '../validationResultModal/ValidationResultModal';
var EditJobPreviewButton = function (_a) {
    var _b;
    var jobId = _a.jobId, proceedToPreview = _a.proceedToPreview;
    var dispatch = useDispatch();
    var _c = useState(false), showValidationResultModal = _c[0], setShowValidationResultModal = _c[1];
    var _d = useState(), validationResult = _d[0], setValidationResult = _d[1];
    var job = useSelector(function (state) { return getJob(state, jobId); });
    var deal = useSelector(function (state) {
        return getDealByJobId(state, jobId);
    });
    var isModifyingJobLineItemsOrJobLineItemGroups = useSelector(function (state) {
        return (state.jobsStore.isUpdatingJobLineItem ||
            state.jobsStore.isDeletingJobLineItem ||
            state.jobsStore.isCreatingJobLineItem ||
            state.jobsStore.isCreatingJobLineItemGroup ||
            state.jobsStore.isUpdatingJobLineItemGroup ||
            state.jobsStore.isDeletingJobLineItemGroup ||
            state.jobsStore.isAddingLineItemGroupToJob ||
            state.jobsStore.isAddingAttachmentToJobLineItem ||
            state.jobsStore.isRemovingAttachmentFromJobLineItem);
    });
    var isUpdatingJob = useSelector(function (state) {
        return state.jobsStore.isUpdatingJob;
    });
    var isPersistingCustomer = useSelector(function (state) {
        return state.customer.isPersistingCustomer;
    });
    var isSettingDealCustomer = useSelector(function (state) {
        return state.deals.isSettingDealCustomer;
    });
    var isProcessing = useMemo(function () {
        return (isUpdatingJob ||
            isPersistingCustomer ||
            isSettingDealCustomer ||
            isModifyingJobLineItemsOrJobLineItemGroups);
    }, [
        isUpdatingJob,
        isPersistingCustomer,
        isSettingDealCustomer,
        isModifyingJobLineItemsOrJobLineItemGroups,
    ]);
    var isGettingJobValidationResult = useSelector(function (state) {
        return state.jobsStore.isGettingJobValidationResult;
    });
    var isRequestPreviewEnabled = useMemo(function () {
        var _a;
        return !isProcessing && !!((deal === null || deal === void 0 ? void 0 : deal.customerId) && ((_a = job === null || job === void 0 ? void 0 : job.jobLineItemIds) === null || _a === void 0 ? void 0 : _a.length));
    }, [(_b = job === null || job === void 0 ? void 0 : job.jobLineItemIds) === null || _b === void 0 ? void 0 : _b.length, deal === null || deal === void 0 ? void 0 : deal.customerId, isProcessing]);
    var onGetJobValidationResultSuccess = useCallback(function (validationResult) {
        setValidationResult(validationResult);
        if (!validationResult.isValid) {
            setShowValidationResultModal(true);
        }
        else {
            proceedToPreview();
        }
    }, [proceedToPreview]);
    var onRequestPreview = useCallback(function () {
        dispatch(jobsActions.requestGetJobValidationResult(jobId, onGetJobValidationResultSuccess));
    }, [onGetJobValidationResultSuccess, jobId, dispatch]);
    return (React.createElement("div", null,
        React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, isProcessing: isGettingJobValidationResult, isDisabled: !isRequestPreviewEnabled, onClick: function () { return !isGettingJobValidationResult && onRequestPreview(); } }, "Preview"),
        validationResult && showValidationResultModal && (React.createElement(ValidationResultModal, { title: 'Preview not available', validationResult: validationResult, isOpen: showValidationResultModal, onClose: function () { return setShowValidationResultModal(false); } }))));
};
export default EditJobPreviewButton;
