var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actions as dealActions } from '@payaca/store/deals';
import ListedDealsTable from '@/ui/components/listedDealsTable/ListedDealsTable';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { SortBy, } from '@payaca/types/listedDealTypes';
import { SortDirection } from '@bit/payaca-tech.payaca-core.types.list-view';
import { CreateButton } from '@payaca/components/button';
import './ListedDealsPage.sass';
import ListedDealsFilterControls from '@/ui/components/listedDealsFilterControls/ListedDealsFilterControls';
import { ResponsiveViewWrapper } from '@payaca/components';
var LOCAL_STORAGE_DEAL_ITEM_STATUS_KEY = 'payaca-listed-deal-status-filter';
var LOCAL_STORAGE_USER_ASSIGNMENT_KEY = 'payaca-listed-deal-user-filter';
var LOCAL_STORAGE_DEAL_ITEM_TYPE_KEY = 'payaca-listed-deal-type-filter';
var ListedDealsPage = function () {
    var dispatch = useDispatch();
    var history = useHistory();
    var _a = useState([]), selectedDealIds = _a[0], setSelectedDealIds = _a[1];
    var initialReadableStatuses = useMemo(function () {
        var statuses = localStorage.getItem(LOCAL_STORAGE_DEAL_ITEM_STATUS_KEY);
        return (statuses ? statuses.split(',') : []);
    }, []);
    var initialUserAssignments = useMemo(function () {
        var userAssignments = localStorage.getItem(LOCAL_STORAGE_USER_ASSIGNMENT_KEY);
        return userAssignments
            ? userAssignments
                .split(',')
                .map(function (x) { return (Number(x) ? Number(x) : x); })
                .filter(function (x) { return Number(x) || x === 'unassigned'; })
            : [];
    }, []);
    var initialDealItemTypes = useMemo(function () {
        var dealItemTypes = localStorage.getItem(LOCAL_STORAGE_DEAL_ITEM_TYPE_KEY);
        return dealItemTypes ? dealItemTypes.split(',') : [];
    }, []);
    var _b = useState({
        sortBy: SortBy.LAST_UPDATED,
        sortDirection: SortDirection.DESCENDING,
        pageSize: 20,
        pageNumber: 1,
        searchTerm: '',
        readableStatuses: initialReadableStatuses,
        types: initialDealItemTypes,
        userAssignments: initialUserAssignments,
    }), getListedDealsRequestData = _b[0], setGetListedDealsRequestData = _b[1];
    useEffect(function () {
        requestGetListedDealsPage();
    }, [getListedDealsRequestData]);
    var requestGetListedDealsPage = useCallback(function () {
        dispatch(dealActions.requestGetListedDealsPage(getListedDealsRequestData));
    }, [getListedDealsRequestData, dispatch]);
    var onSelectDeals = useCallback(function (dealIds) {
        setSelectedDealIds(function (selectedDealIds) {
            return selectedDealIds
                .concat(dealIds)
                .filter(function (dealId, index, array) {
                return array.indexOf(dealId) === index; // get unique
            });
        });
    }, []);
    var onUnselectDeals = useCallback(function (dealIds) {
        setSelectedDealIds(function (selectedDealIds) {
            return selectedDealIds.filter(function (dealId) {
                return !dealIds.includes(dealId);
            });
        });
    }, []);
    var navigateToDealPage = useCallback(function (dealId) {
        history.push("/deals/" + dealId);
    }, [history]);
    var onClickRow = useCallback(function (dealId) {
        navigateToDealPage(dealId);
    }, [navigateToDealPage]);
    var onAddNewDeal = useCallback(function () {
        dispatch(dealActions.requestCreateDeal(function (dealId) {
            history.push("/deals/" + dealId);
        }));
    }, [dispatch, history]);
    var onSelectPage = useCallback(function (pageNumber) {
        setGetListedDealsRequestData(function (getListedDealsRequestData) {
            return __assign(__assign({}, getListedDealsRequestData), { pageNumber: pageNumber });
        });
    }, []);
    var onSelectPageSize = useCallback(function (pageSize) {
        setGetListedDealsRequestData(function (getListedDealsRequestData) {
            return __assign(__assign({}, getListedDealsRequestData), { pageSize: pageSize, pageNumber: 1 });
        });
    }, []);
    var onChangeSortOrder = useCallback(function (sortBy, sortDirection) {
        setGetListedDealsRequestData(function (getListedDealsRequestData) {
            return __assign(__assign({}, getListedDealsRequestData), { sortBy: sortBy, sortDirection: sortDirection });
        });
    }, []);
    var onChangeFilters = function (value) {
        setGetListedDealsRequestData(function (getListedDealsRequestData) {
            // save readableStatuses/userAssignments to localstorage
            if (value.readableStatuses) {
                localStorage.setItem(LOCAL_STORAGE_DEAL_ITEM_STATUS_KEY, value.readableStatuses);
            }
            if (value.userAssignments) {
                localStorage.setItem(LOCAL_STORAGE_USER_ASSIGNMENT_KEY, value.userAssignments);
            }
            if (value.types) {
                localStorage.setItem(LOCAL_STORAGE_DEAL_ITEM_TYPE_KEY, value.types);
            }
            return __assign(__assign(__assign({}, getListedDealsRequestData), value), { pageNumber: 1 });
        });
    };
    var renderListedDealsTable = useMemo(function () {
        return (React.createElement(ListedDealsTable, { sortBy: getListedDealsRequestData.sortBy, sortDirection: getListedDealsRequestData.sortDirection, readableStatuses: getListedDealsRequestData.readableStatuses, types: getListedDealsRequestData.types, userAssignments: getListedDealsRequestData.userAssignments, searchTerm: getListedDealsRequestData.searchTerm, selectedDealIds: selectedDealIds, onClickRow: onClickRow, onSelectDeals: onSelectDeals, onUnselectDeals: onUnselectDeals, onSelectPage: onSelectPage, onChangeSortOrder: onChangeSortOrder, onSelectPageSize: onSelectPageSize, onChangeFilters: onChangeFilters }));
    }, [
        selectedDealIds,
        onClickRow,
        onSelectDeals,
        onUnselectDeals,
        onSelectPage,
        onChangeSortOrder,
        getListedDealsRequestData,
    ]);
    var listedDealsTitleBar = useMemo(function () {
        return (React.createElement(ResponsiveViewWrapper, { className: "listed-deals-title-bar flex-container flex-center flex-grow", downBreakpointSm: 850 },
            React.createElement("div", { className: "flex-container flex-center flex-grow" },
                React.createElement("div", null,
                    React.createElement("h1", null, "Deals")),
                React.createElement(CreateButton, { onClick: onAddNewDeal }, "Create new")),
            React.createElement("div", { className: "flex-container listed-deals-filters" },
                React.createElement(ListedDealsFilterControls, { getListedDealsRequestData: getListedDealsRequestData, onChange: onChangeFilters }))));
    }, [history, onAddNewDeal, getListedDealsRequestData]);
    return (React.createElement(AuthenticatedPageWrapper, { className: "listed-deals-page", title: listedDealsTitleBar, searchFieldConfig: {
            placeholder: 'Search',
            onSearchTermChange: function (searchTerm) {
                setGetListedDealsRequestData(function (getListedItemsRequestData) {
                    return __assign(__assign({}, getListedItemsRequestData), { searchTerm: searchTerm, pageNumber: 1 });
                });
            },
        } },
        React.createElement("div", { className: "listed-deals-table-container" }, renderListedDealsTable)));
};
export default ListedDealsPage;
