import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as accountActions } from '@bit/payaca-tech.payaca-core.store.account';
import { actions as userActions } from '@/api/users';
import { TrialPeriodCountdownBanner, SubscriptionExceededBanner, } from '@/ui/components';
import UserSeatsExceededBanner from '../userSeatsExceededBanner/UserSeatsExceededBanner';
var AccountAccessBanner = function () {
    var dispatch = useDispatch();
    var accountAccessInformation = useSelector(function (state) {
        return state.account.accountAccessInformation;
    });
    var shouldRenderTrialPeriodCountdownBanner = useMemo(function () {
        if (!accountAccessInformation)
            return false;
        return (accountAccessInformation.isOnboarded &&
            !accountAccessInformation.isTrialPeriodExpired &&
            !accountAccessInformation.hasActiveSubscription &&
            accountAccessInformation.trialPeriodDaysRemaining !== null);
    }, [accountAccessInformation]);
    var shouldRenderSubscriptionExceededBanner = useMemo(function () {
        if (!accountAccessInformation)
            return false;
        return (accountAccessInformation.hasActiveSubscription &&
            accountAccessInformation.hasExceededActiveSubscription);
    }, [accountAccessInformation]);
    var shouldRenderUserSeatsExceededBanner = useMemo(function () {
        if (!accountAccessInformation)
            return false;
        return (accountAccessInformation.hasActiveSubscription &&
            accountAccessInformation.hasExceededActiveSubscriptionUserSeats);
    }, [accountAccessInformation]);
    useEffect(function () {
        dispatch(accountActions.requestGetAccountAccessInformation());
        dispatch(userActions.getAccountUsers());
    }, []);
    return (React.createElement(React.Fragment, null,
        shouldRenderTrialPeriodCountdownBanner && React.createElement(TrialPeriodCountdownBanner, null),
        shouldRenderSubscriptionExceededBanner && React.createElement(SubscriptionExceededBanner, null),
        shouldRenderUserSeatsExceededBanner && React.createElement(UserSeatsExceededBanner, null)));
};
export default AccountAccessBanner;
