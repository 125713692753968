import React, { useMemo } from 'react';
import { Badge } from '@payaca/components';
import './SubscriptionControlHeader.sass';
var SubscriptionControlHeader = function (_a) {
    var selectedProduct = _a.selectedProduct, recurringInterval = _a.recurringInterval;
    var isMostPopularPlan = useMemo(function () {
        return selectedProduct.name === 'Professional';
    }, [selectedProduct]);
    return (React.createElement("div", { className: "subscription-control-header" },
        isMostPopularPlan ? (React.createElement(Badge, { colour: "#ffffff", backgroundColour: "#0872ED" }, "Most popular")) : (React.createElement(Badge, { backgroundColour: "#4ea83d", colour: "#ffffff" }, "Selected plan")),
        React.createElement("h3", null, selectedProduct.name),
        React.createElement("p", null,
            "(Billed ",
            recurringInterval === 'month' ? 'monthly' : 'annually',
            ")")));
};
export default SubscriptionControlHeader;
