import React from 'react';
import { PayacaPhoneLink } from '@payaca/components/payacaPhoneLink';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import './ContactUsCallout.sass';
var ContactUsCallout = function (_a) {
    var title = _a.title, children = _a.children;
    return (React.createElement("div", { className: "contact-us-callout" },
        title && React.createElement("h2", null, title),
        children && React.createElement("div", { className: "children-container" }, children),
        React.createElement("div", { className: "contact-us-prompt" },
            React.createElement("span", { className: "flex-grow" }, "Need to speak to us?"),
            React.createElement(PayacaPhoneLink, { showPhoneIcon: false, anchorContent: React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE }, "0333 050 9475") }))));
};
export default ContactUsCallout;
