var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionType } from './fileDownloadTypes';
export var initialState = {
    fileDownloads: {},
    isDownloadingFile: false,
};
var fileDownloadReducer = function (state, action) {
    var _a, _b, _c, _d, _e, _f;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionType.REQUEST_FILE_DOWNLOAD: {
            var fetchedEntity = state.fileDownloads && state.fileDownloads[action.payload.entityId];
            var fetchEntityType = fetchedEntity && fetchedEntity[action.payload.type];
            return __assign(__assign({}, state), { isDownloadingFile: true, fileDownloads: __assign(__assign({}, state.fileDownloads), (_a = {}, _a[action.payload.entityId] = __assign(__assign({}, fetchedEntity), (_b = {}, _b[action.payload.type] = __assign(__assign({}, fetchEntityType), { fetchAttemptedAttemptedAt: new Date(), fetchSucceededAt: undefined, fetchFailedAt: undefined, isFetching: true, fetchError: undefined }), _b)), _a)) });
        }
        case ActionType.FILE_DOWNLOAD_SUCCESS: {
            return __assign(__assign({}, state), { isDownloadingFile: false, fileDownloads: __assign(__assign({}, state.fileDownloads), (_c = {}, _c[action.payload.entityId] = __assign(__assign({}, state.fileDownloads[action.payload.entityId]), (_d = {}, _d[action.payload.type] = {
                    entity: action.payload.value,
                    fetchSucceededAt: new Date(),
                    isFetching: false,
                }, _d)), _c)) });
        }
        case ActionType.FILE_DOWNLOAD_FAILURE: {
            var fetchedEntity = state.fileDownloads && state.fileDownloads[action.payload.entityId];
            var fetchEntityType = fetchedEntity && fetchedEntity[action.payload.type];
            return __assign(__assign({}, state), { isDownloadingFile: false, fileDownloads: __assign(__assign({}, state.fileDownloads), (_e = {}, _e[action.payload.entityId] = __assign(__assign({}, fetchedEntity), (_f = {}, _f[action.payload.type] = __assign(__assign({}, fetchEntityType), { fetchFailedAt: new Date(), isFetching: false, fetchError: action.error }), _f)), _e)) });
        }
        case ActionType.CLEAR_FILE_DOWNLOADS:
            return __assign({}, initialState);
        default:
            return state;
    }
};
export default fileDownloadReducer;
