// ======================================================================================================
// Setup account form
// - Set up business details
// ======================================================================================================
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions as usersActions } from '@/api/users';
import { actions as accountActions } from '@payaca/store/account';
import { FileUploadPersistRemoveControl } from '@payaca/components';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import './SetupAccountBrandingForm.sass';
var SetupAccountBrandingForm = function (_a) {
    var accountId = _a.accountId, onSetupAccountCallback = _a.onSetupAccountCallback;
    var dispatch = useDispatch();
    var _b = useState(null), updateBusinessErrorMessage = _b[0], setUpdateBusinessErrorMessage = _b[1];
    var _c = useState(), uploadedAccountLogo = _c[0], setUploadedAccountLogo = _c[1];
    var _d = useState(false), isUploadingAccountLogo = _d[0], setIsUploadingAccountLogo = _d[1];
    var onSetupBusinessError = useCallback(function () {
        setUpdateBusinessErrorMessage('Sorry, there was an error updating your account, please try again.');
    }, []);
    var onSubmitCallback = useCallback(function (error) {
        if (!error) {
            dispatch(accountActions.requestGetAccountAccessInformation());
            dispatch(usersActions.getProfile(function () {
                onSetupAccountCallback && onSetupAccountCallback();
            }));
        }
        else {
            onSetupBusinessError();
        }
    }, [dispatch, onSetupAccountCallback, onSetupBusinessError]);
    var uploadLogo = useCallback(function (logoFile) {
        setIsUploadingAccountLogo(true);
        return new Promise(function (resolve, reject) {
            // upload account logo
            dispatch(usersActions.uploadAccountLogo(accountId, {
                file: logoFile,
                fileName: logoFile.name,
            }, function (uploadLogoError, accountLogo) {
                setIsUploadingAccountLogo(false);
                if (uploadLogoError) {
                    onSetupBusinessError();
                }
                else {
                    setUploadedAccountLogo(__assign(__assign({}, accountLogo), { fileName: logoFile.name }));
                    resolve();
                }
            }));
        });
    }, [accountId, dispatch]);
    var removeLogo = useCallback(function () {
        return new Promise(function (resolve, reject) {
            dispatch(usersActions.uploadAccountLogo(accountId, null, function (uploadLogoError) {
                setIsUploadingAccountLogo(false);
                if (uploadLogoError) {
                    onSetupBusinessError();
                }
                else {
                    setUploadedAccountLogo(null);
                    resolve();
                }
            }));
        });
    }, [accountId, dispatch]);
    var uploadStatus = useMemo(function () {
        if (uploadedAccountLogo) {
            return 'Upload complete!';
        }
        else if (isUploadingAccountLogo) {
            return 'Uploading file...';
        }
    }, [isUploadingAccountLogo, uploadedAccountLogo]);
    return (React.createElement("div", { className: "setup-account-branding-form" },
        uploadStatus && React.createElement("div", { className: "upload-status" }, uploadStatus),
        React.createElement(FileUploadPersistRemoveControl, { acceptFileTypes: ['.png, .jpeg'], showAsDragAndDrop: true, showUploadImageButton: true, persistFile: uploadLogo, removePersistedFile: removeLogo, persistedFiles: uploadedAccountLogo
                ? [
                    {
                        fileName: uploadedAccountLogo.fileName,
                        identifier: uploadedAccountLogo.logoUrl,
                    },
                ]
                : [] }),
        updateBusinessErrorMessage && (React.createElement("div", { className: "error" }, updateBusinessErrorMessage)),
        React.createElement("div", { className: "button-container" },
            React.createElement(Button, { onClick: function () { return !isUploadingAccountLogo && onSubmitCallback(); }, isProcessing: isUploadingAccountLogo, styleVariant: ButtonStyleVariant.OUTSIZE }, "Continue"),
            React.createElement(Button, { onClick: function () { return !isUploadingAccountLogo && onSubmitCallback(); }, styleVariant: ButtonStyleVariant.ANCHOR }, "Skip, I'll do this later"))));
};
export default SetupAccountBrandingForm;
