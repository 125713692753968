var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect, useState, useCallback, useMemo, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { faChevronRight, faPhone } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { actions as subsidisedFinanceInformationActions } from '@payaca/store/subsidisedFinanceInformation';
import { CompanyType, } from '@payaca/types/subsidisedFinanceInformationTypes';
import { getFieldValidatorsForBusinessInformation } from '@payaca/helpers/subsidisedFinanceInformationValidationHelper';
import { Checkbox } from '@payaca/components/checkbox';
import { BasicField } from '@payaca/components/basicField';
import { DateField } from '@payaca/components/dateField';
import { PhoneNumberField } from '@payaca/components/phoneNumberField';
import { AddressInputControl } from '@payaca/components/addressInputControl';
import { TitledContentPanel } from '@payaca/components/titledContentPanel';
import { ValidatedForm } from '@payaca/components';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import DirectorsFormSubSection from '../directorsFormSubsection/DirectorsFormSubsection';
import CompanyContactsFormSubsection from '../companyContactsFormSubsection/CompanyContactsFormSubsection';
import AssociatedBusinessesFormSubsection from '../associatedBusinessesFormSubsection/AssociatedBusinessesFormSubsection';
import DirectorFormSubSection from '../directorFormSubsection/DirectorFormSubsection';
import { LabelValuePair, MiniLoader, ResponsiveViewWrapper, } from '@payaca/components';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import { usePrevious } from '@/utils/customHooks';
import './BusinessInformationFormSection.sass';
var DEFAULT_COMPANY_CONTACTS = [
    { department: 'Main contact' },
];
var BusinessInformationFormSection = function () {
    var dispatch = useDispatch();
    var persistedBusinessInformation = useSelector(function (state) {
        var _a;
        return (_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.businessInformation;
    });
    var businessInformation = useMemo(function () {
        var _a, _b;
        var businessInformation = persistedBusinessInformation || {};
        if (!((_a = businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.companyContacts) === null || _a === void 0 ? void 0 : _a.length) &&
            businessInformation.companyType !== CompanyType.SOLE_TRADER) {
            businessInformation.companyContacts = DEFAULT_COMPANY_CONTACTS;
        }
        if (!((_b = businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.directors) === null || _b === void 0 ? void 0 : _b.length)) {
            businessInformation.directors = [{}];
        }
        return businessInformation;
    }, [persistedBusinessInformation]);
    var isGettingOrPersisting = useSelector(function (state) {
        var _a, _b;
        return (((_a = state.subsidisedFinanceInformation) === null || _a === void 0 ? void 0 : _a.isGettingBusinessInformation) || ((_b = state.subsidisedFinanceInformation) === null || _b === void 0 ? void 0 : _b.isPersistingBusinessInformation));
    });
    var previousBusinessInformation = usePrevious(businessInformation);
    var _a = useState(0), companyContactsCount = _a[0], setCompanyContactsCount = _a[1];
    var _b = useState(0), directorsCount = _b[0], setDirectorsCount = _b[1];
    var _c = useState(), triggerValidationAt = _c[0], setTriggerValidationAt = _c[1];
    useEffect(function () {
        var _a, _b;
        if (businessInformation &&
            !_.isEqual(businessInformation, previousBusinessInformation)) {
            setCompanyContactsCount(((_a = businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.companyContacts) === null || _a === void 0 ? void 0 : _a.length) || 0);
            setDirectorsCount(((_b = businessInformation === null || businessInformation === void 0 ? void 0 : businessInformation.directors) === null || _b === void 0 ? void 0 : _b.length) || 0);
        }
    }, [businessInformation]);
    useEffect(function () {
        dispatch(subsidisedFinanceInformationActions.requestGetBusinessInformation());
    }, []);
    var fieldValidators = useMemo(function () {
        return getFieldValidatorsForBusinessInformation(companyContactsCount, directorsCount);
    }, [companyContactsCount, directorsCount]);
    var onDirectorAdd = useCallback(function (directors, onFieldChange) {
        onFieldChange({
            directors: __spreadArrays((directors !== null && directors !== void 0 ? directors : []), [{}]),
        });
        setDirectorsCount(function (directorsCount) { return directorsCount + 1; });
    }, []);
    var onDirectorRemove = useCallback(function (directorIndex, directors, onFieldChange) {
        directors.splice(directorIndex, 1);
        onFieldChange({ directors: directors });
        setDirectorsCount(function (directorsCount) { return directorsCount - 1; });
    }, []);
    var onCompanyContactAdd = useCallback(function (companyContacts, onFieldChange) {
        onFieldChange({
            companyContacts: __spreadArrays((companyContacts !== null && companyContacts !== void 0 ? companyContacts : []), [{}]),
        });
        setCompanyContactsCount(function (companyContactsCount) { return companyContactsCount + 1; });
    }, []);
    var onCompanyContactRemove = useCallback(function (contactIndex, companyContacts, onFieldChange) {
        companyContacts.splice(contactIndex, 1);
        onFieldChange({ companyContacts: companyContacts });
        setCompanyContactsCount(function (companyContactsCount) { return companyContactsCount - 1; });
    }, []);
    var onAssociatedBusinessAdd = useCallback(function (associatedBusinesses, onFieldChange) {
        onFieldChange({
            associatedBusinesses: __spreadArrays((associatedBusinesses !== null && associatedBusinesses !== void 0 ? associatedBusinesses : []), [
                {},
            ]),
        });
    }, []);
    var onAssociatedBusinessRemove = useCallback(function (businessIndex, associatedBusinesses, onFieldChange) {
        associatedBusinesses.splice(businessIndex, 1);
        onFieldChange({ associatedBusinesses: associatedBusinesses });
    }, []);
    var onSaveProgress = useCallback(function (modifiedBusinessInformation) {
        dispatch(subsidisedFinanceInformationActions.requestPersistBusinessInformation(modifiedBusinessInformation));
    }, [dispatch]);
    var onSaveAndContinue = useCallback(function (modifiedBusinessInformation) {
        dispatch(subsidisedFinanceInformationActions.requestPersistCompleteBusinessInformation(modifiedBusinessInformation));
    }, [dispatch]);
    var renderActionButtons = useCallback(function (isValid, formState) {
        var modifiedBusinessInformation = formState;
        return (React.createElement("div", { className: "action-buttons-container" }, isGettingOrPersisting ? (React.createElement("div", { className: "loader-container flex-grow" },
            React.createElement(MiniLoader, null))) : (React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: function () { return onSaveProgress(modifiedBusinessInformation); }, styleVariant: ButtonStyleVariant.OUTSIZE, isOutlined: true }, 'Save progress'),
            React.createElement("div", { onClick: function () { return !isValid && setTriggerValidationAt(new Date()); } },
                React.createElement(Button, { isDisabled: !isValid, onClick: function () {
                        return isValid && onSaveAndContinue(modifiedBusinessInformation);
                    }, styleVariant: ButtonStyleVariant.OUTSIZE, iconAfter: faChevronRight }, 'Save and continue'))))));
    }, [onSaveProgress, onSaveAndContinue, isGettingOrPersisting]);
    var renderAddressesFormSections = useCallback(function (formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        var isSoleTrader = formState.companyType === CompanyType.SOLE_TRADER;
        if (isSoleTrader)
            return;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "field-group" },
                React.createElement(TitledContentPanel, { title: "Address of registered office", hasBoxShadow: true },
                    React.createElement(AddressInputControl, { address: formState.registeredAddress, isRequired: true, onChange: onFieldChange, onTouch: onFieldTouch, fieldNamePrefix: "registeredAddress", validationState: validationState, touchedState: touchedState, styleVariant: InputStyleVariant.OUTSIZE }))),
            React.createElement("div", { className: "field-group" },
                React.createElement(TitledContentPanel, { title: "Principal place of business", hasBoxShadow: true },
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "checkbox-container flex-container flex-center" },
                            React.createElement("div", null,
                                React.createElement(Checkbox, { isChecked: formState.isPrincipalPlaceOfBusinessSameAsRegisteredAddress ||
                                        false, onChange: function () {
                                        onFieldChange({
                                            isPrincipalPlaceOfBusinessSameAsRegisteredAddress: !formState.isPrincipalPlaceOfBusinessSameAsRegisteredAddress,
                                        });
                                        onFieldTouch('isPrincipalPlaceOfBusinessSameAsRegisteredAddress');
                                    } })),
                            React.createElement("span", null, "My principal place of business is the same as my registered office address")),
                        !formState.isPrincipalPlaceOfBusinessSameAsRegisteredAddress && (React.createElement(AddressInputControl, { address: formState.principalPlaceOfBusiness, onChange: onFieldChange, onTouch: onFieldTouch, isRequired: true, fieldNamePrefix: "principalPlaceOfBusiness", validationState: validationState, touchedState: touchedState, styleVariant: InputStyleVariant.OUTSIZE })))))));
    }, []);
    var renderDirectorsFormSection = useCallback(function (formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        var _a, _b, _c;
        var isSoleTrader = formState.companyType === CompanyType.SOLE_TRADER;
        if (isSoleTrader) {
            return (React.createElement("div", { className: "field-group" },
                React.createElement(DirectorFormSubSection, { title: "Your contact information", isSoleTrader: true, director: formState.directors[0], onChange: onFieldChange, fieldNamePrefix: "directors[0]", validationState: validationState, touchedState: touchedState })));
        }
        return (React.createElement("div", { className: "field-group" },
            React.createElement(TitledContentPanel, { title: "All directors", hasBoxShadow: true },
                React.createElement(React.Fragment, null,
                    React.createElement(DirectorsFormSubSection, { directors: formState.directors, fieldNamePrefix: "directors", validationState: validationState, touchedState: touchedState, onTouch: onFieldTouch, onChange: onFieldChange, onDirectorAdd: function () {
                            return onDirectorAdd(formState.directors, onFieldChange);
                        }, onDirectorRemove: function (directorIndex) {
                            return onDirectorRemove(directorIndex, formState.directors, onFieldChange);
                        } }),
                    !((_a = validationState === null || validationState === void 0 ? void 0 : validationState.directors) === null || _a === void 0 ? void 0 : _a.isValid) && ((_c = (_b = validationState === null || validationState === void 0 ? void 0 : validationState.directors) === null || _b === void 0 ? void 0 : _b.errors) === null || _c === void 0 ? void 0 : _c.map(function (error, index) {
                        return (React.createElement("p", { className: "validation-error", key: index }, error));
                    }))))));
    }, [onDirectorAdd, onDirectorRemove]);
    var renderCompanyContactsFormSection = useCallback(function (formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        var _a, _b, _c;
        var isSoleTrader = formState.companyType === CompanyType.SOLE_TRADER;
        if (isSoleTrader)
            return;
        return (React.createElement("div", { className: "field-group" },
            React.createElement(TitledContentPanel, { title: "Company contact details", hasBoxShadow: true },
                React.createElement(React.Fragment, null,
                    React.createElement(CompanyContactsFormSubsection, { companyContacts: formState.companyContacts, onCompanyContactAdd: function () {
                            return onCompanyContactAdd(formState.companyContacts, onFieldChange);
                        }, fieldNamePrefix: "companyContacts", validationState: validationState, touchedState: touchedState, onTouch: onFieldTouch, onChange: onFieldChange, onCompanyContactRemove: function (contactIndex) {
                            return onCompanyContactRemove(contactIndex, formState.companyContacts, onFieldChange);
                        } }),
                    !((_a = validationState === null || validationState === void 0 ? void 0 : validationState.companyContacts) === null || _a === void 0 ? void 0 : _a.isValid) && ((_c = (_b = validationState === null || validationState === void 0 ? void 0 : validationState.companyContacts) === null || _b === void 0 ? void 0 : _b.errors) === null || _c === void 0 ? void 0 : _c.map(function (error, index) {
                        return (React.createElement("p", { className: "validation-error", key: index }, error));
                    }))))));
    }, [onCompanyContactAdd, onCompanyContactRemove]);
    var renderAssociatedBusinessesFormSection = useCallback(function (formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        return (React.createElement("div", { className: "field-group" },
            React.createElement(TitledContentPanel, { title: "Associated businesses (current or previous)", hasBoxShadow: true },
                React.createElement(AssociatedBusinessesFormSubsection, { associatedBusinesses: formState.associatedBusinesses, onAssociatedBusinessAdd: function () {
                        return onAssociatedBusinessAdd(formState.associatedBusinesses, onFieldChange);
                    }, onTouch: onFieldTouch, onChange: onFieldChange, onAssociatedBusinessRemove: function (businessIndex) {
                        return onAssociatedBusinessRemove(businessIndex, formState.associatedBusinesses, onFieldChange);
                    }, fieldNamePrefix: "associatedBusinesses", validationState: validationState, touchedState: touchedState }))));
    }, [onAssociatedBusinessAdd, onAssociatedBusinessRemove]);
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "field-group" },
                React.createElement(LabelValuePair, { label: "Company name", value: businessInformation.companyName }),
                React.createElement(LabelValuePair, { label: "Company type", value: businessInformation.companyType }),
                businessInformation.companyRegistrationNumber && (React.createElement(LabelValuePair, { label: "Company registration number", value: businessInformation.companyRegistrationNumber }))),
            React.createElement("div", { className: "field-group" },
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.tradingNames, isTouched: touchedState.tradingNames || false },
                    React.createElement(BasicField, { label: "Trading names", description: "(if there are multiple trading names please use commas to separate them)", value: formState.tradingNames || '', name: "tradingNames", onChange: onFieldChange, onTouch: onFieldTouch, styleVariant: InputStyleVariant.OUTSIZE })),
                formState.companyType !== CompanyType.SOLE_TRADER && (React.createElement(React.Fragment, null,
                    React.createElement(ValidatedFieldWrapper, { validationResult: validationState.businessTradingCommencementDate, isTouched: touchedState.businessTradingCommencementDate || false },
                        React.createElement(DateField, { label: "Business trading commencement date", value: (_a = formState.businessTradingCommencementDate) !== null && _a !== void 0 ? _a : undefined, isRequired: formState.companyType !== CompanyType.SOLE_TRADER, name: "businessTradingCommencementDate", onChange: onFieldChange, onTouch: onFieldTouch, additionalPickerProps: {
                                openTo: 'year',
                                maxDate: new Date(),
                            }, styleVariant: InputStyleVariant.OUTSIZE })),
                    React.createElement(ValidatedFieldWrapper, { validationResult: validationState.mainTelephoneNumber, isTouched: touchedState.mainTelephoneNumber || false },
                        React.createElement(PhoneNumberField, { iconBefore: faPhone, label: "Main telephone number", value: formState.mainTelephoneNumber || '', isRequired: true, name: "mainTelephoneNumber", onChange: onFieldChange, onTouch: onFieldTouch, styleVariant: InputStyleVariant.OUTSIZE })))),
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.websiteAddresses, isTouched: touchedState.websiteAddresses || false },
                    React.createElement(BasicField, { label: "Website addresses", value: formState.websiteAddresses || '', description: "(including all trading website addresses)", name: "websiteAddresses", onChange: onFieldChange, onTouch: onFieldTouch, styleVariant: InputStyleVariant.OUTSIZE }))),
            renderAddressesFormSections(formState, validationState, touchedState, onFieldChange, onFieldTouch),
            renderDirectorsFormSection(formState, validationState, touchedState, onFieldChange, onFieldTouch),
            renderCompanyContactsFormSection(formState, validationState, touchedState, onFieldChange, onFieldTouch),
            renderAssociatedBusinessesFormSection(formState, validationState, touchedState, onFieldChange, onFieldTouch),
            renderActionButtons(isValid, formState)));
    }, [
        onCompanyContactAdd,
        onCompanyContactRemove,
        renderAssociatedBusinessesFormSection,
        onDirectorAdd,
        onDirectorRemove,
        renderActionButtons,
    ]);
    return (React.createElement(ResponsiveViewWrapper, { className: "business-information-form-section", downBreakpointSm: 600 },
        React.createElement("div", { style: { position: 'relative' } },
            React.createElement(ValidatedForm, { triggerValidationAt: triggerValidationAt, initialFormState: businessInformation, fieldValidators: fieldValidators, renderFormContents: renderFormContents }))));
};
export default BusinessInformationFormSection;
