import { JobStatus, ReadableJobStatus, } from '@payaca/types/jobTypes';
import { isQuoteOrEstimate, isInvoice, isQuote, isEstimate, isSentQuoteEstimate, isInvoiceOverdue, jobHasSent, } from './jobStatusHelper';
import { jobTotalsFromJob, maximumJobTotal, minimumJobTotal, } from './financeHelper';
import { getMaximumDepositAmountFromMaxDepositPercentage, getMaximumMinimumDepositPercentage, getMaximumMinimumDepositAmount, isJobUnderMinimumLimitJobTotal, isJobOverMaximumLimitJobTotal, isJobMinimumDepositPercentageOverMaximumLimitDeposit, isJobWithMinimumDepositPercentageUnderMinimumFinanceLimit, isJobWithMinimumDepositAmountOverMaximumLimitDeposit, isJobWithMinimumDepositAmountUnderMinimumLimitJobTotals, } from './jobFinanceHelper';
import { getAttemptedDepositPaymentValueFromJobPayments, getCompletedDepositPaymentValueFromJobPayments, getTotalAttemptedPaymentValueFromJobPayments, getTotalCompletedPaymentValueFromJobPayments, getBacsPendingDepositPaymentsFromJobPayments, getBacsPendingPaymentsExcludingDepositFromJobPayments, } from './jobPaymentHelper';
import { hasRejectedFinanceApplication, hasSubmittedFinanceApplication, } from './jobFinanceApplicationHelper';
import { getJobContactFromCustomer } from './customerHelper';
export var isDepositPaymentRequired = function (job) {
    return (isQuoteOrEstimate(job.status) &&
        (!!job.depositAmount || !!job.depositPercentage));
};
export var isInvoicePaymentRequired = function (job) {
    return isInvoice(job.status) && job.jobTotal > 0;
};
export var shouldShowFinanceOptionsToCustomer = function (job, jobPayments, jobFinanceInformation) {
    // hide finance if job declined
    if (!!job.declinedAt) {
        return false;
    }
    var attemptedPaymentValue = getTotalAttemptedPaymentValueFromJobPayments(jobPayments);
    var remainingTotal = job.jobTotal - attemptedPaymentValue;
    var isJobAttemptedFullyPaid = remainingTotal <= 0;
    // hide finance if job is attempted fully paid
    if (isJobAttemptedFullyPaid) {
        return false;
    }
    // hide finance button if zero percent and application not submitted or submitted and not been rejected
    if (job.showZeroPercentFinance &&
        (!hasSubmittedFinanceApplication(jobFinanceInformation) ||
            (hasSubmittedFinanceApplication(jobFinanceInformation) &&
                !hasRejectedFinanceApplication(jobFinanceInformation)))) {
        return false;
    }
    return job.showFinanceOptions || job.showSelinaFinance;
};
export var isDepositAttemptedPaid = function (job, jobPayments) {
    var attemptedDepositPaymentValue = getAttemptedDepositPaymentValueFromJobPayments(jobPayments);
    var remainingDeposit = (job.depositAmount || 0) - attemptedDepositPaymentValue;
    return remainingDeposit <= 0;
};
export var isDepositConfirmedPaid = function (job, jobPayments) {
    var completedDepositPaymentValue = getCompletedDepositPaymentValueFromJobPayments(jobPayments);
    var remainingDeposit = (job.depositAmount || 0) - completedDepositPaymentValue;
    return remainingDeposit <= 0;
};
export var isInvoiceAttemptedPaid = function (job, jobPayments) {
    var attemptedPaymentValue = getTotalAttemptedPaymentValueFromJobPayments(jobPayments);
    var remainingTotal = job.jobTotal - attemptedPaymentValue;
    return remainingTotal <= 0;
};
export var isInvoiceConfirmedPaid = function (job, jobPayments) {
    var attemptedPaymentValue = getTotalCompletedPaymentValueFromJobPayments(jobPayments);
    var remainingTotal = job.jobTotal - attemptedPaymentValue;
    return remainingTotal <= 0;
};
export var getJobType = function (job) {
    if (isInvoice(job.status)) {
        return 'Invoice';
    }
    else if (isQuote(job.status)) {
        return 'Quote';
    }
    else if (isEstimate(job.status)) {
        return 'Estimate';
    }
    else {
        return 'Job';
    }
};
export var isAccepted = function (job) {
    return isQuoteOrEstimate(job.status) && !!job.acceptedAt;
};
export var isDeclined = function (job) {
    return isQuoteOrEstimate(job.status) && !!job.declinedAt;
};
export var canMarkAsAccepted = function (job) {
    if (job.showZeroPercentFinance)
        return false;
    return (isSentQuoteEstimate(job.status) && !isAccepted(job) && !isDeclined(job));
};
export var canMarkAsDeclined = function (job) {
    if (job.showZeroPercentFinance)
        return false;
    return (isSentQuoteEstimate(job.status) && !isDeclined(job) && !isAccepted(job));
};
export var canEditSentJob = function (job, jobPayments, hasRelatedJobInformation, jobFinanceInformation) {
    if ((jobPayments === null || jobPayments === void 0 ? void 0 : jobPayments.length) || jobFinanceInformation) {
        return false;
    }
    if (isSentQuoteEstimate(job.status)) {
        return !hasRelatedJobInformation && !job.acceptedAt;
    }
    else if (job.status === JobStatus.INVOICED) {
        return true;
    }
    return false;
};
export var canRecordDepositPayment = function (job, jobPayments, jobFinanceInformation) {
    var isJobSentQuoteEstimate = isSentQuoteEstimate(job.status);
    if (!isJobSentQuoteEstimate) {
        return false;
    }
    if (job.showZeroPercentFinance) {
        if (isDepositPaymentRequired(job) &&
            !isDepositConfirmedPaid(job, jobPayments) &&
            !!(jobFinanceInformation === null || jobFinanceInformation === void 0 ? void 0 : jobFinanceInformation.approvedAt) &&
            !!(jobFinanceInformation === null || jobFinanceInformation === void 0 ? void 0 : jobFinanceInformation.customerAgreementSignedAt))
            return true;
        return false;
    }
    return !isDeclined(job);
};
export var canRecordInvoicePayment = function (job) {
    var jobIsQuoteOrEstimate = isQuoteOrEstimate(job.status);
    if (jobIsQuoteOrEstimate)
        return false;
    if (job.showZeroPercentFinance)
        return false;
    var isSentJob = jobHasSent(job.status);
    return isSentJob;
};
export var hasBacsPendingPayments = function (job, jobPayments) {
    var jobIsQuoteOrEstimate = isQuoteOrEstimate(job.status);
    if (jobIsQuoteOrEstimate) {
        return !!getBacsPendingDepositPaymentsFromJobPayments(jobPayments).length;
    }
    return !!getBacsPendingPaymentsExcludingDepositFromJobPayments(jobPayments)
        .length;
};
export var canConvertToInvoice = function (job, hasRelatedInvoice) {
    if (job.showZeroPercentFinance)
        return false;
    return isQuoteOrEstimate(job.status) && !hasRelatedInvoice;
};
export var canMarkJobAsComplete = function (job, jobPayments, jobFinanceInformation) {
    if (!job.showZeroPercentFinance)
        return false;
    if (isDepositPaymentRequired(job) &&
        !isDepositConfirmedPaid(job, jobPayments))
        return false;
    return (!!jobFinanceInformation &&
        !!jobFinanceInformation.approvedAt &&
        !!jobFinanceInformation.customerAgreementSignedAt &&
        !jobFinanceInformation.jobCompletedAt);
};
export var canResendJob = function (job, hasRelatedJobInformation) {
    var primaryContact = getJobContactFromCustomer(job.customer, job.contactId);
    return (jobHasSent(job.status) &&
        !job.archivedAt &&
        (isQuote(job.status) ? !hasRelatedJobInformation : true) &&
        !!(primaryContact === null || primaryContact === void 0 ? void 0 : primaryContact.emailAddress));
};
export var isSatisfactionNoteSignatureRequired = function (job, jobFinanceInformation) {
    if (!job.showZeroPercentFinance)
        return false;
    return (!!jobFinanceInformation &&
        !!jobFinanceInformation.jobCompletedAt &&
        !jobFinanceInformation.satisfactionNoteSignedAt);
};
export var canRejectSatisfactionNote = function (job, jobFinanceInformation) {
    if (!job.showZeroPercentFinance)
        return false;
    return (!!jobFinanceInformation &&
        !!jobFinanceInformation.jobCompletedAt &&
        !jobFinanceInformation.satisfactionNoteSignedAt &&
        !jobFinanceInformation.satisfactionNoteRejectedAt);
};
export var getReadableJobStatus = function (job, jobPayments, jobFinanceInformation, isRelatedInvoiceSent) {
    var isArchived = job.archivedAt;
    var isInactivated = job.inactivatedAt;
    var isZeroPercentJob = job.showZeroPercentFinance;
    var isJobSentQuoteEstimate = isSentQuoteEstimate(job.status);
    var isPaymentPending = hasBacsPendingPayments(job, jobPayments);
    // Return statuses relating to invoices and invoiced quotes
    if (isArchived) {
        return ReadableJobStatus.ARCHIVED;
    }
    if (isInactivated) {
        return ReadableJobStatus.INACTIVE;
    }
    if (isRelatedInvoiceSent) {
        return ReadableJobStatus.INVOICED;
    }
    if (job.status === JobStatus.INVOICED) {
        if (isZeroPercentJob) {
            return ReadableJobStatus.PAYOUT_APPROVED;
        }
        if (isInvoiceConfirmedPaid(job, jobPayments)) {
            return ReadableJobStatus.PAID;
        }
        if (isPaymentPending) {
            return ReadableJobStatus.PAYMENT_PENDING;
        }
        if (isInvoiceOverdue(job.invoiceDue, job.status) && !job.bouncedAt) {
            return ReadableJobStatus.OVERDUE;
        }
    }
    if (job.status === JobStatus.PAID) {
        if (isZeroPercentJob) {
            return ReadableJobStatus.PAYOUT_APPROVED;
        }
        return ReadableJobStatus.PAID;
    }
    // return statuses relating to standard quotes
    if ((isJobSentQuoteEstimate || job.status === JobStatus.ACCEPTED) &&
        !isZeroPercentJob) {
        if (!!job.depositAmount && isDepositConfirmedPaid(job, jobPayments)) {
            return ReadableJobStatus.DEPOSIT_PAID;
        }
        if (isPaymentPending) {
            return ReadableJobStatus.PAYMENT_PENDING;
        }
        if (isAccepted(job) &&
            !!job.depositAmount &&
            !isDepositConfirmedPaid(job, jobPayments)) {
        }
        if (isAccepted(job)) {
            if (!!job.depositAmount && !isDepositConfirmedPaid(job, jobPayments)) {
                return ReadableJobStatus.DEPOSIT_DUE;
            }
            else {
                return ReadableJobStatus.ACCEPTED;
            }
        }
        if (isDeclined(job)) {
            return ReadableJobStatus.DECLINED;
        }
    }
    // return statuses relating to zero% quotes
    if ((isJobSentQuoteEstimate || job.status === JobStatus.ACCEPTED) &&
        isZeroPercentJob &&
        jobFinanceInformation) {
        var isJobDepositPaymentRequired = isDepositPaymentRequired(job);
        if (jobFinanceInformation.satisfactionNoteRejectedAt) {
            return ReadableJobStatus.JOB_UNSATISFACTORY;
        }
        if (jobFinanceInformation.jobCompletedAt) {
            return ReadableJobStatus.JOB_COMPLETE;
        }
        if (!!job.depositAmount && isDepositConfirmedPaid(job, jobPayments)) {
            return ReadableJobStatus.DEPOSIT_PAID;
        }
        if (isPaymentPending) {
            return ReadableJobStatus.PAYMENT_PENDING;
        }
        if (jobFinanceInformation.customerAgreementSignedAt) {
            if (isJobDepositPaymentRequired) {
                return ReadableJobStatus.FINANCE_SIGNED;
            }
            return ReadableJobStatus.FINANCE_COMPLETE;
        }
        if (jobFinanceInformation.rejectedAt) {
            return ReadableJobStatus.FINANCE_REJECTED;
        }
        if (jobFinanceInformation.approvedAt) {
            return ReadableJobStatus.FINANCE_APPROVED;
        }
    }
    // return standard statuses
    if (job.bouncedAt) {
        return ReadableJobStatus.BOUNCED;
    }
    if (job.jobViewedAt) {
        return ReadableJobStatus.VIEWED;
    }
    switch (job.status) {
        case JobStatus.QUOTED:
        case JobStatus.ESTIMATED:
        case JobStatus.INVOICED:
            return ReadableJobStatus.SENT;
        default:
            return ReadableJobStatus.DRAFT;
    }
};
export var getReadableJobStatusStyle = function (status) {
    switch (status) {
        case ReadableJobStatus.ARCHIVED:
        case ReadableJobStatus.INACTIVE:
            return {
                colour: '#ffffff',
                backgroundColour: '#919191',
            };
        case ReadableJobStatus.PAID:
        case ReadableJobStatus.DEPOSIT_PAID:
        case ReadableJobStatus.ACCEPTED:
        case ReadableJobStatus.FINANCE_COMPLETE:
        case ReadableJobStatus.PAYOUT_APPROVED:
            return {
                colour: '#263E59',
                backgroundColour: '#75E582',
            };
        case ReadableJobStatus.DEPOSIT_DUE:
        case ReadableJobStatus.PAYMENT_PENDING:
        case ReadableJobStatus.VIEWED:
            return {
                backgroundColour: '#FABB00',
                colour: '#263E59',
            };
        case ReadableJobStatus.OVERDUE:
        case ReadableJobStatus.FINANCE_REJECTED:
        case ReadableJobStatus.JOB_UNSATISFACTORY:
        case ReadableJobStatus.DECLINED:
            return {
                colour: '#ffffff',
                backgroundColour: '#D93A3A',
            };
        case ReadableJobStatus.BOUNCED:
            return {
                backgroundColour: '#7E27BE',
                colour: '#ffffff',
            };
        case ReadableJobStatus.INVOICED:
        case ReadableJobStatus.SENT:
        case ReadableJobStatus.FINANCE_APPROVED:
        case ReadableJobStatus.FINANCE_SIGNED:
        case ReadableJobStatus.JOB_COMPLETE:
            return {
                backgroundColour: '#DBE5F0',
                colour: '#263E59',
            };
        case ReadableJobStatus.DRAFT:
        default:
            return {
                backgroundColour: '#607387',
                colour: '#ffffff',
            };
    }
};
/**
 * Check that a job is valid against finance plans
 * @param job
 * @param jobLineItemGroups
 * @param financePlans
 * @returns
 */
export var validateJobToCreateUpdate = function (job, jobLineItemGroups, financePlans) {
    if (job.showZeroPercentFinance && financePlans.length) {
        var errors = financePlans.reduce(function (errorAcc, financePlan) {
            var error = validateJobAgainstFinancePlan(job, jobLineItemGroups, financePlan);
            return error ? errorAcc.concat([error]) : errorAcc;
        }, []);
        if (errors.length === financePlans.length) {
            // no plans are valid - return the first error
            return errors[0];
        }
    }
    return {
        isValid: true,
        errorName: '',
    };
};
/**
 * Check that a jobn is valid against a finance plan
 * @param job
 * @param jobLineItemGroups
 * @param financePlan
 * @returns
 */
export var validateJobAgainstFinancePlan = function (job, jobLineItemGroups, financePlan, useJobTotalAsMinimumAndMaximum) {
    if (useJobTotalAsMinimumAndMaximum === void 0) { useJobTotalAsMinimumAndMaximum = false; }
    var jobTotals = jobTotalsFromJob(job, jobLineItemGroups);
    var financePlanMinDepositPercentage = financePlan.minDeposit;
    var financePlanMaxDepositPercentage = financePlan.maxDeposit;
    var financePlanMinAmount = financePlan.minAmount;
    var financePlanMaxAmount = financePlan.maxAmount;
    var minJobTotalValue = minimumJobTotal(jobTotals.jobLineItemGroups);
    var maxJobTotalValue = maximumJobTotal(jobTotals.jobLineItemGroups);
    if (useJobTotalAsMinimumAndMaximum) {
        // use the current job total as the min/max values
        minJobTotalValue = jobTotals.jobTotal;
        maxJobTotalValue = jobTotals.jobTotal;
    }
    // check min job total is above finance minimum with finance plan min deposit consideration
    if (isJobUnderMinimumLimitJobTotal(minJobTotalValue, financePlanMinAmount, financePlanMinDepositPercentage)) {
        console.log('Job total with the finance plan deposit limits causes the minimum job total to be under the finance limits defined by finance plan' // i.e. min job total < min finance with 0% deposit
        );
        var minFinance = financePlanMinAmount / 100;
        return {
            isValid: false,
            errorName: 'validateJobTotalBelowFinanceMinimum',
            errorOptions: { minFinance: minFinance },
        };
    }
    // check max job total is below finance maximum with finance plan max deposit consideration
    if (isJobOverMaximumLimitJobTotal(maxJobTotalValue, financePlanMaxAmount, financePlanMaxDepositPercentage)) {
        console.log('Job total with the finance plan deposit limits causes the maximum job total to be over the finance limits defined by finance plan' // i.e. max job total > max finance with 25% deposit
        );
        var maxFinance = financePlanMaxAmount / 100;
        return {
            isValid: false,
            errorName: 'validateJobTotalAboveFinanceMaximum',
            errorOptions: { maxFinance: maxFinance },
        };
    }
    // check user defined min deposit - min deposit still allows for job total to be within finance limits and deposits are within finance plan deposit limits
    if (job.minimumDepositPercentage) {
        var jobMinimumDepositPercentage = job.minimumDepositPercentage;
        // check min deposit percentage is below max deposit set by finance plan (doens't matter if user defined deposit is under limit, finance plan minimum deposit will be used)
        if (isJobMinimumDepositPercentageOverMaximumLimitDeposit(jobMinimumDepositPercentage, financePlanMaxDepositPercentage)) {
            console.log('Minimum deposit percentage is not valid - deposit is outside limit defined by finance plan'); // i.e. User defined deposit > finance plan max deposit
            var maxDeposit = Math.floor(financePlanMaxDepositPercentage);
            return {
                isValid: false,
                errorName: 'validateMinimumDepositPercentageAboveDepositMaximum',
                errorOptions: { maxDeposit: maxDeposit },
            };
        }
        // check the user defined min deposit allows for job total above minimum limit
        if (isJobWithMinimumDepositPercentageUnderMinimumFinanceLimit(minJobTotalValue, financePlanMinAmount, jobMinimumDepositPercentage)) {
            console.log('Minimum deposit percentage is not valid - a lower minimum deposit will be required to meet the finance minimum'); // i.e. Job with user defined deposit < min job required to meet finance minimum
            // get the maximum minimum deposit for this job total
            var maxMinDeposit = getMaximumMinimumDepositPercentage(minJobTotalValue, financePlanMinAmount);
            return {
                isValid: false,
                errorName: 'validateMinimumDepositPercentageBelowFinanceMinimum',
                errorOptions: { maxMinDeposit: maxMinDeposit },
            };
        }
        // deposit set by user will be lower than finance defined max deposit so no need to check maximum job limit
    }
    else if (job.minimumDepositAmount) {
        // use max job value to get smallest possible min deposit percentage
        var jobMinDepositPercentageFromMaxJobTotal = (job.minimumDepositAmount / maxJobTotalValue) * 100;
        // use min job value to get largest possible min deposit percentage
        var jobMinDepositPercentageFromMinJobTotal = (job.minimumDepositAmount / minJobTotalValue) * 100;
        // check min deposit percentages are below max deposit set by finance plan (doens't matter if user defined deposit is under limit, finance plan minimum will be used)
        if (isJobWithMinimumDepositAmountOverMaximumLimitDeposit(jobMinDepositPercentageFromMaxJobTotal, jobMinDepositPercentageFromMinJobTotal, financePlanMaxDepositPercentage)) {
            console.log('Minimum deposit amount is not valid - deposit amount converts to deposit percentages that is outside the deposit percentage limits defined by finance plan'); // i.e. user defined min deposit amount in percentages > finance plan max deposit
            var maxMinDepositAmountFromMaxJob = Math.floor(getMaximumDepositAmountFromMaxDepositPercentage(maxJobTotalValue, financePlanMaxDepositPercentage)) / 100;
            var maxMinDepositAmountFromMinJob = Math.floor(getMaximumDepositAmountFromMaxDepositPercentage(minJobTotalValue, financePlanMaxDepositPercentage)) / 100;
            var maxMinDepositAmount = Math.floor(maxMinDepositAmountFromMinJob < maxMinDepositAmountFromMaxJob
                ? maxMinDepositAmountFromMinJob
                : maxMinDepositAmountFromMaxJob);
            return {
                isValid: false,
                errorName: 'validateDepositAmountAboveDepositMaximum',
                errorOptions: { maxMinDepositAmount: maxMinDepositAmount },
            };
        }
        // check the user defined min deposits allows for job total above minimum limit
        if (isJobWithMinimumDepositAmountUnderMinimumLimitJobTotals(minJobTotalValue, maxJobTotalValue, jobMinDepositPercentageFromMaxJobTotal, jobMinDepositPercentageFromMinJobTotal, financePlanMinAmount)) {
            console.log('Minimum deposit percentage is not valid - deposit amount converts to deposit percentages that causes the finance to be below the finance limits defined by finance plan'); // i.e. Min or max job with user defined deposit amount < min job required to meet finance minimum
            var maxMinDepositAmount = Math.floor(getMaximumMinimumDepositAmount(minJobTotalValue, financePlanMinAmount)) / 100;
            return {
                isValid: false,
                errorName: 'validateDepositAmountBelowFinanceMinimum',
                errorOptions: { maxMinDepositAmount: maxMinDepositAmount },
            };
        }
    }
    return null;
};
export var jobHasOutstandingDepositPayments = function (job, jobPayments) {
    if (isInvoice(job.status)) {
        return false;
    }
    else {
        var value = (job.depositAmount || 0) -
            getCompletedDepositPaymentValueFromJobPayments(jobPayments);
        return value > 0;
    }
};
