import React, { useMemo } from 'react';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getProductPriceCostExcludingVat } from '@payaca/helpers/subscriptionHelper';
import { LabelValuePair, Tooltip } from '@payaca/components';
import QuantityControl from '../quantityControl/QuantityControl';
import './SelectedProductOverview.sass';
var SelectedProductOverview = function (_a) {
    var recurringInterval = _a.recurringInterval, selectedProduct = _a.selectedProduct, selectedPrice = _a.selectedPrice, additionalUserSeats = _a.additionalUserSeats, taxRatePercentage = _a.taxRatePercentage, _b = _a.enableEmbeddedUserSeatControl, enableEmbeddedUserSeatControl = _b === void 0 ? false : _b, onChangeAdditionalUserSeats = _a.onChangeAdditionalUserSeats;
    var costExcludingVat = useMemo(function () {
        if (!selectedPrice)
            return null;
        return (getProductPriceCostExcludingVat(selectedPrice, additionalUserSeats) ||
            null);
    }, [additionalUserSeats, additionalUserSeats]);
    var vat = useMemo(function () {
        if (!costExcludingVat)
            return 0;
        return costExcludingVat * (taxRatePercentage / 100);
    }, [costExcludingVat, taxRatePercentage]);
    var costIncludingVat = useMemo(function () {
        if (!costExcludingVat)
            return null;
        return costExcludingVat + vat;
    }, [costExcludingVat, vat]);
    var userSeatsElement = useMemo(function () {
        var numberOfUserSeats = (selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.numberOfUserSeats) || 0;
        var additionalUserSeatCost = (selectedPrice === null || selectedPrice === void 0 ? void 0 : selectedPrice.additionalUserSeatCost) || 0;
        var totalNumberOfUserSeats = numberOfUserSeats + additionalUserSeats;
        var tooltipCopy = numberOfUserSeats + " user seats are provided as part of your plan. Additional seats cost " + currencyPrice(additionalUserSeatCost) + " + VAT per seat per " + recurringInterval;
        if (!selectedPrice.canBuyAdditionalUserSeats) {
            return (React.createElement(LabelValuePair, { label: "User seats", value: "" + numberOfUserSeats }));
        }
        if (selectedPrice.canBuyAdditionalUserSeats &&
            !enableEmbeddedUserSeatControl) {
            return (React.createElement("div", { className: "label-value-pair" },
                React.createElement("span", { className: "label" }, "User seats:"),
                React.createElement("span", { style: { marginRight: '1rem' } }, "" + totalNumberOfUserSeats + (additionalUserSeats
                    ? " (" + numberOfUserSeats + (additionalUserSeats
                        ? " + " + additionalUserSeats + " additional"
                        : '') + ")"
                    : '')),
                React.createElement("span", { className: "value" }, currencyPrice(additionalUserSeats * additionalUserSeatCost))));
        }
        if (selectedPrice.canBuyAdditionalUserSeats &&
            enableEmbeddedUserSeatControl) {
            return (React.createElement("div", { className: "label-value-pair" },
                React.createElement("span", { className: "label" },
                    "User seats: ",
                    React.createElement(Tooltip, { text: tooltipCopy })),
                React.createElement("div", { className: "quantity-control-wrapper" },
                    React.createElement("span", null, "Qty"),
                    React.createElement(QuantityControl, { value: totalNumberOfUserSeats, onChange: function (userSeats) {
                            return onChangeAdditionalUserSeats &&
                                onChangeAdditionalUserSeats(userSeats - numberOfUserSeats);
                        }, minimumValue: numberOfUserSeats })),
                React.createElement("span", { className: "value" }, currencyPrice(additionalUserSeats * additionalUserSeatCost))));
        }
    }, [
        selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.numberOfUserSeats,
        selectedPrice === null || selectedPrice === void 0 ? void 0 : selectedPrice.additionalUserSeatCost,
        selectedPrice === null || selectedPrice === void 0 ? void 0 : selectedPrice.canBuyAdditionalUserSeats,
        enableEmbeddedUserSeatControl,
        additionalUserSeats,
    ]);
    if (!selectedProduct || !selectedPrice) {
        return null;
    }
    return (React.createElement("div", { className: "selected-product-overview" },
        React.createElement("div", null,
            React.createElement("div", { className: "user-seats" }, userSeatsElement)),
        React.createElement("div", null,
            React.createElement(LabelValuePair, { label: "Subtotal", value: currencyPrice(costExcludingVat || 0) }),
            React.createElement(LabelValuePair, { label: "VAT (" + taxRatePercentage + "%)", value: currencyPrice(vat) })),
        costIncludingVat && (React.createElement("div", { className: "total" },
            React.createElement(LabelValuePair, { label: "Total cost recurring " + (recurringInterval === 'month' ? 'monthly' : 'annually'), value: currencyPrice(costIncludingVat) })))));
};
export default SelectedProductOverview;
