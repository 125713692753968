import React, { useMemo } from 'react';
import SatisfactionNoteDeclaration from '../satisfactionNoteDeclaration/SatisfactionNoteDeclaration';
import moment from 'moment';
var SatisfactionNoteDeclarationResponse = function (_a) {
    var agreementDuration = _a.agreementDuration, lenderName = _a.lenderName, signedAt = _a.signedAt, rejectedAt = _a.rejectedAt, signatureImageData = _a.signatureImageData, companyName = _a.companyName, customerName = _a.customerName;
    var formattedSignedAtDate = useMemo(function () {
        if (!signedAt)
            return;
        return moment(signedAt).format('Do MMMM YYYY [at] HH:mm');
    }, [signedAt]);
    var formattedRejectedAtDate = useMemo(function () {
        if (!rejectedAt)
            return;
        return moment(rejectedAt).format('Do MMMM YYYY [at] HH:mm');
    }, [rejectedAt]);
    return (React.createElement("div", { className: "satisfaction-note-declaration-response" },
        React.createElement(SatisfactionNoteDeclaration, { agreementDuration: agreementDuration, companyName: companyName, lenderName: lenderName }),
        signatureImageData && (React.createElement("img", { src: signatureImageData, alt: customerName + "'s signature" })),
        signedAt && !!signatureImageData && (React.createElement("p", null, "Satisfaction declaration signed by " + customerName + " on " + formattedSignedAtDate)),
        signedAt && !signatureImageData && (React.createElement("p", null, customerName + " has not signed the satisfaction declaration but as over 72 hours have passed it is assumed to be satisfied on " + formattedSignedAtDate)),
        rejectedAt && (React.createElement("p", null, "Satisfaction declaration rejected by " + customerName + " on " + formattedRejectedAtDate))));
};
export default SatisfactionNoteDeclarationResponse;
