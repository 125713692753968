import React from 'react';
import CreateEditCustomerControl from '../createEditCustomerControl/CreateEditCustomerControl';
import { Modal } from '@payaca/components/modal';
import './CreateEditJobCustomerModal.css';
var CreateEditJobCustomerModal = function (_a) {
    var customer = _a.customer, isOpen = _a.isOpen, createCustomerName = _a.createCustomerName, onClose = _a.onClose, onPersistCustomer = _a.onPersistCustomer, headerContent = _a.headerContent;
    return (React.createElement(Modal, { isOpen: isOpen, title: (customer === null || customer === void 0 ? void 0 : customer.id) ? 'Edit customer' : 'Create customer', size: "sm", onClose: onClose, className: "create-edit-job-customer-modal" },
        React.createElement(CreateEditCustomerControl, { customer: customer, overrideInitialFormState: customer
                ? undefined
                : {
                    name: createCustomerName,
                    contacts: [
                        { name: createCustomerName, isPrimaryContact: true },
                    ],
                }, onPersistCustomer: onPersistCustomer })));
};
export default CreateEditJobCustomerModal;
